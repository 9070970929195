import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { PeopleData } from '../data/people';
import { Person, Honorific, IdType, Sex, User, SystemRole } from '../data/_models/people.model';

@Injectable()
export class PeopleService extends PeopleData {
    constructor(
        private http: HttpClient
    ) {
        super();
    }

    getPeople(): Observable<Array<Person>> {
        return this.http.get<Array<Person>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.personsUrl);
    }

    getPeopleWithUser(): Observable<Array<Person>> {
        return this.http.get<Array<Person>>(ServiceEndPoints.authBaseUrl + '/api/user/v1/people/users');
    }

    getAllUsersList(): Observable<Array<any>> {
        return this.http.get<Array<any>>(ServiceEndPoints.authBaseUrl + '/api/user/v1/users/all');
    }

    getAllIndividuals(): Observable<Array<Person>> {
        return this.http.get<Array<Person>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.individualsUrl);
    }

    getPersonById(personId: string): Observable<Person> {
        return this.http.get<Person>(ServiceEndPoints.authBaseUrl + `/api/profile/v1/person/${personId}`);
    }

    getFullnameByPersonId(personId: string){
      return this.http.get(ServiceEndPoints.authBaseUrl + `/api/profile/v1/person/${personId}/fullname`);
    }

    getAllPersonsLite(){
      return this.http.get(ServiceEndPoints.authBaseUrl + `/api/user/v1/people/all/lite`);
    }

    getAllPersonsLiteByBusinessUnit(businessUnit){
      return this.http.get(ServiceEndPoints.authBaseUrl + `/api/user/v1/people/all/lite/business-unit/${businessUnit}`);
    }

    getTitles(): Observable<Array<Honorific>> {
        return this.http.get<Array<Honorific>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllTitlesUrl);
    }

    getIdTypes(): Observable<Array<IdType>> {
        return this.http.get<Array<IdType>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllIdTypesUrl);
    }

    getGender(): Observable<Array<Sex>> {
        return this.http.get<Array<Sex>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllSexesUrl);
    }

    createUser(person: Person): Observable<Person> {
        return this.http.post<Person>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.registerUrl, person);
    }

    updatePerson(person: Person): Observable<Person> {
        return this.http.post<Person>(ServiceEndPoints.authBaseUrl + '/api/user/v1/update_user', person);
    }

    checkUsername(username: string): Observable<{ exist: boolean, message: string }> {
        return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.authBaseUrl + `/api/user/v1/check/username/${username}`);
    }

    editUser(person: Person): Observable<Person> {
        return this.http.post<Person>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.editUserUrl, person);
    }

    resetPassword(username: string): Observable<any> {
        const url = `${ServiceEndPoints.authBaseUrl + ServiceEndPoints.resetUrl}/${username}`;
        return this.http.post(url, {});
    }

    getUserByUsername(username: string): Observable<User> {
        return this.http.get(ServiceEndPoints.authBaseUrl + `/api/user/v1/dto/user/${username}`);
    }

    addUserRoles(userId: string, roles: Array<SystemRole>): Observable<User> {
        return this.http.patch<User>(ServiceEndPoints.authBaseUrl + `/api/user/v1/${userId}/roles`, roles);
    }

    createPerson(person: Person): Observable<Person> {
        return this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.createPersonUrl, person);
    }

    getPeopleByIdList(ids: Array<string>): Observable<Array<Person>> {
        const idList = ids.map(x => x).join(",");
        return this.http.get<Array<Person>>(ServiceEndPoints.authBaseUrl + `/api/profile/v1/people?ids=${idList}`);
    }

    getCompanyPersons(company: string): Observable<any[]> {
        return this.http.get<any[]>(ServiceEndPoints.authBaseUrl + `/api/user/v1/people/company/${company}/lite`);
    }
}
