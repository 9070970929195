import {Project} from '../project/project';
import {Notes} from '../notes';
import {ExcavationMethod} from './excavation-method';
import {NigisImages} from '../../common/nigisImages';
import {FileToUpload} from 'src/app/common/uploaded-files/entity/file';
import {Size} from '../../common/size';
import {CoordinatesFormat} from '../../common/coordinatesValidator/coordinatesFormat';
import {LocationTemp} from '../../common/locationTemp';
import { BoreholeType } from '../../common/boreholeType';
import { SoilChemistry } from './soil-chemistry';

export class TrialPit {
  id: string;
  trialPitNumber: string;
  startDate: Date;
  endDate: Date;
  stability: string;
  groundWaterObservation: string;
  generalComments: string;
  elevation: Size =  new Size();
  project: Project = new Project();
  location: LocationTemp;
  village: string;
  locationDescription: Notes;
  excavationMethod: ExcavationMethod;
  equipment: string;
  projectManager: string;
  coordinator: string;
  geologist: string;
  active: string;
  soilChemistry: SoilChemistry [];
  status: string;
  locationImages: NigisImages[];
  trialPitFiles: FileToUpload[];
  coordinatesFormat: CoordinatesFormat;
  boreholeType: BoreholeType = new BoreholeType();
  currentState: any;
  statusHistory: any[];
  creator: string;

  constructor() {
  }
}
