import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../_core/data/_models/people.model';
import {MatTableDataSource} from '@angular/material/table';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {GlobalDashboardService} from '../../global-dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {map} from 'rxjs/operators';
import {UserDashboardService} from '../../user-dashboard/user-dashboard.service';
import {Person} from '../../../common/entity/security/profile/person';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserService} from '../../../auth/user/user.service';
import {CollectionsState} from '../../../activities/models/CollectionsState';
import {CustomerRequestActivity} from '../../../activities/models';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-collections-curator-dashboard-ui',
  templateUrl: './collections-curator-dashboard-ui.component.html',
  styleUrls: ['./collections-curator-dashboard-ui.component.scss']
})
export class CollectionsCuratorDashboardUiComponent implements OnInit {

  @Input('myactivities') myactivities: any;
  @Input('isGeologicalSpeciman') isGeologicalSpeciman: any;

  loading = true;
  isSet = true;
  public currentUser: User;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: Array<string> = ['No', 'request_date', 'service_requested', 'status'];
  geologicalSpecimenColumns: Array<string> = ['No', 'accessionNumber', 'specimenIdentification','status','currentState'];
  public _requestsDataSource: MatTableDataSource<CustomerRequestActivity> = new MatTableDataSource<CustomerRequestActivity>();
  public geologicalSpecimenDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  activities: Array<CustomerRequestActivity>;
  @Input() filter: string;

  cards: any;
  state = new CollectionsState();

  public myactivitiesLabels = [ ];
  public myactivitiesData = [ ];

  public pieChartType = 'pie';
  userName: string;
  userReference: string;
  person: Person;
  stillFetchingMyProjectCount = true;
  numberOfMyProjects: number = 0;


  numberOfMyActivities: number = 0;

  numberOfBGIProjects: number = 0;
  stillFetchingBGIProjectCount = true;

  numberOfRequestActivities: number = 0;
  stillFetchingrequestActiviesCount = true;

  numberOfSpecimenRequestActivitiesSubmittedToManager = 0;
  stillFetchingSpecimenRequestActivitiesSubmittedToManagerCount = true;

  numberOfSpecimenRequestActivitiesRejectedByManager = 0;
  stillFetchingSpecimenRequestActivitiesRejectedByManagerCount = true;

  numberOfSpecimenRequestActivitiesApproved = 0;
  stillFetchingSpecimenRequestActiviesApprovedCount = true;

  numberOfSpecimenRequestActivitiesDrafts = 0;
  stillFetchingSpecimenRequestActiviesDraftsCount = true;

  activitiesCount :any;

  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  colors: any = [{
    backgroundColor: ['#573419', '#00A2E5', '#CC0000',
      '#C8A207','#009900', '#CCCCCC']
  }];

  constructor(private breakpointObserver: BreakpointObserver,
              private _router: Router,
              private _toastr: ToastrService,
              private userDashBoardService: UserDashboardService,
              private globalDashboardService: GlobalDashboardService,
              private userService: UserService) {

    const jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({matches}) => {
        return [
          {title: '', cols: 2, rows: 1, id: 'cards'},
          {title: 'My Activities', cols: 1, rows: 3, id: 'myactivities'},
          {title: 'Recent Activities', cols: 1, rows: 3, id: 'recents'}
        ];
      })
    );
  }

  ngOnInit() {
    if(this.isGeologicalSpeciman){
      this.myactivitiesLabels = ['Drafts','Submitted-Senior', 'Submitted-Manager', 'Returned', 'Approved','No status'];
      this.myactivitiesData = [2,4,5,1,2,3]
      this.getGeologicalSpecimenActivityCounts();

    }else{
      this.myactivitiesLabels = ['Drafts','Submitted-Manager', 'Returned', 'Approved'];
      this.myactivitiesData = [2,5,1,2];
      this.getUserSpecimenRequestActivitiesSubmittedToManagerCount();
      /*this.getUserRequestActivitiesCount();*/
      this.getMySpecimenActivites();
    }
      this.getRecentActivities();
      this.getBGIProjectsCount();
  }


  getGeologicalSpecimenActivityCounts(){
    this.userDashBoardService.getGeologicalSpecimenCuratorActivityCounts().subscribe(value => {
      this.activitiesCount = {draft : Number(value['draft']), submittedManager: Number(value['managerSubmitted'])
        , submittedSenior:Number(value['seniorSubmitted']),returned:Number( value['returned']), published: Number(value['approved']),noStatus:Number(value['noStatus'])};


      this.myactivitiesData = [ this.activitiesCount.draft,this.activitiesCount.submittedSenior ,this.activitiesCount.submittedManager
        , this.activitiesCount.returned, this.activitiesCount.published,this.activitiesCount.noStatus];

      this.numberOfMyActivities = Number(this.activitiesCount.draft)+Number(this.activitiesCount.submittedSenior) + Number(this.activitiesCount.submittedManager)
        + Number(this.activitiesCount.returned) + Number(this.activitiesCount.published)+Number(this.activitiesCount.noStatus);
    });
  }

  getRecentActivities() {
    this.loading = false;
    if(this.isGeologicalSpeciman){
      this.userDashBoardService.getGeologicalSpecimenCuratorActivities().subscribe(value => {
        this.geologicalSpecimenDataSource.data = value as [];
      });
    }else{
      this.userDashBoardService.getRecentCuratorActivities().subscribe(value => {
        this.dataSource.data = value as [];
      });
    }

  }

  gotoProject(uri: string) {
    let urlParams: any;
    if ('my_project' === uri) {
      urlParams = {id: 'my_project'};
    }
    if ('bgi_project' === uri) {
      urlParams = {id: 'bgi_project'};
    }
    this._router.navigate([`portal/applied-geoscience/project/list`, urlParams]);
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
      this.stillFetchingBGIProjectCount = false;
    });
  }

  getMySpecimenActivites() {
    this.filter = 'all';
    this.userDashBoardService.getUserSpecimenRequestActivities(this.filter, 'request-specimen').subscribe(res => {
      this.activities = res;
      this._requestsDataSource.data = this._requestsDataSource.data ? this._requestsDataSource.data.concat(this.sliceResultArray(this.activities)) : this.sliceResultArray(this.activities);
    }, err => {
      this._toastr.warning(err.message, 'Failed to get activities');
    }, () => {
    });
  }


  chartClickedActivities(event) {
    if(this.isGeologicalSpeciman) {
      switch (event.active[0]._view.label) {
        case this.myactivitiesLabels[0]:
          this.gotoDraftsList();
          break;
        case this.myactivitiesLabels[1]:
          this.gotoSubmittedToSeniorList();
          break;
        case this.myactivitiesLabels[2]:
          this.gotoSubmittedToManagerList();
          break;
        case this.myactivitiesLabels[3]:
          this.gotoReturnedList();
          break;
        case this.myactivitiesLabels[4]:
          this.gotoApprovedList();
          break;
        case this.myactivitiesLabels[5]:
          this.gotoNoStatusList();
          break;

        default:
      }
    }else{
      switch (event.active[0]._view.label) {
        case this.myactivitiesLabels[0]:
          this.gotoDraftsList();
          break;
        case this.myactivitiesLabels[1]:
          this.gotoSubmittedToManagerList();
          break;
        case this.myactivitiesLabels[2]:
          this.gotoReturnedList();
          break;
        case this.myactivitiesLabels[3]:
          this.gotoApprovedList();
          break;

        default:
      }
    }
  }

  gotoMyActivitiesList() {
    if(this.isGeologicalSpeciman){
      this._router.navigate([`portal/activities/geological_specimen/self/all`]);
    }else{
      this._router.navigate([`portal/activities/archivist/request-specimen/all`]);
    }
  }

  gotoSubmittedToManagerList() {
    if(this.isGeologicalSpeciman){
      this._router.navigate([`portal/activities/geological_specimen/self/SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR`]);
    }else{
      this._router.navigate([`portal/activities/archivist/request-specimen/SUBMITTED_SPECIMEN_TO_MANAGER`]);
    }
  }

  gotoSubmittedToSeniorList() {
    if(this.isGeologicalSpeciman) {
      this._router.navigate([`portal/activities/geological_specimen/self/SUBMITTED_TO_SENIOR`]);
    }
  }

  gotoReturnedList() {
    if(this.isGeologicalSpeciman){
      this._router.navigate([`portal/activities/geological_specimen/self/REJECTED`]);
    }else{
      this._router.navigate([`portal/activities/archivist/request-specimen/REJECTED_SPECIMEN_BY_MANAGER`]);
    }
  }

  gotoApprovedList() {
    if(this.isGeologicalSpeciman){
      this._router.navigate([`portal/activities/geological_specimen/self/APPROVED_MANAGER`]);
    }else{
      this._router.navigate([`portal/activities/archivist/request-specimen/APPROVED_SPECIMEN_MANAGER`]);
    }
  }

  gotoNoStatusList() {
    if(this.isGeologicalSpeciman){
      this._router.navigate([`portal/activities/geological_specimen/self/NO_STATUS`]);
    }
  }

  gotoDraftsList() {
    if(this.isGeologicalSpeciman){
      this._router.navigate([`portal/activities/geological_specimen/self/DRAFTS`]);
    }else{
      this._router.navigate([`portal/activities/archivist/request-specimen/PENDING_SPECIMEN_MANAGER_SUBMISSION`]);
    }
  }

  getUserRequestActivitiesCount() {
    this.userDashBoardService.getUserRequestActivitiesCount('all', 'request').subscribe(number => {
      this.numberOfRequestActivities = number;
      this.stillFetchingrequestActiviesCount = false;
    });
  }

/* TODO My Activities
  getUserSpecimenRequestActivitiesCount() {
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount('all', 'request-specimen').subscribe(number => {
      this.numberOfSpecimenRequestActivities = number;
      this.myactivitiesData [0] = this.numberOfSpecimenRequestActivities;
      this.stillFetchingSpecimenRequestActiviesCount = false;
    });
  }
*/

  getUserSpecimenRequestActivitiesSubmittedToManagerCount() {
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.SUBMITTED_SPECIMEN_TO_MANAGER.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesSubmittedToManager = number;
      this.getUserSpecimenRequestActivitiesRejectedByManagerCount()
    });
  }

  getUserSpecimenRequestActivitiesRejectedByManagerCount() {
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.REJECTED_SPECIMEN_BY_MANAGER.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesRejectedByManager = number;
      this.getUserSpecimenRequestActivitiesApprovedCount();
    });
  }

  getUserSpecimenRequestActivitiesApprovedCount() {
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.APPROVED_SPECIMEN_MANAGER.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesApproved = number;
      this.getUserSpecimenRequestActivitiesDraftsCount();
    });
  }

  getUserSpecimenRequestActivitiesDraftsCount() {
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.PENDING_SPECIMEN_MANAGER_SUBMISSION.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesDrafts = number;
      this.myactivitiesData = [this.numberOfSpecimenRequestActivitiesDrafts,this.numberOfSpecimenRequestActivitiesSubmittedToManager,
        this.numberOfSpecimenRequestActivitiesRejectedByManager,this.numberOfSpecimenRequestActivitiesApproved];
      this.stillFetchingSpecimenRequestActiviesDraftsCount = false;
    });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length - 8);
    }
    return respArray;
  }

}
