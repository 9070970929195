import { Injectable } from "@angular/core";
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorageConstants } from 'src/app/_core/storage.constants';

@Injectable({
    providedIn: 'root'
})
export class UserRoles {
    constructor(
        private _localStorage: LocalStorage
    ){}

    async getAllRoles(): Promise<Array<any>> {
        const fullUser = await this._localStorage.getItem<any>(StorageConstants.fullUserKey).toPromise();

        if (!fullUser) return [];

        
        let tempRoles: string[] = [];

        fullUser.roles.forEach(r => {
            tempRoles.push(r.name);
        });

        let roles: Set<string> = new Set<string>(tempRoles);

        while(tempRoles.length) { tempRoles.pop() }

        roles.forEach(a => { tempRoles.push(a) });

        return tempRoles;
    }

    public async getRoleByName(name: string): Promise<string> {
        let all = await this.getAllRoles();

        return all.find(a => a.trim().toLowerCase() === name.trim().toLowerCase());
    }

    public async hasRole(name: string): Promise<boolean> {
        const roleExist = await this.getRoleByName(name); 
        return roleExist ? true : false;
    }
}