import { WaterBearingZonesCreateComponent } from './../water-bearing-zones-create/water-bearing-zones-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WaterBearingZones } from './../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import { HydrogeologyService } from './../../../hydrogeology.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-water-bearing-zones-details',
  templateUrl: './water-bearing-zones-details.component.html',
  styleUrls: ['./water-bearing-zones-details.component.scss']
})
export class WaterBearingZonesDetailsComponent implements OnInit {

  waterBearingZone: WaterBearingZones;
  waterBearingZoneId: string;
  boreholeId: string
  expanded: boolean = true;

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.boreholeId = params.boreholeId;
      this.waterBearingZoneId = params.id;
    });
  }

  ngOnInit() {
    this.getWaterBearingZoneLogById();
  }

  getWaterBearingZoneLogById() {
    this.hydrogeologyService.getWaterBearingZoneLogById(this.waterBearingZoneId).subscribe(data => {
      this.waterBearingZone = data as WaterBearingZones;
    }, err => {
        this.toast.error(err.message, "Failed Delete");
    });

  }

  onEdit(waterBearingZone: WaterBearingZones) {
    const createDialogRef = this.dialog.open(WaterBearingZonesCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: waterBearingZone
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getWaterBearingZoneLogById();
    });

  }

  redirect() {
    this.router.navigate(['portal/applied-geoscience/geo-tech/water-borehole/list']);
  }

  cancelConstructionView() {
    const idObject = {id: this.boreholeId};
    this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', idObject]);
  }

  exportToPDF() {
    this.toast.warning('UNDER CONSTRUCTION');
  }

}
