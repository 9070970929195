import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoadWorksValidations implements OnInit {

  constructor() {
  }

  validation_messages = {
    'testNumber': [
      {type: 'required', message: 'Test number is required'}
    ],
    'projectName': [
      {type: 'required', message: 'Project name is required'},
    ],
    'date': [
      {type: 'required', message: 'date is required'},
    ],
    'stationNumber': [
      {type: 'required', message: 'Station number is required'},
    ],
    'longitude': [
      {type: 'required', message: 'longitude is required'},
    ],
    'latitude': [
      {type: 'required', message: 'latitude is required'},
    ],
    'village': [
      {type: 'required', message: 'village is required'},
    ],
    'district': [
      {type: 'required', message: 'district is required'},
    ],
    'surfaceType': [
      {type: 'required', message: 'Surface Type is required'},
    ],
    'time': [
      {type: 'required', message: 'time is required'},
    ],
    'hummerWeight': [
      {type: 'required', message: 'hummer weight required'}
    ],
    'projectCoordinator': [
      {type: 'required', message: 'Project coordinator required'}
    ],
    'lithologyDescription': [
      {type: 'required', message: 'description required'}
    ],
    'type': [
      { type: 'required', message: 'Select Coordinates Type' },
    ],
    'format': [
      { type: 'required', message: 'Select Coordinates Format' },
    ],
    'zone34SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone34SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'zone5SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone35SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'dmsSx': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMx': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDx': [
      { type: 'required', message: 'Type degree value' },
    ],
    'dmsSy': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMy': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDy': [
      { type: 'required', message: 'Type degree value' },
    ],
  };

  ngOnInit() {
  }
}
