import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { FileToUpload } from './entity/file';

@Injectable({
  providedIn: 'root'
})
export class UploadedFilesService {

  constructor(private http: HttpClient) {
  }

  getAllFiles(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllFilesUrl);
  }

  getAllFilesByKey(id: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllFilesByForeignKeyUrl + '/' + id);
  }

  createFile(file: FileToUpload): Observable<any> {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createFileUrl, file);
  }

  deleteFile(file: FileToUpload): Observable<any> {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.deleteFileUrl, file);
  }

  uploadFileToServer(formData: FormData): Observable<any> {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.uploadFileToserverUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadFile(fileName: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.downloadFileUrl + '/' + fileName,
    { responseType: 'blob' as 'json' });
  }


}

