import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatChipInputEvent} from '@angular/material';
import {CompanyService} from '../../company/company.service';
import {Company} from '../../../shared-kernel/entity/applied-geoscience/company/company';
import {LicenseType} from '../../../shared-kernel/entity/applied-geoscience/prospecting-license/license-type';
import {Commodity} from '../../../shared-kernel/entity/applied-geoscience/prospecting-license/commodity';
import {ProspectingLicenseService} from '../prospecting-license.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ToastrService} from 'ngx-toastr';
import {ProspectingLicense} from '../../../shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license';
import {CompanyEnum} from '../../../shared-kernel/enumerations/company-enum';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MineralType } from 'src/app/shared-kernel/entity/applied-geoscience/project/mineral-type';

@Component({
  selector: 'app-prospecting-license-create',
  templateUrl: './prospecting-license-create.component.html',
  styleUrls: ['./prospecting-license-create.component.scss']
})
export class ProspectingLicenseCreateComponent implements OnInit {

  plForm: FormGroup = new FormGroup({
    plNumber: new FormControl('', Validators.required),
    company: new FormGroup({
      id: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
    }),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    objective: new FormControl(''),
    mineralTypes: new FormControl(''),
    plTypeCommodities: new FormControl(''),
  });

  dialogConfig = new MatDialogConfig();

  companies: Observable<Company[]>;
  companyList: Company[] = [];
  plTypes: LicenseType[] = [];
  commodityPlTypes: MineralType[] = [];
  plTypeCommodities: Commodity[] = [];
  plCommodities: Commodity[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private companyService: CompanyService,
              private plService: ProspectingLicenseService,
              private toastr: ToastrService,
              private dialogRef: MatDialogRef<ProspectingLicenseCreateComponent>) {
  }

  ngOnInit() {
    if (this.data.prospectingLicense) {

      this.plForm.setValue({
        'plNumber': this.data.prospectingLicense.prospectingLicenseNumber,
        'company': {id: this.data.prospectingLicense.company.id, name: this.data.prospectingLicense.company.name},
        'startDate': new Date(this.data.prospectingLicense.startDate),
        'endDate': new Date(this.data.prospectingLicense.endDate),
        'objective': this.data.prospectingLicense.description.description,
        'mineralTypes': '',
        'plTypeCommodities': '',
      });

      this.plCommodities = this.data.prospectingLicense.commodities;
      this.getCommoditiesPlType();
    }
    // -- Config dialog properties --
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '420px';

    this.getAllPLTypes();
    this.getAllCompanies();

    this.companies = this.plForm.get('company').valueChanges.pipe(
      startWith(''),
      map(value => this._filterCompanies(value || '')),
    );
  }

  private _filterCompanies(value: any): Company[] {
    
    const filterValue = value.name ? value.name.toLowerCase() : '';

    return this.companyList.filter(company => {
      
      return company.name.toLowerCase().includes(filterValue)
    });
  }

  updateCompanyId(id) {
    
    this.plForm.get('company').get('id').setValue(id);
  }

  getCommoditiesByPLTypeId(id: string) {
    
    this.plService.getCommoditiesByPLTypeId(id)
      .subscribe(data => {
        this.plTypeCommodities = data as Commodity[];
      });
  }

  getAllPLTypes() {
    this.plService.getAllPLTypes()
      .subscribe(data => {
        this.plTypes = data as LicenseType[];
      });
  }

  selected(event: any) {
    // -- check for duplicates --
    if (this.plCommodities.indexOf(event.value) < 0) {
      this.plCommodities.push(event.value);

      let index = this.commodityPlTypes.findIndex(type => type.id === event.value.mineralType.id);
      if(index < 0) {
        this.commodityPlTypes.push(event.value.mineralType);

      }
    }
  }

  remove(commodity: Commodity): void {
    const index = this.plCommodities.indexOf(commodity);
    if (index >= 0) {
      this.plCommodities.splice(index, 1);
    }
    
  }

  removePlType(type) {
    const index = this.commodityPlTypes.indexOf(type);
    if (index >= 0) {
      this.commodityPlTypes.splice(index, 1);
    }

    let tmp = [];
    this.plCommodities.forEach(commodity => {
      if(commodity.mineralType.id !== type.id) {
        tmp.push(commodity);
      }
    });

    this.plCommodities = tmp;

  }

  getCommoditiesPlType() {
    this.commodityPlTypes = [];
    let tmp = {};
    this.data.prospectingLicense.commodities.forEach(commodity => {
      if(!tmp[commodity.mineralType.id]) {
        tmp[commodity.mineralType.id] = true;
        this.commodityPlTypes.push(commodity.mineralType);
      }
    });
  }

  /**
   * PL are issued to external companies only.
   * Get all companies and exclude BGI from the list. */
  getAllCompanies() {
    this.companyService.getAllCompanies().pipe()
      .subscribe(data => {
        const allCompanies = data as Company[];

        allCompanies.forEach(company => {
          if (company.name !== CompanyEnum.BGI_NAME) {
            this.companyList.push(company);
          }
        });
      });
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    const formData = this.plForm.value;

    if (new Date(formData.startDate) >= new Date(formData.endDate)) {
      this.toastr.error('Start date MUST be a date before end date', 'Invalid date range');
      this.plForm.controls['endDate'].setValue('');
      return;
    }

    const status = {id: '1', status: 'Open', description: ''};
    const description = {id: '', description: formData.objective};

    if (this.data.prospectingLicense) { // -- Update the existing PL --

      this.data.prospectingLicense.prospectingLicenseNumber = formData.plNumber;
      this.data.prospectingLicense.commodities = this.plCommodities;
      this.data.prospectingLicense.company = formData.company;
      this.data.prospectingLicense.description = description;
      this.data.prospectingLicense.startDate = formData.startDate;
      this.data.prospectingLicense.endDate = formData.endDate;

      this.plService.createProspectingLicense(this.data.prospectingLicense)
        .subscribe(results => {
          this.toastr.success('Prospecting license updated successfully', 'Update Prospecting License');
        }, () => {
          this.toastr.error('Prospecting license update failed', 'Update Prospecting License');
        }, () => {
          this.clearForm();
          this.onClose();
        });

    } else {
      const pl = new ProspectingLicense();
      pl.prospectingLicenseNumber = formData.plNumber;
      pl.company = formData.company;
      pl.commodities = this.plCommodities;
      pl.startDate = formData.startDate;
      pl.endDate = formData.endDate;
      pl.currentStatus = status;
      pl.status = [status];
      pl.description = description;

      this.plService.createProspectingLicense(pl)
        .subscribe(() => {
          this.toastr.success('Creating prospecting license successfully', 'Create Prospecting License');
        }, () => {
          this.toastr.error('Creating prospecting license failed', 'Create Prospecting License');
        }, () => {
          this.clearForm();
          this.onClose();
        });
    }
  }

  /**
   * Reset form fields*/
  clearForm() {
    this.plForm.reset();
    this.plCommodities = [];
  }

}
