import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';

export enum UploadState {
    DEFAULT = 0,
    UPLOADED = 1,
    UPLOADING = 2,
    FAILED = 3
}

@Component({
    selector: 'single-image-view',
    templateUrl: './image-photo.component.html',
    styleUrls: ['./image-photo.component.scss']
})
export class ImagePhotoComponent {
    state: UploadState = UploadState.DEFAULT;

    @Input('dataUrl') dataUrl: string;
    @Input('id') id: string = '';
    @Input('changeState') set s(val: number) { this._changeState(val); }

    @Output() deleteEvent = new EventEmitter<string>();

    constructor() {}

    deleteImage() {
        this.deleteEvent.emit(this.id);
    }

    _changeState(val: UploadState) {
        if (val >= UploadState.DEFAULT && val <= UploadState.FAILED) {
            this.state = val;
        }
    }
}