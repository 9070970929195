import {Injectable, OnInit} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DrillerValidation implements OnInit {

    validation_messages = {
        'drillingMethod': [
          { type: 'required', message: 'Please select drilling method' }
        ],
        'sampleType': [
          { type: 'required', message: 'Please select sample type' },
        ],
        'sampleSize': [
          { type: 'required', message: 'Sample size is required' },
        ],
        'finalDepth': [
          { type: 'required', message: 'End of hole depth is required' },
        ],
        'drillingDate': [
            { type: 'required', message: 'Drilling date is required' },
        ],
        'latitude': [
            { type: 'required', message: 'Latitude is required' },
        ],
        'longitude': [
            { type: 'required', message: 'Longitude is required' },
        ],
        'district': [
            { type: 'required', message: 'Please select district' },
        ],
       'country': [
        { type: 'required', message: 'Please select country' },
      ],
        'village': [
            { type: 'required', message: 'Village is required' },
        ],
        'notes': [
            { type: 'required', message: 'Notes are required' },
        ],
        'project': [
            { type: 'required', message: 'Project is required' },
        ],
        'drillingRequest': [
            { type: 'required', message: 'Drilling Request is required' },
        ],
        'boreholeType': [
            { type: 'required', message: 'Borehole Type is required' },
        ],
        'type': [
          { type: 'required', message: 'Select Coordinates Type' },
        ],
        'format': [
          { type: 'required', message: 'Select Coordinates Format' },
        ],
        'zone34SNorthing': [
          { type: 'required', message: 'Type nothing value' },
        ],
        'zone34SEasting': [
          { type: 'required', message: 'Type easting value' },
        ],
        'zone5SNorthing': [
          { type: 'required', message: 'Type nothing value' },
        ],
        'zone35SEasting': [
          { type: 'required', message: 'Type easting value' },
        ],
        'dmsSx': [
          { type: 'required', message: 'Type seconds value' },
        ],
        'dmsMx': [
          { type: 'required', message: 'Type minutes value' },
        ],
        'dmsDx': [
          { type: 'required', message: 'Type degree value' },
        ],
        'dmsSy': [
          { type: 'required', message: 'Type seconds value' },
        ],
        'dmsMy': [
          { type: 'required', message: 'Type minutes value' },
        ],
        'dmsDy': [
          { type: 'required', message: 'Type degree value' },
        ],
      };

  constructor() {
  }

  ngOnInit() {}
}
