import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { Size } from 'src/app/shared-kernel/entity/common/size';

export class PenetrationRateLog {
    id: string;
    depthFrom: Size;
    depthTo: Size;
    minutes: number;
    seconds: number;
    penetrationRate: number;
    borehole: Borehole;
}
