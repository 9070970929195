import {Component, Input, ViewChild} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';

import {PhotosListComponent} from '../photos-list/photos-list.component';
import {PhotosUploadComponent} from '../photos-upload/photos-upload.component';

@Component({
  selector: 'photos-container',
  templateUrl: './photos-container.component.html',
  styleUrls: ['./photos-container.component.scss']
})
export class PhotosContainerComponent {
    @Input('currentState') currentState: string = '';
    @Input('boreholeCreator') boreholeCreator: string = '';
    @Input('linkedEntity') linkedEntity;
    @Input('entityCreator') entityCreator;
    @Input('tagging') tagging;
    @Input('projectId') projectId : string = '';


  @ViewChild('docImageList') docImageList: PhotosListComponent;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  onUpload() {
    const newUserDialogRef = this.dialog.open(PhotosUploadComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: {
        linkedEntity: this.linkedEntity,
        tagging: this.tagging
      }
    });

    newUserDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.docImageList.refresh();
      }
    });
  }
}
