import { TrayApproveAction } from './../model/tray-approval-state';
import { environment } from './../../../environments/environment';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

export interface ChangeStateModel {
    action: TrayApproveAction,
    comment: string;
}


export interface ChangeStateModelFiles {
  action: TrayApproveAction,
  comment: string;
}

@Injectable({
    providedIn: 'root'
})
export class CollectionsApprovalService {
    constructor(
        private _http: HttpClient
    ) {}

    changeStateCoreTrayDetails(trayDetailsId: string, approval: ChangeStateModel): Observable<any> {
        return this._http.patch<any>(environment.collectionsBaseUrl + `/api/approval/v1/core-tray-details/${trayDetailsId}/approve_states`, approval);
    }

    changeStateChipsTrayDetails(trayDetailsId: string, approval: ChangeStateModel): Observable<any> {
      return this._http.patch<any>(environment.collectionsBaseUrl + `/api/approval/v1/chips-tray-details/${trayDetailsId}/approve_states`, approval);
    }

    changeStateCustomerRequest(customerRequestId: string, approval: ChangeStateModel): Observable<any> {
      return this._http.patch<any>(environment.collectionsBaseUrl + `/api/approval/v1/customer-request/${customerRequestId}/approve_states`, approval);
    }


}
