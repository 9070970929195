import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SoilChemistry } from '../../../../../shared-kernel/entity/applied-geoscience/soil-log/soil-chemistry';
import { GeoTechService } from '../../../geo-tech.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SoilChemistryImportComponent } from './soil-chemistry-import/soil-chemistry-import.component';
import { SoilElements } from '../../../../../shared-kernel/entity/applied-geoscience/soil-log/soil-elements';
import { SoilLogService } from '../soil-log/soil-log.service';
import { TrialPit } from '../../../../../shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-trialpit-tests',
  templateUrl: './trialpit-tests.component.html',
  styleUrls: ['./trialpit-tests.component.scss']
})
export class TrialpitTestsComponent implements OnInit {

  dataSourceTest: MatTableDataSource<SoilChemistry> = new MatTableDataSource();
  loading: boolean = false;
  trialPitId: string;
  trialPit: TrialPit = new TrialPit();
  displayedColumns = ['No.', 'Sample ID', 'Elements'];
  csvRecords: SoilChemistry = new SoilChemistry();
  @ViewChild('fileImportInput') fileImportInput: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  soilTest: SoilChemistry = new SoilChemistry();

  constructor(private geoTechService: GeoTechService, private soilLogService: SoilLogService, private route: ActivatedRoute, private toast: ToastrService,
              private spinner: NgxSpinnerService, private dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.trialPitId = params.id;
    });
  }

  ngOnInit() {
    this.getSoilTestsSoilChemistry();
    this.getTrialPit(this.trialPitId);
  }


  applyFilter(filterValue: string) {
    this.dataSourceTest.filter = filterValue.trim().toLowerCase();
  }

  getSoilTestsSoilChemistry() {
    this.geoTechService.getSoilTestSoilChemistry(this.trialPitId).subscribe(data => {
        this.dataSourceTest.data = data as SoilChemistry[];
        this.loading = false;
      },
      error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.dataSourceTest = new MatTableDataSource<SoilChemistry>(this.dataSourceTest.data);
        this.fileReset();
      });
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = ( <string>csvRecordsArr[0] ).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if(this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = ( <string>csvData ).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow);
        this.importModal(this.csvRecords);
      };

      reader.onerror = function () {
      };

    } else {
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerRow: any[]) {
    const csvRecord: SoilChemistry = new SoilChemistry();

    const data = ( <string>csvRecordsArray[1] ).split(',');
    if(data.length === headerRow.length) {

      csvRecord.sampleId = data[0].trim();
      csvRecord.trialPit = this.trialPit;

      for (let j = 1; j < data.length; j++) {
        let soilElement = new SoilElements();
        soilElement.name = headerRow[j];
        soilElement.reading = parseFloat(data[j]);
        csvRecord.soilElements.push(soilElement);
      }

    }
    this.csvRecords = csvRecord;
    return csvRecord;
  }

  getTrialPit(trialPitId) {
    this.soilLogService.getTrialPitById(trialPitId).subscribe(
      value => {
        this.trialPit = value as TrialPit;
      },
    );
  }

  importModal(soilTest: SoilChemistry) {
    soilTest['edit'] = true;
    const importDialogRef = this.dialog.open(SoilChemistryImportComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      height: '680px',minWidth:'400px',
      data: soilTest
    });

    importDialogRef.afterClosed().subscribe(result => {

      this.getSoilTestsSoilChemistry();
    });
  }

  viewElements(soilTest) {
    const importDialogRef = this.dialog.open(SoilChemistryImportComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      height: '680px',minWidth:'400px',
      data: soilTest
    });
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = new SoilChemistry();
    this.resetPaginator();
  }


  resetPaginator() {
    this.dataSourceTest.paginator = this.paginator;
  }
}
