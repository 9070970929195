import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Petrography } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/Petrography';
import { MatPaginator, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { GeoTechService } from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import { Router, UrlTree, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { PetrographyTestCreateComponent } from './petrography-test-create/petrography-test-create.component';
import { Sample } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/Sample';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-petrography-test',
  templateUrl: './petrography-test.component.html',
  styleUrls: ['./petrography-test.component.scss']
})
export class PetrographyTestComponent implements OnInit {
  @Input('currentState') currentState: string ='';
  petrography: Petrography;
  loading: boolean;
  sampleId: string;
  petrographyTests: Petrography[];
  @Input('projectId') projectId?: string;
  samplesDetails: Sample;


  //@Input('entityId') entityId: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  tableDataSource: MatTableDataSource<Petrography>;
  displayedColumns: string[] = ['#', 'Sample No.', 'Description', 'Cross Section Imagery', 'Action'];

  constructor(
    private geotechService: GeoTechService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.sampleId = params.id;
    });
    this.getPetrographyTestBysampleId();
  }

  private getPetrographyTestBysampleId() {
    this.geotechService.getPetrographyBySampleId(this.sampleId)
    .subscribe(data => {
      this.petrographyTests = data as Petrography[];
      this.tableDataSource = new MatTableDataSource<Petrography>(this.petrographyTests);
      for (let petrographyTest of this.petrographyTests) {
        petrographyTest.imagesNamesFull = '';
        for (let crossSectionImagery of petrographyTest.crossSectionImagery) {
          if(petrographyTest.crossSectionImagery.length > 1) {
            petrographyTest.imagesNames = petrographyTest.crossSectionImagery[0].name + '  ' + '+' + (petrographyTest.crossSectionImagery.length-1);
            petrographyTest.imagesNamesFull += crossSectionImagery.name + ', ';
          } else if (petrographyTest.crossSectionImagery.length = 1) {
            petrographyTest.imagesNames = petrographyTest.crossSectionImagery[0].name;
            petrographyTest.imagesNamesFull = crossSectionImagery.name;
          } else {
            petrographyTest.imagesNames = '--';
            petrographyTest.imagesNamesFull = 'Cross Section Image not provided';
          }
        }
      }
    });
  }

  openDialog(): void  {
    this.spinner.show();
    let dataObject = {sampleId: this.sampleId,
                      petrography: null};
    const createDialogRef = this.dialog.open(PetrographyTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: dataObject,
    });
    createDialogRef.afterClosed().subscribe(result => {
        this.getPetrographyTestBysampleId();
    });

  }

  edit(petrography): void  {
    this.spinner.show();
    let dataObject = {sampleId: this.sampleId,
      petrography: petrography};
      const editDialogRef = this.dialog.open(PetrographyTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: dataObject,
      });

      editDialogRef.afterClosed().subscribe(result => {
      this.getPetrographyTestBysampleId();
      });
  }

  delete(petrography) {
    this.spinner.show();
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete Record',
      type: 'warning',
      html: `Are you sure you want to <b>Delete</b> this record?`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
  }).then(result => {
      if (result && result.value === true) {
          this.geotechService.deletePetrography(petrography).subscribe(res => {
              this._toastr.success("Record deleted successfully", "Record deleted");
              this.getPetrographyTestBysampleId();
          }, _err => {
              this._toastr.error("Failed to delete a Record", "Delete Failed");
          });
      }
  });
  this.spinner.hide();
  }

  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }
}
