import { Injectable } from '@angular/core';

import { LocalStorage } from '@ngx-pwa/local-storage';

import { StorageConstants } from "./../../_core/storage.constants";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenProvider {
    constructor(
        private _localStorage: LocalStorage
    ) {
    }

    /**
     * Token getter for @auth0/angular-jwt Module registration
     */
    tokenGetter(): Observable<string> {
        return this._localStorage.getItem(StorageConstants.tokenKey);
    }
}