import { WaterPumpTestRecoveryCreateComponent } from './../water-pump-test-recovery-create/water-pump-test-recovery-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PeopleService } from './../../../../../_core/mock/people.service';
import { WaterPumpingTestRecovery } from './../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestRecovery';
import { Component, OnInit } from '@angular/core';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-water-pump-test-recovery-view',
  templateUrl: './water-pump-test-recovery-view.component.html',
  styleUrls: ['./water-pump-test-recovery-view.component.scss']
})
export class WaterPumpTestRecoveryViewComponent implements OnInit {

  waterPumpingTestRecoveryId: string;
  boreholeId: string;

  waterPumpingTestRecovery : WaterPumpingTestRecovery;
  geologist: string;
  observationGeologist: string;

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private peopleService: PeopleService,
    private dialog: MatDialog,) {
      this.activeRoute.params.subscribe(value => {
        this.waterPumpingTestRecoveryId = value.id;
        this.boreholeId = value.boreholeId;
      });
  }

  ngOnInit() {
    this.getWaterPumpingTestRecoveryById();
  }

  goToList() {
    this.router.navigate(['portal/applied-geoscience/geo-tech/water-borehole/list']);
  }

  cancelTestView() {
    const idObject = {id: this.boreholeId};
    this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', idObject]);
  }

  getWaterPumpingTestRecoveryById() {
    this.hydrogeologyService.getWaterPumpingTestRecoveryTestById(this.waterPumpingTestRecoveryId).subscribe(data => {
      this.waterPumpingTestRecovery = data as WaterPumpingTestRecovery;
      this.peopleService.getPersonById(this.waterPumpingTestRecovery.geologist).subscribe(
        person => {
          if(person){
            this.geologist = person.name +' '+ person.surname;
          }
        }
      );

      this.peopleService.getPersonById(this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.geologist).subscribe(
        person => {
          if(person){
            this.observationGeologist = person.name +' '+ person.surname;
          }
        }
      );
    });
  }

  onEdit(waterPumpingTestRecovery: WaterPumpingTestRecovery) {
    const obj = {test: waterPumpingTestRecovery, borehole: this.waterPumpingTestRecovery.borehole, transactionType:'Edit'};
    const newGeoHeritageDialogRef = this.dialog.open(WaterPumpTestRecoveryCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getWaterPumpingTestRecoveryById();
    });
  }
}
