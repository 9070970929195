import { District } from './district';
import { Countries } from '../../profile/countries';

export class PostalAddress {

  public id: string;
  public boxNumber: string;
  public cityTown: string;
  public district: District = new District();
  public country: Countries = new Countries();

  constructor() {  }
}
