import { Size } from '../common/size';

export class DrillingConsumables {
    id: string;
    drillMud: Number;
    copperGreaseQuantity: Number;
    coreLifterSprings: Number;
    stopRings: Number;
    copperGreaseWeight: Size = new Size();
 }
