import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';

import { StorageConstants } from 'src/app/_core/storage.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private _router: Router,
        private _localStorage: LocalStorage,
        private _toastr: ToastrService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this._localStorage.getItem<string>(StorageConstants.tokenKey).pipe(
            switchMap(token => {
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `${token}`
                        }
                    });
                }

                return next.handle(request).pipe(catchError(this.handleError.bind(this)));
            })
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 401) {
            this._localStorage.clear().subscribe(res => {
                localStorage.clear();

                this._router.navigate(['auth']);
            }, error => {
                this._toastr.error(error.message, "Security Error");
            });
        } else if (error.status === 403) {
            // TODO: Redirect to forbidden page
        }

        return throwError(error);
    }
}