import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '../auth/login/login.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Session } from '../shared-kernel/services/usermanager/session';
import { LocalStorageManagerService } from '../shared-kernel/storage/local-storagemanager.service';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../_auth/_providers';
import { StorageConstants } from '../_core/storage.constants';
import { UnitEnum } from '../shared-kernel/enumerations/unit-enum';
import { BusinessUnitEnum } from '../shared-kernel/enumerations/business-unit-enum';
import { Roles } from '../shared-kernel/entity/Roles';
import {Person} from "../_core/data/_models/people.model";

@Component({
  selector: 'app-app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss']
})
export class AppNavComponent implements OnInit {

  title = 'Nigis-Portal';
  view: string;
  page = 'settings';
  settings = 'Settings';
  systemHeader = 'National Integrated Geoscience Information System';
  businessUmitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  expanded = false;
  currentUser: any;
  isAdminVar: boolean;
  currentPerson: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  year: number = new Date().getFullYear();

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: LoginService,
    public userSession: Session,
    private storageManager: LocalStorageManagerService,
    private router: Router,
    private _localStorage: LocalStorage,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities
  ) {

  }

  isAdmin() {
    return this.userSession.checkAdminRights();
  }

  isAdminNew(current){
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {
        if(value.roles.find(x => x.name == Roles.SYSTEM_ADMIN)){
          this.isAdminVar = true;
        }
        else {
          this.isAdminVar = false;
        }
      }
    )
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
        this.currentUser = res;

        this.initPerson(res.id);
    }, err => {
        this._toastr.warning(err.message, "Current User Error");
    },() => {

    });
  }

  onLogout() {
    Swal.fire(<SweetAlertOptions>{
        title: 'Logout',
        type: 'warning',
        html: 'Are you sure you want to <b>logout</b>?',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, logout!'
    }).then(result => {
        if (result.value) {
            this.router.navigate(['auth']);
        }
    });
  }

  onLockSession() {
      //
  }

  setExpanded(bool : boolean){
    this.expanded = bool;
  }

  onLogOut() {
    Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      confirmButtonText: 'Submit',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.authService.attemptLogOut().subscribe(
          (response: any) => {
            if (this.storageManager.tokenManagerRemove() === true) {
              this.router.navigate(['login']);
            } else {
              Swal.fire({
                title: 'Logout Attempt',
                text: 'Logout failed please try again',
                type: 'warning',
                confirmButtonText: 'Ok'
              });
              this.router.navigate(['portal']);
            }
          });
      }
    });
  }

  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
        if (res) {
            this.currentPerson = res;
            this.currentPerson['user'] = this.currentUser;
            this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});
        }
    }, err => {
        this._toastr.error(err.message, "Profile Details");
    },() => {
      this.isAdminNew(this.currentPerson);
    });
  }

  onViewDetails(person: Person) {
    this.router.navigateByUrl(`/portal/personnel/${person.id}`);
  }
}
