export enum ProjectTypeEnum {
    EngineeringGeology = 'Engineering Geology',
    Hydrogeology= 'Hydrogeology',
    Geology= 'Geologist',
    EconomicGeology = 'Economic Geologist',
    SeniorEconomicGeology = 'Senior Economic Geologist',
    Geochemist = 'Geochemist',
    SeniorGeochemist = 'Senior Geochemist',
    MineralEconomist = 'Mineral Economist',
    SeniorMineralEconomist = 'Senior Mineral Economist'
  }
