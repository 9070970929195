import { Injectable } from '@angular/core';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { HttpClient } from '@angular/common/http';
import { PenetrationRateLog } from '../entity/penetrationRate';

@Injectable({
  providedIn: 'root'
})
export class PenetrationRateService {

  constructor(private http: HttpClient) { }

  createPenetrationRateLog(penetrationRateLog: PenetrationRateLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createPenetrationRateLogUrl, penetrationRateLog);
  }

  createPenetrationRateLogs(penetrationRateLogs: PenetrationRateLog[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createPenetrationRateLogListUrl, penetrationRateLogs);
  }

  deletePenetrationRateLog(penetrationRateLog: PenetrationRateLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.deletePenetrationRateLogUrl, penetrationRateLog);
  }

  getAllPenetrationRateLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllPenetrationRateLogsUrl);
  }

  getAllPenetrationRateLogsByBoreholeId(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.getAllPenetrationRateLogsByBoreholeIdUrl + '/' + boreholeId);
  }

  getTotalNumberOfPenetrationLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getTotalNumberOfPenetrationRateLogUrl);
  }

  deletePenetrationRateLogBatch(penetrationRateLogs: PenetrationRateLog[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/penetration-rate-log/delete/batch`, penetrationRateLogs);
  }
}
