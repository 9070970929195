import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Members } from '../../../../../shared-kernel/entity/applied-geoscience/project/members';
import { Project } from '../../../../../shared-kernel/entity/applied-geoscience/project/project';
import { ProjectService } from '../../../project.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-project-detail-member-delete',
  templateUrl: './project-detail-member-delete.component.html',
  styleUrls: ['./project-detail-member-delete.component.scss']
})
export class ProjectDetailMemberDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ProjectDetailMemberDeleteComponent>,private projectService: ProjectService,
              private spinner: NgxSpinnerService,private toast: ToastrService,@Inject(MAT_DIALOG_DATA) public data:any []) { }

  ngOnInit() {
  }

  onDeleteMember() {
    let member = this.data[0] as Members;
    let projectDetails = this.data[1] as Project;

    projectDetails.projectMembers.forEach(value => {
      if(value.id === member.id){
        value.deleted = true;
      }
    });


    this.projectService.createProject(projectDetails).subscribe(
      result => {
      }, error => {
        this.spinner.hide();
        this.toast.error('Updating Project members failed, please try again.', 'Project Members');
      }, () => {
        this.closeModal();
        this.spinner.hide();
        this.toast.success('You have Successfully updated Project members', 'Project Members');
      });
  }


  closeModal() {
    this.dialogRef.close();
  }


}
