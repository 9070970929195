import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minerals-manager-files-vetting-dashboard',
  templateUrl: './minerals-manager-files-vetting-dashboard.component.html',
  styleUrls: ['./minerals-manager-files-vetting-dashboard.component.scss']
})
export class MineralsManagerFilesVettingDashboardComponent implements OnInit {

  nigisFileManagerCountsResponseDto: any
  stillFetchingActivities = true;
  userName: string;

  constructor(
    private userDashBoardService: UserDashboardService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
  }

  ngOnInit() {
    this.getManagerActivitiesCount();
  }

  getManagerActivitiesCount() {
    this.userDashBoardService.getActivitiesCounts('manager').subscribe(value => {
      this.nigisFileManagerCountsResponseDto = value;
      this.getBGIProjectsCount();
    });
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.nigisFileManagerCountsResponseDto.numberOfBGIProjects = number;
      this.getMyProjectsCount();
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.nigisFileManagerCountsResponseDto.numberOfMyProjects = number;
      this.stillFetchingActivities = false;
    });
  }

}
