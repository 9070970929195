import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SoilLog } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log';
import { TrialPit } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { SoilLogService } from '../soil-log.service';

@Component({
  selector: 'app-soil-log-upload',
  templateUrl: './soil-log-upload.component.html',
  styleUrls: ['./soil-log-upload.component.scss']
})
export class SoilLogUploadComponent implements OnInit {

  selectedCvsRecord: SoilLog;
  soilLogs: SoilLog[] = [];
  csvRecords: any[] = [];
  trialPitId: string;
  tabPosition: number;
  loading = true;

  csvDdataSource = new MatTableDataSource(this.soilLogs);
  displayedColumns: string[] = ['Sample Number', 'Depth From', 'Depth To', 'Soil', 'Description', 'Sample Status', 'Comment', 'Recommended Test', 'Delete'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('fileImportInput') fileImportInput: any;

  constructor(
    private toastr: ToastrService,
    private soilLogService: SoilLogService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<SoilLogUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.trialPitId = this.data.trialPitId;
    if(this.data.soilLogList) {
      this.soilLogs = this.data.soilLogList;
      if(this.soilLogs.length<1) {
        this.toastr.error('Upload file is empty', 'Error Occurred');
        this.dialogRef.close(this.soilLogs);
        this.loading = false;
      } else {
        if (this.trialPitId != null) {
          this.soilLogService.getAllSoilLogsByTrialPitId(this.trialPitId).subscribe(data => {
              let soilLogs = data as SoilLog[];
              if (soilLogs.length !== 0) {
                this.toastr.error('Soil Log already has existing records', 'Error Occurred');
                this.dialogRef.close(this.soilLogs);
                this.loading = false;
              } else {
                if (this.displayValidRecords()){
                  this.csvDdataSource = new MatTableDataSource<any>(this.soilLogs);
                  this.csvDdataSource.paginator = this.paginator;
                  this.csvDdataSource.sort = this.sort;
                  this.loading = false;
                } else {
                  this.dialogRef.close(this.soilLogs);
                  this.loading = false;
                }
              }
            });
        }
      }
    } else {
      this.soilLogs = [];
      this.loading = false;
    }
  }

  displayValidRecords() : boolean{
    let valid: boolean;
    for(let i=0; i<this.soilLogs.length; i++){
      let previousSoilLog: SoilLog;;
      let nextSoilLog: SoilLog;

      if (!Number(this.soilLogs[i].depthFrom.measure)) {
        if (Number(this.soilLogs[0].depthFrom.measure) != 0) {
          this.toastr.error('Provide a valid Depth From. Please correct record '+Number(i+1)+'');
          return valid = false;
        }
      }
      if (!Number(this.soilLogs[i].depthTo.measure)) {
        this.toastr.error('Provide a valid Depth To. Please correct record '+Number(i+1)+'');
        return valid = false;
      }
      if (Number(this.soilLogs[i].depthFrom.measure) >= Number(this.soilLogs[i].depthTo.measure)) {
        this.toastr.error('Depth From cannot be bigger than Depth To. Please correct record '+Number(i+1)+'');
        return valid = false;
      }
      if (Number(this.soilLogs[i].depthFrom.measure) >= Number(this.soilLogs[i].depthTo.measure)) {
        this.toastr.error('Depth From cannot be bigger than Depth To. Please correct record '+Number(i+1)+'');
        return valid = false;
      }
      if (i===0){
        nextSoilLog = this.soilLogs[1];
        let nextLogNo = Number(i + 2);
        let currentLogNo = Number(i + 1);
        if (Number(this.soilLogs[0].depthFrom.measure) >= Number(nextSoilLog.depthTo.measure)) {
          this.toastr.error('Depth From cannot be bigger than Depth To('+nextSoilLog.depthTo.measure+'m) of the Next Log, check Record '+currentLogNo+' and '+nextLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[0].depthTo.measure) >= Number(nextSoilLog.depthTo.measure)) {
          this.toastr.error('Depth To cannot be bigger than Depth To('+nextSoilLog.depthTo.measure+'m) of the Next Log, check Record '+currentLogNo+' and '+nextLogNo+'', 'Soil Log Error');
          return valid = false;
        } else {
          valid = true;
        }
      } else if (i===1 && this.soilLogs.length===1){
        previousSoilLog = this.soilLogs[0]
        let previousLogNo  = Number(i);
        let currentLogNo = Number(i + 1);
        if (Number(this.soilLogs[1].depthFrom.measure) <= Number(previousSoilLog.depthFrom.measure)) {
          this.toastr.error('Depth From cannot be less than Depth From('+previousSoilLog.depthFrom.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[1].depthTo.measure) <= Number(previousSoilLog.depthFrom.measure)) {
          this.toastr.error('Depth To cannot be less than Depth From('+previousSoilLog.depthFrom.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else {
          valid = true;
        }
      } else if (i===this.soilLogs.length-1) {
        previousSoilLog = this.soilLogs[i-1]
        let previousLogNo  = Number(i);
        let currentLogNo = Number(i + 1);
        if (Number(this.soilLogs[i].depthFrom.measure) <= Number(previousSoilLog.depthFrom.measure)) {
          this.toastr.error('Depth From cannot be less than Depth From('+previousSoilLog.depthFrom.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[i].depthTo.measure) <= Number(previousSoilLog.depthFrom.measure)) {
          this.toastr.error('Depth To cannot be less than Depth From('+previousSoilLog.depthFrom.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[i].depthFrom.measure) < Number(previousSoilLog.depthTo.measure)) {
          this.toastr.error('Depth From cannot be less than Depth From('+previousSoilLog.depthTo.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else {
          valid = true;
        }
      } else {
        previousSoilLog = this.soilLogs[i - 1]
        nextSoilLog = this.soilLogs[i + 1];
        let previousLogNo  = Number(i);
        let nextLogNo = Number(i + 2);
        let currentLogNo = Number(i + 1);
        if (i===1) {
          previousLogNo = 1;
        }
        if (Number(this.soilLogs[i].depthFrom.measure) >= Number(nextSoilLog.depthTo.measure)) {
          this.toastr.error('Depth From cannot be bigger than Depth To('+nextSoilLog.depthTo.measure+'m) of the Next Log, check Record '+currentLogNo+' and '+nextLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[i].depthTo.measure) >= Number(nextSoilLog.depthTo.measure)) {
          this.toastr.error('Depth To cannot be bigger than Depth To('+nextSoilLog.depthTo.measure+'m) of the Next Log, check Record '+currentLogNo+' and '+nextLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[i].depthFrom.measure) <= Number(previousSoilLog.depthFrom.measure)) {
          this.toastr.error('Depth From cannot be less than Depth From('+previousSoilLog.depthFrom.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[i].depthFrom.measure) < Number(previousSoilLog.depthTo.measure)) {
          this.toastr.error('Depth From cannot be less than Depth From('+previousSoilLog.depthTo.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else if (Number(this.soilLogs[i].depthTo.measure) <= Number(previousSoilLog.depthFrom.measure)) {
          this.toastr.error('Depth To cannot be less than Depth From('+previousSoilLog.depthFrom.measure+'m) of the Previous Log, check Record '+previousLogNo+' and '+currentLogNo+'', 'Soil Log Error');
          return valid = false;
        } else {
          valid = true;
        }
      }
    }
    return valid;
  }

  deleteCsvRecord(soilLog: SoilLog) {
    this.toastr.warning('Still Under Construction', 'Record Deletion');
  }

  saveCsvRecords() {
    this.spinner.show();
    this.soilLogs.forEach(element => {
      element.trialPit = new TrialPit();
      element.trialPit.id = this.trialPitId;
      element.deleted = false;
    });
    this.soilLogService.createBatchSoilLog(this.soilLogs).subscribe(data => {
      this.toastr.success('You have Successfully uploaded Soil Logs for this Trial Pit', 'Upload Soil Logs');
      $('#deleteModal').modal('hide');
      this.spinner.hide();
    }, error => {
      this.toastr.error('An error occurred when trying to uploading soil logs', 'Error Occurred');
      this.spinner.hide();
    });

    this.fileReset();
    $('#csvModal').modal('hide');
  }

  public lessThan(depthFrom: number, depthTo: number) {
    return depthTo < depthFrom;
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }

  fileReset() {
    this.soilLogs = [];
    this.dialogRef.close(this.soilLogs);
  }

  closeModal() {
    this.dialogRef.close(this.soilLogs);
  }

}
