import { Component, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';

@Component({
  selector: 'app-collections-manager',
  templateUrl: './collections-manager.component.html',
  styleUrls: ['./collections-manager.component.scss']
})
export class CollectionsManagerComponent implements OnInit {

  cards :any;
  myProjectCounts = 0;
  numberOfCoreshedActivities = 0;
  stillFetchingMyActiviesCount = true;
  stillFetchingBGIProjectCount = true;
  bgiProjectCounts = 0;
  public customerData:number[] = [];
  public customerLabels:Label[] = [ 'Pending Approval', 'Approved', 'Returned','All Vetted'];
  colors: any  = [{backgroundColor: [ '#00A2E5','#C8A207','#CC0000', '#009900']}];
  public pieChartLegend : boolean= true;

  constructor(private _router:Router,private breakpointObserver: BreakpointObserver,private userDashBoardService: UserDashboardService) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'Vetting Portfolio - Customer Requests', cols: 1, rows: 3 , id: 'myactivities'},
          { title: '', cols: 1, rows: 3 ,id: 'districts'},

        ];
      })
    );
  }

  ngOnInit() {
      this.getBGIProjectsCount();
      this.getMyProjectsCount();
      this.getUserRequestActivitiesManagerPendingCount();
  }

  getUserRequestActivitiesCount(){
    this.userDashBoardService.getUserRequestActivitiesCount('all', 'request').subscribe(number => {
      this.numberOfCoreshedActivities = number;
      this.stillFetchingMyActiviesCount = false;
    });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.myProjectCounts = number;
      this.getUserRequestActivitiesCount();
    });
  }
  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.bgiProjectCounts = number;
      this.stillFetchingBGIProjectCount = false;
    });
  }

  chartClickedCustomer(event){
    switch (event.active[0]._view.label) {
      case this.customerLabels[0]:
        this._router.navigate(['portal/activities/archivist/manager/Pending']);
        break
      case this.customerLabels[1]:
        this._router.navigate(['portal/activities/archivist/manager/Approved']);
        break
      case this.customerLabels[2]:
        this._router.navigate(['portal/activities/archivist/manager/Returned']);
        break
      case this.customerLabels[3]:
        this._router.navigate(['portal/activities/archivist/manager/All%20Vetted']);
        break
      default:
    }
  }
  numberOfRequestActivitiesManagerPending: number = 0;
  numberOfRequestActivitiesManagerApproved: number = 0;
  numberOfRequestActivitiesManagerReturned: number = 0;
  numberOfRequestActivitiesManagerAllVetted: number = 0;
  stillFetchingnumberOfRequestActivitiesManagerAllVettedCount = true;

  getUserRequestActivitiesManagerPendingCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('Pending').subscribe(number => {
      this.numberOfRequestActivitiesManagerPending = number;
      this.getUserRequestActivitiesManagerApprovedCount();
    });
  }

  getUserRequestActivitiesManagerApprovedCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('Approved').subscribe(number => {
      this.numberOfRequestActivitiesManagerApproved = number;
      this.getUserRequestActivitiesManagerReturnedCount();
    });
  }

  getUserRequestActivitiesManagerReturnedCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('Returned').subscribe(number => {
      this.numberOfRequestActivitiesManagerReturned = number;
      this.getUserRequestActivitiesManagerAllVettedCount();
    });
  }

  getUserRequestActivitiesManagerAllVettedCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('All Vetted').subscribe(number => {
      this.numberOfRequestActivitiesManagerAllVetted = number;
      this.stillFetchingnumberOfRequestActivitiesManagerAllVettedCount = false;
      this.customerData = [this.numberOfRequestActivitiesManagerPending,this.numberOfRequestActivitiesManagerApproved,
        this.numberOfRequestActivitiesManagerReturned,this.numberOfRequestActivitiesManagerAllVetted];
    });
  }


  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

}
