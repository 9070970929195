import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {PlDrillerLogService} from '../../../services/pl-driller-log.service';

@Component({
  selector: 'app-pl-borehole-drillers-log-create',
  templateUrl: './pl-borehole-drillers-log-create.component.html',
  styleUrls: ['./pl-borehole-drillers-log-create.component.scss']
})
export class PlBoreholeDrillersLogCreateComponent implements OnInit {

  logForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    date: new FormControl(null, Validators.required),
    depthFrom: new FormControl(null, Validators.required),
    depthTo: new FormControl(null, Validators.required),
    description: new FormControl(null),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<PlBoreholeDrillersLogCreateComponent>,
              private toast: ToastrService, private plService: PlDrillerLogService) {
  }

  /**
   * Close mat-dialog and send back some data to the calling parent.
   * @param actions - json object format
   * @return e.g {event:'reset': data:'data_object' }
   */
  onClose(actions: any) {
    this.dialogRef.close(actions);
  }

  ngOnInit() {
    // -- In case of edit, populate the form with log details --
    if (this.data.drillerLog) {
      this.logForm.setValue({
        'id': this.data.drillerLog.id,
        'date': new Date(this.data.drillerLog.date),
        'depthTo': this.data.drillerLog.depthTo,
        'depthFrom': this.data.drillerLog.depthFrom,
        'description': this.data.drillerLog.description
      });
    }
  }

  onSubmit() {
    let log = null;
    if (this.data.drillerLog) {
      log = this.data.drillerLog;
      log.date = this.logForm.value.date;
      log.depthTo = this.logForm.value.depthTo;
      log.depthFrom = this.logForm.value.depthFrom;
      log.description = this.logForm.value.description;

    } else {
      log = this.logForm.value;
      log.id = '';
      log['borehole'] = this.data.borehole;
    }

    this.plService.createPLDrillerLog(log).subscribe(data => {
      if (this.data) {
        if (!log.id) {
          this.toast.success('PL driller`s log created successfully', 'Create PL driller`s log');
        } else {
          this.toast.success('PL driller`s log updated successfully', 'Update PL driller`s log');
        }
        // this.onClose(null);
        this.dialogRef.close({event: 'Success', dialogData: data});
      }
    }, error => {
      this.toast.error('An error occurred when trying to create PL driller`s log', 'PL driller`s log');
    });
  }

}

