import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

import { QaDashboardService } from './qa-dashboard.service';
import { UserService } from 'src/app/auth/user/user.service';
import { Person } from 'src/app/_core/data/_models/people.model';
import { GlobalDashboardService } from '../global-dashboard.service';
import { VettingPortfolioCounts } from '../model/VettingPortfolioCounts';
import { Roles } from '../../shared-kernel/entity/Roles';
import { StorageConstants } from '../../_core/storage.constants';
import { LocalAuthorities } from '../../_auth/_providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qa-dashboard',
  templateUrl: './qa-dashboard.component.html',
  styleUrls: ['./qa-dashboard.component.scss']
})
export class QaDashboardComponent implements OnInit {

  qaDashboardCounts :any;
  isNonBGIQAVar: boolean;

  isNonBGIMANAGERVar: boolean;
  numberOfBGIProjects: number = 0;
  numberOfMyProjects: number = 0;
  numberOfActivitiesSubmittedToQa: number = 0;
  numberOfRejectedByQA: number = 0;
  numberOfApprovedByQA: number  = 0;
  numberOfMyPLs: number = 0;
  numberOfPLs: number = 0;
  numberOfMyPlBoreholes:number = 0;
  numberOfNonPlBoreholes:number = 0;
  numberOfUserTrialPits:number = 0;
  private userName: string;
  private userReference: string;
  private authorities;
  currentUser: any;
  currentPerson: any;
  private roles;
  person: Person;
  qaDashboardCountModel: VettingPortfolioCounts ;
  nonbgiqaDashboardCountModel: VettingPortfolioCounts ;

  stillLoadingActivities = true;
  myactivities = {
    numberOfPendingActivities:0,
    numberOfReturnedActivities:0,
    numberOfActivitiesSubmittedToManager:0,
    numberOfAllVetted:0,
  };


  constructor(
    private _router: Router,
    private qaDashBoardService: QaDashboardService,
    private userService: UserService,
    private managerDashBoardService: GlobalDashboardService,
    private _localStorage: LocalStorage,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private jwtHelper: JwtHelperService) {
      const data = this.jwtHelper.decodeToken(localStorage.getItem('token'));
      this.userName = data.sub;
      this.userReference = data.jti;
      this.authorities = data.userAuthorises;
      this.roles = data.roles;
      this.userService.getPersonByUserId(this.userReference)
        .subscribe(data => {
          this.person = data as Person;
        });
  }

  ngOnInit() {
    this.stillLoadingActivities = true;
    this.getBGIProjectsCount()
    this.getMyProjectActivities()
    this.getMyProjectsCount()
    this.getPLsCount()
    this.getMyPLsCount();
    this.getMyPlBoreholesCount();
    this.getNonPlBoreholesCount();
    this.getUserTrialPitCount();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
    }, err => {
      this._toastr.warning(err.message, "Current User Error");
    },() => {

    });


  }

  private initPerson(userId: string) {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    }, () => {
      (async () => {
        await this.isNonBGI(this.currentPerson);
      })();
    });
  }

  isNonBGI(current){
    let bgiPendingApproval = 0;
    let bgiReturned = 0;
    let bgiSubmittedToManager = 0;
    let bgiAllVetted = 0;

    let nonBgiPendingApproval = 0;
    let nonBgiReturned = 0;
    let nonBgiSubmittedToManager = 0;
    let nonBgiAllVetted = 0;

    (async () => {
      await this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {

        if(value.roles.find(x => x.name.toLowerCase() === Roles.NONBGIQA.toLowerCase())){
          (async () => {
            await this.managerDashBoardService.getNonBgiQADashboardCounts().subscribe(value => {
              this.nonbgiqaDashboardCountModel = value as VettingPortfolioCounts;
              nonBgiPendingApproval = Number(this.nonbgiqaDashboardCountModel.pendingApproval);
              nonBgiReturned = Number(this.nonbgiqaDashboardCountModel.returned);
              nonBgiSubmittedToManager = Number(this.nonbgiqaDashboardCountModel.approved);
              nonBgiAllVetted = Number(this.nonbgiqaDashboardCountModel.allVetted);

              this.myactivities.numberOfPendingActivities = nonBgiPendingApproval + bgiPendingApproval;
              this.myactivities.numberOfReturnedActivities = nonBgiReturned + bgiReturned;
              this.myactivities.numberOfActivitiesSubmittedToManager = nonBgiSubmittedToManager + bgiSubmittedToManager;
              this.myactivities.numberOfAllVetted = nonBgiAllVetted + bgiAllVetted;

              this.stillLoadingActivities = false;
            })
          })();
        }

        if(value.roles.find(x => x.name.toLowerCase() === Roles.QA.toLowerCase())){
          (async () => {
            await this.managerDashBoardService.getQADashboardCounts().subscribe(value => {
              this.qaDashboardCountModel = value as VettingPortfolioCounts;

              bgiPendingApproval = Number(this.qaDashboardCountModel.pendingApproval);
              bgiReturned = Number(this.qaDashboardCountModel.returned);
              bgiSubmittedToManager = Number(this.qaDashboardCountModel.approved);
              bgiAllVetted = Number(this.qaDashboardCountModel.allVetted);

              this.myactivities.numberOfPendingActivities = nonBgiPendingApproval + bgiPendingApproval;
              this.myactivities.numberOfReturnedActivities = nonBgiReturned + bgiReturned;
              this.myactivities.numberOfActivitiesSubmittedToManager = nonBgiSubmittedToManager + bgiSubmittedToManager;
              this.myactivities.numberOfAllVetted = nonBgiAllVetted + bgiAllVetted;
              this.stillLoadingActivities = false;
            });
          })();
        }
      }
    )
    })();
  }

  getMyProjectsCount(){
    this.qaDashBoardService.getMyProjectCount().subscribe(number => {
      this.numberOfMyProjects = number;
    });
  }

  getBGIProjectsCount(){
    this.qaDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
    });
  }

  getMyProjectActivities(){}

  getPLsCount(){
    this.qaDashBoardService.getPLsCount().subscribe(number => {
      this.numberOfPLs = number;
  });
  }
  getMyPLsCount(){
    this.qaDashBoardService.getMyPLsCount(this.userName).subscribe(number => {
      this.numberOfMyPLs = number;
  });
}
getMyPlBoreholesCount(){
  this.qaDashBoardService.getMyPlBoreholesCount(this.userName).subscribe(number => {
    this.numberOfMyPlBoreholes = number;
});
}

getNonPlBoreholesCount(){
  this.qaDashBoardService.getNonPlBoreholesCount(this.userName).subscribe(number => {
    this.numberOfNonPlBoreholes = number;

});
}

getUserTrialPitCount(){
  this.qaDashBoardService.getUserTrialPitCount(this.userName).subscribe(number => {
    this.numberOfUserTrialPits = number;
});
}

getNumberOfQaActivitiesPendingApproval(){
  this.qaDashBoardService.getNumberOfQaActivitiesPendingApproval().subscribe(number => {
    this.numberOfActivitiesSubmittedToQa = number;
  })
}

getNumberOfQaRejected(){
  this.qaDashBoardService.getNumberOfQaRejected().subscribe(number => {
    this.numberOfRejectedByQA = number;
  })
}

getNumberOfQaApproved(){
  this.qaDashBoardService.getNumberOfQaApproved().subscribe(number => {
    this.numberOfApprovedByQA= number;
  })
}

  toNum (x: string): number {
    return parseInt(x) ;
  }



goTo(uri: string) {
    let urlParams: any;
    if('qa_activities_approved'=== uri){
      urlParams = { id: 'activities_approved' };
    }
    else if('qa_activities_vetted'=== uri){
      uri = 'qa_activities_approved';
      urlParams = { id: 'activities_vetted' };
    }
    else {
      urlParams= {};
    }

    this._router.navigate([`portal/activities/${uri}`,urlParams]);
}

}
