import { Size } from '../../common/size';
import { Notes } from '../notes';

export class WaterObservationBorehole {
  id: string;
  waterConstantRateTest: string;
  boreholeNumber: string;
  pumpType: string;
  depthToWater: Size = new Size();
  startDate: Date;
  endDate: Date;
  staticWaterLevel: string
  drawdown: Size = new Size();
  startTime: Size = new Size();
  endTime: Size = new Size();
  pumpingRate: Size = new Size();
  timeToFillLiters: Size = new Size();
  timeToFillTime:string;
  tds: Size = new Size();
  temperature: Size = new Size();
  ph: Size = new Size();
  electricalConductivity: Size = new Size();
  geologist: string;
  comments: Notes = new Notes();
  creator: string;
  created: Date;
}
