import { Injectable } from '@angular/core';
import { TimeAndMotion } from './entity/timeandmotion';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';

@Injectable({
  providedIn: 'root'
})
export class TimeAndMotionService {

  constructor(private http: HttpClient) {
  }

  getAllTimeAndMotionRecords() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllTimeAndMotionRecordsUrl);
  }

  getTimeAndMotionById(timeandmotionId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getTimeAndMotionRecordUrl+'/'+timeandmotionId+'/detail-view');
  }


  getAllByBorehole(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllTimeAndMotionRecordsByBoreholeUrl + '/'
    + boreholeId);
  }

  createTimeAndMotionRecord(timeAndMotion: TimeAndMotion) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createTimeAndMotionRecordUrl, timeAndMotion);
  }

  createTimeAndMotionRecordBatch(timeAndMotion: TimeAndMotion []) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createTimeAndMotionRecordBatchUrl, timeAndMotion);
  }

  deleteTimeAndMotion(timeAndMotion: TimeAndMotion) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteTimeAndMotionRecordUrl, timeAndMotion);
  }
}
