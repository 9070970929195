import {Component, Inject, OnInit} from '@angular/core';
import {Session} from '../../shared-kernel/services/usermanager/session';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Person, Honorific, IdType, Sex, ContactDetail,Kin,PostalAddress} from 'src/app/_core/data/_models/people.model';
import {CountryData} from 'src/app/_core/data/country';
import {Country, District} from 'src/app/_core/data/_models';
import {PeopleData} from 'src/app/_core/data/people';
import {ContactDetailTypeEnum} from '../../shared-kernel/enumerations/contact-detail-type-enum';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss']
})
export class EditUserProfileComponent implements OnInit {
  person: Person;
  email: string = '';
  submitted: boolean = false;
  addNextOfKin: boolean = false;


  isLinear: boolean = true;

  minDate = new Date(1910, 0, 1);
  maxDate = new Date(Date.now());

  countries: Array<Country>;
  titles: Array<Honorific>;
  idTypes: Array<IdType>;
  gender: Array<Sex>;
  districts: Array<District>;

  constructor(public userSession: Session, private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<EditUserProfileComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Person,
              private toastr: ToastrService,
              private countryData: CountryData,
              private peopleData: PeopleData) {
  }


  personalForm: FormGroup;
  contactForm: FormGroup;
  nextOfKinForm: FormGroup;
  contactEnum: typeof ContactDetailTypeEnum = ContactDetailTypeEnum;

  ngOnInit() {
    this.getCountries();
    this.getTitles();
    this.getIdTypes();
    this.getGender();
    this.getDistricts();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  compareFn(object1: any, object2: any): boolean {
    return object1 && object2 ? object1.id === object2.id : (object1 === object2);
  }

  compareRadioButtonValues(objects: Honorific [], object2: Honorific): Honorific {
    return objects.find(obj => obj.id === object2.id);
  }

  compareContactDetailsValues(objects: ContactDetail [], contactType:string) {
    return objects.find(obj => obj.contactDetailType.id === contactType);
  }

  private initForms() {

    this.personalForm = this.formBuilder.group({
      title: [this.compareRadioButtonValues(this.titles, this.data.title), Validators.required],
      name: [this.data.name, Validators.required],
      surname: [this.data.surname, Validators.required],
      dateOfBirth: [this.data.dateOfBirth, Validators.required],
      countries: [this.data.countries, Validators.required],
      idType: [this.data.idType, Validators.required],
      idNumber: [this.data.idNumber, Validators.required],
      workIdNumber: [this.data.workIdNumber, Validators.required],
      profession: [this.data.profession, Validators.required],
      sex: [this.data.sex, Validators.required]
    });

      if(this.data.postalAddress){

      if(this.data.contactDetails){
        this.contactForm = this.formBuilder.group({
          emailAddress: [this.compareContactDetailsValues(this.data.contactDetails,this.contactEnum.Email).address, [Validators.required, Validators.email]],
          telephone: [this.compareContactDetailsValues(this.data.contactDetails,this.contactEnum.Telephone).address, [Validators.required]],
          boxNumber: [this.data.postalAddress.boxNumber, Validators.required],
          townVillage: [this.data.postalAddress.cityTown, Validators.required],
          country: [this.data.postalAddress.country, Validators.required],
          district: [this.data.postalAddress.district, Validators.required]
        });
      }
      else{
        this.contactForm = this.formBuilder.group({
          emailAddress: ['', [Validators.required, Validators.email]],
          telephone: ['', [Validators.required]],
          boxNumber: [this.data.postalAddress.boxNumber, Validators.required],
          townVillage: [this.data.postalAddress.cityTown, Validators.required],
          country: [this.data.postalAddress.country, Validators.required],
          district: [this.data.postalAddress.district, Validators.required]
        });
      }

    } else {
      this.contactForm = new FormGroup({
        emailAddress : new FormControl('', [Validators.required, Validators.email]),
        telephone: new FormControl('', [Validators.required]),
        boxNumber: new FormControl('', Validators.required),
        townVillage: new FormControl('', Validators.required),
        country: new FormControl(null, Validators.required),
        district: new FormControl(null, Validators.required)
      });
    }

    if (this.data.kin) {
      this.nextOfKinForm = this.formBuilder.group({
        cellNumber: [this.data.kin.cellNumber, [Validators.required]],
        emailAddress: [this.data.kin.emailAddress, [Validators.required, Validators.email]],
        homeNumber: [this.data.kin.homeNumber, [Validators.required]],
        name: [this.data.kin.name, [Validators.required, Validators.minLength(2)]],
        surname: [this.data.kin.surname, [Validators.required, Validators.minLength(2)]],
        relationship: [this.data.kin.relationship, [Validators.required, Validators.minLength(2)]]
      });
    } else {
      this.nextOfKinForm = this.formBuilder.group({
        cellNumber: ['', [Validators.required]],
        emailAddress: ['', [Validators.required, Validators.email]],
        homeNumber: ['', [Validators.required]],
        name: ['', [Validators.required, Validators.minLength(2)]],
        surname: ['', [Validators.required, Validators.minLength(2)]],
        relationship: ['', [Validators.required, Validators.minLength(2)]]
      });
    }
  }


  private getCountries() {
    this.countryData.getCountries().subscribe(res => {
      this.countries = res;
    }, err => {
      this.toastr.warning(err.message, 'Failed to get countries');
    });
  }

  private async getTitles() {
    await this.peopleData.getTitles().subscribe(res => {
      this.titles = res as Honorific [];
      this.initForms();

    }, err => {
      this.toastr.warning(err.message, 'Failed to get titles');
    });
  }

  private getIdTypes() {
    this.peopleData.getIdTypes().subscribe(res => {
      this.idTypes = res;
    }, err => {
      this.toastr.warning(err.message, 'Failed to get ID types');
    });
  }

  private getGender() {
    this.peopleData.getGender().subscribe(res => {
      this.gender = res;
    }, err => {
      this.toastr.warning(err.message, 'Failed to get gender types');
    });
  }

  private getDistricts() {
    this.countryData.getDistricts().subscribe(res => {
      this.districts = res;
    }, err => {
      this.toastr.warning(err.message, 'Failed to get districts');
    });
  }


  onSubmit() {
    const personalInfo = this.personalForm.value;
    const contactInfo = this.contactForm.value;
    const kinInfo =  this.addNextOfKin === true ? this.nextOfKinForm.value : null;
    let person = this.data as Person;
    for (let key in personalInfo) person[key] = personalInfo[key];

      person.contactDetails = [
        {
          address: contactInfo.emailAddress,
          contactDetailType: {
            id: '1',
            creator: null,      // TODO: Get current user
            created: new Date(),
            updater: null,      // TODO: Get current user
            updated: new Date(),
            reference: null,
            name: 'Email'
          }
        }
          ,
        {
          address: contactInfo.telephone,
          contactDetailType: {
            id: '3',
            creator: null,      // TODO: Get current user
            created: new Date(),
            updater: null,      // TODO: Get current user
            updated: new Date(),
            reference: null,
            name: 'Cellphone Number'
          }
        }];

    person.postalAddress = {
      boxNumber: contactInfo.boxNumber,
      cityTown: contactInfo.townVillage,
      country: contactInfo.country,
      district: contactInfo.district
    }

    person.kin = kinInfo;

    this.peopleData.editUser(person).subscribe(res => {
        this.submitted = false;
        this.dialogRef.close(res);
      }, err => {
        this.toastr.error(err.message, 'Edit User');
        this.submitted = false;
      },()=>{
      this.toastr.success('The user was successfully edited','Edit User')
      }
    );
  }

}
