import {Component, OnInit} from '@angular/core';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { WaterPumpingTestCalibration } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestCalibration';
import {Router, ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import jsPDF from 'jspdf';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';



@Component({
  selector: 'app-water-pump-test-calibration-view',
  templateUrl: './water-pump-test-calibration-view.component.html',
  styleUrls: ['./water-pump-test-calibration-view.component.scss']
})
export class WaterPumpTestCalibrationViewComponent implements OnInit {
  waterPumpingTestCalibration: WaterPumpingTestCalibration;
  waterPumpingTestCalirationId: any;
  calibrationDetails: any;
  expanded: boolean = true;


  constructor(
    private hydrogeologyService: HydrogeologyService,
    private router: Router,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private spinner: NgxSpinnerService) {
      this._route.params.subscribe(params => {
        this.waterPumpingTestCalirationId = params.id;
      });
  }

  ngOnInit() {

    this.getWaterPumpingTestCalibrationDetails();
  }

  getWaterPumpingTestCalibrationDetails()
  {
    this.hydrogeologyService.getWaterPumpingTestCalibrationById(this.waterPumpingTestCalirationId).subscribe(value => {
      this.calibrationDetails = value as WaterPumpingTestCalibration;
    });
  }
  redirect(){
    this.router.navigate(['portal/applied-geoscience/geo-tech/water-borehole/list']);
  }


  exportToPDF() {
    const doc = new jsPDF();
  }
}
