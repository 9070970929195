import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../_core/data/_models/people.model';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerRequestActivity } from '../../../activities/models';
import { CollectionsState } from '../../../activities/models/CollectionsState';
import { Person } from '../../../common/entity/security/profile/person';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { GlobalDashboardService } from '../../global-dashboard.service';
import { UserService } from '../../../auth/user/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-collection-geological-specimen-vetting',
  templateUrl: './collection-geological-specimen-vetting.component.html',
  styleUrls: ['./collection-geological-specimen-vetting.component.scss']
})
export class CollectionGeologicalSpecimenVettingComponent implements OnInit {


  loading = true;
  isSet = true;
  public currentUser: User;
  displayedColumns: Array<string> = ['No', 'request_date', 'service_requested', 'status'];
  geologicalSpecimenColumns: Array<string> = ['No', 'accessionNumber', 'specimenIdentification','status','currentState'];
  public _requestsDataSource: MatTableDataSource<CustomerRequestActivity> = new MatTableDataSource<CustomerRequestActivity>();
  public geologicalSpecimenDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public geologicalSpecimenDataSource1: MatTableDataSource<any> = new MatTableDataSource<any>();
  public geologicalSpecimenDataSource2: MatTableDataSource<any> = new MatTableDataSource<any>();
  activities: Array<CustomerRequestActivity>;
  @Input() filter: string;

  cards: any;
  state = new CollectionsState();

  public myactivitiesLabels = [ 'Pending Approval', 'Submitted-Manager', 'Returned'];
  public myactivitiesData = [ 107,89, 73];

  public pieChartType = 'pie';
  userName: string;
  userReference: string;
  person: Person;
  stillFetchingMyProjectCount = true;
  numberOfMyProjects: number = 0;


  numberOfMyActivities: number = 0;

  numberOfBGIProjects: number = 0;
  numberOfAllVetted: number = 0;
  stillFetchingBGIProjectCount = true;

  stillFetchingAllVetted = true;

  numberOfRequestActivities: number = 0;
  stillFetchingrequestActiviesCount = true;

  numberOfSpecimenRequestActivitiesSubmittedToManager = 0;
  stillFetchingSpecimenRequestActivitiesSubmittedToManagerCount = true;

  numberOfSpecimenRequestActivitiesRejectedByManager = 0;
  stillFetchingSpecimenRequestActivitiesRejectedByManagerCount = true;

  numberOfSpecimenRequestActivitiesApproved = 0;
  stillFetchingSpecimenRequestActiviesApprovedCount = true;

  numberOfSpecimenRequestActivitiesDrafts = 0;
  stillFetchingSpecimenRequestActiviesDraftsCount = true;

  activitiesCount :any;

  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  colors: any = [{
    backgroundColor: ['#00A2E5','#009900', '#CC0000']
  }];

  constructor(private breakpointObserver: BreakpointObserver,
              private _router: Router,
              private _toastr: ToastrService,
              private userDashBoardService: UserDashboardService,
              private globalDashboardService: GlobalDashboardService,
              private userService: UserService) {
    const jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({matches}) => {
        return [
          {title: '', cols: 2, rows: 1, id: 'cards'},
          {title: 'My Activities', cols: 1, rows: 3, id: 'myactivities'},
          {title: 'Pending Approval', cols: 1, rows: 3, id: 'pending'},
          {title: 'Submitted-Manager', cols: 1, rows: 3, id: 'submitted'},
          {title: 'Returned', cols: 1, rows: 3, id: 'returned'}
        ];
      })
    );

  }

  ngOnInit() {
    this.getBGIProjectsCount();
    this.getActivityCount();
    this.getApprovalRecentData();
  }


  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
      this.stillFetchingBGIProjectCount = false;
    });
  }

  getActivityCount(){
    this.userDashBoardService.getGeologicalSpecimenApprovalActivityCounts().subscribe(value => {

      this.myactivitiesData = [ value.seniorSubmitted,value.managerSubmitted, value.returned];
    });
  }

  getApprovalRecentData(){
    this.userDashBoardService.getGeologicalSpecimenApprovalActivity().subscribe(value => {
      this.geologicalSpecimenDataSource = new MatTableDataSource<any>(value.pendingApproval);
      this.geologicalSpecimenDataSource1 = new MatTableDataSource<any>(value.submittedManager);
      this.geologicalSpecimenDataSource2 = new MatTableDataSource<any>(value.returned);
      this.loading = false;
    });
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      case 'non_bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'non_bgi_project' }]);
        break
      default:
    }
  }

  chartClickedActivities(event) {
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this.gotoPendingApprovalList();
        break;
      case this.myactivitiesLabels[1]:
        this.gotoSubmittedToManagerList();
        break;
      case this.myactivitiesLabels[2]:
        this.gotoReturnedFromSeniorList();
        break;
      default:
    }
  }

  gotoPendingApprovalList(){
    this._router.navigate([`portal/activities/geological_specimen/senior/PENDING_MANAGER_SUBMISSION`]);
  }

  gotoSubmittedToManagerList(){
    this._router.navigate([`portal/activities/geological_specimen/senior/APPROVED_SENIOR`]);
  }

  gotoReturnedFromSeniorList(){
    this._router.navigate([`portal/activities/geological_specimen/senior/REJECTED`]);
  }

  gotoAllVettedList(){
    this._router.navigate([`portal/activities/geological_specimen/senior/ALL_VETTED_SENIOR`]);
  }


}
