export const environment = {
    name: 'Inc BGI Quality Assurance',
    production: false,
    authBaseUrl: 'https://nigis-bgiqa.bitri-ist.co.bw/gateway/bitri-security',
    appliedGeoscienceBaseUrl: 'https://nigis-bgiqa.bitri-ist.co.bw/gateway/applied-geo-science',
    collectionsBaseUrl: 'https://nigis-bgiqa.bitri-ist.co.bw/gateway/collections',
    integrationBaseUrl: 'https://nigis-bgiqa.bitri-ist.co.bw/gateway/nigis-integration',
    routes: {
        enableTracing: false
    }
};
