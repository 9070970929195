import { MineralResourcesModule } from './../mineral-resources.module';
import { NgModule } from '@angular/core';
import { samplesRouting } from './samples.routing';
import { SharedModule } from './../../shared/shared.module';
import { SamplesComponent } from './samples.component';
import { SamplesListComponent } from './samples-list/samples-list.component';
import { SamplesCreateComponent } from './samples-create/samples-create.component';
import { SamplesDetailComponent } from './samples-detail/samples-detail.component';
import { TechnicianSampleCreateComponent } from './technician/technician-sample-create/technician-sample-create.component';
import { TechnicianSampleListComponent } from './technician/technician-sample-list/technician-sample-list.component';
import { TechnicianSampleDetailComponent } from './technician/technician-sample-detail/technician-sample-detail.component';
import { SampleSubmissionCreateComponent } from './sample-submission-create/sample-submission-create.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [
        SamplesComponent,
        SamplesListComponent,
        SamplesCreateComponent,
        SamplesDetailComponent,
        SamplesCreateComponent,
        TechnicianSampleCreateComponent,
        TechnicianSampleListComponent,
        TechnicianSampleDetailComponent,
        SampleSubmissionCreateComponent,
    ],
    imports: [
        SharedModule,
        samplesRouting,
        MineralResourcesModule,
        MatSlideToggleModule,

    ],
    entryComponents: [
        SamplesCreateComponent,
        TechnicianSampleCreateComponent,
        SampleSubmissionCreateComponent,
    ],
  exports: [
    SamplesListComponent,
    TechnicianSampleCreateComponent,
    TechnicianSampleListComponent
  ]
})
export class SamplesModule {
}
