import { Department } from './department';

export class BusinessUnit {

    public id?: string;
    public name?: string;
    public description?: string;
    public department?: Department;

    constructor() {  }
}
