import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {

  contact: FormGroup = new FormGroup({
    id: new FormControl(''),
    contactDetailType: new FormControl(this.data.contactDetailType)
  });

  constructor(private dialogRef: MatDialogRef<AddContactComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    /**
     * Validate email address */
    if (this.data.contactDetailType.name === 'Email') {
      this.contact.addControl('address', new FormControl('', Validators.email));
    } else {
      this.contact.addControl('address', new FormControl(''));
    }
  }

  onClose() {
    this.dialogRef.close(null);
  }

  onSubmit() {
    this.dialogRef.close(this.contact.value);
  }
}
