import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeismologyService implements OnInit {

  constructor( private http: HttpClient) {

  }

  ngOnInit(): void {
  }

  createBulletin(bulletin): Observable<any> {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBulletinUrl, bulletin);
  }

  getAllBulletins() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBulletinsUrl);
  }
}
