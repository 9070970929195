export enum UnitEnum {

  millimeter = '1',
  centimeter = '2',
  meter = '3',
  gram = '4',
  killogram = '5',
  milliliter = '6',
  liter = '7',
  milligram = '8',
  mmblow = '9',
  set = '10',
  pumpRate = '11',
  temperature = '12',
  electricalConductivity = '13',
  manometer = '14',
  inch ='15',
  blowyield = '16',
  waterlevel = '17',
  ph ='18',
  yield='19',
  ec='20',
  TDS = '21'

}
