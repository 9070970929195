import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { ApprovalState } from './../../../applied-geoscience/models/approval-state';
import { Component, OnInit } from '@angular/core';
import { VettingPortfolioCounts } from '../../model/VettingPortfolioCounts';

@Component({
  selector: 'app-minerals-qa-vetting-dashboard',
  templateUrl: './minerals-qa-vetting-dashboard.component.html',
  styleUrls: ['./minerals-qa-vetting-dashboard.component.scss']
})
export class MineralsQaVettingDashboardComponent implements OnInit {

  stillFetchingBGIProjectCount = true;
  stillFetchingMyProjectCount = true;
  stillFetchingMyActivitiesCount = true;
  stillFetchingDrafts = true;
  stillFetchingSubmittedToQA = true;
  stillFetchingSubmittedToManager = true;
  stillFetchingRejectedByQA = true;
  stillFetchingRejectedByManager = true;
  stillFetchingApproved = true;
  stillFetchingAllVetted = true;

  qaDashboardCountModel: VettingPortfolioCounts ;
  nonbgiqaDashboardCountModel: VettingPortfolioCounts ;
  
  activitiesCount = {
    myActivities: 0,
    drafts: 0,
    submittedToQA: 0,
    submittedToManager: 0,
    returnedByQA: 0,
    returnedByManager: 0,
    approved: 0,
    numberOfBGIProjects: 0,
    numberOfMyProjects: 0,
    allVetted:0
    }

    approvalEnum: typeof ApprovalState = ApprovalState;
    userName: string;



  constructor(private userDashBoardService: UserDashboardService) { }

  
  ngOnInit() {
    this.getSubmittedToQA();
    this.getUserActivities();
    this.getDrafts();
    this.getSubmittedToManager();
    this.getActivitiesRejectedByQA();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
    this.getAllVetted();
  
  }


  getUserActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount('all').subscribe(number => {
      this.activitiesCount.myActivities = number;
      this.stillFetchingMyActivitiesCount = false;
    });
  }

  getDrafts(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(number => {
      this.activitiesCount.drafts = number;
      this.stillFetchingDrafts = false;
    });
  }

  getSubmittedToQA(){
      this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
        this.activitiesCount.submittedToQA = number;
        this.stillFetchingSubmittedToQA = false;
      });
  }
  getAllVetted(){
    this.userDashBoardService.getMyActivitiesMineralResourcesQAllVettedCount().subscribe(number => {
      this.activitiesCount.allVetted = number;
      this.stillFetchingAllVetted = false;
    });
}

  getSubmittedToManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.activitiesCount.submittedToManager = number;
      this.stillFetchingSubmittedToManager = false;
    });
  }

  getActivitiesRejectedByQA(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.activitiesCount.returnedByQA = number;
      this.stillFetchingRejectedByQA = false;
    });
  }

  getActivitiesRejectedByManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.activitiesCount.returnedByManager = number;
      this.stillFetchingRejectedByManager = false;
    });
  }

  getApprovedActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQACount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.activitiesCount.approved = number;
      this. stillFetchingApproved = false;
    });
  }

}
