import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GeoTechService } from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import { SoilName } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/SoilName';
import { Notes } from 'src/app/shared-kernel/entity/applied-geoscience/notes';
import { SoilLog } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log';
import { TrialPit } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { Unit } from 'src/app/shared-kernel/entity/common/unit';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { SoilLogCreateComponent } from '../soil-log-create/soil-log-create.component';
import { SoilLogUploadComponent } from '../soil-log-upload/soil-log-upload.component';
import { SoilLogService } from '../soil-log.service';

@Component({
  selector: 'app-soil-log-table',
  templateUrl: './soil-log-table.component.html',
  styleUrls: ['./soil-log-table.component.scss']
})
export class SoilLogTableComponent implements OnInit {

  dataSourceSoilLogs: MatTableDataSource<SoilLog> = new MatTableDataSource();
  selectedRecord: SoilLog = new SoilLog();
  displayedSoilLogColumns = ['#', 'Depth From', 'Depth To', 'Soil', 'Description', 'View', 'Edit', 'Delete'];
  csvDdataSource: MatTableDataSource<SoilLog> = new MatTableDataSource();

  @Input('soilLogs') soilLogs: SoilLog[] = [];
  @Input('trialPitId') trialPitId: string = '';
  @Input('trialPit') trialPit: TrialPit;
  soilLogCreate: SoilLog;
  soilLogEdit: SoilLog;
  soilLog: SoilLog;
  previous_depthTo: any;
  csvRecords: any[] = [];
  loading = true;
  enumUnits: typeof UnitEnum = UnitEnum;
  soilNames: SoilName[] = [];
  errorInRecord = false;

  dialogConfig = new MatDialogConfig();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileImportInput') fileImportInput: any;

  constructor(
    private dialog: MatDialog,
    private soilLogService: SoilLogService,
    private toastr: ToastrService,
    private router: Router,
    private geoTechService: GeoTechService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.dialogConfig.width = '700px';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;

    this.getSoilNames();
    this.getAllSoilLogsByTrialPitId();
    this.soilLog = this.createSoilLogObject();
    this.soilLogCreate = this.createSoilLogObject();
    this.soilLogEdit = this.createSoilLogObject();
  }

  ngAfterViewInit() {
    this.dataSourceSoilLogs.sort = this.sort;
    this.dataSourceSoilLogs.paginator = this.paginator;
    this.dataSourceSoilLogs.data = this.soilLogs;
    this.dataSourceSoilLogs = new MatTableDataSource(this.soilLogs);
  }

  resetPaginator() {
    this.dataSourceSoilLogs.sort = this.sort;
    this.dataSourceSoilLogs.paginator = this.paginator;
    this.dataSourceSoilLogs.data = this.soilLogs;
    this.dataSourceSoilLogs = new MatTableDataSource(this.soilLogs);
  }

  createSoilLogObject(): SoilLog {
    this.soilLog = new SoilLog();
    this.soilLog.depthFrom = new Size();
    this.soilLog.depthFrom.unit = new Unit();
    this.soilLog.depthTo = new Size();
    this.soilLog.depthTo.unit = new Unit();
    this.soilLog.comment = new Notes();
    this.soilLog.trialPit = new TrialPit();
    return this.soilLog;
  }

  onCreate() {
    this.dialogConfig.data = {
      trialPit: this.trialPit,
      soilLog: null
    };

    this.dialog.open(SoilLogCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getAllSoilLogsByTrialPitId();
    });
  }

  onEdit(soilLog: SoilLog, index: number) {
    this.dialogConfig.data = {
      trialPit: this.trialPit,
      soilLog: soilLog,
      index: index
    };

    this.dialog.open(SoilLogCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getAllSoilLogsByTrialPitId();
    });
  }

  getAllSoilLogsByTrialPitId() {
    if (this.trialPitId != null) {
      this.soilLogService.getAllSoilLogsByTrialPitId(this.trialPitId)
        .subscribe(data => {
          this.soilLogs = data as SoilLog[];
          this.soilLogs.sort((a, b) => <any>a.depthFrom.measure - <any>b.depthFrom.measure);
          if (this.soilLogs.length !== 0) {
            this.soilLogs = data as SoilLog[];
            this.dataSourceSoilLogs = new MatTableDataSource(this.soilLogs);
            this.dataSourceSoilLogs.sort = this.sort;
            this.dataSourceSoilLogs.paginator = this.paginator;
            const previous_log = this.dataSourceSoilLogs.data[this.dataSourceSoilLogs.data.length - 1];
            this.soilLogCreate.depthFrom.measure = previous_log.depthTo.measure;
            this.previous_depthTo = this.soilLogCreate.depthFrom.measure;
            this.loading = false;
          } else {
            this.soilLogs = [];
            this.dataSourceSoilLogs = new MatTableDataSource(this.soilLogs);
            this.dataSourceSoilLogs.sort = this.sort;
            this.dataSourceSoilLogs.paginator = this.paginator;
            this.loading = false;
          }
        });
    }
  }

  getSoilNames() {
    this.geoTechService.getAllSoils().subscribe(res => {
      this.soilNames = res as SoilName [];
      this.soilNames.sort((a, b) => (a.soil || '').toString().localeCompare((b.soil || '').toString()));
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceSoilLogs.filter = filterValue.trim().toLowerCase();
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };

    } else {
      this.fileReset();
    }
  }

  isCSVFile(file: any) {
    if (file.name.endsWith('.csv')) {
      return true;
    } else if (file.name.endsWith('.txt')) {
      return true;
    } else {
      return false;
    }
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      if (data.length === headerLength) {
        const csvRecord: SoilLog = new SoilLog();
        csvRecord.depthFrom = new Size();
        csvRecord.depthFrom.unit = new Unit();
        csvRecord.depthFrom.unit.id = this.enumUnits.meter;
        csvRecord.depthTo = new Size();
        csvRecord.depthTo.unit = new Unit();
        csvRecord.depthTo.unit.id = this.enumUnits.meter;
        csvRecord.comment = new Notes();
        csvRecord.sampleNumber = data[0].trim();
        csvRecord.depthFrom.measure = data[1].trim();
        csvRecord.depthTo.measure = data[2].trim();
        if (data[4].trim().substring(0,1) === '"' && data[4].trim().substring(data[4].trim().length-1) === '"') {
          csvRecord.description = data[4].trim().substring(1, data[4].trim().length-1);
        } else {
          csvRecord.description = data[4].trim();
        }
        csvRecord.sampleStatus = data[5].trim();
        csvRecord.comment.description = data[6].trim();
        csvRecord.test = data[7].trim();
        if(this.dataSourceSoilLogs.data.length > 0) {
          this.toastr.error('Soil log already exist for this Trial Pit, to upload, delete existing log first', 'Upload Soil Log');
          this.fileReset();
          return dataArr;
        } else if (data[3].trim()) {
          if (data[3].trim() != '') {
            csvRecord.soil = this.soilNames.filter(x => x.soil === data[3].trim())[0];
            if (csvRecord.soil) {
              dataArr.push(csvRecord);
              this.errorInRecord = false;
            } else {
              this.toastr.error('Soil Name ('+data[3].trim()+') provided in record '+Number(i)+' does not exist. Please correct record before proceeding', 'Upload Soil Log');
              this.errorInRecord = true;
              this.fileReset();
              return dataArr;
            }
          } else {
            this.toastr.error('Please provide Soil Name for record '+Number(i)+'. Please correct record before proceeding', 'Upload Soil Log');
            this.errorInRecord = true;
            this.fileReset();
            return dataArr;
          }
        } else {
          this.toastr.error('Please provide Soil Name for record '+Number(i)+'. Please correct record before proceeding', 'Upload Soil Log');
          this.errorInRecord = true;
          this.fileReset();
          return dataArr;
        }
      }
    }
    if (this.errorInRecord === false) {
      this.csvDdataSource.data = dataArr;
      this.showCsvModal();
      this.fileReset();
      return dataArr;
    }
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

  showCsvModal(): void {
    let packet = {soilLogList: this.csvDdataSource.data, trialPitId: this.trialPitId};
    const createDialogRef = this.dialog.open(SoilLogUploadComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data:packet
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.fileReset();
      this.getAllSoilLogsByTrialPitId();
    });
  }

  viewDetails(soilLog) {
    const idObject = { id: soilLog.id, trialPitId: this.trialPitId };
    this.router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/soil-log/details', idObject]);
  }

  onDelete(soilLog) {
    this.toastr.warning('Still Under Construction', 'Delete Soil Log');

  }

  deleteAllRecordsRequest() {
    $('#deleteModal').modal('show');
  }

  onDeleteAll() {
    this.spinner.show();
    this.dataSourceSoilLogs.data.forEach(element => {
      element.deleted = true;
    });
    this.soilLogService.createBatchSoilLog(this.dataSourceSoilLogs.data).subscribe(data => {
      this.getAllSoilLogsByTrialPitId();
      this.toastr.success('You have Successfully deleted all Soil Logs for this Trial Pit', 'Delete All Soil Logs');
      $('#deleteModal').modal('hide');
      this.spinner.hide();
    }, error => {
      this.toastr.error('An error occurred when trying to delete all soil logs', 'Error Occurred');
      this.spinner.hide();
    });

  }

}
