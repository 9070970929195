import {Size} from '../../common/size';
import {TrialPit} from './trial-pit';
import {Notes} from '../notes';
import { FileToUpload } from 'src/app/common/uploaded-files/entity/file';
import { SoilName } from '../geoTech/SoilName';

export class SoilLog {
  id: String;
  sampleNumber: string;
  depthFrom: Size;
  depthTo: Size;
  legend: string;
  test: string;
  description: string;
  comment: Notes;
  trialPit: TrialPit;
  labResults: FileToUpload;
  labSampleRequestForm: FileToUpload;
  creator: string;
  sampleStatus: string;
  soil: SoilName;
  deleted: boolean;
  constructor() {
  }
}
