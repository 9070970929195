import { PersonnelCreateComponent } from './../../../../../personnel/personnel-create/personnel-create.component';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, Inject } from '@angular/core';
import { InfiltrationTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/InfiltrationTest';
import { District } from 'src/app/common/entity/security/util/common/district';
import { Person } from '../../../../../_core/data/_models/people.model';
import { NigisImages } from 'src/app/shared-kernel/entity/common/nigisImages';
import { FormControl, NgModel, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import { MatAutocomplete, MatChipInputEvent,
  MatTableDataSource, MatPaginator, MatSort,
  MatDialogRef, MAT_DIALOG_DATA, MatSelect,
  MatDialog, MatDialogConfig } from '@angular/material';
import { GeoTechService } from '../../../geo-tech.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from 'src/app/shared-kernel/entity/common/location';
import { Coordinates } from 'src/app/shared-kernel/entity/common/coordinates';
import { CoordinatesType } from 'src/app/shared-kernel/entity/common/coordinatesType';
import { Coordinate } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinate';
import { CoordinatesTypesEnum } from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';
import { CoordinatesService } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import LatLon from 'geodesy/latlon-nvector-spherical.js';
import { Village } from 'src/app/shared-kernel/entity/common/Village';
import { CommonService } from 'src/app/common/common.service';
import { LocationTemp } from 'src/app/shared-kernel/entity/common/locationTemp';
import { BoreholeType } from 'src/app/shared-kernel/entity/common/boreholeType';
import { UserService } from 'src/app/auth/user/user.service';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { ProjectService } from 'src/app/applied-geoscience/project/project.service';
import { Company } from '../../../../../shared-kernel/entity/applied-geoscience/company/company';
import { PersonnelSortPipe } from '../../../../../shared-kernel/pipes/personnel.sort.pipe';
import { ProjectMembers } from 'src/app/applied-geoscience/project/project-detail/project-members/project-member.model';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { PeopleData } from 'src/app/_core/data/people';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

declare var $: any;

@Component({
  selector: 'app-infiltration-test-create',
  templateUrl: './infiltration-test-create.component.html',
  styleUrls: ['./infiltration-test-create.component.scss']
})
export class InfiltrationTestCreateComponent implements OnInit {

  locationImage: NigisImages;

  visible = true;
  selectable = true;
  removable = true;
  isDuplicateTestPoint: boolean = false
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  locationImageCtrl = new FormControl();
  villages: Village[];
  @ViewChild('testPointModel') testPointModel: NgModel;

  @ViewChild('locationImageInput') locationImageInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  infiltrationTest: InfiltrationTest;
  infiltrationTests: InfiltrationTest[];
  locationImages: NigisImages[];
  projectGeologists: Person[];
  projectManagers: Person[];
  projectCoordinators: Person[];
  districts: District[];
  projects: Project[];
  project: Project;
  dataSourceInfiltrationTests: MatTableDataSource<InfiltrationTest> = new MatTableDataSource();
  paginator: MatPaginator;
  sort: MatSort;
  submitButtonLabel: string;

  filteredVillages: Observable<Village[]>;
  filteredDistricts: Observable<District[]>;

  coordinatesTypes: CoordinatesType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];
  currentUser: any;

  dmsSx: number; // decimal minutes seconds, to hold x/longitude seconds value
  dmsMx: number; // decimal minutes seconds, to hold x/longitude minutes value
  dmsDx: number; // decimal minutes seconds, to hold x/longitude decimal value

  dmsSy: number; // decimal minutes seconds, to hold y/latitude seconds value
  dmsMy: number; // decimal minutes seconds, to hold y/latitude minutes value
  dmsDy: number; // decimal minutes seconds, to hold y/latitude decimal value

  zone34SNorthing: number;
  zone34SEasting: number;

  zone35SNorthing: number;
  zone35SEasting: number;
  selectedBoreholeType: string;

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  title = '';

  validation_messages = {
    'project': [
      { type: 'required', message: 'Please select project' }
    ],
    'testPointId': [
      { type: 'required', message: 'Test Point Id is required' },
    ],
    'date': [
      { type: 'required', message: 'Date is required' },
    ],
    'longitude': [
      { type: 'required', message: 'Longitude is required' },
    ],
    'latitude': [
      { type: 'required', message: 'Latitude is required' },
    ],
    'district': [
      { type: 'required', message: 'Please select district' },
    ],
    'village': [
      { type: 'required', message: 'Village is required' },
    ],
    'type': [
      { type: 'required', message: 'Select Coordinates Type' },
    ],
    'format': [
      { type: 'required', message: 'Select Coordinates Format' },
    ],
    'zone34SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone34SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'zone5SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone35SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'dmsSx': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMx': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDx': [
      { type: 'required', message: 'Type degree value' },
    ],
    'dmsSy': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMy': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDy': [
      { type: 'required', message: 'Type degree value' },
    ],
    'projectManager': [
      { type: 'required', message: 'Please select manager' },
    ],
    'projectCoordinator': [
      { type: 'required', message: 'Please select coordinator' },
    ],
    'geologist': [
      { type: 'required', message: 'Please select geologist' },
    ],
  };
  boreholeTypes: BoreholeType[];
  boreholeType: BoreholeType = new BoreholeType();

  infiltrationTestAddForm: FormGroup;
  coordinatesForm: FormGroup;
  submitted = false;
  allCompanies: Company [];

  projectMembers: Array<ProjectMembers> = [];
  personMembers: Array<Person> = [];

  filteredManagers: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredCoordinators: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredGeologists: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  currentManager: FormControl = new FormControl();
  managerFilter: FormControl = new FormControl();

  currentCoordinator: FormControl = new FormControl();
  coordinatorFilter: FormControl = new FormControl();
  currentGeologist: FormControl = new FormControl();
  geologistFilter: FormControl = new FormControl();

  @ViewChild('managerSelect') managerSelect: MatSelect;
  @ViewChild('coordinatorSelect') coordinatorSelect: MatSelect;
  @ViewChild('geologistSelect') geologistSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  projectStillLoading = true;
  districtStillLoading = true;
  villageStillLoading = true;
  projectMemberStillLoading = true;

  constructor(
    private _router: Router,private personnelSortPipe: PersonnelSortPipe,
    private geoTechService: GeoTechService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private coordinatesService: CoordinatesService,
    private commonService: CommonService,
    private userService: UserService,
    private companyService: CompanyService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InfiltrationTestCreateComponent>,
    private peopleSrv: PeopleData,
    private dialog: MatDialog,
    private _localStorage: LocalStorage) {
  }

  ngOnInit() {
    this.getBotswanaGeofence();
    this.getAllVillages();
    this.getAllDistricts();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.getProjectsByMember(this.currentUser.username);
      }, err => {
        this.toast.warning(err.message, "Current User Error");
      },() => {

      });
    this.infiltrationTest = this.createInfiltrationTestObject();
    this.locationImages = [];
    this.infiltrationTests = [];
    this.infiltrationTest.locationImages = [];
    if (this.data) {
      this.title = 'Edit Infiltration Test';
      this.submitButtonLabel = 'Update';
      this.infiltrationTest = this.data;
      this.locationImages = this.infiltrationTest.locationImages;
      this.infiltrationTest.date = new Date(this.infiltrationTest.date);
    } else {
      this.title = 'Create Infiltration Test';
      this.submitButtonLabel = 'Submit';
      this.infiltrationTest.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
      this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
      this.infiltrationTest.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    }
    this.initForms();
    this._getProjectMembers();

    this.districtNameControl.valueChanges.subscribe(
      value => {
        this.filteredDistricts = of(this._filterDistricts(value || ''))
      }
    );

    this.villageNameControl.valueChanges.subscribe(
      value => {
        this.filteredVillages = of(this._filterVillages(value || ''))
      }
    );
  }

  ngAfterViewInit() {
    this._setInitialManager();
    this._setInitialCoordinator();
    this._setInitialGeologist();
  }

  closeDialog() {
    this.dialogRef.close(this.infiltrationTest);
  }

  goTo(filter: string) {
    this._router.navigate([`portal/personnel/${filter}`]);
    this.closeDialog();
  }

  newPersonnel() {
    const newPersonnelDialogRef = this.dialog.open(PersonnelCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true
    });

    newPersonnelDialogRef.afterClosed().subscribe((result) => {
      this._getProjectMembers();
    });
  }

  createInfiltrationTestObject(): InfiltrationTest {
    this.infiltrationTest = new InfiltrationTest();
    this.infiltrationTest.coordinatesFormat = new CoordinatesFormat();
    this.infiltrationTest.project = new Project();
    this.infiltrationTest.locationTemp = new LocationTemp();
    this.infiltrationTest.locationTemp.coordinates = new Coordinates();
    this.infiltrationTest.locationTemp.village = new Village();
    this.infiltrationTest.locationTemp.village.name = '';
    this.infiltrationTest.locationTemp.village.district = new District();
    this.infiltrationTest.locationImages = [];
    this.infiltrationTest.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.infiltrationTest.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;

    return this.infiltrationTest;
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));

        this.filteredVillages =
          this.villageNameControl.valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name ? this._filterVillages(name) : this.villages.slice()
            )
          );

        this.villageStillLoading = false;
      });
  }

  getAllDistricts() {
    this.companyService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        
        this.filteredDistricts =
          this.districtNameControl.valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name ? this._filterDistricts(name) : this.districts.slice()
            )
          );
        this.districtStillLoading = false;
      });
  }

  getAllProjects() {
    this.projectService.getAllProjects()
      .subscribe(data => {
        this.projects = data as Project[];
        this.projectStillLoading = false;
      });
  }

  getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user).subscribe(res => {
      this.projects = res as Project[];
      this.projectStillLoading = false;
    }, err => {
    });
  }

  onDistrictChange(district) {

    let selectedDistrict = this.districtControl.value;
    
    this.filteredVillages = of(this.villages.filter(village => village.district.id === district.id))

    this.districtControl.setValue({
      id: district.id,
      name: district.name,
      code: district.code
    });

    if(district.id !== selectedDistrict.id) {
      this.villageControl.setValue({
        id: '',
        name: ''
      });
    }
  }

  onVillageChange(village: Village) {
    
    this.districtControl.setValue({
      id: village.district.id,
      name: village.district.name,
      code: village.district.code
    });

    this.villageControl.setValue({
      id: village.id,
      name: village.name
    });
  }

  private _filterDistricts(value: string): District[] { 
    
    const filterValue = value.toLowerCase();
    return this.districts ? this.districts.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  private _filterVillages(value: string): Village[] {
    
    const filterValue = value.toLowerCase();

    if(this.villages) {
      return this.villages.filter(option => {
        let district = this.districtControl.value;

        if(district && district.id) {
          return option.name.toLowerCase().includes(filterValue) && option.district.id === district.id;
        } else {
          return option.name.toLowerCase().includes(filterValue);
        }
      });
    } else {
      return [];
    }
  }

  get districtNameControl() {
    return this.infiltrationTestAddForm.get('district').get('name');
  }

  get districtControl() {
    return this.infiltrationTestAddForm.get('district');
  }

  get villageNameControl() {
    return this.infiltrationTestAddForm.get('village').get('name');
  }

  get villageControl() {
    return this.infiltrationTestAddForm.get('village');
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });

  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    if (this.submitButtonLabel === 'Submit') {
      this.selectedCoordinatesType = selectedData.text;
      this.infiltrationTest.coordinatesFormat.type = this.selectedCoordinatesType;
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.infiltrationTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.infiltrationTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
    } else {
      this.selectedCoordinatesType = selectedData.text;
      this.infiltrationTest.coordinatesFormat.type = this.selectedCoordinatesType;
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.infiltrationTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.infiltrationTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34, this.infiltrationTest.locationTemp.coordinates.latitude,
          this.infiltrationTest.locationTemp.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34, this.infiltrationTest.locationTemp.coordinates.latitude,
          this.infiltrationTest.locationTemp.coordinates.longitude)._lon;
      }
    }

  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };

    if (this.submitButtonLabel === 'Submit') {
      this.selectedCoordinatesFormat = selectedData.text;
      this.infiltrationTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
    } else {
      this.selectedCoordinatesFormat = selectedData.text;
      this.infiltrationTest.coordinatesFormat.format = this.selectedCoordinatesFormat;

      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34, this.infiltrationTest.locationTemp.coordinates.latitude,
          this.infiltrationTest.locationTemp.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34, this.infiltrationTest.locationTemp.coordinates.latitude,
          this.infiltrationTest.locationTemp.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
        this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35, this.infiltrationTest.locationTemp.coordinates.latitude,
          this.infiltrationTest.locationTemp.coordinates.longitude)._lat;
        this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35, this.infiltrationTest.locationTemp.coordinates.latitude,
          this.infiltrationTest.locationTemp.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
        this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.infiltrationTest.locationTemp.coordinates.latitude),
          parseFloat(this.infiltrationTest.locationTemp.coordinates.longitude))._lonSValue);
        this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.infiltrationTest.locationTemp.coordinates.latitude),
          parseFloat(this.infiltrationTest.locationTemp.coordinates.longitude))._lonMValue);
        this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.infiltrationTest.locationTemp.coordinates.latitude),
          parseFloat(this.infiltrationTest.locationTemp.coordinates.longitude))._lonDValue);

        this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.infiltrationTest.locationTemp.coordinates.latitude),
          parseFloat(this.infiltrationTest.locationTemp.coordinates.longitude))._latSValue);
        this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.infiltrationTest.locationTemp.coordinates.latitude),
          parseFloat(this.infiltrationTest.locationTemp.coordinates.longitude))._latMValue);
        this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.infiltrationTest.locationTemp.coordinates.latitude),
          parseFloat(this.infiltrationTest.locationTemp.coordinates.longitude))._latDVale);
      }

    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.locationImage = new NigisImages();
          this.locationImage.image = fileSplitdata[1];
          this.locationImage.name = fileName;
          this.locationImages.push(this.locationImage);
          this.infiltrationTest.locationImages.push(this.locationImage);
          this.locationImageCtrl.setValue(null);
        };
      }
    }
  }

  resetPaginator() {
    this.dataSourceInfiltrationTests.sort = this.sort;
    this.dataSourceInfiltrationTests.paginator = this.paginator;
    this.dataSourceInfiltrationTests.data = this.infiltrationTests;
  }

  remove(locationImage: NigisImages): void {
    const index = this.locationImages.indexOf(locationImage);
    if (index >= 0) {
      this.locationImages.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const chipBoreholeLocationImage = new NigisImages;
      chipBoreholeLocationImage.image = value;

      if ((value || '').trim()) {
        this.locationImages.push(chipBoreholeLocationImage);
      }

      if (input) {
        input.value = '';
      }

      this.locationImageCtrl.setValue(null);
    }
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo ? variableOne.id === variableTwo.id : variableOne === variableTwo;
  }

  compareObj(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo ? variableOne.name === variableTwo.name : variableOne === variableTwo;
  }

  onSubmit() {

    if (this.submitButtonLabel === 'Submit') {
      this.createNewRecord();
    } else if (this.submitButtonLabel === 'Update') {
      this.updateExistingRecord();
    }
  }

  private initForms() {
    this.infiltrationTestAddForm = this.formBuilder.group({
      project: [this.infiltrationTest ? this.infiltrationTest.project ? this.infiltrationTest.project.name?
        this.infiltrationTest.project : null:null:null, Validators.required],
      date: [this.infiltrationTest ? this.infiltrationTest.date : null, Validators.required],
      testPointId: [this.infiltrationTest ? this.infiltrationTest.testPointId : null, Validators.required],
      district: this.formBuilder.group({
        id: [this.infiltrationTest ? this.infiltrationTest.locationTemp.village.district.id : null, Validators.required],
        code: [this.infiltrationTest ? this.infiltrationTest.locationTemp.village.district.code : null, Validators.required],
        name: [this.infiltrationTest ? this.infiltrationTest.locationTemp.village.district.name : null, Validators.required],
      }),
      village: this.formBuilder.group({
        id: [this.infiltrationTest ? this.infiltrationTest.locationTemp.village.id : null, Validators.required],
        name: [this.infiltrationTest ? this.infiltrationTest.locationTemp.village.name : null, Validators.required],
      }),
      locationDescription: [this.infiltrationTest ? this.infiltrationTest.locationDescription : null],
      comments: [this.infiltrationTest ? this.infiltrationTest.comments : null],
      coordinatesFormatType: [this.infiltrationTest.coordinatesFormat ? this.infiltrationTest.coordinatesFormat.type : null, Validators.required],
      coordinatesFormatFormat: [this.infiltrationTest.coordinatesFormat ? this.infiltrationTest.coordinatesFormat.format : null, Validators.required],
      latitude: [this.infiltrationTest.locationTemp.coordinates ? this.infiltrationTest.locationTemp.coordinates.latitude : null],
      longitude: [this.infiltrationTest.locationTemp.coordinates ? this.infiltrationTest.locationTemp.coordinates.longitude : null],
      dmsDy: [this.dmsDy ? this.dmsDy : null],
      dmsMy: [this.dmsMy ? this.dmsMy : null],
      dmsSy: [this.dmsSy ? this.dmsSy : null],
      dmsDx: [this.dmsDx ? this.dmsDx : null],
      dmsMx: [this.dmsMx ? this.dmsMx : null],
      dmsSx: [this.dmsSx ? this.dmsSx : null],
      zone34SNorthing: [this.zone34SNorthing ? this.zone34SNorthing : null],
      zone35SNorthing: [this.zone35SNorthing ? this.zone35SNorthing : null],
      zone35SEasting: [this.zone35SEasting ? this.zone35SEasting : null],
      zone34SEasting: [this.zone34SEasting ? this.zone34SEasting : null],

    });
  }

  convertFormToObject() {

    if(this.infiltrationTest.id){
      if (this.currentManager.value!==null) {
        if (this.currentManager.value.id && this.infiltrationTest.projectManager !== this.currentManager.value.id) {
          this.infiltrationTest.projectManager = this.currentManager.value.id;
        }
      }
     
      if (this.currentCoordinator.value!==null) {
        if (this.currentCoordinator.value.id && this.infiltrationTest.projectCoordinator !== this.currentCoordinator.value.id) {
          this.infiltrationTest.projectCoordinator = this.currentCoordinator.value.id;
        }
      }

      if (this.currentGeologist.value!==null) {
        if (this.currentGeologist.value.id && this.infiltrationTest.projectGeologist !== this.currentGeologist.value.id) {
          this.infiltrationTest.projectGeologist = this.currentGeologist.value.id;
        }
      }
    } else {
      this.infiltrationTest.projectManager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
      this.infiltrationTest.projectCoordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
      this.infiltrationTest.projectGeologist = this.currentGeologist.value && this.currentGeologist.value.id ? this.currentGeologist.value.id : '';
    }

    this.infiltrationTest.project = this.infiltrationTestAddForm.value.project;
    this.infiltrationTest.date = this.infiltrationTestAddForm.value.date;
    this.infiltrationTest.testPointId = this.infiltrationTestAddForm.value.testPointId;
    this.infiltrationTest.coordinatesFormat.format = this.infiltrationTestAddForm.value.coordinatesFormatFormat;
    this.infiltrationTest.coordinatesFormat.type = this.infiltrationTestAddForm.value.coordinatesFormatType;
    this.infiltrationTest.locationTemp.coordinates.latitude = this.infiltrationTestAddForm.value.latitude;
    this.infiltrationTest.locationTemp.coordinates.longitude = this.infiltrationTestAddForm.value.longitude;
    this.infiltrationTest.locationDescription = this.infiltrationTestAddForm.value.locationDescription;
    this.infiltrationTest.comments = this.infiltrationTestAddForm.value.comments;
    this.infiltrationTest.locationTemp.village = this.infiltrationTestAddForm.value.village;
    this.infiltrationTest.locationTemp.village.district = this.infiltrationTestAddForm.value.district;

    this.dmsDy = this.infiltrationTestAddForm.value.dmsDy;
    this.dmsMy = this.infiltrationTestAddForm.value.dmsMy;
    this.dmsSy = this.infiltrationTestAddForm.value.dmsSy;
    this.dmsDx = this.infiltrationTestAddForm.value.dmsDx;
    this.dmsMx = this.infiltrationTestAddForm.value.dmsMx;
    this.dmsSx = this.infiltrationTestAddForm.value.dmsSx;
    this.zone34SNorthing = this.infiltrationTestAddForm.value.zone34SNorthing;
    this.zone35SNorthing = this.infiltrationTestAddForm.value.zone35SNorthing;
    this.zone35SEasting = this.infiltrationTestAddForm.value.zone35SEasting;
  }

  createNewRecord() {
    this.convertFormToObject();
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.infiltrationTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.infiltrationTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.infiltrationTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.infiltrationTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.infiltrationTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.infiltrationTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }
    this.infiltrationTest.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.infiltrationTest.locationTemp.coordinates.latitude,
        this.infiltrationTest.locationTemp.coordinates.longitude)._lat;
    this.infiltrationTest.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.infiltrationTest.locationTemp.coordinates.latitude,
        this.infiltrationTest.locationTemp.coordinates.longitude)._lon;

    if (new LatLon(this.infiltrationTest.locationTemp.coordinates.latitude,
      this.infiltrationTest.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      this.spinner.show();
      if (!this.infiltrationTest.locationImages || this.infiltrationTest.locationImages.length <= 0) {
        this.infiltrationTest.locationImages = [];
      }
      if (!this.infiltrationTest.infiltrationTestLogs || this.infiltrationTest.infiltrationTestLogs.length <= 0) {
        this.infiltrationTest.infiltrationTestLogs = [];
      }
      delete this.infiltrationTest.location;
      this.infiltrationTest.locationImages = this.locationImages;

      this.geoTechService.createInfiltrationTest(this.infiltrationTest).subscribe(response => {
        this.infiltrationTest = response as InfiltrationTest;
        this.infiltrationTests.push(this.infiltrationTest);
        this.infiltrationTests.sort((a, b) => (a.project.name || '').toString().localeCompare((b.project.name || '').toString())
          || <any>a.testPointId.localeCompare(<any>b.testPointId, undefined, {numeric: true, sensitivity: 'base'}));
        this.toast.success('You have Successfully created a New Infiltration Test', 'Infiltration Test Create');
        this.resetPaginator();
        this.spinner.hide();
        this.closeDialog();
        this.closeModal();
        this.infiltrationTest = this.createNewInfiltrationTestObject();
      },
        error => {
          this.spinner.hide();
            this.toast.error(error.message, 'Infiltration Test Create');

        },
        () => {
          this.spinner.hide();
          this.resetPaginator();
          this.closeDialog();
          this.closeModal();
          this.infiltrationTest = this.createNewInfiltrationTestObject();
        });
    } else {
      this.toast.error(this.coordinatesService.outSide);
    }
  }

  updateExistingRecord() {
    this.convertFormToObject();
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.infiltrationTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.infiltrationTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.infiltrationTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.infiltrationTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.infiltrationTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.infiltrationTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }
    this.infiltrationTest.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.infiltrationTest.locationTemp.coordinates.latitude,
        this.infiltrationTest.locationTemp.coordinates.longitude)._lat;
    this.infiltrationTest.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.infiltrationTest.locationTemp.coordinates.latitude,
        this.infiltrationTest.locationTemp.coordinates.longitude)._lon;

    if (new LatLon(this.infiltrationTest.locationTemp.coordinates.latitude,
      this.infiltrationTest.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      this.spinner.show();
      delete this.infiltrationTest.location;
      this.infiltrationTest.locationImages = this.locationImages;
      this.geoTechService.createInfiltrationTest(this.infiltrationTest).subscribe(response => {
        this.toast.success('You have Successfully updated an Infiltration Test', 'Infiltration Test Update');
        this.spinner.hide();
        this.closeDialog();
        this.closeModal();
        this.infiltrationTest = this.createNewInfiltrationTestObject();
      },
        error => {
            this.spinner.hide();
            this.toast.error(error.message, 'Infiltration Test Update');
        },
        () => {
          this.spinner.hide();
          this.closeDialog();
          this.closeModal();
          this.createNewInfiltrationTestObject();
        });

    } else {
      this.toast.error(this.coordinatesService.outSide);
    }
  }

  createNewInfiltrationTestObject(): InfiltrationTest {
    this.infiltrationTest = new InfiltrationTest();
    this.infiltrationTest.location = new Location();
    this.infiltrationTest.locationTemp.coordinates = new Coordinates();
    this.infiltrationTest.coordinatesFormat = new CoordinatesFormat();
    this.locationImages = [];
    this.infiltrationTest.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.infiltrationTest.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    return this.infiltrationTest;
  }

  onFocusOut() {
        let testPointId = this.infiltrationTestAddForm.value.testPointId;
        this.infiltrationTest.project = this.infiltrationTestAddForm.value.project;
        if (testPointId && testPointId.trim() !== '') {
           if (this.infiltrationTest.project && this.infiltrationTest.project.id) {
                this.geoTechService.infiltrationInProject(testPointId,this.infiltrationTest.project.id).subscribe(res => {
                   if (res.exist === true) {
                      this.infiltrationTestAddForm.controls.testPointId.setErrors({ 'exist': true });
                     } else {
                      this.infiltrationTestAddForm.controls.testPointId.setErrors(null);
                     }
                  });
           }
         }
   }

  closeModal() {
    this.infiltrationTest = new InfiltrationTest();
    this.infiltrationTest = this.createNewInfiltrationTestObject();
    this.infiltrationTest.coordinatesFormat.type = '';
    this.selectedCoordinatesType = '';
    this.infiltrationTest.coordinatesFormat.format = '';
    this.selectedCoordinatesFormat = '';
    this.dmsSx = null;
    this.dmsMx = null;
    this.dmsDx = null;
    this.dmsSy = null;
    this.dmsMy = null;
    this.dmsDy = null;
    this.zone34SNorthing = null;
    this.zone34SEasting = null;
    this.zone35SNorthing = null;
    this.zone35SEasting = null;
  }

  protected _setInitialManager() {
    this.filteredManagers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.infiltrationTest.id) {
        // Edit
        let manager = this.personMembers.find(pM => pM.id === this.infiltrationTest.projectManager);
        if (!manager) {
          found = false;
        } else {
          found = true;
          this.currentManager.setValue(manager);
          this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;

      // Pre-select
      let manager = this.personMembers.find(pM => this._isOfRole(pM, 'Project Manager'));

      if (!manager) return;

      this.currentManager.setValue(manager);
      this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }


  protected _setInitialCoordinator() {
    this.filteredCoordinators.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.infiltrationTest.id) {
        // Edit
        let coordinator = this.personMembers.find(pM => pM.id === this.infiltrationTest.projectCoordinator);

        if (!coordinator) {
          found = false;
        } else {
          found = true;
          this.currentCoordinator.setValue(coordinator);
          this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let coordinator = this.personMembers.find(pM => this._isOfRole(pM, 'Project Coodinator'));

      if (!coordinator) return;
      this.currentCoordinator.setValue(coordinator);
      this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialGeologist() {
    this.filteredGeologists.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;

      if (this.infiltrationTest.id) {
        // Edit
        let geologist = this.personMembers.find(pM => pM.id === this.infiltrationTest.projectGeologist);
        if (!geologist) {
          found = false;
        } else {
          found = true;
          this.currentGeologist.setValue(geologist);
          this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;

      // Pre-select
      let geologist = this.personMembers.find(pM => this._isOfRole(pM, 'Geologist'));

      if (!geologist) return;
      this.currentGeologist.setValue(geologist);
      this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  private _getProjectMembers() {
    this.projectMemberStillLoading = true;
    if (this.infiltrationTest.project) {
      this.projectService.getProjectMembers(this.infiltrationTest.project.id).subscribe(res => {
        this.projectMembers = res as Array<ProjectMembers>;
        this._getProjectPeople(this.projectMembers);
      }, err => {
        this.toast.error(err.message, "Project Member Fail");
      });
    }
  }

  private _getProjectPeople(members: Array<ProjectMembers>) {
    let ids = [];
    members.forEach(m => {
      ids.push(m.personId);
    });

    if (ids.length <= 0) {
      this.projectMemberStillLoading = false;
      return;
    }

    this.peopleSrv.getPeopleByIdList(ids).subscribe(res => {
      this.personMembers = res;

      this.filteredManagers.next(res.slice());
      this.managerFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterManager();
      });

      this.filteredCoordinators.next(res.slice());
      this.coordinatorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCoordinators();
      });
      this.filteredGeologists.next(res.slice());
      this.geologistFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterGeologist();
      });
      this.projectMemberStillLoading = false;
    }, err => {
      this.toast.error(err.message, "Project People Fail");
    });
  }

  private _isOfRole(person: Person, role: string) {
    let member = this.projectMembers.find(pM => pM.personId === person.id && pM.deleted === false);
    if (!member) return false;
    let roleFound = false;
    member.projectRoles.forEach(r => {
      if (r.name.toLowerCase() === role.toLowerCase()) {
        roleFound = true;
      }
    });
    return roleFound;
  }

  filterManager() {
    if (!this.personMembers) return;
    let search = this.managerFilter.value;
    if (!search) {
      this.filteredManagers.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredManagers.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterCoordinators() {
    if (!this.personMembers) return;
    let search = this.coordinatorFilter.value;
    if (!search) {
      this.filteredCoordinators.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCoordinators.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterGeologist() {
    if (!this.personMembers) return;
    let search = this.geologistFilter.value;
    if (!search) {
      this.filteredGeologists.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGeologists.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  companyChanged() {
    this.infiltrationTest.project = this.infiltrationTestAddForm.controls.project.value;
    this._getProjectMembers();
  }

}
