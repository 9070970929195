import {Component, Inject, OnInit} from '@angular/core';
import {Person} from '../../../../_core/data/_models/people.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogConfig} from '@angular/material/dialog';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {CompanyService} from '../../../../applied-geoscience/company/company.service';
import {ProjectService} from '../../../../applied-geoscience/project/project.service';
import {PeopleService} from '../../../../_core/mock/people.service';
import {UserService} from '../../../../auth/user/user.service';
import {ScheduleService} from '../../../services/schedule.service';
import {ToastrService} from 'ngx-toastr';
import {CompanyEnum} from '../../../../shared-kernel/enumerations/company-enum';
import {compareAsc, compareDesc, startOfToday} from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { CoreshedLocation } from '../../../entity/CoreshedLocation';
import { CoreshedService } from '../../../services/coreshed.service';
import { Village } from '../../../../shared-kernel/entity/common/Village';
import { CommonService } from '../../../../common/common.service';

@Component({
  selector: 'app-event-schedule-form',
  templateUrl: './event-schedule-form.component.html',
  styleUrls: ['./event-schedule-form.component.scss']
})
export class EventScheduleFormComponent implements OnInit {

  people: Person[] = [];
  loadingPeople = false;
  villages: Village []= [];
  serviceCenters = [
  ];

  getCoreshedLocations(){
    this.coreshedService.getCoreshedLocations().subscribe(value => {
      this.serviceCenters = value as CoreshedLocation[];
      this.serviceCenters.forEach(value1 => {value1.village =this.villages.find(value => value.id==value1.village).name})
    });
  }

  getVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.getCoreshedLocations();
      });
  }

  servicedBy: Person[] = [];

  eventScheduleForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    startDate: new FormControl(null, Validators.required),
    startTime: new FormControl(null),
    endDate: new FormControl(null, Validators.required),
    endTime: new FormControl(null),
    allDay: new FormControl(false),
    serviceCenters: new FormControl(null, Validators.required),
    actualTurnAroundTime: new FormControl(null),
    servicedBy: new FormControl(null),
    description: new FormControl(null)
  });

  dialogConfig = new MatDialogConfig();
  minDate = new Date();

  readonly = false;
  currentUser : any;

  constructor(private dialog: MatDialog, private companyService: CompanyService,private coreshedService:CoreshedService,
              @Inject(MAT_DIALOG_DATA) public data: any, private projectService: ProjectService,
              private peopleService: PeopleService, private userService: UserService,
              private scheduleService: ScheduleService, private toastr: ToastrService,
              private dialogRef: MatDialogRef<EventScheduleFormComponent>,
              private spinner: NgxSpinnerService,
               private _localStorage: LocalStorage,private commonService:CommonService
  ) {
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '500px';

    this.getAllBGIPersonnel();
    this.getVillages();

    if (this.data.eventSchedule) {
      this.populateFormDetails();
    }
  }

  populateFormDetails() {
    this.eventScheduleForm.reset();
    this.data.eventSchedule.servicedBy.forEach(id => {
      this.peopleService.getPersonById(id).subscribe(person => {
        this.servicedBy.push(person);
        this.eventScheduleForm.controls['servicedBy'].setValue(this.servicedBy);
      });
    });

    this.eventScheduleForm.setValue({
        id: this.data.eventSchedule.id,
        startDate: new Date(this.data.eventSchedule.startDate),
        startTime: this.data.eventSchedule.startTime,
        endDate: new Date(this.data.eventSchedule.endDate),
        endTime: this.data.eventSchedule.endTime,
        allDay: this.data.eventSchedule.allDay,
        serviceCenters: this.data.eventSchedule.serviceCenters,
        actualTurnAroundTime: this.data.eventSchedule.actualTurnAroundTime,
        servicedBy: this.servicedBy,
        description: this.data.eventSchedule.description
      }
    );
    this.onChange(this.data.eventSchedule.allDay);
  }

  onChange(isChecked: string) {
    if (isChecked) {
      this.readonly = true;
      this.eventScheduleForm.controls['startTime'].setValue('00:00');
      this.eventScheduleForm.controls['endTime'].setValue('00:00');
    } else {
      this.readonly = false;
    }
  }

  onTimeChange() {
    if ((this.eventScheduleForm.controls['startTime'].value === '00:00')
      && (this.eventScheduleForm.controls['endTime'].value === '00:00')) {
      this.eventScheduleForm.controls['allDay'].setValue(true);
    } else {
      this.eventScheduleForm.controls['allDay'].setValue(false);
    }
  }

  getAllBGIPersonnel() {
    this.loadingPeople = true;

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.userService.getPersonnelByBusinessUnit(res.businessUnit.id).subscribe(
        data => {
          this.people = data as Person[];
          this.loadingPeople = false;
        }
      );

    }, err => {
    });

  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    const eventSchedule = this.eventScheduleForm.value;

    // -- If time is not specified, set it to 00:00 --
    eventSchedule.startTime = eventSchedule.startTime ? eventSchedule.startTime : '00:00';
    eventSchedule.endTime = eventSchedule.endTime ? eventSchedule.endTime : '00:00';
    if (eventSchedule.startTime === '00:00' && eventSchedule.endTime === '00:00') {
      eventSchedule.allDay = true;
    }

    const servicedByIDs = [];
    if (eventSchedule.servicedBy) {
      eventSchedule.servicedBy.forEach(person => {
        servicedByIDs.push(person.id);
      });
    }

    if (compareDesc(eventSchedule.startDate, eventSchedule.endDate) !== -1) {
      if (this.data.eventSchedule) {
        this.data.eventSchedule.startDate = eventSchedule.startDate;
        this.data.eventSchedule.startTime = eventSchedule.startTime;
        this.data.eventSchedule.endDate = eventSchedule.endDate;
        this.data.eventSchedule.endTime = eventSchedule.endTime;
        this.data.eventSchedule.allDay = eventSchedule.allDay;
        this.data.eventSchedule.serviceCenters = eventSchedule.serviceCenters;
        this.data.eventSchedule.actualTurnAroundTime = eventSchedule.actualTurnAroundTime;
        this.data.eventSchedule.servicedBy = servicedByIDs;
        this.data.eventSchedule.description = eventSchedule.description;

        this.spinner.show();
        this.scheduleService.updateEventSchedule(this.data.eventSchedule).subscribe(
          res => {
            this.toastr.success('Event schedule updated successfully', 'Event Schedule');
            this.onClose();
            this.spinner.hide();
          }, error => {
            this.toastr.error(error.statusText, 'Event Schedule');
          }
        );
      } else {
        eventSchedule.request = this.data.customerRequest;
        eventSchedule.servicedBy = servicedByIDs;

        this.spinner.show();
        this.scheduleService.createEventSchedule(eventSchedule).subscribe(
          res => {
            this.toastr.success('Event schedule created successfully', 'Create Event Schedule');
            this.spinner.hide();
            this.onClose();
          }, error => {
            this.toastr.error(error.statusText, 'Create Event Schedule');
          }
        );
      }
    } else {
      this.toastr.error('Please ensure that start date is before end date or the two dates are the same.',
                           'Invalid date range');
    }
  }
}
