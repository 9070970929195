import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';


import { Subject } from 'rxjs';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { CreateTestPointComponent } from './create-test-point/create-test-point.component';
import { DynamicConePenetrometerTestLog } from 'src/app/_core/data/_models/dcp-foundation.models';
import { DcpFoundationData } from 'src/app/_core/data/dcp-foundation';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';



@Component({
    selector: "test-points-container",
    templateUrl: "./test-points-container.html",
    styleUrls: ["./test-points-container.scss"]
})
export class TestPointContainerComponent {
    [x: string]: any;
    eventsSubject: Subject<void> = new Subject<void>();
    @Input('testId') testId: string;
    @Input('currentState') currentState: string ='';
    @ViewChild('uploadInput') uploadInput: ElementRef;
    tableDataSource: MatTableDataSource<DynamicConePenetrometerTestLog>;
    displayedColumns: string[] = ['number', 'number_of_blows', 'penetration', 'penetration_rate', 'start_depth', 'end_depth', 'comments', 'edit', 'delete'];
    @ViewChild(MatPaginator) paginator: MatPaginator;

    currentDate = new Date();
    constructor(
        private dialog: MatDialog,
        private dcpFoundationData: DcpFoundationData,
        private toastr: ToastrService,
    ) {}

    emitEventToChild() {
        this.eventsSubject.next();
    }

    ngOnInit() {
        this.getTestPoints();
    }



    onAddNew() {
        const newTestPointDialogRef = this.dialog.open(CreateTestPointComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: { testId: this.testId, testPoint: null }
        });

        newTestPointDialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.emitEventToChild();
            }
            this.getTestPoints();
        });
    }

    importCSVFile() {
        this.uploadInput.nativeElement.click();

    }
    isCsvExtension(file: File): Boolean {
        return file.name.endsWith('.csv');
    }

    onSelectFile(files: FileList) {
        const csvFile = files[0];
        if (!this.isCsvExtension(csvFile)) {
            this.toastr.error("Invalid file extension", "Invalid File");
            return;
        }

        if(files && files.length > 0) {
            let file : File = files.item(0);
              let reader: FileReader = new FileReader();
              reader.readAsText(file);
              reader.onload = (e) => {
                 //let csvArr: string = reader.result as string;
                 let csvData = reader.result;
                 let csvArr = (<string>csvData).split(/\r\n|\n/);
                 const recordsToUpload: Array<DynamicConePenetrometerTestLog> = [];
                 const headers = csvArr[0].split(',');


                 for (let i = 1; i < csvArr.length; i++) {
                    if (csvArr[i].trim() === "") continue;
                    let currentRecord = csvArr[i].split(',');
                    let tempRecord: DynamicConePenetrometerTestLog ;
            if(Number(currentRecord[0]).valueOf() > 0 && Number(currentRecord[1]).valueOf() >0){
                    if(Number(currentRecord[4])>Number(currentRecord[3])){
                    tempRecord = <DynamicConePenetrometerTestLog><unknown>{
                        numberOfBlows: currentRecord[0],
                        penetration: {measure: currentRecord[1],unit:{id:UnitEnum.meter}},
                        penetrationRate: {measure: currentRecord[2],unit:{id:UnitEnum.meter}},
                        startDepth: {measure: currentRecord[3],unit:{id:UnitEnum.meter}},
                        finishDepth: {measure: currentRecord[4],unit:{id:UnitEnum.meter}},
                        comments: currentRecord[5],
                        isDeleted: false
                    };
                    recordsToUpload.push(tempRecord);
                }
            else{
                    this.toastr.warning('Finish Depth has to be greater than Start Depth','Import Data Validation');

                }
            }else{

                    this.toastr.warning('Provide valid data','Import Data Validation');
                }

                 }

                 this.dcpFoundationData.addTestPointList(this.testId, recordsToUpload).subscribe(res => {
                    this.submited = false;
                    this.toastr.info("Test Point saved successfully", "Test Point  Saved");
                    this.getTestPoints();

                   // this.dialogRef.close(res);
                }, err => {
                    this.toastr.error(err.message, "Failed to upload");
                    this.submited = false;
                });
              }
           }


    }


    getTestPoints() {
        if (this.testId) {
            this.dcpFoundationData.getTestPoints(this.testId).subscribe(res => {
                this.testPoints = res;
                this.testPoints.sort((a, b) => <any>a.startDepth.measure - <any>b.startDepth.measure);
                this.tableDataSource = new MatTableDataSource<DynamicConePenetrometerTestLog>(this.testPoints);
                this.tableDataSource.paginator = this.paginator;
            });
        } else {
            this.getTestPoints();
        }
    }

    onEdit(testPoint: DynamicConePenetrometerTestLog) {
        const newTestPointDialogRef = this.dialog.open(CreateTestPointComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: { testId: this.testId, testPoint: testPoint }
        });

        newTestPointDialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.getTestPoints();
            }
        });
    }

    onDelete(testPoint: DynamicConePenetrometerTestLog) {
        Swal.fire({
            title: "Delete test point",
            text: "Are you sure you want to delete a test point?",
            type: "question",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then(res => {
            if (res.value) {
                this.dcpFoundationData.deleteTestPoint(testPoint.id).subscribe(_res => {
                    this.toastr.info("Point deleted successfully", "Point Deleted");
                    this.getTestPoints();
                }, err => {
                    this.toastr.error(err.message, "Delete failed");
                });
            }
        });
    }



}
