import { Sample } from './Sample';
import { Notes } from '../notes';
import { NigisImages } from '../../common/nigisImages';

export class Petrography {
    id: string;
    date: Date;
    sample: Sample;
    description: Notes = new Notes();
    crossSectionImagery:  NigisImages[];
    deleted: boolean;
    imagesNames: string;
    imagesNamesFull: string;
}