import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';

@Injectable({
  providedIn: 'root'
})
export class GlobalDashboardService {

  constructor(private http: HttpClient) { }



  getMangerDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDashboardCountsUrl + 'manager' );
  }

  getNonBGIMangerDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDashboardCountsUrl + 'non_bgi_manager' );
  }


  getQADashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDashboardCountsUrl + 'qa' );
  }

  getNonBgiQADashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDashboardCountsUrl + 'non_bgi_qa' );
  }

  getUserDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDashboardCountsUrl + 'user' );
  }

  getSuperintendentDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDashboardCountsUrl + 'superintendent' );
  }

  managerDrillRequestCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/dashboard/counts/v2/manager' );
  }


  adminDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/dashboard/counts/v1/admin' );
  }

  adminProjectDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/dashboard/counts/v1/admin/project' );
  }

  getActivitiesPerDistrictCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/dashboard/counts/v1/admin/perdistrict' );
  }


  districtDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/dashboard/counts/v1/my_district_activities' );
  }

  plDistrictDashboardCounts(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/dashboard/counts/v1/my_pl_district_activities' );
  }



  managerDrillRequestCountsByUnit(unitId): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/dashboard/counts/v2/manager/unit/${unitId}` );
  }


  getProjectActivitesByStageCount(state?: string): Observable<any> {
    return this.http.get<number>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/manager/project/activities` + '/' + state + '/count' );
  }
}
