import Swal,{ SweetAlertOptions } from 'sweetalert2';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { HydrogeologyValidation } from '../../../hydrogeology.validation';
import { WaterPumpingTestConstantRateTestTypeEnum } from 'src/app/shared-kernel/enumerations/water-pumping-test-constant-rate-test-type';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WaterPumpTestConstantRateTestCreateComponent } from '../water-pump-test-constant-rate-test-create/water-pump-test-constant-rate-test-create.component';
import { Borehole } from '../../../../../shared-kernel/entity/common/borehole';
import { WaterConstantRateTest } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterConstantRateTest';
import { WaterObservationBorehole } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterObservationBorehole';
import {ExportToCsv} from "export-to-csv";
import {SoilName} from "../../../../../shared-kernel/entity/applied-geoscience/geoTech/SoilName";
import {SoilUploadComponent} from "../../../../../_settings/stratigraphy/soil/soil-upload/soil-upload.component";
import {ConstantRateTestUploadComponent} from "../constant-rate-test-upload/constant-rate-test-upload.component";
import {GeoTechService} from "../../../../geo-tech/geo-tech.service";
import {Notes} from "../../../../../shared-kernel/entity/applied-geoscience/notes";
import {UnitEnum} from "../../../../../shared-kernel/enumerations/unit-enum";
declare var $: any;
@Component({
  selector: 'app-water-pump-test-constant-rate-test-list',
  templateUrl: './water-pump-test-constant-rate-test-list.component.html',
  styleUrls: ['./water-pump-test-constant-rate-test-list.component.scss']
})
export class WaterPumpTestConstantRateTestListComponent implements OnInit {

  dataSourceWaterConstantRateTest: MatTableDataSource<any> = new MatTableDataSource();
  displayedWaterPumpingTestConstantRateTestColumns = ['#', 'pumpingRate','temperature', 'conductivity', 'pH', 'Edit', 'View', 'Delete'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  waterConstantRateTests: WaterConstantRateTest [] = [];
  loading: boolean;
  @Input('currentState') currentState: string = '';
  @Input('isConstantRate') isConstantRate: boolean = false;
  @Input('constantRate') constantRate: WaterConstantRateTest = new WaterConstantRateTest();
  @Input('boreholeDetails') boreholeDetails: Borehole = new Borehole();
  @Input('projectId') projectId: string = '';
  boreholeId: string;
  boreholes: Notes [] = [];  @ViewChild('fileImportInput') fileImportInput: any;
  csvRecords: any[] = [];
  csvDdataSource: MatTableDataSource<WaterConstantRateTest> = new MatTableDataSource();

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private hydrogeologyService: HydrogeologyService,
    private geoTechService: GeoTechService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private hydrogeologyValidation: HydrogeologyValidation,
    private spinner: NgxSpinnerService) {
      this.router.params.subscribe(value => {
        this.boreholeId = value.id;
      });
  }

  validation_messages = this.hydrogeologyValidation.validation_messages;

  ngOnInit() {
    this.getAllBoreholesLite();
    if(this.isConstantRate){
      this.getAllWaterPumpingTestConstantRateTestByWaterPumpingTestId();
    }
    else{
      this.getAllWaterObservationBorehole();
    }

  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  createNew(){
    if(this.isConstantRate){
      const constantRateDialogRef = this.dialog.open(WaterPumpTestConstantRateTestCreateComponent, <MatDialogConfig>{
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        data:{url:this.boreholeDetails}
      });

      constantRateDialogRef.afterClosed().subscribe(value => {
        if(value){
          this.getAllWaterPumpingTestConstantRateTestByWaterPumpingTestId();
        }
      });
    }
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  resetPaginator() {
    this.dataSourceWaterConstantRateTest.sort = this.sort;
    this.dataSourceWaterConstantRateTest.paginator = this.paginator;
  }

  getAllWaterObservationBorehole(){
    this.spinner.show();
    this.hydrogeologyService.getWaterObservationBoreholeByConstantRateTest(this.constantRate.id).subscribe(value => {
      this.dataSourceWaterConstantRateTest.data = value as WaterObservationBorehole[];
      this.spinner.hide();
      this.loading = false;
    },error => {
      this.spinner.hide();
      this.loading = false;
    },() => {})
  }

  getAllWaterPumpingTestConstantRateTestByWaterPumpingTestId() {
    this.spinner.show();
    this.hydrogeologyService.getAllWaterConstantRateTestByWaterPumpingTestId(this.boreholeId)
      .subscribe(data => {
        this.waterConstantRateTests = data as WaterConstantRateTest[];
        this.dataSourceWaterConstantRateTest.data = data as WaterConstantRateTest[];
        this.spinner.hide();
        this.loading = false;
      }, error => {
        this.spinner.hide();
        this.loading = false;
      }, () => {
        this.spinner.hide();
        this.loading = false;
      });
  }

  viewRecords(waterPumpingTest){
    const urlParams = { id: waterPumpingTest.id ,
    borehole: this.boreholeId, state:this.currentState};
    this.route.navigate(['/portal/applied-geoscience/hydrogeology/water-pump-test/constant-rate-test/view', urlParams]);
  }

  editRecords(test){
    if(this.isConstantRate){
      const constantRateDialogRef = this.dialog.open(WaterPumpTestConstantRateTestCreateComponent, <MatDialogConfig>{
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        data:{waterConstantRateTest:test,url:this.boreholeDetails}

      });

      constantRateDialogRef.afterClosed().subscribe(value => {
        if(value){
          this.getAllWaterPumpingTestConstantRateTestByWaterPumpingTestId();
        }
      });
    }

  }



  deleteRecord(waterPumpingTestConstantRateTest){

    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestConstantRateTestOne(waterPumpingTestConstantRateTest).subscribe(() => {
              this.getAllWaterPumpingTestConstantRateTestByWaterPumpingTestId();
              this.toastr.success('Delete Water Pumping Test', 'You have Successfully deleted Water Pumping Test');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });

  }


  applyFilter(filterValue: string) {
    this.dataSourceWaterConstantRateTest.filter = filterValue.trim().toLowerCase();
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestConstantRateTestBulk(this.dataSourceWaterConstantRateTest.data).subscribe(() => {
              this.getAllWaterPumpingTestConstantRateTestByWaterPumpingTestId();
              this.toastr.success('Delete Water Pumping Tests', 'You have Successfully deleted Water Pumping Tests');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });
  }

  exportCSV() {
    const data = [{
      'Borehole No.': '',
      'Pumping Type': '',
      'Depth To Water (m)': '',
      'Start Date': '',
      'End Date': '',
      'Start Time': '',
      'End Time': '',
      'Satatic Water Level': '',
      'Draw Down': '',
      'Pumping Rate (mᵌ/hr)': '',
      'Time To Fill (l)': '',
      'Time To Fill  (time)': '',
      'Tds': '',
      'Temperature (℃)':'',
      'pH (S/m)':'',
      'Electrical Conductivity (S/m)':'',
      'Observation Boreholes': '',
      'Comments': '',
      'Geologist': '',
    }];

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'constantRateTest',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: ['Borehole No.',
      'Pumping Type',
      'Depth To Water (m)',
      'Start Date',
      'End Date',
      'Start Time',
      'End Time',
      'Satatic Water Level',
      'Draw Down',
      'Pumping Rate (mᵌ/hr)',
      'Time To Fill (l)',
      'Time To Fill  (time)',
      'Tds',
      'Temperature (℃)',
      'pH (S/m)',
      'Electrical Conductivity (S/m)',
      'Observation Boreholes',
      'Comments',
      'Geologist']
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }


  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
    } else {
      this.fileReset();
    }
  }


  getAllBoreholesLite(){
    this.geoTechService.getAllBoreholesLite().subscribe(value => {
      this.boreholes = value as Notes [];
    });
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  stringIsNumber(s) {
    var x = +s; // made cast obvious for demonstration
    return x.toString() === s;
  }


  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];
    let dataError = '';
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        if(this.stringIsNumber(data[2]) && this.stringIsNumber(data[8]) && this.stringIsNumber(data[9]) &&
          this.stringIsNumber(data[10]) && this.stringIsNumber(data[12]) &&  this.stringIsNumber(data[13]) &&
          this.stringIsNumber(data[14]) && this.stringIsNumber(data[15])) {
            const csvRecord: WaterConstantRateTest = new WaterConstantRateTest();
            csvRecord.borehole = this.boreholeDetails;
            csvRecord.pumpType =  data[1];
            csvRecord.depthToWater.unit.id = UnitEnum.meter;
            csvRecord.depthToWater.measure = data[2];
            csvRecord.startDate = new Date(data[3]);
            csvRecord.endDate = new Date(data[4]);
            csvRecord.startTime = data[5];
            csvRecord.endTime = data[6];
            csvRecord.staticWaterLevel = data[7];
            csvRecord.drawdown.unit.id = UnitEnum.meter;
            csvRecord.drawdown.measure = data[8];
            csvRecord.pumpingRate.unit.id = UnitEnum.pumpRate;
            csvRecord.pumpingRate.measure = data[9];
            csvRecord.timeToFillLiters.unit.id = UnitEnum.liter;
            csvRecord.timeToFillLiters.measure = data[10];
            csvRecord.timeToFillTime = data[11];
            csvRecord.tds.unit.id = UnitEnum.TDS;
            csvRecord.tds.measure = data[12];
            csvRecord.temperature.unit.id = UnitEnum.temperature;
            csvRecord.temperature.measure = data[13];
            csvRecord.ph.unit.id = UnitEnum.ph;
            csvRecord.ph.measure = data[14];
            csvRecord.electricalConductivity.unit.id = UnitEnum.electricalConductivity;
            csvRecord.electricalConductivity.measure = data[15];
            csvRecord.waterObservationBoreholes.push(null);
            csvRecord.comments.description = data[17];
            csvRecord.geologist = data[18];
            if(data[0]){
              dataArr.push(csvRecord);
            }
      }else{
          dataError = dataError + i + (i <=data.length?',':'')  ;
        }
      }
    }
    dataArr.forEach( (currentValue, index) => {
      if(currentValue.rock === ''){
        dataArr.splice(index, 1);
      }
    });
    this.toastr.warning( ' Record(s) : '+dataError+ '\n Do not meet integrity standard( Not a number)','CRT data Validation');
    this.csvDdataSource.data = dataArr;
    this.showCsvModal();
    return dataArr;
  }

  showCsvModal(): void {
    const createDialogRef = this.dialog.open(ConstantRateTestUploadComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data:this.csvDdataSource.data
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.fileReset();
    });
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
  }

}
