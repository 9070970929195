import { Injectable, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Company } from 'src/app/shared-kernel/entity/applied-geoscience/company/company';
import { ContactDetail } from 'src/app/common/entity/security/util/common/contact-detail';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CompanyValidation implements OnInit {
  company: Company;
  contactDetail: ContactDetail;

  validation_messages = {
    'company': [
      {type: 'required', message: 'Company name is required'}
    ],
    'companyCategory': [
      {type: 'required', message: 'Please select company category'},
    ],
    'companyType': [
      {type: 'required', message: 'Please select company type'},
    ],
    'website': [
      {type: 'required', message: 'Company website is required'},
    ]
  };

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  telephoneFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
  ]);

  constructor(private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }


  validateContactDetailsAsTheyAreAdded(contactDetail: ContactDetail): boolean {
    this.contactDetail = contactDetail;
    if (!contactDetail.address) {
      this.errorsmsg('Please make sure contact is provided');
      return false;
    } else if (!contactDetail.contactDetailType) {
      this.errorsmsg('Please make sure contact type is provided');
      return false;
    } else {
      return true;
    }
  }

  validateCompany(company: Company): boolean {
    this.company = company;
    if (this.company) {
      if (this.company.postalAddress !== null) {
        if (this.company.postalAddress.country) {
          if(!this.company.postalAddress.country.country || this.company.postalAddress.country.country == null) {
            delete this.company.postalAddress.country;
          }
        }
        if (this.company.postalAddress.district) {
          if(!this.company.postalAddress.district.name || this.company.postalAddress.district.name == null) {
            delete this.company.postalAddress.district;
          }
        }
        if (!this.company.postalAddress.country && !this.company.postalAddress.district
          && !this.company.postalAddress.boxNumber && !this.company.postalAddress.cityTown) {
          delete this.company.postalAddress;
        }
      }
      
      if (this.company.physicalAddress !== null) {
        if (this.company.physicalAddress.country) {
          if(!this.company.physicalAddress.country.country || this.company.physicalAddress.country.country == null) {
            delete this.company.physicalAddress.country;
          }
        }
     
        if (!this.company.physicalAddress.country && !this.company.physicalAddress.district
          && !this.company.physicalAddress.cityTown && !this.company.physicalAddress.plotNumber
          && !this.company.physicalAddress.streetName) {
          delete this.company.physicalAddress;
        }
      } 
    } else {
      this.errorsmsg('Company details NOT provided');
      return false;
    }
    return true;
  }

  validateInteger(digit): boolean {
    if (isNaN(Number(digit))) {
      return true;
    } else {
      return false;
    }
  }
}
