export enum BusinessUnitEnum {

  appliedGeoScience = 'Applied Geoscience',
  surveys = 'Surveys',
  laboratoryServices = 'Laboratory Services',
  mineralResources = 'Mineral Resources/Hydrocarbon',
  collections = 'Collections',
  iS = 'Information Systems',
  iT = 'Information Technology',
  hr = 'Human Resources',
  finance = 'Finance',
  pr= 'Marketing, PR- Communication',
  internalAudit = 'Internal Audit',
  risk = 'Risk Management/Strategy',
  secretary= 'Secretary',
}
