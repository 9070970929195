import { Observable } from 'rxjs';

import { Person, Honorific, IdType, Sex, User, SystemRole } from './_models/people.model';

export abstract class PeopleData {
    abstract getPeople(): Observable<Array<Person>>;
    abstract getPeopleWithUser(): Observable<Array<Person>>;
    abstract getAllUsersList(): Observable<Array<any>>;
    abstract getPersonById(personId: string): Observable<Person>;
    abstract getTitles(): Observable<Array<Honorific>>;
    abstract getIdTypes(): Observable<Array<IdType>>;
    abstract getGender(): Observable<Array<Sex>>;
    abstract createUser(person: Person): Observable<Person>;
    abstract updatePerson(person: Person): Observable<Person>;
    abstract checkUsername(username: string): Observable<{ exist: boolean, message: string }>;
    abstract editUser(person: Person): Observable<Person>;
    abstract resetPassword(username): Observable<any>;
    abstract getUserByUsername(username: string): Observable<User>;
    abstract addUserRoles(userId: string, roles: Array<SystemRole>): Observable<User>;
    abstract createPerson(person: Person): Observable<Person>;
    abstract getPeopleByIdList(ids: Array<string>): Observable<Array<Person>>;
}
