import { BusinessUnitEnum } from './../../shared-kernel/enumerations/business-unit-enum';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { Person } from '../../common/entity/security/profile/person';
import { UserService } from '../../auth/user/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from '../user-dashboard/user-dashboard.service';
import { Router } from '@angular/router';
import { LocalAuthorities } from './../../_auth/_providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorageConstants } from '../../_core/storage.constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  title = 'Dashboard';
  myactivities:any;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  currentPerson: any;
  currentUser: any;
  person: Person;
  numberOfActivities: any;
  loading = true;

  constructor(
    private _router: Router,
    private dashBoardService: DashboardService,
    private userService: UserService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
    private _toastr: ToastrService,
  ) {

  }

  ngOnInit() {

    this.myactivities = {
      myproject: '',
      myactivities: '',
      drafts: '',
      submitted_QA: '',
      submitted_manager: '',
      rejected: '',
      approved: ''
    }

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
      }, err => {
          this._toastr.warning(err.message, "Current User Error");
      },() => {

      });
  }


  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
        if (res) {
            this.currentPerson = res;
            this.currentPerson['user'] = this.currentUser;
            this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});
        }
    }, err => {
        this._toastr.error(err.message, "Profile Details");
    },() => {
    });
  }

}
