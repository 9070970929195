import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { DrillingRequestApproveAction } from './../../../shared-kernel/entity/driller/drilling-request';
import { DrillerService } from './../../../driller/driller.service';

@Component({
    selector: "dr-approval-modal",
    templateUrl: './approval-modal.html',
    styleUrls: ['./approval-modal.scss']
})
export class ApprovalModalComponent implements OnInit {
    titleMesage: string = "";
    buttonMessage: string = "";

    approveForm: FormGroup;
    submitted: Boolean = false;

    constructor (
        private _fb: FormBuilder,
        public dialogRef: MatDialogRef<ApprovalModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { action: DrillingRequestApproveAction, drillingRequestId: string },
        private drillerService: DrillerService
    ) {

        switch (data.action) {
            case DrillingRequestApproveAction.MANAGER_ACTIVATE:
                this.titleMesage = "Reactivate";
                this.buttonMessage = "Reactivate";
                break;
            case DrillingRequestApproveAction.MANAGER_APPROVE:
                this.titleMesage = "Approve";
                this.buttonMessage = "Approve";
                break;
            case DrillingRequestApproveAction.MANAGER_PEND:
                this.titleMesage = "Update user";
                this.buttonMessage = "Update User";
                break;
            case DrillingRequestApproveAction.MANAGER_REJECT:
                this.titleMesage = "Return To User";
                this.buttonMessage = "Return To User";
                break;
            case DrillingRequestApproveAction.SUBMIT_TO_MANAGER:
                this.titleMesage = "Submit to manager";
                this.buttonMessage = "Submit Request";
                break;
            case DrillingRequestApproveAction.SUBMIT_TO_SUPERINTENDENT:
                this.titleMesage = "Submit to superintendent";
                this.buttonMessage = "Submit to Superintendent";
                break;
            case DrillingRequestApproveAction.SUPERINTENDENT_REJECT:
                this.titleMesage = "Return To Manager";
                this.buttonMessage = "Return To Manager";
                break;
            case DrillingRequestApproveAction.SUPERINTENDENT_SUBMIT_TO_MANAGER:
                this.titleMesage = "Submit to manager";
                this.buttonMessage = "Submit to manager";
                break;
            case DrillingRequestApproveAction.APPROVE_FOR_OUTSOURCE:
                this.titleMesage = "Approve For Outsource";
                this.buttonMessage = "Approve For Outsource";
                break;
            case DrillingRequestApproveAction.MANAGER_RETURN_TO_SUPERINTENDENT:
                this.titleMesage = "Return to superintendent";
                this.buttonMessage = "Submit to superintendent";
                break;
        }
    }

    ngOnInit () {
        this._initForm();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.approveForm.invalid) return;

        this.submitted = true;

        this.drillerService.changeState(this.data.action, this.data.drillingRequestId, this.approveForm.value.comment).subscribe(res => {
            this.submitted = false;
            this.dialogRef.close(res);
        }, err => {
            this.submitted = false;
        });
    }

    private _initForm() {
        this.approveForm = this._fb.group({
            comment: [null, []]
        });

        const commentT = this.approveForm.get("comment");

        switch (this.data.action) {
            case DrillingRequestApproveAction.MANAGER_ACTIVATE:
            case DrillingRequestApproveAction.MANAGER_PEND:
            case DrillingRequestApproveAction.MANAGER_REJECT:
            case DrillingRequestApproveAction.SUPERINTENDENT_REJECT:
                commentT.setValidators([Validators.required]);
                break;
            case DrillingRequestApproveAction.MANAGER_RETURN_TO_SUPERINTENDENT:
                commentT.setValidators([Validators.required]);
                this.data.action = DrillingRequestApproveAction.SUBMIT_TO_SUPERINTENDENT;
                break;
            case DrillingRequestApproveAction.SUPERINTENDENT_SUBMIT_TO_MANAGER:
            case DrillingRequestApproveAction.MANAGER_APPROVE:
            case DrillingRequestApproveAction.SUBMIT_TO_MANAGER:
            case DrillingRequestApproveAction.SUBMIT_TO_SUPERINTENDENT:
                break;
        }
    }
}
