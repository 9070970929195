import { Pipe, PipeTransform } from '@angular/core';

@Pipe({  name: 'enumToWords' })
export class EnumToWords implements PipeTransform {
    transform(enumConst: string, args?: any): any {
        if (enumConst) {
            const words = enumConst.split('_');
            let finalWord = '';
            
            words.forEach(w => {
                finalWord = finalWord + this.capitalizeFirstLetter(w) + ' ';
            });

            return finalWord.trim();
        }
    };

    private capitalizeFirstLetter(word: string): string {
        const cased = word.toLocaleLowerCase();
        return cased.charAt(0).toUpperCase() + cased.slice(1);
    }
}