import { CustomValidators } from './../../services/custom-validators';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../../_helpers/must-match.validator';
import { UserService } from '../../../auth/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { WaterBearingZones } from '../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import { User } from '../../../_core/data/_models/people.model';

@Component({
  selector: 'app-set-my-password',
  templateUrl: './set-my-password.component.html',
  styleUrls: ['./set-my-password.component.scss']
})
export class SetMyPasswordComponent implements OnInit {

  passwordChangeForm: FormGroup;
  typePass:string = 'password';
  typePass1:string = 'password';
  typePass2:string = 'password';
  username:string;
  oldPassword:string;
  passView = true;
  passView2 = true;
  passView1 =  true;
  submitted: boolean;
  constructor(private formBuilder: FormBuilder, private usersService: UserService, private toast: ToastrService,
              public dialogRef: MatDialogRef<SetMyPasswordComponent>,
              @Inject(MAT_DIALOG_DATA) public data: User) {


  }

  ngOnInit() {
    this.passwordChangeForm = this.formBuilder.group({
      currentPassword: ['',[Validators.required]],
      newPassword: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],
      confirmPassword: [
        null,
        Validators.compose([
          Validators.required,
        ])
      ],
    },
    {
      // validator: MustMatch('newPassword', 'confirmPassword')
      validator: CustomValidators.passwordMatchValidator
    })
  }

  onSubmit(){
    this.submitted = true;
    if(this.passwordChangeForm.valid){

      let passwordStore: any = {
        username: this.data.username,
        newPassword: btoa(this.passwordChangeForm.controls.newPassword.value),
        oldPassword: btoa(this.passwordChangeForm.controls.currentPassword.value),
        isReset: false
      };

      this.usersService.setUserPassword(passwordStore).subscribe(value => {
        if(value){
          this.toast.success('Passsword change, successful','Password Reset');
          this.dialogRef.close();
        }else{
          this.toast.warning('Your Current password is incorrect','Password Reset')
        }
      },error => {
        this.toast.error(error.message.error,'Password Reset')
      },() => {

      });

    }
  }

  closeModal() {
      this.dialogRef.close();
  }


  togglePass() {
    this.passView2 = !this.passView2;
    if (!this.passView2) {
      this.typePass = 'text';
    } else {
      this.typePass = 'password';
    }
  }

  togglePass1() {
    this.passView1 = !this.passView1;
    if (!this.passView1) {
      this.typePass1 = 'text';
    } else {
      this.typePass1 = 'password';
    }
  }

  showPass() {
    this.passView = !this.passView;
    if (!this.passView) {
      this.typePass2 = 'text';
    } else {
      this.typePass2 = 'password';
    }
  }

}
