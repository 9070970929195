import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceEndPoints} from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import {PLDrillerLog} from './PLDrillerLog';
import {DrillerLogRecord} from '../../../../shared-kernel/entity/applied-geoscience/drillerlog/drillerlogrecord';

@Injectable({
  providedIn: 'root'
})
export class PlDrillerLogService {

  constructor(private http: HttpClient) {
  }

  createPLDrillerLog(log: PLDrillerLog): Observable<PLDrillerLog> {
    return this.http.post<PLDrillerLog>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/pl-driller-log/v1/create', log);
  }
  createPLDrillerLogs(plLogRecords: PLDrillerLog[]): Observable<PLDrillerLog[]> {
    return this.http.post<PLDrillerLog[]>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/pl-driller-log/v1/records/create', plLogRecords);
  }

  updatePLDrillerLog(log: PLDrillerLog): Observable<PLDrillerLog> {
    return this.http.put<PLDrillerLog>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/pl-driller-log/v1/update', log);
  }

  getAllPLDrillerLogByBoreholeId(plBoreholeId: string): Observable<PLDrillerLog[]> {
    return this.http.get<PLDrillerLog[]>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/pl-driller-log/v1/pl-borehole/${plBoreholeId}`);
  }

  getPLDrillerLogById(plBoreholeId: string): Observable<PLDrillerLog> {
    return this.http.get<PLDrillerLog>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/pl-driller-log/v1/${plBoreholeId}`);
  }

  deletePLDrillerLogById(plDriller: PLDrillerLog): Observable<any> {
    return this.http.post<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/pl-driller-log/v1/delete`, plDriller);
  }

  deleteAllPLDrillerLogById(plBoreholeId: string): Observable<any> {
    return this.http.post<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/pl-driller-log/v1/deleteAll`, plBoreholeId);
  }

}
