import { Size } from '../../common/size';
import { Borehole } from '../../common/borehole';

export class WaterPumpingTestRecovery {
    id: string;
    depthToWaterLevel: Size;
    residualDrawdown: Size;
    startDate: Date;
    endDate: Date;
    time: number;
    startTime: Date;
    endTime: Date;
    observationBorehole: Borehole;
    waterPumpingTestRecoveryObservation: WaterPumpingTestRecovery
    geologist: string;
    comments: string;
    borehole: Borehole;
    deleted: boolean;
    creator: String;
    created: Date;
}
