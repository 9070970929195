import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { NigisDocImage } from './nigi-image.model';

@Injectable({
    providedIn: 'root'
})
export class DocImageService {
    constructor (
        private _http: HttpClient
    ) {}

    addFileDetails(docImage: NigisDocImage): Observable<NigisDocImage> {
        return this._http.post<NigisDocImage>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/v1/nigis_doc_images/image_details', docImage);
    }

    getEntityImages(entityId: string, tag: string): Observable<Array<any>> {
        return this._http.get<Array<any>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/v1/nigis_doc_images/image_details/entities/${entityId}/${tag}`);
    }

    getEntityImagesCount(entityId: string, tag: string) {
      return this._http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/v1/nigis_doc_images/image_details/entities/${entityId}/${tag}/count`);
    }

    getImageFile(fullUrl: string): Observable<any> {
        return this._http.get<any>(fullUrl, { responseType: 'blob' as 'json' });
    }

    deleteImageFile(id: string): Observable<void> {
        return this._http.delete<void>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/v1/nigis_doc_images/document_details/${id}`);
    }

    batchDeleteImageFile(images:any[]) {
      return this._http.post<void>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/v1/nigis_doc_images/document_details/batch`,images);
    }
}
