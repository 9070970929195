import {Size} from '../../common/size';
import {CasingMaterial} from '../geoTech/CasingMaterial';
import {ScreeningCasing} from './ScreeningCasing';
import {Borehole} from '../../common/borehole';

export class ScreeningLog {
  id: string = '';
  screeningId: string = '';
  constructionType: string = '';
  diameter: Size = new Size();
  depthFrom: Size = new Size();
  depthTo: Size = new Size();
  borehole: Borehole = new Borehole();
  slotNumber: string = '';
  material: CasingMaterial = new CasingMaterial();
  deleted: boolean = false;
}
