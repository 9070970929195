import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { SoilLog } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log';
import { TrialPit } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { SoilLogCreateComponent } from '../soil-log-create/soil-log-create.component';
import { SoilLogService } from '../soil-log.service';

@Component({
  selector: 'app-soil-log-details',
  templateUrl: './soil-log-details.component.html',
  styleUrls: ['./soil-log-details.component.scss']
})
export class SoilLogDetailsComponent implements OnInit {

  soilLogId: string;
  soilLog: SoilLog;
  trialPit: TrialPit;
  trialPitId: string;
  dialogConfig = new MatDialogConfig();

  constructor(
    private soilLogService: SoilLogService,
    private _router: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this._router.paramMap.subscribe(params => {
      this.soilLogId = params.get('id');
      this.trialPitId = params.get('trialPitId');
      this.getSoilLogById();
      this.getTrialById();
    });

  }


  getSoilLogById() {
    this.soilLogService.getSoilLogById(this.soilLogId)
      .subscribe(data => {
        this.soilLog = data as SoilLog;
      });
  }

  getTrialById() {
    if (this.trialPitId != null) {
      this.soilLogService.getTrialPitById(this.trialPitId)
        .subscribe(data => {
          this.trialPit = data as TrialPit;
        });
    }
  }

  onEdit(soilLog: SoilLog) {
    this.dialogConfig.data = {
      trialPit: {id: this.trialPitId},
      soilLog: soilLog
    };

    this.dialog.open(SoilLogCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
        this.getTrialById();
    });
  }

}
