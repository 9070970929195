import { Component, OnInit } from '@angular/core';
import { DrillerLogRecord } from '../../../shared-kernel/entity/applied-geoscience/drillerlog/drillerlogrecord';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../auth/user/user.service';
import { DrillerlogService } from '../drillerlog.service';
import { GeoTechService } from '../../../applied-geoscience/geo-tech/geo-tech.service';
import { Borehole } from '../../../shared-kernel/entity/common/borehole';

@Component({
  selector: 'app-drilling-log-details',
  templateUrl: './drilling-log-details.component.html',
  styleUrls: ['./drilling-log-details.component.scss']
})
export class DrillingLogDetailsComponent implements OnInit {

  drillingLogDetails: DrillerLogRecord;
  drillingLogId: string;
  borehole: Borehole;

  constructor( private drillerLogService: DrillerlogService, private geoTechService: GeoTechService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.drillingLogId = params.id;
    }); }

  ngOnInit() {
    this.getDrillingLogById();

  }

  getDrillingLogById(){
      this.drillerLogService.getDrillerLogRecordById(this.drillingLogId).subscribe(
        value => {
          this.drillingLogDetails =  value as DrillerLogRecord;
        },error => {},() => {
          this.getBoreholeById(this.drillingLogDetails.boreholeLogRecord);
        }
      );
  }

  getBoreholeById(boreholeID){
      this.geoTechService.getBoreholeById(boreholeID).subscribe(value => {
        this.borehole = value as Borehole;
      });
  }

}
