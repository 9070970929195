import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeoTechService } from '../../../geo-tech.service';
import { Sample } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/Sample';

@Component({
  selector: 'app-sample-tests',
  templateUrl: './sample-tests.component.html',
  styleUrls: ['./sample-tests.component.scss']
})
export class SampleTestsComponent implements OnInit {

  @Input('entityId') entityId: string = '';
  @Input('sampleNumber') sampleNumber: string;
  @Input('currentState') currentState: string ='';
  @Input('projectId') projectId: string ='';

  selectedTab: number;
  sampleId: string;
  isSampleTypeRock: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private geoTechService: GeoTechService,) {
    router.params.subscribe(
      data => {
        this.sampleId = data.id;
        if (data.tab) {
          this.selectedTab = data.tab;
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  ngOnInit() {
    this.getSampleBySampleId(this.sampleId);
  }

  getSampleBySampleId(id: string) {
    this.geoTechService.getSampleById(this.sampleId).subscribe(data => {
      let samplesDetails = data as Sample;
      if (samplesDetails.sampleTypes) {
        for (let sampleType of samplesDetails.sampleTypes) {
          if (sampleType.name === 'Rock') {
            this.isSampleTypeRock = true;
          }
        }
      }
    }, error => {
    }, () => {
    });
  }

}
