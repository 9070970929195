import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Disable edit functionality on Root users/System admin Roles
 * * If the User on the list is a 'root user'/system admin'
 * then the templated elements (edit button) will be removed from the DOM
 */
@Directive({
  selector: '[appUserEditable]'
})
export class UserEditableDirective {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }
  @Input() set appUserEditable(userName: string) {

    if ((userName != 'super@bitri.co.bw'))  {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

