import { Component, Input, OnInit } from '@angular/core';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/applied-geoscience/geo-tech/core/core.service';

@Component({
  selector: 'app-water-pump-test-logs',
  templateUrl: './water-pump-test-logs.component.html',
  styleUrls: ['./water-pump-test-logs.component.scss']
})
export class WaterPumpTestLogsComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('borehole') borehole: Borehole;
  @Input('projectId') projectId: string = '';
  boreholeNumber: string;
  boreholeId: string;
  selectedTab: number;

  constructor(
    private router: ActivatedRoute
  ) { }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.boreholeId = params.id;
    });
    this.boreholeNumber = this.borehole.boreholeNumber;
  }

}
