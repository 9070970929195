import {Component,  OnInit} from '@angular/core';
import {VettingPortfolioCounts} from "../model/VettingPortfolioCounts";
import {User} from "../../_core/data/_models/people.model";
import {Person} from "../../common/entity/security/profile/person";
import {BusinessUnitEnum} from "../../shared-kernel/enumerations/business-unit-enum";
import {Router} from "@angular/router";
import {GlobalDashboardService} from "../global-dashboard.service";
import {ToastrService} from "ngx-toastr";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ActivitiesService} from "../../activities/services/activities.service";
import {StorageConstants} from "../../_core/storage.constants";
import {MatTableDataSource} from "@angular/material/table";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-superintendent-dashboard-ui',
  templateUrl: './superintendent-dashboard-ui.component.html',
  styleUrls: ['./superintendent-dashboard-ui.component.scss']
})
export class SuperintendentDashboardUiComponent implements OnInit {

  superintendentDashboardCounts: any =[];

  loading = true;
  public currentUser: User;
  colors: any;

  displayedColumns: string[] = ['number', 'date', 'projectName', 'drillingMethod', 'boreholeType', 'status'];
  requestDataSource = new MatTableDataSource<any>();
  dataSource = new MatTableDataSource<any>();
  /** Based on the screen size, switch from standard to one column per row */
  cards: any;
  stillLoadingActivities = false;

  public myactivitiesLabels = [ 'Drilling-Requests','Draft', 'Submitted-Manager',  'Returned'];
  public myactivitiesData = [ 91, 107, 73, 107];

  public pieChartType = 'pie';
  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  };

  public currentPerson: Person;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;

  constructor(private _router: Router,
              private _toaster: ToastrService,
              private _localStorage: LocalStorage,
              private globalDashBoardService: GlobalDashboardService,
              private _activitiesServ: ActivitiesService,
              private breakpointObserver: BreakpointObserver,) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'Drilling Requests', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'Drilling Requests', cols: 1, rows: 3 , id: 'myRequests'},
          { title: 'Recent Drilling Requests', cols: 1, rows: 3 , id: 'recents'}
        ];
      })
    );


  }

  async getSuperintendentDashboardCounts() {

    await this.globalDashBoardService.getSuperintendentDashboardCounts().subscribe(value => {
      this.superintendentDashboardCounts = value as VettingPortfolioCounts;

      if (this.superintendentDashboardCounts.sumbmittedQA === null){
        this.superintendentDashboardCounts.sumbmittedQA =  '0'
      }

      if (this.superintendentDashboardCounts.sumbmittedManager === null){
        this.superintendentDashboardCounts.sumbmittedManager =  '0'
      }


      this.myactivitiesData = [Number(this.superintendentDashboardCounts.pendingApproval)
        ,Number(this.superintendentDashboardCounts.drafts),
        Number(this.superintendentDashboardCounts.sumbmittedManager), Number(this.superintendentDashboardCounts.returned)];

      this.stillLoadingActivities = true;
    });

  }


  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toaster.error(err.message, "Profile Details");
    });
  }

  ngOnInit() {
    this.getSuperintendentDashboardCounts();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson();
    }, err => {
      this._toaster.warning(err.message, "Current User Error");
    });
    this.getRecentADrillingRequest();
    this.colors = [{backgroundColor: [  '#573419','#00A2E5',
    '#C8A207','#CC0000','#009900', '#CC9933']}];
  }


  gotoDRillingRequest(drillingRequest){
    const drillingRequestId = {id: drillingRequest.id};
    this._router.navigate(['/portal/drilling/request/detail', drillingRequestId]);
  }

  chartClickedActivities(event) {
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/drilling_requests/SUPERINTENDENT_DRILLING_REQUESTS']);
        break

      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/drilling_requests/SUPERINTENDENT_DRAFTS']);
        break

      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/drilling_requests/SUPERINTENDENT_MANAGER_SUBMITTED']);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate(['portal/activities/drilling_requests/SUPERINTENDENT_REJECT']);
      default:
    }
  }

  goTo(requestState: string) {
    this._router.navigate([`portal/activities/drilling_requests/${requestState}`]);
  }

  getRecentADrillingRequest() {
    this.loading = false;
    this._activitiesServ.getRecentDrillingRequest().subscribe(value => {
      this.requestDataSource.data = this.requestDataSource.data ? this.requestDataSource.data.concat(this.sliceResultArray(value)) : this.sliceResultArray(value);
    });
  }

  openMyActivityDetails(activity) {
    const id = {id: activity.id};
    this._router.navigate(['portal/drilling/request/detail', id]);
  }
  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }
}
