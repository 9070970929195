export enum ActivityType {
    WATER_BOREHOLE = 0,
    PL_BOREHOLE = 1,
    BGI_BOREHOLE = 2,
    TRIAL_PIT = 3,
    DCP_FOUNDATION = 4,
    DCP_ROADWORKS = 5,
    INFILTRATION = 6,
    SAMPLE = 7,
}

export enum CollectionsActivityType {
  CORE_TRAY = 0,
  CHIPS_TRAY = 1,
}

export interface Activity {
    id: string;
    activityType: number;
    number: string;
    name: string;
    location: string;
    sampleType: string;
    status: string;
    geologist: string;
    created: Date
}

export interface CollectionsActivity {
  id: string;
  activityType: number
  name: string;
  boreholeNumber: string;
  trayNumber: string;
  position: string;
  sampleType: string;
  depth: string;
  status: string;
  inventoryId: string;
  shelvingId: string;
  archivist: string;
}

export interface CustomerRequestActivity {
  id: string;
  activityType: number;
  name: string;
  boreholeNumber: string;
  customerName: string;
  requestDate: Date;
  serviceRequest: string;
  status: string;
  creator: string;
  needManagerApproval: boolean;
}

export interface MineralResourcesActivities {
  id: string;
  activityType: number
  name: string;
  number: string;
  sampleType: string;
  status: string;
  personnel: string;
  projectName: string;
  collectedBy: string;
  collectionDate: Date;
  sampleRole: string;

}


export interface MineralResourcesActivitiesFiles {
  id: string;
  name: string;
  description: string;
  fileType: string;
  creation: string;
  status: string;
}
