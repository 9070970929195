import { ApprovalState } from './../../../applied-geoscience/models/approval-state';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minerals-tech-dashboard',
  templateUrl: './minerals-tech-dashboard.component.html',
  styleUrls: ['./minerals-tech-dashboard.component.scss']
})
export class MineralsTechDashboardComponent implements OnInit {

  userName: string;
  numberOfMyProjects = 0;
  numberOfBGIProjects = 0;
  stillLoadingCounts = true;
  stillLoadingMyActivitiesCounts = true;
  stillLoadingDraftsCounts = true;
  stillLoadingSubmittedToGeologistCounts = true;
  stillLoadingSubmittedToManagerCounts = true;
  stillLoadingReturnedByGeologistCounts = true;
  stillLoadingReturnedByManagerCounts = true;
  stillLoadingApprovedCounts = true;

  activitiesCount = {
    myActivities: 0,
    drafts: 0,
    submittedToGeologist: 0,
    submittedToManager: 0,
    returnedByGeologist: 0,
    returnedByManager: 0,
    approved: 0
    }

    approvalEnum: typeof ApprovalState = ApprovalState;

  constructor(
    private userDashBoardService: UserDashboardService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
   }

  ngOnInit() {
    this.getMyProjectsCount();
    this.getUserActivities();
    this.getDrafts();
    this.getSubmittedToGeologist();
    this.getSubmittedToManagerByGeoForTech();
    this.getActivitiesRejectedByGeologist();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
  }

  getMyProjectsCount() {
    this.stillLoadingCounts = true;
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.numberOfMyProjects = number;
      this.getBGIProjectsCount();
    });
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
      this.stillLoadingCounts = false;
    });
  }

  getUserActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount('all').subscribe(number => {
      this.activitiesCount.myActivities = number;
      this.stillLoadingMyActivitiesCounts = false;
    });
  }

  getDrafts(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PENDING_GEOLOGIST_SUBMISSION).subscribe(number => {
      this.activitiesCount.drafts = number;
      this.stillLoadingDraftsCounts = false;
    });
  }

  getSubmittedToGeologist(){
      this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_GEOLOGIST).subscribe(number => {
        this.activitiesCount.submittedToGeologist = number;
        this.stillLoadingSubmittedToGeologistCounts = false;
      });
  }

  getSubmittedToManagerByGeoForTech(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_MANAGER_BY_GEOLOGIST).subscribe(number => {
      this.activitiesCount.submittedToManager = number
      this.stillLoadingSubmittedToManagerCounts = false;
    });
  }

  getActivitiesRejectedByGeologist(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_GEOLOGIST).subscribe(number => {
      this.activitiesCount.returnedByGeologist = number;
      this.stillLoadingReturnedByGeologistCounts = false;
    });
  }

  getActivitiesRejectedByManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.activitiesCount.returnedByManager = number;
      this.stillLoadingReturnedByManagerCounts = false;
    });
  }

  getApprovedActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.activitiesCount.approved = number;
      this.stillLoadingApprovedCounts = false;
    });
  }

}
