import { Component, OnInit } from '@angular/core';
import { Person, User } from '../../../_core/data/_models/people.model';
import { MatTableDataSource } from '@angular/material/table';
import { Activity } from '../../../activities/models';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../../../_auth/_providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ActivitiesService } from '../../../activities/services/activities.service';
import { map } from 'rxjs/operators';
import { StorageConstants } from '../../../_core/storage.constants';
import { State } from '../../../activities/models/state';

@Component({
  selector: 'app-mineral-resource-borehole-dashboard',
  templateUrl: './mineral-resource-borehole-dashboard.component.html',
  styleUrls: ['./mineral-resource-borehole-dashboard.component.scss']
})
export class MineralResourceBoreholeDashboardComponent implements OnInit {


  public currentPerson: Person;
  numberOfBGIProjects = 0;
  allVettedCount = 0;
  myBorehole = {
    numberOfMyBoreholes:0,
    numberOfProjectsDrafts:0,
    numberOfProjectsSubmittedToQA:0,
    numberOfProjectsSubmittedToManager:0,
    numberOfProjectsApproved:0,
    numberOfProjectsReturned:0
  };
  loading = true;
  state = new State();
  isSet = true;
  public currentUser: User;
  displayedColumns: string[] = ['#', 'activities', 'status', 'date'];
  dataSource = new MatTableDataSource<any>();
  /** Based on the screen size, switch from standard to one column per row */
  cards :any;
  public myBoreholeOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }
    },
    hover: {
      onHover: function(e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    }
  };

  public myBoreholeLabels:string[] = ['Pending-Approval', 'Submitted-Manager', 'Returned','Approved'];
  public myBoreholesData = [ 91, 107, 73, 107];
  public pieChartType = 'pie';

  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  colors: any ;
  colorBorehole:any;
  isssetData = false;
  isRoleManager = false;
  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();


  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,private _activitiesServ: ActivitiesService,) {

    //this.spinner.show();
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'Borehole Activities',cols: 1, rows: 3 ,id: 'myprojects'},
          { title: 'Pending Approval', cols: 1, rows: 3 , id: 'Drafts'},
          { title: 'Returned', cols: 1, rows: 3 ,id: 'Returned'},
          { title: 'Approved', cols: 1, rows: 3 ,id: 'Approved'}
        ];
      })
    );


  }

  ngOnInit() {
    this.initPerson();
    this.colorBorehole = [{backgroundColor: ['#573419','#CC9933','#CC0000','#009900']}];
  }


  getBoreholeChartData(isRoleManager){
    if(isRoleManager){
      this.userDashBoardService.getBoreholeMineralResourcesVettingCount(this.state.SUBMITTED_TO_MANAGER.label).subscribe(pendingApproval => {
        this.userDashBoardService.getBoreholeMineralResourcesVettingCount(this.state.PUBLISHED.label).subscribe(approved => {
          this.userDashBoardService.getBoreholeMineralResourcesVettingCount(this.state.REJECTED_BY_MANAGER.label).subscribe(rejected => {
            this.myBoreholesData = [pendingApproval,undefined,rejected,approved];
            this.allVettedCount = approved + rejected;
          })
        })
      });

      this.isssetData = true;
    }else{
      this.userDashBoardService.getBoreholeMineralResourcesVettingCount(this.state.SUBMITTED_TO_QA.label).subscribe(pendingApproval => {
        this.userDashBoardService.getBoreholeMineralResourcesVettingCount(this.state.SUBMITTED_TO_MANAGER.label).subscribe(approved => {
          this.userDashBoardService.getBoreholeMineralResourcesVettingCount(this.state.REJECTED_BY_QA.label).subscribe(rejected => {
            this.myBoreholesData = [pendingApproval,approved,rejected,undefined];
            this.allVettedCount = approved + rejected;
          })
        })
      });
      this.isssetData = true;
    }
  }


  chartClickedBoreholes(event){
    switch (event.active[0]._view.label) {
      case this.myBoreholeLabels[0]:{
        if(this.isRoleManager){
          this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/'+this.state.SUBMITTED_TO_MANAGER.label]);
        }else{
          this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/'+this.state.SUBMITTED_TO_QA.label]);
        }
        break;
      }
      case this.myBoreholeLabels[1]:
        this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/SUBMITTED_TO_MANAGER']);
        break;
      case this.myBoreholeLabels[2]:{
        if(this.isRoleManager){
          this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/'+this.state.REJECTED_BY_MANAGER.label]);
        }else{
          this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/'+this.state.REJECTED_BY_QA.label]);
        }
        break
      }
      case this.myBoreholeLabels[3]:{
        if(this.isRoleManager){
          this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/PUBLISHED']);
        }
        break
      }
      default:
    }
  }


  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    }, () => {
      this._localAuthorities.getFullUser(this.currentPerson.user.username).subscribe(
        value => {
          this.isRoleManager = value.roles.filter(role => role.name==='Manager').length >0;
          this.getBoreholeChartData(this.isRoleManager);
          this.getTableData(this.isRoleManager);
      });
    });
  }


  getTableData(isRoleManager){

        if(isRoleManager){
            this.userDashBoardService.getBoreholeMineralResourcesVettingRecents(this.state.SUBMITTED_TO_MANAGER.label).subscribe(res => {
                this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
              }, err => {
              this._toastr.warning(err.message, 'Failed to get Pending activities');
            });
            this.userDashBoardService.getBoreholeMineralResourcesVettingRecents(this.state.REJECTED_BY_MANAGER.label).subscribe(res => {
                this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
              }, err => {
              this._toastr.warning(err.message, 'Failed to get Rejected activities');
            });
            this.userDashBoardService.getBoreholeMineralResourcesVettingRecents(this.state.PUBLISHED.label).subscribe(res => {
                this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
              }, err => {
              this._toastr.warning(err.message, 'Failed to get activities');
            });
        }
        else{
            this.userDashBoardService.getBoreholeMineralResourcesVettingRecents(this.state.SUBMITTED_TO_QA.label).subscribe(res => {
              this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
              }, err => {
                this._toastr.warning(err.message, 'Failed to get Pending activities');
            });
            this.userDashBoardService.getBoreholeMineralResourcesVettingRecents(this.state.REJECTED_BY_QA.label).subscribe(res => {
              this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
              }, err => {
                this._toastr.warning(err.message, 'Failed to get Rejected activities');
            });
            this.userDashBoardService.getBoreholeMineralResourcesVettingRecents(this.state.SUBMITTED_TO_MANAGER.label).subscribe(res => {
                this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
              }, err => {
              this._toastr.warning(err.message, 'Failed to get activities');
            });
        }

  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }


  goto(type,filter){
    if(this.isRoleManager){
      switch(filter){
        case 'pendingApproval':
          filter = this.state.SUBMITTED_TO_MANAGER.label;
          break;
        case 'returned':
          filter = this.state.REJECTED_BY_MANAGER.label;
          break;
        case 'approved':
          filter = this.state.PUBLISHED.label;
          break;
      }
    }else{
      switch(filter){
        case 'pendingApproval':
          filter = this.state.SUBMITTED_TO_QA.label;
          break;
        case 'returned':
          filter = this.state.REJECTED_BY_QA.label;
          break;
        case 'approved':
          filter = this.state.SUBMITTED_TO_MANAGER.label;
          break;
      }
    }
    this._router.navigate([`portal/activities/mineral-resources/${type}/${filter}`]);

  }



}
