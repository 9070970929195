import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreService } from "../core/core.service";
import { SpecimenService } from "../../../collections/views/geoheritage/geological-specimen/services/specimen.service";
import { ToastrService } from "ngx-toastr";
import { Borehole } from "src/app/shared-kernel/entity/common/borehole";

@Component({
  selector: "app-depth-analytical-reports",
  templateUrl: "./depth-analytical-reports.component.html",
  styleUrls: ["./depth-analytical-reports.component.scss"],
})
export class DepthAnalyticalReportsComponent implements OnInit {
  @Input() logs: any[];
  data: any[] = [];

  scalingFactor = 50;

  rockTypes = [
    "igneous-rock.jpg",
    "metamorphic-rock.jpg",
    "sedimentary-rock.jpg",
  ];
  rockNames = ["Igneous Rock", "Metamorphic Rock", "Sedimentary Rock"];
  filteredGroups = [];
  @Input() borehole;

  constructor(
    private router: ActivatedRoute, private coreService: CoreService,private specimenServ: SpecimenService,private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.data = [];
    const totalDepth = this.getTotalDepth(this.logs);
    this.logs.forEach((log) => {
      
      const percentage = (((((log.to) - (log.from)) / totalDepth) * 100));

      this.data.push({
        thickness: { from: log.from, to: log.to },
        penetration: {},
        percentage: percentage,
        height: percentage + parseFloat(this.scalingFactor + ""),
        color: log.color,
        sample: log.image
          ? log.image
          : "Image N/A",
        type: log.type,
        description: log.description,
      });
    });
  }

  getTotalDepth(logs: any[]): number {
    let totalDepth = 0;
    logs.forEach((log) => {
      totalDepth += log.to - log.from;
    });

    return totalDepth;
  }

  formatData() {
    this.data = [];
    this.router.params.subscribe(
      param => {
        this.coreService.getBoreholeById(param['id']).subscribe(borehole => {

          this.borehole = borehole;

          const totalDepth = this.getTotalDepth(this.borehole.boreholeLithologyLogs);
          this.borehole.boreholeLithologyLogs.forEach(log => {

            const percentage = (((((log.depthTo.measure) - (log.depthFrom.measure)) / totalDepth) * 100));

            const index = this.rockNames.indexOf(log.rockName.rock.trim());

            this.data.push(
              {
                thickness: {from: log.depthFrom.measure, to: log.depthTo.measure},
                penetration: {},
                percentage: percentage,
                height: percentage + parseFloat(this.scalingFactor + ''),
                color: log.color,
                sample: log.rockName.rockImages[0]?log.rockName.rockImages[0].image:'Image N/A',
                type: log.rockName.rock,
                description: log.lithologyDescription.description
              });
          });
        });
      });
  }
}
