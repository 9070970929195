import {Component, Inject, OnInit} from '@angular/core';
import {Borehole} from '../../../../../shared-kernel/entity/common/borehole';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {RockType} from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/RockType';
import {UnitEnum} from '../../../../../shared-kernel/enumerations/unit-enum';
import {BoreholeLithologyLog} from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/BoreholeLithologyLog';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {HydrogeologyService} from '../../../../hydrogeology/hydrogeology.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CoreService} from '../../core.service';
import {ActivatedRoute} from '@angular/router';
import {CoreValidation} from '../../core.validation';
import { SampleType } from '../../../../../shared-kernel/entity/common/sample-type';
import { DrillerService } from '../../../../../driller/driller.service';
import { RockGroup } from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/RockGroup';
import { GeoTechService } from '../../../geo-tech.service';
import { RockGroupEnum } from '../../../../../shared-kernel/enumerations/rock-group-enum';
import { RockName } from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/RockName';
import { RockTypeEnum } from '../../../../../shared-kernel/enumerations/rock-type-enum';
import { RockSubGroupEnum } from '../../../../../shared-kernel/enumerations/rock-sub-group-enum';
import { Unit } from '../../../../../shared-kernel/entity/common/unit';

@Component({
  selector: 'app-core-lithology-create',
  templateUrl: './core-lithology-create.component.html',
  styleUrls: ['./core-lithology-create.component.scss']
})
export class CoreLithologyCreateComponent implements OnInit {
  borehole: Borehole;
  coreLithologyLog: BoreholeLithologyLog;
  isAdd: boolean = true;
  boreholeLithologyLogs: BoreholeLithologyLog[] = [];
  sampleStatusList: any[];
  url: string;
  boreholeId: string;
  rockTypeSelected: boolean =  false;
  isBasicType: boolean =  false;
  isGroupSymbols: boolean =  false;
  isVarious: boolean =  false;
  isMixedSymbol: boolean =  false;
  isGeneralRock : boolean =  false;
  isSubdivision: boolean =  false;
  isCorrespondingDyke: boolean =  false;
  rockGroupId: string;
  isPure: boolean = false;
  isImpurePure: boolean = false;
  isMetamorphicRocks: boolean =  false;
  isMagmaticRocksAndVarious: boolean =  false;
  isSedimentsAndSedimentaryRocks

  coreLithologyLogFormGroup: FormGroup;
  sampleTypes: SampleType[];
  rockGroups: RockGroup[] = [];
  rockNames: RockName[] = [];
  rockSymbolsForSubDivision: RockName[] = [];
  correspondingDykes: RockName[] = [];
  mixedTypes: RockName[] = [];
  groupSymbols: RockName[] = [];
  rockTypes: RockType[];
  enumUnits: typeof UnitEnum = UnitEnum;
  rockTypeEnum: typeof RockTypeEnum = RockTypeEnum;
  rockGroupEnum: typeof RockGroupEnum = RockGroupEnum;
  rockSubGroupEnum: typeof RockSubGroupEnum = RockSubGroupEnum;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<CoreLithologyCreateComponent>,
              private toaster: ToastrService,
              private drillerService: DrillerService,
              private coreService: CoreService,
              private geoTechService: GeoTechService,
              private formBuilder: FormBuilder,
              private hydrogeologyService: HydrogeologyService) {
  }

  ngOnInit() {
    this._getAllSampleStatus();
    this.getAllRockTypes();
    this.getAllSampleTypes();
    this.getCoreLithologyLogByBoreholeId(this.data.boreholeId);

  }

  changeRockType(value: RockType) {
      this.resetDropDown();
      this.getRockGroups(value.id);
      this.rockTypeSelected = true;
  }

  resetDropDown(){
    this.isMixedSymbol = false;
    this.isGeneralRock = false;
    this.isVarious = false;
    this.isGroupSymbols = false;
    this.isBasicType = false;
    this.isCorrespondingDyke = false;
    this.isSubdivision = false;
  }

  getCoreLithologyLogByBoreholeId(boreholeId: string) {
    this.coreService.getBoreholeLithologyLogByBoreholeId(boreholeId).subscribe(data => {
      this.boreholeLithologyLogs = data as BoreholeLithologyLog[];
      this.boreholeLithologyLogs.sort((a, b) => <any>a.depthFrom.measure - <any>b.depthFrom.measure);
      this.initForms();
    });
  }

  changeType(value: RockType) {
    this.rockGroupId = value.id;

    this.coreLithologyLogFormGroup.controls.groupSymbols.setValue(null);
    if(value.name === this.rockGroupEnum.BasicTypes){
      this.getBasicRocks(value.id);
      this.isBasicType = true;
      this.isSubdivision = false;
      this.isGeneralRock = false;
      this.isMixedSymbol = false;
      this.isGroupSymbols = false;
      this.isCorrespondingDyke = false;
    }
    else  if(value.name === this.rockGroupEnum.VolcanicRocks){
      this.getRockNamesByRockGroupId(value.id);
      this.isGroupSymbols = true;
      this.isMixedSymbol = false;
      this.isVarious = false;
      this.isGeneralRock = false;
      this.isSubdivision = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
    }
    else  if(value.name === this.rockGroupEnum.PlutonicRocks){
      this.getRockNamesByRockGroupId(value.id);
      this.isGroupSymbols = true;
      this.isVarious = false;
      this.isGeneralRock = false;
      this.isMixedSymbol = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
      this.isSubdivision = false;
    }
    else  if(value.name === this.rockGroupEnum.Various){
      this.getBasicRocks(value.id);
      this.isVarious = true;
      this.isGroupSymbols = false;
      this.isMixedSymbol = false;
      this.isGeneralRock = false;
      this.isSubdivision = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
    }
    else  if(value.name === this.rockGroupEnum.Mesozone || value.name === this.rockGroupEnum.Catazone
      || value.name === this.rockGroupEnum.Epizone  || value.name === this.rockGroupEnum.MetamorphicGeneralAndOthers){
      this.getBasicRocks(value.id);
      this.isGeneralRock = true;
      this.isMixedSymbol = false;
      this.isVarious = false;
      this.isSubdivision = false;
      this.isGroupSymbols = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
    }

    else  if( value.name === this.rockGroupEnum.MixedMagmaticRocks ){
      this.getBasicRocks(value.id);
      this.isMixedSymbol = true;
      this.isVarious = false;
      this.isGeneralRock = false;
      this.isSubdivision = false;
      this.isGroupSymbols = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
    }
    else  if(value.name === this.rockGroupEnum.GeneralRockTypes || value.name === this.rockGroupEnum.SedimentsAndSedimentary
      || value.name === this.rockGroupEnum.Soils || value.name === this.rockGroupEnum.TypesOfGravelAndSands   ){
      this.getBasicRocks(value.id);
      this.isGeneralRock = true;
      this.isMixedSymbol = false;
      this.isVarious = false;
      this.isSubdivision = false;
      this.isGroupSymbols = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
    }
    else{
      this.resetDropDown();
    }
  }

  changePurity(value){
    if(value==='0'){
      this.isPure = true;
      this.isImpurePure = false;
    }
    else{
      this.isPure = false;
      this.isImpurePure = true;
    }
  }

  changeGroupSymbols(value:RockName){
    this.geoTechService.getRockNamesByParentNameAndSubgroup(value.rock, this.rockSubGroupEnum.SymbolsForFurtherSubdivision).subscribe(res => {
      this.rockSymbolsForSubDivision = res as RockName [];
      if(this.rockSymbolsForSubDivision.length>0){
        this.isSubdivision = true;
        this.isCorrespondingDyke = true;
      }
      this.rockSymbolsForSubDivision.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    },error => {},() => {
    });

    this.geoTechService.getRockNamesByParentNameAndSubgroup(value.rock, this.rockSubGroupEnum.CorrespondingDykeAndSillRocks).subscribe(res => {
      this.correspondingDykes = res as RockName [];
      if(this.correspondingDykes.length>0){
        this.isCorrespondingDyke = true;
      }
      this.correspondingDykes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });

  }

  changeCorrespondingBySubdivision(subdivision:RockName){

    this.geoTechService.getRockNamesBySubdivision(subdivision.id).subscribe(res => {
      this.correspondingDykes = res as RockName [];
      if(this.correspondingDykes.length>0){
        this.isCorrespondingDyke = true;
      }
      this.correspondingDykes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  changeCorrespondingByParent(value){
    this.geoTechService.getRockNamesByParentNameAndSubgroup(value.rock, this.rockSubGroupEnum.CorrespondingDykeAndSillRocks).subscribe(res => {
      this.correspondingDykes = res as RockName [];
      if(this.correspondingDykes.length>0){
        this.isCorrespondingDyke = true;
      }
      this.correspondingDykes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  getMixedTypes(parent:RockName){
    this.geoTechService.getMixedTypeByBasicId(parent.id).subscribe(value => {
      this.mixedTypes = value as RockName [];
      this.mixedTypes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  getRockNamesByRockGroupId(rockGroupId){
    this.geoTechService.getRockNamesByGroupAndBySubGroup(rockGroupId, this.rockSubGroupEnum.GroupSymbols).subscribe(res => {
      this.groupSymbols = res as RockName [];
      this.groupSymbols.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  getBasicRocks(rockGroupId){
    this.geoTechService.getBasicTypeRockNames(rockGroupId).subscribe(res => {
      this.rockNames = res as RockName [];
      this.rockNames.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  getRockGroups(id) {
    this.geoTechService.getRockGroupByRockTypeId(id).subscribe(res => {
      this.rockGroups = res as RockGroup [];
      this.rockGroups = this.rockGroups.filter(value => value.name !== 'Mixed Types');
      this.rockGroups.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
    });
    if(this.rockTypeEnum.MetamorphicRocks){
      this.isMetamorphicRocks = true;
    }
    if(this.rockTypeEnum.SedimentsAndSedimentaryRocksAndVariousSoilsAndGeneralRockTypes){
      this.isSedimentsAndSedimentaryRocks = true;
    }
    if(this.rockTypeEnum.MagmaticRocksAndVarious){
      this.isMagmaticRocksAndVarious = true;
    }
  }

  getAllRockTypes() {
    this.hydrogeologyService.getAllRockTypes().subscribe(
      data => {
        this.rockTypes = data as RockType[];
      }
    );
  }

  getAllSampleTypes() {
    this.drillerService.getAllSampleTypes()
      .subscribe(data => {
        this.sampleTypes = data as SampleType[];
      });
  }

  initForms(){

    this.borehole = this.data.lithologyRecord.borehole;
    this.boreholeId = this.data.boreholeId;
    if (this.data.boreholeType === 'BGI-Borehole'){
      this.coreLithologyLogFormGroup = this.formBuilder.group({
        dateCaptured:  [this.data.lithologyRecord.id ? new Date(this.data.lithologyRecord.dateCaptured) : null, Validators.required],
        depthFrom: [this.data.lithologyRecord.id ? this.data.lithologyRecord.depthFrom.measure : null,[Validators.required, Validators.min(0)]],
        depthTo: [this.data.lithologyRecord.id ? this.data.lithologyRecord.depthTo.measure : null,[Validators.required, Validators.min(0)]],
        recoveredCore: [this.data.lithologyRecord.id ? this.data.lithologyRecord.recoveredCore.measure : null],
        coreLossGain: [this.data.lithologyRecord.id ? this.data.lithologyRecord.coreLossGain.measure : null],
        rockQualityDesignation: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockQualityDesignation : null],
        freshness: [this.data.lithologyRecord.id ? this.data.lithologyRecord.freshness: null],
        sampleType: [this.data.lithologyRecord.id ? this.data.lithologyRecord.sampleType : null],
        rockType: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.rockGroup.rockType : null,[Validators.required]],
        rockName: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName : null],
        purity: [],
        various: [this.data.lithologyRecord.id? this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.Various ? this.data.lithologyRecord.rockName.rock: null : null],
        mixedSymbols: [this.data.lithologyRecord.id?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks ? this.data.lithologyRecord.rockName : null: null],
        correspondingDyke: [this.data.lithologyRecord.id ?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks? this.data.lithologyRecord.rockName.parentRock? this.data.lithologyRecord.rockName : null: null: null],
        groupSymbols: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.parentRock : null],
        subDivision: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.subDivisionSymbol : null],
        mixedType: [this.data.lithologyRecord.id ?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedTypes ? this.data.lithologyRecord.rockName : null: null],
        sampleStatus: [this.data.lithologyRecord.id ? this.data.lithologyRecord.sampleStatus : null],
        types: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.rockGroup : null, [Validators.required]],
        lithologyDescription: [this.data.lithologyRecord.id ? this.data.lithologyRecord.lithologyDescription.description : null]
      });
    }

    if (this.data.boreholeType === 'Water'){
      this.coreLithologyLogFormGroup = this.formBuilder.group({
        dateCaptured:  [this.data.lithologyRecord.id ? new Date(this.data.lithologyRecord.dateCaptured) : null, Validators.required],
        depthFrom: [this.data.lithologyRecord.id ? this.data.lithologyRecord.depthFrom.measure : null,[Validators.required, Validators.min(0)]],
        depthTo: [this.data.lithologyRecord.id ? this.data.lithologyRecord.depthTo.measure : null,[Validators.required, Validators.min(0)]],
        freshness: [this.data.lithologyRecord.id ? this.data.lithologyRecord.freshness: null],
        sampleType: [this.data.lithologyRecord.id ? this.data.lithologyRecord.sampleType : null],
        rockType: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.rockGroup.rockType : null,[Validators.required]],
        rockName: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName : null],
        purity: [],
        various: [this.data.lithologyRecord.id? this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.Various ? this.data.lithologyRecord.rockName.rock: null : null],
        mixedSymbols: [this.data.lithologyRecord.id?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks ? this.data.lithologyRecord.rockName : null: null],
        correspondingDyke: [this.data.lithologyRecord.id ?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks? this.data.lithologyRecord.rockName.parentRock? this.data.lithologyRecord.rockName : null: null: null],
        groupSymbols: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.parentRock : null],
        subDivision: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.subDivisionSymbol : null],
        mixedType: [this.data.lithologyRecord.id ?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedTypes ? this.data.lithologyRecord.rockName : null: null],
        sampleStatus: [this.data.lithologyRecord.id ? this.data.lithologyRecord.sampleStatus : null],
        types: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.rockGroup : null, [Validators.required]],
        lithologyDescription: [this.data.lithologyRecord.id ? this.data.lithologyRecord.lithologyDescription.description : null]
      });
    }

    if (this.data.boreholeType === 'PL-Borehole'){
      this.coreLithologyLogFormGroup = this.formBuilder.group({
        dateCaptured:  [this.data.lithologyRecord.id ? new Date(this.data.lithologyRecord.dateCaptured) : null, Validators.required],
        depthFrom: [this.data.lithologyRecord.id ? this.data.lithologyRecord.depthFrom.measure : null,[Validators.required, Validators.min(0)]],
        depthTo: [this.data.lithologyRecord.id ? this.data.lithologyRecord.depthTo.measure : null,[Validators.required, Validators.min(0)]],
        sampleType: [this.data.lithologyRecord.id ? this.data.lithologyRecord.sampleType : null],
        rockType: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.rockGroup.rockType : null,[Validators.required]],
        rockName: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName : null],
        purity: [],
        various: [this.data.lithologyRecord.id? this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.Various ? this.data.lithologyRecord.rockName.rock: null : null],
        mixedSymbols: [this.data.lithologyRecord.id?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks ? this.data.lithologyRecord.rockName : null: null],
        correspondingDyke: [this.data.lithologyRecord.id ?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks? this.data.lithologyRecord.rockName.parentRock? this.data.lithologyRecord.rockName : null: null: null],
        groupSymbols: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.parentRock : null],
        subDivision: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.subDivisionSymbol : null],
        mixedType: [this.data.lithologyRecord.id ?this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedTypes ? this.data.lithologyRecord.rockName : null: null],
        sampleStatus: [this.data.lithologyRecord.id ? this.data.lithologyRecord.sampleStatus : null],
        types: [this.data.lithologyRecord.id ? this.data.lithologyRecord.rockName.rockGroup : null, [Validators.required]],
        lithologyDescription: [this.data.lithologyRecord.id ? this.data.lithologyRecord.lithologyDescription.description : null]
      });
    }


    if(this.data.lithologyRecord.id) {
      this.isAdd = false;
      this.coreLithologyLog = this.data.lithologyRecord;
      if(this.data.lithologyRecord.rockName){
        if(this.data.lithologyRecord.rockName.rockGroup) {
          this.rockGroupId = this.data.lithologyRecord.rockName.rockGroup.id;
          this.rockTypeSelected = true;
          this.changeRockType(this.data.lithologyRecord.rockName.rockGroup.rockType);

          if(this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks) {
            this.isMixedSymbol = true;
            this.getBasicRocks(this.data.lithologyRecord.rockName.rockGroup.id)
          }

          if(this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.PlutonicRocks) {
            this.isGroupSymbols = true;
            this.isCorrespondingDyke = true;
            this.isSubdivision = true;
            this.getRockNamesByRockGroupId(this.rockGroupId);

            if(this.data.lithologyRecord.rockName.parentRock) {
              this.changeGroupSymbols(this.data.lithologyRecord.rockName.parentRock);
              this.changeCorrespondingByParent(this.data.lithologyRecord.rockName.parentRock);
            }
            else{
              this.coreLithologyLogFormGroup.controls.groupSymbols.setValue(this.data.lithologyRecord.rockName);
              this.changeGroupSymbols(this.data.lithologyRecord.rockName);
              this.changeCorrespondingByParent(this.data.lithologyRecord.rockName.parentRock);
            }
          }

          if(this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.VolcanicRocks) {
            this.isGroupSymbols = true;
            this.isSubdivision = true;
            this.getRockNamesByRockGroupId(this.rockGroupId);
            if(this.data.lithologyRecord.rockName.parentRock) {
              this.changeGroupSymbols(this.data.lithologyRecord.rockName.parentRock);
              this.changeCorrespondingByParent(this.data.lithologyRecord.rockName.parentRock);
            }
          }

          if(this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.Various) {
            this.isVarious = true;
            this.getBasicRocks(this.rockGroupId);
          }

          if(this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.BasicTypes) {
            this.isBasicType = true;
            this.getBasicRocks(this.rockGroupId);
            if(this.data.lithologyRecord.rockName.basicType){
              this.coreLithologyLogFormGroup.controls.purity.setValue('1');
              this.isImpurePure = true;
            }
            else{
              this.coreLithologyLogFormGroup.controls.purity.setValue('0');
            }
          }

          if(this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.GeneralRockTypes || this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.SedimentsAndSedimentary
            || this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.Soils || this.data.lithologyRecord.rockName.rockGroup.name === this.rockGroupEnum.TypesOfGravelAndSands) {
            this.isGeneralRock = true;
            this.getBasicRocks(this.rockGroupId);
          }
        }
      }
    }

    this.coreLithologyLogFormGroup.controls.sampleStatus.setValue('unavailable');

    if (this.boreholeLithologyLogs && !this.data.lithologyRecord.id) {
      this.coreLithologyLogFormGroup.get('depthFrom').setValue(0);
      const previous_log = this.boreholeLithologyLogs[this.boreholeLithologyLogs.length - 1];
      if (previous_log) {
        this.coreLithologyLogFormGroup.get('depthFrom').setValue((previous_log.depthTo.measure));
      }
    }
  }


  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  compareFnSampleStatus(obj1: string, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  submitValidator(){
    if(this.data.lithologyRecord.id){
      let previousRockName : BoreholeLithologyLog;
      let nextRockName : BoreholeLithologyLog;
      let isValid: boolean = false;

      if(this.data.index!==0){
        previousRockName = this.data.lithologyLog[this.data.index-1];
        isValid = this.isValidDepthPrev(Number(previousRockName.depthFrom.measure),Number(previousRockName.depthTo.measure));
        if(isValid){
          this.adjustPrev(this.coreLithologyLogFormGroup.controls.depthFrom.value,previousRockName)
        }
      }
      if(this.data.index+1<this.data.lithologyLog.length){
        nextRockName = this.data.lithologyLog[this.data.index+1];
        isValid = this.isValidDepthNext(Number(nextRockName.depthFrom.measure),Number(nextRockName.depthTo.measure));
        if(isValid){
          this.adjustNext(this.coreLithologyLogFormGroup.controls.depthTo.value,nextRockName);
        }
      }

      if(this.data.index === this.data.lithologyLog.length-1  && this.data.index===0){
        this.onSubmit();
      }
      if(isValid){
        this.onSubmit();
      }

    }else {this.onSubmit()}

  }

  isValidDepthNext(depthFrom,depthTo): boolean {
    if (this.coreLithologyLogFormGroup.controls.depthTo.value >= depthTo){
      this.toaster.error('Depth To cannot be bigger than Depth To(' + depthTo + 'm) of the Next Log', 'lithology Log Error');
      return false;
    }
    else if (this.coreLithologyLogFormGroup.controls.depthFrom.value>= depthTo) {
      this.toaster.error('Depth From cannot be bigger than Depth To('+depthTo+'m) of the Next Log', 'lithology Log Error');
      return false;
    }
    else{return true;}
  }

  isValidDepthPrev(depthFrom,depthTo): boolean {

    if (depthFrom > this.coreLithologyLogFormGroup.controls.depthFrom.value){
      this.toaster.error('Depth From cannot be less than Depth From('+depthFrom+'m) of the Previous Log', 'lithology Log Error');
      return false;
    }

    else if (depthFrom >= this.coreLithologyLogFormGroup.controls.depthTo.value){
      this.toaster.error('Depth To cannot be less than Depth From('+depthFrom+'m) of the Previous Log', 'lithology Log Error');
      return false;
    }
    else{return true;}
  }


  adjustPrev(depthFrom,prevLithology:BoreholeLithologyLog){
    prevLithology.depthTo.measure = ''+(depthFrom);
    this.onPrevOrNextEdit(prevLithology);
  }

  adjustNext(depthTo, nextLithology:BoreholeLithologyLog){
    nextLithology.depthFrom.measure = ''+(depthTo);
    this.onPrevOrNextEdit(nextLithology);

  }



  onSubmit() {


    let coreLithologyTemplate = new BoreholeLithologyLog();

    if(this.data.lithologyRecord.id){
      coreLithologyTemplate.id = this.data.lithologyRecord.id;
    }

    coreLithologyTemplate.borehole = null;
    coreLithologyTemplate.sampleType = this.coreLithologyLogFormGroup.controls.sampleType.value;

    if (this.data.boreholeType === 'BGI-Borehole'){
      coreLithologyTemplate.rockQualityDesignation = this.coreLithologyLogFormGroup.controls.rockQualityDesignation.value;
      coreLithologyTemplate.recoveredCore.measure = this.coreLithologyLogFormGroup.controls.recoveredCore.value;
      coreLithologyTemplate.recoveredCore.unit = new Unit();
      coreLithologyTemplate.recoveredCore.unit.id = this.enumUnits.meter;
      coreLithologyTemplate.freshness = this.coreLithologyLogFormGroup.controls.freshness.value;

      coreLithologyTemplate.coreLossGain.measure = this.coreLithologyLogFormGroup.controls.coreLossGain.value;
      coreLithologyTemplate.coreLossGain.unit = new Unit();
      coreLithologyTemplate.coreLossGain.unit.id = this.enumUnits.meter;
    }

    if (this.data.boreholeType === 'Water'  || this.data.boreholeType === 'PL-Borehole'){
      coreLithologyTemplate.rockQualityDesignation = null;
      coreLithologyTemplate.recoveredCore = null;
      coreLithologyTemplate.coreLossGain = null;
      if(this.data.boreholeType === 'Water')
        coreLithologyTemplate.freshness = this.coreLithologyLogFormGroup.controls.freshness.value;
    }

    coreLithologyTemplate.lithologyDescription.description = this.coreLithologyLogFormGroup.controls.lithologyDescription.value;
    coreLithologyTemplate.sampleStatus = this.coreLithologyLogFormGroup.controls.sampleStatus.value;

    coreLithologyTemplate.dateCaptured = this.coreLithologyLogFormGroup.controls.dateCaptured.value;




    coreLithologyTemplate.depthTo.measure = this.coreLithologyLogFormGroup.controls.depthTo.value;
    coreLithologyTemplate.depthTo.unit = new Unit();
    coreLithologyTemplate.depthTo.unit.id = this.enumUnits.meter;

    coreLithologyTemplate.depthFrom.measure = this.coreLithologyLogFormGroup.controls.depthFrom.value;
    coreLithologyTemplate.depthFrom.unit = new Unit();
    coreLithologyTemplate.depthFrom.unit.id = this.enumUnits.meter;

    coreLithologyTemplate.rockName = this.isBasicType && !this.isImpurePure ? this.coreLithologyLogFormGroup.controls.rockName.value :
      this.isBasicType && this.isImpurePure? this.coreLithologyLogFormGroup.controls.mixedType.value:
      this.isVarious? this.coreLithologyLogFormGroup.controls.various.value:
      this.isGeneralRock ? this.coreLithologyLogFormGroup.controls.rockName.value :
        this.isMixedSymbol ? this.coreLithologyLogFormGroup.controls.mixedSymbols.value:
          this.isGroupSymbols && !this.coreLithologyLogFormGroup.controls.subDivision.value
        && !this.coreLithologyLogFormGroup.controls.correspondingDyke.value ?this.coreLithologyLogFormGroup.controls.groupSymbols.value :
         this.isSubdivision && this.coreLithologyLogFormGroup.controls.subDivision.value && !this.coreLithologyLogFormGroup.controls.correspondingDyke.value? this.coreLithologyLogFormGroup.controls.subDivision.value :
           this.isCorrespondingDyke && this.coreLithologyLogFormGroup.controls.correspondingDyke.value && !this.isBasicType ? this.coreLithologyLogFormGroup.controls.correspondingDyke.value:'';

    if (coreLithologyTemplate.depthTo.measure > coreLithologyTemplate.depthFrom.measure ) {

      this.coreService.createBoreholeLithology(coreLithologyTemplate, this.boreholeId)
        .subscribe(response => {

              this.toaster.success('lithology log updated successfully');
          },
          error => {
            this.toaster.error(error.toString());
          },() => {
            this.close();
          });
    } else {
      this.toaster.error('`Depth To` must be greater than `Depth From`', 'Invalid Depth');
      this.coreLithologyLogFormGroup.get('depthTo').setValue('');
    }
  }

  onPrevOrNextEdit(lithology: BoreholeLithologyLog ){
    if (lithology.depthTo.measure > lithology.depthFrom.measure ) {
      lithology.borehole = null;
      this.coreService.createBoreholeLithology(lithology, this.boreholeId)
        .subscribe();
    } else {
      this.toaster.error('`Depth To` must be greater than `Depth From`', 'Invalid Depth');
      this.coreLithologyLogFormGroup.get('depthTo').setValue('');
    }
  }


  close() {
    this.dialogRef.close();
  }

  _getAllSampleStatus() {
    this.coreService.getAllSampleStatuses().subscribe(res => {
      this.sampleStatusList = res as any[];
    });
  }
}
