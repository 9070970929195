import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoadWorksLogsValidations implements OnInit {

  constructor() {
  }

  validation_messages = {
    'testNumber': [
      {type: 'required', message: 'Test number is required'}
    ],
    'latitude': [
      {type: 'required', message: 'Latitude is required'},
    ],
    'longitude': [
      {type: 'required', message: 'Longitude is required'},
    ],
    'Village': [
      {type: 'required', message: 'Village is required'},
    ],
    'comments': [
      {type: 'required', message: 'Location Description is required'},
    ],
    'blows': [
      {type: 'required', message: 'Number of Blows is required'},
    ],
    'penetration': [
      {type: 'required', message: 'Penetration is required'},
    ],
    'penetrationRate': [
      {type: 'required', message: 'Penetration Rate is required'},
    ],
    'startDepth': [
      {type: 'required', message: 'Start Depth is required'},
    ],
    'endDepth': [
      {type: 'required', message: 'End Depth is required'},
    ],
    'type': [
      { type: 'required', message: 'Select Coordinates Type' },
    ],
    'format': [
      { type: 'required', message: 'Select Coordinates Format' },
    ],
    'zone34SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone34SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'zone5SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone35SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'dmsSx': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMx': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDx': [
      { type: 'required', message: 'Type degree value' },
    ],
    'dmsSy': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMy': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDy': [
      { type: 'required', message: 'Type degree value' },
    ],
  };

  ngOnInit() {
  }
}
