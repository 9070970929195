import { GlobalDashboardService } from './../../global-dashboard.service';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { User, Person } from 'src/app/_core/data/_models/people.model';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { LocalAuthorities } from 'src/app/_auth/_providers';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-driller-dashboard',
  templateUrl: './manager-driller-dashboard.component.html',
  styleUrls: ['./manager-driller-dashboard.component.scss']
})
export class ManagerDrillerDashboardComponent implements OnInit {

  stillLoadingActivities = true;

  drillerDashboardCounts = {
    DRILLING_REQUESTS: 0,
    SUBMITTED_TO_SUPERINTENDENT: 0,
    RETURNED: 0,
    APPROVED: 0,
    ALL_VETTED: 0,
    RETURNED_SUPERINTENDENT: 0
  };

  currentUser: User;
  currentPerson: Person;
  currentLoggedPerson: Person;

  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
    private managerDashBoardService: GlobalDashboardService,
  ) { }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.initPerson();
      this.initLoggedPerson(this.currentUser.id);
    }, err => {
      this._toastr.warning(err.message, "Current User Error");
    },() => {
  });
  }

  private initLoggedPerson(userId: string){
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
      if (res) {
          this.currentLoggedPerson = res;
          this.currentLoggedPerson['user'] = this.currentUser;
          this._localStorage.setItem(StorageConstants.personKey, this.currentLoggedPerson).subscribe(() => {});
          this.managerDashBoardService.managerDrillRequestCountsByUnit(this.currentLoggedPerson.user.businessUnit.id).subscribe(res => {
            let result: Array<{ title: string, count: number }> = res as Array<{ title: string, count: number }>;
            result.forEach(r => { this.drillerDashboardCounts[r.title] = r.count;
            });
            this.stillLoadingActivities = false;
          });
      }
    }, err => {
        this._toastr.error(err.message, "Profile Details");
    },() => {
    });
  }

  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    }, () => {
    });
  }

}
