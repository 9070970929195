import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ServiceEndPoints } from '../shared-kernel/apiCalls/ServiceEndPoints';



@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnInit {

  constructor(
    private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  getAllCountries() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCountriesAGSUrl);
  }

  getAllDistricts() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDistrictsAGSUrl);
  }

  createVillage(village) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createVillageUrl, village);
  }

  updateVillage(village) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.editVillageUrl, village);
  }

  deleteVillage(village) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteVillageUrl, village);
  }

  getAllVillages() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllVillagesUrl);
  }

  getAllVillagesLite() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllVillageLiteUrl);
  }

  getVillagesByDistrictId(districtId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getVillagesByDistrictIdUrl + '/' + districtId);
  }

  searchVillages(villageName?: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}${ServiceEndPoints.searchVillagesUrl}/search?criteria=${villageName ? villageName : ''}`);
  }

  searchVillageByDistrict(districtId: string, villageName?: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}${ServiceEndPoints.searchVillagesUrl}/search/${districtId}?criteria=${villageName ? villageName : ''}`);
  }

  searchDistricts(districtName?: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}${ServiceEndPoints.searchDistrictsUrl}/search?criteria=${districtName ? districtName : ''}`);
  }

}
