import { Borehole } from '../../common/borehole';
import { WaterPumpingTestStepTest } from './WaterPumpingTestStepTest';
import { WaterPumpingTestCalibration } from './WaterPumpingTestCalibration';
import { WaterPumpingTestType } from './WaterPumpingTestType';

export class WaterPumpingTest {
    id: string;
    startdate:Date;
    enddate:Date;
    starttime:Date;
    endtime:Date;
    geologist: string;
    topOfCasing: string;
    pumpType: string;
    startDate: Date;
    endDate: Date;
    startTime: Date;
    endTime: Date;
    waterPumpingTestType: WaterPumpingTestType = new WaterPumpingTestType();
    borehole: Borehole;
    waterPumpingTestStepTests: WaterPumpingTestStepTest[];
    waterPumpingTestCalibrations: WaterPumpingTestCalibration[];

  }
