import { SystemRole } from '../role/system-role';
import {BusinessUnit} from '../../../../_core/data/_models/business.models';

export class User {

  public id: string;
  public password: string;
  public username: string;
  public accountNonExpired: boolean;
  public accountNonLocked: boolean;
  public credentialsNonExpired: boolean;
  public enabled: boolean;
  public lastPasswordReset: Date;
  public roles: SystemRole[];
  public token?: string;
  public businessUnit: BusinessUnit;

  constructor() {  }
}
