import { SoilLog } from './soil-log';

export class SoilRecord {
    id: String;
    legend: String;
    depthFrom: String;
    depthTo: String;
    description: String;
    sampleName: String;
    test: String;
    soilLog: SoilLog;

    constructor () {
    }
  }
