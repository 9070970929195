import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {RoleService} from '../roles.service';
import {Roles} from 'src/app/shared-kernel/entity/Roles';
import {Router} from '@angular/router';
import {BusinessUnit} from 'src/app/common/entity/security/role/business-unit';
import {SystemRole} from 'src/app/common/entity/security/role/system-role';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

  public roles: Roles[];
  businessUnit: BusinessUnit;
  role: SystemRole;

  displayedColumns: string[] = ['No', 'Name', 'Description', 'Business Unit', 'Department', 'Action'];
  dataSourceRoles = new MatTableDataSource();

  constructor(private roleService: RoleService, private router: Router) {
  }

  ngOnInit() {
    this.dataSourceRoles = new MatTableDataSource(this.roles);
    this.getRoles();
  }

  getRoles() {
    this.roleService.getAllRoles()
      .subscribe(data => {
        this.roles = data as Roles[];
        for (const role of this.roles) {
          const roleData = role as SystemRole;
          if (!roleData.businessUnit) {
            roleData.businessUnit = {
              id: 'na', name: 'N/A', description: 'na',
              department: {id: 'na1', name: 'N/A', description: 'na'}
            };
          }
        }
        this.dataSourceRoles = new MatTableDataSource(this.roles);
      });
  }

  applyFilter(filterValue: string) {
    this.dataSourceRoles.filter = filterValue.trim().toLowerCase();
  }

  openRolesForm() {
    this.router.navigate(['admin/role/create']);
  }

  editRole(role: any) {
    const id = {id: role};
    this.router.navigate(['admin/role/create', id]);
  }
}
