import { StorageConstants } from './../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogData, NigisDocument } from '../../shared/documents/nigis-doc.model';
import { ToastrService } from 'ngx-toastr';
import { DocumentsService } from '../../shared/documents/dococument.service';
import { DocUploadComponent } from '../../shared/documents/doc-upload/doc-upload.component';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import {State} from "../../activities/models/state";
import { ApproveAction } from "../../applied-geoscience/models";
import {ApprovalService} from "../../applied-geoscience/geo-tech/borehole/services/approval.service";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {

  fetching: boolean = true;
  documentCount: number = 0;
  state: State = new State();
  @Input('canAdd') canAdd: boolean = true;
  @Input('canEdit') canEdit: boolean = true;
  @Input('currentState') currentState: string = '';
  @Input('canDelete') canDelete: boolean = true;
  @Input('entityId') entityId: string = '';
  @Input('filter') filter: string = '';
  @Input('isMineralResourceReport') isMineralResourceReport: boolean = false;
  @Input('isAnnualData') isAnnualData: boolean = false;
  @Input('isInputData') isInputData: boolean = false;
  selectedSection: string ;

  @Output() refreshEvent = new EventEmitter<boolean>();
  selection = new SelectionModel<NigisDocument>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  fetchedFiles: NigisDocument [];
  tableDataSource: MatTableDataSource<NigisDocument> = new MatTableDataSource();
  displayedColumns: string[] = ['check','#', 'Name', 'Description', 'File Type',  'Year', 'Status', 'Creation Date', 'Action'];

  currentPrincipal: any;

  constructor (
    private dialog: MatDialog,
    private _toastr: ToastrService,
    private docServ: DocumentsService,
    private approvalService: ApprovalService,
    private _localStorage: LocalStorage,
  ) {}

  statusMap = {
    Drafts: 'PENDING_SENIOR_SUBMISSION',
    Submitted_Senior_economic_geologist: 'SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST',
    Submitted_Senior_mineral_economist: 'SUBMITTED_TO_SENIOR_MINERAL_ECONOMIST',
    Submitted_Senior_geochemist: 'SUBMITTED_TO_SENIOR_GEOCHEMIST',
    Returned_Senior_geochemist: 'REJECTED_BY_SENIOR_GEOCHEMIST',
    Returned_Senior_economic_geologist: 'REJECTED_BY_SENIOR_ECONOMIC_GEOLOGIST',
    Returned_Senior_mineral_economist: 'REJECTED_BY_SENIOR_MINERAL_ECONOMIC',
    Returned_Manager: 'REJECTED_BY_MANAGER',
    Submitted_Manager_senior_geochemist: 'SUBMITTED_TO_MANAGER_BY_SENIOR_GEOCHEMIST',
    Submitted_Manager_senior_mineral_economist: 'SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST',
    Submitted_Manager_geochemist: 'SUBMITTED_TO_MANAGER_BY_GEOCHEMIST',
    Submitted_Manager_economic_geologist: 'SUBMITTED_TO_MANAGER_BY_ECONOMIC_GEOLOGIST',
    Submitted_Manager_mineral_economist: 'SUBMITTED_TO_MANAGER_BY_MINERAL_ECONOMIST',
    Publised: 'APPROVED_MANAGER',
  };

  ngOnInit() {
    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource = new MatTableDataSource(this.fetchedFiles);
    this._currentPrincipal();
  }

  private _currentPrincipal() {
    this._localStorage.getItem<any>(StorageConstants.fullUserKey).subscribe(res => {
      this.currentPrincipal = res;
      this._getFiles();
    });
  }

  _initialFilter(){
    switch (this.filter) {
      case 'PENDING_SENIOR_SUBMISSION':
        this.selectedSection = 'Drafts';
        break;
      case 'APPROVED_MANAGER':
        this.selectedSection = 'Publised';
        break;
      case 'SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST':
        this.selectedSection = 'Submitted_Senior_economic_geologist';
        break;
      case 'SUBMITTED_TO_SENIOR_GEOCHEMIST':
        this.selectedSection = 'Submitted_Senior_geochemist';
        break;
      case 'SUBMITTED_TO_SENIOR_MINERAL_ECONOMIST':
        this.selectedSection = 'Submitted_Senior_mineral_economist';
        break;
      case 'REJECTED_BY_SENIOR_ECONOMIC_GEOLOGIST':
        this.selectedSection = 'Returned_Senior_economic_geologist';
        break;
      case 'REJECTED_BY_MANAGER':
        this.selectedSection = 'Returned_Manager';
        break;
      case 'REJECTED_BY_SENIOR_GEOCHEMIST':
        this.selectedSection = 'Returned_Senior_geochemist';
        break;
      case 'REJECTED_BY_SENIOR_MINERAL_ECONOMIST':
        this.selectedSection = 'Returned_Senior_mineral_economist';
        break;
      case 'SUBMITTED_TO_MANAGER_BY_SENIOR_GEOCHEMIST':
        this.selectedSection = 'Submitted_Manager_senior_geochemist';
        break;
      case 'SUBMITTED_TO_MANAGER_BY_SENIOR_ECONOMIC_GEOLOGIST':
        this.selectedSection = 'Submitted_Manager_senior_economic_geologist';
        break;
      case 'SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST':
        this.selectedSection = 'Submitted_Manager_senior_mineral_economist';
        break;
      case 'SUBMITTED_TO_MANAGER_BY_GEOCHEMIST':
        this.selectedSection = 'Submitted_Manager_geochemist';
        break;
      case 'SUBMITTED_TO_MANAGER_BY_ECONOMIC_GEOLOGIST':
        this.selectedSection = 'Submitted_Manager_economic_geologist';
        break;
      default:
        this.selectedSection = 'All';
        break;
    }
    this.onSectionChange(this.selectedSection);
  }

  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  onSectionChange(selectedSection: string) {
    this.selectedSection = selectedSection;
    if ('All' === selectedSection) {
      let allMyFiles = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username);
      this.tableDataSource.data = allMyFiles;
    }
    else if ('AllMyActivities' === selectedSection) {
      let allMyFiles = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username);
      this.tableDataSource.data = allMyFiles;
    } else {
      if ('Submitted_Manager_geochemist' === selectedSection || 'Submitted_Manager_senior_geochemist' === selectedSection) {
        this.tableDataSource.data = this.fetchedFiles.filter(a => a.currentState.currentState === this.statusMap[selectedSection]
          || a.currentState.currentState === this.statusMap['Submitted_Manager_senior_geochemist'] );
      }
      if ('Submitted_Manager_economic_geologist' === selectedSection || 'Submitted_Manager_senior_economic_geologist' === selectedSection) { //
        this.tableDataSource.data = this.fetchedFiles.filter(a => a.currentState.currentState === this.statusMap[selectedSection]
          || a.currentState.currentState === this.statusMap['Submitted_Manager_senior_economic_geologist'] );
      }
      if (selectedSection === 'PublishedAll') { //
        this.tableDataSource.data = this.fetchedFiles.filter(x => x.currentState.currentState === 'APPROVED_MANAGER');
      }

      if (this.isAnnualData === true) { //
        if (selectedSection === 'Drafts') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'PENDING_SENIOR_SUBMISSION');
        }
        if (selectedSection === 'Submitted_Manager_mineral_economist') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'SUBMITTED_TO_MANAGER_BY_MINERAL_ECONOMIST');
        }
        if (selectedSection === 'Submitted_Senior_mineral_economist') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'SUBMITTED_TO_SENIOR_MINERAL_ECONOMIST');
        }
        if (selectedSection === 'Submitted_Manager_senior_mineral_economist') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST');
        }
        if (selectedSection === 'Returned_Senior_mineral_economist') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'REJECTED_BY_SENIOR_MINERAL_ECONOMIC');
        }
        if (selectedSection === 'Returned_Manager') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'REJECTED_BY_MANAGER');
        }
        if (selectedSection === 'Publised') { //
          this.tableDataSource.data = this.fetchedFiles.filter(x => x.creator === this.currentPrincipal.username
            && x.currentState.currentState === 'APPROVED_MANAGER');
        }
      } else {
        this.tableDataSource.data = this.fetchedFiles.filter(a => a.currentState.currentState === this.statusMap[selectedSection] );
      }
    }

    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;
  }

  openDialog() {
    this.dialog.open(DocUploadComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: <DialogData>{
        id: this.entityId, edit: false, delete: false, fileId: null, approval:true
      }
    }).afterClosed().subscribe(r => {
      if (r) {
        this._getFiles();
        this.selectedSection = 'All';
        this.onSectionChange(this.selectedSection);
      }
    });
  }

   submitToSenior(role:string){
    let changeStateModel: ApproveAction;
    let fileList: string = '';
     this.selection.selected.forEach(value => {
       fileList = fileList + ' <li>' + value.name + '</li>';
     });
     Swal.fire(<SweetAlertOptions>{
       title: 'Submit Files For Approval',
       type: 'warning',
       html: 'Are you sure you want to submit the following ?  <ul class="col-md-6 ">' + fileList + '</ul>' ,
       showCloseButton: false,
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes'
     }).then(result => {


       if (role === 'Geochemist') {
         changeStateModel = ApproveAction.SUBMIT_TO_SENIOR_GEOCHEMIST;
       } if ( role === 'Economic Geologist') {
         changeStateModel = ApproveAction.SUBMIT_TO_SENIOR_ECONOMIC_GEOLOGIST;
       }
       if ( role === 'Mineral Economist') {
         changeStateModel = ApproveAction.SUBMIT_TO_SENIOR_MINERAL_ECONOMIST;
       }
       if(result.dismiss){

       }
       else {

         this.selection.selected.forEach(value => {
           this.approvalService.changeStateFiles(value.id, {
             action: changeStateModel,
             comment: ''
           }).subscribe(value1 => {
             this.selection = new SelectionModel<NigisDocument>(true, []);
             this._toastr.success("Submitted Successfully", "File Submission");
             this._getFiles();
             if(changeStateModel === ApproveAction.SUBMIT_TO_SENIOR_GEOCHEMIST) {
               this.selectedSection = 'Submitted_Senior_geochemist';
             }
             if(changeStateModel === ApproveAction.SUBMIT_TO_SENIOR_ECONOMIC_GEOLOGIST) {
               this.selectedSection = 'Submitted_Senior_economic_geologist';
             }
             if(changeStateModel === ApproveAction.SUBMIT_TO_SENIOR_MINERAL_ECONOMIST) {
               this.selectedSection = 'Submitted_Senior_mineral_economist';
             }
             this.onSectionChange(this.selectedSection);
           }, error1 => {
             this._toastr.error("Unsuccessful", "File Approval");
           });
         });
       }
     });
   }


  submitToManager(role: string) {
    let fileList: string = '';
    this.selection.selected.forEach(value => {
      fileList = fileList + ' <li>' + value.name + '</li>';
    });
    Swal.fire(<SweetAlertOptions>{
      title: 'Submit Files For Approval',
      type: 'warning',
      html: 'Are you sure you want to submit the following ?  <ul class="col-md-6 ">' + fileList + '</ul>' ,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(result => {
      let changeStateModel: ApproveAction;

      if (role.trim().toLocaleLowerCase() === 'Senior Geochemist'.trim().toLocaleLowerCase()) {
        changeStateModel = ApproveAction.SUBMIT_TO_MANAGER_SENIOR_GEOCHEMIST;
      } if (role.trim().toLocaleLowerCase() === 'Senior Economic Geologist'.trim().toLocaleLowerCase()) {
        changeStateModel = ApproveAction.SUBMIT_TO_MANAGER_SENIOR_ECONOMIC_GEOLOGIST;
      }
      if (role.trim().toLocaleLowerCase() === 'Geochemist'.trim().toLocaleLowerCase()) {
        changeStateModel = ApproveAction.SUBMIT_TO_MANAGER_GEOCHEMIST;
      }
      if (role.trim().toLocaleLowerCase() === 'Economic Geologist'.trim().toLocaleLowerCase()) {
        changeStateModel = ApproveAction.SUBMIT_TO_MANAGER_ECONOMIC_GEOLOGIST;
      }
      if (role.trim().toLocaleLowerCase() === 'Mineral Economist'.trim().toLocaleLowerCase()) {
        changeStateModel = ApproveAction.SUBMIT_TO_MANAGER_MINERAL_ECONOMIST;
      }
      if (role.trim().toLocaleLowerCase() === 'Senior Mineral Economist'.trim().toLocaleLowerCase()) {
        changeStateModel = ApproveAction.SUBMIT_TO_MANAGER_SENIOR_MINERAL_ECONOMIST;
      }
      if(result.dismiss){

      }
      else {
        this.selection.selected.forEach(value => {
          this.approvalService.changeStateFiles(value.id, {action: changeStateModel, comment: ''}).subscribe(value1 => {
            this._toastr.success("Submitted Successfully", "File Submission");
            this._getFiles();
            if(changeStateModel === ApproveAction.SUBMIT_TO_MANAGER_SENIOR_GEOCHEMIST) {
              this.selectedSection = 'Submitted_Manager_senior_geochemist';
            }
            if(changeStateModel === ApproveAction.SUBMIT_TO_MANAGER_SENIOR_ECONOMIC_GEOLOGIST) {
              this.selectedSection = 'Submitted_Manager_senior_economic_geologist';
            }
            if(changeStateModel === ApproveAction.SUBMIT_TO_MANAGER_SENIOR_MINERAL_ECONOMIST) {
              this.selectedSection = 'Submitted_Manager_senior_mineral_economist';
            }
            if(changeStateModel === ApproveAction.SUBMIT_TO_MANAGER_GEOCHEMIST) {
              this.selectedSection = 'Submitted_Manager_geochemist';
            }
            if(changeStateModel === ApproveAction.SUBMIT_TO_MANAGER_MINERAL_ECONOMIST) {
              this.selectedSection = 'Submitted_Manager_economic_geologist';
            }
            if(changeStateModel === ApproveAction.SUBMIT_TO_SENIOR_MINERAL_ECONOMIST) {
              this.selectedSection = 'Submitted_Manager_mineral_economist';
            }
            this.onSectionChange(this.selectedSection);
            this.selection = new SelectionModel<NigisDocument>(true, []);
          }, error1 => {
            this._toastr.error("Unsuccessful", "File Approval");
          });
        });

      }
    });
  }

  editDocumet(doc: NigisDocument) {
    this.dialog.open(DocUploadComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: <DialogData>{
        id: this.entityId, fileId: doc.id, edit: true, delete: false,approval:true
      }
    }).afterClosed().subscribe(r => {
      if (r) {
        this._getFiles();
      }
    });
  }

  deleteFile(doc: NigisDocument) {
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete Document',
      type: 'warning',
      html: `Are you sure you want to <b>Delete</b>? ${doc.name}`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(result => {
      if (result && result.value === true) {
        this.docServ.deleteFile(doc.id).subscribe(res => {
          this._toastr.success("File deleted successfully", "File deleted");
          this._getFiles();
        }, _err => {
          this._toastr.error("Failed to delete a file", "Delete Failed");
        });
      }
    });
  }

  downloadDocument(doc: NigisDocument) {
    this.docServ.getApprovalDocumentFile(doc.uri).subscribe(data => {
      this._downloadFile(doc, data);
    }, _err => {
      this._toastr.error("Failed to download file", "File Download");
    });
  }

  getFileExtension(filename: string): string {
    return filename.split('.').pop();
  }

  private _getFiles() {
    this.tableDataSource.data = [];
    this.docServ.getEntityFiles(this.entityId).subscribe(res => {
      this.fetching = false;
      this.fetchedFiles = res as NigisDocument[];
      this.tableDataSource = new MatTableDataSource<NigisDocument>(res);
      this.tableDataSource.paginator = this.paginator;
      this.refreshEvent.emit(true);
      this._countFiles();
      if(!this.selectedSection){
        this._initialFilter();
      }
    }, _err => {
      this._toastr.error("Failed to retrieve documents", "Documents List");
    });
  }

  private _countFiles() {
    this.docServ.getFileCount(this.entityId).subscribe(res => {
      this.documentCount = res;
    })
  }

  private _downloadFile(doc: NigisDocument, data: any) {
    let binaryData = [];
    binaryData.push(data);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: data.dataType}));
    downloadLink.setAttribute('download', doc.fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data? this.tableDataSource.data.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableDataSource.data.forEach(row => this.selection.select(row));

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: NigisDocument): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }


}
