import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Remove edit functionality on Activities:
 *
 * If the activity currentState is either 'PENDING_QA_SUBMISSION' or 'REJECTED_BY_QA' or REJECTED_BY_MANAGER'
 * then the templated elements (edit button) will be removed from the DOM
 *
 * e.g
 * <span *canEditActivity="infiltrationTest.currentState.currentState" >
 *   <a mat-icon-button *ifCreator="infiltrationTest.creator" (click)="openInfiltrationTestForEdit(infiltrationTest)" matTooltip="edit">
 *    <i class="edit icon bgi-golden-brown-txt"></i>
 *   </a>
 * </span>
 *
 */
@Directive({selector: '[canEditActivity]'})
export class CanEditActivityDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
  }

  @Input() set canEditActivity(currentState: string) {

    if ((currentState === 'PENDING_QA_SUBMISSION') || (currentState === 'REJECTED_BY_GEOLOGIST')
        || (currentState === 'REJECTED_BY_QA') || (currentState === 'REJECTED_BY_MANAGER') || (currentState === 'PENDING_SENIOR_ARCHIVIST_SUBMISSION')
        || (currentState === 'PENDING_GEOLOGIST_SUBMISSION' || (currentState === 'PENDING_MANAGER_SUBMISSION'))
        || (currentState === 'REJECTED_BY_SENIOR_ARCHIVIST'))  {

      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
