import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { CoreRecoveryLog } from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/CoreRecoveryLog';
import { CoreService } from '../../core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Borehole } from '../../../../../shared-kernel/entity/common/borehole';
import { Person } from '../../../../../common/entity/security/profile/person';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Unit } from 'src/app/shared-kernel/entity/common/unit';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { PeopleService } from '../../../../../_core/mock/people.service';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import {
  WaterBearingZones
} from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import { ExportToCsv } from 'export-to-csv';

declare var $: any;

@Component({
  selector: 'app-core-recovery-list',
  templateUrl: './core-recovery-list.component.html',
  styleUrls: ['./core-recovery-list.component.scss']
})
export class CoreRecoveryListComponent implements OnInit {


  @Input('currentState') currentState: string;
  @Input('boreholeCreator') boreholeCreator: string = '';
  @Input('boreholeOwner') boreholeOwner: string = '';
  @Input('projectId') projectId: string ;
  @Input('companyId') companyId: string ;
  dataSourceCoreRecoveryLogs : MatTableDataSource<CoreRecoveryLog> = new MatTableDataSource();
  displayedCoreRecoveryLogsColumns = ['No.', 'Date', 'Run Number', 'From', 'To',
    'TCR', 'Loss/gain', 'RQD', 'Lithology', 'Geologist', 'Discontinuity', 'Edit', 'Delete'];
  geologist: any;
  corerecoverylogs: CoreRecoveryLog[];

  updated = false;
  boreholeId: string;
  person: Person;
  borehole: Borehole;
  url: string;
  loading = true;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  model: any = {};
  coreRecoveryLog: CoreRecoveryLog = new CoreRecoveryLog();
  recoveryDate: any;
  depthFrom: any;
  depthTo: any;
  unit: any;
  coreRecoveryLogs: any;
  persons: Person[];
  rockQuality: string;
  rockName: any;
  name: string;

  csvRecords: any[] = [];

  @ViewChild('fileImportInput') fileImportInput: any;

  displayedColumns: string[] = ['Date', 'Run Number', 'Geologist', 'Depth From', 'Depth To',
    'Total Core Recovery', 'Rock Quality', 'Lithology', 'Action'];
  dataSource: MatTableDataSource<CoreRecoveryLog> = new MatTableDataSource();
  csvDdataSource: MatTableDataSource<CoreRecoveryLog> = new MatTableDataSource();
  coreRecoveryLogRecords: Array<CoreRecoveryLog>;

  dialogRef: any;
  selectedCvsRecord: CoreRecoveryLog = new CoreRecoveryLog();

  validation_messages = {
    'date': [
      { type: 'required', message: 'Please insert date' },
    ],
    'runNumber': [
      { type: 'required', message: 'Runmber is required' },
    ],
    'geologist': [
      { type: 'required', message: 'Please select geologist first.' },
    ],
    'depthFrom': [
      { type: 'required', message: 'Depth From is required.' },
    ],
    'depthTo': [
      { type: 'required', message: 'Depth To is required.' },
    ],
    'totalCoreRecory': [
      { type: 'required', message: 'Total Core Recovery is required.' },
    ],
    'rockQualityDescription': [
      { type: 'required', message: 'Rock Quality Description is required.' },
    ],
    'lithology': [
      { type: 'required', message: 'Please select lithology.' },
    ]
  };
  isNewRecord = false;
  lastRunNumber: number;
  csvRunNumber: string;

  constructor(private coreService: CoreService,
    private router: ActivatedRoute,
    private pageroute: Router,
    private peopleService: PeopleService,
    private companyService: CompanyService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService) {

    router.params.subscribe(
      data => {
        this.boreholeId = data.id;
      }
    );
  }

  openDiscoveryLog(runNumber, coreRecoveryId) {
    const idObject = { run: runNumber, core: coreRecoveryId };
    this.pageroute.navigate(['/portal/applied-geoscience/geo-tech/core/discontinuity/list', idObject]);
  }

  createRecord() {
    $('#coreRecoveryCreate').modal({ backdrop: 'static' });
    $('#coreRecoveryCreate').modal('show');
    this.sortRunnerNumbers(this.corerecoverylogs);
  }

  initialiseDataObjects() {
    this.coreRecoveryLog.depthFrom = new Size();
    this.coreRecoveryLog.depthTo = new Size();
    this.coreRecoveryLog.totalCoreRecovery = new Size();
    this.coreRecoveryLog.depthFrom.unit = new Unit();
    this.coreRecoveryLog.depthTo.unit = new Unit();
    this.coreRecoveryLog.totalCoreRecovery.unit = new Unit();
    this.coreRecoveryLog.depthFrom.unit.id = UnitEnum.meter;
    this.coreRecoveryLog.depthTo.unit.id = UnitEnum.meter;
    this.coreRecoveryLog.totalCoreRecovery.unit.id = UnitEnum.meter;
    this.coreRecoveryLog.recoveryDate = new Date();
    this.person = new Person();
    this.rockQuality = '';
    this.name = '';
    this.coreRecoveryLogs = [];
  }

  ngOnInit() {
    this.initialiseDataObjects();
    this.getAllGeologists();
    this.geologist = '';
    this.getBoreholeById(this.boreholeId);
  }

  getBoreholeById(boreholeId: string) {
    this.coreService.getBoreholeById(boreholeId).subscribe(
      data => {
        this.borehole = data as Borehole;
      }
    );
  }

  getRunNumber() {
    if (this.corerecoverylogs.length !== 0) {
      this.coreRecoveryLog.runNumber =
        this.lastRunNumber + 1 + '';
      this.csvRunNumber = this.lastRunNumber + '';
    } else {
      this.coreRecoveryLog.runNumber = '1';
      this.csvRunNumber = '1';
    }
  }

  exportCSV() {
    const data = [];
    data.push({
      'Date (mm/dd/yyyy)':'','Run Number':'',	'Geologist':'', 'Depth From (m)':'','Depth To (m)':'','Total Core Recovery (m)':'',
      'Rock Quality Designation (%)':'',	'Lithology':''
    });


    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'coreRecovery',
      useTextFile: false,
      useBom: true,
      headers: ['Date (mm/dd/yyyy)','Run Number','Geologist','Depth From (m)','Depth To (m)', 'Total Core Recovery (m)'	,'Rock Quality Designation (%)',	'Lithology',	]
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  getAllGeologists() {
    
    this.companyService.getAllCompanyPersonnel(this.companyId).subscribe(d => {
      this.persons =  d as [];
      this.getAllBoreholeCoreRecoveryLog(this.boreholeId);
    })
  }

  geologistSelected(person){
    this.geologist.id = person.id;
    this.coreRecoveryLog.geologist = person.id;
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo ? variableOne === variableTwo : variableOne === variableTwo;
  }

  editRecordRequest(coreRecoveryLog: CoreRecoveryLog) {
    $('#coreRecoveryEdit').modal({ backdrop: 'static' });
    $('#coreRecoveryEdit').modal('show');
    this.coreRecoveryLog = coreRecoveryLog;
    let person = this.persons.find(value => value.id === this.coreRecoveryLog.geologist);
    this.geologist = {
      id: person.id,
      name: `${person.name} ${person.surname}`
    }

    this.coreRecoveryLog.recoveryDate = new Date(this.coreRecoveryLog.recoveryDate);
    this.coreRecoveryLog.borehole = this.borehole;
  }

  saveEditedRecord() {
    if (parseFloat(this.coreRecoveryLog.depthFrom.measure) > parseFloat(this.coreRecoveryLog.depthTo.measure)) {
      this.toast.error('Depth From should be less than Depth To', 'Depth To');
      this.coreRecoveryLogs = [];
    } else if (parseFloat(this.coreRecoveryLog.rockQuality) > 101 || parseFloat(this.coreRecoveryLog.rockQuality) < 0) {
      this.toast.error('Rock Quality should be between 0 and 100', 'Rock Quality');
      this.coreRecoveryLogs = [];
    } else {
      this.coreRecoveryLogs.push(this.coreRecoveryLog);
      this.createBoreholeCoreRecoveryLog(this.coreRecoveryLogs, this.boreholeId);
    }
  }


  createBoreholeCoreRecoveryLog(recoveryLogs: CoreRecoveryLog[], boreholeId: string) {
    this.spinner.show();
    this.coreService.createBoreholeCoreRecoveryLog(recoveryLogs).subscribe(data => {
    },
      error => {
        this.toast.error('Recovery log creation failed', 'Recovery Log Creation');
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toast.success('Recovery log creation successful', 'Recovery Log Creation');
        $('#coreRecoveryCreate').modal('hide');
        $('#coreRecoveryEdit').modal('hide');
        this.coreRecoveryLogs = [];
        $('#resetForm').click();
        this.getAllBoreholeCoreRecoveryLog(this.boreholeId);
        this.resetPaginator();
      });
  }

  resetPaginator() {
    this.dataSourceCoreRecoveryLogs.sort = this.sort;
    this.dataSourceCoreRecoveryLogs.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSourceCoreRecoveryLogs.filter = filterValue.trim().toLowerCase();
  }

  sortRunnerNumbers(coreRecoveryLogs: CoreRecoveryLog[]) {
    const array: number[] = [];
    if (coreRecoveryLogs.length !== 0) {
      coreRecoveryLogs.forEach(element => {
        array.push(parseFloat(element.runNumber));
      });
      array.sort((a, b) => 0 - (a > b ? -1 : 1));
      this.lastRunNumber = array[array.length - 1];
    } else {
      this.lastRunNumber = 1;
    }
  }

  resetForm() {
    $('#resetForm').click();
  }

  getAllBoreholeCoreRecoveryLog(id) {
    this.coreService.getBoreholeCoreRecoveryLogs(id).subscribe(
      data => {
        this.loading = false;
        this.corerecoverylogs = data as CoreRecoveryLog[];
        this.corerecoverylogs.forEach(value => {
          
          let person = this.persons.find(value1 => value1.id === value.geologist);
            if(person){
              let fullname = `${person.name} ${person.surname}`;
              value.geologistName = fullname;
              value.geologist = person.id;
            }
          });
        this.corerecoverylogs.sort((a, b) => <any>a.depthFrom - <any>b.depthFrom);
        this.dataSourceCoreRecoveryLogs.data = this.corerecoverylogs;
      },
      error => {
        this.loading = false;
      },
      () => {
        this.loading = false;

        this.sortRunnerNumbers(this.corerecoverylogs);
      }
    );
  }

  deleteRecordRequest(coreRecoveryLog: CoreRecoveryLog) {
    this.coreRecoveryLog = coreRecoveryLog;
    this.coreRecoveryLog.borehole = new Borehole();
    this.coreRecoveryLog.borehole.id = this.boreholeId;
    $('#deleteModal').modal({ backdrop: 'static' });
    $('#deleteModal').modal('show');
  }

  deleteRecord() {
    this.deleteCoreRecovery(this.coreRecoveryLog);
  }

  deleteCoreRecovery(coreRecovery: CoreRecoveryLog) {
    this.coreService.deleteCoreRecoveryLog(coreRecovery).subscribe(response => {

    },
      error => {
        this.toast.error('Record delete Failed.');

      }, () => {
        this.toast.success('Record deleted successfully');
        $('#deleteModal').modal('hide');
        this.getAllBoreholeCoreRecoveryLog(this.boreholeId);
        this.resetPaginator();
      });
  }


  showModal(): void {
    $('#deleteModal').modal({ backdrop: 'static' });
    $('#deleteModal').modal('show');
  }

  showCsvModal(): void {
    $('#csvModal').modal({ backdrop: 'static' });
    $('#csvModal').modal('show');
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  initialiseDataObjectsForImport(record: CoreRecoveryLog) {
    record.depthFrom = new Size();
    record.depthTo = new Size();
    record.totalCoreRecovery = new Size();

    record.depthFrom.unit = new Unit();
    record.depthTo.unit = new Unit();
    record.totalCoreRecovery.unit = new Unit();

    record.depthFrom.unit.id = UnitEnum.meter;
    record.depthTo.unit.id = UnitEnum.meter;
    record.totalCoreRecovery.unit.id = UnitEnum.meter;

    record.recoveryDate = new Date();
    record.lithology = '';
    record.rockQuality = '';
    record.geologist = '';
    this.coreRecoveryLogs = [];
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];
    const errorArr = [];
    this.getRunNumber();

    for (let i = 1; i < csvRecordsArray.length-1; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      let validated = true;
      data.forEach(value => {
        if(value){}
        else{
          validated = false;
        }
      });
      let personCsv = this.persons.find(value => value[1].trim().toLowerCase()===data[2].trim().toLowerCase());

      if(personCsv) {
        if(data.length === headerLength) {
          const csvRecord: CoreRecoveryLog = new CoreRecoveryLog();
          csvRecord.borehole = this.borehole;
          csvRecord.recoveryDate = new Date(data[0].trim());
          csvRecord.depthFrom = new Size();
          csvRecord.depthTo = new Size();
          csvRecord.totalCoreRecovery = new Size();
          csvRecord.depthFrom.measure = parseFloat(data[3].trim()) + '';
          csvRecord.depthTo.measure = parseFloat(data[4].trim()) + '';
          csvRecord.totalCoreRecovery.measure = parseFloat(data[5].trim()) + '';
          csvRecord.depthFrom.unit = new Unit();
          csvRecord.depthTo.unit = new Unit();
          csvRecord.totalCoreRecovery.unit = new Unit();
          csvRecord.depthFrom.unit.id = UnitEnum.meter;
          csvRecord.depthTo.unit.id = UnitEnum.meter;
          csvRecord.totalCoreRecovery.unit.id = UnitEnum.meter;
          csvRecord.geologist = data[2].trim()?personCsv?personCsv[1]:null:null;
          csvRecord.lithology = data[7].trim();
          csvRecord.rockQuality = data[6].trim();
          csvRecord.runNumber = parseFloat(this.csvRunNumber) + i + '';

          if(validated) {
            dataArr.push(csvRecord);
          } else {
            errorArr.push('Row: ' + i + ' insure the data conforms to requirements (all fields are mandatory) \n')
          }

        }
      }else{
        errorArr.push('Row: ' + i + ' Geologist should match a user from the system')
      }
    }
    if(errorArr.length>0){
      this.toast.warning('Import Data ',errorArr.toString())
    }

    this.csvDdataSource.data = dataArr;
    return dataArr;
  }

  saveCsvRecords() {
    this.coreRecoveryLogRecords = [];
    this.csvRecords.forEach(element => {
      this.coreRecoveryLogRecords.push(element);
    });
    this.saveBatch(this.coreRecoveryLogRecords);
    this.fileReset();
    this.dataSource = new MatTableDataSource(this.coreRecoveryLogRecords);
    $('#csvModal').modal('hide');
  }

  saveBatch(records: CoreRecoveryLog[]) {
    this.createBoreholeCoreRecoveryLog(records, this.boreholeId);
  }

  deleteCsvRecord(recoveryLog: CoreRecoveryLog) {
    this.selectedCvsRecord = recoveryLog;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toast.success('Record deleted', 'Record Deletion');
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

}
