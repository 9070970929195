import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreRecoveryLog} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/CoreRecoveryLog';
import {CoreService} from '../../../core.service';
import {DiscontinuityLog} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/DiscontinuityLog';

declare var $: any;

@Component({
  selector: 'app-discontinuity-log-list',
  templateUrl: './discontinuity-log-list.component.html',
  styleUrls: ['./discontinuity-log-list.component.scss']
})
export class DiscontinuityLogListComponent implements OnInit {


  runNumber: string;
  coreRecoveryId: string;
  coreRecoverylog: CoreRecoveryLog;
  dataSourceDiscontinuityLogs = new MatTableDataSource();
  discontinuityLogsColumns = ['#', 'Depth', 'DiscontinuityType', 'dipDirection', 'angleFromHorizon'];
  discontinuityForTable = DiscontinuityLog;
  discontinuityLogs: DiscontinuityLog[];
  loading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private coreService: CoreService, private router: ActivatedRoute, private pageroute: Router) {
    router.params.subscribe(
      data => {
        this.runNumber = data.run;
        this.coreRecoveryId = data.core;
      }
    );
  }

  ngOnInit() {

    this.getAllDiscontinuityLogs(this.coreRecoveryId, this.runNumber);
  }

  applyFilter(filterValue: string) {
    this.dataSourceDiscontinuityLogs.filter = filterValue.trim().toLowerCase();
  }


  ngAfterViewInit() {
    this.dataSourceDiscontinuityLogs.sort = this.sort;
    this.dataSourceDiscontinuityLogs.paginator = this.paginator;
  }

  createRecord() {
    $('#discontinuityLogCreate').modal({backdrop: 'static'});
    $('#discontinuityLogCreate').modal('show');
  }

  getAllDiscontinuityLogs(id, run) {
    this.coreService.getAllBoreholeCoreRecoveryDiscontinuityLog(run, id).subscribe(
      data => {
        this.discontinuityLogs = data as DiscontinuityLog[];
        this.dataSourceDiscontinuityLogs.data = this.discontinuityLogs;
        this.loading = false;
      }, error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }


}
