import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { Person } from './../../../_core/data/_models/people.model';
import { UserService } from './../../../auth/user/user.service';
import { CompanyEnum } from './../../../shared-kernel/enumerations/company-enum';
import { CompanyService } from './../../../applied-geoscience/company/company.service';
import { SampleLabSubmission } from './../../entity/SampleLabSubmission';
import { ToastrService } from 'ngx-toastr';
import { GeoTechService } from './../../../applied-geoscience/geo-tech/geo-tech.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sample-submission-create',
  templateUrl: './sample-submission-create.component.html',
  styleUrls: ['./sample-submission-create.component.scss']
})
export class SampleSubmissionCreateComponent implements OnInit {

  title: string;
  sampleSubmissionForm: FormGroup;
  sampleLabSubmission: SampleLabSubmission = new SampleLabSubmission();

  loadingPeople = false;
  filteredOptionsPeople: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  people: Person[] = [];

  @ViewChild('requestorSelect') requestorSelect: MatSelect;
  requestedBy: FormControl = new FormControl();
  requestorFilter: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private geoTechService: GeoTechService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SampleSubmissionCreateComponent>,
    private companyService: CompanyService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.initForms();
    if (this.data.sampleLabSubmission) {
      this.title = 'Edit Sample Submission';
    } else {
      this.title = 'Create Sample Submission';
    }

    this.getAllBGIPersonnel();
  }

  collectorFilter() {
    if (!this.people) return;
    let search = this.requestorFilter.value;
    if (!search) {
      this.filteredOptionsPeople.next(this.people.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptionsPeople.next(
      this.people.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  getAllBGIPersonnel() {
    this.loadingPeople = true;
    this.companyService.getCompanyByName(CompanyEnum.BGI_NAME).subscribe(company => {
      this.userService.getPersonsByCompanyId(company.id).subscribe(
        data => {
          this.people = data as Person[];
          this.people.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
          this.filteredOptionsPeople.next(this.people.slice());
          this.requestorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.collectorFilter();
          });

          this._setInitialRequestor();
          this.loadingPeople = false;
        }
      );
    });
  }

  private initForms() {
    this.sampleSubmissionForm = this.formBuilder.group({
      labNo: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.labNo : ''],
      submissionDate: [this.data.sampleLabSubmission? this.data.sampleLabSubmission.submissionDate ? new Date(this.data.sampleLabSubmission.submissionDate) : '' : null, Validators.required],
      requestedBy: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.requestedBy : ''],
      analysisDate: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.analysisDate? new Date(this.data.sampleLabSubmission.analysisDate) : null : null, Validators.required],
      completionDate: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.completionDate? new Date(this.data.sampleLabSubmission.completionDate) : null : null, Validators.required],
      sampleDescription: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.sampleDescription : ''],
      typeOfAnalysis: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.typeOfAnalysis : null, Validators.required],
      analysisComment: [this.data.sampleLabSubmission ? this.data.sampleLabSubmission.analysisComment : ''],
    });
  }

  onSubmit(){

    if (this.data.sampleLabSubmission) {
      this.sampleLabSubmission = this.data.sampleLabSubmission;
    }

    this.sampleLabSubmission.labNo = this.sampleSubmissionForm.controls.labNo.value;
    this.sampleLabSubmission.submissionDate = this.sampleSubmissionForm.controls.submissionDate.value;
    this.sampleLabSubmission.requestedBy = this.requestedBy.value.id;
    this.sampleLabSubmission.analysisDate = this.sampleSubmissionForm.controls.analysisDate.value;
    this.sampleLabSubmission.completionDate = this.sampleSubmissionForm.controls.completionDate.value;
    this.sampleLabSubmission.sampleDescription = this.sampleSubmissionForm.controls.sampleDescription.value;
    this.sampleLabSubmission.sample = this.data.sample;
    this.sampleLabSubmission.typeOfAnalysis = this.sampleSubmissionForm.controls.typeOfAnalysis.value;
    this.sampleLabSubmission.analysisComment = this.sampleSubmissionForm.controls.analysisComment.value;

    this.geoTechService.createSampleLabSubmission(this.sampleLabSubmission).subscribe(value => {
      }
      , error => {
        if(this.title === 'Create Sample Submission') {
          this.toastr.error(error, 'Create Sample Submission');
        } else {
          this.toastr.error(error, 'Update Sample Submission');
        }
      }, () => {
        this.closeModal();

        if(this.title === 'Create Sample Submission') {
          this.toastr.success('Sample Lab Submission Created Successfully', 'Sample Lab Submission Create');
        } else {
          this.toastr.success('Sample Lab Submission Updated Successfully', 'Sample Lab Submission Update');
        }
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

  compareFnDropDowns(stringOne: any, stringTwo: any): boolean {
    return stringOne && stringTwo ? stringOne === stringTwo : stringOne === stringTwo;
  }

  private _filterPeople(name: string): Person[] {
    const filterValue = name.toLowerCase();
    return this.people.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFnRequestedBy(requestedBy: Person): string {
    return requestedBy && requestedBy.name ? requestedBy.name +' '+ requestedBy.surname : '';
  }


  onFocusOutRigType() {
    let drillingRigType = this.sampleSubmissionForm.controls.requestedBy.value;
    if (drillingRigType && drillingRigType !== '') {
      let isPresent: boolean;
      isPresent = this.people.some(function(ds){
          if (drillingRigType.name) {
            return ds.name === drillingRigType.name;
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleSubmissionForm.get('requestedBy').setErrors(null);
        } else {
          this.sampleSubmissionForm.get('requestedBy').setErrors({ 'exist': true });
        }
    }
  }

  protected _setInitialRequestor() {
    this.filteredOptionsPeople.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.data.sample) {
        // Edit
        let person = this.people.find(pM => pM.id === this.data.sample.collectedBy);
        if (!person) {
          found = false;
        } else {
          found = true;
          this.requestedBy.setValue(person);
          this.requestorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;

      let person = this.people.find(pM => pM.id === this.data.sample.collectedBy);

      this.requestedBy.setValue(person);
      this.requestorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

}
