import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-minerals-geologist-sample-vetting-dashboard-ui',
  templateUrl: './minerals-geologist-sample-vetting-dashboard-ui.component.html',
  styleUrls: ['./minerals-geologist-sample-vetting-dashboard-ui.component.scss']
})
export class MineralsGeologistSampleVettingDashboardUiComponent implements OnInit {

  @Input('activitiesCount') activitiesCount: any;

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  cards :any;
  colors: any ;
  pieChartType = 'pie';
  isSet = true;

  allVetted = 0;

  public myactivitiesLabels = [ 'Pending-Approval', 'Returned', 'Submitted-Manager'];
  public myactivitiesData = [ 91, 107, 73, 4, 84];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _toaster: ToastrService,
    private userDashBoardService: UserDashboardService,
    private spinner: NgxSpinnerService,
  ) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

            return [
              { title: 'My Activities', cols: 1, rows: 1 },
              { title: 'Returned', cols: 1, rows: 1 },
              { title: 'Pending Approval', cols: 1, rows: 1 },
              { title: 'Submitted-Manager', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'},
            { title: 'Pending Approval',cols: 1, rows: 3 ,id: 'pendingApproval'},
            { title: 'Submitted-Manager', cols: 1, rows: 3 ,id: 'approved'}
          ];
        })
      );
  }


  ngOnInit() {
    this.myactivitiesData = [this.activitiesCount.pending, this.activitiesCount.returnedByGeologist,
      this.activitiesCount.submittedToManager];

      this.getIsDataset();
      this.getActivities();

      this.colors = [{backgroundColor: [  '#573419','#C8A207', '#00A2E5',
      '#CC0000','#009900', '#CC9933']}];

      this.allVetted = (Number(this.activitiesCount.returnedByGeologist)) + (Number(this.activitiesCount.submittedToManager));
  }

  getIsDataset(){
    this.isSet = (Number(this.activitiesCount.pending)+Number(this.activitiesCount.returnedByGeologist)+
      Number(this.activitiesCount.submittedToManager))>0;
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/mineral-resources/sample-collection-geologist/SUBMITTED_TO_GEOLOGIST']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/mineral-resources/sample-collection-geologist/REJECTED_BY_GEOLOGIST']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/mineral-resources/sample-collection-geologist/SUBMITTED_TO_MANAGER_BY_GEOLOGIST']);
        break
      default:
    }
  }

  getActivities() {
    this.spinner.show();
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeo('SUBMITTED_TO_GEOLOGIST').subscribe(res => {
        this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Pending activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeo('REJECTED_BY_GEOLOGIST').subscribe(res => {
        this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Rejected activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeo('SUBMITTED_TO_MANAGER_BY_GEOLOGIST').subscribe(res => {
        this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get activities');
      });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  gotoReturnedList(){
    this._router.navigate(['portal/activities/mineral-resources/sample-collection/Returned']);
  }

  gotoPendingSubmissionToQAList(){
    this._router.navigate(['portal/activities/mineral-resources/sample-collection/PENDING_QA_SUBMISSION']);
  }

  gotoApproved(){
    this._router.navigate(['portal/activities/mineral-resources/sample-collection/PUBLISHED']);
  }

}
