import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { Activity } from '../../activities/models';

@Injectable({
  providedIn: 'root'
})
export class QaDashboardService {

  constructor(private http: HttpClient) { }

  getBGIProjectCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBGIProjectCountUrl);
  }

  getMyProjectCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProjectCountUrl);
  }

  getPLsCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProspectingLicenseCountUrl);
  }

  getMyPLsCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProspectingLicenseCountUrl + '/' + creator);
  }
  getMyPlBoreholesCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProspectingLicenseBoreholesCountUrl + '/' + creator);
  }

  getNonPlBoreholesCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNonPlBoreholesCountUrl + '/' + creator);
  }

  getUserTrialPitCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getUserTrialPitsCountUrl + '/' + creator);
  }


  getNumberOfQaActivitiesPendingApproval(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNumberOfQaActivitiesPendingApprovalUrl);
  }

  getNumberOfQaRejected(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNumberAllQaActivitiesRejectedUrl);
  }

  getNumberOfQaApproved(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNumberAllQaActivitiesApprovedUrl);
  }

}
