import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";

import { Subscription, Observable } from 'rxjs';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { DynamicConePenetrometerTestLog } from 'src/app/_core/data/_models/dcp-foundation.models';
import { DcpFoundationData } from 'src/app/_core/data/dcp-foundation';
import { CreateTestPointComponent } from '../create-test-point/create-test-point.component';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: "test-point-list",
    templateUrl: "./test-point-list.component.html",
    styleUrls: ["./test-point-list.component.scss"]
})
export class TestPointListComponent implements OnInit, OnDestroy {
    private eventsSubscription: Subscription;
    testPoints: Array<DynamicConePenetrometerTestLog>;
    tableDataSource: MatTableDataSource<DynamicConePenetrometerTestLog>;
    displayedColumns: string[] = ['number', 'number_of_blows', 'penetration', 'penetration_rate', 'start_depth', 'end_depth', 'comments', 'edit', 'delete'];
    @Input() currentState: string ='';
    @Input() events: Observable<void>;
    @Input() testId: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        private dcpFoundationData: DcpFoundationData
    ) {}

    ngOnInit() {
        if (this.events)
            this.eventsSubscription = this.events.subscribe(() => this.getTestPoints());

        this.getTestPoints();
    }

    ngOnDestroy() {
        this.eventsSubscription.unsubscribe();
    }

    applyFilter(filterValue: string) {
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }

    onEdit(testPoint: DynamicConePenetrometerTestLog) {
        const newTestPointDialogRef = this.dialog.open(CreateTestPointComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: { testId: this.testId, testPoint: testPoint }
        });

        newTestPointDialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.getTestPoints();
            }
        });
    }

    onDelete(testPoint: DynamicConePenetrometerTestLog) {
        Swal.fire({
            title: "Delete test point",
            text: "Are you sure you want to delete a test point?",
            type: "question",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then(res => {
            if (res.value) {
                this.dcpFoundationData.deleteTestPoint(testPoint.id).subscribe(_res => {
                    this.toastr.info("Point deleted successfully", "Point Deleted");
                    this.getTestPoints();
                }, err => {
                    this.toastr.error(err.message, "Delete failed");
                });
            }
        });
    }

    getTestPoints() {
        if (this.testId) {
            this.dcpFoundationData.getTestPoints(this.testId).subscribe(res => {
                this.testPoints = res;
                this.testPoints.sort((a, b) => <any>a.startDepth.measure - <any>b.startDepth.measure);
                this.tableDataSource = new MatTableDataSource<DynamicConePenetrometerTestLog>(this.testPoints);
                this.tableDataSource.paginator = this.paginator;
            });
        } else {
            this.getTestPoints();
        }
    }
}
