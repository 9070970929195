import { LicenseType } from './license-type';
import { MineralType } from './mineral-type';
export class Commodity {
    id: string;
    name: string;
    description: string;
    licenseType: LicenseType;
    mineralType: MineralType;

    constructor () {
    }
}
