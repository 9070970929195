import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CoreService} from '../../core.service';
import {BoreholeLithologyLog} from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/BoreholeLithologyLog';
import {Borehole} from '../../../../../shared-kernel/entity/common/borehole';

@Component({
  selector: 'app-core-lithology-details',
  templateUrl: './core-lithology-details.component.html',
  styleUrls: ['./core-lithology-details.component.scss']
})
export class CoreLithologyDetailsComponent implements OnInit {

  lithologyLog: BoreholeLithologyLog;
  borehole: Borehole;
  boreholeType: string;

  constructor(
    private router: ActivatedRoute,
    private coreService: CoreService,
    private route: ActivatedRoute,) {
      this.route.params.subscribe(
        data => {
          this.boreholeType = data.type;
        }
      );
  }

  ngOnInit() {
    this.router.params.subscribe(prm => {
      this.getBoreholeLithologyLogById(prm.id);
      this.getBoreholeByLithologyLogId(prm.id);
    });
  }

  getBoreholeLithologyLogById(id: string) {
    this.coreService.getBoreholeLithologyLogById(id).subscribe(log => {
      this.lithologyLog = log as BoreholeLithologyLog;
    });
  }

  getBoreholeByLithologyLogId(id: string) {
    this.coreService.getBoreholeByLithologyLogId(id).subscribe(log => {
      this.borehole = log as Borehole;
    });
  }

}
