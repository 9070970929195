import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Sample } from '../../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { GeoTechService } from '../../geo-tech.service';
import { Person } from '../../../../common/entity/security/profile/person';
import { UserService } from '../../../../auth/user/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SampleCreateComponent } from '../sample-create/sample-create.component';
import { ImageComponent } from '../../../../common/image/image.component';
import { Lightbox } from 'ngx-lightbox';
import { ApproveAction } from '../../../models';
import Swal from "sweetalert2";
import { ApprovalService } from '../../borehole/services/approval.service';
import { ToastrService } from 'ngx-toastr';
import { CompanyEnum } from '../../../../shared-kernel/enumerations/company-enum';

@Component({
  selector: 'app-samples-details',
  templateUrl: './samples-details.component.html',
  styleUrls: ['./samples-details.component.scss']
})
export class SamplesDetailsComponent implements OnInit {

  samplesDetails: Sample;
  isBGIproject: boolean;
  companyEnum: typeof CompanyEnum = CompanyEnum;
  currentComment: string;
  sampleId: string;
  selectedSection: string;
  expanded: boolean = true;
  projectManager: any = {};
  projectCoordinator: any = {};
  projectGeologist: any = {};
  album: any = [];
  sampleTypes: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private geoTechService: GeoTechService,
    private _approvalServ: ApprovalService,
    private userService: UserService,
    private toast: ToastrService,
    private lightbox: Lightbox,
    private dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.sampleId = params.id;
    });

  }

  editSample(sample){
    const editDialogRef = this.dialog.open(SampleCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: sample
    });
    editDialogRef.afterClosed().subscribe(result => {
      this.getSampleBySampleId(this.sampleId);
    });
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  close(): void {
    this.lightbox.close();
  }

  openImage(image){
    const imageDialog = this.dialog.open(ImageComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: image
    });
    imageDialog.afterClosed().subscribe(result => {
    });
  }

  getProjectManager(projectManagerId) {
    this.userService.getPersonById(projectManagerId)
      .subscribe(data => {
        let fullName = data as Person;
        if (fullName) {
          this.projectManager.name = fullName.name+' '+fullName.surname;
          this.projectManager.id = fullName.id;
        }
      });
  }

  getProjectCoordinator(projectCoordinatorid) {
    this.userService.getPersonById(projectCoordinatorid)
      .subscribe(data => {
        let fullName = data as Person;
        if (fullName) {
          this.projectCoordinator.name = fullName.name+' '+fullName.surname;
          this.projectCoordinator.id = fullName.id;
        }
      });
  }

  getProjectGeologist(personid) {
    this.userService.getPersonById(personid)
      .subscribe(data => {
        let fullName = data as Person;
        if (fullName) {
          this.projectGeologist.name = fullName.name +' '+fullName.surname;
          this.projectGeologist.id = fullName.id;
        }
      });
  }

  getSampleBySampleId(id: string) {
    this.geoTechService.getSampleById(this.sampleId).subscribe(data => {
      this.samplesDetails = data as Sample;
      this.isBGIproject = this.samplesDetails.project.company.name === this.companyEnum.BGI_NAME;


      for (let image of this.samplesDetails.samplesLocationImages) {
        let albumImage: any = {};
        albumImage.src = 'data:image/jpg;base64,'+ image.image;
        albumImage.caption = image.name;
        albumImage.thumb = 'data:image/jpg;base64,'+ image.image;
        this.album.push(albumImage);
      }

      if (this.samplesDetails.sampleTypes) {
        this.sampleTypes = this.samplesDetails.sampleTypes.map(x => x.name).join(",");
      }

    }, error => {
    }, () => {
      this.getProjectCoordinator(this.samplesDetails.projectCoordinator);
      this.getProjectGeologist(this.samplesDetails.projectGeologist);
      this.getProjectManager(this.samplesDetails.projectManager);
    });
  }

  ngOnInit() {
    this.getSampleBySampleId(this.sampleId);
  }

  redicrectToDocuments() {
    this.selectedSection = 'sampleDocuments';
  }

  redicrectToTests() {
    this.selectedSection = 'sampleTests';
  }

  cancel() {
    localStorage.removeItem('borehole');
    this.router.navigate(['portal/applied-geoscience/geo-tech/samples/list']);
  }

  onSectionChange(selectedSection) {
    this.selectedSection = selectedSection;
  }

  companyView(companyId){
    const urlParameters = {id: companyId};
     this.router.navigate(['portal/applied-geoscience/company/detail', urlParameters]);
  }

  projectView(projectId){
    const urlParameters = {id: projectId};
    this.router.navigate(['portal/applied-geoscience/project/detail', urlParameters]);
  }

  personnelView(personId){
    this.router.navigate(['portal/personnel/' + personId]);
  }


  changeState(action: ApproveAction) {
    let successMessage: string = 'Sample submitted successfully';
    let title: string = 'Sample Submit';

    switch (action) {
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'Sample submitted successfully';
        title = 'Sample Submit';
        break;
      case ApproveAction.QA_APPROVE:
        successMessage = 'Sample approved successfully';
        title = 'Sample Approve';
        break;
      case ApproveAction.QA_REJECT:
        successMessage = 'Sample rejected successfully';
        title = 'Sample Reject';
        break;
      case ApproveAction.MANAGER_APPROVE:
        successMessage = 'Sample approved successfully';
        title = 'Sample Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        successMessage = 'Sample rejected successfully';
        title = 'Sample Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        successMessage = 'Sample published successfully';
        title = 'Sample Published';
        break;
      default:
        throw new Error('Unknown change state');
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this._approvalServ.changeSampleState(this.sampleId, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toast.success(successMessage, title);
          this.getSampleBySampleId(this.sampleId);
        }, err => {
          this.toast.warning(err.message, 'Failed');
        });
      }
    });

  }


}
