import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Remove delete functionality on Activities:
 *
 * If the activity currentState is either 'PENDING_QA_SUBMISSION'
 * then the templated elements (edit button) will be removed from the DOM
 *
 * e.g
 * <span *canDeleteActivity="infiltrationTest.currentState.currentState" >
 *   <a mat-icon-button *ifCreator="infiltrationTest.creator"
 *   *ifCreator="infiltrationTest.creator" (click)="deleteRecordRequest(infiltrationTest)">
 *    <i class="delete icon bgi-brown-txt"></i>
 *   </a>
 * </span>
 *
 */
@Directive({selector: '[canDeleteActivity]'})
export class CanDeleteActivityDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
  }

  @Input() set canDeleteActivity(currentState: string) {

    if ((currentState === 'PENDING_QA_SUBMISSION')) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
