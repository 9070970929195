import { MatSelect } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { CompanyEnum } from 'src/app/shared-kernel/enumerations/company-enum';
import { ReplaySubject, Subject } from 'rxjs';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { Person } from './../../../../../_core/data/_models/people.model';
import { ProjectRole } from './../../../../../shared-kernel/entity/applied-geoscience/project/project-role';
import { ProjectService } from './../../../project.service';
import { UserService } from './../../../../../auth/user/user.service';
import { ProjectMembers } from '../project-member.model';
import { Members } from '../../../../../shared-kernel/entity/applied-geoscience/project/members';

const BGI_NAME: string = 'Botswana Geoscience Institute';

@Component({
    selector: "pr-add-members",
    templateUrl: './add-member.component.html',
    styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {
    people: Array<Person>;
    roles: Array<ProjectRole>;
    memberForm: FormGroup;
    submitted: boolean = false;
    memberStillLoading: boolean = true;

    filteredOptionsPeople: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
    collectedBy: FormControl = new FormControl();
    loadingPeople = false;
    collectorFilter: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();

    @Input('projectId') projectId: string;
    @Input('companyId') companyId: string;
    @Input('memberList') members:Array<any>;

    @Output('onMemberAdded') onMemberAdded = new EventEmitter<boolean>();
    @ViewChild('collectorSelect') collectorSelect: MatSelect;

    constructor(
        private _fb: FormBuilder,
        private _toastr: ToastrService,
        private _projectSrv: ProjectService,
        private userService: UserService,
    ) {}

    get f() { return this.memberForm.controls; }

    ngOnInit() {
        this._initForm();
        this._getRoles();
        this.getAllBGIPersonnel(this.companyId);
    }

    onSubmit() {
        if (this.memberForm.invalid) return;

        this.submitted = true;

        let member = <ProjectMembers>{
            personId: this.collectedBy.value.id,
            project: this.projectId,
            deleted: false,
            projectRoles: this.f.projectRole.value,
            nonBgiRole: '',
            member: this.collectedBy.value.user ? this.collectedBy.value.user.username : null
        };

        this._projectSrv.addProjectMember(this.projectId, member).subscribe(res => {
            this.onMemberAdded.emit(res !== null && res !== undefined);
        }, err => {
            this._toastr.error(err.message, "Failed to add Member");
        });

        this.submitted = false;
    }

    private _getRoles() {
        this._projectSrv.getProjectRoles().subscribe(res => {
            this.roles = res as Array<ProjectRole>;
        }, err => {
            this._toastr.error(err.message, "Failed to get Project Roles");
        });
    }

    getAllBGIPersonnel(company: string) {
      this.loadingPeople = true;
          this.userService.getBGIAndNoBGIPersons(this.companyId).subscribe(res => {
            this.people = res as Person[];
            if(this.members){
            this.members.forEach(p => {
              this.people = this.people.filter(value => value.id !== p.personId);
            });
          }
            this.people.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
            this.filteredOptionsPeople.next(this.people.slice());
            this.collectorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
              this.collectorFilterFnc();
            });
            this.loadingPeople =false;

        }, err => {
            this._toastr.error(err.message, "Failed to get People");
        });
    }

    collectorFilterFnc() {
      if (!this.people) return;
      let search = this.collectorFilter.value;
      if (!search) {
        this.filteredOptionsPeople.next(this.people.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      this.filteredOptionsPeople.next(
        this.people.filter(pM => {
          let fullName = pM.name + pM.middleName + pM.surname;
          return fullName.toLowerCase().indexOf(search) > -1;
        })
      );
    }

    private _initForm() {
        this.memberForm = this._fb.group({
            projectRole: [null, [Validators.required]]
        });
    }
}
