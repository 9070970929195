import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Company } from '../../../shared-kernel/entity/applied-geoscience/company/company';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CompanyService } from '../../company/company.service';
import { Person } from '../../../_core/data/_models/people.model';
import { ProjectService } from '../../project/project.service';
import { ProspectingLicense } from '../../../shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license';
import { ProspectingLicenseService } from '../prospecting-license.service';
import { ToastrService } from 'ngx-toastr';
import { PeopleService } from '../../../_core/mock/people.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {ProspectingLicenseMember} from '../../../shared-kernel/entity/applied-geoscience/project/plmember';

@Component({
  selector: 'app-prospecting-licence-members-form',
  templateUrl: './prospecting-licence-members-form.component.html',
  styleUrls: ['./prospecting-licence-members-form.component.scss']
})
export class ProspectingLicenceMembersFormComponent implements OnInit {

  company: Company;

  members: Person[];
  selectedMember: Person = null;

  role: '';

  prospectingLicense: ProspectingLicense;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  columns = ['#', 'Name', 'Project Roles', 'Actions'];

  pLMembers = [];

  loading = true;
  flag = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private projectService: ProjectService,
              private dialogRef: MatDialogRef<ProspectingLicenceMembersFormComponent>,
              private companyService: CompanyService, private pLService: ProspectingLicenseService,
              private toastr: ToastrService, private peopleService: PeopleService,
              private _router: Router) {
  }

  ngOnInit() {
    this.prospectingLicense = this.data.prospectingLicense;
    this.pLMembers = this.prospectingLicense.projectMembers;
    this.formatData();

    this.company = this.prospectingLicense.company;
    this.getCompanyPersonnel();
  }

  goTo(filter: string) {
    this._router.navigate([`portal/personnel/${filter}`]);
    this.dialogRef.close();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  /**
   * Get selected company personnel. This method is also called when the selected company change.
   */
  getCompanyPersonnel() {
    this.companyService.getAllCompanyPersonnel(this.company.id).subscribe(
      list => {
        this.members = list as Person[];
        this.loading = false;
        this.selectedMember = null;
      }
    );
  }

  addMember() {
    const member: ProspectingLicenseMember = {
      memberId : this.selectedMember.id,
      plId : this.prospectingLicense.id,
      role : this.role
    };

    let flag = false;
    this.pLMembers.forEach(mbr => {
      if (mbr.memberId === member.memberId) {
        this.toastr.error(`${this.selectedMember.name} ${this.selectedMember.surname} has been added already to the members list. Update
         to save changes`, 'Member Exist');
        flag = true;
      }
    });

    if (!flag) {
      this.pLMembers.push(member);
         this.formatData();
    }
    this.selectedMember = null;
    this.role = null;
  }

  updatePLMember() {
    const member: ProspectingLicenseMember = {
      memberId : this.selectedMember.id,
      plId : this.prospectingLicense.id,
      role : this.role
    };
    let flag = false;
    this.pLMembers.forEach(mbr => {
      if (mbr.memberId === member.memberId) {
        this.toastr.error(`${this.selectedMember.name} ${this.selectedMember.surname} has been added already to the members list. Update
         to save changes`, 'Member Exist');
        flag = true;
      }
    });

    if (!flag) {
      
    this.pLMembers.push(member);
    this.selectedMember = null;
    this.role = null;

    this.prospectingLicense.projectMembers = this.pLMembers;

    this.pLService.createProspectingLicense(this.prospectingLicense).subscribe(
      response => {
        this.toastr.success('Prospecting license project members updated successfully', 'Update Project Members');
        this.onClose();
      },
      error => {
        this.toastr.error(error.error.message, 'Error Occurred During An Update');
        this.onClose();
      }, () => {
        this.getPLByID();
        this.formatData();
      });
    }
  }

  /**
   * Re-format data for display purposes.
   */
  formatData() {
    this.dataSource = new MatTableDataSource(this.pLMembers);

    this.pLMembers.forEach(member => {
      this.peopleService.getPersonById(member.memberId).subscribe(person => {
        this.companyService.getCompanyById(person.company).subscribe(
          company => {
            member['person'] = person;
            // member['company'] = company as Company;
          });
      });
    });
  }

  /**
   * Refresh prospecting licence details
   */
  getPLByID() {
    this.pLService.getPLById(this.prospectingLicense.id).subscribe(
      pl => {
        this.prospectingLicense = pl as ProspectingLicense;
      }
    );
  }

  delete(member: any) {
    Swal.fire({
      title: `Are you ? You wont be able to change this!`,
      type: 'question',
      confirmButtonText: 'Ok',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        const temp = [];
        this.pLMembers.forEach(mbr => {
          if (mbr.memberId !== member.memberId) {
            temp.push(mbr);
          }
        });

        this.pLMembers = temp;
        this.formatData();
      }
    });
  }

  /**
   * Send back updated PL to the calling parent.
   */
  onClose() {
    if (this.prospectingLicense.projectMembers.length !== this.pLMembers.length) {
      Swal.fire({
        title: `Do you want to discard changes that you have made?`,
        type: 'question',
        confirmButtonText: 'Discard',
        showCancelButton: true,
        cancelButtonText: 'Save Changes',
      }).then((result) => {
        if (result.value) {
          this.dialogRef.close(this.prospectingLicense);
        } else {
          this.updatePLMember();
          this.dialogRef.close(this.prospectingLicense);
        }
      });
    } else {
      this.dialogRef.close(this.prospectingLicense);
    }

  }

}
