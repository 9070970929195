import {Company} from '../company/company';
import {Notes} from '../notes';
import {Commodity} from './commodity';
import {Status} from './status';
import {LicenseType} from './license-type';
import {FileToUpload} from '../../../../common/uploaded-files/entity/file';
import {ProspectingLicenseMember} from '../project/plmember';

export class ProspectingLicense {
  id: string;
  creator: string;
  prospectingLicenseNumber: string;
  currentProspectingLicenseType: LicenseType;
  startDate: Date;
  endDate: Date;
  commodities: Commodity[];
  description: Notes;
  status: Status[];
  currentStatus: Status;
  company: Company;
  prospectingLicenseFiles: FileToUpload [];
  projectMembers: ProspectingLicenseMember[];
  constructor() {
  }
}
