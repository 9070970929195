import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../../../core/core.service';
import { SpecimenService } from '../../../../../collections/views/geoheritage/geological-specimen/services/specimen.service';
import { ToastrService } from 'ngx-toastr';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';

@Component({
  selector: 'app-water-analytical-reports',
  templateUrl: './water-analytical-reports.component.html',
  styleUrls: ['./water-analytical-reports.component.scss']
})
export class WaterAnalyticalReportsComponent implements OnInit {

  @Input() borehole;
  data: any[] = [];

  scalingFactor = 50;

  rockTypes = ['igneous-rock.jpg', 'metamorphic-rock.jpg', 'sedimentary-rock.jpg'];
  rockNames = ['Igneous Rock', 'Metamorphic Rock', 'Sedimentary Rock'];
  filteredGroups = [];

  constructor(private router: ActivatedRoute, private coreService: CoreService,private specimenServ: SpecimenService,private toastr: ToastrService,) {

  }

  ngOnInit() {
    this.formatData();
  }

  formatData() {
    this.data = [];
    this.router.params.subscribe(
      param => {
        this.coreService.getBoreholeById(param['id']).subscribe(borehole => {

          this.borehole = borehole;

          const totalDepth = this.getTotalDepth(this.borehole.boreholeLithologyLogs);
          this.borehole.boreholeLithologyLogs.forEach(log => {


            const percentage = (((((log.depthTo.measure) - (log.depthFrom.measure)) / totalDepth) * 100));

            const index = this.rockNames.indexOf(log.rockName.rock.trim());

            this.data.push(
              {
                thickness: {from: log.depthFrom.measure, to: log.depthTo.measure},
                penetration: {},
                percentage: percentage,
                height: percentage + parseFloat(this.scalingFactor + ''),
                color: log.color,
                sample: log.rockName.rockImages[0]?log.rockName.rockImages[0].image:'Image N/A',
                type: log.rockName.rock,
                description: log.lithologyDescription.description
              });
          });
        });
      });
  }

  getTotalDepth(lithologyLogs: any[]): number {
    let totalDepth = 0;
    lithologyLogs.forEach(log => {
      totalDepth += ((log.depthTo.measure) - (log.depthFrom.measure));
    });

    return totalDepth;
  }
}
