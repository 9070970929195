import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {

  constructor(private http: HttpClient) { }

  getBGIProjectCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBGIProjectCountUrl);
  }


  getNonBGIProjectCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNonBGIProjectCountUrl);
  }

  getMyProjectCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProjectCountUrl);
  }

  getMyProjectApprovalCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProjectApprovalCountsUrl );
  }

  getMyProspectingLicencesApprovalCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProspectingLicenseApprovalCountsUrl );
  }


  getMyProspectingLicencesActivitiesApprovalCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/ags-common/v1/prospecting-license/activity/individual/counts' );
  }

  getPLsCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProspectingLicenseCountUrl);
  }

  getMyPLsCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProspectingLicenseCountUrl + '/' + creator);
  }
  getMyPlBoreholesCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProspectingLicenseBoreholesCountUrl + '/' + creator);
  }

  getNonPlBoreholesCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNonPlBoreholesCountUrl + '/' + creator);
  }

  getUserTrialPitCount(creator: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getUserTrialPitsCountUrl + '/' + creator);
  }


  getMyActivitiesCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mine/${status}/count`);
  }


  getMyProjectActivitiesCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mine/${status}/project/count`);
  }

  getMyProspectingLicensesActivitiesCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mine/${status}/prospecting-licences/count`);
  }


  getMyProspectingLicensesActivitiesCountPerDistrict (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mine/${status}/prospecting-licences/district`);
  }


  getMyActivitiesMineralResourcesCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/mine/${status}/count`);
  }


  getMyBoreholeMineralResourcesCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/mine/borehole/${status}/count`);
  }


  getBoreholeMineralResourcesVettingCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/borehole/${status}/count`);
  }

  getBoreholeMineralResourcesVetting (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/borehole/${status}`);
  }

  getMyActivitiesMineralResourcesQACount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/qa/${status}/count`);
  }

  getMyActivitiesMineralResourcesVettingQACount (status:string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/mineral-resources/vetting/qa/${status}/count`)
  }
  getMyActivitiesMineralResourcesQAllVettedCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/mineral-resources/vetting/qa/all/count`)
  }
  getMyActivitiesMineralResourcesQAllVetted(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/mineral-resources/vetting/qa/all`)
  }
  getMyActivitiesMineralResourcesVettingQA (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/qa/${status}`);
  }

  getMyActivitiesMineralResourcesVettingGeoCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/geo/${status}/count`);
  }

  getMyActivitiesMineralResourcesManagerCount (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/manager/${status}/count`);
  }

  /**
   * @param status : Approval status e.g currentStatus
   * @param activityType : either project or prospecting license activity
   */
  getUserActivitiesCount (status: string, activityType: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/activity/${activityType}/${status}/count`);
  }

  getUserActivities (status: string, activityType: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/activity/${activityType}/${status}`);
  }

  getMyActivitiesMineralResources (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/mine/${status}`);
  }

  getMyBoreholeMineralResources (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/mine/borehole/${status}`);
  }


  getBoreholeMineralResourcesVettingRecents (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/borehole/${status}/recents`);
  }

  getMyBoreholeMineralResourceRecents (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/mine/borehole/${status}/recents`);
  }

  getMyActivitiesMineralResourcesQA (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/qa/${status}`);
  }

  getMyActivitiesMineralResourcesVettingGeo (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/geo/${status}`);
  }

  getMyActivitiesMineralResourcesManager (status: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/mineral-resources/vetting/manager/${status}`);
  }


  getUserActivitiesRejected (status: string, activityType: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +  `/api/activities/v1/activities_rejected/${activityType}/${status}`);
  }

  getUserTrayActivities (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/${activityType}/${status}`);
  }

  getUserTrayActivitiesTopTen (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/top_ten/${activityType}/${status}`);
  }

  getSeniorArchivistTrayActivities (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/senior-archivist/${status}`);
  }

  getSeniorArchivistTrayActivitiesCount (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/senior-archivist/${status}/count`);
  }

  getUserTrayActivitiesCount (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/${activityType}/${status}/count`);
  }

  getRecentCuratorActivities(): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl  + '/api/activities/v1/activity/${activityType}/${status}/count');
  }

  getRecentGeologicalSpecimenActivities(): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl  + '/api/activities/v1/activity/${activityType}/${status}/count');
  }

  getGeologicalSpecimenCuratorActivities() :Observable<any> {
    return this.http.get(environment.collectionsBaseUrl  + '/api/activities/v1/activity/geological_specimen/recent');
  }

  getGeologicalSpecimenCuratorActivityCounts(): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl  + '/api/activities/v1/activity/geological_specimen/count');
  }

  getGeologicalSpecimenApprovalActivityCounts(): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl  + '/api/activities/v1/activity/geological_specimen/approval/count');
  }


  getGeologicalSpecimenApprovalActivity(): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl  + '/api/activities/v1/activity/geological_specimen/approval/recent');
  }

  getUserRequestActivities (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/${activityType}/${status}`);
  }

  getManagerRequestActivities (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/manager/${status}`);
  }

  getManagerRequestActivitiesCount (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/manager/${status}/count`);
  }

  getUserRequestActivitiesCount (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/${activityType}/${status}/count`);
  }



  getUserSpecimenRequestActivities (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/specimen/${activityType}/${status}`);
  }

  getManagerSpecimenRequestActivities (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/manager/specimen/${status}`);
  }

  getManagerSpecimenRequestActivitiesCount (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/manager/specimen/${status}/count`);
  }

  getUserSpecimenRequestActivitiesCount (status: string, activityType: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/activity/request/specimen/${activityType}/${status}/count`);
  }
  // Minerals Files approval Dashboard
  // ..>>
  getFileActivities (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/nigis_file_activities/${status}`);
  }

  getVettingFileActivities (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/nigis_file_activities/${status}/vetting_portfolio`);
  }

  getActivitiesCount (status: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/nigis_file_activities/${status}/count`);
  }

  getActivitiesCounts (role: string): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/nigis_file_activities/count/${role}`);
  }
  getMyActivities (): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/my_files`);
  }
  getMyFilesActivitiesCount (): Observable<any> {
    return this.http.get(environment.collectionsBaseUrl +  `/api/activities/v1/my_files/count`);
  }

  getUserProjectActivitesByStageCount(state?: string): Observable<number> {
    return this.http.get<number>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/user/project/activities` + '/' + state + '/count');
  }

}
