import { LocalAuthorities } from './../../../../_auth/_providers/local-authorities';
import { TechnicianSampleCreateComponent } from './../technician-sample-create/technician-sample-create.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeoTechService } from './../../../../applied-geoscience/geo-tech/geo-tech.service';
import { BusinessUnitEnum } from './../../../../shared-kernel/enumerations/business-unit-enum';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Sample } from './../../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {ChipsTrayDetails} from "../../../../collections/entity/ChipsTrayDetails";
import Swal, {SweetAlertOptions} from "sweetalert2";
import { StorageConstants } from '../../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-technician-sample-list',
  templateUrl: './technician-sample-list.component.html',
  styleUrls: ['./technician-sample-list.component.scss']
})
export class TechnicianSampleListComponent implements OnInit {

  samples: Sample[];
  dataSourceSamples: MatTableDataSource<Sample> = new MatTableDataSource();
  displayedSampleColumns = ['#', 'projectName', 'sampleNumber', 'sampleType', 'nameOfSampler', 'view', 'edit', 'delete'];
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  sampleCount:number = 0;
  loading:boolean = true;
  @Input('entityId') entityId: string = '';
  @Input('projectId') projectId: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  currentPrincipal: any;
  isLoggedPersonCreator:boolean = false;

  constructor(
    private geoTechService: GeoTechService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
  ) { }

  ngOnInit() {
    this._currentPrincipal();
    this.getSamplesByUnit();
  }

  private _currentPrincipal() {
    this._localStorage.getItem<any>(StorageConstants.fullUserKey).subscribe(res => {
      this.currentPrincipal = res;
    });
  }

  isCreator(sample):boolean{
    if (this.currentPrincipal.username === sample.creator) {
     return  true;
    } else {
      return false;
    }
  }

  getSamplesByUnit() {
    if(this.entityId.length<1){
      this.geoTechService.getSamplesByUnitAndRole(this.businessUnitEnum.mineralResources, 'Technician').subscribe(
        value => {
          let retrievedSamples = value as any [];
          new Promise((resolve) => {
            retrievedSamples.forEach((sample, index, array) => {
              this.localAuthorities.getPersonByUsername(sample.creator).subscribe(res => {
                const person = res;
                sample.samplerName = person.name + ' ' + person.surname;
                if (index === array.length - 1) {
                  resolve(sample);
                }
              }, err => {
                this.toastr.error(err.message, 'Failed to retrieve people details');
              });
            });
          });

          const currentYear = new Date().getFullYear();
          const thisYearSamples = retrievedSamples.filter(sample => sample.year === currentYear);

          this.sampleCount = thisYearSamples.length;
          this.dataSourceSamples.data = retrievedSamples;
          const datasourceArray = this.dataSourceSamples.data;

          this.dataSourceSamples.data = datasourceArray.sort((x, y) => +new Date(y.created) - +new Date(x.created)) as Sample[];

          this.dataSourceSamples = new MatTableDataSource<any>(this.dataSourceSamples.data);
          this.dataSourceSamples.paginator = this.paginator;
          this.loading = false;
        },error => {
          this.loading = false;
        },() => {
          this.dataSourceSamples = new MatTableDataSource<any>(this.dataSourceSamples.data);
          this.dataSourceSamples.sort = this.sort;
          this.loading = false;
        });
    }

  else{
      this.geoTechService.getSamplesByBoreholeId(this.entityId).subscribe(
        value => {
          let retrievedSamples = value as any [];
          new Promise((resolve) => {
            retrievedSamples.forEach((sample, index, array) => {
              this.localAuthorities.getPersonByUsername(sample.creator).subscribe(res => {
                const person = res;
                sample.samplerName = person.name + ' ' + person.surname;
                if (index === array.length - 1) {
                  resolve(sample);
                }
              }, err => {
                this.toastr.error(err.message, 'Failed to retrieve people details');
              });
            });
          });

          const currentYear = new Date().getFullYear();
          const thisYearSamples = retrievedSamples.filter(sample => sample.year === currentYear);

          this.sampleCount = thisYearSamples.length;
          this.dataSourceSamples.data = retrievedSamples;
          const datasourceArray = this.dataSourceSamples.data;

          this.dataSourceSamples.data = datasourceArray.sort((x, y) => +new Date(y.created) - +new Date(x.created)) as Sample[];

          this.dataSourceSamples = new MatTableDataSource<any>(this.dataSourceSamples.data);
          this.dataSourceSamples.paginator = this.paginator;
          this.loading = false;
        },error => {
          this.loading = false;
        },() => {
          this.dataSourceSamples = new MatTableDataSource<any>(this.dataSourceSamples.data);
          this.dataSourceSamples.sort = this.sort;
          this.loading = false;
        });
    }
  }

  onCreate() {
    const obj = {sample: null, sampleCount: this.sampleCount,entityId:this.entityId};
    const newGeoHeritageDialogRef = this.dialog.open(TechnicianSampleCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getSamplesByUnit();
    });
  }

//  open details
  openDetails(sampleId) {
    const urlParams = { id: sampleId };
    this.router.navigate(['/portal/mineral-resources/samples/technician/details', urlParams]);
  }

  // on edit
  onEdit(sample) {
    const obj = {sample: sample, sampleCount: this.sampleCount,entityId:this.entityId};
    const newGeoHeritageDialogRef = this.dialog.open(TechnicianSampleCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getSamplesByUnit();
    });
  }

  onDelete(sample: Sample) {
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete Sample',
      type: 'warning',
      html: `Are you sure you want to <b>Delete</b> this Sample?`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(result => {
      if (result && result.value === true) {
        this.spinner.show();
        this.geoTechService.deleteSample(sample).subscribe(
          value => {
            this.getSamplesByUnit();
            this.spinner.hide();
          }
          , error => {
            this.toastr.error(error, 'Delete Sample');
          }
          , () => {
            this.toastr.success('SampleDeleted Successfully', 'Delete Sample');
          }
        );
      }
    });
  }

  navigateToMap() {
    let shortenedSamples = this.sanitizeList();
    if (shortenedSamples.length > 0) {
      localStorage.setItem('list', JSON.stringify(shortenedSamples));
      const from = { list: 'samples' };
      this.router.navigate(['portal/applied-geoscience/geo-tech/samples/locations', from]);
    } else {
      this.toastr.warning('Please make sure that Borehole list is not Empty', 'Plot To Map');
    }
  }


  sanitizeList():any[] {
    let sample :any[] = [];
    this.samples.forEach(element => {
      sample.push({id:element.id,locationTemp:{
          latitude:element.locationTemp.coordinates.latitude,
          longitude:element.locationTemp.coordinates.longitude}
        ,sampleNumber:element.sampleNumber,
        locationDescription:element.locationDescription.description
      });

    });

    return sample;
  }

  exportPDF() {

  }

  exportCSV() {

  }

  applyFilter(filterValue: string) {
    this.dataSourceSamples.filter = filterValue.trim().toLowerCase();
  }

}
