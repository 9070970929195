import { Injectable, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HydrogeologyValidation implements OnInit {
  validation_messages = {
    'stepNumber': [
      {type: 'required', message: 'Step Number is required'}
    ],
    'relativeTimeMinutes': [
      {type: 'required', message: 'Relative Time is required'},
    ],
    'depthToWater': [
      {type: 'required', message: 'Depth to Water is required'},
    ],
    'drawdown': [
      {type: 'required', message: 'Drawdown is required'},
    ],
    'pumpingRate': [
      {type: 'required', message: 'Pumping Rate is required'},
    ],
    'timeToFill10L': [
      {type: 'required', message: 'Time To Fill 10L is required'},
    ],
    'timeToFill20L': [
      {type: 'required', message: 'Time To Fill 20L is required'},
    ],
    'timeToFill': [
      {type: 'required', message: 'Time To Fill is required'},
    ],
    'timeToFill50L': [
      {type: 'required', message: 'Time To Fill 50L is required'},
    ],
    'swl': [
      {type: 'required', message: 'SWL is required'},
    ],
    'comments': [
      {type: 'required', message: 'Comment is required'},
    ],
    'borehole': [
      {type: 'required', message: 'Borehole is required'},
    ],
    'pH': [
      {type: 'required', message: 'pH is required'},
    ], 'flowRate': [
      {type: 'required', message: 'Flow rate is required'},
    ], 'electricalConductivity': [
      {type: 'required', message: 'Electrical Conductivity is required'},
    ], 'temperature': [
      {type: 'required', message: 'Temperature is required'},
    ]
  };

  validation_messages_water_pumping_test = {
    'testNumber': [
      {type: 'required', message: 'Test Number is required'}
    ],
    'date': [
      {type: 'required', message: 'Date is required'},
    ],
    'geologist': [
      {type: 'required', message: 'Geologist is required'},
    ]
  };

  constructor(private toastr: ToastrService) {
  }

  ngOnInit() {
  }


  projectDatesvalid(openDate: Date, endDate: Date, startTime: Date, endTime: Date): boolean {

    if (startTime >= endTime) {
      this.errorsmsg('Please make sure open date and time is BEFORE close date and time');
      return false;
    }

    return true;
  }

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }

}
