import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DrillingChecklist } from 'src/app/shared-kernel/entity/driller/DrillingChecklist';
import { DrillerChecklistCreateComponent } from '../driller-checklist-create/driller-checklist-create.component';

@Component({
  selector: 'app-drilling-checklist-detail',
  templateUrl: './drilling-checklist-detail.component.html',
  styleUrls: ['./drilling-checklist-detail.component.scss']
})
export class DrillingChecklistDetailComponent implements OnInit {

  @Input("drillingChecklist") drillingChecklist?: DrillingChecklist;
  @Output() checklistEvent = new EventEmitter<boolean>();
  expanded: boolean = true;


  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  firthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  onEdit() {
    const changeStateDialogRef = this.dialog.open(DrillerChecklistCreateComponent, <MatDialogConfig>{
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        data: { action: 3, drillingRequestId: this.drillingChecklist.drillingRequest.id, drillingChecklist: this.drillingChecklist }
    });

    changeStateDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.checklistEvent.emit(true);
        }
    });
}

}
