import { Size } from '../../common/size';
import { InfiltrationTest } from './InfiltrationTest';

export class InfiltrationTestLog {
    id: string = '';
    time: number;
    head: Size = new Size();
    headDiff: number;
    infiltrationRate: number;
    infiltrationTest: InfiltrationTest = new InfiltrationTest();
    deleted: boolean = false;
  }
