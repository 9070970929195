import { LocalAuthorities } from 'src/app/_auth/_providers';
import { ActivitiesService } from './../../../activities/services/activities.service';
import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-manager-driller-dashboard-ui',
  templateUrl: './manager-driller-dashboard-ui.component.html',
  styleUrls: ['./manager-driller-dashboard-ui.component.scss']
})
export class ManagerDrillerDashboardUiComponent implements OnInit {

  @Input('drillerDashboardCounts') drillerDashboardCounts: any;

  cards :any;
  public pieChartType = 'pie';
  currentUser: any;
  currentPerson: any;
  isSet = true;
  colors: any ; 

  public myactivitiesLabels = [ 'Drilling Requests', 'Submitted- Drilling Superintended', 'Returned', 'Approved', 'Returned-Superintended'];
  public myactivitiesData = [ 91, 107, 73, 54, 43];

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  submittedToSupTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _localStorage: LocalStorage,
    private _toaster: ToastrService,
    private _router: Router,
    private _activitiesServ: ActivitiesService,
    private _localAuthorities: LocalAuthorities,
  ) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Submitted - Drilling Superintendent', cols: 1, rows: 3 ,id: 'submittedDrillingSuperintendent'},
            { title: 'Drilling Requests',cols: 1, rows: 3 ,id: 'pendingApproval'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'}
          ];
        })
      );
  }

  ngOnInit() {
    this.myactivitiesData = [Number(this.drillerDashboardCounts.DRILLING_REQUESTS), Number(this.drillerDashboardCounts.SUBMITTED_TO_SUPERINTENDENT), Number(this.drillerDashboardCounts.RETURNED),
                            Number(this.drillerDashboardCounts.APPROVED), Number(this.drillerDashboardCounts.RETURNED_SUPERINTENDENT)];
    this.getIsDataset();

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
    }, err => {
      this._toaster.warning(err.message, "Current User Error");
    },() => {

    });
    this.colors = [{backgroundColor: [  '#573419','#00A2E5',
    '#C8A207','#009900','#CC0000', '#CC9933']}];
  }

  private initPerson(userId: string) {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toaster.error(err.message, "Profile Details");
    }, () => {
      (async () => {
        await this.getActivities(this.currentPerson);
      })();
    });
  }

  getIsDataset(){
    this.isSet = (Number(this.drillerDashboardCounts.DRILLING_REQUESTS) + Number(this.drillerDashboardCounts.SUBMITTED_TO_SUPERINTENDENT) + Number(this.drillerDashboardCounts.RETURNED) +
    Number(this.drillerDashboardCounts.APPROVED) + Number(this.drillerDashboardCounts.RETURNED_SUPERINTENDENT))>0;
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this.gotoPendingSubmissionToSuperList();
        break
      case this.myactivitiesLabels[1]:
        this.gotoSubmittedToSuperList();
        break
      case this.myactivitiesLabels[2]:
        this.gotoReturnedList();
        break
      case this.myactivitiesLabels[3]:
        this.gotoApprovedList();
        break
      case this.myactivitiesLabels[4]:
        this.gotoReturnedSuperList();
        break
      default:
    }
  }

  gotoPendingSubmissionToSuperList(){
    this._router.navigate([`portal/activities/drilling_requests/SUBMIT_TO_MANAGER`]);
  }

  gotoSubmittedToSuperList(){
    this._router.navigate([`portal/activities/drilling_requests/SUBMIT_TO_SUPERINTENDENT`]);
  }

  gotoReturnedList(){
    this._router.navigate([`portal/activities/drilling_requests/MANAGER_REJECT`]);
  }

  gotoApprovedList(){
    this._router.navigate([`portal/activities/drilling_requests/MANAGER_APPROVE`]);
  }

  gotoReturnedSuperList(){
    this._router.navigate([`portal/activities/drilling_requests/SUPERINTENDENT_REJECT`]);
  }

  gotoAllVetted(){
    this._router.navigate([`portal/activities/drilling_requests/MANAGER_ALL_VETTED`]);
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  getActivities(current) {

    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {
          this._activitiesServ.getManagerDrillRequestActivities().subscribe(res => {
            this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);

          }, err => {
            this._toaster.warning(err.message, 'Failed to get Pending activities');
          });

          this._activitiesServ.getManagerRejectedActivities().subscribe(res => {
            this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get Rejected activities');
          });

          this._activitiesServ.getManagerApprovedActivities().subscribe(res => {
            this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get activities');
          });

          this._activitiesServ.getManagerSubmittedToSupActivities().subscribe(res => {
            this.submittedToSupTableDataSource.data = this.submittedToSupTableDataSource.data ? this.submittedToSupTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get Rejected activities');
          });
      }
    );
  }




}
