import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {LocalStorageManagerService} from '../../shared-kernel/storage/local-storagemanager.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnInit {

  token;

  constructor(private http: HttpClient, private router: Router, private storageManager: LocalStorageManagerService) {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  ngOnInit(): void {
  }

  getIsFirstUserRegistered() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.isFirstUserAvailableUrl);
  }

  AttemptLogin(username: string, password: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.loginUrl, {
      'username': username,
      'password': btoa(password)
    }, {
      headers: headers,
      responseType: 'text'
    }).pipe(map(userToken => {
      if (this.storageManager.tokenManagerRemove() === false) {
        this.storageManager.tokenManagerAdd(userToken);
      }
    }));
  }

  attemptLogOut() {
    return this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.logOutUrl, {responseType: 'text'});
  }

  resetPassword(username) {
    const url = `${ServiceEndPoints.authBaseUrl + ServiceEndPoints.resetUrl}/${username}`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.post(url, {
      headers: headers,
      responseType: 'text'
    }).subscribe(
      (res: any) => {
        Swal.fire({
          title: 'Password Reset',
          text: 'Check your email for confirmation!',
          type: 'success',
          confirmButtonText: 'Ok'
        });
      },
      (err: any) => {
        Swal.fire({
          title: 'Oops!',
          text: 'SOMETHING WRONG',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    );

  }
}
