import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DrillerLogRecord} from "../../../../../../shared-kernel/entity/applied-geoscience/drillerlog/drillerlogrecord";
import {PLDrillerLog} from "../../../services/PLDrillerLog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {PlDrillerLogService} from "../../../services/pl-driller-log.service";

@Component({
  selector: 'app-pl-drillers-log-import',
  templateUrl: './pl-drillers-log-import.component.html',
  styleUrls: ['./pl-drillers-log-import.component.scss']
})
export class PlDrillersLogImportComponent implements OnInit {

  csvDdataSource: MatTableDataSource <PLDrillerLog> = new MatTableDataSource();
  displayedColumnsForImport: string[] = ['date', 'depthFrom', 'depthTo', 'description'];
  selectedCvsRecord: PLDrillerLog;

  constructor(public dialogRef: MatDialogRef<PlDrillersLogImportComponent>, @Inject(MAT_DIALOG_DATA) public data: MatTableDataSource <PLDrillerLog>,
              private plDrillersLogService: PlDrillerLogService, private toaster: ToastrService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.csvDdataSource = this.data;
  }
  deleteCsvRecord(drl: PLDrillerLog) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvDdataSource.data.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvDdataSource.data);
    this.toaster.success('Record deleted', 'Record Deletion');
  }
  closeModal() {
    this.dialogRef.close();
  }

  saveCsvRecords() {
    this.saveRecordsList(this.csvDdataSource.data);
  }
  saveRecordsList(records: PLDrillerLog[]) {

    this.plDrillersLogService.createPLDrillerLogs(records).subscribe(data => {
      }, error => {
        this.toaster.error('Records import failed ', 'Import Failed');
        this.spinner.hide();
      },
      () => {
        this.closeModal();
        this.toaster.success('Records import successful ', 'Import successful');
        this.spinner.hide();
      });
  }


}
