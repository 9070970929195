import { Notes } from '../applied-geoscience/notes';
import { SampleType } from '../common/sample-type';
import { Size } from '../common/size';
import { DrillingRequestStatus } from './drilling-request-status';
import { DrillingMethod } from './drilling-method';
import { Project } from '../applied-geoscience/project/project';
import { DrillingRequestResponseNote } from './DrillingRequestResponseNote';
import { CoordinatesFormat } from '../common/coordinatesValidator/coordinatesFormat';
import {LocationTemp} from '../common/locationTemp';
import { FileToUpload } from '../../../common/uploaded-files/entity/file';
import { BoreholeType } from '../common/boreholeType';
import { NigisImages } from '../common/nigisImages';

export class DrillingRequest {
   id: string;
   drillingDate: Date = new Date();
   sampleTypes: SampleType[];
   sampleSize: Size = new Size();
   finalDepth: Size = new Size();
   locationTemp: LocationTemp = new LocationTemp();
   status: DrillingRequestStatus ;
   drillingMethod: DrillingMethod = new DrillingMethod();
   notes: Notes;
   comments: Notes = new Notes();
   boreholeType: BoreholeType = new BoreholeType();
   drillingRequestFiles: NigisImages [] = [];
   project: Project = new Project();
   currentState: any;
   statusHistory: any[];
   active: string;
   deleted: boolean;
   drillingRequestResponseNotes: DrillingRequestResponseNote[];
   coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
   businessUnit: string;

   creator?:    any | null | any;
   created?:    string | Date | any;
   updater?:    any | null | string | Date;
   updated?:    string | Date | any;
   reference?: null;
}

export enum DrillingRequestApproveAction {
   SUBMIT_TO_MANAGER = 0,
   MANAGER_PEND = 1,
   MANAGER_REJECT = 2,
   SUBMIT_TO_SUPERINTENDENT = 3,
   SUPERINTENDENT_REJECT = 4,
   SUPERINTENDENT_SUBMIT_TO_MANAGER = 5,
   MANAGER_APPROVE = 6,
   MANAGER_ACTIVATE = 7,
   APPROVE_FOR_OUTSOURCE = 8,
   MANAGER_RETURN_TO_SUPERINTENDENT = 9
}

export enum DrillingRequestStage {
  PENDING_MANAGER_SUBMISSION = 0,
  SUBMITTED_TO_MANAGER = 1,
  PENDING_AT_MANAGER = 2,
  REJECTED_BY_MANAGER = 3,
  SUBMITTED_TO_SUPERINTENDENT = 4,
  REJECTED_BY_SUPERINTENDENT = 5,
  SUBMITTED_TO_MANAGER_BY_SUPERINTENDENT = 6,
  APPROVED_BY_MANAGER = 7,
  OUTSOURCED = 8
}

