import { RockName } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/RockName';
import { Project } from '../project/project';
import { SampleType } from '../../common/sample-type';
import { Notes } from '../notes';
import { NigisImages } from '../../common/nigisImages';
import { CoordinatesFormat } from '../../common/coordinatesValidator/coordinatesFormat';
import { LocationTemp } from '../../common/locationTemp';
import { Status } from '../project/status';
import { Size } from '../../common/size';

export class Sample {
  id: string;
  sampleObjective: Notes= new Notes();
  sampleDate: Date;
  project: Project = new Project();
  sampleNumber: string;
  locationTemp: LocationTemp = new LocationTemp();
  locationDescription: Notes = new Notes();
  villageText: string ;
  samplesLocationImages: NigisImages[];
  sampleTypes: SampleType[] = [];
  active: string;
  deleted: boolean;
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
  projectManager: string;
  projectCoordinator:  string;
  projectGeologist:  string;
  creator:  string;
  geochronomologyTestFileMapper: string;
  currentState?: any;
  statusHistory?: any[];
  status?: Status[];
  collectedBy: string;
  sampleDescription: string;
  structuresMeasurements: string;
  rockName: RockName;
  sampleOwnerUnit: string;
  comments: string;
  sampleType: string;
  horizonSampled: string;
  duplicate: string;
  colour: string;
  texture: string;
  relief: string;
  vegetation: string;
  moisture: string;
  reactionWithHcl: string;
  sampleRole: string;
  entityLink: string;
  depthFrom: Size;
  depthTo: Size;
  created: Date;

}
