import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SoilChemistry } from '../../../../../../shared-kernel/entity/applied-geoscience/soil-log/soil-chemistry';
import { SoilElements } from '../../../../../../shared-kernel/entity/applied-geoscience/soil-log/soil-elements';
import { GeoTechService } from '../../../../geo-tech.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-soil-chemistry-import',
  templateUrl: './soil-chemistry-import.component.html',
  styleUrls: ['./soil-chemistry-import.component.scss']
})
export class SoilChemistryImportComponent implements OnInit {

  editStatus : string;
  csvElements: MatTableDataSource<SoilElements> = new MatTableDataSource();
  displayedColumnsElements = ['No.', 'Name', 'Reading'];

  constructor(private geotechService: GeoTechService, private toastr: ToastrService, public dialogRef: MatDialogRef<SoilChemistryImportComponent>, @Inject(MAT_DIALOG_DATA) public data: SoilChemistry) {
  }

  ngOnInit() {
    this.csvElements.data = this.data.soilElements;
    this.editStatus = this.data['edit'];
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.geotechService.createSoilTestSoilChemistry(this.data).subscribe(
      value => {
      }
      , error => {
        this.toastr.error(error.error, 'Soil Chemistry Test');
      }
      , () => {
        this.closeModal();
        this.toastr.success('Successful', 'Soil Chemistry Test');
      }
    );
  }

}
