import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DrillerChecklistCreateComponent } from 'src/app/driller/driller-checklist/driller-checklist-create/driller-checklist-create.component';
import { DrillingChecklist } from 'src/app/shared-kernel/entity/driller/DrillingChecklist';

import { DrillerService } from './../../driller/driller.service';
import { DrillingRequest, DrillingRequestApproveAction } from './../../shared-kernel/entity/driller/drilling-request';
import { ApprovalModalComponent } from './approval-modal/approval-modal';

@Component({
    selector: 'drill-request-approval',
    templateUrl: './drill-request-approval.html',
    styleUrls: ['./drill-request-approval.scss']
})
export class DrillRequestApprovalComponent implements OnInit {
    @Input("drillRequestId") drillRequestId?: string;
    @Input("drillRequest") drillRequest?: DrillingRequest;

    drillingRequest: DrillingRequest;
    drillingChecklists: DrillingChecklist[];
    drillingRequestHasChecklist: boolean;

    @Output() checklistEvent = new EventEmitter<boolean>();

    constructor (
        private dialog: MatDialog,
        private drillerService: DrillerService
    ) {}

    ngOnInit() {
        this._initDrillRequest();
        this.getDrillingChecklistsByDrillingRequestId();

    }

    changeState(action: DrillingRequestApproveAction) {
        const changeStateDialogRef = this.dialog.open(ApprovalModalComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: { action, drillingRequestId: this.drillingRequest.id }
        });

        changeStateDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._initDrillRequest();
            }
        });
    }

    attachChecklist(action: number) {
        const changeStateDialogRef = this.dialog.open(DrillerChecklistCreateComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: { action, drillingRequestId: this.drillingRequest.id, drillingChecklist: null }
        });

        changeStateDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._initDrillRequest();
                this.getDrillingChecklistsByDrillingRequestId();
                this.checklistEvent.emit(true);
            }
        });
    }

    getDrillingChecklistsByDrillingRequestId() {
        this.drillerService.getChecklistsByDrillingRequestId(this.drillRequestId)
        .subscribe(data => {
          this.drillingChecklists = data as DrillingChecklist[];
          if (this.drillingChecklists.length > 0) {
            this.drillingRequestHasChecklist = true;
          } else {
            this.drillingRequestHasChecklist = false;
          }
        });
    }

    private _initDrillRequest() {
        if (this.drillRequest) {
            this.drillingRequest = this.drillRequest;
        } else if (this.drillRequestId) {
            this.drillerService.getDrillingRequestById(this.drillRequestId).subscribe(res => {
                this.drillingRequest = res as DrillingRequest;
            }, err => {
                // TODO: Show error
            });
        }
    }
}
