import { UserService } from './../../../auth/user/user.service';
import { State } from './../../../activities/models/state';
import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-qa-dashboard-table',
  templateUrl: './qa-dashboard-table.component.html',
  styleUrls: ['./qa-dashboard-table.component.scss']
})
export class QaDashboardTableComponent implements OnInit {

  loading = true;
  displayedColumns: Array<string> = ['number', 'activities', 'geologist', 'date'];
  public _dataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  get dataSource(): MatTableDataSource<Activity> {
    this.loading = false;
    return this._dataSource;

  }

  @Input('activitiesDataSource') set dataSource(val: MatTableDataSource<Activity>) {
    this._dataSource = val;
    if (this._dataSource) {
      this.loading = false;
      this._dataSource.paginator = this.paginator;
    }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  state = new State();

  title: string

  constructor(
    private _userService: UserService,
  ) { }

  ngOnInit(): void {

  }

  applyFilter(filterValue: string) {
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }
}
