import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {UserService} from '../../../../auth/user/user.service';
import {ActivatedRoute} from '@angular/router';
import {Size} from '../../../../shared-kernel/entity/common/size';
import {UnitEnum} from '../../../../shared-kernel/enumerations/unit-enum';
import {Unit} from '../../../../shared-kernel/entity/common/unit';
import {ScreeningMaterial} from '../../../../shared-kernel/entity/applied-geoscience/geoTech/ScreeningMaterial';
import {HydrogeologyService} from '../../hydrogeology.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {CasingMaterial} from '../../../../shared-kernel/entity/applied-geoscience/geoTech/CasingMaterial';
import {CasingLog} from '../../../../shared-kernel/entity/applied-geoscience/hydrogeology/CasingLog';


@Component({
  selector: 'app-borehole-construction-casing',
  templateUrl: './borehole-construction-casing.component.html',
  styleUrls: ['./borehole-construction-casing.component.scss']
})


export class BoreholeConstructionCasingComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('projectId') projectId: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  unitEnum: typeof UnitEnum = UnitEnum;
  dataSourceCasing: MatTableDataSource<CasingLog> = new MatTableDataSource();
  displayedColumns: string[] = ['check', '#', 'parentId', 'casingId', 'Depth from', 'Depth to', 'material', 'slotNumber', 'constructionType', 'diameter', 'totalLength', 'edit', 'delete'];
  modalColumns: string[] = ['#', 'parentId', 'casingId', 'Depth from', 'Depth to', 'material', 'slotNumber', 'constructionType', 'diameter', 'totalLength', 'delete'];
  casingLog: CasingLog;
  casingLogs: CasingLog [];
  casingMaterials: ScreeningMaterial [];
  casingMaterial: ScreeningMaterial;
  selectedBorehole: any;
  modalHeading: string;
  casingId: string;
  boreholeId: string;
  idObj: any;

  @ViewChild('fileImportInput') fileImportInput: any;
  selectedCvsRecord: CasingLog = new CasingLog();

  csvRecords: CasingLog[] = [];

  csvDdataSource: MatTableDataSource<CasingLog> = new MatTableDataSource();

  selection = new SelectionModel<CasingLog>(true, []);

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService) {
    this.route.params.subscribe(params => {
      this.selectedBorehole = params.id;
      this.boreholeId = params.id;
      this.idObj = {id: this.boreholeId};
    });
  }

  casingAndParentIDs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  validation_messages = {
    'material': [
      {type: 'required', message: 'Please insert material'},
    ],
    'diameter': [
      {type: 'required', message: 'Please insert diameter'},
    ],
    'depthFrom': [
      {type: 'required', message: 'Please insert From value'},
    ],
    'depthTo': [
      {type: 'required', message: 'Please insert To value'},
    ],
    'totalLength': [
      {type: 'required', message: 'Please insert Total Length value'},
    ],
    'casingType': [
      {type: 'required', message: 'Please insert Description'},
    ],
    'slotNumber': [
      {type: 'required', message: 'Please insert Description'},
    ]
    ,
    'constructionType': [
      {type: 'required', message: 'Please insert Construction Type'},
    ]
  };

  deleteCsvRecord(drl: CasingLog) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toaster.success('Record deleted', 'Record Deletion');
  }


  compareFn(object1: any, object2: any): boolean {
    return object1 && object2 ? object1.id === object2.id : (object1 === object2);
  }

  resetPaginator() {
    this.dataSourceCasing.paginator = this.paginator;
    this.dataSourceCasing.sort = this.sort;
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  deleteCasing(casingLog: CasingLog) {
    this.casingLog = casingLog;
    this.modalHeading = 'Delete Screening Log';
    this.casingLog.deleted = true;
    $('#deleteModal').modal({backdrop: 'static'});
    $('#deleteModal').modal('show');
  }


  ngOnInit() {
    this.casingMaterial = new ScreeningMaterial();
    this.casingLog = new CasingLog();
    this.casingMaterial.description = '';
    this.casingLog.diameter = new Size();
    this.casingLog.diameter.unit = new Unit();
    this.casingLog.depthFrom = new Size();
    this.casingLog.depthFrom.unit = new Unit();
    this.casingLog.depthTo = new Size();
    this.casingLog.depthTo.unit = new Unit();
    this.casingLog.constructionType = '';
    this.casingLog.deleted = false;
    this.getAllConstructionTypes();
    this.getAllCasingByBoreholeId();
  }


  getAllConstructionTypes() {
    this.hydrogeologyService.getAllConstructionTypes().subscribe(
      data => {
        this.casingMaterials = data as ScreeningMaterial [];

      }
    );
  }

  getAllCasingByBoreholeId() {
    this.hydrogeologyService.getAllCasing(this.boreholeId).subscribe(
      data => {
        this.casingLogs = data as CasingLog [];
        this.dataSourceCasing.data = this.casingLogs;
         new MatTableDataSource(this.dataSourceCasing.data);
        this.resetPaginator();
      }
    );
  }


  importCSVFile() {
    $('#uploadCsv').click();
  }

  showCsvModal(): void {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }


  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  saveBatch(records: CasingLog []) {
    this.spinner.show();
    let loopCount = 0;
    records.forEach(element => {
      this.hydrogeologyService.createBoreholeConstructionCasingLog(element, element.id).subscribe(data => {

        },
        error => {
          this.toaster.error('Records import failed', 'Import Failed');
          this.spinner.hide();
        }, () => {
          this.spinner.hide();
          loopCount++;
          if (records.length === loopCount) {
            this.successmsg();
            this.getAllCasingByBoreholeId();
          }
        });

    });


  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];


    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        const casingLogCsv = new CasingLog();
        const casingMaterialCsv = new CasingMaterial();
        casingMaterialCsv.description = '';
        casingLogCsv.diameter = new Size();
        casingLogCsv.diameter.unit = new Unit();
        casingLogCsv.depthFrom = new Size();
        casingLogCsv.depthFrom.unit = new Unit();
        casingLogCsv.depthTo = new Size();
        casingLogCsv.depthTo.unit = new Unit();

        casingLogCsv.diameter.unit.id = this.unitEnum.inch;
        casingLogCsv.depthFrom.unit.id = this.unitEnum.meter;
        casingLogCsv.depthTo.unit.id = this.unitEnum.meter;
        casingLogCsv.material = new CasingMaterial();


        casingLogCsv.parentId = data[0];
        casingLogCsv.casingId = data[1];
        casingLogCsv.depthFrom.measure = '' + parseFloat(data[2].trim());
        casingLogCsv.depthTo.measure = '' + parseFloat(data[3].trim());
        this.casingMaterials.forEach(value => {
          if (value.description.trim().toUpperCase() === data[4].trim().toUpperCase()) {
            casingLogCsv.material = value;
          }
        });
        casingLogCsv.slotNumber = data[5];
        casingLogCsv.diameter.measure = data[6];
        casingLogCsv.constructionType = data[7];
        casingLogCsv.deleted = false;


        casingLogCsv.id = this.boreholeId;
        dataArr.push(casingLogCsv);
      }
    }
    this.csvDdataSource.data = dataArr;

    return dataArr;
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }


  getCasingByIdFromList(id) {
    for (var casingRecord of this.dataSourceCasing.data) {
      if (casingRecord.id === id) {
        return casingRecord;
      }
    }
  }

  addNewRecordRequest() {
    this.casingLog = new CasingLog();
    this.casingMaterial.description = '';
    this.casingLog.diameter = new Size();
    this.casingLog.diameter.unit = new Unit();
    this.casingLog.depthFrom = new Size();
    this.casingLog.depthFrom.unit = new Unit();
    this.casingLog.depthTo = new Size();
    this.casingLog.depthTo.unit = new Unit();
    this.casingLog.constructionType = '';
    this.casingLog.deleted = false;
    $('#resetButton').click();
    this.modalHeading = 'Add Casing Material';
    $('#addModal').modal({backdrop: 'static'});
    $('#addModal').modal('show');
  }

  editRequest(id) {
    this.casingLog = this.getCasingByIdFromList(id);
    this.modalHeading = 'Edit Casing Material';
    this.casingId = id;

    $('#addModal').modal({backdrop: 'static'});
    $('#addModal').modal('show');
  }

  closeAddModal() {
    $('#addModal').modal('hide');
  }

  errorsmsg() {
    this.toast.error('Borehole construction save Unsuccessful', 'Casing Create');
  }

  successmsg() {
    this.toast.success('Borehole construction saved successfully', 'Casing Create');
  }

  onSubmit(modalId) {

    if (Number(this.casingLog.depthFrom.measure) <= Number(this.casingLog.depthTo.measure)) {

      this.casingLog.diameter.unit.id = this.unitEnum.inch;
      this.casingLog.depthFrom.unit.id = this.unitEnum.meter;
      this.casingLog.depthTo.unit.id = this.unitEnum.meter;
      this.hydrogeologyService.createBoreholeConstructionCasingLog(this.casingLog, this.boreholeId).subscribe(
        response => {

        },
        error => {
          this.errorsmsg();
        },()=> {
          this.getAllCasingByBoreholeId();
            $('#' + modalId).modal('hide');

            if (modalId === 'deleteModal') {
              this.toast.success('Operation successful', 'Delete Record');
            } else {
              this.toast.success('Record created successfully', 'Add Record');
            }

        }
      );
    } else {
      this.casingLog = new CasingLog();
      this.toast.error('Depth To value should be greater than the Depth From value');
    }
  }


  saveCsvRecords() {

    this.saveBatch(this.csvRecords);
    this.fileReset();

    $('#csvModal').modal('hide');
  }

  public lessThan(depthFrom: number, depthTo: number) {
    return depthTo < depthFrom;
  }

  applyFilter(filterValue: string) {
    this.dataSourceCasing.filter = filterValue.trim().toLowerCase();
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceCasing.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceCasing.data ? this.dataSourceCasing.data.length : 0;
    return numSelected === numRows;
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteCasingLogBatch(this.selection.selected).subscribe(() => {
              this.getAllCasingByBoreholeId();
              this.toaster.success('Delete casing', 'You have Successfully deleted Casing');
            }, err => {
                this.toaster.error(err.message, "Failed Delete");
            });
        }
    });
  }

}
