import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DocumentsService} from '../../shared/documents/dococument.service';
import {NigisDocument} from '../../shared/documents/nigis-doc.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from "@angular/material/paginator";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-relinquished-documents',
  templateUrl: './relinquished-documents.component.html',
  styleUrls: ['./relinquished-documents.component.scss']
})
export class RelinquishedDocumentsComponent implements OnInit {
  documents: Document[];
  fetching = true;
  tableDataSource: MatTableDataSource<NigisDocument>;
  displayedCompaniesColumns = ['#', 'Description', 'Filename', 'LinkedEntity', 'type'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() refreshEvent = new EventEmitter<boolean>();
  constructor(private http: HttpClient, private docServ: DocumentsService,  private _toastr: ToastrService) { }

  ngOnInit() {
    this.getRelinquishedDocuments();

  }
  private getRelinquishedDocuments() {
    this.docServ.getLandfolioDocuments().subscribe(res => {
      this.fetching = false;
      this.tableDataSource = new MatTableDataSource<NigisDocument>(res);
      this.tableDataSource.paginator = this.paginator;
      this.refreshEvent.emit(true);
    }, _err => {
      this._toastr.error('Failed to retrieve documents', ' Relinquished Documents List' );
    });
  }

}
