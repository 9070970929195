import { Component, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'image-dialog',
  templateUrl: 'image.component.html',
})
export class ImageComponent {
  imageString: string
  title:string
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ImageComponent>) {
      if(data){
        this.imageString = data.image;
        this.title = data.name;
        // if (data.buttonText) {
        // this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        // }
      }
  }

  onConfirmClick(): void {
    this.dialogRef.close();
  }

}