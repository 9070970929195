import { CollectionsState } from './../../activities/models/CollectionsState';
import { UserService } from './../../auth/user/user.service';
import { UserDashboardService } from './../user-dashboard/user-dashboard.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Person } from '../../common/entity/security/profile/person';

@Component({
  selector: 'app-collections-dashboard',
  templateUrl: './collections-dashboard.component.html',
  styleUrls: ['./collections-dashboard.component.scss']
})
export class CollectionsDashboardComponent implements OnInit {

  numberOfBGIProjects: number = 0;
  stillFetchingBGIProjectCount = true;
  numberOfMyProjects: number = 0;
  stillFetchingMyProjectCount = true;
  numberOfActivities: number = 0;
  stillFetchingMyActivitiesCount = true;
  numberOfCoreshedActivities: number = 0;
  stillFetchingCoreshedActiviesCount = true;
  numberOfCoreshedActivitiesSubmittedToSeniorArchivist: number = 0;
  stillFetchingCoreshedActivitiesSubmittedToSeniorArchivistCount = true;
  numberOfCoreshedActivitiesRejectedBySeniorArchivist: number = 0;
  stillFetchingCoreshedActivitiesRejectedBySeniorArchivistCount = true;
  numberOfCoreshedActivitiesApproved: number = 0;
  stillFetchingCoreshedActiviesApprovedCount = true;
  numberOfCoreshedActivitiesDrafts: number = 0;
  stillFetchingCoreshedActiviesDraftsCount = true;

  numberOfCoreshedActivitiesSeniorArchivistPending: number = 0;
  stillFetchingnumberOfCoreshedActivitiesSeniorArchivistPendingCount = true;

  numberOfCoreshedActivitiesSeniorArchivistApproved: number = 0;
  stillFetchingnumberOfCoreshedActivitiesSeniorArchivistApprovedCount = true;

  numberOfCoreshedActivitiesSeniorArchivistReturned: number = 0;
  stillFetchingnumberOfCoreshedActivitiesSeniorArchivistReturnedCount = true;

  numberOfCoreshedActivitiesSeniorArchivistAllVetted: number = 0;
  stillFetchingnumberOfCoreshedActivitiesSeniorArchivistAllVettedCount = true;

  numberOfRequestActivities: number = 0;
  stillFetchingrequestActiviesCount = true;

  numberOfRequestActivitiesSubmittedToManager: number = 0;
  stillFetchingRequestActivitiesSubmittedToManagerCount = true;

  numberOfRequestActivitiesRejectedByManager: number = 0;
  stillFetchingRequestActivitiesRejectedByManagerCount = true;

  numberOfRequestActivitiesApproved: number = 0;
  stillFetchingRequestActiviesApprovedCount = true;

  numberOfRequestActivitiesDrafts: number = 0;
  stillFetchingRequestActiviesDraftsCount = true;

  numberOfRequestActivitiesManagerPending: number = 0;
  stillFetchingnumberOfRequestActivitiesManagerPendingCount = true;

  numberOfRequestActivitiesManagerApproved: number = 0;
  stillFetchingnumberOfRequestActivitiesManagerApprovedCount = true;

  numberOfRequestActivitiesManagerReturned: number = 0;
  stillFetchingnumberOfRequestActivitiesManagerReturnedCount = true;

  numberOfRequestActivitiesManagerAllVetted: number = 0;
  stillFetchingnumberOfRequestActivitiesManagerAllVettedCount = true;

  numberOfSpecimenRequestActivities = 0;
  stillFetchingSpecimenRequestActiviesCount = true;
  numberOfSpecimenRequestActivitiesSubmittedToManager = 0;
  stillFetchingSpecimenRequestActivitiesSubmittedToManagerCount = true;
  numberOfSpecimenRequestActivitiesRejectedByManager = 0;
  stillFetchingSpecimenRequestActivitiesRejectedByManagerCount = true;
  numberOfSpecimenRequestActivitiesApproved= 0;
  stillFetchingSpecimenRequestActiviesApprovedCount = true;
  numberOfSpecimenRequestActivitiesDrafts= 0;
  stillFetchingSpecimenRequestActiviesDraftsCount = true;

  numberOfSpecimenRequestActivitiesManagerPending = 0;
  stillFetchingnumberOfSpecimenRequestActivitiesManagerPendingCount = true;
  numberOfSpecimenRequestActivitiesManagerApproved = 0;
  stillFetchingnumberOfSpecimenRequestActivitiesManagerApprovedCount = true;
  numberOfSpecimenRequestActivitiesManagerReturned = 0;
  stillFetchingnumberOfSpecimenRequestActivitiesManagerReturnedCount = true;
  numberOfSpecimenRequestActivitiesManagerAllVetted = 0;
  stillFetchingnumberOfSpecimenRequestActivitiesManagerAllVettedCount = true;

  userName: string;
  userReference: string;
  person: Person;
  state = new CollectionsState();

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private userService: UserService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
  }

  ngOnInit() {
    this.getMyProjectsCount();
    this.getBGIProjectsCount();
    this.getUserActivities();
    this.getUserTrayActivitiesCount();
    this.getUserTrayActivitiesSubmittedToSeniorArchivistCount();
    this.getUserTrayActivitiesRejectedBySeniorArchivistCount();
    this.getUserTrayActivitiesApprovedCount();
    this.getUserTrayActivitiesDraftsCount();
    this.getUserTrayActivitiesSeniorArchivistPendingCount();
    this.getUserTrayActivitiesSeniorArchivistApprovedCount();
    this.getUserTrayActivitiesSeniorArchivistReturnedCount();
    this.getUserTrayActivitiesSeniorArchivistAllVettedCount();

    this.getUserRequestActivitiesCount();
    this.getUserRequestActivitiesSubmittedToManagerCount();
    this.getUserRequestActivitiesRejectedByManagerCount();
    this.getUserRequestActivitiesApprovedCount();
    this.getUserRequestActivitiesDraftsCount();
    this.getUserRequestActivitiesManagerPendingCount();
    this.getUserRequestActivitiesManagerApprovedCount();
    this.getUserRequestActivitiesManagerReturnedCount();
    this.getUserRequestActivitiesManagerAllVettedCount();

    this.getUserSpecimenRequestActivitiesCount();
    this.getUserSpecimenRequestActivitiesSubmittedToManagerCount();
    this.getUserSpecimenRequestActivitiesRejectedByManagerCount();
    this.getUserSpecimenRequestActivitiesApprovedCount();
    this.getUserSpecimenRequestActivitiesDraftsCount();
    this.getUserSpecimenRequestActivitiesManagerPendingCount();
    this.getUserSpecimenRequestActivitiesManagerApprovedCount();
    this.getUserSpecimenRequestActivitiesManagerReturnedCount();
    this.getUserSpecimenRequestActivitiesManagerAllVettedCount();


  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.numberOfMyProjects = number;
      this.stillFetchingMyProjectCount = false;
  });
  }

  getBGIProjectsCount() {
  this.userDashBoardService.getBGIProjectCount().subscribe(number => {
    this.numberOfBGIProjects = number;
    this.stillFetchingBGIProjectCount = false;
    });
  }

  getUserActivities(){
    this.userDashBoardService.getMyActivitiesCount('all').subscribe(number => {
      this.numberOfActivities = number;
      this.stillFetchingMyActivitiesCount = false;
    });
  }

  getUserTrayActivitiesCount(){
    this.userDashBoardService.getUserTrayActivitiesCount('all', 'tray').subscribe(number => {
      this.numberOfCoreshedActivities = number;
      this.stillFetchingCoreshedActiviesCount = false;
    });
  }

  getUserTrayActivitiesSubmittedToSeniorArchivistCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.SUBMITTED_TO_SENIOR_ARCHIVIST.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesSubmittedToSeniorArchivist = number;
      this.stillFetchingCoreshedActivitiesSubmittedToSeniorArchivistCount = false;
    });
  }

  getUserTrayActivitiesRejectedBySeniorArchivistCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.REJECTED_BY_SENIOR_ARCHIVIST.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesRejectedBySeniorArchivist = number;
      this.stillFetchingCoreshedActivitiesRejectedBySeniorArchivistCount = false;
    });
  }

  getUserTrayActivitiesApprovedCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.APPROVED.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesApproved= number;
      this.stillFetchingCoreshedActiviesApprovedCount = false;
    });
  }

  getUserTrayActivitiesDraftsCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.PENDING_SENIOR_ARCHIVIST_SUBMISSION.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesDrafts= number;
      this.stillFetchingCoreshedActiviesDraftsCount = false;
    });
  }

  getUserRequestActivitiesCount(){
    this.userDashBoardService.getUserRequestActivitiesCount('all', 'request').subscribe(number => {
      this.numberOfRequestActivities = number;
      this.stillFetchingrequestActiviesCount = false;
    });
  }

  getUserSpecimenRequestActivitiesCount(){
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount('all', 'request-specimen').subscribe(number => {
      this.numberOfSpecimenRequestActivities = number;
      this.stillFetchingSpecimenRequestActiviesCount = false;
    });
  }

  getUserRequestActivitiesSubmittedToManagerCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.SUBMITTED_TO_MANAGER.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesSubmittedToManager = number;
      this.stillFetchingRequestActivitiesSubmittedToManagerCount = false;
    });
  }

  getUserSpecimenRequestActivitiesSubmittedToManagerCount(){
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.SUBMITTED_SPECIMEN_TO_MANAGER.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesSubmittedToManager = number;
      this.stillFetchingSpecimenRequestActivitiesSubmittedToManagerCount = false;
    });

  }

  getUserRequestActivitiesRejectedByManagerCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.REJECTED_BY_MANAGER.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesRejectedByManager = number;
      this.stillFetchingRequestActivitiesRejectedByManagerCount = false;
    });
  }

  getUserSpecimenRequestActivitiesRejectedByManagerCount(){
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.REJECTED_SPECIMEN_BY_MANAGER.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesRejectedByManager = number;
      this.stillFetchingSpecimenRequestActivitiesRejectedByManagerCount = false;
    });
  }

  getUserRequestActivitiesApprovedCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.APPROVED_MANAGER.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesApproved= number;
      this.stillFetchingRequestActiviesApprovedCount = false;
    });
  }

  getUserSpecimenRequestActivitiesApprovedCount(){
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.APPROVED_SPECIMEN_MANAGER.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesApproved= number;
      this.stillFetchingSpecimenRequestActiviesApprovedCount = false;
    });
  }

  getUserRequestActivitiesDraftsCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.PENDING_SPECIMEN_MANAGER_SUBMISSION.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesDrafts= number;
      this.stillFetchingRequestActiviesDraftsCount = false;
    });
  }

  getUserSpecimenRequestActivitiesDraftsCount(){
    this.userDashBoardService.getUserSpecimenRequestActivitiesCount(this.state.PENDING_SPECIMEN_MANAGER_SUBMISSION.status, 'request').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesDrafts= number;
      this.stillFetchingSpecimenRequestActiviesDraftsCount = false;
    });
  }

  getUserTrayActivitiesSeniorArchivistPendingCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('Pending').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistPending = number;
      this.stillFetchingnumberOfCoreshedActivitiesSeniorArchivistPendingCount = false;
    });
  }

  getUserTrayActivitiesSeniorArchivistApprovedCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('Approved').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistApproved = number;
      this.stillFetchingnumberOfCoreshedActivitiesSeniorArchivistApprovedCount = false;
    });
  }

  getUserTrayActivitiesSeniorArchivistReturnedCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('Returned').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistReturned = number;
      this.stillFetchingnumberOfCoreshedActivitiesSeniorArchivistReturnedCount = false;
    });
  }

  getUserTrayActivitiesSeniorArchivistAllVettedCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('All Vetted').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistAllVetted = number;
      this.stillFetchingnumberOfCoreshedActivitiesSeniorArchivistAllVettedCount = false;
    });
  }

  getUserRequestActivitiesManagerPendingCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('Pending').subscribe(number => {
      this.numberOfRequestActivitiesManagerPending = number;
      this.stillFetchingnumberOfRequestActivitiesManagerPendingCount = false;
    });
  }

  getUserSpecimenRequestActivitiesManagerPendingCount(){
    this.userDashBoardService.getManagerSpecimenRequestActivitiesCount('Pending').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesManagerPending = number;
      this.stillFetchingnumberOfSpecimenRequestActivitiesManagerPendingCount = false;
    });
  }

  getUserRequestActivitiesManagerApprovedCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('Approved').subscribe(number => {
      this.numberOfRequestActivitiesManagerApproved = number;
      this.stillFetchingnumberOfRequestActivitiesManagerApprovedCount = false;
    });
  }

  getUserSpecimenRequestActivitiesManagerApprovedCount(){
    this.userDashBoardService.getManagerSpecimenRequestActivitiesCount('Approved').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesManagerApproved = number;
      this.stillFetchingnumberOfSpecimenRequestActivitiesManagerApprovedCount = false;
    });
  }

  getUserRequestActivitiesManagerReturnedCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('Returned').subscribe(number => {
      this.numberOfRequestActivitiesManagerReturned = number;
      this.stillFetchingnumberOfRequestActivitiesManagerReturnedCount = false;
    });
  }

  getUserSpecimenRequestActivitiesManagerReturnedCount(){
    this.userDashBoardService.getManagerSpecimenRequestActivitiesCount('Returned').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesManagerReturned = number;
      this.stillFetchingnumberOfSpecimenRequestActivitiesManagerReturnedCount = false;
    });
  }

  getUserRequestActivitiesManagerAllVettedCount(){
    this.userDashBoardService.getManagerRequestActivitiesCount('All Vetted').subscribe(number => {
      this.numberOfRequestActivitiesManagerAllVetted = number;
      this.stillFetchingnumberOfRequestActivitiesManagerAllVettedCount = false;
    });
  }

  getUserSpecimenRequestActivitiesManagerAllVettedCount(){
    this.userDashBoardService.getManagerSpecimenRequestActivitiesCount('All Vetted').subscribe(number => {
      this.numberOfSpecimenRequestActivitiesManagerAllVetted = number;
      this.stillFetchingnumberOfSpecimenRequestActivitiesManagerAllVettedCount = false;
    });
  }

  gotoProject(uri: string) {
    let urlParams: any;
    if('my_project' === uri){
      urlParams = { id: 'my_project' };
    }
    if('bgi_project' === uri){
      urlParams = { id: 'bgi_project' };
    }
    this._router.navigate([`portal/applied-geoscience/project/list`,urlParams]);
  }

  goTo(type: string, filter: string) {
    this._router.navigate([`portal/activities/archivist/${type}/${filter}`]);
  }

  goToCoreshed(type: string, filter: string){
    this._router.navigate([`portal/activities/archivist/${type}/${filter}`]);
  }

  goToRequests(type: string, filter: string){
    this._router.navigate([`portal/activities/archivist/${type}/${filter}`]);
  }

}
