import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CountryData } from '../data/country';
import { Country, District } from '../data/_models';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';

@Injectable()
export class CountryService extends CountryData {
    constructor(
        private http: HttpClient
    ) {
        super();
    }

    getCountries(): Observable<Array<Country>> {
        return this.http.get<Array<Country>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllCountriesUrl);
    }

    getDistricts(): Observable<Array<District>> {
        return this.http.get<Array<District>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllDistrictsUrl);
    }
}