import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';

import { UnitsData } from '../data/units';
import { Unit } from '../data/_models/math.models';

@Injectable()
export class UnitsService extends UnitsData {
    public getUnits(): Observable<Array<Unit>> {
        return of([<Unit>{
             id: "1",
             unit: "mm",
             description: ""
        }, <Unit>{
            id: "2",
            unit: "cm",
            description: ""
        }, <Unit>{
            id: "3",
            unit: "m",
            description: ""
        }, <Unit>{
            id: "4",
            unit: "g",
            description: ""
        }, <Unit>{
             id: "5",
             unit: "kg",
             description: ""
        }, <Unit>{
            id: "6",
            unit: "ml",
            description: ""
        }, <Unit>{
            id: "7",
            unit: "l",
            description: ""
        }, <Unit>{
            id: "8",
            unit: "mg",
            description: ""
        }, <Unit>{
            id: "9",
            unit: "mm/blow",
            description: ""
        }, <Unit>{
            id: "10",
            unit: "set",
            description: ""
        }, <Unit>{
            id: "11",
            unit: "m2/hr",
            description: ""
        }, <Unit>{
            id: "12",
            unit: "°C",
            description: ""
        }, <Unit>{
            id: "13",
            unit: "S/m",
            description: ""
        }, <Unit>{
            id: "14",
            unit: "(cm)/(S/m)",
            description: ""
        }, <Unit>{
            id: "15",
            unit: "inch",
            description: ""
        }, <Unit>{
            id: "16",
            unit: "m3/hr",
            description: ""
        }, <Unit>{
            id: "17",
            unit: "mbgl",
            description: ""
        }, <Unit>{
           id: "18",
           unit: "ph",
           description: ""
        }, <Unit>{
            id: "19",
            unit: "V-notch mm",
            description: ""
        }, <Unit>{
            id: "20",
            unit: "μS/cm",
            description: ""
        }, <Unit>{
            id: "21",
            unit: "mg/L",
            description: ""
        }]);
    }
}