import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {CompanyService} from '../../../../applied-geoscience/company/company.service';
import {Person} from '../../../../_core/data/_models/people.model';
import {Company} from '../../../../shared-kernel/entity/applied-geoscience/company/company';
import {UserService} from '../../../../auth/user/user.service';
import {PeopleService} from '../../../../_core/mock/people.service';
import { AppUsersService } from '../../../../_settings/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PersonnelSortPipe } from '../../../../shared-kernel/pipes/personnel.sort.pipe';

@Component({
  selector: 'app-add-attendants',
  templateUrl: './add-attendants.component.html',
  styleUrls: ['./add-attendants.component.scss']
})
export class AddAttendantsComponent implements OnInit {

  person: Person;
  attendants: Person[];
  allCompanies: Company[];
  selectedPerson: any;

  loadingCompanies = false;
  loadingCustomers = false;
  filteredOptions: Observable<Person[]>;

  personnelFilter: FormControl = new FormControl();
  personelForm: FormGroup;

  constructor(private dialog: MatDialog, private companyService: CompanyService,private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any, private peopleService: PeopleService,
              private userService: UserService,private appUsersService: AppUsersService,private personnelSortPipe: PersonnelSortPipe,
              private dialogRef: MatDialogRef<AddAttendantsComponent>, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.personelForm = this.formBuilder.group({
      customer: []
    });
    if (this.data.personType === 'company') {
      this.getAllCompanies();
    } else {
      this.getAllIndividual();
    }
  }

  displayFn(person: Person): string {
    return person && person.name ? person.name+' '+person.surname: '';
  }



  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  comparePersons(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1[0] === obj2[0] : obj1 === obj2;
  }

  getAllCompanies() {
    this.loadingCompanies = true;
    this.companyService.getAllCompanies()
      .subscribe(data => {
        this.allCompanies = data as Company[];
        this.loadingCompanies = false;
      });
  }

  private _filter(value: string): Person[] {
    const filterValue = value.toLowerCase();
    let tempAttendent = [];
    if(this.data.personType == 'company'){
      tempAttendent = this.attendants.filter(option => option.surname.concat(' ',option.name).toLowerCase().includes(filterValue));
    }
    else{
      tempAttendent = this.attendants.filter(option => option[1].toLowerCase().includes(filterValue));
    }
    return tempAttendent;
  }

  onChange(person){
    this.selectedPerson = person;
  }


  getAllIndividual() {
    this.loadingCustomers = true;
    this.peopleService.getAllPersonsLite()
      .subscribe(data => {
        this.attendants = data as Person[];

        this.loadingCustomers = false;
        this.filteredOptions = this.personnelFilter.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name || value.surname),
            map(name => name ? this._filter(name) : this.attendants.slice())
        );
      });
  }

  getAllPersonsByCompanyId(id: string) {
    this.loadingCustomers = true;
    this.userService.getPersonsByCompanyId(id).subscribe(
      data => {
        this.attendants = data as Person[];
        this.loadingCustomers = false;
        this.filteredOptions = this.personnelFilter.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name || value.surname),
          map(name => name ? this._filter(name) : this.attendants.slice())
        );
      }
    );
  }



  onAdd(): void {
    if (this.selectedPerson) {
      if(this.selectedPerson.id){
        if (this.data.attendants.findIndex((attendant) => attendant.id === this.selectedPerson.id) < 0) {
          this.appUsersService.getPersonById(this.selectedPerson.id).subscribe(value => {
            this.data.attendants.push(value);
          });
        }
        else{
          this.toastr.warning('Customer Name already selected', 'Add Customer');
        }
      }
      else{
        if (this.data.attendants.findIndex((attendant) => attendant.id === this.selectedPerson[0]) < 0) {
            this.appUsersService.getPersonById(this.selectedPerson[0]).subscribe(value => {
              this.data.attendants.push(value);
            });
        }
        else{
          this.toastr.warning('Customer Name already selected', 'Add Customer');
        }
      }
      this.dialogRef.close(this.data.attendants);
    }
  }

  onClose(): void {
    this.dialogRef.close(this.data.attendants);
  }

}
