import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Person } from '../../../../common/entity/security/profile/person';
import { ProjectRole } from '../../../../shared-kernel/entity/applied-geoscience/project/project-role';
import { ProjectService } from '../../project.service';
import { MatTableDataSource } from '@angular/material/table';
import { Members } from '../../../../shared-kernel/entity/applied-geoscience/project/members';
import { UserService } from '../../../../auth/user/user.service';
import { Company } from '../../../../shared-kernel/entity/applied-geoscience/company/company';
import { CompanyService } from '../../../company/company.service';
import { Project } from '../../../../shared-kernel/entity/applied-geoscience/project/project';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ProjectDetailMemberDeleteComponent } from './project-detail-member-delete/project-detail-member-delete.component';
import { LocalAuthorities } from '../../../../_auth/_providers';

@Component({
  selector: 'app-project-detail-members',
  templateUrl: './project-detail-members.component.html',
  styleUrls: ['./project-detail-members.component.scss']
})
export class ProjectDetailMembersComponent implements OnInit {

  memberCreateForm: FormGroup;
  members: Person [];
  projectRoles: ProjectRole [];
  projectMembers: Members [] = [];
  projectMember: Members = new Members();
  companies: Company [];
  displayedProjectMembersColumns = ['#', 'MemberName', 'ProjectRole', 'Delete'];
  dataSourceProjectMembers: MatTableDataSource<any> = new MatTableDataSource();
  loading = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<ProjectDetailMembersComponent>,private formBuilder: FormBuilder, private projectService: ProjectService,
              private spinner: NgxSpinnerService,private toast: ToastrService,private route: Router,private dialog: MatDialog
              ,private userService: UserService,private companyService: CompanyService,@Inject(MAT_DIALOG_DATA) public data: Project,private _localAuthorities: LocalAuthorities) {

  }

  ngOnInit() {

    this.getProjectRoles();
    this.getAllCompanies();
    this.initForms();
  }

  private initForms() {

    this.memberCreateForm = this.formBuilder.group({
      projectCompany:[this.data.company],
      projectMembers:[],
      projectRoles:[]

    });
    this.getAllMembers();
  }

  compareFn(object1: any, object2: any): boolean {
    return object1 && object2 ? object1.id === object2.id : (object1 === object2);
  }

  getAllCompanies() {
    this.companyService.getAllCompanies()
      .subscribe(data => {
        this.companies = data as Company[];

      });

  }

  goTo(filter: string) {
    this.closeModal();
    this.route.navigate([`portal/personnel/${filter}`]);
  }

  deleteMember(member: Members) {
    let dataHolder : any []   = [];
    dataHolder[0] = member;
    dataHolder[1] = this.data;
    const createDialogRef = this.dialog.open(ProjectDetailMemberDeleteComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: dataHolder
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.projectMember = new Members();

      this.getAllMembers();
      this.resetPaginator();
    });

  }

  filterChanged(event){
    this.getAllMembers();
  }

  updateMembers() {
    if (this.members.length > 0 && this.projectMembers.length > 0) {

      for (let i = 0; i < this.members.length; i++) {
        for (let j = 0; j < this.projectMembers.length; j++) {
          try {
            if (this.members[i].id === this.projectMembers[j].member) {
              this.members.splice(i, 1);
            }
          } catch (error) {
            this.toast.error(error);
          }
        }
      }
    }
  }

  getAllMembers() {
    this.userService.getPersonsByCompanyId(this.memberCreateForm.controls.projectCompany.value.id).subscribe(data => {
      this.members = data as Person[];
    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
      this.getProjectMembers();
    });
  }

  getProjectRoles() {
    this.projectService.getProjectRoles().subscribe(
      result => {
        this.projectRoles = result as ProjectRole [];
      }
    )
  }



  closeModal() {
    this.dialogRef.close();
  }

  getProjectMembers() {

    this.projectService.getProjectMembers(this.data.id).subscribe(
      data => {
        this.projectMembers = data as Members[];
        this.projectMembers.forEach(projectMember => {
          this.userService.getPersonById(projectMember.member).subscribe(
            userdata => {
              let personDetails = userdata as Person;
              projectMember['username'] = personDetails.name + ' ' + personDetails.surname;
              projectMember['profession'] = personDetails.profession;
            });
        });
        this.dataSourceProjectMembers.data = this.projectMembers;
        this.dataSourceProjectMembers = new MatTableDataSource(this.projectMembers);
        this.updateMembers();
      }
    );

    this.resetPaginator();
  }

  resetPaginator() {
    this.dataSourceProjectMembers.paginator = this.paginator;
    this.dataSourceProjectMembers.sort = this.sort;
  }


  onSubmit() {
    this.projectMember.project = this.data.id;
    this.projectMember.member = this.memberCreateForm.controls.projectMembers.value.id;
    this.projectMember.nonBgiRole = this.memberCreateForm.controls.projectRoles.value;
    this.data.projectMembers.push(this.projectMember);
    this.projectService.createProject(this.data).subscribe(
      result => {
      }, error => {
        this.spinner.hide();
        this.toast.error('Updating Project members failed, please try again.', 'Project Members');
      }, () => {
        this.spinner.hide();
        this.toast.success('You have Successfully updated Project members', 'Project Members');
        this.getProjectMembers();
      });
  }
}
