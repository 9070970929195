import {DcpTestImage} from '../../../../shared-kernel/entity/common/DcpTestImage';
import {DynamicConePenetrometerTest} from '../../../../shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTest';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';

export class DcpHelper {
  id: string;
  stationNumber: string;
  time: string;
  testLevel: string;
  testImages: Array<DcpTestImage>;
  dynamicConePenetrometerTest: DynamicConePenetrometerTest = new DynamicConePenetrometerTest();
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
}
