import { Company } from '../company/company';
import { Notes } from '../notes';
import { Commodity } from './commodity';
import { Status } from './status';
import { FileToUpload } from 'src/app/common/uploaded-files/entity/file';
import { Members } from './members';
import { ProjectType } from './ProjectType';
import { ProjectCategoryType } from './ProjectCategoryType';

export class Project {
  id: string;
  creator: string;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  commodities?: Commodity[];
  objective?: Notes;
  status?: Status[];
  currentStatus?: Status;
  company?: Company;
  projectFiles?: FileToUpload [];
  updated?: Date;
  projectType?: ProjectType;
  projectCategoryTypes?: ProjectCategoryType[];
  projectMembers?: Members [];
  projectCategoryTypesString?: string;
  commoditiesString?: string;
  currentState?: any;
  statusHistory?: any[];
  hasDrillingRequest?: boolean;

    constructor () {
    }
  }
