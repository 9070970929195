import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ScreeningLog} from '../../../../shared-kernel/entity/applied-geoscience/hydrogeology/ScreeningLog';
import {ActivatedRoute} from '@angular/router';
import {UnitEnum} from '../../../../shared-kernel/enumerations/unit-enum';
import {Unit} from '../../../../shared-kernel/entity/common/unit';
import {ScreeningMaterial} from '../../../../shared-kernel/entity/applied-geoscience/geoTech/ScreeningMaterial';
import {HydrogeologyService} from '../../hydrogeology.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Borehole} from '../../../../shared-kernel/entity/common/borehole';
import {BoreholeConstructionScreeningCreateComponent} from './borehole-construction-screening-create/borehole-construction-screening-create.component';
import {CoreService} from '../../../geo-tech/core/core.service';

declare var $: any;

@Component({
  selector: 'app-borehole-construction-screening',
  templateUrl: './borehole-construction-screening.component.html',
  styleUrls: ['./borehole-construction-screening.component.scss']
})


export class BoreholeConstructionScreeningComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('projectId') projectId: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  unitEnum: typeof UnitEnum = UnitEnum;
  dataSourceScreening: MatTableDataSource<ScreeningLog> = new MatTableDataSource();
  displayedColumns: string[] = ['check', '#', 'screenId', 'from', 'to', 'diameter', 'material', 'slotNumber', 'construction type', 'edit', 'delete'];
  modalColumns: string[] = ['#', 'screenId', 'from', 'to', 'diameter', 'material', 'slotNumber', 'construction type', 'delete'];
  screeningLog: ScreeningLog;
  screeningLogs: ScreeningLog [];
  screeningMaterials: ScreeningMaterial [];
  screeningMaterial: ScreeningMaterial;
  borehole: Borehole;
  boreholeId: string;
  modalHeading: string;
  screeingId: string;
  selectedBorehole: any;
  dialogConfig = new MatDialogConfig();

  @ViewChild('fileImportInput') fileImportInput: any;
  selectedCvsRecord: ScreeningLog = new ScreeningLog();
  loading = true;
  csvRecords: ScreeningLog[] = [];

  dataSource: MatTableDataSource<ScreeningLog> = new MatTableDataSource();
  csvDdataSource: MatTableDataSource<ScreeningLog> = new MatTableDataSource();

  selection = new SelectionModel<ScreeningLog>(true, []);

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private dialog: MatDialog, private route: ActivatedRoute,
    private toaster: ToastrService,
    private coreService: CoreService,
    private spinner: NgxSpinnerService) {
    this.route.params.subscribe(params => {
      this.selectedBorehole = params.id;
      this.boreholeId = params.id;
    });
  }

  screeningIDs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  validation_messages = {
    'material': [
      {type: 'required', message: 'Please insert material'},
    ],
    'diameter': [
      {type: 'required', message: 'Please insert diameter'},
    ],
    'from': [
      {type: 'required', message: 'Please insert From value'},
    ],
    'to': [
      {type: 'required', message: 'Please insert To value'},
    ],
    'constructionType': [
      {type: 'required', message: 'Please insert Description'},
    ],
    'slotNumber': [
      {type: 'required', message: 'Please insert Slot Number'},
    ],
    'screeningId': [
      {type: 'required', message: 'Please insert Screening id'},
    ]
  };

  deleteCsvRecord(drl: ScreeningLog) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toaster.success('Record deleted', 'Record Deletion');
  }


  compareFn(object1: any, object2: any): boolean {
    return object1 && object2 ? object1.id === object2.id : object1 === object2;
  }

  resetPaginator() {
    this.dataSourceScreening.paginator = this.paginator;
    this.dataSourceScreening.sort = this.sort;
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }


  ngOnInit() {
    this.screeningMaterial = new ScreeningMaterial();
    this.screeningLog = new ScreeningLog();
    this.screeningMaterial.description = '';
    this.screeningLog.diameter.unit = new Unit();
    this.screeningLog.depthFrom.unit = new Unit();
    this.screeningLog.depthTo.unit = new Unit();
    this.screeningLog.constructionType = '';
    this.getAllConstructionTypes();
    this.getAllScreeningLogsByBoreholeId();
    this.getBorehole(this.boreholeId);
  }


  deleteScreening(screeningLog: ScreeningLog) {
    this.screeningLog = screeningLog;
    this.screeningLog.deleted = true;
    this.modalHeading = 'Delete Screening Log';

    $('#deleteModal').modal({backdrop: 'static'});
    $('#deleteModal').modal('show');
  }

  getAllConstructionTypes() {
    this.hydrogeologyService.getAllConstructionTypes().subscribe(
      data => {
        this.screeningMaterials = data as ScreeningMaterial [];

      }
    );
  }

  getAllScreeningLogsByBoreholeId() {
    this.hydrogeologyService.getAllScreening(this.boreholeId).subscribe(
      data => {
        this.screeningLogs = data as ScreeningLog [];
        this.dataSourceScreening.data = this.screeningLogs;
        this.dataSourceScreening = new MatTableDataSource(this.dataSourceScreening.data);
      }
    );
  }


  importCSVFile() {
    $('#uploadCsv').click();
  }

  showCsvModal(): void {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  saveBatch(records: ScreeningLog []) {
    this.spinner.show();
    let loopCount = 0;
    records.forEach(element => {
      this.hydrogeologyService.createBoreholeConstructionScreeningLog(element, element.id).subscribe(data => {

        },
        error => {
          this.toaster.error('Records import failed', 'Import Failed');
          this.spinner.hide();
        }, () => {
          this.spinner.hide();
          loopCount++;
          if (records.length === loopCount) {
            this.successmsg();
            this.getAllScreeningLogsByBoreholeId();
          }
        });

    });


  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];


    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        const screeningLogCsv = new ScreeningLog();
        const screeningMaterialCsv = new ScreeningMaterial();
        screeningMaterialCsv.description = '';
        screeningLogCsv.diameter.unit = new Unit();
        screeningLogCsv.depthFrom.unit = new Unit();
        screeningLogCsv.depthTo.unit = new Unit();

        screeningLogCsv.diameter.unit.id = this.unitEnum.inch;
        screeningLogCsv.depthFrom.unit.id = this.unitEnum.meter;
        screeningLogCsv.depthTo.unit.id = this.unitEnum.meter;
        screeningLogCsv.material = new ScreeningMaterial();

        screeningLogCsv.screeningId = data[0];
        screeningLogCsv.depthFrom.measure = '' + parseFloat(data[1].trim());
        screeningLogCsv.depthTo.measure = '' + parseFloat(data[2].trim());
        screeningLogCsv.diameter.measure = '' + parseFloat(data[3].trim());
        this.screeningMaterials.forEach(value => {
          if (value.description.trim().toUpperCase() === data[4].trim().toUpperCase()) {
            screeningLogCsv.material = value;
          }
        });
        screeningLogCsv.slotNumber = data[5];
        screeningLogCsv.constructionType = data[6];


        screeningLogCsv.borehole = this.borehole;
        dataArr.push(screeningLogCsv);
      }
    }
    this.csvDdataSource.data = dataArr;

    return dataArr;
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  closeAddModal() {
    $('#addModal').modal('hide');
  }

  errorsmsg() {
    this.toaster.error('Borehole construction save Unsuccessful', 'Screening Create');
  }

  successmsg() {
    this.toaster.success('Borehole construction saved successfully', 'Screening Create');
  }

  onSubmit(modalId) {

    if (Number(this.screeningLog.depthFrom.measure) <= Number(this.screeningLog.depthTo.measure)) {

      this.screeningLog.diameter.unit.id = this.unitEnum.inch;
      this.screeningLog.depthFrom.unit.id = this.unitEnum.meter;
      this.screeningLog.depthTo.unit.id = this.unitEnum.meter;

      this.hydrogeologyService.createBoreholeConstructionScreeningLog(this.screeningLog, this.boreholeId).subscribe(
        response => {

        },
        error => {
          this.errorsmsg();
        }, () => {
          this.getAllScreeningLogsByBoreholeId();
          $('#' + modalId).modal('hide');
          if (modalId === 'deleteModal') {
            this.toaster.success('Operation successful', 'Delete Record');
          } else {
            this.toaster.success('Record created successfully', 'Add Record');
          }
        }
      );


    } else {
      this.toaster.error('Depth To value should be greater than the Depth From value');
    }
  }

  saveCsvRecords() {

    this.saveBatch(this.csvRecords);
    this.fileReset();

    $('#csvModal').modal('hide');
  }

  public lessThan(depthFrom: number, depthTo: number) {
    return depthTo < depthFrom;
  }

  applyFilter(filterValue: string) {
    this.dataSourceScreening.filter = filterValue.trim().toLowerCase();
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

  onCreate() {
    this.dialogConfig.data = {
      borehole: this.borehole,
      screeningLogDataLog: null
    };
    this.dialog.open(BoreholeConstructionScreeningCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      if (result.event === 'submit') {
        this.getBorehole(this.borehole.id);
        this.getAllScreeningLogsByBoreholeId();
      }
    });
  }

  onEdit(log: any) {
    this.dialogConfig.data = {
      borehole: this.borehole,
      screeningLogDataLog: log
    };
    this.dialog.open(BoreholeConstructionScreeningCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      if (result.event === 'submit') {
        this.getBorehole(this.borehole.id);
        this.getAllScreeningLogsByBoreholeId();
      }
    });
  }

  getBorehole(id: any) {
    this.coreService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
        this.loading = false;
        this.getAllScreeningLogsByBoreholeId();
      }
    );
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceScreening.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceScreening.data ? this.dataSourceScreening.data.length : 0;
    return numSelected === numRows;
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteScreeningLogBatch(this.selection.selected).subscribe(() => {
              this.getAllScreeningLogsByBoreholeId();
              this.toaster.success('Delete Screen', 'You have Successfully deleted Screen');
            }, err => {
                this.toaster.error(err.message, "Failed Delete");
            });
        }
    });
  }

}
