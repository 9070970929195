import {Injectable} from '@angular/core';
import {TokenResponse} from '../entity/TokenResponse';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageManagerService {

  constructor() {
  }

  /**
   * All storage variable, Ids managed here
   */

  DEFAULT_TOKEN_STORAGE_NAME = 'token';

  public tokenManagerRemove(): boolean {
    if (localStorage.getItem(this.DEFAULT_TOKEN_STORAGE_NAME) != null) {
      localStorage.clear();
      return true;
    } else {
      return false;
    }
  }

  public tokenManagerAdd(responseToken: string): string {
    if (localStorage.getItem(this.DEFAULT_TOKEN_STORAGE_NAME) === null) {
      localStorage.setItem(this.DEFAULT_TOKEN_STORAGE_NAME, responseToken);
      return responseToken;
    }
    return null;
  }

  public getToken(): string {
    return localStorage.getItem(this.DEFAULT_TOKEN_STORAGE_NAME);
  }
}
