import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatTableDataSource } from '@angular/material';
import { Activity } from './../../../activities/models/activity.model';
import { Component, OnInit, Input } from '@angular/core';
import { State } from '../../../activities/models/state';

@Component({
  selector: 'app-minerals-qa-vetting-dashboard-ui',
  templateUrl: './minerals-qa-vetting-dashboard-ui.component.html',
  styleUrls: ['./minerals-qa-vetting-dashboard-ui.component.scss']
})
export class MineralsQaVettingDashboardUiComponent implements OnInit {

  @Input('activitiesCount') activitiesCount: any;
  @Input('pendingApprovalCount') pendingApprovalCount: any;
  @Input('allVettedCount') allVettedCount: any;
  @Input('myactivities') myactivities: any;

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  submittedToManagerTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  public pieChartType = 'pie';

  state = new State();

  public myactivitiesLabels = [ 'Pending-Approval', 'Returned', 'Submitted-Manager'];
  public myactivitiesData = [ 91, 107, 73, 107];


  cards :any;
  currentUser: any;
  currentPerson: any;
  colors: any ;
  isSet = true;



  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _toaster: ToastrService,
    private userDashBoardService: UserDashboardService,
    private spinner: NgxSpinnerService,) {

    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

            return [
              { title: 'My Activities', cols: 1, rows: 1 },
              { title: 'Submitted To Manager', cols: 1, rows: 1 },
              { title: 'Pending Approval', cols: 1, rows: 1 },
              { title: 'Returned', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Submitted To Manager', cols: 1, rows: 3 ,id: 'managerSubmitted'},
            { title: 'Pending Approval',cols: 1, rows: 3 ,id: 'pendingApproval'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'}
          ];
        })
      );
  }

  ngOnInit() {
    this.myactivitiesData = [this.activitiesCount.submittedToQA
      , Number(this.activitiesCount.returnedByQA),this.activitiesCount.submittedToManager];

      this.getIsDataset();
      this.getActivities();

    this.colors = [{backgroundColor: [  '#573419','#CC0000',
    '#C8A207','#CC0000','#009900', '#CC9933']}];
  }

  getIsDataset(){
    this.isSet = (Number(this.activitiesCount.drafts)+Number(this.activitiesCount.submittedToQA)+
      Number(this.activitiesCount.submittedToManager)+Number(this.activitiesCount.returnedByQA)+Number(this.activitiesCount.returnedByManager)+
      Number(this.activitiesCount.approved))>0;
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }
  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/mineral-resources/sample-collection-tech/'+this.state.SUBMITTED_TO_QA.label]);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/mineral-resources/sample-collection-tech/'+this.state.REJECTED_BY_QA.label]);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/mineral-resources/sample-collection-tech/'+this.state.SUBMITTED_TO_MANAGER.label]);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate([`portal/activities/qa_activities_approved`,{id: 'activities_vetted'}]);
        break
      default:
    }
  }
  getActivities() {
    this.spinner.show();
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingQA('SUBMITTED_TO_QA').subscribe(res => {
        this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Pending activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesVettingQA('REJECTED_BY_QA').subscribe(res => {
        this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.userDashBoardService.getMyActivitiesMineralResourcesVettingQA('REJECTED_BY_MANAGER').subscribe(res1 => {
          this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res1)) : this.sliceResultArray(res1);
          this.spinner.hide();
        }, err => {
          this._toaster.warning(err.message, 'Failed to get Rejected activities');
        });
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Rejected activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesVettingQA('SUBMITTED_TO_MANAGER').subscribe(res => {
        this.submittedToManagerTableDataSource.data = this.submittedToManagerTableDataSource.data ? this.submittedToManagerTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesVettingQA('PUBLISHED').subscribe(res => {
        this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get activities');
      });
  }
  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }



  gotoPendingSubmissionToQAList(){
    this._router.navigate(['portal/activities/mineral-resources/sample-collection/PENDING_QA_SUBMISSION']);
  }

  gotoApproved(){
    this._router.navigate(['portal/activities/mineral-resources/sample-collection/PUBLISHED']);
  }

goTo(type: string, filter: string) {
  this._router.navigate([`portal/activities/mineral-resources/${type}/${filter}`]);
}

gotoReturnedList(){
  this._router.navigate([`portal/activities/qa_activities_rejected`]);
}

gotoManagerSubmittedList(){
  let urlParams = { id: 'activities_approved' };
   this._router.navigate([`portal/activities/qa_activities_approved`,urlParams]);
}


}
