import { Project } from '../project/project';
import { SurfaceType } from './SurfaceType';
import { Location } from '../../common/location';
import { NigisImages } from '../../common/nigisImages';
import { InfiltrationTestLog } from './InfiltrationTestLog';
import { CoordinatesFormat } from '../../common/coordinatesValidator/coordinatesFormat';
import { LocationTemp } from '../../common/locationTemp';
import { BoreholeType } from '../../common/boreholeType';

export class InfiltrationTest {
  id: string;
  date: Date;
  project: Project = new Project();
  testPointId: string;
  location: Location = new Location();
  locationTemp: LocationTemp = new LocationTemp();
  locationDescription: string;
  locationImages: NigisImages[];
  comments: string;
  boreholeType: BoreholeType = new BoreholeType();
  projectCoordinator: string;
  projectManager: string;
  projectGeologist: string;
  infiltrationTestLogs: InfiltrationTestLog[];
  deleted: boolean;
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
  currentState: any;
  statusHistory: any[];
  creator?: string;
}
