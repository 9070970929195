import { District } from './district';
import { Countries } from '../../profile/countries';

export class PhysicalAddress {

  public id: string;
  public plotNumber: string;
  public streetName: string;
  public cityTown: string;
  public district: District = new District();
  public country: Countries = new Countries();

  constructor() {  }
}
