export let SettingsMenuItem = [
    {
        path: "general",
        title: "General",
        children: []
    },
    {
        path: "users",
        title: 'Personel',
        children: [
            {
                path: 'users',
                title: 'System Users'
            },
            {
                path: 'users/directorates/list',
                title: 'Directorates'
            },
            {
                path: 'users/business-units/list',
                title: 'Business Units'
            }
        ]
    },
    {
        path: "security",
        title: "Security",
        children: [
            {
                path: 'security/roles',
                title: 'Roles'
            },
            {
                path: 'security/authorities',
                title: 'Authorities'
            },
            {
                path: 'security/authorities/groups',
                title: 'Authority Groups'
            }
        ]
    },
    {
        path: "villages",
        title: "Villages",
    }
];
