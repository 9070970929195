import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleService implements OnInit {

public roleList: string[];
  constructor(private http: HttpClient, private router: Router) {

  }

  ngOnInit(): void {
  }

  getAllRoles() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllRolesUrl);
  }

  getRoleById(role: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getRoleByIdUrl  + '/' + role);
  }

  getAllAuthorities() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllAuthoritiesUrl);
  }

  getAllDepartments() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllDepartmentsUrl);
  }

  getAllBusinessUnitsByDepartment(department: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllBusinessUnitsByDepartmentUrl + '/' + department);
  }

  createrNewRole(role) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.createNewRoleUrl , role, { headers: headers })
    .subscribe(
      response => {
        this.router.navigate(['admin/role/list']);
        Swal.fire({
          title: 'Create Role',
          text: 'You have Successfully created a new Role',
          type: 'success',
          confirmButtonText: 'Ok'
        });
      },
      error => {
        Swal.fire({
          title: 'Oops!',
          text: 'Role creation failed',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    );
  }
}
