import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {JwtHelperService} from '@auth0/angular-jwt';

import { Person, User } from 'src/app/_core/data/_models/people.model';
import {GlobalDashboardService} from '../global-dashboard.service';
import {UserService} from 'src/app/auth/user/user.service';
import {ManagerDashboardCountModel} from '../model/manager-dasboard-count.model';
import { StorageConstants } from '../../_core/storage.constants';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../../_auth/_providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BusinessUnitEnum } from '../../shared-kernel/enumerations/business-unit-enum';
import { Roles } from '../../shared-kernel/entity/Roles';
import { VettingPortfolioCounts } from '../model/VettingPortfolioCounts';

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ManagerDashboardComponent implements OnInit {
  managerDashboardCountModel: ManagerDashboardCountModel ;
  nonbgimanagerDashboardCountModel: VettingPortfolioCounts ;

  private userReference: string;
  dashboardCounts = {
    DRILLING_REQUESTS: 0,
    SUBMITTED_TO_SUPERINTENDENT: 0,
    RETURNED: 0,
    APPROVED: 0,
    ALL_VETTED: 0,
    RETURNED_SUPERINTENDENT: 0
  };

  projectSubmittedToManagerCount = 0;
  projectReturnedToUserCount = 0;
  projectApprovedByManagerCount = 0;
  allProjectsVettedByManagerCount = 0;

  pendingCountStillLoading = true;
  returnedCountStillLoading = true;
  approvedCountStillLoading = true;
  vettedCountStillLoading = true;

  public currentUser: User;
  public currentPerson: Person;
  public currentLoggedPerson: Person;
  person: Person;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;

  constructor(
    private _router: Router,private _toaster: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
    private _toastr: ToastrService,
    private managerDashBoardService: GlobalDashboardService,
    private userService: UserService,
    private jwtHelper: JwtHelperService
  ) {
      const data = this.jwtHelper.decodeToken(localStorage.getItem('token'));
      this.userReference = data.jti;
      this.userService.getPersonByUserId(this.userReference)
        .subscribe(res => {
          this.person = res as Person;
        });
  }

  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toaster.error(err.message, "Profile Details");
    }, () => {
      this.isNonBGI(this.currentPerson);
    });
  }

  private initLoggedPerson(userId: string){
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
      if (res) {
          this.currentLoggedPerson = res;
          this.currentLoggedPerson['user'] = this.currentUser;
          this._localStorage.setItem(StorageConstants.personKey, this.currentLoggedPerson).subscribe(() => {});
          this.managerDashBoardService.managerDrillRequestCountsByUnit(this.currentLoggedPerson.user.businessUnit.id).subscribe(res => {
            let result: Array<{ title: string, count: number }> = res as Array<{ title: string, count: number }>;
            result.forEach(r => { this.dashboardCounts[r.title] = r.count;
            });
          });
      }
  }, err => {
      this._toastr.error(err.message, "Profile Details");
  },() => {
  });
  }

  isNonBGI(current){
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {

        if(value.roles.find(x => x.name.toLowerCase() == Roles.NONBGIMANAGER.toLowerCase())){

          this.managerDashBoardService.getNonBGIMangerDashboardCounts().subscribe(value => {
            this.nonbgimanagerDashboardCountModel = value as VettingPortfolioCounts ;
          });
        }

        if(value.roles.find(x => x.name.toLowerCase() == Roles.MANAGER.toLowerCase())){
          this.managerDashBoardService.getMangerDashboardCounts().subscribe(value => {
            this.managerDashboardCountModel = value as ManagerDashboardCountModel ;
          });
        }

      }
    )
  }

  toNum (x: string): number {
    return parseInt(x) ;
  }

  isAppliedGeoscience():boolean{
    return this.currentPerson.user.businessUnit.name === this.businessUnitEnum.appliedGeoScience;
  }

  ngOnInit() {

      this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
        this.currentUser = res;
        this.initPerson();
        this.initLoggedPerson(this.currentUser.id);
        this.getProjectActivitiesSubmittedCount();
        this.getProjectActivitiesReturnedCount();
        this.getProjectActivitiesApprovedCount();
        this.getProjectActivitiesAllVettedCount();
      }, err => {
        this._toastr.warning(err.message, "Current User Error");
      },() => {
    });

  }


  getProjectActivitiesSubmittedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('SUBMIT_TO_MANAGER').subscribe(number => {
      this.projectSubmittedToManagerCount = number;
      this.pendingCountStillLoading = false;
    });
  }

  getProjectActivitiesReturnedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('MANAGER_REJECT').subscribe(number => {
      this.projectReturnedToUserCount = number;
      this.returnedCountStillLoading = false;
    });
  }

  getProjectActivitiesApprovedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('MANAGER_APPROVE').subscribe(number => {
      this.projectApprovedByManagerCount = number;
      this.approvedCountStillLoading = false;
    });
  }

  getProjectActivitiesAllVettedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('MANAGER_ALL_VETTED').subscribe(number => {
      this.allProjectsVettedByManagerCount = number;
      this.vettedCountStillLoading = false;
    });
  }

  goToActivities(requestState: string) {
    this._router.navigate([`portal/activities/${requestState}`]);
  }

  goToDrillingRequests(requestState: string) {
    this._router.navigate([`portal/activities/drilling_requests/${requestState}`]);
  }

  goToProjectActivities(requestState: string) {
    this._router.navigate([`portal/activities/manager-project-activities/${requestState}`]);
  }



  gotoProject(uri: string) {

    let urlParams: any;
    if ('my_project' === uri) {
      urlParams = {id: 'my_project'};
    }
    if ('bgi_project' === uri) {
      urlParams = {id: 'bgi_project'};
    }

    this._router.navigate([`portal/applied-geoscience/project/list`, urlParams]);
  }

}
