import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatAutocomplete,
  MatDialogConfig,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from "../../company/company.service";
import { Company } from "../../../shared-kernel/entity/applied-geoscience/company/company";
import { LicenseType } from "../../../shared-kernel/entity/applied-geoscience/prospecting-license/license-type";
import { Commodity } from "../../../shared-kernel/entity/applied-geoscience/prospecting-license/commodity";
import { ProspectingLicenseService } from "../prospecting-license.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ToastrService } from "ngx-toastr";
import { ProspectingLicense } from "../../../shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license";
import { CompanyEnum } from "../../../shared-kernel/enumerations/company-enum";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MineralType } from "src/app/shared-kernel/entity/applied-geoscience/project/mineral-type";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-prospecting-license-select",
  templateUrl: "./prospecting-license-select.component.html",
  styleUrls: ["./prospecting-license-select.component.scss"],
})
export class ProspectingLicenseSelectComponent implements OnInit {
  @ViewChild("plPaginator") paginator: MatPaginator;
  @ViewChild("plSort") sort: MatSort;

  plFilter: FormControl = new FormControl();
  prospectingLicenses: ProspectingLicense[] = [];
  selectedProspectingLicense: ProspectingLicense;
  prospectingLicenseDataSource = new MatTableDataSource<ProspectingLicense>([]);
  pageSize = 10;
  pageNumber = 0;
  loading = false;
  totalElements = 0;
  selection = new SelectionModel<ProspectingLicense>(false, []);

  displayedProjectColumns = [
    "#",
    "PL Number",
    "Company Name",
    "PL Type",
    "Date Started",
    "Status",
    "Select",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private plService: ProspectingLicenseService,
    private dialogRef: MatDialogRef<ProspectingLicenseSelectComponent>
  ) {}

  ngOnInit(): void {

    this.paginator.page.subscribe((page) => {
        this.pageNumber = page.pageIndex;
        this.pageSize = page.pageSize;
        this.search();
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onDone() {
    this.dialogRef.close(this.selectedProspectingLicense);
  }

  search() {
    
    this.loading = true;
    this.plService
      .pagedPLsSearch(this.plFilter.value ? this.plFilter.value : '', this.pageNumber, this.pageSize)
      .subscribe((data) => {
        this.prospectingLicenses = data.content;
        this.totalElements = data.totalElements;
        this.prospectingLicenseDataSource.data = this.prospectingLicenses;
        this.prospectingLicenseDataSource.paginator = this.paginator;
        this.prospectingLicenseDataSource.sort = this.sort;
        this.loading = false;
      });
  }

  prospectingLicenseSelected(prospectingLicense: ProspectingLicense) {
    this.selectedProspectingLicense = prospectingLicense;
    this.selection.toggle(prospectingLicense);
  }
  
  radioLabel(row?: ProspectingLicense, position?: number): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${position + 1}`;
  }
}
