import { Injectable } from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import { LocalStorage } from '@ngx-pwa/local-storage';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from 'ngx-toastr';

import { StorageConstants } from "./../../_core/storage.constants";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _toastr: ToastrService,
        private _localStorage: LocalStorage
    ) {
    }
    
    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        try {
            let localToken = await this._localStorage.getItem<string>(StorageConstants.tokenKey).toPromise();

            if (!localToken) {
                this._router.navigate(['auth'], { queryParams: { returnUrl: _state.url } });
                return false;
            }

            let tokenHelper = new JwtHelperService();
            const isExpired: boolean = tokenHelper.isTokenExpired(localToken, 10);

            if (isExpired === false) return true;

            this._localStorage.clear().subscribe(res => {
                if (res === false) localStorage.clear();

                this._router.navigate(['auth'], { queryParams: { returnUrl: _state.url } });
            });

            return isExpired;
        } catch(_error) {
            this._toastr.error("Security error occured", "Security error");
            this._router.navigate(['auth'], { queryParams: { returnUrl: _state.url } });
            return false;
        }
    }
}