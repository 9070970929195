import { Component, Inject, OnInit } from '@angular/core';
import { DrillerLogRecord } from '../../../shared-kernel/entity/applied-geoscience/drillerlog/drillerlogrecord';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sample } from '../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { CompanyService } from '../../../applied-geoscience/company/company.service';
import { DrillerlogService } from '../drillerlog.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-drillers-log-import',
  templateUrl: './drillers-log-import.component.html',
  styleUrls: ['./drillers-log-import.component.scss']
})
export class DrillersLogImportComponent implements OnInit {

  csvDdataSource: MatTableDataSource <DrillerLogRecord> = new MatTableDataSource();
  displayedColumnsForImport: string[] = ['date', 'sampleNumber', 'description', 'numberOfRods',
    'coreBarrel', 'drillString', 'tableHeight', 'stickUp', 'totalStickUp', 'depth', 'previousDepth',
    'holeRun', 'coreRecovered', 'coreLoss', 'action'];
  loading: boolean =true;
  selectedCvsRecord: DrillerLogRecord;
  constructor(public dialogRef: MatDialogRef<DrillersLogImportComponent>, @Inject(MAT_DIALOG_DATA) public data: MatTableDataSource <DrillerLogRecord>,
              private drillerLogService: DrillerlogService,private toaster: ToastrService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.csvDdataSource = this.data;
    this.loading = false;
  }


  deleteCsvRecord(drl: DrillerLogRecord) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvDdataSource.data.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvDdataSource.data);
    this.toaster.success('Record deleted', 'Record Deletion');
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveCsvRecords() {
    this.saveRecordsList(this.csvDdataSource.data);
  }

  saveRecordsList(records: DrillerLogRecord[]) {
    if(this.loading){
      this.toaster.warning('No records to submit', 'Import Drillers Log');
      this.spinner.hide();
    }else{
        this.drillerLogService.createDrillersLogRecords(records).subscribe(data => {
          }, error => {

            this.toaster.error('Records import failed', 'Import Failed');
            this.spinner.hide();

          },
          () => {
          this.closeModal();
            this.toaster.success('Records import successful', 'Import Failed');
            this.spinner.hide();
          });
    }
  }

}
