import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  registerUser(person) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.registerUrl, person, {headers: headers})
      .subscribe(
        response => {
          this.router.navigate(['admin/user/list']);
          this.successmsg('Register', 'You have Successfully Registered a new user');
        },
        error => {
          this.errorsmsg('Error', error.error.message);
        }
      );
  }

  registerFirstTimeUser(person) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.registerFirstUserUrl, person, {headers: headers})
      .subscribe(
        response => {
          this.router.navigate(['auth']);
          this.successmsg('Register', 'You have Successfully Registered a First time user');
        },
        error => {
          this.errorsmsg('Error', error.error.message);
        }
      );
  }

  setUserPassword(passwordStore : any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.setUserPasswordUrl ,passwordStore, {headers: headers});
  }

  getAllTitles() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllTitlesUrl);
  }

  getAllCountries() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllCountriesUrl);
  }

  getAllIdTypes() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllIdTypesUrl);
  }

  getAllSexes() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllSexesUrl);
  }

  getAllDistricts() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllDistrictsUrl);
  }

  getPersons() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.personsUrl);
  }

  searchPersons(person: string) {
    return this.http.get(`${ServiceEndPoints.authBaseUrl}${ServiceEndPoints.searchPersonsUrl}?person=${person ? person : ''}`);
  }

  getBGIAndNoBGIPersons(company: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getBGINonBGIPersonUrl+ '/' + company);
  }

  getPersonById(person: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonByIdUrl + '/' + person);
  }

  getPersonsLite() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonsLiteUrl);
  }

  getPersonByUserId(person: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonByUserIdUrl + '/' + person);
  }

  checkIfFirstUserExist() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.checkIfFirstUserExistUrl);
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }

  getPersonByBusinessUnit(businessUnitId: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonByBusinessUnitUrl + '/' + businessUnitId);
  }
  getPersonnelByBusinessUnit(businessUnitId: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonnelByBusinessUnitUrl + '/' + businessUnitId);
  }

  getMineralResourceUsernames() {
    return this.http.get(ServiceEndPoints.authBaseUrl + '/api/user/v1/users/business-unit/mineral-resource');
  }

  getUserBusinessUnit(user: any) {
    let tempApiUrl :string = ServiceEndPoints.getBusinessUnitByUserIdUrl;
    let apiUrl = tempApiUrl.replace('{userId}',user);
    return this.http.get(ServiceEndPoints.authBaseUrl +  apiUrl);
  }

  getPersonsByCompanyId(company: any){
    let tempApiUrl :string = ServiceEndPoints.getPersonsByCompanyIdUrl;
    let apiUrl = tempApiUrl.replace('{companyId}',company);
    return this.http.get(ServiceEndPoints.authBaseUrl +  apiUrl);
  }
}

