import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MineralChemistry} from '../../../shared-kernel/entity/applied-geoscience/hydrogeology/MineralChemistry';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProspectingLicenseService} from '../prospecting-license.service';
import {ScreeningLog} from '../../../shared-kernel/entity/applied-geoscience/hydrogeology/ScreeningLog';
import {ProspectingLicense} from '../../../shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license';
import {
  ChemistryCompound,
  SampleGeoChemistry
} from "../../geo-tech/samples/samples-details/geo-chemistry/csv-record.model";
import {SampleGeoChemistryService} from "../../geo-tech/samples/samples-details/geo-chemistry/geo-chemistry.service";

declare var $: any;

@Component({
  selector: 'app-prospecting-licence-mineral-chemistry',
  templateUrl: './prospecting-licence-mineral-chemistry.component.html',
  styleUrls: ['./prospecting-licence-mineral-chemistry.component.scss'],
  providers: [
    SampleGeoChemistryService
  ]
})
export class ProspectingLicenceMineralChemistryComponent implements OnInit {

  dataSourceProspectingLicenseLogs = new MatTableDataSource();
  keys:string[] = [];
  mineralChemistryLogs: MineralChemistry[] ;
  displayedProjectColumns: string[] = ['#', 'sampleId', 'mineralId'];
  modalcolumns: string[] = ['#', 'sampleId', 'mineralId'];
  selectedProject: string;
  projectId: string;
  idObj: any;
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileImportInput') fileImportInput: any;
  selectedCvsRecord: MineralChemistry = new MineralChemistry();

  csvRecords: MineralChemistry[] = [];

  csvDdataSource: MatTableDataSource<MineralChemistry> = new MatTableDataSource();

  constructor(private prospectingLicenceService: ProspectingLicenseService,private geoChemServ: SampleGeoChemistryService,
              private route: ActivatedRoute, private toast: ToastrService,
              private toaster: ToastrService, private spinner: NgxSpinnerService) {
    this.route.params.subscribe(params => {
      this.selectedProject = params.id;
      this.projectId = params.id;
      this.idObj = {id: this.projectId}
    });
  }

  ngOnInit() {
    this.resetPaginator();
    this.getAllMineralChemistry();
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  deleteCsvRecord(drl: MineralChemistry) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toaster.success('Record deleted', 'Record Deletion');
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  showCsvModal(): void {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  getAllMineralChemistry() {
    this.loading = true;
      this.geoChemServ.getGeoChemByBoreholeId(this.projectId).subscribe(res => {
        this._initColums(res);
        this._initRows(res);
        this.loading = false;
      }, err => {
        this.toast.error(err.message, "Failed to get mineral chemistry");
      });
  }

  private _initColums(rawChemistry: Array<MineralChemistry>) {
    let total : string;
    while (this.displayedProjectColumns && this.displayedProjectColumns.length > 0) this.displayedProjectColumns.pop();
      this.displayedProjectColumns.push("No.", "Sample No.", "Mineral Id");
      if (rawChemistry && rawChemistry.length > 0) {
        rawChemistry[0].compounds.forEach(c => {
          if('Total'.toLowerCase() === c.compound.trim().toString().toLowerCase()) {
            total = c.compound;
          }
          else{
            this.displayedProjectColumns.push(c.compound);
          }
        });
        this.displayedProjectColumns.push(total);
      }
  }

  private _initRows(rawChemistry: Array<MineralChemistry>) {

    this.dataSourceProspectingLicenseLogs.data = [];
    let localDataSource = [];

    for (let i = 0; i < rawChemistry.length; i++) {
      let temp : any;
      let total : number;
        temp = {
          "No.": i + 1,
          "Sample No.": rawChemistry[i].sampleId,
          "Mineral Id": rawChemistry[i].mineralId
        };

      rawChemistry[i].compounds.forEach(c => {
        temp[c.compound] = c.number;
      });
      localDataSource.push(temp);
    }
    this.dataSourceProspectingLicenseLogs.data = localDataSource;

  }


  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  saveBatch(records: MineralChemistry []) {
    this.spinner.show();
     //this.prospectingLicenceService.createBatchProspectingLicenceMineralChemistry(records).subscribe(data => {
     this.geoChemServ.addMineralChemistryInProspectingLicense(records, this.projectId).subscribe(_res => {
          this.toaster.success("Successfully saved","Mineral Chemistry");
          this.getAllMineralChemistry();
        },
        error => {
          this.toaster.error('Records import failed', 'Import Failed');
          this.spinner.hide();
        }, () => {
          this.spinner.hide();
        });
  }

  resetPaginator() {
    this.dataSourceProspectingLicenseLogs.paginator = this.paginator;
    this.dataSourceProspectingLicenseLogs.sort = this.sort;
  }

  isCsvExtension(file: File): Boolean {
    return file.name.endsWith('.csv');
  }

  onSelectFile(files: FileList) {
    const csvFile = files[0];
    if (!this.isCsvExtension(csvFile)) {
      this.toast.error("Invalid file extension", "Invalid File");
      return;
    }
    const reader = new FileReader();
    reader.readAsText(csvFile);
    reader.onerror = () => {
      this.toast.error("Error reading file", "File Error");
    }
    reader.onload = () => {
      let csvData = reader.result;
      let recordsArr = (<string>csvData).split(/\r\n|\n/);
      const recordsToUpload: Array<MineralChemistry> = [];
      const headers = recordsArr[0].split(',');
      this.modalcolumns = ['#', 'Sample Id', 'Mineral Id'];
      for (let i = 3; i < recordsArr.length; i++) {
        this.modalcolumns.push(headers[i]);
      }
      for (let i = 1; i < recordsArr.length; i++) {
          if (recordsArr[i].trim() === "") continue;
          let currentRecord = recordsArr[i].split(',');
          if(currentRecord[0].trim() !== ""){
              let tempRecord: SampleGeoChemistry;
              tempRecord = <SampleGeoChemistry>{
                sampleId: currentRecord[0],
                mineralId: currentRecord[1],
                linkedEntity: this.projectId,
                compounds: []
              };
              for (let j = 4; j< currentRecord.length; j++)
              {
                  if(Number(currentRecord[j])){
                    tempRecord.compounds.push(<ChemistryCompound>{
                      compound: headers[j],
                      number: +(currentRecord[j])
                     });
                    if(i==1){
                      this.keys.push(headers[j]);
                    }
                  }
              }
            if(i==1){
              let firstKeys = Object.keys(tempRecord);
              firstKeys.pop();
              this.keys = [...firstKeys,...this.keys];
            }
              recordsToUpload.push(tempRecord);
          }else{
              this.toast.error('Sample Number is not ','Mineral Chemistry');
          }
      }
      this.csvDdataSource.data = recordsToUpload;
      this.showCsvModal();
    }
    this.fileReset();
  }
  saveCsvRecords() {
    this.saveBatch(this.csvDdataSource.data);
    this.fileReset();
    $('#csvModal').modal('hide');
  }


  applyFilter(filterValue: string) {
    this.dataSourceProspectingLicenseLogs.filter = filterValue.trim().toLowerCase();
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
  }

}
