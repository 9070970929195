import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { NigisDocument } from './nigis-doc.model';
import { environment } from '../../../environments/environment';
import {ChangeStateModel} from "../../collections/services/collections.approval.service";

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    constructor (
        private _http: HttpClient
    ) {}

    uploadFile(formData: FormData): Observable<any> {
        return this._http.post<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/nigis_documents/v1/upload', formData, {
            reportProgress: true, observe: 'events', responseType: 'json'
        });
    }

    addFileDetails(doc: NigisDocument): Observable<NigisDocument> {
        return this._http.post<NigisDocument>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/nigis_documents/v1/document_details', doc);
    }

    getDocument(docId: string): Observable<NigisDocument> {
        return this._http.get<NigisDocument>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/nigis_documents/v1/document_details/${docId}`);
    }

    getDocuments(): Observable<Array<NigisDocument>> {
        return this._http.get<Array<NigisDocument>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/nigis_documents/v1/document_details');
    }

    getEntityDocuments(entityId: string): Observable<Array<NigisDocument>> {
        return this._http.get<Array<NigisDocument>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/nigis_documents/v1/document_details/entities/${entityId}`);
    }

    deleteDocument(docId: string): Observable<any> {
        return this._http.delete<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/nigis_documents/v1/document_details/${docId}`);
    }

    getDocumentFile(fullUrl: string): Observable<any> {
        return this._http.get<any>(fullUrl, { responseType: 'blob' as 'json' });
    }

    getDocumentCount(entityId: string): Observable<number> {
        return this._http.get<number>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/nigis_documents/v1/document_details/entities/${entityId}/count`);
    }




    uploadApprovalFile(formData: FormData): Observable<any> {
        return this._http.post<any>(environment.collectionsBaseUrl + '/api/nigis_documents/v1/upload', formData, {
            reportProgress: true, observe: 'events', responseType: 'json'
        });
    }

    addApprovalFileDetails(doc: NigisDocument): Observable<NigisDocument> {
        return this._http.post<NigisDocument>(environment.collectionsBaseUrl + '/api/nigis_documents/v1/document_details', doc);
    }

    getFile(docId: string): Observable<NigisDocument> {
        return this._http.get<NigisDocument>(environment.collectionsBaseUrl+ `/api/nigis_documents/v1/document_details/${docId}`);
    }

    getFiles(): Observable<Array<NigisDocument>> {
        return this._http.get<Array<NigisDocument>>(environment.collectionsBaseUrl + '/api/nigis_documents/v1/document_details');
    }

    getEntityFiles(entityId: string): Observable<Array<NigisDocument>> {
        return this._http.get<Array<NigisDocument>>(environment.collectionsBaseUrl + `/api/nigis_documents/v1/document_details/entities/${entityId}`);
    }

    deleteFile(docId: string): Observable<any> {
        return this._http.delete<any>(environment.collectionsBaseUrl + `/api/nigis_documents/v1/document_details/${docId}`);
    }

    getApprovalDocumentFile(fullUrl: string): Observable<any> {
        return this._http.get<any>(fullUrl, { responseType: 'blob' as 'json' });
    }


    getFileCount(entityId: string): Observable<number> {
      return this._http.get<number>(environment.collectionsBaseUrl + `/api/nigis_documents/v1/file_details/entities/${entityId}/count`);
    }

  addCollectionFileDetails(doc: NigisDocument): Observable<NigisDocument> {
    return this._http.post<NigisDocument>(environment.collectionsBaseUrl + '/api/nigis_documents/v1/document_details', doc);
  }

  getLandfolioDocuments(): Observable<Array<NigisDocument>> {
    return this._http.get<Array<NigisDocument>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/nigis_documents/v1/document_details/entities`);
  }


}
