import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FileToUpload } from './entity/file';
import { MatDialogRef, MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UploadedFilesService } from './uploaded-files.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Link } from './entity/link';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { DocUploadComponent } from './../../shared/documents/doc-upload/doc-upload.component';
declare var $: any;

@Component({
  selector: 'app-uploaded-files',
  templateUrl: './uploaded-files.component.html',
  styleUrls: ['./uploaded-files.component.scss']
})
export class UploadedFilesComponent implements OnInit {
  documentsForm: FormGroup;
  uploadedFileList: FileToUpload[];
  uploadedFileDatasource: MatTableDataSource<FileToUpload> = new MatTableDataSource();
  displayedColumns = ['#', 'Name', 'Description', 'File Type', 'Creation Date', 'Action'];
  loading = true;
  entityId: string;
  modalHeader = 'Upload File';
  selectedFile: File = null;
  selectedFiles: FileList;
  edit = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  researchLinks: Link[] = [];

  displayName = 'Browse for a file';

  selectedDocument: FileToUpload = new FileToUpload();

  fileToBeUploaded: FileToUpload = new FileToUpload();
  dialogConfig = new MatDialogConfig();
  formData: FormData;

  validation_messages = {
    'name': [
      { type: 'required', message: 'File name is required' },
    ]
  };
  progress: { percentage: number } = { percentage: 0 };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isNewUpload = false;

  constructor(
    private fileUploadService: UploadedFilesService, private toaster: ToastrService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.entityId = params.id;
    });

  }



  ngOnInit() {
    this.getAllFilesByKey(this.entityId);

  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  openDialog() {
    this.dialogConfig.data = {
      id: this.entityId,
      modalHeader: this.modalHeader = 'Upload File',

    };

    this.dialog.open(UploadFileComponent, this.dialogConfig)
      .afterClosed().subscribe((result) => {
          this.getAllFilesByKey(this.entityId);
      });
  }

  openNewDialog() {
    this.dialog.open(DocUploadComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: { id: this.entityId }
    }).afterClosed().subscribe(r => {
    });
  }

  editRecord(fileToBeUploaded: FileToUpload) {

    if (!this.selectedFile) {
      this.selectedFile = {
        lastModified: null,
        name: fileToBeUploaded.fileName,
        size: null,
        type: null,
        slice: null
      };
    }
    this.dialogConfig.data = {
      id: this.entityId,
      modalHeader: this.modalHeader = 'Edit File',
      edit: true,
      displayName: fileToBeUploaded.fileName,
      fileToBeUploaded: fileToBeUploaded,
      file: this.selectedFile,
      links: fileToBeUploaded.links
    };

    this.dialog.open(UploadFileComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
        if (result) {
          this.getAllFilesByKey(this.entityId);

        }
      });

  }


  deleteRecordRequest(fileToBeUploaded: FileToUpload) {
    this.selectedDocument = fileToBeUploaded;
    $('#deleteModal').modal('show');
  }

  deleteRecord() {
    this.fileUploadService.deleteFile(this.selectedDocument).subscribe(data => {
      this.getAllFilesByKey(this.entityId);
      this.toaster.success('Record deleted', 'Record Deletion');
      $('#deleteModal').modal('hide');
    },
      error => {
        this.toaster.error('Record Failed', 'Record Deletion');
      });
  }

  closeAddModal() {
    if (this.edit) {
      $('#uploadFileModal').modal('hide');
      this.researchLinks = [];
    } else {
      $('#resetForm').click();
      $('#uploadFileModal').modal('hide');
      this.selectedFile = null;
      this.researchLinks = [];
      this.fileToBeUploaded = new FileToUpload();
    }
    this.modalHeader = 'Upload File';
    this.edit = false;
    this.isNewUpload = false;
    this.progress.percentage = 0;
  }

  resetPaginator() {
    this.uploadedFileDatasource.sort = this.sort;
    this.uploadedFileDatasource.paginator = this.paginator;
  }

  getAllFiles() {
    this.fileUploadService.getAllFiles().subscribe(response => {
      this.uploadedFileList = response as FileToUpload[];
      this.uploadedFileDatasource.data = this.uploadedFileList;
      this.loading = false;
    },
      error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  getAllFilesByKey(boreholeId: string) {
    this.fileUploadService.getAllFilesByKey(boreholeId).subscribe(response => {
      this.uploadedFileList = response as FileToUpload[];
      this.uploadedFileDatasource.data = this.uploadedFileList;
      this.loading = false;
    },
      error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  downloadFile(file: FileToUpload) {
    this.fileUploadService.downloadFile(file.fileName).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      if (file.fileName) {
        downloadLink.setAttribute('download', file.fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    }, error => {
      this.toaster.warning(file.fileName + ' could not be found on the server');
    }, () => {

    });
  }

  applyFilter(filterValue: string) {
    this.uploadedFileDatasource.filter = filterValue.trim().toLowerCase();
  }
}
