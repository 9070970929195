import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-minerals-manager-sample-vetting-dashboard-ui',
  templateUrl: './minerals-manager-sample-vetting-dashboard-ui.component.html',
  styleUrls: ['./minerals-manager-sample-vetting-dashboard-ui.component.scss']
})
export class MineralsManagerSampleVettingDashboardUiComponent implements OnInit {

  @Input('activitiesCount') activitiesCount: any;

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  cards :any;
  colors: any ;
  pieChartType = 'pie';
  isSet = true;

  allVetted = 0;

  public myactivitiesLabels = [ 'Pending-Approval', 'Returned', 'Approved'];
  public myactivitiesData = [ 91, 107, 73];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _toaster: ToastrService,
    private userDashBoardService: UserDashboardService,
    private spinner: NgxSpinnerService,
  ) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

            return [
              { title: 'My Activities', cols: 1, rows: 1 },
              { title: 'Returned', cols: 1, rows: 1 },
              { title: 'Pending Approval', cols: 1, rows: 1 },
              { title: 'Approved', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'},
            { title: 'Pending Approval',cols: 1, rows: 3 ,id: 'pendingApproval'},
            { title: 'Approved', cols: 1, rows: 3 ,id: 'approved'}
          ];
        })
      );
  }

  ngOnInit() {
    this.myactivitiesData = [this.activitiesCount.pending, this.activitiesCount.returned,
      this.activitiesCount.approved];

      this.getIsDataset();
      this.getActivities();

      this.colors = [{backgroundColor: [  '#573419','#C8A207', '#00A2E5',
      '#CC0000','#009900', '#CC9933']}];

      this.allVetted = (Number(this.activitiesCount.returned)) + (Number(this.activitiesCount.approved));
  }

  getIsDataset(){
    this.isSet = (Number(this.activitiesCount.pending)+Number(this.activitiesCount.returnedByGeologist)+
      Number(this.activitiesCount.submittedToManager))>0;
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager/Submitted']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager/Returned']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager/Approved']);
        break
      default:
    }
  }

  getActivities() {
    this.spinner.show();
    this.userDashBoardService.getMyActivitiesMineralResourcesManager('Submitted').subscribe(res => {
        this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Pending activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesManager('Returned').subscribe(res => {
        this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Rejected activities');
      });

      this.userDashBoardService.getMyActivitiesMineralResourcesManager('Approved').subscribe(res => {
        this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get activities');
      });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  gotoReturnedList(){
    this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager/Returned']);
  }

  gotoPendingApprovalList(){
    this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager/Submitted']);
  }

  gotoApproved(){
    this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager/Approved']);
  }

}
