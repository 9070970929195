import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { State } from './../../../activities/models/state';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource } from '@angular/material';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Input } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { setTextRange } from 'typescript';

@Component({
  selector: 'app-minerals-geologist-files-dashboard-ui',
  templateUrl: './minerals-geologist-files-dashboard-ui.component.html',
  styleUrls: ['./minerals-geologist-files-dashboard-ui.component.scss']
})
export class MineralsGeologistFilesDashboardUiComponent implements OnInit {

  @Input('nigisFileActivityCountsResponseDto') nigisFileActivityCountsResponseDto: any;

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  cards :any;
  currentUser :any;
  colors: any ;
  pieChartType = 'pie';
  userRole :any[]=[];
  isSet = true;
  state = new State();

  public myactivitiesLabels = [ 'Drafts', 'Submitted-Senior', 'Submitted-Manager', 'Returned', 'Approved'];
  public myactivitiesData = [ 91, 107, 73, 4, 84];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userDashBoardService: UserDashboardService,
    private _router: Router,
    private _toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private _localStorage: LocalStorage,
  ) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

            return [
              { title: 'My Activities', cols: 1, rows: 1 },
              { title: 'Returned', cols: 1, rows: 1 },
              { title: 'Drafts', cols: 1, rows: 1 },
              { title: 'Approved', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'},
            { title: 'Drafts',cols: 1, rows: 3 ,id: 'drafts'},
            { title: 'Approved', cols: 1, rows: 3 ,id: 'approved'}
          ];
        })
      );
  }

  ngOnInit() {

    //
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.currentUser.roles.forEach(element => {
      let state:State  = new State();
      let userRoleLocal = element.name.toUpperCase();

          for(var i=0; i <userRoleLocal.split(' ').length;i++){
            userRoleLocal = userRoleLocal.replace(' ', "_");
          }

        if(element.name !== 'Manager'){
          switch(true){
            case state.SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST.label.includes(userRoleLocal):
              this.userRole[0] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_SENIOR_MINERAL_ECONOMIST/slf';
              if(userRoleLocal.includes("SENIOR")){
                this.userRole[1] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST/slf';
              }
              else{
                this.userRole[1] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_MINERAL_ECONOMIST/slf';
              }
              this.userRole[2] = 'portal/activities/mineral-resources-files/project/REJECTED_BY_SENIOR_MINERAL_ECONOMIST/slf';
              break;
            case state.SUBMITTED_TO_SENIOR_GEOCHEMIST.label.includes(userRoleLocal):
              //this.userRole =userRoleLocal;
              this.userRole[0] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_SENIOR_GEOCHEMIST/slf';
              if(userRoleLocal.includes("SENIOR")){
                this.userRole[1] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_SENIOR_GEOCHEMIST/slf';
              }
              else{
                this.userRole[1] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_GEOCHEMIST/slf';
              }
              this.userRole[2] = 'portal/activities/mineral-resources-files/project/REJECTED_BY_SENIOR_GEOCHEMIST/slf';
              break;
            case state.SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST.label.includes(userRoleLocal):
             // this.userRole =userRoleLocal;
              this.userRole[0] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST/slf';
              if(userRoleLocal.includes("SENIOR")){
                this.userRole[1] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_SENIOR_ECONOMIC_GEOLOGIST/slf';
              }
              else{
                this.userRole[1] = 'portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_ECONOMIC_GEOLOGIST/slf';
              }
              this.userRole[2] = 'portal/activities/mineral-resources-files/project/REJECTED_BY_SENIOR_ECONOMIC_GEOLOGIST/slf';
              break;
            default:
              break
          }
        }
      });


    }, err => {
      this._toaster.warning(err.message, 'Current User Error');
    }, () => {

    });



    this.myactivitiesData = [this.nigisFileActivityCountsResponseDto.draft, this.nigisFileActivityCountsResponseDto.seniorSubmitted,
      this.nigisFileActivityCountsResponseDto.managerSubmitted, this.nigisFileActivityCountsResponseDto.returned,
      this.nigisFileActivityCountsResponseDto.approved];

      this.getIsDataset();
      this.getActivities();

      this.colors = [{backgroundColor: [  '#573419','#C8A207', '#00A2E5',
      '#CC0000','#009900', '#CC9933']}];
  }

  getIsDataset(){
    this.isSet = (Number(this.nigisFileActivityCountsResponseDto.draft)+Number(this.nigisFileActivityCountsResponseDto.seniorSubmitted)+
      Number(this.nigisFileActivityCountsResponseDto.managerSubmitted)+Number(this.nigisFileActivityCountsResponseDto.returned)+
      Number(this.nigisFileActivityCountsResponseDto.approved))>0;
  }

  getActivities() {
    this.spinner.show();
    this.userDashBoardService.getFileActivities('pending').subscribe(res => {
        this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Pending activities');
      });

      this.userDashBoardService.getFileActivities('returned_geochemist').subscribe(res => {
        this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Rejected activities');
      });

      this.userDashBoardService.getFileActivities('approved_manager').subscribe(res => {
        this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get activities');
      });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  goTo(type: string, filter: string, role: string) {
    this._router.navigate([`portal/activities/mineral-resources-files/${type}/${filter}/${role}`]);
  }

  chartClickedActivities(event){

    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate([`portal/activities/mineral-resources-files/project/PENDING_SENIOR_SUBMISSION/slf`]);
        break
      case this.myactivitiesLabels[1]:
        //this._router.navigate(['portal/activities/mineral-resources-files/project/SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST/slf']);
        this._router.navigate([this.userRole[0]]);
        break
      case this.myactivitiesLabels[2]:
        //this._router.navigate([`portal/activities/mineral-resources-files/project/SUBMITTED_TO_MANAGER_BY_SENIOR_${this.userRole}/slf`]);
        this._router.navigate([this.userRole[1]]);
        break
      case this.myactivitiesLabels[3]:
        //this._router.navigate([`portal/activities/mineral-resources-files/project/REJECTED_BY_SENIOR_${this.userRole}/slf`]);
        this._router.navigate([this.userRole[2]]);
        break
      case this.myactivitiesLabels[4]:
        this._router.navigate(['portal/activities/mineral-resources-files/project/PUBLISHED/slf']);
      default:
  }
}
}
