import { Component, Inject, OnInit } from '@angular/core';
import {DrillerService} from '../../driller.service';
import {DrillingRequest} from 'src/app/shared-kernel/entity/driller/drilling-request';
import {ProjectService} from 'src/app/applied-geoscience/project/project.service';
import {CompanyService} from 'src/app/applied-geoscience/company/company.service';
import {DrillingChecklist} from 'src/app/shared-kernel/entity/driller/DrillingChecklist';
import {BoreholeType} from 'src/app/shared-kernel/entity/common/boreholeType';
import {GeoTechService} from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import {UnitEnum} from 'src/app/shared-kernel/enumerations/unit-enum';
import {DrillerValidation} from '../../driller.validation';
import { FormGroup,  FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-driller-checklist-create',
  templateUrl: './driller-checklist-create.component.html',
  styleUrls: ['./driller-checklist-create.component.scss']
})
export class DrillerChecklistCreateComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  firthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  title: string = 'Create New Drilling Checklist';
  panelOpenState: boolean = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DrillerChecklistCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private drillerService: DrillerService,
    private drillerValidation: DrillerValidation,
    private toastr: ToastrService) {
  }

  validation_messages = this.drillerValidation.validation_messages;
  drillingChecklistCreateForm: FormGroup;
  drillingCheckList: DrillingChecklist = new DrillingChecklist();
  boreholeType: BoreholeType;
  boreholeTypes: BoreholeType[];
  unitEnum: typeof UnitEnum = UnitEnum;

  ngOnInit() {
    this.initForms()
  }

  closeModal() {
    this.dialogRef.close();
  }

  private initForms() {

    if(this.data.drillingChecklist){
      this.title  = 'Edit Drilling checklist';
    }
    this.drillingChecklistCreateForm = this.formBuilder.group({
      fuelFilterQuantity:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.rigSpares.fuelFilterQuantity : ''],
      oilFilter:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.rigSpares.oilFilter : ''],
      hydraulicFilter:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.rigSpares.hydraulicFilter : ''],
      fanBelt:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.rigSpares.fanBelt : ''],
      hydraulic:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.oils.hydraulic.measure : ''],
      engine:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.oils.engine.measure : ''],
      gearBox:[this.data.drillingChecklist ? this.data.drillingChecklist.drillRig.oils.gearBox.measure : ''],
      mudPumpSparesValves:[this.data.drillingChecklist ? this.data.drillingChecklist.mudPumpSpares.valves: ''],
      mudPumpSparesSeals:[this.data.drillingChecklist ? this.data.drillingChecklist.mudPumpSpares.seals : ''],
      mudPumpSparesSuctionSystem:[this.data.drillingChecklist ? this.data.drillingChecklist.mudPumpSpares.suctionSystem : ''],
      mudPumpSparesDeliveryHoseAndConnections:[this.data.drillingChecklist ? this.data.drillingChecklist.mudPumpSpares.deliveryHoseAndConnections : ''],
      coreBarrelsAssembly:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingString.coreBarrelsAssembly : ''],
      drillRodsSize:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingString.drillRodsSize.measure : ''],
      waterSwivel:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingString.waterSwivel: ''],
      drillBitsBq:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingString.drillBit.bq: ''],
      drillBitsNq:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingString.drillBit.nq : ''],
      drillBitsHq:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingString.drillBit.hq : ''],
      shiftingSpanner48:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.shiftingSpanner48 : ''],
      shiftingSpanner36:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.shiftingSpanner36 : ''],
      shiftingSpanner24:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.shiftingSpanner24 : ''],
      parmalleeWrenchesBq:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.parmalleeWrenches.bq.measure : ''],
      parmalleeWrenchesNq:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.parmalleeWrenches.nq.measure : ''],
      parmalleeWrenchesHq:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.parmalleeWrenches.hq.measure : ''],
      parmalleeWrenchesToolBoxComplete:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingTools.parmalleeWrenches.toolBoxComplete.measure : ''],
      drillMud:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingConsumables.drillMud : ''],
      copperGreaseQuantity:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingConsumables.copperGreaseQuantity : ''],
      copperGreaseWeight:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingConsumables.copperGreaseWeight.measure : ''],
      coreLifterSprings:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingConsumables.coreLifterSprings : ''],
      stopRings:[this.data.drillingChecklist ? this.data.drillingChecklist.drillingConsumables.stopRings : ''],
      coreTraysBq:[this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.bq : ''],
      coreTraysHq:[this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.hq : ''],
      coreTraysHq2:[this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.hq2 : ''],
      waterTankQuantity : [this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.waterTankQuantity : ''],
      waterTankVolume:[this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.waterTankVolume.measure : ''],
      fuelTankQuantity:[this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.fuelTankQuantity : ''],
      fuelTankVolume:[this.data.drillingChecklist ? this.data.drillingChecklist.coreTrays.fuelTankVolume.measure: '']
    });
  }

  getDrillingRequestByIdBeforeSubmit(){
    this.drillerService.getDrillingRequestById(this.data.drillingRequestId).subscribe(value => {
      this.data.drillingRequest = value as DrillingRequest;
    },error => {},() => {
      this.onSubmit();
    })
  }

  onSubmit() {
    this.submitted = true;

    if(this.data.drillingChecklist){
      this.drillingCheckList = this.data.drillingChecklist;
    }else {
      this.drillingCheckList.drillingRequest = new DrillingRequest();
      this.drillingCheckList.drillingRequest = this.data.drillingRequest;
    }
    this.drillingCheckList.coreTrays.waterTankQuantity = this.drillingChecklistCreateForm.controls.waterTankQuantity.value;
    this.drillingCheckList.coreTrays.fuelTankVolume.unit.id = this.unitEnum.liter;
    this.drillingCheckList.coreTrays.fuelTankVolume.measure = this.drillingChecklistCreateForm.controls.fuelTankVolume.value;
    this.drillingCheckList.coreTrays.waterTankVolume.unit.id = this.unitEnum.liter;
    this.drillingCheckList.coreTrays.waterTankVolume.measure = this.drillingChecklistCreateForm.controls.waterTankVolume.value;
    this.drillingCheckList.coreTrays.fuelTankQuantity = this.drillingChecklistCreateForm.controls.fuelTankQuantity.value;
    this.drillingCheckList.coreTrays.bq = this.drillingChecklistCreateForm.controls.coreTraysBq.value;
    this.drillingCheckList.coreTrays.hq = this.drillingChecklistCreateForm.controls.coreTraysHq.value;
    this.drillingCheckList.coreTrays.hq2 = this.drillingChecklistCreateForm.controls.coreTraysHq2.value;
    this.drillingCheckList.drillingConsumables.copperGreaseQuantity = this.drillingChecklistCreateForm.controls.copperGreaseQuantity.value;
    this.drillingCheckList.drillingConsumables.copperGreaseWeight.unit.id = this.unitEnum.killogram;
    this.drillingCheckList.drillingConsumables.copperGreaseWeight.measure = this.drillingChecklistCreateForm.controls.copperGreaseWeight.value;
    this.drillingCheckList.drillingConsumables.coreLifterSprings = this.drillingChecklistCreateForm.controls.coreLifterSprings.value;
    this.drillingCheckList.drillingConsumables.drillMud = this.drillingChecklistCreateForm.controls.drillMud.value;
    this.drillingCheckList.drillingConsumables.stopRings = this.drillingChecklistCreateForm.controls.stopRings.value;
    this.drillingCheckList.drillingTools.shiftingSpanner24 = this.drillingChecklistCreateForm.controls.shiftingSpanner24.value;
    this.drillingCheckList.drillingTools.shiftingSpanner48 = this.drillingChecklistCreateForm.controls.shiftingSpanner48.value;
    this.drillingCheckList.drillingTools.shiftingSpanner36 = this.drillingChecklistCreateForm.controls.shiftingSpanner36.value;
    this.drillingCheckList.drillingTools.parmalleeWrenches.toolBoxComplete.unit.id = this.unitEnum.set;
    this.drillingCheckList.drillingTools.parmalleeWrenches.bq.unit.id = this.unitEnum.set;
    this.drillingCheckList.drillingTools.parmalleeWrenches.nq.unit.id = this.unitEnum.set;
    this.drillingCheckList.drillingTools.parmalleeWrenches.hq.unit.id = this.unitEnum.set;
    this.drillingCheckList.drillingTools.parmalleeWrenches.bq.measure = this.drillingChecklistCreateForm.controls.parmalleeWrenchesBq.value;
    this.drillingCheckList.drillingTools.parmalleeWrenches.hq.measure = this.drillingChecklistCreateForm.controls.parmalleeWrenchesHq.value;
    this.drillingCheckList.drillingTools.parmalleeWrenches.nq.measure = this.drillingChecklistCreateForm.controls.parmalleeWrenchesNq.value;
    this.drillingCheckList.drillingTools.parmalleeWrenches.toolBoxComplete.measure = this.drillingChecklistCreateForm.controls.parmalleeWrenchesToolBoxComplete.value;
    this.drillingCheckList.mudPumpSpares.seals = this.drillingChecklistCreateForm.controls.mudPumpSparesSeals.value;
    this.drillingCheckList.mudPumpSpares.deliveryHoseAndConnections = this.drillingChecklistCreateForm.controls.mudPumpSparesDeliveryHoseAndConnections.value;
    this.drillingCheckList.mudPumpSpares.suctionSystem = this.drillingChecklistCreateForm.controls.mudPumpSparesSuctionSystem.value;
    this.drillingCheckList.mudPumpSpares.valves = this.drillingChecklistCreateForm.controls.mudPumpSparesValves.value;
    this.drillingCheckList.drillingString.coreBarrelsAssembly= this.drillingChecklistCreateForm.controls.coreBarrelsAssembly.value;
    this.drillingCheckList.drillingString.drillBit.bq= this.drillingChecklistCreateForm.controls.drillBitsBq.value;
    this.drillingCheckList.drillingString.drillBit.hq= this.drillingChecklistCreateForm.controls.drillBitsNq.value;
    this.drillingCheckList.drillingString.drillBit.nq= this.drillingChecklistCreateForm.controls.drillBitsHq.value;
    this.drillingCheckList.drillingString.drillRodsSize.unit.id = this.unitEnum.meter;
    this.drillingCheckList.drillingString.drillRodsSize.measure = this.drillingChecklistCreateForm.controls.drillRodsSize.value;
    this.drillingCheckList.drillingString.waterSwivel= this.drillingChecklistCreateForm.controls.waterSwivel.value;
    this.drillingCheckList.drillRig.oils.engine.unit.id = this.unitEnum.liter;
    this.drillingCheckList.drillRig.oils.engine.measure= this.drillingChecklistCreateForm.controls.engine.value;
    this.drillingCheckList.drillRig.oils.gearBox.unit.id = this.unitEnum.liter;
    this.drillingCheckList.drillRig.oils.gearBox.measure= this.drillingChecklistCreateForm.controls.gearBox.value;
    this.drillingCheckList.drillRig.oils.hydraulic.unit.id = this.unitEnum.liter;
    this.drillingCheckList.drillRig.oils.hydraulic.measure= this.drillingChecklistCreateForm.controls.hydraulic.value;
    this.drillingCheckList.drillRig.rigSpares.fanBelt= this.drillingChecklistCreateForm.controls.fanBelt.value;
    this.drillingCheckList.drillRig.rigSpares.fuelFilterQuantity = this.drillingChecklistCreateForm.controls.fuelFilterQuantity.value;
    this.drillingCheckList.drillRig.rigSpares.hydraulicFilter= this.drillingChecklistCreateForm.controls.hydraulicFilter.value;
    this.drillingCheckList.drillRig.rigSpares.oilFilter= this.drillingChecklistCreateForm.controls.oilFilter.value;
    this.drillerService.createDrillingChecklist(this.drillingCheckList).subscribe(value => {
    }
    ,error => {
      this.errorsmsg('Drilling Checklist Create',error.error.message);
      },
      () => {
       this.successmsg('Drilling Checklist Create','Success');
       this.dialogRef.close(this.drillingCheckList);
      }
    );
}


  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }

}
