import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Remove edit functionality on Activities:
 *
 * If the activity currentState is either 'PENDING_QA_SUBMISSION' or 'REJECTED_BY_QA' or REJECTED_BY_MANAGER'
 * then the templated elements (edit button) will be removed from the DOM
 *
 * e.g
 * <span *canEditDrillingRequestActivity="drillingRequest.currentState.currentStage" >
 *   <a mat-icon-button *ifCreator="infiltrationTest.creator" (click)="openInfiltrationTestForEdit(infiltrationTest)" matTooltip="edit">
 *    <i class="edit icon bgi-golden-brown-txt"></i>
 *   </a>
 * </span>
 *
 */
@Directive({selector: '[canEditDrillingRequestActivity]'})
export class CanEditDrillingRequestActivityDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
  }

  @Input() set canEditDrillingRequestActivity(currentState: string) {
    if ((currentState === 'PENDING_MANAGER_SUBMISSION') || (currentState === 'REJECTED_BY_MANAGER')) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
