import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Activity } from '../../../../activities/models';
import { MatPaginator } from '@angular/material/paginator';
import { State } from '../../../../activities/models/state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mineral-resource-borehole-table',
  templateUrl: './mineral-resource-borehole-table.component.html',
  styleUrls: ['./mineral-resource-borehole-table.component.scss']
})
export class MineralResourceBoreholeTableComponent implements OnInit {

  loading = true;
  state = new State();
  displayedColumns: Array<string> = ['number', 'activities', 'status', 'date'];
  public _dataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  @ViewChild(MatPaginator) paginator: MatPaginator;


  get dataSource(): MatTableDataSource<Activity> {
    this.loading = false;
    return this._dataSource;

  }

  @Input('datasource') set dataSource(val: MatTableDataSource<Activity>) {
    this._dataSource = val;
    if (this._dataSource) {
      this.loading = false;
      this._dataSource.paginator = this.paginator;
    }
  }

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  gotoActivity(activityId){
    const id = {id: activityId};
    this._router.navigate(['portal/applied-geoscience/geo-tech/borehole/details', {id: activityId, type: 'BGI'}]);
  }

  applyFilter(filterValue: string) {
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }

}
