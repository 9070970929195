export class Roles {

  constructor() {
  }

  public static readonly SYSTEM_ADMIN = 'System Admin';
  public static readonly SEISMOLOGIST = 'Seismologist';
  public static readonly GEOLOGIST = 'Geologist';
  public static readonly NONBGIQA = 'Non BGI QA';
  public static readonly NONBGIMANAGER = 'Non BGI Manager';
  public static readonly QA = 'quality assurance officer';
  public static readonly MANAGER = 'manager';
  public static readonly ARCHIVIST = "Archivist";
  public static readonly SENIOR_ARCHIVIST = "Senior Archivist";
  public static readonly CURATOR = "Curator";
  public static readonly SENIOR_CURATOR = "Senior Curator";
}
