import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {UnitEnum} from '../../../../../shared-kernel/enumerations/unit-enum';
import {ActivatedRoute, Router} from '@angular/router';
import {HydrogeologyService} from '../../../hydrogeology.service';
import {ToastrService} from 'ngx-toastr';
import {HydrogeologyValidation} from '../../../hydrogeology.validation';
import {Borehole} from '../../../../../shared-kernel/entity/common/borehole';
import {WaterPumpingTestCalibration} from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestCalibration';
import { WaterPumpingTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTest';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {WaterPumpTestCalibrationCreateComponent} from '../water-pump-test-calibration-create/water-pump-test-calibration-create.component';
import Swal, {SweetAlertOptions} from "sweetalert2";
import { DrillingProtocol } from '../../../../../shared-kernel/entity/driller/DrillingProtocol';
import { Size } from '../../../../../shared-kernel/entity/common/size';
import { Unit } from '../../../../../shared-kernel/entity/common/unit';
import { DrillingMethod } from '../../../../../shared-kernel/entity/driller/drilling-method';
import { ExportToCsv } from 'export-to-csv';
declare var $: any;

@Component({
  selector: 'app-water-pump-test-calibration-list',
  templateUrl: './water-pump-test-calibration-list.component.html',
  styleUrls: ['./water-pump-test-calibration-list.component.scss']
})
export class WaterPumpTestCalibrationListComponent implements OnInit {

  dataSourceWaterPumpingTestCalibration: MatTableDataSource<WaterPumpingTestCalibration> = new MatTableDataSource();
  displayedWaterPumpingTestCalibrationColumns = ['No', 'StepNumber', 'Number of Logs', 'Depth to Water', 'Draw Down', 'Pumping Rate', 'view', 'edit', 'delete'];
  csvCalibrationsColumns =  ['No', 'StepNumber', 'Number of Logs', 'Depth to Water', 'Draw Down', 'Pumping Rate','delete'];
  loading:boolean = true;
  waterPumpingTestCalibrations: WaterPumpingTestCalibration[] = [];
  csvDataSource: MatTableDataSource<WaterPumpingTestCalibration> = new MatTableDataSource();
  boreholeId: any;
  @Input('borehole') borehole: Borehole = new Borehole();
  @Input('currentState') currentState: any;
  @Input('projectId') projectId: string = '';
  csvRecords: DrillingProtocol[] = [];
  @ViewChild('fileImportInput') fileImportInput: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  url: string;

  urlData = {waterPumpingTestId: null, boreholeId: null};
  // loading = true;
  pumpingRate: number;

  constructor(
    private router: Router,
    private routerActivated: ActivatedRoute,
    private dialog: MatDialog,
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private hydrogeologyValidation: HydrogeologyValidation,
    private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    this.getAllWaterPumpingTestCalibrationByWaterPumpingTestId();

  }

  getAllWaterPumpingTestCalibrationByWaterPumpingTestId(){
    this.spinner.show();
    this.hydrogeologyService.getAllWaterPumpingTestCalibrationByBoreholeId(this.borehole.id).subscribe(value => {
      this.dataSourceWaterPumpingTestCalibration.data = value as WaterPumpingTestCalibration [];
      this.dataSourceWaterPumpingTestCalibration.paginator = this.paginator;
      this.dataSourceWaterPumpingTestCalibration.sort = this.sort;
      this.loading = false;
      this.spinner.hide();
    }, error => {
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error, 'Error loading WaterPumpingTestsCalibration');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceWaterPumpingTestCalibration.filter = filterValue.trim().toLowerCase();
  }


  onCreate(){
    const newWaterPumpTestCalibrationDialogRef = this.dialog.open(WaterPumpTestCalibrationCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: {entity: this.borehole}
    });
    newWaterPumpTestCalibrationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllWaterPumpingTestCalibrationByWaterPumpingTestId();
      }
    });
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileChangeListener($event: any): void {
    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }



  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.csvDataSource = new MatTableDataSource<WaterPumpingTestCalibration>();
    this.resetPaginator();
  }

  resetPaginator(){

  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        if(this.dataSourceWaterPumpingTestCalibration.data.length<1 || this.dataSourceWaterPumpingTestCalibration.data.filter(value => value.stepNumber === Number(data[0])).length<1){
          const calibrationTemp = new WaterPumpingTestCalibration();
          calibrationTemp.stepNumber = Number(data[0]);
          calibrationTemp.noOfLogs = Number(data[1]);
          calibrationTemp.depthToWater = new Size();
          calibrationTemp.depthToWater.measure = data[2];
          calibrationTemp.depthToWater.unit = new Unit();
          calibrationTemp.depthToWater.unit.id = UnitEnum.meter;
          calibrationTemp.drawdown = new Size();
          calibrationTemp.drawdown.measure = data[3];
          calibrationTemp.drawdown.unit = new Unit();
          calibrationTemp.drawdown.unit.id = UnitEnum.meter;
          calibrationTemp.pumpingRate = new Size();
          calibrationTemp.pumpingRate.measure = data[4];
          calibrationTemp.pumpingRate.unit = new Unit();
          calibrationTemp.pumpingRate .unit.id = UnitEnum.pumpRate;

          calibrationTemp.borehole = new Borehole();
          calibrationTemp.borehole = this.borehole;

          dataArr.push(calibrationTemp);
        }else{
          this.toastr.warning('Step Number < '+Number(data[0])+' > already Exists','Calibration Import')
        }

      }
    }
    this.csvDataSource.data = dataArr;
    return dataArr;
  }

  showCsvModal() {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  deleteCsvRecord(calibrationRecord : WaterPumpingTestCalibration){


    this.csvDataSource.data.filter(value => value.pumpingRate.measure != calibrationRecord.pumpingRate.measure &&
      value.drawdown.measure != calibrationRecord.drawdown.measure &&
      value.depthToWater.measure != calibrationRecord.depthToWater.measure &&
      value.stepNumber != calibrationRecord.stepNumber)
  }

  //  open details
  openDetails(waterPumpTestCalibrationId){
    const urlParams = { id: waterPumpTestCalibrationId };
    this.router.navigate(['/portal/applied-geoscience/hydrogeology/water-pump-test/calibration/view', urlParams]);
  }


  // on edit
  onEdit(waterPumpTestCalibration){
    const newWaterPumpTestCalibrationDialogRef = this.dialog.open(WaterPumpTestCalibrationCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: {waterPumpTestCalibration: waterPumpTestCalibration, entity: this.borehole}
    });
    newWaterPumpTestCalibrationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllWaterPumpingTestCalibrationByWaterPumpingTestId();
      }
    });
  }

  onDelete(waterPumpingTestCalibration: WaterPumpingTestCalibration) {

    Swal.fire(<SweetAlertOptions>{
      title: 'Delete Calibration Test',
      type: 'warning',
      html: `Are you sure you want to <b>Delete</b> this Test?`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(result => {
      if (result && result.value === true) {
        this.hydrogeologyService.deleteWaterPumpingTestCalibrationOne(waterPumpingTestCalibration).subscribe(
          value => {
            this.getAllWaterPumpingTestCalibrationByWaterPumpingTestId();
            this.toastr.success('Calibration test Deleted Successfully', 'Calibration Test Delete');
          }
          , error => {
            this.toastr.error(error, 'Delete Calibration Test');
          });
      }
    });
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestCalibrationBulk(this.dataSourceWaterPumpingTestCalibration.data).subscribe(() => {
              this.getAllWaterPumpingTestCalibrationByWaterPumpingTestId();
              this.toastr.success('Delete Water Pumping Tests', 'You have Successfully deleted Water Pumping Tests');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });
  }


  saveBatch() {
    this.spinner.show();
    this.waterPumpingTestCalibrations = this.csvDataSource.data as [];
    this.hydrogeologyService.createWaterPumpingTestCalibration(this.waterPumpingTestCalibrations).subscribe(
      value => {
        this.toastr.success('Saved successfully','Calibration Import');
        $('#csvModal').modal('hide');
        this.spinner.hide();
        this.getAllWaterPumpingTestCalibrationByWaterPumpingTestId();
      }
      , error => {
        this.toastr.error('Unsuccessful','Calibration Import');
      }
      , () => {

      }
    );

  }

  exportCSV(){

    const csv_datatest = [];
    csv_datatest.push({
      'Step Number': '',
      'No Of Logs': '',
      'Depth To Water': '',
      'Draw Down':'',
      'Pumping Rate':'',
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csv_datatest);
  }




}
