import { PeopleService } from './../../../../../_core/mock/people.service';
import { WaterPumpTestStepTestCreateComponent } from './../water-pump-test-step-test-create/water-pump-test-step-test-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { WaterPumpingTestStepTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestStepTest';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GeoTechService } from 'src/app/applied-geoscience/geo-tech/geo-tech.service';

@Component({
  selector: 'app-water-pump-test-step-test-view',
  templateUrl: './water-pump-test-step-test-view.component.html',
  styleUrls: ['./water-pump-test-step-test-view.component.scss']
})
export class WaterPumpTestStepTestViewComponent implements OnInit {

  waterPumpingTestStepTest: WaterPumpingTestStepTest;
  boreholeId: string;
  waterPumpingTestId: string;
  boreholeNumber: string;
  expanded: boolean = true;
  borehole: Borehole;
  geologist: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private hydrogeologyService: HydrogeologyService,
    private geotechService: GeoTechService,
    private dialog: MatDialog,
    private peopleService: PeopleService,) {
      this.route.params.subscribe(params => {
        this.boreholeId = params.boreholeId;
        this.waterPumpingTestId = params.waterPumpingTestId;
        this.boreholeNumber = params.boreholeNumber;
      });
  }

  ngOnInit() {
    this.getWaterPumpingTestStepTestById();
    this.getBoreholeById();
  }

  getWaterPumpingTestStepTestById() {
    this.hydrogeologyService.getWaterPumpingTestStepTestById(this.waterPumpingTestId).subscribe(data => {
      this.waterPumpingTestStepTest = data as WaterPumpingTestStepTest;
      this.peopleService.getPersonById(this.waterPumpingTestStepTest.geologist).subscribe(
        person => {
          if(person){
            this.geologist = person.name +' '+ person.surname;
          }
        }
      );
    }, err => {
        this.toastr.error(err.message, "Failed Delete");
    });

  }

  getBoreholeById() {
    this.geotechService.getBoreholeById(this.boreholeId)
      .subscribe(data => {
        this.borehole = data as Borehole;
      });
  }

  openWaterPumpingTestEditForm(waterPumpingTestStepTest: any) {
    const obj = {test: waterPumpingTestStepTest, borehole:this.borehole, transactionType:'Edit'};
    const newGeoHeritageDialogRef = this.dialog.open(WaterPumpTestStepTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getWaterPumpingTestStepTestById()
    });
  }

  cancelTestView() {
    const idObject = {id: this.boreholeId};
    this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', idObject]);
  }

  redirect(){
    this.router.navigate(['/portal/applied-geoscience/geo-tech/water-borehole/list']);
  }

}
