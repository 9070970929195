import {PeopleService} from './../../../../_core/mock/people.service';
import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatAutocomplete,
  MatDialogConfig,
  MatChipList,
  MatDialog
} from '@angular/material';
import {Borehole} from '../../../../shared-kernel/entity/common/borehole';
import {GeoTechService} from '../../geo-tech.service';
import {BoreholeType} from 'src/app/shared-kernel/entity/common/boreholeType';
import {Person} from 'src/app/common/entity/security/profile/person';
import jsPDF from 'jspdf';
import {ExportToCsv} from 'export-to-csv';
import {ToastrService} from 'ngx-toastr';
import {NigisImages} from 'src/app/shared-kernel/entity/common/nigisImages';
import {CoordinatesTypesEnum} from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import {CoordinatesFormatsEnum} from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import {BoreholeFilterEnum} from '../../../../shared-kernel/enumerations/borehole-filter-enum';
import {User} from '../../../../_core/data/_models/people.model';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Company} from 'src/app/shared-kernel/entity/applied-geoscience/company/company';
import {Observable, of} from 'rxjs';
import {Project} from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import {Village} from 'src/app/shared-kernel/entity/common/Village';
import {map, startWith} from 'rxjs/operators';
import {CommonService} from 'src/app/common/common.service';
import {CompanyService} from 'src/app/applied-geoscience/company/company.service';
import {ProjectService} from 'src/app/applied-geoscience/project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { BoreholeSearchCriteria } from 'src/app/shared-kernel/entity/common/BoreholeSearchCriteria';
import { BoreholeCreateComponent } from '../borehole-create/borehole-create.component';
import { BoreholeTypeEnum } from "src/app/shared-kernel/enumerations/borehole-type-enum";

declare var $: any;

@Component({
  selector: 'app-borehole-list',
  templateUrl: './borehole-list.component.html',
  styleUrls: ['./borehole-list.component.scss']
})
export class BoreholeListComponent implements OnInit, AfterViewInit {

  dataSourceBoreholes: MatTableDataSource<Borehole> = new MatTableDataSource();
  displayedBoreholeColumns: string[] = []; 

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild("sort") sort: MatSort;

  @ViewChild('boreholeLocationImageInput') boreholeLocationImageInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  borehole: Borehole;
  title: string = '';
  boreholes: Borehole[];
  boreholeFilterEnum: typeof BoreholeFilterEnum = BoreholeFilterEnum;
  loading = true;
  boreholeTypes: BoreholeType[];
  boreholeLocationImages: NigisImages[];
  firstLinkColor = '';
  secondLinkColor = 'teal';
  secondLinkActive = 'active left pointing';
  firstLinkActive = '';
  exportType: string;
  saveAsName: string;
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  showSearchIcon = false;
  searchForm: FormGroup;
  companies: any[];
  selectedSection: string;
  projects: any[] = [];
  villages: any[];

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;

  targetValues: any[] = []
  filteredTargetValues: Observable<any[]>;

  public currentUser: User;
  public currentPerson: Person;

  stillFetchingCompany = false;
  stillFetchingProject = false;
  stillFetchingVillage = false;
  stillFetchingIndividualOwner = false;

  dataFetched = undefined;

  totalElements = 0;
  pageIndex = 0;
  pageSize = 10;
  filterControl = new FormControl('')
  target: string = ''
  targetValue: string = ''

  type: string;
  boreholeByNumberFunction: any;
  boreholeByProjectNameFunction: any;
  boreholeByVillageFunction: any;
  boreholeByCompanyNameFunction: any;

  addAllowedRoles = []
  newBoreholeCreation: boolean = false;
  criteria: BoreholeSearchCriteria = new BoreholeSearchCriteria();

  constructor(
    private geoTechService: GeoTechService,
    private router: Router,
    private toast: ToastrService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private peopleService: PeopleService,) {

  }

  async ngOnInit() {

    this.initForms();

    this.route.queryParams
      .subscribe(params => {
        this.type = params.type;

        this.title = `${this.type} Boreholes`;

        if(this.type === 'Water'){

          this.boreholeByNumberFunction = this.geoTechService.getWaterBoreholesByBoreholeNumber;
          this.boreholeByProjectNameFunction = this.geoTechService.getWaterBoreholesByProjectName;
          this.boreholeByVillageFunction = this.geoTechService.getWaterBoreholesByVillage;

          this.addAllowedRoles = ['Technician Mineral Resources','hydrogeologist','system admin','Archivist','Senior Archivist','Geochemist', 'Economic Geologist','Senior Geochemist', 'Senior Economic Geologist']
          this.displayedBoreholeColumns = ['#', 'Borehole Number', 'Project Name', 'Borehole Owner', 'Project Company' ,'Location', 'Drilling Date', 'View', 'Edit'];

        } else if(this.type === 'PL'){

          this.boreholeByNumberFunction = this.geoTechService.getPLBoreholesByBoreholeNumber;
          this.boreholeByCompanyNameFunction = this.geoTechService.getPLBoreholesByCompanyName;
          this.boreholeByVillageFunction = this.geoTechService.getPLBoreholesByVillage;

          this.addAllowedRoles = ['Geologist','system admin','Archivist','Senior Archivist'];
          this.displayedBoreholeColumns = ['#', 'Borehole Number', 'PL Number' ,'Location', 'Drilling Date', 'View', 'Edit'];

        } else if(this.type === 'BGI'){
          this.boreholeByNumberFunction = this.geoTechService.getBGIBoreholesByBoreholeNumber;
          this.boreholeByProjectNameFunction = this.geoTechService.getBGIBoreholesByProjectName;
          this.boreholeByVillageFunction = this.geoTechService.getBGIBoreholesByVillage;

          this.addAllowedRoles = ['Technician Mineral Resources','Geologist','system admin','Archivist','Senior Archivist','Geochemist', 'Economic Geologist','Senior Geochemist', 'Senior Economic Geologist']
          this.displayedBoreholeColumns = ['#', 'Borehole Number', 'Project Name', 'Project Company' ,'Location', 'Drilling Date', 'View', 'Edit'];

        } ;
      }
    );

    this.getCompanies();
    this.dataFetched = await this.getCompanies();
    
    this.boreholeLocationImages = [];
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    
    this.initList();
    this.getAllProjects();
    this.getAllVillages();

  }

  onSectionChange() {
    this.selectedSection = this.searchForm.get('searchTarget').value;

    if(this.selectedSection === 'BOREHOLE_NUMBER' || this.selectedSection === 'PROSPECTING_LICENCE_NUMBER') {

      this.targetValues = [];
      this.filteredTargetValues = of([]);

    } else if(this.selectedSection === 'PROJECT_NAME') {

      this.targetValues = this.projects;
      this.filteredTargetValues = of(this.projects);

    } else if(this.selectedSection === 'COMPANY_BOREHOLE_OWNER_NAME') {

      this.targetValues = this.companies;
      this.filteredTargetValues = of(this.companies);

    } else if(this.selectedSection === 'VILLAGE_NAME') {

      this.targetValues = this.villages;
      this.filteredTargetValues = of(this.villages);
    }
  }

  initList() {
    this.searchForBorehole();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe((pg) => {

      this.pageIndex = pg.pageIndex;
      this.pageSize = pg.pageSize;

      this.searchForBorehole();
    })

    this.filterControl.valueChanges.subscribe((value) => {
      this.criteria.criteria = value;
    });

    this.searchForm.get('searchValue').valueChanges.subscribe((value) => {
      this.filteredTargetValues = of(this._filterValues(value));
    });
  }

  filter(filterValue: string) {
    if (filterValue === this.boreholeFilterEnum.myBoreholes) {
      this.title = this.boreholeFilterEnum.myBoreholes;
      this.firstLinkColor = 'teal';
      this.secondLinkColor = '';
      this.firstLinkActive = 'active left pointing';
    }

    if (filterValue === this.boreholeFilterEnum.allBoreholes || !filterValue) {
      this.initList();
      this.secondLinkColor = 'teal';
      this.firstLinkColor = '';
      this.secondLinkActive = 'active left pointing';
    }
  }

  getBoreholePLName(prospectingLicense): string {
    return '';
  }

  getBoreholesTopTen(criteria: BoreholeSearchCriteria) {
    this.spinner.show();

    this.geoTechService.searchBoreholesPagedMinimal(criteria, this.pageIndex, this.pageSize)
    .subscribe(dataPage => {
      
      this.boreholes = dataPage.content as Borehole[];
        this.boreholes.forEach(borehole => {
          if (borehole.boreholeOwnerIndividual) {
            this.stillFetchingIndividualOwner = true;
            this.peopleService.getPersonById(borehole.boreholeOwnerIndividual).subscribe(data => {
              let ownerIndividual = data as Person;
              let firstName = ownerIndividual.name;
              let middleName = ownerIndividual.middleName ? ownerIndividual.middleName : '';
              let surname = ownerIndividual.surname;
              borehole.boreholeOwnerIndividualFullName = firstName + ' ' + middleName + ' ' + surname;
              this.stillFetchingIndividualOwner = false;
            });
          }
        })

        this.totalElements = dataPage.totalElements;
        this.dataSourceBoreholes = new MatTableDataSource(dataPage.content as Borehole[]); 

        this.dataSourceBoreholes.sort = this.sort;
        this.loading = false;
        this.spinner.hide();
    },
    error => {
      this.loading = false;
      this.toast.error(error.message, `${this.type} borehole`);
      this.toast.error('Failled to load data:' + error.message.error, `${this.type} borehole`)
      this.spinner.hide();
    },
    () => {
      this.loading = false;
      this.spinner.hide();
    });

    
  }

  openBoreholeDetails(boreholeId) {

    if(this.type === 'Water') {
      this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', {id: boreholeId, type: this.type}]);
    } else if(this.type === 'PL') {
      
      this.router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/details', { id: boreholeId, type: this.type }]);
    } else if(this.type === 'BGI') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/details', {id: boreholeId, type: this.type}]);
    }
    
  }

  remove(index) {
    this.criteria.targets.splice(index, 1);
    this.criteria.targetValues.splice(index, 1);
  }

  sanitizeList() {
    this.boreholes.forEach(element => {
      element.project = null;
      element.rigType = null;
      element.drillingMethod = null;
      element.descriptionOfDrillingFluidsUsed = null;
      element.boreholeCoreRecoveryLogs = null;
      element.boreholeLogRecords = null;
      element.waterBoreholeLithologyLogs = null;
      element.timeAndMotions = null;
      element.waterBoreholeDrillersLogs = null;
      element.waterPumpingTests = null;
      element.constructionScreenings = null;
      element.constructionCasings = null;
      element.drillingProtocols = null;
      element.waterBearingZonesLogs = null;
      element.corePicture = null;
      element.boreholeLithologyLogs = null;
      element.boreholeCoreRecoveryLogs = null;
      element.boreholeFiles = null;
      element.elevation = null;
      element.endDepth = null;
      element.plannedDepth = null;
      element.elevation = null;
      element.boreholeType = null;
    });
  }

  navigateToMap() {
    this.sanitizeList();
    if (this.boreholes.length > 0) {
      localStorage.setItem('list', JSON.stringify(this.boreholes));
      const from = {list: 'water'};
      this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/locations', from]);
    } else {
      this.toast.warning('Please make sure that Borehole list is not Empty', 'Plot To Map');
    }

  }

  export(expType: string) {
    this.exportType = expType;
    $('#saveAs').modal('show');
  }

  saveAs() {

    this.exportToFile();

    $('#saveAs').modal('hide');
  }

  exportToPDF(data: any[]) {

    const columns = [
      {title: '#', dataKey: 'id'},
    ];

    let item = data[0];

    Object.keys(item).forEach(key => {
      columns.push({title: key, dataKey: key})
    })

    let tmp = data.map((item, index) => {
      item['id'] = index+1
      return item
    })
    
    const doc = new jsPDF('l', 'pt', 'a2');
    doc.autoTable(columns, data, {
      styles: {fontSize: 7},
      margin: {top: 60},
      beforePageContent: function (data) {
        doc.text(`${this.type} Borehole List`, 40, 30);
      }
    });

    doc.save(this.saveAsName + '.pdf');
  }

  openBoreholeEditDialog(boreholeId?: string) {

    let dialogData = {
      borehole: undefined,
      title: `${boreholeId ? 'Edit' : 'Create'} ${this.type} Borehole`,
      boreholeType: this.type === 'Water' ? BoreholeTypeEnum.Water : BoreholeTypeEnum.Exploration,
      depthOfSurfaceCasingLabel: this.type === 'Water' ? 'Top of Casing (TOC)' : 'Depth of Surface Casing',
      disableOwner: this.type != 'Water',
      type: this.type,
      visibleFields: {
        prospectingLicense: this.type === 'PL',
        developmentType: this.type !== 'BGI',
        descriptionOfDrillingFluidsUsed: this.type === 'Water',
        depthOfSurfaceCasing: true,
        boreholeOwner: this.type === 'Water',
        staticWaterLevel: this.type === 'Water',
        samplesCollected: this.type === 'PL',
        variance: true,
        plannedDepth: true,
        overburdenThickness: this.type != 'Water',
        drillRigType: this.type != 'Water',
        waterStrike: this.type === 'BGI',
        estimatedYield: this.type === 'Water',
      }
    }

    if(boreholeId){
      this.geoTechService.getBoreholeById(boreholeId).subscribe(data => {
        dialogData['borehole'] = data as Borehole;
        this.doOpenBoreholeEditDialog(dialogData);
      });
    } else {
      this.doOpenBoreholeEditDialog(dialogData);
    }
  }

  private doOpenBoreholeEditDialog(dialogData) {
    const newDCPDialogRef = this.dialog.open(BoreholeCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: dialogData
    });

    newDCPDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.initList();
      }
    });
  }

  exportToFile() {
    this.geoTechService.searchBoreholes(this.constructCriteria()).subscribe((boreholes: Borehole[])=> {

      const data = [];
      boreholes.forEach(borehole => {

        let tmp = {};

        if(this.type != 'PL') {
          tmp['Project Name'] = borehole.project ? borehole.project.name : '';
        }

        tmp['Borehole Owner'] = borehole.boreholeOwner ? borehole.boreholeOwner.name : '';
        tmp['Borehole Number'] = borehole.boreholeNumber;

        if(borehole.prospectingLicense) {
          tmp['Prospecting Licence Number'] = borehole.prospectingLicense.prospectingLicenseNumber;
        }
        tmp['Borehole Type'] = borehole.boreholeType ? borehole.boreholeType.name : '';

        if(borehole.locationTemp) {
          tmp['Location'] = borehole.locationTemp.village.name;
          tmp['Coordinates'] = borehole.locationTemp.coordinates ? (`Latitude: ${borehole.locationTemp.coordinates.latitude} , Longitude: ${borehole.locationTemp.coordinates.longitude}`) : '';
        }

        tmp['Objective'] = borehole.boreholeDescription ? borehole.boreholeDescription.description : '';
        tmp['Diameter'] = borehole.holeDiameter ? borehole.holeDiameter.measure : '';
        tmp['Drilling Method'] = borehole.drillingMethod ? borehole.drillingMethod.name : '';
        tmp['Planned Depth'] = borehole.plannedDepth ? borehole.plannedDepth.measure : '';
        tmp['End of Hole'] = borehole.endDepth ? borehole.endDepth.measure : '';
        tmp['Azimuth'] = borehole.azimuth ? borehole.azimuth : '';
        tmp['Elevation'] = borehole.elevation ? borehole.elevation.measure : '';
        tmp['Inclination'] = borehole.holeInclination ? borehole.holeInclination : '';

        if(borehole.overburdenThickness) {
          tmp['Overburden Thickness'] = borehole.overburdenThickness;
        }

        tmp['Drilling Sub-Contractor'] = borehole.driller ? borehole.driller : '';

        if(borehole.waterStrike) {
          tmp['Water Strike'] = borehole.waterStrike.measure;
        } else {
          tmp['Water Strike'] = '';
        }

        if(borehole.sampleTypes) {
          tmp['Sample Type'] = borehole.sampleTypes.map(x => x.name).join(",");
        }

        if(borehole.depthOfSurfaceCasing) {
          tmp['Depth of Surface Casing'] = borehole.depthOfSurfaceCasing.measure;
        }

        data.push(tmp);
      });

      if(this.exportType === 'pdf') {
        this.exportToPDF(data)
      } else {
        this.exportToCSV(data)
      }
        
    })
  }

  exportToCSV(data: any[]) {
    
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `${this.type} Borehole List`,
      useTextFile: false,
      useBom: true,
      filename: this.saveAsName,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  private constructCriteria(): BoreholeSearchCriteria {

    let criteria = new BoreholeSearchCriteria();

    criteria.targets = [...this.criteria.targets]; 
    criteria.targetValues = [...this.criteria.targetValues];

    if(this.type === 'Water') { 

      criteria.targets.push('BOREHOLE_TYPE'); 
      criteria.targetValues.push(this.type);

    }

    if(this.type === 'PL') {
      criteria.targets.push('IS_PROSPECTING_BOREHOLE');
      criteria.targetValues.push('True');
    }

    if(this.type === 'BGI') {
      criteria.targets.push('PROJECT_COMPANY_NAME'); 
      criteria.targetValues.push('Botswana Geoscience Institute');
    }

    if(this.filterControl.value && this.filterControl.value != '') {

      criteria.criteria = this.filterControl.value;
    }

    return criteria;
  }

  searchForBorehole() {

    this.getBoreholesTopTen(this.constructCriteria());
  }

  private initForms() {

    this.searchForm = this.formBuilder.group({
      searchTarget: [''],
      searchValue: ['']
    });
  }

  addSearchCriteria() {
    
    let search = this.searchForm.value;

    if(search.searchValue && search.searchValue != '') {
      this.criteria.targets.push(search.searchTarget);
      this.criteria.targetValues.push(search.searchValue);
    }

    this.searchForm.get('searchValue').setValue('');
    this.searchForm.get('searchTarget').setValue('');

    this.selectedSection = undefined;

  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = (data as Village[]).map(c => {
          return {
            id: c.id,
            name: c.name
          }
        });

        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      });
  }

  getCompanies() {
    this.companyService.getAllCompaniesLite()
      .subscribe(data => {
        this.companies = (data as Company[]).map(c => {
          return {
            id: c.id,
            name: c.name
          }
        });
        this.companies.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      });
  }

  private _filterValues(name: string): Company[] {
    const filterValue = name.toLowerCase();
    return this.targetValues.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getAllProjects() {
    this.spinner.show();
    this.projectService.getAllProjectName()
      .subscribe(data => {
        this.projects = (data as []).map(c => {
          return {
            id: c['id'],
            name: c['name']
          }
        });
        this.initList();
      });
  }
}
