import { StorageConstants } from 'src/app/_core/storage.constants';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { LocalAuthorities } from 'src/app/_auth/_providers';
import { GlobalDashboardService } from './../../global-dashboard.service';
import { Roles } from 'src/app/shared-kernel/entity/Roles';
import { ManagerDashboardCountModel } from './../../model/manager-dasboard-count.model';
import { VettingPortfolioCounts } from './../../model/VettingPortfolioCounts';
import { BusinessUnitEnum } from './../../../shared-kernel/enumerations/business-unit-enum';
import { User, Person } from 'src/app/_core/data/_models/people.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-activities-dashboard',
  templateUrl: './manager-activities-dashboard.component.html',
  styleUrls: ['./manager-activities-dashboard.component.scss']
})
export class ManagerActivitiesDashboardComponent implements OnInit {

  managerDashboardCountModel: ManagerDashboardCountModel ;
  nonbgimanagerDashboardCountModel: VettingPortfolioCounts ;

  stillLoadingActivities = true;

  currentUser: any;
  currentPerson: any;

    constructor(
      private globalDashboardService: GlobalDashboardService,
      private _localAuthorities: LocalAuthorities,
      private _localStorage: LocalStorage,
      private _toastr: ToastrService,
    ) {

    }

    ngOnInit() {
      this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
        this.currentUser = res;

        this.initPerson(res.id);
      }, err => {
        this._toastr.warning(err.message, "Current User Error");
      },() => {

      });
    }

    private initPerson(userId: string) {
      this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
        if (pRes) {
          this.currentPerson = pRes;
        }
      }, err => {
        this._toastr.error(err.message, "Profile Details");
      }, () => {
        (async () => {
          await this.getDashboardCounts(this.currentPerson);
        })();
      });
    }

    getDashboardCounts(current){
      this._localAuthorities.getFullUser(current.user.username).subscribe(
        value => {

          if(value.roles.find(x => x.name.toLowerCase() == Roles.NONBGIMANAGER.toLowerCase())){

            this.globalDashboardService.getNonBGIMangerDashboardCounts().subscribe(value => {
              this.nonbgimanagerDashboardCountModel = value as VettingPortfolioCounts ;
              this.stillLoadingActivities = false;
            });
          }

          if(value.roles.find(x => x.name.toLowerCase() == Roles.MANAGER.toLowerCase())){
            this.globalDashboardService.getMangerDashboardCounts().subscribe(value => {
              this.managerDashboardCountModel = value as ManagerDashboardCountModel ;
              this.stillLoadingActivities = false;
            });
          }

        }
      )
    }


}
