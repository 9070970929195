import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { DcpFoundationData } from "./../data/dcp-foundation";

import { DynamicConePenetrometerTestLog } from '../data/_models/dcp-foundation.models';

@Injectable()
export class DcpFoundationService extends DcpFoundationData {
    constructor(
        private http: HttpClient
    ){
        super();
    }

    public addTestPoint(foundationId: string, testPoint: DynamicConePenetrometerTestLog): Observable<DynamicConePenetrometerTestLog> {
        return this.http.post<DynamicConePenetrometerTestLog>(ServiceEndPoints.appliedGeoscienceBaseUrl + 
            `/api/geotech/dcpt/v1/foundation/test-log/create/${foundationId}`, testPoint);
    }

    public getTestPoints(testFoundationId: string): Observable<Array<DynamicConePenetrometerTestLog>> {
        return this.http.get<Array<DynamicConePenetrometerTestLog>>(ServiceEndPoints.appliedGeoscienceBaseUrl + 
            `/api/geotech/dcpt/v1/foundation/test-logs/all/${testFoundationId}`);
    }

    public deleteTestPoint(testPointId: string): Observable<DynamicConePenetrometerTestLog> {
        return this.http.delete<DynamicConePenetrometerTestLog>(ServiceEndPoints.appliedGeoscienceBaseUrl + 
            `/api/geotech/dcpt/v1/foundation/test-log/delete/${testPointId}`);
    }
    public addTestPointList(foundationId: string, testPointList: Array<DynamicConePenetrometerTestLog>): Observable<DynamicConePenetrometerTestLog> {
        return this.http.post<DynamicConePenetrometerTestLog>(ServiceEndPoints.appliedGeoscienceBaseUrl + 
            `/api/geotech/dcpt/v1/foundation/test-log/createList/${foundationId}`, testPointList);
    }
}