import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

import { UserDashboardService } from './user-dashboard.service';
import { Person } from '../../common/entity/security/profile/person';
import { UserService } from '../../auth/user/user.service';
import { State } from '../../activities/models/state';
import { UnitEnum } from '../../shared-kernel/enumerations/unit-enum';
import { ApprovalState } from '../../applied-geoscience/models';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {
  title = 'User Dashboard';
  numberOfNonBGIProjects: number;
  numberOfBGIProjects: number;
  numberOfMyProjects: number;
  numberOfProjectsActivitiesSubmittedToQA: number;
  numberOfProjectsActivitiesSubmittedToManager: number;
  numberOfProjectsActivitiesRejectedByQA: number = 0;
  numberOfProjectsActivitiesRejectedByManager: number = 0;
  numberOfProjectsActivitiesApproved: number;
  numberOfMyPLs: number;
  numberOfPLs: number;
  numberOfMyPlBoreholes:number;
  numberOfNonPlBoreholes:number;
  numberOfUserTrialPits:number;
  private userName: string;
  private userReference: string;
  private authorities;
  private roles;
  numberOfActivities: any;
  numberOfDrafts: any;
  person: Person;
  loading = true;
  myactivities = {
    numberOfMyProjects:0,
    numberOfBGIProjects:0,
    numberOfActivities:0,
    numberOfDrafts:0,
    numberOfProjectsActivitiesSubmittedToQA:0,
    numberOfProjectsActivitiesSubmittedToManager:0,
    approved:0,
    returned:0
  };

  myplactivities = {
    numberOfMyPl:0,
    numberOfAllPl:0,
    numberOfActivities:0,
    numberOfDrafts:0,
    numberOfPlActivitiesSubmittedToQA:0,
    numberOfPlActivitiesSubmittedToManager:0,
    approved:0,
    returned:0
  };

  myproject = {
    numberOfProjectsDrafts:0,
    numberOfProjectsSubmittedToQA:0,
    numberOfProjectsSubmittedToManager:0,
    numberOfProjectsApproved:0,
    numberOfProjectsReturned:0
  };

  mypl = {
    numberOfPlDrafts:0,
    numberOfPlSubmittedToQA:0,
    numberOfPlSubmittedToManager:0,
    numberOfPlApproved:0,
    numberOfPlReturned:0
  };

  projectPendingSubmissionCount = 0;
  projectSubmittedCount = 0;
  projectReturnedCount = 0;
  projectApprovedCount = 0;

  pendingCountStillLoading = true;
  submittedCountStillLoading = true;
  rejectedCountStillLoading = true;
  approvedCountStillLoading = true;

  approvalEnum: typeof ApprovalState = ApprovalState;

  state = new State();

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private userService: UserService) {

    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userReference = data.jti;
    this.authorities = data.userAuthorises;
    this.roles = data.roles;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
}

  ngOnInit() {



    this.getBGIProjectsCount();
    this.getMyProjectsCount();
    this.getPLsCount();
    this.getMyPLsCount();
    this.getMyPlBoreholesCount();
    this.getNonPlBoreholesCount();
    this.getUserTrialPitCount();
    this.getUserActivities();
    this.getSubmittedToQA();
    this.getSubmittedToManager();
    this.getActivitiesRejectedByQA();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
    this.getNonBGIProjectsCount();
    this.getUserDrafts();
    this.getPendingProjectCount();
    this.getSubmittedProjectCount();
    this.getRejectedProjectCount();
    this.getApprovedProjectCount();

  }

  getPendingProjectCount(){
    this.userDashBoardService.getUserProjectActivitesByStageCount('PENDING_MANAGER_SUBMISSION').subscribe(number => {
      this.projectPendingSubmissionCount = number;
      this.pendingCountStillLoading = false;
    });
  }

  getSubmittedProjectCount(){
    this.userDashBoardService.getUserProjectActivitesByStageCount('SUBMITTED_TO_MANAGER').subscribe(number => {
      this.projectSubmittedCount = number;
      this.submittedCountStillLoading = false;
    });
  }

  getRejectedProjectCount(){
    this.userDashBoardService.getUserProjectActivitesByStageCount('REJECTED_BY_MANAGER').subscribe(number => {
      this.projectReturnedCount = number;
      this.rejectedCountStillLoading = false;
    });
  }

  getApprovedProjectCount(){
    this.userDashBoardService.getUserProjectActivitesByStageCount('PUBLISHED').subscribe(number => {
      this.projectApprovedCount = number;
      this.approvedCountStillLoading = false;
    });
  }

  getMyProjectsCount() {
      this.userDashBoardService.getMyProjectCount().subscribe(number => {
        this.numberOfMyProjects = number;
        this.myactivities.numberOfMyProjects = this.numberOfMyProjects;
    });
  }
  getBGIProjectsCount() {
  this.userDashBoardService.getBGIProjectCount().subscribe(number => {
    this.numberOfBGIProjects = number;
    this.myactivities.numberOfBGIProjects = this.numberOfBGIProjects;
    });
  }

  getNonBGIProjectsCount() {
  this.userDashBoardService.getNonBGIProjectCount().subscribe(number => {
    this.numberOfNonBGIProjects = number;
    });
  }

  getPLsCount(){
    this.userDashBoardService.getPLsCount().subscribe(number => {
      this.numberOfPLs = number;
      this.myplactivities.numberOfAllPl = this.numberOfPLs;
    });
  }
  getMyPLsCount(){
    this.userDashBoardService.getMyPLsCount(this.userName).subscribe(number => {
      this.numberOfMyPLs = number;
      this.myplactivities.numberOfMyPl = this.numberOfMyPLs;
    });
  }

  getMyPlBoreholesCount(){
    this.userDashBoardService.getMyPlBoreholesCount(this.userName).subscribe(number => {
      this.numberOfMyPlBoreholes = number;
      this.myplactivities.numberOfActivities = number;
    });
  }

getNonPlBoreholesCount(){
  this.userDashBoardService.getNonPlBoreholesCount(this.userName).subscribe(number => {
    this.numberOfNonPlBoreholes = number;
  });
}

getUserTrialPitCount(){
    this.userDashBoardService.getUserTrialPitCount(this.userName).subscribe(number => {
      this.numberOfUserTrialPits = number;
  });
}

getUserActivities(){
  this.userDashBoardService.getMyActivitiesCount('all').subscribe(number => {
    this.numberOfActivities = number;
    this.myactivities.numberOfActivities = this.numberOfActivities;
  });
}

getUserDrafts(){
    this.userDashBoardService.getMyActivitiesCount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(number => {
      this.numberOfDrafts = number;
      this.myactivities.numberOfDrafts = this.numberOfDrafts;
    });
}

getSubmittedToQA(){
    this.userDashBoardService.getMyActivitiesCount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
      this.numberOfProjectsActivitiesSubmittedToQA = number;
      this.myactivities.numberOfProjectsActivitiesSubmittedToQA = this.numberOfProjectsActivitiesSubmittedToQA;
    });
}

  getSubmittedToManager(){
    this.userDashBoardService.getMyActivitiesCount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.numberOfProjectsActivitiesSubmittedToManager = number;
      this.myactivities.numberOfProjectsActivitiesSubmittedToManager = this.numberOfProjectsActivitiesSubmittedToManager;
    });
  }


  getActivitiesRejectedByQA(){
    this.userDashBoardService.getMyActivitiesCount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.numberOfProjectsActivitiesRejectedByQA = number;
      this.getActivitiesRejectedByManager();
    });
  }

  getActivitiesRejectedByManager(){
    this.userDashBoardService.getMyActivitiesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.numberOfProjectsActivitiesRejectedByManager = number;
      this.myactivities.returned = this.numberOfProjectsActivitiesRejectedByManager+this.numberOfProjectsActivitiesRejectedByQA;
    });
  }
  getApprovedActivities(){
    this.userDashBoardService.getMyActivitiesCount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.numberOfProjectsActivitiesApproved = number;
      this.myactivities.approved = this.numberOfProjectsActivitiesApproved;
    });
  }


goTo(type: string, filter: string) {
  this._router.navigate([`portal/activities/geologist/${type}/${filter}`]);
}

gotoProject(uri: string) {

    let urlParams: any;
    if('my_project' === uri){
      urlParams = { id: 'my_project' };
    }
    if('bgi_project' === uri){
      urlParams = { id: 'bgi_project' };
    }

    this._router.navigate([`portal/applied-geoscience/project/list`,urlParams]);
  }


gotoProspectingLicences(uri: string) {
  let urlParams: any;
  if('my_pl'=== uri){
    urlParams = { id: 'my_pl' };
  }
  else {
    urlParams= {};
  }

  this._router.navigate([`portal/applied-geoscience/prospecting-license/list`,urlParams]);
}

goToProjectActivities(uri: string){
  this._router.navigate([`portal/activities/user-project-activities/${uri}`]);
}

}
