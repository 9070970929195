import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { InventoryBoreholeDetails } from './../model/inventory-borehole-details.model';
import { Borehole } from './../../shared-kernel/entity/common/borehole';
import { ShelvingDetails } from '../entity/ShelvingDetails';
import { CoreshedLocation } from '../entity/CoreshedLocation';
import { TrayDetails } from '../entity/TrayDetails';
import { ChipsTrayDetails } from '../entity/ChipsTrayDetails';

export interface BoreholeIds {
    id: string
}

@Injectable({
    providedIn: 'root'
})
export class CoreshedService {
    constructor(
        private _http: HttpClient
    ){}

    createInvCoreshedDetails(details: InventoryBoreholeDetails): Observable<InventoryBoreholeDetails> {
        return this._http.post<InventoryBoreholeDetails>(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1', details);
    }

    updateInvCoreshedDetailsById(id: string, details: InventoryBoreholeDetails): Observable<InventoryBoreholeDetails> {
        return this._http.put<InventoryBoreholeDetails>(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/${id}`, details);
    }

    getAllInvCoreshedDetails(): Observable<Array<InventoryBoreholeDetails>> {
        return this._http.get<Array<InventoryBoreholeDetails>>(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1');
    }

    getTopTenInventoryCoreshedList(){
        return this._http.get(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/list/top_ten');
    }

    getInvCoreshedDetailsById(id: string): Observable<InventoryBoreholeDetails> {
        return this._http.get<InventoryBoreholeDetails>(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/${id}`);
    }

    deleteInvCoreshedDetailsById(id: string): Observable<void> {
        return this._http.delete<void>(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/${id}`);
    }

    searchBoreholeByNumber(boreholeNumber: string): Observable<Array<Borehole>> {
        return this._http.get<Array<Borehole>>(environment.appliedGeoscienceBaseUrl + `/api/boreholes/v1/search_borehole?boreholeNumber=${boreholeNumber}`);
    }

    getBoreholeById(id: string): Observable<Borehole> {
        return this._http.get<Borehole>(environment.appliedGeoscienceBaseUrl + `/api/ags-common/v1/borehole/${id}`);
    }

    getBoreholesByIds(ids: Array<string>): Observable<Array<Borehole>> {
        const idList = ids.map(x => x).join(",");
        return this._http.get<Array<Borehole>>(environment.appliedGeoscienceBaseUrl + `/api/boreholes/v1/borehole_list?ids=${idList}`);
    }

    createShelvingDetails(shelvingDetails: ShelvingDetails) {
        return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/shelving/create', shelvingDetails);
    }

    getShelvingDetailsByInventoryIdAndSampleType(inventoryId:string, sampleType: string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/shelving/list/inventory/${inventoryId}/sample_type/${sampleType}`);
    }

    getAllShelvingDetails(){
      return this._http.get(environment.collectionsBaseUrl  + '/api/inventory_borehole_details/v1/shelving/list/all');
    }

    getShelvingDetailsById(id:string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/shelving/${id}`);
    }

    deleteShelvingDetails(shelvingDetails: ShelvingDetails) {
        return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/shelving/delete', shelvingDetails);
    }

  getCoreshedLocations() {
    return this._http.get(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/coreshed_location' );
  }


  getCoreshedLocationsById(coreshed_id) {
    return this._http.get(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/coreshed_location/${coreshed_id}` );
  }

  createCoreshedLocations(coreshedLocation:CoreshedLocation) {
    return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/coreshed_location/create',coreshedLocation );
  }

    boreholeIdsInVillage(villageId: string): Observable<Array<BoreholeIds>> {
        return this._http.get<Array<BoreholeIds>>(environment.appliedGeoscienceBaseUrl + `/api/boreholes/v1/search_borehole/by_village/${villageId}`);
    }

    boreholeIdsInCompany(companyId: string): Observable<Array<BoreholeIds>> {
        return this._http.get<Array<BoreholeIds>>(environment.appliedGeoscienceBaseUrl + `/api/boreholes/v1/search_borehole/by_company/${companyId}`);
    }

    invCoreshedDetailsFromBoreholes(boreholeIds: Array<String>): Observable<Array<InventoryBoreholeDetails>> {
        const idList = boreholeIds.map(x => x).join(",");
        return this._http.get<Array<InventoryBoreholeDetails>>(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/search_boreholes?boreholeIds=${idList}`);
    }

    invCoreshedDetailsForBorehole(boreholeId: String): Observable<Array<InventoryBoreholeDetails>> {
        return this._http.get<Array<InventoryBoreholeDetails>>(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/boreholes/${boreholeId}`);
    }

    invCoreshedDetailsForSampleType(sampleType: String): Observable<Array<InventoryBoreholeDetails>> {
        return this._http.get<Array<InventoryBoreholeDetails>>(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/search_sample_types/${sampleType}`);
    }
    createTrayDetails(trayDetails: TrayDetails) {
        return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/tray/create', trayDetails);
    }

    checkoutCoreTrayDetails(checkoutHistoryList: any[]) {
      return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/tray/core/checkout', checkoutHistoryList);
    }

    checkoutChipsTrayDetails(checkoutHistoryList: any[]) {
      return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/tray/chips/checkout', checkoutHistoryList);
    }

    checkinCoreTrayDetails(checkinHistoryList: any[]) {
      return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/tray/core/checkin', checkinHistoryList);
    }

    checkinChipsTrayDetails(checkinHistoryList: any[]) {
      return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/tray/chips/checkin', checkinHistoryList);
    }

    getTrayDetailsByShelvingDetailsId(shelvingDetailsId:string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/tray/list/shelving/${shelvingDetailsId}`);
    }

    getTrayDetailsById(id:string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/tray/${id}`);
    }

    deleteTrayDetails(trayDetails: TrayDetails) {
        return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/tray/delete', trayDetails);
    }

    //chips tray
    createChipsTrayDetails(chipsTrayDetails: ChipsTrayDetails) {
        return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/chips/create', chipsTrayDetails);
    }

    getChipsTrayDetailsByShelvingDetailsId(shelvingDetailsId:string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/chips/list/shelving/${shelvingDetailsId}`);
    }

    getChipsTrayDetailsById(id:string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/chips/${id}`);
    }

    deleteChipsTrayDetails(chipsTrayDetails: ChipsTrayDetails) {
        return this._http.post(environment.collectionsBaseUrl + '/api/inventory_borehole_details/v1/chips/delete', chipsTrayDetails);
    }


    countShelvingDetailsByInventoryIdAndSampleType(inventoryId:string, sampleType: string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/shelving/count/inventory/${inventoryId}/sample_type/${sampleType}`);
    }

    countTrayDetailsByShelvingDetailsId(shelvingDetailsId:string) {
        return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/tray/count/shelving/${shelvingDetailsId}`);
    }

    //delete coreshed_location
    deleteCoreshedLocation(id: string) {
        return this._http.get(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/coreshared_location/delete/${id}`);
    }

    countChipsTrayDetailsByShelvingDetailsId(shelvingDetailsId:string) {
      return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/chips/count/shelving/${shelvingDetailsId}`);
  }

  getcheckoutHistoryByTrayDetails(trayDetailId: string) {
    return this._http.get(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/tray/chips/checkout/history/${trayDetailId}`);
  }

  getAllCheckoutHistory() {
    return this._http.get(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/tray/chips/checkout/history/recent`);
  }

  getCountOfCheckoutHistoryByTrayDetails(trayDetailId: string) {
    return this._http.get(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/tray/chips/checkout/history/${trayDetailId}/count`);
  }

  getPresevationDetailsById(id:string) {
    return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/tray/presevation/${id}`);
  }

  getCheckoutHistoryById(id:string) {
    return this._http.get(environment.collectionsBaseUrl  + `/api/inventory_borehole_details/v1/tray/checkoutHistory/${id}`);
  }

  getTrayWithPreservation(trays) {
    return this._http.get(environment.collectionsBaseUrl + `/api/inventory_borehole_details/v1/tray/checkin/preservation?trays=${trays}`);
  }

  getInventoryReport(parameters) {
    return this._http.post(environment.collectionsBaseUrl + `/api/reports/v1/geological_museum/inventory_reports`,parameters);
  }

  getSpecimenCollectionReport(parameters) {
    return this._http.post(environment.collectionsBaseUrl + `/api/reports/v1/geological_museum/specimen_collection`,parameters);
  }

  getTransactionReport(parameters) {
    return this._http.post(environment.collectionsBaseUrl + `/api/reports/v1/coreshed/transaction_reports`,parameters);
  }

  getStatusReport(parameters) {
    return this._http.post(environment.collectionsBaseUrl + `/api/reports/v1/coreshed/status_reports`,parameters);
  }




}
