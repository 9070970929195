import { Router } from '@angular/router';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { WaterPumpTestRecoveryCreateComponent } from './../water-pump-test-recovery-create/water-pump-test-recovery-create.component';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { WaterPumpingTestRecovery } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestRecovery';
import { HydrogeologyService } from './../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';
import { Borehole } from './../../../../../shared-kernel/entity/common/borehole';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-water-pump-test-recovery-list',
  templateUrl: './water-pump-test-recovery-list.component.html',
  styleUrls: ['./water-pump-test-recovery-list.component.scss']
})
export class WaterPumpTestRecoveryListComponent implements OnInit {

  dataSourceWaterPumpingTestRecovery: MatTableDataSource<WaterPumpingTestRecovery> = new MatTableDataSource();
  displayedWaterPumpingTestRecoveryColumns = ['#', 'startDate', 'depthToWater', 'residualDrawdown', 'time', 'view', 'edit', 'delete'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input('borehole') borehole: Borehole;
  @Input('projectId') projectId: string = '';
  waterPumpingTestRecoveryTests: WaterPumpingTestRecovery[];
  loading = true;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private hydrogeologyService: HydrogeologyService,
    private dialog: MatDialog,
    private route: Router,
  ) {
  }

  ngOnInit() {
    this.getRecoveryTestsByBoreholeId();

  }

  getRecoveryTestsByBoreholeId() {
    this.spinner.show();
    this.hydrogeologyService.getAllWaterPumpingTestRecoveryTestByBoreholeId(this.borehole.id)
      .subscribe(data => {
        this.waterPumpingTestRecoveryTests = data as WaterPumpingTestRecovery[];
        this.dataSourceWaterPumpingTestRecovery.data = this.waterPumpingTestRecoveryTests;
        this.dataSourceWaterPumpingTestRecovery.sort = this.sort;
        this.dataSourceWaterPumpingTestRecovery.paginator = this.paginator;
        this.loading = false;
        this.spinner.hide();
      }, error => {
        this.loading = false;
        this.spinner.hide();
      }, () => {
        this.loading = false;
        this.spinner.hide();
      });

  }

  onCreate() {
    const obj = {test: null, borehole:this.borehole, transactionType:'New'};
    const newGeoHeritageDialogRef = this.dialog.open(WaterPumpTestRecoveryCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getRecoveryTestsByBoreholeId();
    });
  }

  onEdit(waterPumpingTestRecovery: WaterPumpingTestRecovery) {
    const obj = {test: waterPumpingTestRecovery, borehole:this.borehole, transactionType:'Edit'};
    const newGeoHeritageDialogRef = this.dialog.open(WaterPumpTestRecoveryCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getRecoveryTestsByBoreholeId();
    });
  }

  onDelete(waterPumpingTestRecovery: WaterPumpingTestRecovery) {
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestRecoveryTestOne(waterPumpingTestRecovery).subscribe(() => {
              this.getRecoveryTestsByBoreholeId();
              this.toastr.success('Delete Water Pumping Test', 'You have Successfully deleted Water Pumping Test');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestRecoveryTests(this.dataSourceWaterPumpingTestRecovery.data).subscribe(() => {
              this.getRecoveryTestsByBoreholeId();
              this.toastr.success('Delete Water Pumping Tests', 'You have Successfully deleted Water Pumping Tests');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });
  }

  onDetailView(waterPumpingTestRecovery: WaterPumpingTestRecovery){
    const urlParams = { id: waterPumpingTestRecovery.id, boreholeId: waterPumpingTestRecovery.borehole.id};
    this.route.navigate(['/portal/applied-geoscience/hydrogeology/water-pump-test/recovery/view', urlParams]);
  }

  applyFilter(filterValue: string) {
    this.dataSourceWaterPumpingTestRecovery.filter = filterValue.trim().toLowerCase();
  }

  importCSVFile() {
    this.toastr.warning('STILL UNDER CONSTRUCTION');
  }

}
