import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {CustomerRequestFormComponent} from '../customer-request-form/customer-request-form.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ScheduleService} from '../../../services/schedule.service';
import {CustomerRequest} from '../../../entity/CustomerRequest';
import {PeopleService} from '../../../../_core/mock/people.service';
import {EventScheduleFormComponent} from '../event-schedule-form/event-schedule-form.component';
import { StorageConstants } from '../../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { LocalAuthorities } from '../../../../_auth/_providers';
import { ToastrService } from 'ngx-toastr';
import { BusinessUnitEnum } from '../../../../shared-kernel/enumerations/business-unit-enum';

@Component({
  selector: 'app-event-list',
  templateUrl: './customer-request-list.component.html',
  styleUrls: ['./customer-request-list.component.scss']
})
export class CustomerRequestListComponent implements OnInit {

  dataSource: MatTableDataSource<CustomerRequest> = new MatTableDataSource();
  displayedColumns = ['#', 'requestDate', 'customer', 'requestedServices', 'contacts', 'status', 'actions'];
  businessUmit = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  currentUser: any;
  isEconomicGeologist: boolean = false;

  loading = false;

  dialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog, private scheduleService: ScheduleService,    private _localStorage: LocalStorage,
              private peopleService: PeopleService,private toast: ToastrService) {


  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

        if(this.currentUser.businessUnit.name === BusinessUnitEnum.mineralResources) {
          this.businessUmit = '(Mineral Resource Assessment)';
          this.isEconomicGeologist = true;
        }
        else{
          this.isEconomicGeologist = false;
        }
      this.getAllCustomerRequests();
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '800px';

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   *
   * @param requestType - individual or company
   */
  onCreate(requestType: string): void {
    this.dialogConfig.data = {
      request: null,
      requestType: requestType,
      isEconomicGeologist: this.isEconomicGeologist
    };

    this.dialog.open(CustomerRequestFormComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getAllCustomerRequests();
    });
  }

  /**
   * Update customer details
   */
  onEdit(request: CustomerRequest): void {
    this.dialogConfig.data = {
      request: request,
      requestType: (request.company) ? 'company' : 'individual',
      isEconomicGeologist: this.isEconomicGeologist
    };

    this.dialog.open(CustomerRequestFormComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
        this.getAllCustomerRequests();
      });
  }

  onScheduleEvent(request: CustomerRequest): void {
    request.customerID = request.customerID.id;
    const dialogConfig = {
      width: '600px',
      data: {
        customerRequest: request,
        eventSchedule: null,
        edit: true,
      },
    };

    this.dialog.open(EventScheduleFormComponent, dialogConfig)
      .afterClosed().subscribe(result => {
      this.getAllCustomerRequests();
    });
  }

  getAllCustomerRequests() {
     this.loading = true;
       if(this.isEconomicGeologist){
         this.scheduleService.getAllCustomerRequestByService('Mineral Resource Assessment').subscribe(
           res => {
             this.dataSource.sort = this.sort;
             this.dataSource.paginator = this.paginator;
             this.loading = false;

             res.forEach(request => {
               this.peopleService.getPersonById(request.customerID).subscribe(
                 person => {
                   request.customerID = person;
                 }
               );

               this.scheduleService.getAllEventScheduleByRequestId(request.id).subscribe(
                 events => {
                   if (events.length > 0) {
                     request.events = events[0];
                   }
                 }
               );
               request.requestedServicesString = request.requestedServices.map(x=>x).join(",");
             });
             this.dataSource.data = res;
           },error => {
           },() => {}
         );
       }
       else{
         this.scheduleService.getAllCustomerRequest().subscribe(
           res => {

             this.dataSource.sort = this.sort;
             this.dataSource.paginator = this.paginator;
             this.loading = false;

             res.forEach(request => {
               this.peopleService.getPersonById(request.customerID).subscribe(
                 person => {
                   request.customerID = person;
                 }
               );

               this.scheduleService.getAllEventScheduleByRequestId(request.id).subscribe(
                 events => {
                   if (events.length > 0) {
                     request.events = events[0];
                   }
                 }
               );
               request.requestedServicesString = request.requestedServices.map(x=>x).join(",");
             });
             this.dataSource.data = res;
           }
         );
       }

   }
}
