import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import Utm, { LatLon, Dms } from 'geodesy/utm.js';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CoordinatesService {
  public readonly outSide: string = 'The coordinates are out of Botswana';

  constructor(private http: HttpClient, private toast: ToastrService) { }

  getGeofenceData(): Observable<any> {
    return this.http.get('/assets/geofence/botswanaGeofence.json');
  }

  convertUtmToLatLon(format, y, x) {
    try {
      const utmCoordinate = Utm.parse(format + ' ' + y + ' ' + x);
      return utmCoordinate.toLatLon();
    } catch (error) {
      this.toast.error(error);
    }

  }
  convertLatLonToUtm(zone, latitude, longitude) {
    try {
      const latlon = new LatLon(latitude, longitude);
      const utmCoordinate = latlon.toUtm(zone).toString();
      return {
        '_zone': utmCoordinate.split(' ')[0] + ' ' + utmCoordinate.split(' ')[1],
        '_lat': utmCoordinate.split(' ')[2], '_lon': utmCoordinate.split(' ')[3]
      };
    } catch (error) {
      this.toast.error(error);
    }

  }

  convertLatLonToDms(lat: number, lon: number) {
    try {
      Dms.separator = '';
      const dms = new LatLon(lat, lon).toString('dms');
      const dmsString = '' + dms + '';
      const dmsStringArray = dmsString.split(',');
      const latitudeDvalue = dmsStringArray[0].split('°')[0];
      const latitudeMvalue = dmsStringArray[0].split('°')[1].split('′')[0];
      const latitudeSvalue = dmsStringArray[0].split('°')[1].split('′')[1].split('″')[0];
      const longitudeDvalue = dmsStringArray[1].split('°')[0];
      const longitudeMvalue = dmsStringArray[1].split('°')[1].split('′')[0];
      const longitudeSvalue = dmsStringArray[1].split('°')[1].split('′')[1].split('″')[0];
      return {
        '_latDVale': latitudeDvalue, '_latMValue': latitudeMvalue, '_latSValue': latitudeSvalue, '_lonDValue': longitudeDvalue,
        '_lonMValue': longitudeMvalue, '_lonSValue': longitudeSvalue
      };
    } catch (error) {
      this.toast.error(error);
    }

  }



  convertDmsToLatLon(latitudeDegree, latitudeMinutes, latitudeSeconds,
    longitudeDegree, longitudeMinutes, longitudeSeconds) {
    try {
      const dmsLatToLatLonLat = Dms.parse(latitudeDegree + '°' + latitudeMinutes + '′' + latitudeSeconds + '″' + 'S');
      const dmsLonToLatLonLon = Dms.parse(longitudeDegree + '°' + longitudeMinutes + '′' + longitudeSeconds + '″' + 'E');
      const latLon = { _lat: dmsLatToLatLonLat, _lon: dmsLonToLatLonLon };
      return latLon;
    } catch (error) {
      this.toast.error(error);
    }
  }

  formatLatLon(lat, lon) {
    try {
      const latLon = new LatLon(lat, lon);
      return latLon;
    } catch (error) {
      this.toast.error(error);
    }

  }


}
