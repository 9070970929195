import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InfiltrationTestLog } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/InfiltrationTestLog';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { InfiltrationTestLogFormComponent } from '../infiltration-test-log-form/infiltration-test-log-form.component';
import { GeoTechService } from '../../../geo-tech.service';
import { ActiveFlagEnum } from 'src/app/shared-kernel/enumerations/active-flag-enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UrlTree, UrlSegment, PRIMARY_OUTLET, Router, ActivatedRoute } from '@angular/router';
import { InfiltrationTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/InfiltrationTest';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { Unit } from 'src/app/shared-kernel/entity/common/unit';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SampleCreateComponent } from '../../../samples/sample-create/sample-create.component';
declare var $: any;

@Component({
  selector: 'app-infiltration-test-log',
  templateUrl: './infiltration-test-log.component.html',
  styleUrls: ['./infiltration-test-log.component.scss']
})
export class InfiltrationTestLogComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  dataSourceInfiltrationTestLogs: MatTableDataSource<InfiltrationTestLog> = new MatTableDataSource();
  loading = false;
  displayedInfiltrationTestLogColumns = ['No.', 'Time', 'Head','TimeSec','HeadCm','TimeInterval', 'Head Diff', 'InfiltrationRate', 'Edit', 'Delete'];

  csvDdataSource: MatTableDataSource<InfiltrationTestLog> = new MatTableDataSource();
  displayedColumns: string[] = ['Time', 'Head', 'HeadDiff', 'InfiltrationRate', 'action'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(InfiltrationTestLogFormComponent) infiltrationTestLogForm;
  @ViewChild('fileImportInput') fileImportInput: any;

  infiltrationTestLog: InfiltrationTestLog = new InfiltrationTestLog();
  infiltrationTest: InfiltrationTest = new InfiltrationTest();
  infiltrationTestLogs: InfiltrationTestLog[];
  infiltrationTestLogsForSaving: InfiltrationTestLog[];
  unitEnum: typeof UnitEnum = UnitEnum;
  csvRecords: any[] = [];
  infiltrationId: string;
  selectedCvsRecord: InfiltrationTestLog = new InfiltrationTestLog();

  constructor(
    private geoTechService: GeoTechService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.infiltrationId = params.id;
    });
  }

  ngOnInit() {
    this.spinner.hide();
    this.infiltrationTestLogs = [];
    this.infiltrationTestLogsForSaving = [];
    this.getInfiltrationTestLogs();
    this.getInfiltrationLogById(this.infiltrationId);
    this.getInfiltrationTestId();
  }

  getInfiltrationLogById(infiltrationTestId:string){
    this.geoTechService.getInfiltrationTestById(infiltrationTestId).subscribe(value => {
      this.infiltrationTest = value as InfiltrationTest;
    },error => {},() => {

    })
  }



  resetPaginator() {
    this.dataSourceInfiltrationTestLogs.sort = this.sort;
    this.dataSourceInfiltrationTestLogs.paginator = this.paginator;
    this.dataSourceInfiltrationTestLogs.data = this.infiltrationTestLogs;
  }


  applyFilter(filterValue: string) {
    this.dataSourceInfiltrationTestLogs.filter = filterValue.trim().toLowerCase();
  }

  getInfiltrationTestLogs() {
    this.geoTechService.getAllActiveInfiltrationTestLogsByTestId(this.infiltrationId)
    .subscribe(data => {
      this.infiltrationTestLogs = data as InfiltrationTestLog[];
      this.infiltrationTestLogs = this.infiltrationTestLogs.sort((a, b) => a.time > b.time ? 1 : -1);
        for (const item of this.infiltrationTestLogs) {
          const index: number = this.infiltrationTestLogs.indexOf(item);
          if (index+1 === this.infiltrationTestLogs.length) {
            item.headDiff = 0;
            item.infiltrationRate = 0;
          } else {
            const previousHead = this.infiltrationTestLogs[index+1].head.measure;
            item.headDiff = (Number(previousHead)/10) - (Number(item.head.measure)/10) ;
            item.infiltrationRate = item.headDiff / Math.abs((Number(item.time)*60)-(Number(this.infiltrationTestLogs[index+1].time)*60));
            item['interval'] =  Math.abs((Number(item.time)*60)-(Number(this.infiltrationTestLogs[index+1].time)*60));
          }
        }

      this.dataSourceInfiltrationTestLogs = new MatTableDataSource(this.infiltrationTestLogs);
      this.infiltrationTest.infiltrationTestLogs = this.infiltrationTestLogs;
      this.resetPaginator();
    });
  }

  getInfiltrationTestId() {
    this.geoTechService.getInfiltrationTestById(this.infiltrationId).subscribe(data => {
      this.infiltrationTest = data as InfiltrationTest;
    }, error => {
    }, () => {
    });

  }

  openInfiltrationTestLogForm() {
    let paramObject: any = {};
    paramObject.infiltrationTestLog = null;
    paramObject.infiltrationTest = this.infiltrationTest;
    const createDialogRef = this.dialog.open(InfiltrationTestLogFormComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: paramObject,
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getInfiltrationTestLogs();
    });
  }

  editInfiltrationTestLogForm(infiltrationTestLog: any) {
    let paramObject: any = {};
    paramObject.infiltrationTestLog = infiltrationTestLog;
    paramObject.infiltrationTest = this.infiltrationTest;
    const createDialogRef = this.dialog.open(InfiltrationTestLogFormComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: paramObject
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getInfiltrationTestLogs();
    });
  }


  closeModal() {
    $('#deleteModal').modal('hide');
    this.infiltrationTestLog = new InfiltrationTestLog();
    this.infiltrationTestLogForm.createInfiltrationTestLogObject();
  }

  onDelete() {
    this.spinner.show();
    this.infiltrationTestLog.deleted = true;

    this.geoTechService.deleteInfiltrationTestLog(this.infiltrationTestLog).subscribe(response => {
        this.toast.success('Deleting an Infiltration Test successful.', 'Infiltration Test Update');
       this.getInfiltrationTestLogs();
      },
      error => {
        this.spinner.hide();
        this.toast.error('Deleting an Infiltration Test failed, please try again.', 'Infiltration Test Update');
      },
      () => {
        this.spinner.hide();
        this.resetPaginator();
        this.closeModal();
      });
  }

  deleteRecordRequest(infiltrationTestLog: InfiltrationTestLog) {
    this.infiltrationTestLog = infiltrationTestLog;
    $('#deleteModal').modal('show');
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        if(this.infiltrationTestLogs.find(value => value.time === Number(data[0].trim()))){
          this.toast.warning('Time Interval : ' +Number(data[0].trim())+ ' already captured','Recods Already exist')
        }
        else{
          const csvRecord: InfiltrationTestLog = new InfiltrationTestLog();
          csvRecord.head = new Size();
          csvRecord.head.unit = new Unit();
          csvRecord.time = Number(data[0].trim());
          csvRecord.head.measure = data[1].trim();
          if (i+1 === csvRecordsArray.length-1) {
            csvRecord.headDiff = 0;
            csvRecord.infiltrationRate = 0;
          } else {
            const previousData = (<string>csvRecordsArray[i+1]).split(',');
            const previousHead = previousData[1].trim();
            csvRecord.headDiff = Math.abs( (Number(csvRecord.head.measure)/10) - (Number(previousHead)/10));
            csvRecord.infiltrationRate = csvRecord.headDiff / Math.abs((Number(csvRecord.time)*60) - (Number(previousData[0].trim()) *60)) ;
          }
          csvRecord.infiltrationTest.id = this.infiltrationId;
          dataArr.push(csvRecord);
        }
      }
    }
    this.csvDdataSource  = new MatTableDataSource(dataArr);
    return dataArr;
  }

  showCsvModal(): void {
    $('#csvModal').modal({ backdrop: 'static' });
    $('#csvModal').modal('show');
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  deleteCsvRecord(infiltrationTestLog: InfiltrationTestLog) {
    const itemIndex: number = this.csvRecords.indexOf(infiltrationTestLog);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toast.success('Record deleted', 'Record Deletion');
  }

  saveCsvRecords() {
    this.saveBatch(this.csvRecords);
    this.fileReset();
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    $('#csvModal').modal('hide');
  }

  saveBatch(records: InfiltrationTestLog[]) {
    this.spinner.show();
    for (const item of records) {
      item.infiltrationTest.id = this.infiltrationId;
      item.head.unit = new Unit();
      item.head.unit.id = this.unitEnum.millimeter;
    }
    this.createInfiltrationTestLogRecord(records);
  }

  createInfiltrationTestLogRecord(infiltrationTestLogs: InfiltrationTestLog[]) {
    this.infiltrationTestLogsForSaving = infiltrationTestLogs;
    this.geoTechService.createInfiltrationTestLog(this.infiltrationTestLogsForSaving).subscribe(response => {
        this.getInfiltrationTestLogs();
        this.toast.success('You have Successfully created a New Infiltration Test Log', 'Infiltration Test Log Create');
        this.spinner.hide();
        this.resetPaginator();
        this.infiltrationTestLogsForSaving = [];
        this.closeModal();
      },
      error => {
        this.toast.error('Creating a new Infiltration Test Log failed, please try again.', 'Infiltration Test Log Create');
      },
      () => {
        this.spinner.hide();
        this.resetPaginator();
        this.infiltrationTestLogsForSaving = [];
        this.closeModal();
      });

  }

}
