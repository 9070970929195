import {Component, Inject, OnInit} from '@angular/core';
import {Borehole} from '../../../../../shared-kernel/entity/common/borehole';
import {WaterDrillersLogRecord} from '../entity/waterdrillerslog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatTableDataSource} from '@angular/material';
import {WaterDrillersLogService} from '../water-drillers-log.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UnitEnum} from '../../../../../shared-kernel/enumerations/unit-enum';
import {CoreService} from '../../../core/core.service';

@Component({
  selector: 'app-water-drillers-log-create',
  templateUrl: './water-drillers-log-create.component.html',
  styleUrls: ['./water-drillers-log-create.component.scss']
})
export class WaterDrillersLogCreateComponent implements OnInit {
  borehole: Borehole;
  waterDrillersLogRecord: WaterDrillersLogRecord;
  depth = 0;
  boreholeId: string;
  enumUnits: typeof UnitEnum = UnitEnum;
  waterDrillersLogRecords: Array<WaterDrillersLogRecord>;
  loading = true;
  dataSource: MatTableDataSource<WaterDrillersLogRecord> = new MatTableDataSource();

  dialogConfig = new MatDialogConfig();

  drillersLogFormGroup: FormGroup = new FormGroup({
    id: new FormControl(''),
    description: new FormControl(null, [Validators.required]),
    diameter: new FormControl(null, [Validators.min(0)]),
    depthFrom: new FormControl(null, [Validators.required, Validators.min(0)]),
    depthTo: new FormControl(null, [Validators.required, Validators.min(0)]),
    date: new FormControl(null, Validators.required),
  });

  constructor(
              @Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<WaterDrillersLogCreateComponent>,
              private waterDrillersLogService: WaterDrillersLogService,
              private route: ActivatedRoute,
              private toaster: ToastrService,
              private dialog: MatDialog,
              private coreService: CoreService) {
    this.route.params.subscribe(params => {
      this.boreholeId = params.id;
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.borehole = this.data.borehole;
    this.waterDrillersLogRecord = this.data.drillersLog;

    this.drillersLogFormGroup.get('depthFrom').setValue(0);

    if (this.waterDrillersLogRecord) {
      this.populateForm();
    }

    if (this.borehole.waterBoreholeDrillersLogs && !this.waterDrillersLogRecord) {
      const previous_log = this.borehole.waterBoreholeDrillersLogs[this.borehole.waterBoreholeDrillersLogs.length - 1];
      if (previous_log) {
        this.drillersLogFormGroup.get('depthFrom').setValue((previous_log.depthTo.measure));
      }
    }
  }
  onCreate() {
    this.dialogConfig.data = {
      borehole: this.borehole,
      drillersLog: null
    };

    this.dialog.open(WaterDrillersLogCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      if (result.event === 'submit') {
        this.getBorehole(this.borehole.id);
      }
    });
  }

  onSubmit() {

    const log = this.drillersLogFormGroup.value;

    if (this.waterDrillersLogRecord) {

      log['id'] = this.waterDrillersLogRecord.id;

      this.waterDrillersLogRecord.depthTo.measure = log['depthTo'];
      this.waterDrillersLogRecord.depthFrom.measure = log['depthFrom'];
      this.waterDrillersLogRecord.diameter.measure = log['diameter'];
      this.waterDrillersLogRecord.description = log['description'];

      log['diameter'] = this.waterDrillersLogRecord.diameter;
      log['description'] = this.waterDrillersLogRecord.description;
      log['depthTo'] = this.waterDrillersLogRecord.depthTo;
      log['depthFrom'] = this.waterDrillersLogRecord.depthFrom;


    } else {

      log['borehole'] = this.borehole;
      log['depthTo'] = {
        id: '',
        measure: log['depthTo'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };

      log['depthFrom'] = {
        id: '',
        measure: log['depthFrom'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };
      log['diameter'] = {
        id: '',
        measure: log['diameter'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };


    }
    if ((+log['depthTo'].measure) > (+log['depthFrom'].measure)) {
      log.borehole = this.borehole;
      this.waterDrillersLogService.createWaterDrillersLogRecord(log)
        .subscribe(response => {
            this.onClose({event: 'submit', data: log});
            if (this.waterDrillersLogRecord) {
              this.toaster.success('Water drillers log updated successfully');
            } else {
              this.toaster.success('Water drillers log created successfully');
            }
          },
          error => {
            this.toaster.error(error.toString());
          });
    } else {
      this.toaster.error('`Depth To` must be greater than `Depth From`', 'Invalid Depth');
      this.drillersLogFormGroup.get('depthTo').setValue('');
    }
  }

  getBorehole(id: any) {
    this.coreService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
        this.loading = false;
        this.getWatersDrillersLogRecordsByBoreholeId(this.borehole.id);
      }
    );
  }
  getWatersDrillersLogRecordsByBoreholeId(boreholeId: string) {
    this.waterDrillersLogService.getBoreholeWaterDrillersLogRecords(boreholeId).subscribe(data => {
      this.waterDrillersLogRecords = data as WaterDrillersLogRecord[];
      this.waterDrillersLogRecords.sort((a, b) => <any>a.depthFrom.measure - <any>b.depthFrom.measure);
      this.dataSource.data = data as WaterDrillersLogRecord[];
    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  reset() {
    this.drillersLogFormGroup.setValue({
      id: '', drillString: '',
      tableHeight: '', depthFrom: null,
      depthTo: null, date: '', drillerFormationLog: '',
      borehole: '',
    });
    this.depth = 0;

    this.onClose({event: 'reset', data: null});
  }

  onClose(actions: any) {
    this.dialogRef.close(actions);
  }
  populateForm() {
    const log = {
      'id': this.waterDrillersLogRecord.id,
      'description': this.waterDrillersLogRecord.description,
      'diameter': this.waterDrillersLogRecord.diameter.measure,
      'date': new Date(this.waterDrillersLogRecord.date),
      'depthFrom': this.waterDrillersLogRecord.depthFrom.measure,
      'depthTo': this.waterDrillersLogRecord.depthTo.measure,
    };

    this.drillersLogFormGroup.setValue(log);
  }

}
