import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SoilLogService} from '../soil-log.service';
import {ToastrService} from 'ngx-toastr';
import {UnitEnum} from '../../../../../../shared-kernel/enumerations/unit-enum';
import { GeoTechService } from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import { SoilName } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/SoilName';
import { SoilLog } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log';

@Component({
  selector: 'app-soil-log-create',
  templateUrl: './soil-log-create.component.html',
  styleUrls: ['./soil-log-create.component.scss']
})
export class SoilLogCreateComponent implements OnInit {

  enumUnits: typeof UnitEnum = UnitEnum;
  soilNames: SoilName[] = [];
  soilLogs: SoilLog[] = [];
  soilLogForm: FormGroup;
  depthFromReadOnly: boolean = true;
  depthToReadOnly: boolean = false;

  recordEditingComplete: boolean;
  relatedRecordEditingComplete: boolean;

  constructor(private dialogRef: MatDialogRef<SoilLogCreateComponent>,
              private soilLogService: SoilLogService,
              private toast: ToastrService,
              private formBuilder: FormBuilder,
              private geoTechService: GeoTechService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.initForm()
    this.getAllSoilLogsByTrialPitId();
    this.getSoilNames();
  }

  initForm(){
    this.soilLogForm = this.formBuilder.group({
      id: [''],
      sampleNumber: [this.data.soilLog ? this.data.soilLog.sampleNumber: null],
      depthFrom: [this.data.soilLog ? this.data.soilLog.depthFrom.measure : null, Validators.required],
      depthTo: [this.data.soilLog ? this.data.soilLog.depthTo.measure : null, Validators.required],
      soilName: [this.data.soilLog ? this.data.soilLog.soil : null, Validators.required],
      sampleStatus: [this.data.soilLog ? this.data.soilLog.sampleStatus: null],
      comment: [this.data.soilLog ? this.data.soilLog.comment.description: null],
      legend: [this.data.soilLog ? this.data.soilLog.legend: null],
      test: [this.data.soilLog ? this.data.soilLog.test: null],
      description: [this.data.soilLog ? this.data.soilLog.description: null],
    });

  }

  closeDialog() {
    this.dialogRef.close(this.data.soilLog);
  }

  onReset() {
    this.soilLogForm.reset();
  }

  getAllSoilLogsByTrialPitId() {
    if (this.data.trialPit != null) {
      this.soilLogService.getAllSoilLogsByTrialPitId(this.data.trialPit.id)
        .subscribe(data => {
          this.soilLogs = data as SoilLog[];
          if (this.data.soilLog) {
            if (this.data.soilLog.id) {
            this.depthFromReadOnly = false;
            this.depthToReadOnly = false;
            }
          } else {
            if (this.soilLogs.length > 0) {
              this.soilLogForm.get('depthFrom').setValue(this.soilLogs[this.soilLogs.length - 1].depthTo.measure);
            } else {
              this.depthFromReadOnly = false;
              this.soilLogForm.get('depthFrom').setValue(0);
            }
          }
        });
    }
  }

  onSubmit() {

    let lastDepth = 0;
    let nextDeptFrom = 0;

    if(this.data.index) {
      
      lastDepth = Number(this.soilLogs[this.data.index].depthTo.measure);
      nextDeptFrom = Number(this.soilLogs[this.data.index+1].depthFrom.measure);

    } else if(this.soilLogs && this.soilLogs.length > 0) {

      lastDepth = Number(this.soilLogs[this.soilLogs.length - 1].depthTo.measure);
    }

    let error = false;
    let errorMessage = '';

    if(Number(this.depthTo) <= Number(this.depthFrom)) {
      error = true;
      errorMessage = 'Depth To must be greater than Depth From';

    }

    if(Number(this.depthFrom) < lastDepth) {
      error = true;
      errorMessage = 'Depth From must be greater than last Depth To (' + lastDepth + ')';
    }

    if(this.data.index) {

      if(Number(this.depthTo) > nextDeptFrom) {
        error = true;
        errorMessage = 'Depth To must be less than next Depth From (' + nextDeptFrom + ')';
      }
    }

    if(error) {
      this.toast.error(errorMessage, 'Invalid depth range');
      return;
    } 

    let rowCount;
    let soilLog;

    if (this.soilLogs.length > 0) {
      if(this.data.soilLog) {
        rowCount = this.data.soilLog.logNumber;
      } else {
        rowCount = this.soilLogs.length + 1;
      }
    } else {
      rowCount = 1
    }

    soilLog = {
      id: (this.data.soilLog ? this.data.soilLog.id : ''),
      depthFrom: {id: '', measure: this.depthFrom, unit: {id: this.enumUnits.meter, unit: '', description: ''}},
      depthTo: {id: '', measure: this.depthTo, unit: {id: this.enumUnits.meter, unit: '', description: ''}},
      comment: {id: '', description: this.soilLog.comment},
      description: this.soilLog.description,
      legend: this.soilLog.legend,
      sampleNumber: this.soilLog.sampleNumber,
      soil: this.soilLog.soilName,
      sampleStatus: this.soilLog.sampleStatus,
      test: this.soilLog.test,
      trialPit: this.data.trialPit,
      logNumber: (this.data.soilLog ? this.data.soilLog.logNumber : rowCount),
      labResults: (this.data.soilLog ? this.data.soilLog.labResults : null),
      labSampleRequestForm: (this.data.soilLog ? this.data.soilLog.labSampleRequestForm : null),
      creator: (this.data.soilLog ? this.data.soilLog.creator : null),
      created: (this.data.soilLog ? this.data.soilLog.created : null),
    };

    if ((+this.depthFrom) < (+this.depthTo)) {
      this.soilLogService.createSoilLog(soilLog).subscribe(data => {
        
        if (this.data) {
          let returnData = {
            soilLog: data,
            index: this.data.index
          }

          if (!soilLog.id || soilLog.id === '') {
            this.toast.success('Soil Log created successfully', 'Create Soil Log');
          } else {
            this.toast.success('Soil Log has been updated successfully', 'Update Soil Log');
          }
          this.dialogRef.close({
            returnData: returnData
          });
        }
      }, error => {
        this.toast.error('An error occurred when trying to create/ update soil log', 'Error Occurred');
      });
    } else {
      this.soilLogForm.get('depthTo').setValue('');
      this.toast.error('Depth To must be greater than Depth From', 'Invalid depth range');
    }

  }

  get soilLog() {
    return this.soilLogForm.value;
  }

  get depthFrom() {
    return this.soilLogForm.get('depthFrom').value;
  }

  get depthTo() {
    return this.soilLogForm.get('depthTo').value;
  }
  
  editRelatedRecord(soilLogToEdit) {
    this.relatedRecordEditingComplete = false;
    if ((+soilLogToEdit.depthFrom.measure) < (+soilLogToEdit.depthTo.measure)) {
      this.soilLogService.createSoilLog(soilLogToEdit).subscribe(data => {
        this.relatedRecordEditingComplete = true;
      }, error => {
        this.toast.error('An error occurred when trying to create/ update soil log', 'Error Occurred');
      });
    } else {
      this.soilLogForm.get('depthTo').setValue('');
      this.toast.error('Depth To must be greater than Depth From', 'Invalid depth range');
    }

  }

  getSoilNames() {
    this.geoTechService.getAllSoils().subscribe(res => {
      this.soilNames = res as SoilName [];
      this.soilNames.sort((a, b) => (a.soil || '').toString().localeCompare((b.soil || '').toString()));
    });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  greaterThan() {
    const depthFromValue = this.soilLogForm.get('depthFrom');
    const depthToValue = this.soilLogForm.get('depthTo');
    const isMoreThan = Number(depthFromValue.value) >= Number(depthToValue.value);
    this.soilLogForm.get('depthTo').setErrors(null);

    if (isMoreThan) {
        this.soilLogForm.get('depthFrom').setErrors({ 'error': true });
    } else {
      this.soilLogForm.get('depthFrom').setErrors(null);
    }
  }

  lessThan() {
    const depthFromValue = this.soilLogForm.get('depthFrom');
    const depthToValue = this.soilLogForm.get('depthTo');
    const isLessThan = Number(depthToValue.value) <= Number(depthFromValue.value);
    this.soilLogForm.get('depthFrom').setErrors(null);

    if (isLessThan) {
      this.soilLogForm.get('depthTo').setErrors({ 'error': true });
    } else {
      this.soilLogForm.get('depthTo').setErrors(null);
    }
  }
}
