import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {UserService} from '../../../auth/user/user.service';
import {CoreService} from '../../geo-tech/core/core.service';
import {ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlTree} from '@angular/router';
import {Borehole} from '../../../shared-kernel/entity/common/borehole';
declare var $: any;
@Component({
  selector: 'app-hydrologeology-borehole-construction',
  templateUrl: './hydrologeology-borehole-construction.component.html',
  styleUrls: ['./hydrologeology-borehole-construction.component.scss']
})
export class HydrologeologyBoreholeConstructionComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('projectId') projectId: string = '';
  boreholeNumber: string;
  boreholeId: string;
  borehole: Borehole;
  selectedTab: number;

  constructor(private router: ActivatedRoute, private coreService: CoreService) {

    router.params.subscribe(
      data => {
        this.boreholeId = data.id;
        if (data.tab) {
          this.selectedTab = data.tab;
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  getBoheholeNumber(id) {
    this.coreService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
        this.boreholeNumber = this.borehole.boreholeNumber;
      });
  }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.boreholeId = params.id;
    });
    this.boreholeNumber = '';
    this.getBoheholeNumber(this.boreholeId);
    $('.menu .item').tab();
  }
}
