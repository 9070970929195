export class Kin {

  public id: string;
  public cellNumber: string;
  public emailAddress: string;
  public homeNumber: string;
  public name: string;
  public surname: string;
  public relationship: string;

    constructor() {  }
}
