import {DcpTestImage} from '../../../../../shared-kernel/entity/common/DcpTestImage';
import {DynamicConePenetrometerTest} from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTest';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';

export class DcpTestFoundation {
  id: String;
  elevation: String;
  surfaceType: String;
  testLevel: String;
  testImageList: Array<DcpTestImage>;
  dynamicConePenetrometerTest: DynamicConePenetrometerTest = new DynamicConePenetrometerTest();
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
  currentState: any;
  statusHistory: any[];

  creator?:    any | null | any;
  created?:    string | Date | any;
  updater?:    any | null | string | Date;
  updated?:    string | Date | any;
  reference?: null;
}
