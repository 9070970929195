import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {ProjectService} from '../project.service';
import {ActivatedRoute} from '@angular/router';
import {Borehole} from '../../../shared-kernel/entity/common/borehole';
import {TimeandmotionProject} from './entity/timeandmotion-project';
import {TimeAndMotion} from '../../../driller/timeandmotion/entity/timeandmotion';

@Component({
  selector: 'app-timeandmotion-project',
  templateUrl: './timeandmotion-project.component.html',
  styleUrls: ['./timeandmotion-project.component.scss']
})
export class TimeandmotionProjectComponent implements OnInit {
  boreholes: Borehole[];
  sum: any;
  timeandmotions: TimeandmotionProject[];
  hours = new Array();
  loading = true;
  selectedProject: any;
  displayedColumns: string[] = ['No.', 'BoreholeNo', 'TotalHours', 'View'];

  dataSourceTimeAndMotion: MatTableDataSource<TimeandmotionProject> = new MatTableDataSource();

  constructor(private projectService: ProjectService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.selectedProject = params.id;
    });

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.dataSourceTimeAndMotion.paginator = this.paginator;
    this.dataSourceTimeAndMotion = new MatTableDataSource(this.timeandmotions);
    this.getAllBoreholeByProjectId();
  }

  getAllBoreholeByProjectId() {
    this.projectService.getAllBoreholesByProjectId(this.selectedProject).subscribe(data => {
      this.dataSourceTimeAndMotion.data = data as TimeandmotionProject[];
      this.loading = false;
      this.sum = 0;
      function filter(arr) {
        return arr.filter(Boolean);
      }
      this.dataSourceTimeAndMotion.data.forEach(value => {
        this.hours.push(value.totalHours);
      });
      filter(this.hours).forEach(value => {
        this.sum += parseFloat(value);
      });
    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceTimeAndMotion.filter = filterValue.trim().toLowerCase();
  }
}
