import { LocalAuthorities } from './../../../_auth/_providers/local-authorities';
import { StorageConstants } from './../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeoTechService } from '../../../applied-geoscience/geo-tech/geo-tech.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserRoles } from '../../../_auth/services/user-roles';
import { ProjectService } from '../../../applied-geoscience/project/project.service';
import { DrillerService } from '../../driller.service';
import { UserService } from '../../../auth/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { DrillingRequest } from '../../../shared-kernel/entity/driller/drilling-request';
import { DrillingMethod } from '../../../shared-kernel/entity/driller/drilling-method';
import { FileToUpload } from '../../../common/uploaded-files/entity/file';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UploadedFilesService } from '../../../common/uploaded-files/uploaded-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DrillingRequestStatusEnum } from '../../../shared-kernel/enumerations/drilling-request-status-enum';
import { BoreholeType } from '../../../shared-kernel/entity/common/boreholeType';
import { DrillingRequestStatus } from '../../../shared-kernel/entity/driller/drilling-request-status';
import { Size } from '../../../shared-kernel/entity/common/size';
import { disableBindings } from '@angular/core/src/render3';
import { DocumentLink, NigisDocument, UploadFileResponse } from '../../../shared/documents/nigis-doc.model';
import { map } from 'rxjs/operators';
import { NigisImages } from '../../../shared-kernel/entity/common/nigisImages';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-drilling-request-surveys-create',
  templateUrl: './drilling-request-create.component.html',
  styleUrls: ['./drilling-request-create.component.scss']
})
export class DrillingRequestCreateComponent implements OnInit {

  drillingRequest: DrillingRequest = new DrillingRequest();
  drillingRequestCreateForm: FormGroup;
  boreholeTypes: BoreholeType[];
  drillingMethods: DrillingMethod[];
  displayName = 'Browse for a file';
  selectable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fileToBeUploaded: NigisImages = new NigisImages();
  progress: { percentage: number } = {percentage: 0};
  formData: FormData;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  removable = true;
  addOnBlur = true;
  drillingRequestStatusEnum: typeof DrillingRequestStatusEnum = DrillingRequestStatusEnum;
  currentPerson: any;
  currentUser: any;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DrillingRequestCreateComponent>,
    private geotechService: GeoTechService,
    private spinner: NgxSpinnerService,
    private drillerService: DrillerService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DrillingRequest,
    private _localStorage: LocalStorage,
    private _localAuthorities: LocalAuthorities,
  ) {
  }

  ngOnInit() {
    this.initForms();
    this.getAlBoreholeTypes() ;
    this.getAllDrillingMethods();

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
      }, err => {
          this.toast.warning(err.message, "Current User Error");
      },() => {

      });
  }

  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
        if (res) {
            this.currentPerson = res;
            this.currentPerson['user'] = this.currentUser;
            this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});
        }
    }, err => {
        this.toast.error(err.message, "Profile Details");
    },() => {
    });
  }

  private initForms() {

    this.drillingRequestCreateForm = this.formBuilder.group({
      drillingDate: [this.data.id ? new Date(this.data.drillingDate) : '', Validators.required],
      drilleRequestComments: [this.data.id ? this.data.comments.description : ''],
      drillingMethod: [this.data.id ? this.data.drillingMethod : null, Validators.required],
      boreholeType: [this.data.id ? this.data.boreholeType : '', Validators.required],
      // uploadFiles: []
    });

  }


  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const chipBoreholeLocationImage = new NigisImages();
      chipBoreholeLocationImage.name = value;

      if ((value || '').trim()) {
      }

      if (input) {
        input.value = '';
      }

    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }


  getFileExtFromName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  getAllDrillingMethods() {
    this.drillerService.getAllDrillingMethods()
      .subscribe(data => {
        this.drillingMethods = data as DrillingMethod[];
      });
  }

  getAlBoreholeTypes() {
    this.geotechService.getAllBoreholeTypes()
      .subscribe(data => {
        this.boreholeTypes = data as BoreholeType[];

      });
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    if(this.data.id) {
      this.drillingRequest = this.data;
    }

    this.drillingRequest.project.id = this.data.project.id;
    this.drillingRequest.drillingDate = this.drillingRequestCreateForm.controls.drillingDate.value;
    this.drillingRequest.comments.description = this.drillingRequestCreateForm.controls.drilleRequestComments.value;
    this.drillingRequest.drillingMethod = this.drillingRequestCreateForm.controls.drillingMethod.value;
    this.drillingRequest.locationTemp = null;
    this.drillingRequest.sampleSize = null;
    this.drillingRequest.finalDepth = null;
    this.drillingRequest.status = new DrillingRequestStatus();
    this.drillingRequest.status.id = this.drillingRequestStatusEnum.Submitted.id;
    this.drillingRequest.status.status = this.drillingRequestStatusEnum.Submitted.status;
    this.drillingRequest.boreholeType = this.drillingRequestCreateForm.controls.boreholeType.value;
    this.drillingRequest.businessUnit = this.currentPerson.user.businessUnit.id;


    this.drillerService.createDrillingRequest(this.drillingRequest)
      .subscribe(
        data => {
              this.closeModal();
              this.toast.success('You have Successfully submitted a drilling request', 'Create Drilling Request');
              this.spinner.hide();
      },
        error => {
              this.toast.error(error[0], 'Create unsuccessful');
              this.spinner.hide();
            }, () => {
              this.spinner.hide();
            }
    );
  }

  attachFile() {
    $('#browseFile').click();
  }


}
