import { PostalAddress } from 'src/app/common/entity/security/util/common/postal-address';
import { PhysicalAddress } from 'src/app/common/entity/security/util/common/physical-address';
import { ContactDetail } from 'src/app/common/entity/security/util/common/contact-detail';
import { CompanyCategory } from './company-category';
import { CompanyType } from './company-type';

export class Company {
     id: string;
     name: string;
     profilePicture: string;
     website: String;
     companyType: CompanyType = new CompanyType();
     companyCategory: CompanyCategory = new CompanyCategory();
     postalAddress: PostalAddress = new PostalAddress();
     physicalAddress: PhysicalAddress = new PhysicalAddress();
     contactDetails: ContactDetail[] = [];
     creator: string;
  }
