import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';
import {GeoTechService} from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TimeAndMotion} from './entity/timeandmotion';
import {TimeAndMotionService} from './timeandmotion.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {element} from 'protractor';
import { SampleCreateComponent } from '../../applied-geoscience/geo-tech/samples/sample-create/sample-create.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { TimeandmotionCreateComponent } from './timeandmotion-create/timeandmotion-create.component';
import { WaterBearingZones } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import { Borehole } from '../../shared-kernel/entity/common/borehole';
import { DiameterFormation } from '../../common/diameter-formation/entity/diameterFormation';
import { Size } from '../../shared-kernel/entity/common/size';
import { Unit } from '../../shared-kernel/entity/common/unit';
import { ExportToCsv } from 'export-to-csv';

declare var $: any;

@Component({
  selector: 'app-timeandmotion',
  templateUrl: './timeandmotion.component.html',
  styleUrls: ['./timeandmotion.component.scss']
})
export class TimeandmotionComponent implements OnInit {

  @Input('currentState') currentState;
  timeAndMotionRecords: Array<TimeAndMotion>;
  selectedTimeAndMotionRecord: TimeAndMotion = new TimeAndMotion();
  loading = true;
  edit = false;
  sumDurationHours = 0;
  sumDurationMinutes = 0;
  boreholeId: string;
  borehole : Borehole;
  totalDuration: string[] = [];
  csvRecords: any[] = [];

  @ViewChild('fileImportInput') fileImportInput: any;
  @Input('projectId') projectId: string = '';

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatPaginator)
  uploadpaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['No.', 'date', 'timeFrom', 'timeTo', 'drillingHours', 'travellingHoursTo', 'View', 'edit', 'delete'];
  displayedColumnsForImport: string[] = ['No.', 'date', 'timeFrom', 'timeTo','meterFrom','meterTo', 'drillingHours',
    'pullingandLowering','maintainance' ,'mobilization', 'preStartChecks','others'];

  dataSource: MatTableDataSource<TimeAndMotion> = new MatTableDataSource();
  csvDataSource: MatTableDataSource<TimeAndMotion> = new MatTableDataSource();

  dialogRef: any;
  selectedCvsRecord: TimeAndMotion = new TimeAndMotion();


  constructor(
    private timeAndMotionService: TimeAndMotionService,
    private geoTechService: GeoTechService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,private geotechService: GeoTechService,
    private dialog: MatDialog,
    private toaster: ToastrService,) {
    this.route.params.subscribe(params => {
      this.boreholeId = params.id;
    });

  }

  ngOnInit(): void {
    this.getAllTimeAndMotionByBoreholeId(this.boreholeId);
  }

  resetPaginator() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }


  addNewRecordRequest(timeAndMotion: any) {

    let timeAndMotionData : TimeAndMotion = new TimeAndMotion();
    if(timeAndMotion!==''){
      timeAndMotionData = timeAndMotion;
    }
    timeAndMotionData.borehole = this.boreholeId;

    const createDialogRef = this.dialog.open(TimeandmotionCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: timeAndMotionData
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getAllTimeAndMotionByBoreholeId(this.boreholeId);
    });
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.getAllTimeAndMotionByBoreholeId(this.boreholeId);
    $('#csvModal').modal('hide');
  }

  showCsvModal(): void {
    $('#csvModal').modal({ backdrop: 'static' });
    $('#csvModal').modal('show');
  }

  closeCsvModal(): void {
    $('#csvModal').modal({ backdrop: 'static' });
    $('#csvModal').modal('hide');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }


  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {

      if(new Date(data[0]) && data[0].length>6){
        let timeFromHolder = data[1].split(':');
        let timeToHolder = data[1].split(':');

        if(timeFromHolder.length > 0 && timeToHolder.length > 0){
          const csvRecord: TimeAndMotion = new TimeAndMotion();
          csvRecord.date = new Date(data[0]);

          csvRecord.timeFrom = data[1];
          csvRecord.timeTo = data[2];
          csvRecord.metersFrom = data[3];
          csvRecord.metersTo = data[4];

          csvRecord.drillingHours.hours = data[5];
          csvRecord.drillingHours.minutes = data[6];

          csvRecord.pullingAndLowering.hours = data[7];
          csvRecord.pullingAndLowering.minutes = data[8];

          csvRecord.maintenanceHours.hours = data[9];
          csvRecord.maintenanceHours.minutes = data[10];

          csvRecord.mobilisationHours.hours = data[11];
          csvRecord.mobilisationHours.minutes = data[12];

          csvRecord.preStartChecks.hours = data[13];
          csvRecord.preStartChecks.minutes = data[14];

          csvRecord.others.hours = data[15];
          csvRecord.others.minutes = data[16];

          csvRecord.comments = data[17];
          csvRecord.borehole = this.boreholeId;
          dataArr.push(csvRecord);
        }

      }else{
        this.toaster.warning('Record does not contain date','Import Time and motion')
      }

      }
    }
    this.csvDataSource.data = dataArr;
    return dataArr;
  }

  saveCsvRecords(){
    if(this.loading){
      this.toaster.warning('No records to submit', 'Import Time and Motion Log');
    }else{
      this.timeAndMotionService.createTimeAndMotionRecordBatch(this.csvDataSource.data).subscribe(data => {
        this.fileReset();
        }, error => {

          this.toaster.error('Records import failed', 'Import Failed');
          this.spinner.hide();

        },
        () => {
          this.toaster.success('Records import successful', 'Import Successful');
          this.spinner.hide();
        });
    }
  }

  exportCSV(){

    const csv_datatest = [];
    csv_datatest.push({
      'Date (mm/dd/yyyy)': '',
      'Time From (hh:mm)': '',
      'Time To (hh:mm)': '',
      'Meter From':'',
      'Meter To':'',
      'Drilling (hours)':'',
      'Drilling (minutes)':'',
      'Pulling & Lowering (hours)':'',
      'Pulling & Lowering (minutes)':'',
      'Maintenance (hours)':'',
      'Maintenance (minutes)':'',
      'Mobilisation (hours)':'',
      'Mobilisation (minutes)':'',
      'Pre-Start Checks (hours)':'',
      'Pre-Start Checks (minutes)':'',
      'Others (hours)':'',
      'Others (minutes)':'',
      'Comments': ''
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      filename: 'timeandmotion',
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csv_datatest);
  }


  deleteRecordRequest(timeAndMotion: TimeAndMotion) {
    this.selectedTimeAndMotionRecord = timeAndMotion;
    $('#deleteModal').modal({backdrop: 'static'});
    $('#deleteModal').modal('show');
  }

  deleteCsvRecord(timeAndMotion: TimeAndMotion) {
    this.selectedCvsRecord = timeAndMotion;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDataSource.data.splice(itemIndex, 1);
    }
    this.csvDataSource = new MatTableDataSource(this.csvRecords);
    this.toaster.success('Record deleted', 'Record Deletion');
  }

  deleteRecord() {
    this.timeAndMotionService.deleteTimeAndMotion(this.selectedTimeAndMotionRecord).subscribe(data => {
        const itemIndex = this.timeAndMotionRecords.findIndex(item => item.id === this.selectedTimeAndMotionRecord.id);
        if (itemIndex !== -1) {
          this.timeAndMotionRecords.splice(itemIndex, 1);
        }
        this.dataSource = new MatTableDataSource(this.timeAndMotionRecords);
        this.resetPaginator();
        $('#deleteModal').modal('hide');
        this.toaster.success('Record deleted', 'Record Deletion');
      },
      error => {
        this.toaster.error('Record Failed', 'Record Deletion');
      });
  }

  viewTimeAndMotion(timeAndMotion){
    const urlParams = { id: timeAndMotion.id };
    this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/timeandmotion/details', urlParams]);
  }


  getAllTimeAndMotionByBoreholeId(borehole) {
    this.sumDurationHours = 0;
    this.sumDurationMinutes = 0;
    this.totalDuration = [];
    this.timeAndMotionService.getAllByBorehole(borehole).subscribe(data => {
      this.timeAndMotionRecords = data as TimeAndMotion[];
      this.dataSource.data = data as TimeAndMotion[];
      this.loading = false;
    }, error => {
      this.loading = false;
    }, () => {
      this.timeAndMotionRecords.forEach((key) => {
        this.totalDuration.push(key.duration);
      });
      this.totalDuration.forEach(key => {
        this.sumDurationHours += Math.floor(parseFloat(key));
        this.sumDurationMinutes += Math.ceil((parseFloat(key) - Math.floor(parseFloat(key)))*60);
      });
      this.loading = false;
    });
  }

  showModal(): void {
    $('#deleteModal').modal({backdrop: 'static'});
    $('#deleteModal').modal('show');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
