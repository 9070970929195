import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import {DrillerService} from '../../driller.service';
import {DrillingRequest} from 'src/app/shared-kernel/entity/driller/drilling-request';
import {DrillerValidation} from '../../driller.validation';
import {DrillingRequestStatusEnum} from 'src/app/shared-kernel/enumerations/drilling-request-status-enum';
import {CompanyService} from 'src/app/applied-geoscience/company/company.service';
import {ProjectService} from 'src/app/applied-geoscience/project/project.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute} from '@angular/router';
import {DrillingRequestCreateComponent} from '../drilling-request-create/drilling-request-create.component';
import {UserBusinessUnits} from '../../../_auth/services/user-business-units';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Session} from '../../../shared-kernel/services/usermanager/session';
import {UserService} from '../../../auth/user/user.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {LocalAuthorities} from '../../../_auth/_providers';
import { DrillingRequestBorehole } from '../../../shared-kernel/entity/driller/drilling-request-borehole';
import { AddDRBoreholeComponent } from '../drilling-request-boreholes/add-drborehole/add-drborehole.component';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';

@Component({
  selector: 'app-drilling-request-list',
  templateUrl: './drilling-request-list.component.html',
  styleUrls: ['./drilling-request-list.component.scss']
})
export class DrillingRequestListComponent implements OnInit {

  dataSourceDrillingRequests = new MatTableDataSource();
  displayedDrillingRequestColumns = ['#', 'Drilling Date', 'Drilling Method', 'Borehole Type',
    'Request Status', 'View', 'Edit', 'Delete'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  validation_messages = this.drillerValidator.validation_messages;

  drillingRequest: DrillingRequest;
  drillingRequests: DrillingRequest[];
  drillingRequestDetails: DrillingRequest;
  loading = true;
  drillingRequestStatusEnum: typeof DrillingRequestStatusEnum = DrillingRequestStatusEnum;
  projectId: string;
  project: Project = { id: '', creator: '' };

  constructor(
    private drillerService: DrillerService,
    private drillerValidator: DrillerValidation,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private spinner: NgxSpinnerService,
    private userBusinessUnits: UserBusinessUnits,
    private session: Session,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private _localStorage: LocalStorage,
    private dialog: MatDialog,
    private pageRoute: ActivatedRoute,
    private _localAuthorities: LocalAuthorities
  ) {
  }

  ngOnInit() {
    this.pageRoute.params.subscribe(params => {
      this.projectId = params.id;
      this.getDrillingRequestsByProjectId();
      this.getProject(this.projectId);
    });
  }


  ngAfterViewInit() {
    this.dataSourceDrillingRequests.sort = this.sort;
    this.dataSourceDrillingRequests.paginator = this.paginator;
  }

  resetPaginator() {
    this.dataSourceDrillingRequests.sort = this.sort;
    this.dataSourceDrillingRequests.paginator = this.paginator;
  }

  getDrillingRequestsByProjectId() {
    this.drillerService.getDrillingRequestsByProjectId(this.projectId)
      .subscribe(data => {
        this.drillingRequests = data as DrillingRequest[];
        this.dataSourceDrillingRequests.data = this.drillingRequests;
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }


  openDrillingRequestForm(drillingRequest: any) {

    let drillingRequestTemp = new DrillingRequest();
    drillingRequestTemp.project.id = this.projectId;
    if (drillingRequest !== '') {
      drillingRequestTemp = drillingRequest;
    }
    const createDialogRef = this.dialog.open(DrillingRequestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: drillingRequestTemp
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getDrillingRequestsByProjectId();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceDrillingRequests.filter = filterValue.trim().toLowerCase();
  }

  addBorehole(drillingRequest) {
    const drillingRequestBoreholeData: DrillingRequestBorehole = new DrillingRequestBorehole();

    drillingRequestBoreholeData.drillingRequest = drillingRequest;
    const createDialogRef = this.dialog.open(AddDRBoreholeComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: drillingRequestBoreholeData
    });


  }

  openViewDetail(drillingRequest: DrillingRequest) {
    this.drillingRequest = drillingRequest as DrillingRequest;
    const urlParams = {id: drillingRequest.id};

    this.router.navigate(['portal/drilling/request/detail', urlParams]);
  }

  private getProject(id: string) {
    this.projectService.getProjectById(id).subscribe(res => {
      this.project = res as Project;
    });
  }
}
