import { Size } from '../../common/size';
import { Borehole } from '../../common/borehole';
import { RockType } from './RockType';
import { Notes } from '../notes';

export class WaterBoreholeLithologyLog {
  id: string;
  grainSize: string;
  color: string;
  freshness: string;
  mineralogy: string;
  depthFrom: Size = new Size();
  depthTo: Size = new Size();
  date: Date = new Date();
  lithologyDescription: Notes = new Notes();
  borehole: Borehole = new Borehole();
  rockType: RockType = new RockType();
}
