import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../entity/applied-geoscience/company/company';


@Pipe({
    name: "companyAlphabeticalOrder"
})
export class CompanyAlphabeticalOrderPipe implements PipeTransform {
    transform(value: Company[]) {
        return value ? value.sort((a, b) => (a.name > b.name) ? 1 : -1) : [];
    }
}