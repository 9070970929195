import { Size } from 'src/app/shared-kernel/entity/common/size';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';

export class DiameterFormation {
    id: string;
    depthFrom: Size;
    depthTo: Size;
    diameter: Size;
    deleted: boolean;
    borehole: Borehole;
}
