import { SoilElements } from './soil-elements';
import { TrialPit } from './trial-pit';

export class SoilChemistry {
  trialPit: TrialPit = new TrialPit();
  sampleId: string = '';
  soilElements: SoilElements [] = [];
  constructor() {
  }
}
