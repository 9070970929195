import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ScheduleService} from '../../../services/schedule.service';
import {ToastrService} from 'ngx-toastr';
import { Person, User } from '../../../../_core/data/_models/people.model';
import {CompanyEnum} from '../../../../shared-kernel/enumerations/company-enum';
import {PeopleService} from '../../../../_core/mock/people.service';
import {UserService} from '../../../../auth/user/user.service';
import {AddAttendantsComponent} from '../add-attendants/add-attendants.component';
import {MatDialogConfig} from '@angular/material/dialog';
import {CompanyService} from '../../../../applied-geoscience/company/company.service';
import { StorageConstants } from '../../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-close-request-form',
  templateUrl: './close-request-form.component.html',
  styleUrls: ['./close-request-form.component.scss']
})
export class CloseRequestFormComponent implements OnInit {

  closeRequestForm: FormGroup = new FormGroup({
    status: new FormControl(true, Validators.required),
    amountPaid: new FormControl(null),
    assistanceRendered: new FormControl(null, Validators.required),
    closeComments: new FormControl(null),
    servicedBy: new FormControl(null, Validators.required),
    actualAttendants: new FormControl(null),
    serviceRenderedBy: new FormControl(null)
  });

  people: any[] = [];
  loadingPeople = false;

  attendants: Person[] = [];
  dialogConfig = new MatDialogConfig();
  currentUser: User;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<CloseRequestFormComponent>, private companyService: CompanyService,
              private peopleService: PeopleService, private userService: UserService,
              private scheduleService: ScheduleService, private toastr: ToastrService,private _localStorage: LocalStorage) {
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res as User;
      this.getAllBGIPersonnel();
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '500px';
    this.dialogConfig.height = '210px';

  }

  removeAttendant(attendant: any) {
    const index = this.attendants.indexOf(attendant);
    if (index >= 0) {
      this.attendants.splice(index, 1);
    }
  }

  /**
   *
   * @param personType - individual or company
   */
  onCreate(personType: string): void {
    this.dialogConfig.data = {
      personType: personType,
      attendants: this.attendants
    };

    this.dialog.open(AddAttendantsComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.attendants = result;
    });
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getAllBGIPersonnel() {
    this.loadingPeople = true;
    this.peopleService.getAllPersonsLiteByBusinessUnit(this.currentUser.businessUnit.name).subscribe(
      value => {
        this.people = value as [];
        this.loadingPeople = false;
      }
    );
/*    this.companyService.getCompanyByName(CompanyEnum.BGI_NAME).subscribe(company => {
      this.userService.getPersonsByCompanyId(company.id).subscribe(
        data => {
          this.loadingPeople = false;
          let temppeople = data as Person[];
          temppeople.forEach(value => {
              if(value.user){
                  if(value.user.businessUnit.id === this.currentUser.businessUnit.id ){
                      this.people.push(value);
                  }
              }
          });
        }
      );
    });*/
  }

  onSubmit() {
    const request = this.closeRequestForm.value;

    const attendantsID = [];
    this.attendants.forEach(attendant => {
      attendantsID.push(attendant.id);
    });

    const servicedByIDs = [];
    if (request.servicedBy) {
      request.servicedBy.forEach(person => {
        servicedByIDs.push(person[0]);
      });
    }

    if (this.data.request) {
      this.data.request.assistanceRendered = request.assistanceRendered;
      this.data.request.amountPaid = request.amountPaid;
      this.data.request.status = request.status;
      this.data.request.actualAttendants = attendantsID;
      this.data.request.closeComments = request.closeComments;
      this.data.request.servicedBy = servicedByIDs;
      this.data.request.serviceRenderedBy = request.serviceRenderedBy;

      this.scheduleService.updateCustomerRequest(this.data.request).subscribe(
        res => {
          this.toastr.success('Customer request updated successfully', 'Update Request');
          this.onClose();
        }, error => {
          this.toastr.error(error.statusText, 'Update Request');
        }
      );
    }
  }
}
