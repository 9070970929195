import { Router } from '@angular/router';
import Swal,{ SweetAlertOptions } from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Borehole } from './../../../../../shared-kernel/entity/common/borehole';
import { WaterPumpTestStepTestCreateComponent } from './../water-pump-test-step-test-create/water-pump-test-step-test-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { WaterPumpingTestStepTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestStepTest';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { HydrogeologyValidation } from '../../../hydrogeology.validation';
import { WaterPumpingTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTest';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScreeningLog } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/ScreeningLog';
import { ScreeningMaterial } from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/ScreeningMaterial';
import { Unit } from '../../../../../shared-kernel/entity/common/unit';
import { Size } from '../../../../../shared-kernel/entity/common/size';
import * as moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Person } from '../../../../../common/entity/security/profile/person';
import { UserService } from '../../../../../auth/user/user.service';
declare var $: any;

@Component({
  selector: 'app-water-pump-test-step-test-list',
  templateUrl: './water-pump-test-step-test-list.component.html',
  styleUrls: ['./water-pump-test-step-test-list.component.scss']
})
export class WaterPumpTestStepTestListComponent implements OnInit {
  dataSourceWaterPumpingTestStepTest: MatTableDataSource<WaterPumpingTestStepTest> = new MatTableDataSource();
  displayedWaterPumpingTestStepTestColumns = ['#', 'stepNumber', 'depthToWater', 'drawdown', 'pumpingRate', 'timeToFillDuration', 'edit', 'view', 'delete'];
  modalColumns: string[] = ['#', 'stepNumber', 'depthToWater', 'drawdown', 'pumpingRate', 'timeToFillDuration','timeToFillLitres'
    ,'staticWaterLevel','startDate','startTime','endDate','endTime'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  csvDataSource: MatTableDataSource<WaterPumpingTestStepTest> = new MatTableDataSource();

  csvRecords: WaterPumpingTestStepTest[] = [];
  @ViewChild('fileImportInput') fileImportInput: any;

  url: string;
  waterPumpingTestStepTests: WaterPumpingTestStepTest[];
  urlData = {waterPumpingTestId: null, boreholeId: null};
  loading = true;
  pumpingRate: number;
  persons: Person[];
  unitEnum: typeof UnitEnum = UnitEnum;
  selectedCvsRecord: WaterPumpingTestStepTest = new WaterPumpingTestStepTest();

  @Input('borehole') borehole: Borehole;
  @Input('projectId') projectId: string = '';

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private hydrogeologyValidation: HydrogeologyValidation,
    private dialog: MatDialog,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    ) {}

  validation_messages = this.hydrogeologyValidation.validation_messages;

  ngOnInit() {
    this.getWaterTestingStepTests();
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  resetPaginator() {
     this.dataSourceWaterPumpingTestStepTest.sort = this.sort;
     this.dataSourceWaterPumpingTestStepTest.paginator = this.paginator;
  }

  openWaterPumpingTestForm() {
    const obj = {test: null, borehole:this.borehole, transactionType:'New'};
    const newGeoHeritageDialogRef = this.dialog.open(WaterPumpTestStepTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getWaterTestingStepTests();
    });
  }

  getAllGeologists() {
    this.userService.getPersons()
      .subscribe(data => {
        this.persons = data as Person[];
      });
  }

  openWaterPumpingTestEditForm(waterPumpingTestStepTest: any) {
    const obj = {test: waterPumpingTestStepTest, borehole:this.borehole, transactionType:'Edit'};
    const newGeoHeritageDialogRef = this.dialog.open(WaterPumpTestStepTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getWaterTestingStepTests();
    });
  }

  deleteCsvRecord(drl: WaterPumpingTestStepTest) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDataSource.data.splice(itemIndex, 1);
    }
    this.csvDataSource = new MatTableDataSource(this.csvRecords);
    this.toastr.success('Record deleted', 'Record Deletion');
  }

  openWaterPumpingTestDetails(waterPumpingTestId) {
    let borehole = {id: null};
    borehole = this.borehole as Borehole;
    let urlObject = {waterPumpingTestId: null, boreholeId: null, boreholeNumber: null};
    urlObject = {waterPumpingTestId: waterPumpingTestId, boreholeId: this.borehole.id, boreholeNumber: this.borehole.boreholeNumber};
    this.router.navigate(['portal/applied-geoscience/hydrogeology/water-pump-test/step-test/view', urlObject]);
  }

  getWaterTestingStepTests(){
    this.spinner.show();
    this.hydrogeologyService.getAllWaterPumpingTestStepTestByBoreholeId(this.borehole.id)
      .subscribe(data => {
        this.waterPumpingTestStepTests = data as WaterPumpingTestStepTest[];
        this.dataSourceWaterPumpingTestStepTest.data = this.waterPumpingTestStepTests;
        this.dataSourceWaterPumpingTestStepTest.sort = this.sort;
        this.dataSourceWaterPumpingTestStepTest.paginator = this.paginator;
        this.getAllGeologists();
        this.loading = false;
        this.spinner.hide();
      }, error => {
        this.loading = false;
        this.spinner.hide();
      }, () => {
        this.loading = false;
        this.spinner.hide();
      });
  }

  onDelete(waterPumpingTestStepTest) {
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestStepTestOne(waterPumpingTestStepTest).subscribe(() => {
              this.getWaterTestingStepTests();
              this.toastr.success('Delete Water Pumping Test', 'You have Successfully deleted Water Pumping Test');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterPumpingTestStepTests(this.dataSourceWaterPumpingTestStepTest.data).subscribe(() => {
              this.getWaterTestingStepTests();
              this.toastr.success('Delete Water Pumping Tests', 'You have Successfully deleted Water Pumping Tests');
            }, err => {
                this.toastr.error(err.message, "Failed Delete");
            });
        }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceWaterPumpingTestStepTest.filter = filterValue.trim().toLowerCase();
  }


  importCSVFile() {
    $('#uploadCsv').click();
  }

  exportCSV(){

    const csv_datatest = [];
    csv_datatest.push({
      'Step Number': '',
      'Depth To Water (m)': '',
      'Draw Down ':'',
      'Pumping Rate (m3/Hr)':'',
      'Start Date e.g 9/30/2022 (mm/dd/yyyy)':'',
      'End Date (mm/dd/yyyy)':'',
      'Start Time eg 16:45 (hh:mm)':'',
      'End Time (hh:mm)':'',
      'Static Water Level':'',
      'Time To Fill Litres (10|20|50|100|200)':'',
      'Time To Fill Duration':'',
      'Pump Type ':'',
      'Geologist ':'',
      'Comments ':''
    });


    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      filename : 'step-Test',
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csv_datatest);
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

  onSubmit(param){

  }

  showCsvModal(): void {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }


  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        const waterPumpingTestStepTestCsv = new WaterPumpingTestStepTest();
        let startDateHolder = data[6].split(':');
        let endDateHolder = data[7].split(':');
        let person = this.persons.find(value =>  value['fullName'].toLowerCase().trim() === data[12].trim().toLowerCase().trim());
        if(this.dataSourceWaterPumpingTestStepTest.data.find(value => value.stepNumber=== data[0])) {
            this.toastr.warning('Step Number already exists : <'+data[0]+'>','Inaccurate Record ');
        }else{
          if(person){
            waterPumpingTestStepTestCsv.geologist = person.id;
            if(endDateHolder && startDateHolder){
              waterPumpingTestStepTestCsv.borehole = this.borehole;
              waterPumpingTestStepTestCsv.stepNumber = data[0]  ;
              waterPumpingTestStepTestCsv.depthToWater = new Size();
              waterPumpingTestStepTestCsv.depthToWater.unit = new Unit();
              waterPumpingTestStepTestCsv.depthToWater.unit.id = this.unitEnum.meter;
              waterPumpingTestStepTestCsv.depthToWater.measure = '' + parseFloat(data[1].trim());

              waterPumpingTestStepTestCsv.drawdown = new Size();
              waterPumpingTestStepTestCsv.drawdown.unit = new Unit();
              waterPumpingTestStepTestCsv.drawdown.unit.id = this.unitEnum.meter;
              waterPumpingTestStepTestCsv.drawdown.measure = '' + parseFloat(data[2].trim());

              waterPumpingTestStepTestCsv.pumpType = data[11];
              waterPumpingTestStepTestCsv.comments = data[13];

              waterPumpingTestStepTestCsv.pumpingRate = new Size();
              waterPumpingTestStepTestCsv.pumpingRate.unit = new Unit();
              waterPumpingTestStepTestCsv.pumpingRate.unit.id = this.unitEnum.pumpRate;
              waterPumpingTestStepTestCsv.pumpingRate.measure = '' + parseFloat(data[3].trim());

              waterPumpingTestStepTestCsv.endDate = new Date(data[5]);
              waterPumpingTestStepTestCsv.startDate = new Date(data[4]);
              waterPumpingTestStepTestCsv.startTime = new Date(new Date(2020,1,1,Number(startDateHolder[0]),Number(startDateHolder[1])));
              waterPumpingTestStepTestCsv.endTime = new Date(new Date(2020,1,1,Number(endDateHolder[0]),Number(endDateHolder[1])));

              waterPumpingTestStepTestCsv.staticWaterLevel = parseFloat(data[8]);
              waterPumpingTestStepTestCsv.timeToFillDuration = parseFloat(data[10]);
              waterPumpingTestStepTestCsv.timeToFillLitres = new Size();
              waterPumpingTestStepTestCsv.timeToFillLitres.unit = new Unit();
              waterPumpingTestStepTestCsv.timeToFillLitres.unit.id = this.unitEnum.liter;
              waterPumpingTestStepTestCsv.timeToFillLitres.measure = data[9];

              dataArr.push(waterPumpingTestStepTestCsv);
            }
          }else{
            this.toastr.warning('Geologist not in the system : <'+data[12].trim()+'>','Inaccurate Record ');
          }
        }

      }
    }
    this.csvDataSource.data = dataArr;

    return dataArr;
  }



  saveCsvRecords() {

    this.saveBatch(this.csvRecords);
    this.fileReset();

    $('#csvModal').modal('hide');
  }

  saveBatch(records: WaterPumpingTestStepTest []) {
    this.spinner.show();
    this.hydrogeologyService.createWaterPumpingTestStepTest(records).subscribe(value => {
      this.spinner.hide();
      this.toastr.success('Records saved successfully','Record Import');
      this.getWaterTestingStepTests();
    })

  }

}
