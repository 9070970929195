import { PersonnelCreateComponent } from './../../../../../personnel/personnel-create/personnel-create.component';
import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CoordinatesType } from 'src/app/shared-kernel/entity/common/coordinatesType';
import { Coordinate } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinate';
import { CoordinatesTypesEnum } from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import { TrialPit } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { CoordinatesService } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import LatLon from 'geodesy/latlon-nvector-spherical.js';
import { NigisImages } from 'src/app/shared-kernel/entity/common/nigisImages';
import { SoilLogService } from '../soil-log/soil-log.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatChipInputEvent,
  MatAutocomplete, MatSelect, MatDialog, MatDialogConfig } from '@angular/material';
import { LocationTemp } from 'src/app/shared-kernel/entity/common/locationTemp';
import { Coordinates } from 'src/app/shared-kernel/entity/common/coordinates';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { Unit } from 'src/app/shared-kernel/entity/common/unit';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import { Village } from 'src/app/shared-kernel/entity/common/Village';
import { Notes } from 'src/app/shared-kernel/entity/applied-geoscience/notes';
import { SoilRecord } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log-record';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';
import { ActiveFlagEnum } from 'src/app/shared-kernel/enumerations/active-flag-enum';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { CommonService } from 'src/app/common/common.service';
import { District } from 'src/app/common/entity/security/util/common/district';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Person } from '../../../../../_core/data/_models/people.model';
import { Router } from '@angular/router';
import { ExcavationMethod } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/excavation-method';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { ProjectService } from 'src/app/applied-geoscience/project/project.service';
import { UserService } from 'src/app/auth/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerRresponse } from 'src/app/shared-kernel/error-handling/serverRresponse';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { User } from '../../../../../_core/data/_models/people.model';
import { Company } from '../../../../../shared-kernel/entity/applied-geoscience/company/company';
import { PersonnelSortPipe } from '../../../../../shared-kernel/pipes/personnel.sort.pipe';
import { ProjectMembers } from './../../../../project/project-detail/project-members/project-member.model';
import { PeopleData } from './../../../../../_core/data/people';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-trial-pit-create',
  templateUrl: './trial-pit-create.component.html',
  styleUrls: ['./trial-pit-create.component.scss']
})
export class TrialPitCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  title: string = '';
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  trialPitForModal: TrialPit;
  //trialPitForModalEdit: TrialPit;
  soilLogRecord: SoilRecord;
  soilLogRecords: SoilRecord[];
  district: District = new District();
  districts: District[];
  village: string;
  villages: Village[];
  filteredVillages: Observable<Village[]>;
  filteredDistricts: Observable<District[]>;
  selectable = true;
  removable = true;
  addOnBlur = true;
  trialPitLocationImageCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  projectManagers: Person[];
  projectCoordinators: Person[];
  projectGeologists: Person[];
  disableGeologistDropdown: boolean = false;
  public currentPerson: Person;
  public currentUser: User;
  projectGeologist: Person;

  trialPit: TrialPit;
  project: Project;
  projects: Project[];

  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];

  dmsSx: number; // decimal minutes seconds, to hold x/longitude seconds value
  dmsMx: number; // decimal minutes seconds, to hold x/longitude minutes value
  dmsDx: number; // decimal minutes seconds, to hold x/longitude decimal value

  dmsSy: number; // decimal minutes seconds, to hold y/latitude seconds value
  dmsMy: number; // decimal minutes seconds, to hold y/latitude minutes value
  dmsDy: number; // decimal minutes seconds, to hold y/latitude decimal value

  zone34SNorthing: number;
  zone34SEasting: number;

  zone35SNorthing: number;
  zone35SEasting: number;

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  trialPitLocationImage: NigisImages;
  trialPitLocationImages: NigisImages[] = [];
  recordEdit = false;
  activeFlagEnum: typeof ActiveFlagEnum = ActiveFlagEnum;
  enumUnits: typeof UnitEnum = UnitEnum;
  excavationMethods: ExcavationMethod[];
  serverError: ServerRresponse;
  allCompanies: Company [];
  projectStillLoading = true;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('trialPitLocationImageInput') trialPitLocationImageInput: ElementRef<HTMLInputElement>;

  validation_messages = {
    'project': [
      { type: 'required', message: 'Please select project' }
    ],
    'trialPitNumber': [
      { type: 'required', message: 'Trial pit number can not be empty' }
    ],
    'latitude': [
      { type: 'required', message: 'Latitude can not be empty' },
    ],
    'longitude': [
      { type: 'required', message: 'Longitude can not be empty' },
    ],
    'district': [
      { type: 'required', message: 'District can not be empty' },
    ],
    'village': [
      { type: 'required', message: 'Village can not be empty' },
    ],
    'stability': [
      { type: 'required', message: 'Stability can not be empty' },
    ],
    'excavationMethods': [
      { type: 'required', message: 'Please select excavation methods' },
    ],
    'excavationEquipment': [
      { type: 'required', message: 'Excavation Equipment can not be empty' },
    ],
    'groundWaterObservation': [
      { type: 'required', message: 'groundWaterObservation can not be empty' },
    ],
    'projectManager': [
      { type: 'required', message: 'project Manager can not be empty' },
    ],
    'coordinator': [
      { type: 'required', message: 'Coordinator can not be empty' },
    ],
    'elevation': [
      { type: 'required', message: 'elevation can not be empty' },
    ],
    'geologist': [
      { type: 'required', message: 'Geologist can not be empty' },
    ],
    'locationDescription': [
      { type: 'required', message: 'location description can not be empty' },
    ],
    'generalComments': [
      { type: 'required', message: 'General Comments can not be empty' },
    ],
    'date': [
      { type: 'required', message: 'Please insert date' },
    ],
    'number': [
      { type: 'required', message: 'Please insert a number' },
    ],
    'length': [
      { type: 'required', message: 'Should at least be 5 letters' },
    ],
    'type': [
      { type: 'required', message: 'Select Coordinates Type' },
    ],
    'format': [
      { type: 'required', message: 'Select Coordinates Format' },
    ],
    'zone34SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone34SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'zone5SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone35SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'dmsSx': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMx': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDx': [
      { type: 'required', message: 'Type degree value' },
    ],
    'dmsSy': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMy': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDy': [
      { type: 'required', message: 'Type degree value' },
    ],
  };

  projectMembers: Array<ProjectMembers> = [];
  personMembers: Array<Person> = [];

  // filteredManagers: Array<Person> = [];
  filteredManagers: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredCoordinators: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredGeologists: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  currentManager: FormControl = new FormControl();
  managerFilter: FormControl = new FormControl();

  currentCoordinator: FormControl = new FormControl();
  coordinatorFilter: FormControl = new FormControl();

  currentGeologist: FormControl = new FormControl();
  geologistFilter: FormControl = new FormControl();

  @ViewChild('managerSelect') managerSelect: MatSelect;
  @ViewChild('coordinatorSelect') coordinatorSelect: MatSelect;
  @ViewChild('geologistSelect') geologistSelect: MatSelect;
  protected _onDestroy = new Subject<void>();

  constructor(
    private coordinatesService: CoordinatesService,private personnelSortPipe: PersonnelSortPipe,
    private soilLogService: SoilLogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TrialPitCreateComponent>,
    private commonService: CommonService,
    private router: Router,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private _localStorage: LocalStorage,
    private cd: ChangeDetectorRef,
    private peopleSrv: PeopleData,
    private dialog: MatDialog) {}

  ngOnInit() {
    this.getBotswanaGeofence();
    this.getAllExcavationMethods();
    this.getAllDistricts();
    this.getCompanies();
    this.getAllVillages();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.getProjectsByMember(this.currentUser.username);
      }, err => {
        this.toastr.warning(err.message, "Current User Error");
      },() => {

      });

    if (this.data) {
      this.title = 'Edit Trial Pit';
      this.trialPitForModal = this.data as TrialPit;

      this.selectedCoordinatesType = this.data.coordinatesFormat.type;
      this.cd.detectChanges();
      this.trialPitForModal.startDate = new Date(this.trialPitForModal.startDate);
      this.trialPitForModal.endDate = new Date(this.trialPitForModal.endDate);
      this.trialPitLocationImages = this.trialPitForModal.locationImages;
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lon;
      }
    } else {
      this.title = 'Create Trial Pit';
      this.trialPitForModal = this.createTrialPitObject();
    }

    this._localStorage.getItem(StorageConstants.personKey).subscribe(person => {
      this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
        this.currentUser =res;
        this.currentPerson = person;
        if (this.currentPerson) {
          if (this.currentUser) {
            for (let role of this.currentUser.roles) {
              if (role.name === 'Engineering Geologist') {
                this.disableGeologistDropdown = true;
                this.trialPitForModal.geologist = this.currentPerson.id;
                this.projectGeologist.id = this.currentPerson.id;
              }
            }
          }
        }
      }, err => {
        this.toastr.warning(err.message, "Current User Error");
      });
    }, err => {
      this.toastr.warning(err.message, "Current Person Error");
    });

    this._getProjectMembers();
  }

  ngAfterViewInit() {
    this._setInitialManager();
    this._setInitialCoordinator();
    this._setInitialGeologist();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCompanies(){
    this.companyService.getAllCompanies().subscribe(value => {
      this.allCompanies = value as Company [];
    },error => {},() => {
      this.getAllPersons();
    })
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });
  }

  getAllExcavationMethods() {
    this.soilLogService.getAllExcavationMethods()
      .subscribe(data => {
        this.excavationMethods = data as ExcavationMethod[];
      });
  }

  getAllDistricts() {
    this.companyService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        this.filteredDistricts = of(this.districts);
      });
  }

  getAllProjects() {
    this.projectService.getAllProjects()
      .subscribe(data => {
        this.projects = data as Project[];
        
      });
  }

  getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user).subscribe(res => {
      this.projects = res as Project[];
      this.projectStillLoading = false;
    }, err => {
    });
  }

  getAllPersons() {
    this.userService.getPersons()
      .subscribe(data => {
        let projectGeologists = data as Person[];
        this.projectGeologists = this.personnelSortPipe.transform(this.allCompanies, projectGeologists);
        this.projectManagers = this.projectGeologists;
        this.projectCoordinators = this.projectGeologists;
      });
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        this.filteredVillages = of(this.villages);
      });
  }

  createTrialPitObject(): TrialPit {
    this.trialPit = new TrialPit();
    this.trialPit.location = new LocationTemp();
    this.trialPit.location.coordinates = new Coordinates();
    this.trialPit.location.coordinates.latitude = '';
    this.trialPit.location.coordinates.longitude = '';
    this.trialPit.elevation = new Size();
    this.trialPit.elevation.unit = new Unit();
    this.trialPit.elevation.unit.id = this.enumUnits.meter;
    this.trialPit.project = new Project();
    this.trialPit.project.name = '';
    this.trialPit.location.village = new Village();
    this.trialPit.projectManager = '';
    this.trialPit.geologist = '';
    this.trialPit.coordinator = '';
    this.trialPit.locationDescription = new Notes();
    this.trialPit.locationDescription.description = '';
    this.trialPit.generalComments = '';
    this.soilLogRecord = new SoilRecord();
    this.trialPit.active = this.activeFlagEnum.Active;
    this.trialPit.coordinatesFormat = new CoordinatesFormat();


    return this.trialPit;
  }

  onSubmit() {
    if(this.trialPitForModal.id){
      if (this.currentManager) {
        if (this.currentManager.value && this.trialPitForModal.projectManager !== this.currentManager.value.id) {
          this.trialPitForModal.projectManager = this.currentManager.value.id;
        }
      }

      if (this.currentCoordinator) {
        if (this.currentCoordinator.value && this.trialPitForModal.coordinator !== this.currentCoordinator.value.id) {
          this.trialPitForModal.coordinator = this.currentCoordinator.value.id;
        }
      }

      if (this.currentGeologist) {
        if (this.currentGeologist.value && this.trialPitForModal.geologist !== this.currentGeologist.value.id) {
          this.trialPitForModal.geologist = this.currentGeologist.value.id;
        }
      }

      this.updateRecord(this.trialPitForModal);
    } else {
      this.trialPitForModal.projectManager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
      this.trialPitForModal.coordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
      this.trialPitForModal.geologist = this.currentGeologist.value && this.currentGeologist.value.id ? this.currentGeologist.value.id : '';
      this.createNewRecord(this.trialPitForModal);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createNewRecord(trialPit: TrialPit) {
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
      this.trialPitForModal.location.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.trialPitForModal.location.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.trialPitForModal.location.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.trialPitForModal.location.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.trialPitForModal.location.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.trialPitForModal.location.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }
    this.trialPitForModal.location.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.trialPitForModal.location.coordinates.latitude,
        this.trialPitForModal.location.coordinates.longitude)._lat;
    this.trialPitForModal.location.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.trialPitForModal.location.coordinates.latitude,
        this.trialPitForModal.location.coordinates.longitude)._lon;

    if (new LatLon(this.trialPitForModal.location.coordinates.latitude,
      this.trialPitForModal.location.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      this.spinner.show();
      trialPit.locationImages = this.trialPitLocationImages;
      this.soilLogService.createTrialPit(trialPit).subscribe(data => {
        const trialPitData = data as TrialPit;
        this.spinner.hide();
      }, error => {
        this.serverError = error.error as ServerRresponse;
        this.toastr.error(this.serverError.message, 'Trial Pit Create');
        this.spinner.hide();
      },
        () => {
          this.spinner.hide();
          this.toastr.success('You have Successfully created a Trial Pit', 'Trial Pit Create')
          this.spinner.hide();
          this.trialPitForModal = this.createTrialPitObject();
          this.trialPitLocationImages = [];
          this.dialogRef.close(trialPit);
        }
      );
    } else {
      this.toastr.error(this.coordinatesService.outSide);
    }
    //this.spinner.show();
    if (!trialPit.trialPitFiles) {
      trialPit.trialPitFiles = [];
    }
  }

  updateRecord(trialPit: TrialPit) {
    this.trialPitForModal = trialPit;
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.trialPitForModal.location.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.trialPitForModal.location.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.trialPitForModal.location.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.trialPitForModal.location.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.trialPitForModal.location.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.trialPitForModal.location.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }
    this.trialPitForModal.location.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.trialPitForModal.location.coordinates.latitude,
        this.trialPitForModal.location.coordinates.longitude)._lat;
    this.trialPitForModal.location.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.trialPitForModal.location.coordinates.latitude,
        this.trialPitForModal.location.coordinates.longitude)._lon;

    if (new LatLon(this.trialPitForModal.location.coordinates.latitude,
      this.trialPitForModal.location.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      this.spinner.show();
      trialPit.locationImages = this.trialPitLocationImages;
      this.soilLogService.createTrialPit(trialPit).subscribe(data => {

      }, error => {
        this.toastr.error('Error', error.error.message);
        this.spinner.hide();
      },
        () => {
          this.spinner.hide();
          this.recordEdit = false;
          this.toastr.success('You have Successfully updated a Trial Pit', 'Trial Pit Update')
          this.trialPitForModal = this.createTrialPitObject();
          this.trialPitLocationImages = [];
          this.dialogRef.close(trialPit);
        }
      );
    } else {
      this.toastr.error(this.coordinatesService.outSide);
    }
  }

  compareFn(projectOne: Project, projectTwo: Project): boolean {
    return projectOne && projectTwo ? projectOne.id === projectTwo.id : projectOne === projectTwo;
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesType = selectedData.text;
    this.trialPitForModal.coordinatesFormat.type = this.selectedCoordinatesType;

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
      this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;
    }
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
      this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;
    }

    if (this.recordEdit) {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lon;
      }
    }
  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesFormat = selectedData.text;
    this.trialPitForModal.coordinatesFormat.format = this.selectedCoordinatesFormat;

    if (this.recordEdit) {
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
        this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lat;
        this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35, this.trialPitForModal.location.coordinates.latitude,
          this.trialPitForModal.location.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
        this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.trialPitForModal.location.coordinates.latitude),
          parseFloat(this.trialPitForModal.location.coordinates.longitude))._lonSValue);
        this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.trialPitForModal.location.coordinates.latitude),
          parseFloat(this.trialPitForModal.location.coordinates.longitude))._lonMValue);
        this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.trialPitForModal.location.coordinates.latitude),
          parseFloat(this.trialPitForModal.location.coordinates.longitude))._lonDValue);

        this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.trialPitForModal.location.coordinates.latitude),
          parseFloat(this.trialPitForModal.location.coordinates.longitude))._latSValue);
        this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.trialPitForModal.location.coordinates.latitude),
          parseFloat(this.trialPitForModal.location.coordinates.longitude))._latMValue);
        this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.trialPitForModal.location.coordinates.latitude),
          parseFloat(this.trialPitForModal.location.coordinates.longitude))._latDVale);
      }
    }
  }

  onDistrictChange(districtId) {

    this.filteredVillages = of(this.villages.filter(village => village.district.id === districtId))
  }

  districtChange($event) {

    this.filteredDistricts = of(this._filterDistricts($event));
  }

  villageChange($event) {
    this.filteredVillages = of(this._filterVillages($event));
  }

  private _filterDistricts(value: string): District[] { 
    
    const filterValue = value.toLowerCase();
    return this.districts ? this.districts.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  private _filterVillages(value: string): Village[] {

    const filterValue = value.toLowerCase();

    if(this.villages) {
      return this.villages.filter(option => {

        if(this.trialPitForModal.location.village && this.trialPitForModal.location.village.district && this.trialPitForModal.location.village.district.id) {
          return option.name.toLowerCase().includes(filterValue) && option.district.id === this.trialPitForModal.location.village.district.id;
        } else {
          return option.name.toLowerCase().includes(filterValue);
        }
      });
    } else {
      return [];
    }
  }

  onVillageChange(district: District) {
    for (let i = 0; i < this.districts.length; i++) {
      if (this.districts[i].id === district.id) {
        this.trialPitForModal.location.village.district = {
          id: this.districts[i].id,
          name: this.districts[i].name,
          code: this.districts[i].code
        };
      }
    }
  }

  remove(trialPitLocationImage: NigisImages): void {
    const index = this.trialPitLocationImages.indexOf(trialPitLocationImage);
    if (index >= 0) {
      this.trialPitLocationImages.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      const chipTrialPitLocationImage = new NigisImages;
      chipTrialPitLocationImage.name = value;

      if ((value || '').trim()) {
        this.trialPitLocationImages.push(chipTrialPitLocationImage);
      }

      if (input) {
        input.value = '';
      }
      this.trialPitLocationImageCtrl.setValue(null);
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.trialPitLocationImage = new NigisImages();
          this.trialPitLocationImage.image = fileSplitdata[1];
          this.trialPitLocationImage.name = fileName;
          this.trialPitLocationImages.push(this.trialPitLocationImage);
          if (this.trialPitForModal.locationImages) {
            if (this.trialPitForModal.locationImages.length > 0) {
              this.trialPitForModal.locationImages.push(this.trialPitLocationImage);
            } else {
              this.trialPitForModal.locationImages = this.trialPitLocationImages;
            }
          } else {
            this.trialPitForModal.locationImages = this.trialPitLocationImages;
          }
          //this.trialPitForModal.locationImages = this.trialPitLocationImages;
          this.trialPitLocationImageInput.nativeElement.value = '';
          this.trialPitLocationImageCtrl.setValue(null);
        };
      }
    }
  }

  compareGeologist(valueOne: any, valueTwo: any): boolean {
    return valueOne && valueTwo ? valueOne === valueTwo : valueOne === valueTwo;
  }

  goTo(filter: string) {
    this.router.navigate([`portal/personnel/${filter}`]);
    this.closeDialog();
  }

  newPersonnel() {
    const newPersonnelDialogRef = this.dialog.open(PersonnelCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true
    });

    newPersonnelDialogRef.afterClosed().subscribe((result) => {
      this._getProjectMembers();
    });
  }

  companyChanged() {
    this._getProjectMembers();
  }

  filterManager() {
    if (!this.personMembers) return;

    let search = this.managerFilter.value;

    if (!search) {
      this.filteredManagers.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredManagers.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterCoordinators() {
    if (!this.personMembers) return;

    let search = this.coordinatorFilter.value;

    if (!search) {
      this.filteredCoordinators.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCoordinators.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterGeologist() {
    if (!this.personMembers) return;

    let search = this.geologistFilter.value;

    if (!search) {
      this.filteredGeologists.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGeologists.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  comparePerson(a: Person, b: Person) {
    return a && b && a.id === b.id;
  }

  protected _setInitialManager() {
    this.filteredManagers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.trialPitForModal.id) {
        // Edit
        let manager = this.personMembers.find(pM => pM.id === this.trialPitForModal.projectManager);

        if (!manager) {
          found = false;
        } else {
          found = true;

          this.currentManager.setValue(manager);
          this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let manager = this.personMembers.find(pM => this._isOfRole(pM, 'Project Manager'));

      if (!manager) return;

      this.currentManager.setValue(manager);
      this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialCoordinator() {
    this.filteredCoordinators.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.trialPitForModal.id) {
        // Edit
        let coordinator = this.personMembers.find(pM => pM.id === this.trialPitForModal.coordinator);

        if (!coordinator) {
          found = false;
        } else {
          found = true;

          this.currentCoordinator.setValue(coordinator);
          this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let coordinator = this.personMembers.find(pM => this._isOfRole(pM, 'Project Coodinator'));

      if (!coordinator) return;

      this.currentCoordinator.setValue(coordinator);
      this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialGeologist() {
    this.filteredGeologists.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.trialPitForModal.id) {
        // Edit
        let geologist = this.personMembers.find(pM => pM.id === this.trialPitForModal.geologist);

        if (!geologist) {
          found = false;
        } else {
          found = true;

          this.currentGeologist.setValue(geologist);
          this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let geologist = this.personMembers.find(pM => this._isOfRole(pM, 'Geologist'));

      if (!geologist) return;

      this.currentGeologist.setValue(geologist);
      this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  private _getProjectMembers() {
    if (this.trialPitForModal.project) {
      this.projectService.getProjectMembers(this.trialPitForModal.project.id).subscribe(res => {
        this.projectMembers = res as Array<ProjectMembers>;
        this._getProjectPeople(this.projectMembers);
      }, err => {
        this.toastr.error(err.message, "Project Member Fail");
      });
    }
  }

  private _getProjectPeople(members: Array<ProjectMembers>) {
    let ids = [];

    members.forEach(m => {
      ids.push(m.personId);
    });

    if (ids.length <= 0) return;

    this.peopleSrv.getPeopleByIdList(ids).subscribe(res => {
      this.personMembers = res;
      // this.filteredManagers = res;
      this.filteredManagers.next(res.slice());
      this.managerFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterManager();
      });

      this.filteredCoordinators.next(res.slice());
      this.coordinatorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCoordinators();
      });

      this.filteredGeologists.next(res.slice());
      this.geologistFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterGeologist();
      });
    }, err => {
      this.toastr.error(err.message, "Project People Fail");
    });
  }

  private _isOfRole(person: Person, role: string) {
    let member = this.projectMembers.find(pM => pM.personId === person.id && pM.deleted === false);

    if (!member) return false;

    let roleFound = false;
    member.projectRoles.forEach(r => {
      if (r.name.toLowerCase() === role.toLowerCase()) {
        roleFound = true;
      }
    });

    return roleFound;
  }

  updateDistrictId(id: any) {
  }
}
