import { Sample } from './../../shared-kernel/entity/applied-geoscience/geoTech/Sample';

export class SampleLabSubmission {
  id: string;
  labNo: string;
  submissionDate: Date;
  requestedBy: string;
  analysisDate: Date;
  completionDate: Date;
  sampleDescription: string;
  typeOfAnalysis: string;
  analysisComment: string;
  sample: Sample
  creator: string;
  created: string;
  deleted: boolean;
}
