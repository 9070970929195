import { PersonnelCreateComponent } from './../../../../../personnel/personnel-create/personnel-create.component';
import { Component, ElementRef, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { DCPTestSubgrade } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DCPTestSubgrade';
import { District } from 'src/app/common/entity/security/util/common/district';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { ProjectService } from 'src/app/applied-geoscience/project/project.service';
import { DCPTestService } from '../../dynamic-cone-penetrometer.service';
import { MatChipInputEvent, MatPaginator, MatSort,
  MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef,
  MatSelect, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DcpSubgradeTest } from '../dcpSubgradeTest';
import { Person } from '../../../../../_core/data/_models/people.model';
import { UserService } from '../../../../../auth/user/user.service';
import { DcpTestImage } from '../../../../../shared-kernel/entity/common/DcpTestImage';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as moment from 'moment';
import { DcpHelper } from '../dcpHelper';
import { RoadWorksValidations } from './road-works-validations';
import { CoordinatesService } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import { CoordinatesType } from 'src/app/shared-kernel/entity/common/coordinatesType';
import { Coordinate } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinate';
import { CoordinatesTypesEnum } from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import LatLon from 'geodesy/latlon-nvector-spherical.js';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';
import {Village} from '../../../../../shared-kernel/entity/common/Village';
import { CommonService } from 'src/app/common/common.service';
import { DynamicConePenetrometerTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTest';
import { LocationTemp } from 'src/app/shared-kernel/entity/common/locationTemp';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from '../../../../../shared-kernel/entity/applied-geoscience/company/company';
import { CompanyService } from '../../../../company/company.service';
import { PersonnelSortPipe } from '../../../../../shared-kernel/pipes/personnel.sort.pipe';
import { ProjectMembers } from 'src/app/applied-geoscience/project/project-detail/project-members/project-member.model';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PeopleData } from 'src/app/_core/data/people';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

declare var $: any;

@Component({
  selector: 'app-dcp-subgrade-create',
  templateUrl: './dcp-roadworks-create.component.html',
  styleUrls: ['./dcp-roadworks-create.component.scss']
})
export class DcpRoadworksCreateComponent implements OnInit {

  isEdit: Boolean = false;
  projectCoordinators: Person[];
  projectManagers: Person[];
  projectGeologists: Person[];
  dcpTestLocationImageCtrl = new FormControl();
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  dcptestLocationImage;
  loading = true;

  @Input() dcpSubgradeTest: DcpSubgradeTest = new DcpSubgradeTest();
  @Input() dcpTestLocationImages: Array<DcpTestImage> = [];
  @Input() dcpSubgradeTestHelper: DcpHelper = new DcpHelper();
  @Input() dataSourceDcpTests: MatTableDataSource<DcpSubgradeTest> = new MatTableDataSource();
  @Input() paginator: MatPaginator;
  @Input() sort: MatSort;
  @Input() dcpSubgradeTestList;


  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  dcpTestSubgrade: DCPTestSubgrade;

  district: District;
  districts: District[];
  project: Project;
  projects: Project[];
  penetration: Size;
  penetrationRate: Size;
  startDepth: Size;
  finishDepth: Size;
  hammerWeight: Size;
  villages: Village[];
  @ViewChild('boreholeLocationImageInput') dcpTestLocationImageInput: ElementRef<HTMLInputElement>;

  coordinatesTypes: CoordinatesType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];

  dmsSx: number; // decimal minutes seconds, to hold x/longitude seconds value
  dmsMx: number; // decimal minutes seconds, to hold x/longitude minutes value
  dmsDx: number; // decimal minutes seconds, to hold x/longitude decimal value

  dmsSy: number; // decimal minutes seconds, to hold y/latitude seconds value
  dmsMy: number; // decimal minutes seconds, to hold y/latitude minutes value
  dmsDy: number; // decimal minutes seconds, to hold y/latitude decimal value

  zone34SNorthing: number;
  zone34SEasting: number;

  zone35SNorthing: number;
  zone35SEasting: number;

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  title: string;
  allCompanies: Company [];

  projectMembers: Array<ProjectMembers> = [];
  personMembers: Array<Person> = [];
  currentUser: any;

  filteredManagers: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredCoordinators: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredGeologists: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  currentManager: FormControl = new FormControl();
  managerFilter: FormControl = new FormControl();

  currentCoordinator: FormControl = new FormControl();
  coordinatorFilter: FormControl = new FormControl();
  currentGeologist: FormControl = new FormControl();
  geologistFilter: FormControl = new FormControl();

  @ViewChild('managerSelect') managerSelect: MatSelect;
  @ViewChild('coordinatorSelect') coordinatorSelect: MatSelect;
  @ViewChild('geologistSelect') geologistSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  projectStillLoading = true;
  districtStillLoading = true;
  villageStillLoading = true;
  projectMemberStillLoading = true;

  constructor(private router: Router,private personnelSortPipe: PersonnelSortPipe,
    private roadWorksValidation: RoadWorksValidations,
    private projectService: ProjectService, private dcpTestService: DCPTestService,
    private toastr: ToastrService,private  companyService: CompanyService,
    private userService: UserService, private coordinatesService: CoordinatesService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DcpRoadworksCreateComponent>,
    private peopleSrv: PeopleData,
    private dialog: MatDialog,
    private _localStorage: LocalStorage) {
  }

  validation_messages = this.roadWorksValidation.validation_messages;

  ngOnInit() {
    this.getBotswanaGeofence();
    this.getAllDistricts();
    this.getAllVillages();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.getProjectsByMember(this.currentUser.username);
      }, err => {
        this.toastr.warning(err.message, "Current User Error");
      },() => {

      });
    this.getCompanies();
    this.hammerWeight = new Size();
    this.penetration = new Size();
    this.penetrationRate = new Size();
    this.startDepth = new Size();
    this.finishDepth = new Size();

    this.dcpSubgradeTest.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.dcpSubgradeTest.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;


    if (this.data) {
      this.title = 'Edit DCP Roadworks Test';
      this.dcpSubgradeTest = this.data;
      this.dcpSubgradeTestHelper = this.data;
      this.dcpSubgradeTestHelper.time = moment(this.dcpSubgradeTest.time).format('hh:mm');
      this.dcpTestLocationImages = this.dcpSubgradeTest.testImages;
      this.dcpSubgradeTest.dynamicConePenetrometerTest.date = new Date(this.dcpSubgradeTest.dynamicConePenetrometerTest.date);
    } else {
      this.title = 'Create DCP Roadworks Test';
    }

    this._getProjectMembers();

  }

  ngAfterViewInit() {
    this._setInitialManager();
    this._setInitialCoordinator();
    this._setInitialGeologist();
  }

  getCompanies(){
    this.companyService.getAllCompanies().subscribe(value => {
      this.allCompanies = value as Company [];
    },error => {},() => {
      this.getAllPersons();
    })
  }


  closeDialog() {
    this.dialogRef.close(this.dcpSubgradeTest);
  }

  goTo(filter: string) {
    this.router.navigate([`portal/personnel/${filter}`]);
    this.closeDialog();
  }

  newPersonnel() {
    const newPersonnelDialogRef = this.dialog.open(PersonnelCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true
    });

    newPersonnelDialogRef.afterClosed().subscribe((result) => {
      this._getProjectMembers();
    });
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villageStillLoading = false;
      });
  }

  onDistrictChange(districtId) {
    this.commonService.getVillagesByDistrictId(districtId)
    .subscribe(data => {
      this.villages = data as Village[];
    });
  }

  onVillageChange(village: Village) {
    
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesType = selectedData.text;
    this.dcpSubgradeTest.coordinatesFormat.type = this.selectedCoordinatesType;
    if (this.isEdit) {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.dcpSubgradeTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.dcpSubgradeTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
      }

    } else {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.dcpSubgradeTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.dcpSubgradeTest.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
    }

  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesFormat = selectedData.text;
    this.dcpSubgradeTest.coordinatesFormat.format = this.selectedCoordinatesFormat;


    if (this.isEdit) {
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
        this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
        this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
        this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonSValue);
        this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonMValue);
        this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonDValue);

        this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latSValue);
        this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latMValue);
        this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latDVale);
      }
    }
  }
  getProjectById(projectId) {
    this.projectService.getProjectById(projectId).subscribe(value => {
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.project = value as Project;
      this.executeSubmission();
    });
  }

  onSubmit() {
    this.getProjectById(this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.project.id);
  }


  executeSubmission(){
    if(this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.id){
    
       
          this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.manager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
          this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.coordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';;
        
      

      if (this.currentGeologist) {
        if (this.currentGeologist.value.id && this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist !== this.currentGeologist.value.id) {
          this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist = this.currentGeologist.value.id;
        }
      }
    } else {
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.manager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.coordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist = this.currentGeologist.value && this.currentGeologist.value.id ? this.currentGeologist.value.id : '';
    }

    const dateTime = new Date(Date.parse(new Date(this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.date).toLocaleDateString()
      + ' ' + this.dcpSubgradeTestHelper.time));
    this.dcpSubgradeTest.time = dateTime;
    this.dcpSubgradeTest.stationNumber = this.dcpSubgradeTestHelper.stationNumber;
    this.dcpSubgradeTest.dynamicConePenetrometerTest = this.dcpSubgradeTestHelper.dynamicConePenetrometerTest;
    this.dcpSubgradeTest.id = this.dcpSubgradeTestHelper.id;
    this.dcpSubgradeTest.testImages = this.dcpTestLocationImages;


    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }


    this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
    this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;


    if (new LatLon(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
      this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      this.spinner.show();
      this.dcpTestService.createDynamicConePenetroMeterSubgradeTest(this.dcpSubgradeTest).subscribe(response => {
        if (!this.isEdit) {
          this.getAllDCPSubbgradeTest();
          this.successmsg('You have Successfully created a New Dynamic Cone Penetrometer Test (Foundation)');
        } else {
          this.successmsg('You have Successfully Edited Dynamic Cone Penetrometer Test (Foundation)');
        }
      }, err => {
        this.spinner.hide();

      }, () => {
        this.spinner.hide();
        this.resetPaginator();
        this.resetModal();
      });
    } else {
      this.toastr.error(this.coordinatesService.outSide);
    }
  }


  getAllDCPSubbgradeTest() {
    this.dcpTestService.getAllDCPSubgradeTest()
      .subscribe(data => {
        this.dcpSubgradeTestList = data as DcpSubgradeTest[];
        this.dcpSubgradeTestList.sort((a, b) => (a.dynamicConePenetrometerTest.project.name || '').toString().localeCompare((b.dynamicConePenetrometerTest.project.name || '').toString())
          || (a.dynamicConePenetrometerTest.testNumber || '').toString().localeCompare((b.dynamicConePenetrometerTest.testNumber || '').toString()));
        this.dataSourceDcpTests.data = this.dcpSubgradeTestList;
        this.loading = false;
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
        this.resetPaginator();
      });
  }

  resetPaginator() {
    this.dataSourceDcpTests.sort = this.sort;
    this.dataSourceDcpTests.paginator = this.paginator;
    this.dataSourceDcpTests.data = this.dcpSubgradeTestList;
  }

  createRoadWorksObject(): DcpSubgradeTest {
    this.dcpSubgradeTest = new DcpSubgradeTest();
    this.dcpSubgradeTest.dynamicConePenetrometerTest = new DynamicConePenetrometerTest();
    this.dcpSubgradeTest.dynamicConePenetrometerTest.project = new Project();
    this.dcpSubgradeTest.dynamicConePenetrometerTest.project.name = '';
    this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp = new LocationTemp();
    this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.village = new Village();
    this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.village.district = new District();
    this.dcpSubgradeTest.coordinatesFormat = new CoordinatesFormat();
    this.dcpSubgradeTest.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.dcpSubgradeTest.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;

    return this.dcpSubgradeTest;
  }

  createSubgradeHelperObject(): DcpHelper {
    this.dcpSubgradeTestHelper = new DcpHelper();
    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest = new DynamicConePenetrometerTest();
    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.project = new Project();
    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.project.name = '';
    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.locationTemp = new LocationTemp();
    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.locationTemp.village = new Village();
    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.locationTemp.village.district = new District();
    this.dcpSubgradeTestHelper.coordinatesFormat = new CoordinatesFormat();


    return this.dcpSubgradeTestHelper;
  }

  resetModal() {
    this.dcpSubgradeTest = null;
    this.dcpTestLocationImages = [];
    this.dcpSubgradeTest = this.createRoadWorksObject();
    this.dcpSubgradeTestHelper = this.createSubgradeHelperObject();
    this.dcpSubgradeTest.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.dcpSubgradeTest.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    this.dialogRef.close(this.dcpSubgradeTest);
  }

  getAllPersons() {
    this.userService.getPersons()
      .subscribe(data => {
        this.projectCoordinators = this.personnelSortPipe.transform(this.allCompanies,data as Person[]);
        this.projectManagers = this.personnelSortPipe.transform(this.allCompanies,data as Person[]);
        this.projectGeologists = this.personnelSortPipe.transform(this.allCompanies,data as Person[]);
    });
  }

  getAllDistricts() {
    this.commonService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districtStillLoading = false;
      });
  }

  getAllProjects() {
    this.projectService.getAllProjects()
      .subscribe(data => {
        this.projects = data as Project[];
        this.projectStillLoading = false;
      });
  }

  getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user).subscribe(res => {
      this.projects = res as Project[];
      this.projectStillLoading = false;
    }, err => {
    });
  }

  cancelForm() {
    this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/list']);
  }

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo ? variableOne.id === variableTwo.id : variableOne === variableTwo;
  }

  remove(images: Array<DcpTestImage>, image: DcpTestImage) {
    const index = images.indexOf(image);
    if (index >= 0) {
      images.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const dcpTestLocationImage = new DcpTestImage();
    dcpTestLocationImage.name = value;

    if ((value || '').trim()) {
      this.dcpTestLocationImages.push(dcpTestLocationImage);
    }

    if (input) {
      input.value = '';
    }

    this.dcpTestLocationImageCtrl.setValue(null);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.dcptestLocationImage = new DcpTestImage();
          this.dcptestLocationImage.image = fileSplitdata[1];
          this.dcptestLocationImage.name = fileName;
          this.dcptestLocationImage.type = fileSplitdata[0];
          this.dcpTestLocationImages.push(this.dcptestLocationImage);
          this.dcpTestLocationImageCtrl.setValue(null);
        };
      }
    }
  }

  editSubgradeDetails(editDcpTest: DcpSubgradeTest, isEdit: boolean) {
    this.isEdit = isEdit;
    editDcpTest.dynamicConePenetrometerTest.date = new Date(editDcpTest.dynamicConePenetrometerTest.date);
    this.dcpTestLocationImages = editDcpTest.testImages;
    this.dcpSubgradeTest = editDcpTest;

    if(this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.id){
      
        
          this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.manager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
     
          this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.coordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
        
      

      if (this.currentGeologist) {
        if (this.currentGeologist.value.id && this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist !== this.currentGeologist.value.id) {
          this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist = this.currentGeologist.value.id;
        }
      }
    } else {
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.manager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.coordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
      this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist = this.currentGeologist.value && this.currentGeologist.value.id ? this.currentGeologist.value.id : '';
    }

    this.dcpSubgradeTestHelper.dynamicConePenetrometerTest = this.dcpSubgradeTest.dynamicConePenetrometerTest;
    this.dcpSubgradeTestHelper.stationNumber = this.dcpSubgradeTest.stationNumber;
    this.dcpSubgradeTestHelper.testImages = this.dcpSubgradeTest.testImages;
    this.dcpSubgradeTestHelper.time = moment(this.dcpSubgradeTest.time).format('hh:mm');
    this.dcpSubgradeTestHelper.id = this.dcpSubgradeTest.id;

    this.dcpSubgradeTest.coordinatesFormat.type = this.dcpSubgradeTest.coordinatesFormat.type;
    this.selectedCoordinatesType = this.dcpSubgradeTest.coordinatesFormat.type;
    this.dcpSubgradeTest.coordinatesFormat.format = this.dcpSubgradeTest.coordinatesFormat.format;
    this.selectedCoordinatesFormat = this.dcpSubgradeTest.coordinatesFormat.format;

    if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
      this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
      this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
    }
    if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
      this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
      this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
    }
    if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
      this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonSValue);
      this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonMValue);
      this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonDValue);

      this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latSValue);
      this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latMValue);
      this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpSubgradeTest.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latDVale);
    }
  }

  protected _setInitialManager() {
    this.filteredManagers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.id) {
        // Edit
        let manager = this.personMembers.find(pM => pM.id === this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.manager);
        if (!manager) {
          found = false;
        } else {
          found = true;
          this.currentManager.setValue(manager);
          this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;

      // Pre-select
      let manager = this.personMembers.find(pM => this._isOfRole(pM, 'Project Manager'));

      if (!manager) return;

      this.currentManager.setValue(manager);
      this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }


  protected _setInitialCoordinator() {
    this.filteredCoordinators.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.id) {
        // Edit
        let coordinator = this.personMembers.find(pM => pM.id === this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.coordinator);

        if (!coordinator) {
          found = false;
        } else {
          found = true;
          this.currentCoordinator.setValue(coordinator);
          this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let coordinator = this.personMembers.find(pM => this._isOfRole(pM, 'Project Coodinator'));

      if (!coordinator) return;
      this.currentCoordinator.setValue(coordinator);
      this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialGeologist() {
    this.filteredGeologists.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;

      if (this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.id) {
        // Edit
        let geologist = this.personMembers.find(pM => pM.id === this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.geologist);
        if (!geologist) {
          found = false;
        } else {
          found = true;
          this.currentGeologist.setValue(geologist);
          this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;

      // Pre-select
      let geologist = this.personMembers.find(pM => this._isOfRole(pM, 'Geologist'));

      if (!geologist) return;
      this.currentGeologist.setValue(geologist);
      this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  private _getProjectMembers() {
    this.projectMemberStillLoading = true;
    if (this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.project || this.dcpSubgradeTest.dynamicConePenetrometerTest.project) {
      this.projectService.getProjectMembers(this.dcpSubgradeTestHelper.dynamicConePenetrometerTest.project.id).subscribe(res => {
        this.projectMembers = res as Array<ProjectMembers>;
        this._getProjectPeople(this.projectMembers);
      }, err => {
        this.toastr.error(err.message, "Project Member Fail");
      });
    } else {
      this.projectMemberStillLoading = false;
    }
  }

  private _getProjectPeople(members: Array<ProjectMembers>) {
    let ids = [];
    members.forEach(m => {
      ids.push(m.personId);
    });

    if (ids.length <= 0) {
      this.projectMemberStillLoading = false;
      return;
    }

    this.peopleSrv.getPeopleByIdList(ids).subscribe(res => {
      this.personMembers = res;

      this.filteredManagers.next(res.slice());
      this.managerFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterManager();
      });

      this.filteredCoordinators.next(res.slice());
      this.coordinatorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCoordinators();
      });
      this.filteredGeologists.next(res.slice());
      this.geologistFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterGeologist();
      });
      this.projectMemberStillLoading = false;
    }, err => {
      this.toastr.error(err.message, "Project People Fail");
    });
  }

  private _isOfRole(person: Person, role: string) {
    let member = this.projectMembers.find(pM => pM.personId === person.id && pM.deleted === false);
    if (!member) return false;
    let roleFound = false;
    member.projectRoles.forEach(r => {
      if (r.name.toLowerCase() === role.toLowerCase()) {
        roleFound = true;
      }
    });
    return roleFound;
  }

  filterManager() {
    if (!this.personMembers) return;
    let search = this.managerFilter.value;
    if (!search) {
      this.filteredManagers.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredManagers.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterCoordinators() {
    if (!this.personMembers) return;
    let search = this.coordinatorFilter.value;
    if (!search) {
      this.filteredCoordinators.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCoordinators.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterGeologist() {
    if (!this.personMembers) return;
    let search = this.geologistFilter.value;
    if (!search) {
      this.filteredGeologists.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGeologists.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  companyChanged() {
    this._getProjectMembers();
  }
}
