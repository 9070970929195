import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sample } from '../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { TimeAndMotion } from '../entity/timeandmotion';
import * as moment from 'moment';
import { Borehole } from '../../../shared-kernel/entity/common/borehole';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimeAndMotionService } from '../timeandmotion.service';

@Component({
  selector: 'app-timeandmotion-create',
  templateUrl: './timeandmotion-create.component.html',
  styleUrls: ['./timeandmotion-create.component.scss']
})
export class TimeandmotionCreateComponent implements OnInit {
  timeandmotionCreateForm: FormGroup;
  timeandmotionTemplate: TimeAndMotion = new TimeAndMotion();
  borehole: Borehole;
  title: string = 'Create Time & Motion';

  constructor(public dialogRef: MatDialogRef<TimeandmotionCreateComponent>,private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: TimeAndMotion,private toaster: ToastrService,
              private timeAndMotionService: TimeAndMotionService,
              private spinner: NgxSpinnerService) { }



  ngOnInit() {
    this.initForms();
    this.setDurationValue();
  }

  calculateTravellingTimeTo() {
     this.timeandmotionCreateForm.get('timeTo').valueChanges.subscribe(value => {
       let to = moment.duration(this.timeandmotionCreateForm.controls.timeTo.value);
       let from = moment.duration(this.timeandmotionCreateForm.controls.timeFrom.value);
       let timeToAndFromDiff = to.subtract(from);
       this.timeandmotionCreateForm.controls.travellingMinutes.setValue(timeToAndFromDiff.minutes())  ;
       this.timeandmotionCreateForm.controls.travellingHours.setValue(timeToAndFromDiff.hours());
     });
   }

   setDurationValue(){
     let allMinutes = parseInt(this.timeandmotionCreateForm.controls.pullingLoweringMinutes.value ? this.timeandmotionCreateForm.controls.pullingLoweringMinutes.value: '0')
       + parseInt((this.timeandmotionCreateForm.controls.drillingMinutes.value? this.timeandmotionCreateForm.controls.drillingMinutes.value: '0'))
       + parseInt((this.timeandmotionCreateForm.controls.preStartChecksMinutes.value? this.timeandmotionCreateForm.controls.preStartChecksMinutes.value: '0'))
       + parseInt((this.timeandmotionCreateForm.controls.mobilisationMinutes.value? this.timeandmotionCreateForm.controls.mobilisationMinutes.value: '0'))
       + parseInt((this.timeandmotionCreateForm.controls.maintenanceMinutes.value? this.timeandmotionCreateForm.controls.maintenanceMinutes.value: '0'))
       + parseInt((this.timeandmotionCreateForm.controls.othersMinutes.value? this.timeandmotionCreateForm.controls.othersMinutes.value: '0'))
       + parseInt((this.timeandmotionCreateForm.controls.travellingMinutes.value? this.timeandmotionCreateForm.controls.travellingMinutes.value: '0'));

     this.timeandmotionCreateForm.controls.durationHours.setValue(
       parseInt((this.timeandmotionCreateForm.controls.pullingLoweringHours.value? this.timeandmotionCreateForm.controls.pullingLoweringHours.value: '0'))
       +parseInt((this.timeandmotionCreateForm.controls.drillingHours.value? this.timeandmotionCreateForm.controls.drillingHours.value: '0'))
       +parseInt((this.timeandmotionCreateForm.controls.travellingHours.value? this.timeandmotionCreateForm.controls.travellingHours.value: '0'))
       +parseInt((this.timeandmotionCreateForm.controls.preStartChecksHours.value? this.timeandmotionCreateForm.controls.preStartChecksHours.value: '0'))
       +parseInt((this.timeandmotionCreateForm.controls.mobilisationHours.value? this.timeandmotionCreateForm.controls.mobilisationHours.value: '0'))
       +parseInt((this.timeandmotionCreateForm.controls.maintenanceHours.value? this.timeandmotionCreateForm.controls.maintenanceHours.value: '0'))
       +parseInt((this.timeandmotionCreateForm.controls.othersHours.value? this.timeandmotionCreateForm.controls.othersHours.value: '0'))
       + (Math.floor(allMinutes/60)));

     this.timeandmotionCreateForm.controls.durationMinutes.setValue(allMinutes%60);
   }

   calculateDuration(){
        this.timeandmotionCreateForm.get('travellingHours').valueChanges.subscribe( value => {
          this.setDurationValue();
        });
       this.timeandmotionCreateForm.get('travellingMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('drillingHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('drillingMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('travellingHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('pullingLoweringHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('pullingLoweringMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('maintenanceHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('maintenanceMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('mobilisationHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('mobilisationMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('preStartChecksHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('preStartChecksMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('othersHours').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
       this.timeandmotionCreateForm.get('othersMinutes').valueChanges.subscribe( value => {
         this.setDurationValue();
       });
   }

  calculateTravellingTimeFrom(){
    this.timeandmotionCreateForm.get('timeFrom').valueChanges.subscribe(value => {
      let to = moment.duration(this.timeandmotionCreateForm.controls.timeTo.value);
      let from = moment.duration(this.timeandmotionCreateForm.controls.timeFrom.value);
      let timeToAndFromDiff = to.subtract(from);
      this.timeandmotionCreateForm.controls.travellingMinutes.setValue(timeToAndFromDiff.minutes())  ;
      this.timeandmotionCreateForm.controls.travellingHours.setValue(timeToAndFromDiff.hours());
    });
  }

  private initForms() {
    if(this.data.id){
      this.title = 'Edit Time & Motion';
    }

    this.timeandmotionCreateForm = this.formBuilder.group({
      date: [this.data.id ? new Date(this.data.date) : null, Validators.required],
      timeFrom: [this.data.id ? this.data.timeFrom : null],
      timeTo: [this.data.id ? this.data.timeTo : null],
      meterFrom: [this.data.id ? this.data.metersFrom : null],
      meterTo: [this.data.id ? this.data.metersTo : null],
      travellingHours: [this.data.id ? this.data.travellingHoursTo.hours : null],
      travellingMinutes: [this.data.id ? this.data.travellingHoursTo.minutes : null, [Validators.max(59), Validators.min(0)]],
      drillingHours: [this.data.id ? this.data.drillingHours.hours : null],
      drillingMinutes: [this.data.id ? this.data.drillingHours.minutes : null, [Validators.max(59), Validators.min(0)]],
      pullingLoweringHours: [this.data.id ? this.data.pullingAndLowering.hours : null],
      pullingLoweringMinutes: [this.data.id ? this.data.pullingAndLowering.minutes : null, [Validators.max(59), Validators.min(0)]],
      maintenanceHours: [this.data.id ? this.data.maintenanceHours.hours : null],
      maintenanceMinutes: [this.data.id ? this.data.maintenanceHours.minutes : null, [Validators.max(59), Validators.min(0)]],
      mobilisationHours: [this.data.id ? this.data.mobilisationHours.hours : null],
      mobilisationMinutes: [this.data.id ? this.data.mobilisationHours.minutes : null, [Validators.max(59), Validators.min(0)]],
      preStartChecksHours: [this.data.id ? this.data.preStartChecks.hours : null],
      preStartChecksMinutes: [this.data.id ? this.data.preStartChecks.minutes : null, [Validators.max(59), Validators.min(0)]],
      othersHours: [this.data.id ? this.data.others.hours : null],
      othersMinutes: [this.data.id ? this.data.others.minutes : null, [Validators.max(59), Validators.min(0)]],
      comments: [this.data.id ? this.data.comments : null],
      durationHours: [],
      durationMinutes: [],
    });

    this.calculateTravellingTimeTo();
    this.calculateTravellingTimeFrom();
    this.calculateDuration();


  }

  onSubmit() {
    if((this.timeandmotionCreateForm.controls.timeFrom.value.length === 0 && this.timeandmotionCreateForm.controls.timeTo.value.length > 0)
      ||  (this.timeandmotionCreateForm.controls.timeFrom.value.length >0 && this.timeandmotionCreateForm.controls.timeTo.value.length ===0)){

      this.toaster.error('TimeFrom and TimeTo fields', 'You have to fill both fields or non at all!');
    }
    else{
        if(this.data.id) {
          this.timeandmotionTemplate = this.data;
        }

        this.timeandmotionTemplate.date = this.timeandmotionCreateForm.controls.date.value;
        this.timeandmotionTemplate.drillingHours.minutes = this.timeandmotionCreateForm.controls.drillingHours.value;
        this.timeandmotionTemplate.drillingHours.hours  = this.timeandmotionCreateForm.controls.drillingMinutes.value;
        this.timeandmotionTemplate.mobilisationHours.hours = this.timeandmotionCreateForm.controls.mobilisationHours.value;
        this.timeandmotionTemplate.mobilisationHours.minutes = this.timeandmotionCreateForm.controls.mobilisationMinutes.value;
        this.timeandmotionTemplate.maintenanceHours.minutes = this.timeandmotionCreateForm.controls.mobilisationHours.value;
        this.timeandmotionTemplate.maintenanceHours.hours = this.timeandmotionCreateForm.controls.mobilisationMinutes.value;
        this.timeandmotionTemplate.pullingAndLowering.minutes = this.timeandmotionCreateForm.controls.pullingLoweringMinutes.value;
        this.timeandmotionTemplate.pullingAndLowering.hours = this.timeandmotionCreateForm.controls.pullingLoweringHours.value;
        this.timeandmotionTemplate.travellingHoursTo.minutes = this.timeandmotionCreateForm.controls.travellingMinutes.value;
        this.timeandmotionTemplate.travellingHoursTo.hours = this.timeandmotionCreateForm.controls.travellingHours.value;
        this.timeandmotionTemplate.metersTo = this.timeandmotionCreateForm.controls.meterTo.value;
        this.timeandmotionTemplate.metersFrom = this.timeandmotionCreateForm.controls.meterFrom.value;
        this.timeandmotionTemplate.comments = this.timeandmotionCreateForm.controls.comments.value;
        this.timeandmotionTemplate.timeTo = this.timeandmotionCreateForm.controls.timeTo.value;
        this.timeandmotionTemplate.timeFrom = this.timeandmotionCreateForm.controls.timeFrom.value;
        this.timeandmotionTemplate.borehole = this.data.borehole;
        this.timeandmotionTemplate.deleted = false;
        this.timeandmotionTemplate.duration = this.timeandmotionCreateForm.controls.durationHours.value+(this.timeandmotionCreateForm.controls.durationMinutes.value/60);
        this.timeandmotionTemplate.others.hours = this.timeandmotionCreateForm.controls.othersHours.value;
        this.timeandmotionTemplate.others.minutes = this.timeandmotionCreateForm.controls.othersMinutes.value;
        this.timeandmotionTemplate.preStartChecks.hours = this.timeandmotionCreateForm.controls.preStartChecksHours.value;
        this.timeandmotionTemplate.preStartChecks.minutes = this.timeandmotionCreateForm.controls.preStartChecksMinutes.value;

        if((this.timeandmotionTemplate.metersFrom!== null && this.timeandmotionTemplate.metersTo!== null)  && (parseInt(this.timeandmotionTemplate.metersFrom) > parseInt(this.timeandmotionTemplate.metersTo))){
          this.toaster.error('Meter To value must be greater than Meters From', 'Meter From greater Than Meters To ');
        }
        else {
          this.spinner.show();
          this.timeAndMotionService.createTimeAndMotionRecord(this.timeandmotionTemplate).subscribe(data => {
              this.closeModal()
              this.spinner.hide();
                if (this.data.id) {
                  this.toaster.success('Record updated successfully', 'Record Edit');
                } else {
                  this.toaster.success('Record saved successfully', 'Add Record');
                }
              }, error => {
                this.toaster.error('Sorry, try again, record creation failed', 'Failure');
                this.spinner.hide();
              },
              () => {
                this.spinner.hide();
              }
          );
        }
  }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
