import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Activity } from '../../../../../activities/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-project-activities-minerals',
  templateUrl: './project-activities-minerals.component.html',
  styleUrls: ['./project-activities-minerals.component.scss']
})
export class ProjectActivitiesMineralsComponent implements OnInit {

  loading = true;
  displayedColumns: Array<string> = ['number', 'name', 'source', 'date'];
  public _dataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  get dataSource(): MatTableDataSource<Activity> {
    this.loading = false;
    return this._dataSource;

  }

  @Input('datasource') set dataSource(val: MatTableDataSource<Activity>) {
    this._dataSource = val;
    if (this._dataSource) {
      this.loading = false;
      this._dataSource.paginator = this.paginator;
    }
  }

  constructor() { }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }

}
