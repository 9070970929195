import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minerals-geologist-files-dashboard',
  templateUrl: './minerals-geologist-files-dashboard.component.html',
  styleUrls: ['./minerals-geologist-files-dashboard.component.scss']
})
export class MineralsGeologistFilesDashboardComponent implements OnInit {

  nigisFileActivityCountsResponseDto: any;
  stillFetchingActivities = true;
  userName: string;

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
  }

  ngOnInit() {
    this.getActivitiesCount();
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.nigisFileActivityCountsResponseDto.numberOfBGIProjects = number;
      this.getMyProjectsCount();
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.nigisFileActivityCountsResponseDto.numberOfMyProjects = number;
      this.stillFetchingActivities = false;
    });
  }


  getActivitiesCount() {
    this.userDashBoardService.getActivitiesCounts('individual').subscribe(value => {
      this.nigisFileActivityCountsResponseDto = value;
      this.getBGIProjectsCount();
    });
  }

  goTo(type: string, filter: string, role: string) {
    this._router.navigate([`portal/activities/mineral-resources-files/${type}/${filter}/${role}`]);
  }

}
