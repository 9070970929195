import { Honorific } from './honorific';
import { Kin } from './kin';
import { Sex } from './sex';
import { Countries } from './countries';
import { PhysicalAddress } from '../util/common/physical-address';
import { IdType } from './id-type';
import { User } from '../user/user';
import { ContactDetail } from '../util/common/contact-detail';
import { PostalAddress } from '../util/common/postal-address';

export class Person {

  public id: string;
  public idNumber: string;
  public idType: IdType;
  public dateOfBirth: Date;
  public name: string;
  public middleName: string;
  public surname: string;
  public workIdNumber: string;
  public user: User;
  public contactDetails: ContactDetail[];
  public kin: Kin;
  public sex: Sex;
  public countries: Countries;
  public physicalAddress: PhysicalAddress;
  public postalAddress: PostalAddress;
  public profession: string;
  public title: Honorific;

  constructor() {  }
}
