import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DCPTestService implements OnInit {

  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  getAllSurfaceType() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSurfaceTypesUrl);
  }

  getAllDCPTestFoundation() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDCPTestFoundationUrl);
  }

  getAllDCPSubgradeTest() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDCPSubgradeTestUrl);
  }


  getAllDCPFoundationTestById(testId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDCPFoundationTestByIdUrl + '/' + testId);
  }

  createDynamicConePenetroMeterFoundationTest(foundationTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.createDynamicConePenetrometerFoundationTestUrl, foundationTest);
  }

  createDynamicConePenetroMeterSubgradeTest(subgradeTest): Observable<any> {
    return this.http.post<any>(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createDynamicConePenetrometerSubgradeTestUrl, subgradeTest);
  }

  createDynamicConePenetroMeterRoadworkLogRecord(roadworkRecord) {
    return this.http.post<any>(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createDynamicConePenetrometerRoadworkLogUrl, roadworkRecord);
  }

  createDynamicConePenetroMeterRoadworkLogRecordBatch(roadworkRecord) {
    return this.http.post<any>(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createDynamicConePenetrometerRoadworkBatchLogUrl, roadworkRecord);
  }

  getAllDCPTestSubgradeByDynamicConePenetrometerTestId(TestId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.getDCPSubgradeTestByIdUrl + '/' + TestId);
  }

  getDcpRoadworksRecordById(testId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.getDCPRoadworkTestByIdUrl + '/' + testId);
  }

  dcpFoundationInProject(testNumber: string, projectId: string): Observable<{ exist: boolean, message: string }> {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/dcpt/v1/check/${testNumber}/project/${projectId}`);
  }
}
