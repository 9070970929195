import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Color } from 'ng2-charts';
import { GlobalDashboardService } from '../global-dashboard.service';
import { ActivityType, CollectionsActivityType } from '../../activities/models';
import { DashboardService } from '../dashboard/dashboard.service';
import { State } from '../../activities/models/state';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  loading: boolean = false;
  cards :any;
  adminDashboardCounts :any;
  totalActivities = 0;
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    }
  };
  state = new State();
  displayedColumns: string[] = ['#', 'activities', 'status', 'date'];
  recentActivitiesDataSource = new MatTableDataSource<any>();
  dataSource = new MatTableDataSource<any>();

  public barChartLabels: string[] = [];
  public myactivitiesLabels = [ 'Drafts', 'Submitted-QA', 'Submitted-Manager', 'Returned','Approved'];
  public myProjectsLabels = ['Drafts', 'Submitted-Manager', 'Returned','Approved', 'No-Status'];
  public myactivitiesData = [ 91, 107, 73, 107, 73];
  public myProjectsData = [ 91, 107, 73, 107,91, 0];
  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  colors: any ;
  colorProjects: any;
  colorBarchart: any;
  barChartcolors: any;

  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public pieChartType = 'pie';
  public barChartData: any[] = [];

  public myProjectsOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  //optimized
  registeredCompanyCount: number = 0;
  bgiProjectsCounts: number = 0;
  nonBgiProjectsCounts: number = 0;
  stillFetchingBGIProjectCount = true;
  stillFetchingActivitiesPerDistrict = true;
  doneFetchingProject = false;
  doneFetchingActivitiesPerDistrict = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dashBoardService: DashboardService,
    private _router: Router,
    private globalDashboardService:GlobalDashboardService,
    private companyService: CompanyService,
    private spinner: NgxSpinnerService) {

    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'All Activities', cols: 1, rows: 3 , id: 'myactivities'},
          { title: 'BGI Activities per District', cols: 1, rows: 3 ,id: 'districts'},
          { title: 'Projects',cols: 1, rows: 3 ,id: 'myprojects'},
          { title: 'Recent Activities', cols: 1, rows: 3 ,id: 'recents'}
        ];
      })
    );
  }

  ngOnInit() {
        this.getActivities();
        this.getRecentActivties();
        this.getAllCompaniesCount();
        this.getActivitiesPerDistrictCounts();
        this.getProjectCounts();
        this.colors = [{backgroundColor: [  '#573419','#00A2E5',
            '#C8A207','#CC0000','#009900', '#CC9933']}];
        this.colorProjects = [{backgroundColor: ['#573419','#CC9933','#CC0000','#009900']}]

        this.colorBarchart= [{backgroundColor: ['#00A2E5','#573419',
        '#C8A207','#CC0000','#009900', '#CC9933']}];

    this.barChartcolors = [{backgroundColor:  '#573419'},{backgroundColor: '#00A2E5'}
      ,{backgroundColor: '#C8A207'},{backgroundColor: '#CC0000'},{backgroundColor: '#009900'},{backgroundColor: '#ccbcbf'}];
  }

  getActivities(){
    this.spinner.show();
      this.globalDashboardService.adminDashboardCounts().subscribe(
        value => {
          this.adminDashboardCounts = value;
          this.totalActivities = Number(this.adminDashboardCounts.projectVettingPortfolioCounts.drafts)+
            Number(this.adminDashboardCounts.projectVettingPortfolioCounts.submittedQA)+
            Number(this.adminDashboardCounts.projectVettingPortfolioCounts.submittedManager)+
            Number(this.adminDashboardCounts.projectVettingPortfolioCounts.returned)+
            Number(this.adminDashboardCounts.projectVettingPortfolioCounts.approved);

          this.myactivitiesData = [ this.adminDashboardCounts.projectVettingPortfolioCounts.drafts,
            this.adminDashboardCounts.projectVettingPortfolioCounts.submittedQA,
            this.adminDashboardCounts.projectVettingPortfolioCounts.submittedManager,
            this.adminDashboardCounts.projectVettingPortfolioCounts.returned,
            this.adminDashboardCounts.projectVettingPortfolioCounts.approved];
          this.spinner.hide();
        });
  }

  getProjectCounts(){
    this.globalDashboardService.adminProjectDashboardCounts().subscribe(value => {
      let noStatus = 0;
      noStatus = (Number(value.bgiProjects)+Number(value.nonBgiProjects)) - (Number(value.drafts)+Number(value.sumbmittedManager)+Number(value.returned)+Number(value.approved))
      this.myProjectsData = [ value.drafts,
        value.sumbmittedManager,
        value.returned,
        value.approved,
        noStatus];
        this.nonBgiProjectsCounts = Number(value.nonBgiProjects);
        this.bgiProjectsCounts = Number(value.bgiProjects);
        this.doneFetchingProject  = true;
        this.stillFetchingBGIProjectCount = false;
      });
  }


  getActivitiesPerDistrictCounts() {
    this.globalDashboardService.getActivitiesPerDistrictCounts().subscribe(value => {
      let tempArr = value as [];
      let chartParentData: Array<[]> = [];
      let activityData = new Set();
      let activityRecordsParent : Array<[]> = [];

      tempArr.forEach(value1 => {
           this.barChartLabels.push(value1['districtName']);
            let activityRecords = value1['activityRecord'] as [];
            activityRecordsParent.push(activityRecords)

            activityRecords.forEach(value2 => {
                if(chartParentData.length==0){
                  activityData.add(value2['activityName']);
                }
            });
      });
      activityData.forEach(label => {
        let chartData: [] = [];
        activityRecordsParent.forEach(value1 => {
          value1.forEach(value2 => {
            if(label === value2['activityName']){
              chartData.push(value2['count']);
            }
          })
        }) ;
        this.barChartData.push({ data: chartData, label: label });

      });
      this.doneFetchingActivitiesPerDistrict = true;
      this.stillFetchingActivitiesPerDistrict = false;

    });
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      case 'non_bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'non_bgi_project' }]);
        break
      default:
    }
  }

  openMyActivityDetails(activity) {

    const id = {id: activity.id};
    const shelvingId = activity.shelvingId;
    const inventoryId = activity.inventoryId;

    if (inventoryId && shelvingId) {
      switch (activity.activitytype) {
        case  CollectionsActivityType.CORE_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Core/tray/'+activity.id]);
          break;
        }

        case  CollectionsActivityType.CHIPS_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Chips/'+activity.id]);
          break;
        }
      }

    } else {
      switch (activity.activitytype) {

        case  ActivityType.PL_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/details', {id: activity.id, type: 'PL'}]);
          break;
        }
        case  ActivityType.BGI_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/borehole/details', {id: activity.id, type: 'BGI'}]);
          break;
        }
        case  ActivityType.WATER_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', {id: activity.id, type: 'Water'}]);
          break;
        }
        case  ActivityType.TRIAL_PIT: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', id]);
          break;
        }
        case  ActivityType.INFILTRATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', id]);
          break;
        }
        case  ActivityType.DCP_FOUNDATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/detail', id]);
          break;
        }
        case  ActivityType.DCP_ROADWORKS: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/detail', id]);
          break;
        }
        case  ActivityType.SAMPLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/samples/list/details', id]);
          break;
        }
      }
    }
  }

  getRecentActivties()
  {
    this.loading = false;
    this.dashBoardService.getRecentActivities().subscribe(value => {
      
      this.dataSource.data = value as [];
      this.recentActivitiesDataSource.data = this.dataSource.data;

    });
  }

  getAllCompaniesCount() {
    this.companyService.getAllCompaniesCount().subscribe(value => {
      this.registeredCompanyCount = value as number;
    });
  }


}
