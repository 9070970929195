export enum RockGroupEnum {
    BasicTypes = 'Basic Types',
    MixedTypes = 'Mixed Types',
    TypesOfGravelAndSands = 'Types of Gravel and Sands',
    SedimentsAndSedimentary = 'Sediments and Sedimentary',
    Soils = 'Soils',
    GeneralRockTypes = 'General Rock Types',
    PlutonicRocks = 'PLUTONIC ROCKS',
    Various = 'Various',
    VolcanicRocks = 'Volcanic Rocks',
    MixedMagmaticRocks = 'MIXED SYMBOLS',
    Epizone = 'EPIZONE GREEN SCHIST FACIES',
    Mesozone = 'MESOZONE AMPHIBOLITE FACIES',
    Catazone = 'CATAZONE GRANULITE FACIES',
    MetamorphicGeneralAndOthers = 'GENERAL AND OTHER SYMBOLS'

  }
