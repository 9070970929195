export let AdminMenuItem = [
  {
    path: 'user',
    title: 'User Management',
    icon: 'build',
    children: [
      {
        path: 'user/list',
        title: 'System Users',
        icon: 'list',
      },
      {
        path: 'role/create',
        title: 'User Roles',
        icon: 'person',
      }
    ]},
  {
    path: 'role/create',
    title: 'System Roles'
  },
  {
    path: 'authority',
    title: 'System Authorities'
  },
  {
    path: 'authority/group',
    title: 'System AuthorityGroups'
  }
];
