import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CountryService} from './country.service';
import {PeopleService} from './people.service';
import {DcpFoundationService} from './dcp-foundation.service';
import {UnitsService} from './units.service';
import {BusinessService} from './business.service';

const SERVICES = [
  CountryService,
  PeopleService,
  DcpFoundationService,
  UnitsService,
  BusinessService
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ...SERVICES
  ]
})
export class MockDataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: MockDataModule,
      providers: [
        ...SERVICES,
      ]
    };
  }
}
