import { Borehole } from '../../common/borehole';
import { Size } from '../../common/size';
import { Notes } from '../notes';
import { SampleType } from '../../common/sample-type';
import { RockName } from './RockName';

export class BoreholeLithologyLog {
   id: string;
   dateCaptured: Date;
   sampleStatus: string;
   sampleType: SampleType;
   freshness: string;
   coreLossGain: Size = new Size();
   rockQualityDesignation: string;
   recoveredCore: Size = new Size();
   depthFrom: Size = new Size();
   depthTo: Size = new Size();
   deleted: boolean;
   lithologyDescription: Notes = new Notes();
   borehole: Borehole;
   rockName: RockName;
   creator: string;
   active: string;
  }
