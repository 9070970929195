import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompanyService} from '../../../../applied-geoscience/company/company.service';
import {Company} from '../../../../shared-kernel/entity/applied-geoscience/company/company';
import { Person, PersonLite } from '../../../../_core/data/_models/people.model';
import {PeopleService} from '../../../../_core/mock/people.service';
import {MatDialogConfig} from '@angular/material/dialog';
import {AddAttendantsComponent} from '../add-attendants/add-attendants.component';
import {ProjectService} from '../../../../applied-geoscience/project/project.service';
import {UserService} from '../../../../auth/user/user.service';
import * as _ from 'lodash';
import {ScheduleService} from '../../../services/schedule.service';
import {ToastrService} from 'ngx-toastr';
import {Observable, Subject} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import * as requestServices from '../../../shared/request-services.json';
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-customer-request-form',
  templateUrl: './customer-request-form.component.html',
  styleUrls: ['./customer-request-form.component.scss']
})
export class CustomerRequestFormComponent implements OnInit {

  companies: Company[];
  customers: any[] = [];
  commodities: any[];
  filteredCommodities: any[];
  mineralTypes: any[];
  selectedCommodities: any[] = [];

  expectedAttendants: Person[] = [];
  servicesRequested: any[] = (requestServices as any).default;

  loadingCompanies = false;
  loadingCustomers = false;
  loadingCommodities = false;
  loadingMineralTypes = false;
  submitted = false;
  companyOptions: Observable<Company[]>;
  customerOptions: Observable<any[]>;

  requestForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    requestDate: new FormControl(null, Validators.required),
    company: new FormControl(null),
    customer: new FormControl(null, Validators.required),
    location: new FormControl(null),
    requestThrough: new FormControl(null),
    areaOfInterest: new FormControl(null),
    commodity: new FormControl(null),
    plannedTurnAroundTime: new FormControl(null),
    requestedServices: new FormControl(null, this.data.isEconomicGeologist? null:Validators.required ),
    coreInformation: new FormControl(null),
    comments: new FormControl(null),
    mineralType: new FormControl(null),
  });

  dialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog, private companyService: CompanyService,
              @Inject(MAT_DIALOG_DATA) public data: any, private projectService: ProjectService,
              private peopleService: PeopleService, private userService: UserService,
              private scheduleService: ScheduleService, private toastr: ToastrService,
              private dialogRef: MatDialogRef<CustomerRequestFormComponent>
  ) {
  }

  ngOnInit() {
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '500px';
    this.dialogConfig.height = '210px';
    // -- Load people based on request type --
    if (this.data.requestType === 'company') {
      this.getAllCompanies();
    }

    // -- Edit mode --
    if (this.data.request) {
      this.populateFormDetails();
    }

    this.getAllCommodities();
    this.getAllMineralTypes();
    this.getAllPersons();
  }

  populateFormDetails() {
    this.requestForm.reset();
    this.data.request.expectedAttendants.forEach(id => {
      this.userService.getPersonById(id).subscribe(person => {
        this.expectedAttendants.push(<Person>person);
      });
    });

    this.data.request.commodities.forEach(commodity => {
      this.selectedCommodities.push(commodity);
    });

    this.requestForm.setValue({
        id: this.data.request.id,
        requestDate: new Date(this.data.request.requestDate),
        customer: [{id:this.data.request.customerID.id,name:this.data.request.customerID.name,surname:this.data.request.customerID.surname}],
        location: this.data.request.location,
        company: null,
        requestThrough: this.data.request.requestThrough,
        areaOfInterest: this.data.request.areaOfInterest,
        commodity: null,
        plannedTurnAroundTime: this.data.request.plannedTurnAroundTime,
        requestedServices: this.data.request.requestedServices,
        coreInformation: this.data.request.coreInformation,
        comments: this.data.request.comments,
        mineralType: null,
      }
    );

    if (this.data.request.company) {
      this.companyService.getCompanyById(this.data.request.company).subscribe(company => {
        this.requestForm.controls['company'].setValue(company);
        this.requestForm.controls['customer'].setValue(this.data.request.customerID);
      });
    }
  }

  getAllPersons() {
      this.loadingCustomers = true;
      this.peopleService.getAllPersonsLite().subscribe(
          data => {
            let tempPersons = data as any[];
            tempPersons.forEach(value => {
              let split = value[1].split(' ');
              this.customers.push({id:value[0],name:split[0],surname:split[1]})
            });

            this.customerOptions = this.requestForm.get('customer').valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filterCustomers(name) : this.customers.slice())
              );
            this.loadingCustomers = false;

          }
      );
  }

  getAllCommodities() {
    this.loadingCommodities = true;
    this.scheduleService.getAllCommodities()
      .subscribe(data => {
        this.commodities = data as any[];
        this.filteredCommodities = this.commodities;
        this.loadingCommodities = false;
      });
  }

  getAllMineralTypes() {
    this.loadingMineralTypes = true;
    this.scheduleService.getAllMineralTypes()
      .subscribe(data => {
        this.mineralTypes = data as any[];
        if(this.data.isEconomicGeologist){
          this.mineralTypes = this.mineralTypes.filter(value => value.name!=='Clay Minerals'
            && value.name!=='Regional Mapping' && value.name!=='Platinum Group Elements'
            && value.name!=='Rare Earth Elements' && value.name!=='Ground Water');

        }
        this.loadingMineralTypes = false;
      });
  }

  /**
   *
   * @param personType - individual or company
   */
  onAddAttendants(personType: string): void {
      this.dialogConfig.data = {
        personType: personType,
        attendants: this.expectedAttendants
      };

      this.dialog.open(AddAttendantsComponent, this.dialogConfig)
        .afterClosed().subscribe(result => {
        this.expectedAttendants = result;
      });
  }

  /**
   * Get all company
   */
  getAllCompanies() {
    this.loadingCompanies = true;
    this.companyService.getAllCompanies()
      .subscribe(data => {
        this.companies = data as Company[];

        this.companyOptions = this.requestForm.get('company').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.companies.slice())
          );

        this.loadingCompanies = false;
      });
  }

  displayFn(company: Company): string {
    return company && company.name ? company.name : '';
  }

  displayCustomer(customer): string {
    return customer && (customer.name || customer[0]) ?
      (customer.name ? customer.name :customer[0].name)+' '+(customer.surname ?customer.surname:customer[0].surname ): '';
  }

  private _filter(name: string): Company[] {
    const filterValue = name.toLowerCase();
    return this.companies.filter(company => company.name.toLowerCase().includes(filterValue));
  }

  private _filterCustomers(name: string): Company[] {
    const filterValue = name.toLowerCase();
    return this.customers.filter(customer => customer.name.toLowerCase().includes(filterValue) | customer.surname.toLowerCase().includes(filterValue));
  }

  removeAttendant(attendant: any) {
    const index = this.expectedAttendants.indexOf(attendant);
    if (index >= 0) {
      this.expectedAttendants.splice(index, 1);
    }
  }

  onAddCommodity(commodity: any): void {
    if (this.selectedCommodities.length > 0) {
      let count = 0;
      this.selectedCommodities.forEach(commodityOld => {
        if (commodityOld.name === commodity.name) {
          count = count + 1;
        }
      });
      if (count === 0) {
        this.selectedCommodities.push(commodity);
      }
    } else {
      this.selectedCommodities.push(commodity);
    }
  }

  onSelectMineralType(id: string) {
    let clayMineral = this.commodities.filter(x => x.mineralType.name.toLowerCase() === 'Clay Minerals'.toLowerCase());
    this.filteredCommodities = this.commodities.filter(x => x.mineralType.id === id);
    if(this.data.isEconomicGeologist){

      if(this.filteredCommodities.filter(x => x.mineralType.id.toLowerCase() === 'bd7f7937-432a-4345-b204-b186b7d95623'.toLowerCase() && x.mineralType.id === id )[0].mineralType.id === 'bd7f7937-432a-4345-b204-b186b7d95623'){
        this.filteredCommodities = this.filteredCommodities.filter(value => value.id!=='c1591fcb-38e9-41c7-93dd-a128962206b9');
        this.filteredCommodities = this.filteredCommodities.filter(value => value.id!=='b93064ce-f845-4ff1-885f-120cfb72d282' );//remove Asbetos
        this.filteredCommodities = this.filteredCommodities.filter(value => value.id!=='0fe61d3c-3a4f-4f93-be01-a9cc4bd2622f'  );//remove Granite
        this.filteredCommodities = [...this.filteredCommodities,...clayMineral];
      }
      if(this.filteredCommodities.filter(x => x.mineralType.name.toLowerCase() === 'Energy'.toLowerCase() && x.mineralType.id === id )[0].mineralType.name ==='Energy'.toLowerCase()){
        this.commodities = this.commodities.filter(value => value.id!=='e498ae2c-f787-4d98-a768-1e6e0f93d849')
      }
      if(this.filteredCommodities.filter(x => x.mineralType.name.toLowerCase() === 'Semi-Precious Stones'.toLowerCase() && x.mineralType.id === id )[0].mineralType.name ==='Semi-Precious Stones'.toLowerCase()){
        this.commodities = this.commodities.filter(value => value.id!=='6b293302-7656-4d80-ba25-c7e71f86c200' &&  value.id!=='2d863c9d-df70-4d27-b4a2-651ccd35ff9a');
      }
      if(this.filteredCommodities.filter(x => x.mineralType.name.toLowerCase() === 'Precious Stones'.toLowerCase() && x.mineralType.id === id )[0].mineralType.name ==='Precious Stones'.toLowerCase()){
        this.commodities = this.commodities.filter(value => value.id!=='181f8ecf-4984-4cc7-88d0-c368f7bce9c9' );//remove silver
      }

    }
  }

  removeCommodity(commodity: any) {
    const index = this.selectedCommodities.indexOf(commodity);
    if (index >= 0) {
      this.selectedCommodities.splice(index, 1);
    }
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  compareNames(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1[0] === obj2[0] : obj1 === obj2;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    let request = this.requestForm.value;
    const requesterID = request.customer.id;
    const attendantsID = [];
    this.expectedAttendants.forEach(attendant => {
      attendantsID.push(attendant.id);
    });

    if (this.data.request) {
      this.data.request.customerID = requesterID;
      this.data.request.expectedAttendants = attendantsID;
      this.data.request.requestDate = request.requestDate;
      this.data.request.requestThrough = request.requestThrough;
      this.data.request.location = request.location;
      this.data.request.company = (request.company) ? request.company.id : '';
      this.data.request.areaOfInterest = request.areaOfInterest;
      if(this.data.isEconomicGeologist){
        this.data.request.requestedServices = ['Mineral Resource Assessment'];
      }else {
        this.data.request.requestedServices = request.requestedServices;
      }
      this.data.request.plannedTurnAroundTime = request.plannedTurnAroundTime;
      this.data.request.coreInformation = request.coreInformation;
      this.data.request.comments = request.comments;
      this.data.request.commodities = this.selectedCommodities;

      this.scheduleService.updateCustomerRequest(this.data.request).subscribe(
        res => {
          this.toastr.success('Customer request updated successfully', 'Update Request');
          this.onClose();
        }, error => {
          this.toastr.error(error.statusText, 'Update Request');
        }
      );
    } else {
      // -- omit fields that are not part of the request entity --
      request = _.omit(request, ['customer']);
      request.customerID = requesterID;
      request.expectedAttendants = attendantsID;
      request.company = (request.company) ? request.company.id : '';
      request.commodities = this.selectedCommodities;
      if(this.data.isEconomicGeologist){
        request.requestedServices = ['Mineral Resource Assessment'];
      }

      this.userService.getMineralResourceUsernames().subscribe(value => {
        let newRequestModel = {geologists:value as string [],request:request};
        this.scheduleService.createCustomerRequest(newRequestModel).subscribe(
          res => {
            this.toastr.success('Customer request created successfully', 'Create Request');
            this.onClose();
          }, error => {
            this.toastr.error(error.statusText, 'Create Request');
          }
        );
      })

    }
  }
}
