import {Component, OnInit} from '@angular/core';
import {Honorific} from 'src/app/common/entity/security/profile/honorific';
import {Countries} from 'src/app/common/entity/security/profile/countries';
import {IdType} from 'src/app/common/entity/security/profile/id-type';
import {User} from 'src/app/common/entity/security/user/user';
import {Person} from 'src/app/common/entity/security/profile/person';
import {Sex} from 'src/app/common/entity/security/profile/sex';
import {UserService} from '../user.service';
import {SystemRole} from 'src/app/common/entity/security/role/system-role';
import {UserValidation} from '../user.validations';
import {MyErrorStateMatcher} from 'src/app/shared-kernel/validation/email.validation';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {IdTypeEnum} from 'src/app/shared-kernel/enumerations/id-type-enum';
import {BusinessUnitData} from '../../../_core/data/BusinessUnitData';
import {BusinessUnit} from '../../../_core/data/_models/business.models';

@Component({
  selector: 'app-first-setup',
  templateUrl: './first-setup.component.html',
  styleUrls: ['./first-setup.component.scss']
})
export class FirstSetupComponent implements OnInit {
  public title: Honorific;
  public titles: Honorific[];
  public country: Countries;
  public countries: Countries[];
  public idType: IdType;
  public idTypes: IdType[];
  public businessUnit: BusinessUnit;
  public user: User;
  public person: Person;
  public sex: Sex;
  public sexes: Sex[];
  public email: string;
  public role: SystemRole;
  public selectedRoles: SystemRole[];
  idTypeEnum: typeof IdTypeEnum = IdTypeEnum;
  businessUnits: Array<BusinessUnit>;

  constructor(
    private userService: UserService,
    private validate: UserValidation,
    private toastr: ToastrService,
    private businessUnitData: BusinessUnitData,
    private router: Router) {
  }

  matcher = new MyErrorStateMatcher();

  emailFormControl = this.validate.emailFormControl;

  validation_messages = this.validate.validation_messages;

  idNumberTitle = 'ID Number';
  validIdLength: boolean;
  validIdGender: boolean;
  genderSelected: boolean;
  idTypeSelected: boolean;
  userExist: boolean;

  getErrorMessage() {
    return this.emailFormControl.hasError('required') ? 'You must enter a value' :
      this.emailFormControl.hasError('emailFormControl') ? 'Not a valid email' :
        '';
  }

  ngOnInit() {
    this.getTitles();
    this.getCountries();
    this.getIdTypes();
    this.getSexes();
    this.getBusinessUnits();
    this.title = new Honorific();
    this.country = new Countries();
    this.idType = new IdType();
    this.user = new User();
    this.person = new Person();
    this.sex = new Sex();
    this.role = new SystemRole();
    this.validIdLength = true;
    this.validIdGender = true;
    this.genderSelected = true;
    this.idTypeSelected = true;
  }

  ngAfterViewInit() {
    this.userService.checkIfFirstUserExist()
      .subscribe(data => {
        this.userExist = data as boolean;
        if (this.userExist === true) {
          this.router.navigate(['auth']);
          this.errorsmsg('First User already exist');
        }
      });
  }

  onSubmitFirstSetUp() {
    let valid: boolean;

    this.selectedRoles = [{
      id: 'c0d37dca-30cb-11e8-8823-0fccf85311c8',
      name: this.role.name,
      description: this.role.description,
      authorities: this.role.authorities,
      businessUnit: this.role.businessUnit
    }];
    this.user = {
      id: this.user.id,
      password: this.user.password,
      username: this.user.username,
      accountNonExpired: this.user.accountNonExpired,
      accountNonLocked: this.user.accountNonLocked,
      credentialsNonExpired: this.user.credentialsNonExpired,
      lastPasswordReset: this.user.lastPasswordReset,
      enabled: this.user.enabled,
      roles: this.selectedRoles,
      businessUnit: this.businessUnit
    };

    this.person = {
      id: this.person.id,
      idType: this.idType,
      idNumber: this.person.idNumber,
      dateOfBirth: this.person.dateOfBirth,
      name: this.person.name,
      middleName: this.person.middleName,
      surname: this.person.surname,
      workIdNumber: this.person.workIdNumber,
      user: this.user,
      contactDetails: this.person.contactDetails,
      kin: this.person.kin,
      sex: this.sex,
      countries: this.country,
      physicalAddress: this.person.physicalAddress,
      postalAddress: this.person.postalAddress,
      profession: this.person.profession,
      title: this.title
    };

    valid = this.validate.validateFirstRegistrationForm(this.person);
    if (this.emailFormControl.hasError('email')) {
      this.errorsmsg('Please make sure provided usermane is a valid email address');
    } else {
      if (valid) {
        this.userService.registerFirstTimeUser(this.person);
      }
    }
  }

  checkIfFirstUserExist(): boolean {
    let userExist: boolean;
    this.userService.checkIfFirstUserExist()
      .subscribe(data => {
        userExist = data as boolean;
      });
    return userExist;
  }

  getTitles() {
    this.userService.getAllTitles()
      .subscribe(data => {
        this.titles = data as Honorific[];
      });
  }

  getCountries() {
    this.userService.getAllCountries()
      .subscribe(data => {
        this.countries = data as Countries[];
      });
  }

  getIdTypes() {
    this.userService.getAllIdTypes()
      .subscribe(data => {
        this.idTypes = data as IdType[];
      });
  }

  private getBusinessUnits() {
    this.businessUnitData.getBusinessUnits().subscribe(res => {
      this.businessUnits = res;
    }, err => {
      this.toastr.warning(err.message, 'Failed to get business units');
    });
  }

  getSexes() {
    this.userService.getAllSexes()
      .subscribe(data => {
        this.sexes = data as Sex[];
      });
  }

  changeIdType() {
    if (this.idType.id === this.idTypeEnum.Omang) {
      this.idNumberTitle = 'Omang Number';
      this.person.idNumber = '';
    }
    if (this.idType.id === this.idTypeEnum.BirthCertificate) {
      this.idNumberTitle = 'Birth Certificate Number';
      this.person.idNumber = '';
    }
    if (this.idType.id === this.idTypeEnum.Passport) {
      this.idNumberTitle = 'Passport Number';
      this.person.idNumber = '';
    }
  }

  onGenderTypeChange() {
    this.genderSelected = true;
    if (this.person.idNumber !== null) {
      this.validateIdNumberOnTyping();
    }
  }

  returnToLogin() {
    this.router.navigate(['auth']);
  }

  validateIdNumberOnTyping() {
    if (this.idType.id !== null) {
      this.idTypeSelected = true;
      if ((this.idType.id === this.idTypeEnum.Omang) || (this.idType.id === this.idTypeEnum.BirthCertificate)) {
        if (this.validate.idNumberValidLength(this.person.idNumber, this.idType)) {
          this.validIdLength = true;
        } else {
          this.validIdLength = false;
        }
        if (this.sex.id !== null) {
          this.genderSelected = true;
          if (this.validate.idNumberValidGender(this.person.idNumber, this.idType, this.sex)) {
            this.validIdGender = true;
          } else {
            this.validIdGender = false;
          }
        } else {
          this.genderSelected = false;
        }
      }
    } else {
      this.idTypeSelected = true;
    }
  }

  errorsmsg(message) {
    this.toastr.error(message);
  }

}
