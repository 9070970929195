import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import {CustomerRequest} from '../entity/CustomerRequest';
import {EventSchedule} from '../entity/EventSchedule';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) {
  }

  createCustomerRequest(request): Observable<any> {
    return this.http.post<any>(`${environment.collectionsBaseUrl}/api/schedule/v1/requests/create`, request);
  }

  updateCustomerRequest(request: CustomerRequest): Observable<CustomerRequest> {
    return this.http.put<CustomerRequest>(`${environment.collectionsBaseUrl}/api/schedule/v1/requests/update`, request);
  }

  getAllCustomerRequest(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/requests/all`);
  }

  getAllCustomerRequestByService(service: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/requests/service/${service}`);
  }

  getCustomerRequestById(id: string): Observable<CustomerRequest> {
    return this.http.get<CustomerRequest>(`${environment.collectionsBaseUrl}/api/schedule/v1/requests/${id}`);
  }

  // -- Event schedule --
  createEventSchedule(event: EventSchedule): Observable<EventSchedule> {
    return this.http.post<EventSchedule>(`${environment.collectionsBaseUrl}/api/schedule/v1/events/create`, event);
  }

  updateEventSchedule(event: EventSchedule): Observable<EventSchedule> {
    return this.http.put<EventSchedule>(`${environment.collectionsBaseUrl}/api/schedule/v1/events/update`, event);
  }

  getAllEventScheduleByRequestId(id: string): Observable<EventSchedule[]> {
    return this.http.get<EventSchedule[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/events/${id}/all`);
  }

  getAllEventSchedules(): Observable<EventSchedule[]> {
    return this.http.get<EventSchedule[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/events/all`);
  }

  getMineralEventSchedules(): Observable<EventSchedule[]> {
    return this.http.get<EventSchedule[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/events/all/mineral-resource`);
  }

  getEventScheduleById(id: string): Observable<EventSchedule> {
    return this.http.get<EventSchedule>(`${environment.collectionsBaseUrl}/api/schedule/v1/events/${id}`);
  }

  getAllMineralTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/mineral-types/all`);
  }

  getAllCommodities(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.collectionsBaseUrl}/api/schedule/v1/commodities/all`);
  }
}
