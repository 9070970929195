import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators, ValidatorFn, FormControl } from '@angular/forms';

import { MatTableDataSource,MatPaginator,MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DcpFoundationData } from 'src/app/_core/data/dcp-foundation';
import { DynamicConePenetrometerTestLog } from 'src/app/_core/data/_models/dcp-foundation.models';
import { UnitsData } from 'src/app/_core/data/units';
import { Unit } from 'src/app/_core/data/_models/math.models';

@Component({
    selector: "create-test-point",
    templateUrl: "./create-test-point.component.html",
    styleUrls: ["./create-test-point.component.scss"]
})
export class CreateTestPointComponent implements OnInit {
    newTestPointForm: FormGroup;
    pointToEdit: DynamicConePenetrometerTestLog;
    testId: string;
    submited: boolean = false;
    units: Array<Unit>;
    testPoints: Array<DynamicConePenetrometerTestLog>;
    tableDataSource: MatTableDataSource<DynamicConePenetrometerTestLog>;
    @ViewChild(MatPaginator) paginator: MatPaginator;


    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<CreateTestPointComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { testId: string, testPoint: DynamicConePenetrometerTestLog },
        private toastr: ToastrService,
        private dcpFoundationData: DcpFoundationData,
        private unitsData: UnitsData
    ) {
        this.testId = data.testId;
        this.pointToEdit = data.testPoint;
    }

    ngOnInit() {
        this.initForm();
        this.initUnits();
    }

    onSubmit() {
        this.submited = true;
        const formValue = this.newTestPointForm.getRawValue();

        let finalPoint: DynamicConePenetrometerTestLog = {
            numberOfBlows: formValue.numberOfBlows,
            penetration: {
                measure: formValue.penetration,
                unit: this.units.find(u => u.unit === "mm")
            },
            penetrationRate: {
                measure: formValue.penetrationRate,
                unit: this.units.find(u => u.unit === "mm/blow")
            },
            startDepth: {
                measure: formValue.startDepth,
                unit: this.units.find(u => u.unit === "mm")
            },
            finishDepth: {
                measure: formValue.finishDepth,
                unit: this.units.find(u => u.unit === "mm")
            },
            comments: formValue.comments,
            isDeleted: false
        };

        if (this.pointToEdit) {
            finalPoint.created = this.pointToEdit.created;
            finalPoint.creator = this.pointToEdit.creator;
            finalPoint.id = this.pointToEdit.id;
            finalPoint.isDeleted = this.pointToEdit.isDeleted;
            finalPoint.reference = this.pointToEdit.reference;
            finalPoint.updated = this.pointToEdit.updated;
            finalPoint.updater = this.pointToEdit.updater;
        }

        this.dcpFoundationData.addTestPoint(this.testId, finalPoint).subscribe(res => {
            this.submited = false;
            this.dialogRef.close(res);
            this.getTestPoints();
        }, err => {
            this.toastr.error(err.message, "Failed");
            this.submited = false;
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
    getTestPoints() {
        if (this.testId) {
            this.dcpFoundationData.getTestPoints(this.testId).subscribe(res => {
                this.testPoints = res;
                this.testPoints.sort((a, b) => <any>a.startDepth.measure - <any>b.startDepth.measure);
                this.tableDataSource = new MatTableDataSource<DynamicConePenetrometerTestLog>(this.testPoints);
                this.tableDataSource.paginator = this.paginator;
            });
        } else {
            this.getTestPoints();
        }
    }

    private initForm() {
        this.newTestPointForm = this.formBuilder.group({
            numberOfBlows: [this.pointToEdit ? this.pointToEdit.numberOfBlows : null, [Validators.required, Validators.min(0)]],
            penetration: [this.pointToEdit ? this.pointToEdit.penetration.measure : null, Validators.required],
            penetrationRate: [this.pointToEdit ? this.pointToEdit.numberOfBlows/this.pointToEdit.penetration.measure : null, Validators.required],
            startDepth: [this.pointToEdit ? this.pointToEdit.startDepth.measure : null, Validators.required],
            finishDepth: [this.pointToEdit ? this.pointToEdit.finishDepth.measure : null, Validators.required],
            comments: [this.pointToEdit ? this.pointToEdit.comments : null, Validators.required]
        }, { validators: this.rangeValidator });

        this.newTestPointForm.valueChanges.subscribe(val => {
            if (val.penetration && val.penetration !== 0 && val.numberOfBlows && val.numberOfBlows !== 0) {
                const rate = (val.penetration / val.numberOfBlows);
                this.newTestPointForm.controls.penetrationRate.patchValue(rate, { emitEvent: false });

                this.validateForm(this.newTestPointForm);
            } else if (val.penetration === 0 || val.numberOfBlows === 0) {
                this.newTestPointForm.controls.penetrationRate.patchValue(null, { emitEvent: false });

                this.validateForm(this.newTestPointForm);
            }
        });
    }

    private initUnits() {
        this.unitsData.getUnits().subscribe(res => {
            this.units = res;
        });
    }

    private rangeValidator: ValidatorFn = (fg: FormGroup) => {
        const start = fg.get('startDepth').value;
        const end = fg.get('finishDepth').value;

        return start !== null && end !== null && start < end  ? null : { range: true };
    }

    private validateForm(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
            formGroup.controls[key].markAsTouched();
            formGroup.controls[key].updateValueAndValidity({ emitEvent: false });
        });
    }
}
