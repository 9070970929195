import { Component, Input } from '@angular/core';

@Component({
    selector: 'activity-comments',
    templateUrl: './activity-comments.component.html',
    styleUrls: ['./activity-comments.component.scss']
})
export class ActivityCommentsComponent {
    public displayList: any[];

    @Input('history') set history(val: any[]) {
        this.displayList = this.filterOutAndSort(val);
    }

    constructor() {}

    private filterOutAndSort(history: any[]): any[] {
        let filtered: any[] = [];

        history.forEach(h => {
            switch(h.currentState) {
                case 'REJECTED_BY_QA':
                case 'REJECTED_BY_SENIOR_CURATOR':
                case 'REJECTED_BY_GEOLOGIST':
                case 'SUBMITTED_TO_MANAGER':
                case 'PENDING_SENIOR_CURATOR_SUBMISSION':
                case 'SUBMITTED_TO_SENIOR_CURATOR':
                case 'SUBMITTED_TO_MANAGER_BY_GEOLOGIST':
                case 'REJECTED_BY_MANAGER':
                case 'SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR':
                case 'APPROVED_MANAGER':
                case 'PUBLISHED':
                    filtered.push(h);
                    break;
                case 'PENDING_QA_SUBMISSION':
                    if (h.comments && h.comments.trim() !== '') {
                        filtered.push(h);
                    }
                    break;
                case 'PENDING_GEOLOGIST_SUBMISSION':
                  if (h.comments && h.comments.trim() !== '') {
                      filtered.push(h);
                  }
                break;
                default:
                    break;
            }
        });

        return filtered.sort((a, b) => new Date(a.chagedAt).getTime() - new Date(b.chagedAt).getTime());
    }
}
