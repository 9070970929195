import {Borehole} from '../../common/borehole';
import {Size} from '../../common/size';
import {RockName} from './RockName';
import {CoreRecoveryLog} from "./CoreRecoveryLog";
import {DiscontinuityType} from "./DiscontinuityType";
import {MacroRoughness} from "./MacroRoughness";
import {MicroRoughness} from "./MicroRoughness";
import {Separation} from "./Separation";
import {JointWallAlteration} from "./JointWallAlteration ";
import {InFillType} from "./InFillType";

export class DiscontinuityLog {
  id: string;
  coreRecoveryLog: CoreRecoveryLog;
  depthDown: Size;
  discontinuityType: DiscontinuityType;
  dipDirection: string;
  angleFromHorizontal: string;
  macroRoughness: MacroRoughness;
  microRoughness: MicroRoughness;
  infillType: InFillType;
  separation: Separation;
  jointWallAlteration: JointWallAlteration;
  comments: string;

}
