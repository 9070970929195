import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApproveAction} from '../../../../models';
import {ApprovalService} from '../../services/approval.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-approval-change-state',
  templateUrl: './change-state.component.html',
  styleUrls: ['./change-state.component.scss']
})
export class ChangeStateComponent implements OnInit {
  comment: string;
  currentAction = 'submit to QA';
  actionName = 'Submit to QA';
  successMessage: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ChangeStateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: ApproveAction, itemId: string, itemName: string },
    private _toastr: ToastrService,
    private _approvalServ: ApprovalService
  ) {
  }

  ngOnInit(): void {
    switch (+this.data.action) {
      case ApproveAction.SUBMIT_TO_QA:
        this.currentAction = 'submit to QA.';
        this.actionName = 'Submit to QA';
        this.successMessage = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length ) + ' submitted successfully';
        this.title = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length ) + ' Submit';
        break;
      case ApproveAction.QA_APPROVE:
        this.currentAction = 'approve.';
        this.actionName = 'Submit to Manager';
        this.successMessage = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' approved successfully';
        this.title = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' Approve';
        break;
      case ApproveAction.QA_REJECT:
        this.currentAction = 'reject.';
        this.actionName = 'QA Reject';
        this.successMessage = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' returned successfully';
        this.title = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' Reject';
        break;
      case ApproveAction.MANAGER_APPROVE:
        this.currentAction = 'approve/publish.';
        this.actionName = 'Publish';
        this.successMessage = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' approved successfully';
        this.title = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        this.currentAction = 'reject.';
        this.actionName = 'Manager Reject';
        this.successMessage = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' returned successfully';
        this.title = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length) + ' Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        this.currentAction = 'un-publish.';
        this.actionName = 'Un-Publish';
        this.successMessage = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length - 1) + ' published successfully';
        this.title = this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length - 1) + ' Published';
        break;
      default:
        throw new Error('Unknown change state');
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }

  changeState() {
    // when approving boreholes
    if (this.data.itemName.toLowerCase() === 'borehole') {
      this._approvalServ.changeState(this.data.itemId, {
        action: this.data.action, comment: this.comment
      }).subscribe(res => {
        this._toastr.success('Submitted ' + this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length - 1) +  ' to QA', 'Borehole Submitted');
        this.dialogRef.close(res);
      }, err => {
        this._toastr.warning(err.message, this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length - 1) + ' Submit failed');
      });


    } else if (this.data.itemName.toLowerCase() === 'dcp foundation') { //  when approving dcp foundation

      this._approvalServ.changeDcpFoundationState(this.data.itemId, {
        action: this.data.action, comment: this.comment
      }).subscribe(res => {
        this._toastr.success('Submitted ' + this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length - 1) +  ' to QA', 'Borehole Submitted');
        this.dialogRef.close(res);
      }, err => {
        this._toastr.warning(err.message, this.data.itemName[0].toUpperCase() +
        this.data.itemName.substring(1, this.data.itemName.length - 1) + ' Submit failed');
      });

    }

  }
}
