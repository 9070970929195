import { Component, OnInit } from '@angular/core';
import { InfiltrationTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/InfiltrationTest';
import { Router, UrlTree, UrlSegment, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { GeoTechService } from '../../../geo-tech.service';
import { Person } from 'src/app/common/entity/security/profile/person';
import { UserService } from 'src/app/auth/user/user.service';
import { ApproveAction } from 'src/app/applied-geoscience/models';
import Swal from 'sweetalert2';
import { ApprovalService } from '../../../borehole/services/approval.service';
import { ToastrService } from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import { InfiltrationTestCreateComponent } from '../infiltration-test-create/infiltration-test-create.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { Lightbox } from 'ngx-lightbox';
import { CompanyEnum } from '../../../../../shared-kernel/enumerations/company-enum';
import {BusinessUnitEnum} from "../../../../../shared-kernel/enumerations/business-unit-enum";
import {StorageConstants} from "../../../../../_core/storage.constants";
import {Roles} from "../../../../../shared-kernel/entity/Roles";
import {LocalAuthorities} from "../../../../../_auth/_providers";
import {LocalStorage} from "@ngx-pwa/local-storage";

@Component({
  selector: 'app-infiltration-test-detail',
  templateUrl: './infiltration-test-detail.component.html',
  styleUrls: ['./infiltration-test-detail.component.scss']
})
export class InfiltrationTestDetailComponent implements OnInit {

  infiltrationTest: InfiltrationTest;
  isBGIproject: boolean;
  companyEnum: typeof CompanyEnum = CompanyEnum;
  url: string;
  urlInfiltrationObject = {};
  person: Person;
  manager: string;
  coordinator: string;
  geologist: string;
  fullName: string;
  selectedSection: string;
  currentComment: string;
  totalNumberOfFiles: number;
  infiltId: string;
  album: any = [];
  currentPerson: any;
  currentUser: any;
  isAdminVar: boolean;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private geoTechService: GeoTechService,
    private userService: UserService,
    private _approvalServ: ApprovalService,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private lightbox: Lightbox
  ) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[4].parameters;
    this.urlInfiltrationObject = urlData;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.infiltId = params.id;
    });
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
    }, err => {
      this._toastr.warning(err.message, "Current User Error");
    }, () => {

    });
    this.infiltrationTest = new InfiltrationTest();
    this.getInfiltrationTestId();
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  close(): void {
    this.lightbox.close();
  }

  getInfiltrationTestId() {
    let infiltrationTestObject = {id: null};
    infiltrationTestObject = this.urlInfiltrationObject as InfiltrationTest;
    this.geoTechService.getInfiltrationTestById(infiltrationTestObject.id).subscribe(data => {
      this.infiltrationTest = data as InfiltrationTest;
      this.isBGIproject = this.infiltrationTest.project.company.name === this.companyEnum.BGI_NAME;
      for (let image of this.infiltrationTest.locationImages) {
        let albumImage: any = {};
        albumImage.src = 'data:image/jpg;base64,'+ image.image;
        albumImage.caption = image.name;
        albumImage.thumb = 'data:image/jpg;base64,'+ image.image;
        this.album.push(albumImage);
      }

      this.getCoordinator(this.infiltrationTest.projectCoordinator);
      this.getManager(this.infiltrationTest.projectManager);
      this.getGeologist(this.infiltrationTest.projectGeologist);
    }, error => {
    }, () => {
    });

  }

  getManager(id) {
    this.userService.getPersonById(id)
      .subscribe(data => {
        this.person = data as Person;
        this.manager = this.person.name + ' ' + this.person.surname;
      });
  }

  getCoordinator(id) {
    this.userService.getPersonById(id)
      .subscribe(data => {
        this.person = data as Person;
        this.coordinator = this.person.name + ' ' + this.person.surname;
      });
  }

  getGeologist(id) {
    this.userService.getPersonById(id)
      .subscribe(data => {
        this.person = data as Person;
        this.geologist = this.person.name + ' ' + this.person.surname;
      });
  }

  onSectionChange(selectedSection) {
    this.selectedSection = selectedSection;
  }

  redirectToDocuments() {
    this.selectedSection = 'infiltrationDocuments';
  }

  redirectToTests() {
    this.selectedSection = 'infiltrationTestLog';
  }

  navBack() {
    this.router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/list']);
  }

  openInfiltrationTestForEdit(infiltrationTest: InfiltrationTest) {
    const newDCPDialogRef = this.dialog.open(InfiltrationTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: infiltrationTest,
    });
    newDCPDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getInfiltrationTestId();
      }
    });
  }

  changeState(action: ApproveAction) {
    let successMessage: string = 'Infiltration Test submitted successfully';
    let title: string = 'Infiltration Test Submit';

    switch (+action) {
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'Infiltration Test submitted successfully';
        title = 'Infiltration Test Submit';
        break;
      case ApproveAction.QA_APPROVE:
        successMessage = 'Infiltration Test approved successfully';
        title = 'Infiltration Test Approve';
        break;
      case ApproveAction.QA_REJECT:
        successMessage = 'Infiltration Test rejected successfully';
        title = 'Infiltration Test Reject';
        break;
      case ApproveAction.MANAGER_APPROVE:
        successMessage = 'Infiltration Test approved successfully';
        title = 'Infiltration Test Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        successMessage = 'Infiltration Test rejected successfully';
        title = 'Infiltration Test Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        successMessage = 'Infiltration Test published successfully';
        title = 'Infiltration Test Published';
        break;
      default:
        throw new Error('Unknown change state');
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this.spinner.show();
        this._approvalServ.changeStateInfiltrationTest(this.infiltrationTest.id, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this._toastr.success(successMessage, title);
          this.getInfiltrationTestId();
        }, err => {
          this.spinner.hide();
          this._toastr.warning(err.message, 'Failed');
        }, ()=> {
          this.spinner.hide();
        });
      }
    });
  }
  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
      if (res) {
        this.currentPerson = res;
        this.currentPerson['user'] = this.currentUser;
        this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    }, ( ) => {
      this.isAdminNew(this.currentPerson);
    });
  }
  isAdminNew(current) {
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {
        if (value.roles.find(x => x.name == Roles.SYSTEM_ADMIN)){
          this.isAdminVar = true;
        }
        else {
          this.isAdminVar = false;
        }
      }
    )
  }

}
