import {Injectable, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { Person } from 'src/app/common/entity/security/profile/person';
import { User } from 'src/app/common/entity/security/user/user';
import { FormControl, Validators } from '@angular/forms';
import { IdType } from 'src/app/common/entity/security/profile/id-type';
import { Sex } from 'src/app/common/entity/security/profile/sex';
import { GenderEnum } from 'src/app/shared-kernel/enumerations/gender-enum';
import { IdTypeEnum } from 'src/app/shared-kernel/enumerations/id-type-enum';


@Injectable({
    providedIn: 'root'
})
export class UserValidation implements OnInit {
    person: Person;
    user: User;
    genderEnum: typeof GenderEnum = GenderEnum;
    idTypeEnum: typeof IdTypeEnum = IdTypeEnum;

    validation_messages = {
        'title': [
            { type: 'required', message: 'Please make sure title is selected' }
        ],
        'name': [
            { type: 'required', message: 'Please provide first name' },
        ],
        'surname': [
            { type: 'required', message: 'Please provide surname' },
        ],
        'dateOfBirth': [
            { type: 'required', message: 'Please provide date of birth' },
        ],
        'country': [
            { type: 'required', message: 'Please provide country' }
        ],
        'idType': [
            { type: 'required', message: 'Please provide id type' },
        ],
        'idNumber': [
            { type: 'required', message: 'Please provide id number' },
        ],
        'workIdNumber': [
            { type: 'required', message: 'Please provide work number' },
        ],
        'profession': [
            { type: 'required', message: 'Please provide profession' }
        ],
        'sex': [
            { type: 'required', message: 'Please provide gender' },
        ],
        'username': [
            { type: 'required', message: 'Please provide username' },
        ],
        'businessUnit': [
            { type: 'required', message: 'Please provide business unit' },
        ]
      };

      emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
      ]);

  constructor(private toastr: ToastrService) {
  }

  ngOnInit() {}

  validateFirstRegistrationForm(person: Person): boolean {
    this.person = person;
    let idMiddleNumber: string;
    idMiddleNumber = this.person.idNumber.charAt(4);
    const genderMale = this.genderEnum.Male;
    const genderFemale = this.genderEnum.Female;

    if (!this.person.title.id) {
        this.errorsmsg('Please make sure title is selected');
        return false;
    } else if (!this.person.name) {
        this.errorsmsg('Please provide first name');
        return false;
    } else if (!this.person.surname) {
        this.errorsmsg('Please provide surname');
        return false;
    } else if (!this.person.dateOfBirth) {
        this.errorsmsg('Please provide date of birth');
        return false;
    } else if (!this.person.countries.id) {
        this.errorsmsg('Please provide country');
        return false;
    } else if (!this.person.idType.id) {
        this.errorsmsg('Please provide id type');
        return false;
    } else if (!this.person.idNumber) {
        this.errorsmsg('Please provide id number');
        return false;
    } else if (!this.person.workIdNumber) {
        this.errorsmsg('Please provide work number');
        return false;
    } else if (!this.person.profession) {
        this.errorsmsg('Please provide profession');
        return false;
    } else if (!this.person.sex.id) {
        this.errorsmsg('Please provide gender');
        return false;
    } else if (!this.person.user) {
        this.errorsmsg('Please provide user details');
        return false;
    } else if (!this.person.user.username) {
        this.errorsmsg('Please provide username');
        return false;
    } else if (this.person.idNumber) {
        if (this.person.idType.id !== this.idTypeEnum.Passport) {
            if (this.person.sex.id === genderMale) {
                if (idMiddleNumber !== genderMale) {
                    this.errorsmsg('Omang / Birth certifacate must correspond with selected gender');
                    return false;
                } else {
                    return true;
                }
            }
            if (this.person.sex.id === genderFemale) {
                if (idMiddleNumber !== genderFemale) {
                    this.errorsmsg('Omang / Birth certifacate must correspond with selected gender');
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }

    } else {
        return true;
    }
  }

  idNumberValidLength(idNumber: String, idType: IdType): boolean {
    const validOmangBirthCertificateNumber = 9;
    if (idNumber.length !== validOmangBirthCertificateNumber) {
        return false;
    } else {
        return true;
    }
  }

  idNumberValidGender(idNumber: String, idType: IdType, gender: Sex): boolean {
      let idMiddleNumber: string;
      idMiddleNumber = idNumber.charAt(4);
      const genderMale = this.genderEnum.Male;
      const genderFemale = this.genderEnum.Female;
    if (gender.id === genderMale) {
        if (idMiddleNumber !== genderMale) {
            return false;
        } else {
            return true;
        }
    }
    if (gender.id === genderFemale) {
        if (idMiddleNumber !== genderFemale) {
            return false;
        } else {
            return true;
        }
    }
  }

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }

}
