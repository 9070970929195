import { Component, OnInit } from '@angular/core';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WaterConstantRateTest } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterConstantRateTest';
import { UserService } from '../../../../../auth/user/user.service';
import { Person } from '../../../../../common/entity/security/profile/person';
import { WaterPumpTestConstantRateTestCreateComponent } from '../water-pump-test-constant-rate-test-create/water-pump-test-constant-rate-test-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GeoTechService } from '../../../../geo-tech/geo-tech.service';
import { Notes } from '../../../../../shared-kernel/entity/applied-geoscience/notes';

@Component({
  selector: 'app-water-pump-test-constant-rate-test-view',
  templateUrl: './water-pump-test-constant-rate-test-view.component.html',
  styleUrls: ['./water-pump-test-constant-rate-test-view.component.scss']
})
export class WaterPumpTestConstantRateTestViewComponent implements OnInit {
  waterConstantRateTest: WaterConstantRateTest;
  geologistPersonOb: string;
  geologistPersonCrt: string;
  geologist: any;
  boreholeDetails: Borehole;
  boreholeNo: string;
  boreholeNos: Notes[] = [];
  constantRateId: string;
  boreholeId: string;
  currentState: string;

  constructor(private hydrogeologyService: HydrogeologyService, private router: Router, private activeRoute: ActivatedRoute, private dialog: MatDialog,
              private geoTechService: GeoTechService,
              private userService: UserService) {
    this.activeRoute.params.subscribe(value => {
      this.constantRateId = value.id;
      this.boreholeId = value.borehole;
      this.currentState = value.state;
    });
  }

  ngOnInit() {
    this.getBoreholeDetails();
    this.getConstantRateById();

  }

  getConstantRateById() {
    this.hydrogeologyService.getWaterConstantRateTestById(this.constantRateId).subscribe(value => {
      this.waterConstantRateTest = value as WaterConstantRateTest;
      this.getPersonsCrt(this.waterConstantRateTest.geologist);
      if(this.waterConstantRateTest.waterObservationBoreholes) {
        this.getPersonsOb(this.waterConstantRateTest.waterObservationBoreholes[0].geologist);
      }

      if(this.waterConstantRateTest.waterObservationBoreholes) {
        this.getBoreholeNumbers();
      }

    });
  }


  getBoreholeNumbers() {

    this.geoTechService.geBoreholeNumber().subscribe(value => {
      this.boreholeNos = value as Notes[];
      this.boreholeNo = this.boreholeNos.filter(value1 => value1.id === this.waterConstantRateTest.waterObservationBoreholes[0].boreholeNumber)[0].description;

    }, error => {
    }, () => {

    });
  }

  cancelTestView() {
    const idObject = {id: this.boreholeId};
    this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', idObject]);
  }

  editRecords(test) {
    const constantRateDialogRef = this.dialog.open(WaterPumpTestConstantRateTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: {waterConstantRateTest: test, url: this.boreholeDetails}

    });

    constantRateDialogRef.afterClosed().subscribe(value => {
      if(value) {
        this.getConstantRateById();
      }
    });
  }

  getBoreholeDetails() {
    this.geoTechService.getBoreholeById(this.boreholeId).subscribe(value => {
      this.boreholeDetails = value as Borehole;
    });
  }

  getPersonsCrt(personId) {
    this.userService.getPersonById(personId).subscribe(value => {
      let personTemp = value as Person;
      this.geologistPersonCrt = personTemp.name + ' ' + personTemp.surname;
    });
  }

  getPersonsOb(personId) {
    this.userService.getPersonById(personId).subscribe(value => {
      let personTemp = value as Person;
      this.geologistPersonOb = personTemp.name + ' ' + personTemp.surname;
    });
  }


}
