import { Component, OnInit } from '@angular/core';
import { Person, User } from '../../../../_core/data/_models/people.model';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Activity, ActivityType, CollectionsActivityType } from '../../../../activities/models';
import { ApprovalState } from '../../../../applied-geoscience/models';
import { UserDashboardService } from '../../user-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../../../../_auth/_providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ActivitiesService } from '../../../../activities/services/activities.service';
import { StorageConstants } from '../../../../_core/storage.constants';

@Component({
  selector: 'app-mineral-resource-project-dashboard',
  templateUrl: './mineral-resource-project-dashboard.component.html',
  styleUrls: ['./mineral-resource-project-dashboard.component.scss']
})
export class MineralResourceProjectDashboardComponent implements OnInit {

  public currentPerson: Person;
  numberOfBGIProjects = 0;
  myproject = {
    numberOfMyProjects:0,
    numberOfProjectsDrafts:0,
    numberOfProjectsSubmittedToQA:0,
    numberOfProjectsSubmittedToManager:0,
    numberOfProjectsApproved:0,
    numberOfProjectsReturned:0
  };
  loading = true;
  issetDataMyProject = false;
  isSet = true;
  public currentUser: User;
  displayedColumns: string[] = ['#', 'activities', 'status', 'date'];
  dataSource = new MatTableDataSource<any>();
  /** Based on the screen size, switch from standard to one column per row */
  cards :any;
  public myProjectsOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }
    },
    hover: {
      onHover: function(e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    }
  };

  public myProjectsLabels:string[] = ['Draft', 'Submitted-Manager', 'Returned','Approved'];
  public myProjectsData = [ 91, 107, 73, 107];
  public pieChartType = 'pie';

  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  colors: any ;
  colorProjects:any;
  isssetData = false;

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();


  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,private _activitiesServ: ActivitiesService,) {

    //this.spinner.show();
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'My Projects',cols: 1, rows: 3 ,id: 'myprojects'},
          { title: 'Drafts', cols: 1, rows: 3 , id: 'Drafts'},
          { title: 'Returned', cols: 1, rows: 3 ,id: 'Returned'},
          { title: 'Approved', cols: 1, rows: 3 ,id: 'Approved'}
        ];
      })
    );


  }


  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectApprovalCount().subscribe(result => {
      let projectApprovals = result as [];
      this.myproject['numberOfMyProjects'] = 0;
      projectApprovals.forEach(value => {
        this.myproject['numberOfMyProjects'] =  this.myproject['numberOfMyProjects']  + value['counts'];
        switch(value['state']) {
          case ApprovalState.PENDING_MANAGER_SUBMISSION:
            this.myproject.numberOfProjectsDrafts = value['counts'];
            break;
          case ApprovalState.SUBMITTED_TO_MANAGER:
            this.myproject.numberOfProjectsSubmittedToManager = value['counts'];
            break;
          case ApprovalState.REJECTED_BY_MANAGER:
            this.myproject.numberOfProjectsReturned =  value['counts'];
            break;
          case ApprovalState.PUBLISHED:
            this.myproject.numberOfProjectsApproved = value['counts'];
            break;
          default:
            break;
        };

      });
      this.myProjectsData = [ this.myproject.numberOfProjectsDrafts, this.myproject.numberOfProjectsSubmittedToManager,
        this.myproject.numberOfProjectsReturned, this.myproject.numberOfProjectsApproved];
      this.isssetData = true;
      this.issetDataMyProject = true;
    });

  }

  ngOnInit() {
    this.initPerson();
    this.getMyProjectsCount();
    this.getBGIProjectsCount();
    this.colorProjects = [{backgroundColor: ['#573419','#CC9933','#CC0000','#009900']}];
  }

  chartClickedProjects(event){
    switch (event.active[0]._view.label) {
      case this.myProjectsLabels[0]:
        this._router.navigate(['portal/activities/user-project-activities/PENDING_MANAGER_SUBMISSION']);
        break
      case this.myProjectsLabels[1]:
        this._router.navigate(['portal/activities/user-project-activities/SUBMITTED_TO_MANAGER']);
        break
      case this.myProjectsLabels[2]:
        this._router.navigate(['portal/activities/user-project-activities/REJECTED_BY_MANAGER']);
        break
      case this.myProjectsLabels[3]:
        this._router.navigate(['portal/activities/user-project-activities/PUBLISHED']);
        break
      default:
    }
  }


  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }


  openMyActivityDetails(activity) {
    const id = {id: activity.id};
    const shelvingId = activity.shelvingId;
    const inventoryId = activity.inventoryId;

    if (inventoryId && shelvingId) {
      switch (activity.activityType) {
        case  CollectionsActivityType.CORE_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Core/tray/'+activity.id]);
          break;
        }

        case  CollectionsActivityType.CHIPS_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Chips/'+activity.id]);
          break;
        }
      }

    } else {
      switch (activity.activityType) {

        case  ActivityType.PL_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/details', {id: activity.id, type: 'PL'}]);
          break;
        }
        case  ActivityType.BGI_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/borehole/details',  {id: activity.id, type: 'BGI'}]);
          break;
        }
        case  ActivityType.WATER_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details',  {id: activity.id, type: 'Water'}]);
          break;
        }
        case  ActivityType.TRIAL_PIT: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', id]);
          break;
        }
        case  ActivityType.INFILTRATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', id]);
          break;
        }
        case  ActivityType.DCP_FOUNDATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/detail', id]);
          break;
        }
        case  ActivityType.DCP_ROADWORKS: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/detail', id]);
          break;
        }
        case  ActivityType.SAMPLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/samples/list/details', id]);
          break;
        }
      }
    }
  }

  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    }, () => {
      this.getTableData(this.currentPerson);
    });
  }


  getTableData(current){
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {
        this._activitiesServ.getUserProjectActivitesByStage('PENDING_MANAGER_SUBMISSION').subscribe(res => {
          this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        }, err => {
          this._toastr.warning(err.message, 'Failed to get Pending activities');
        });

        this._activitiesServ.getUserProjectActivitesByStage('REJECTED_BY_MANAGER').subscribe(res => {
          this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        }, err => {
          this._toastr.warning(err.message, 'Failed to get Rejected activities');
        });

        this._activitiesServ.getUserProjectActivitesByStage('PUBLISHED').subscribe(res => {
          this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        }, err => {
          this._toastr.warning(err.message, 'Failed to get activities');
        });

      }
    );
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
    });
  }


  gotoDraftList(){
    //
    this._router.navigate(['portal/activities/user-project-activities/PENDING_MANAGER_SUBMISSION']);
  }

  gotoReturnedList(){
    this._router.navigate(['portal/activities/user-project-activities/REJECTED_BY_MANAGER']);
  }

  gotoApprovedList(){
    this._router.navigate(['portal/activities/user-project-activities/PUBLISHED']);
  }




}
