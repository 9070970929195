import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { ApprovalState } from './../../../applied-geoscience/models/approval-state';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minerals-geologist-sample-dashboard',
  templateUrl: './minerals-geologist-sample-dashboard.component.html',
  styleUrls: ['./minerals-geologist-sample-dashboard.component.scss']
})
export class MineralsGeologistSampleDashboardComponent implements OnInit {

  stillFetchingBGIProjectCount = true;
  stillFetchingMyProjectCount = true;
  stillFetchingMyActivitiesCount = true;
  stillFetchingDrafts = true;
  stillFetchingSubmittedToQA = true;
  stillFetchingSubmittedToManager = true;
  stillFetchingRejectedByQA = true;
  stillFetchingRejectedByManager = true;
  stillFetchingApproved = true;

  activitiesCount = {
    myActivities: 0,
    drafts: 0,
    submittedToQA: 0,
    submittedToManager: 0,
    returnedByQA: 0,
    returnedByManager: 0,
    approved: 0,
    numberOfBGIProjects: 0,
    numberOfMyProjects: 0
    }


  approvalEnum: typeof ApprovalState = ApprovalState;
  userName: string;


  constructor(
    private userDashBoardService: UserDashboardService
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
  }

  ngOnInit() {
    this.getBGIProjectsCount();
    this.getMyProjectsCount();
    this.getUserActivities();
    this.getUserDrafts();
    this.getSubmittedToQA();
    this.getSubmittedToManager();
    this.getActivitiesRejectedByQA();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.activitiesCount.numberOfBGIProjects = number;
      this.stillFetchingBGIProjectCount = false;
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.activitiesCount.numberOfMyProjects = number;
      this.stillFetchingMyProjectCount = false;
    });
  }

  getUserActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount('all').subscribe(number => {
      this.activitiesCount.myActivities = number;
      this.stillFetchingMyActivitiesCount = false;
    });
  }

  getUserDrafts(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(number => {
      this.activitiesCount.drafts = number;
      this.stillFetchingDrafts = false;
    });
  }

  getSubmittedToQA(){
      this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
        this.activitiesCount.submittedToQA = number;
        this.stillFetchingSubmittedToQA = false;
      });
  }

  getSubmittedToManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.activitiesCount.submittedToManager = number;
      this.stillFetchingSubmittedToManager = false;
    });
  }

  getActivitiesRejectedByQA(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.activitiesCount.returnedByQA = number;
      this.stillFetchingRejectedByQA = false;
    });
  }

  getActivitiesRejectedByManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.activitiesCount.returnedByManager = number;
      this.stillFetchingRejectedByManager = false;
    });
  }

  getApprovedActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.activitiesCount.approved = number;
      this. stillFetchingApproved = false;
    });
  }

}
