import { DashboardService } from './../../dashboard/dashboard.service';
import { CollectionsActivityType, ActivityType } from './../../../activities/models/activity.model';
import { GlobalDashboardService } from './../../global-dashboard.service';
import { User } from './../../../_core/data/_models/people.model';
import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pl-dashboard-ui',
  templateUrl: './pl-dashboard-ui.component.html',
  styleUrls: ['./pl-dashboard-ui.component.scss']
})
export class PlDashboardUiComponent implements OnInit {


  @Input('plDistrict') plDistrict: [];
  @Input('myplactivities') myplactivities: any;
  @Input('mypl') mypl: any;
  loading = true;
  public currentUser: User;
  colors:any;
  colorBarchart: any;
  barChartcolors: any;

  displayedColumns: string[] = ['#', 'activities', 'status', 'date'];
  plDataSource = new MatTableDataSource<any>();
  dataSource = new MatTableDataSource<any>();
  /** Based on the screen size, switch from standard to one column per row */
  activityPerDistrictCounts :any;
  plActivityPerDistrictCounts :any;
  cards :any;
  plcards :any;
  public myProjectsOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }
    },
    hover: {
      onHover: function(e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    }
  };
  public myactivitiesLabels = [ 'Draft', 'Submitted-QA', 'Submitted-Manager', 'Returned','Approved'];
  public myPlLabels = ['Draft','Submitted-QA', 'Submitted-Manager', 'Returned','Approved'];
  public myactivitiesData = [ 91, 107, 73, 107, 73];
  public myPlActivitiesData = [ 91, 107, 73, 107, 73];
  public myProjectsData = [ 91, 107, 73, 107];
  public myPlData = [ 91,91, 107, 73, 107];
  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }

  public plbarChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          steps: 2,
          stepValue: 5,
        }
      }]
    }
  };
  public plDistrictsLabels: [] =[];




  public barChartDataPls: any[] = [];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public pieChartType = 'pie';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private dashBoardService: DashboardService,
    private globalDashboardService: GlobalDashboardService,
    private spinner: NgxSpinnerService ) {

    //this.spinner.show();


    this.plcards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
          { title: 'All PL Activities per District', cols: 1, rows: 3 ,id: 'districts'},
          { title: 'My Pls',cols: 1, rows: 3 ,id: 'myprojects'},
          { title: 'Recent Activities', cols: 1, rows: 3 ,id: 'recents'}
        ];
      })
    );
  }

  ngOnInit() {


    this.myPlActivitiesData = [this.myplactivities.numberOfDrafts, this.myplactivities.numberOfPlActivitiesSubmittedToQA,
        this.myplactivities.numberOfPlActivitiesSubmittedToManager,this.myplactivities.returned, this.myplactivities.approved];
   this.myProjectsData = [ this.mypl.numberOfProjectsDrafts, this.mypl.numberOfProjectsSubmittedToManager,
      this.mypl.numberOfProjectsReturned, this.mypl.numberOfProjectsApproved];
   this.myPlData = [ this.mypl.numberOfPlDrafts,this.mypl.numberOfPlSubmittedToQA, this.mypl.numberOfPlSubmittedToManager,
      this.mypl.numberOfPlReturned, this.mypl.numberOfPlApproved];
    this.getRecentActivties();
    this.getPlDistrict();
    this.colors = [{backgroundColor: [  '#573419','#00A2E5',
    '#C8A207','#CC0000','#009900', '#CC9933']}];

    this.colorBarchart= [{backgroundColor: ['#00A2E5','#573419',
        '#C8A207','#CC0000','#009900', '#CC9933']}];

    this.barChartcolors = [{backgroundColor:  '#573419'},{backgroundColor: '#009900'}
      ,{backgroundColor: '#C8A207'},{backgroundColor: '#CC0000'},{backgroundColor: '#009900'},{backgroundColor: '#CC9933'}];

  }


  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/geologist/project/PENDING_QA_SUBMISSION']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/geologist/project/SUBMITTED_TO_QA']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/geologist/project/SUBMITTED_TO_MANAGER']);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate(['portal/activities/geologist/project/REJECTED_BY_QA']);
        break
      case this.myactivitiesLabels[4]:
        this._router.navigate(['portal/activities/geologist/project/PUBLISHED']);
      default:
    }
  }




  getPlDistrict(){
    this.globalDashboardService.plDistrictDashboardCounts().subscribe(value => {
        this.plActivityPerDistrictCounts = value;
        let activityDistrictList = this.plActivityPerDistrictCounts as [];
        let activityNames = new Set();

          activityDistrictList.forEach(districtActivities => {
              this.plDistrictsLabels.push(districtActivities['districtName']);
              let activityRecords = districtActivities['activityRecord'] as [];
              activityRecords.forEach(value2 => {
                activityNames.add(value2['activityName']);
              });
          });

          activityNames.forEach(activityName => {
              let data = [];
              activityDistrictList.forEach(rec => {
                  let activityRecords = rec['activityRecord'] as [];
                  let foundActivityRecord = activityRecords.find(activityRecord => activityRecord['activityName'] === activityName);
                  if(foundActivityRecord) {
                      data.push(foundActivityRecord['count']);
                  } else {
                      data.push(0);
                  }
              });
              this.barChartDataPls.push({data: data, label: activityName});
          });
      });
  }




  chartClickedPl(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/geologist/pl/PENDING_QA_SUBMISSION']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/geologist/pl/SUBMITTED_TO_QA']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/geologist/pl/SUBMITTED_TO_MANAGER']);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate(['portal/activities/geologist/pl/REJECTED_BY_QA']);
        break
      case this.myactivitiesLabels[4]:
        this._router.navigate(['portal/activities/geologist/pl/PUBLISHED']);
      default:
    }
  }


  getRecentActivties()
  {
      this.loading = false;
      this.dashBoardService.getMyRecentActivities().subscribe(value => {
        this.dataSource.data = value as [];
        this.plDataSource.data = this.dataSource.data.filter(value1 => value1.activityType === 1).slice(0,this.dataSource.data.length>7?7:this.dataSource.data.length);
      });
  }



  gotoProspectingLicences(uri: string) {
    let urlParams: any;
    if('my_pl'=== uri){
      urlParams = { id: 'my_pl' };
    }
    else {
      urlParams= {};
    }

    this._router.navigate([`portal/applied-geoscience/prospecting-license/list`,urlParams]);
  }

  openMyActivityDetails(activity) {
    const id = {id: activity.id};
    const shelvingId = activity.shelvingId;
    const inventoryId = activity.inventoryId;

    if (inventoryId && shelvingId) {
      switch (activity.activityType) {
        case  CollectionsActivityType.CORE_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Core/tray/'+activity.id]);
          break;
        }

        case  CollectionsActivityType.CHIPS_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Chips/'+activity.id]);
          break;
        }
      }

    } else {
      switch (activity.activityType) {

        case  ActivityType.PL_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/details', {id: activity.id, type: 'PL'}]);
          break;
        }
        case  ActivityType.BGI_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/borehole/details', {id: activity.id, type: 'BGI'}]);
          break;
        }
        case  ActivityType.WATER_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', {id: activity.id, type: 'Water'}]);
          break;
        }
        case  ActivityType.TRIAL_PIT: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', id]);
          break;
        }
        case  ActivityType.INFILTRATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', id]);
          break;
        }
        case  ActivityType.DCP_FOUNDATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/detail', id]);
          break;
        }
        case  ActivityType.DCP_ROADWORKS: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/detail', id]);
          break;
        }
        case  ActivityType.SAMPLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/samples/list/details', id]);
          break;
        }
      }
    }
  }

}
