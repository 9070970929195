import { Size } from '../../common/size';
import { DCPTestSubgrade } from './DCPTestSubgrade';
import { DCPTestFoundation } from './DCPTestFoundation';

export class DynamicConePenetrometerTestLog {
    id: string;
    numberOfBlows: Int32Array;
    penetration: Size;
    penetrationRate: Size;
    startDepth: Size;
    finishDepth: Size;
    comments: String;
    dcpTestSubgrade: DCPTestSubgrade;
    dcpTestFoundation: DCPTestFoundation;
   }
