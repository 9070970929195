import { Size } from 'src/app/shared-kernel/entity/common/size';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';

export class WaterDrillersLogRecord {
    id: string;
    date: Date;
    depthFrom: Size = new Size();
    depthTo: Size = new Size();
    diameter: Size = new Size();
    description: string;
    drillString: Size = new Size();
    tableHeight: Size = new Size();
    drillerFormationLog: string;
    deleted: boolean;
    borehole: Borehole = new Borehole();
}
