import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute} from '@angular/router';
import { DrillerService } from '../../driller.service';
import { DrillingChecklist } from 'src/app/shared-kernel/entity/driller/DrillingChecklist';
import { ProjectService } from 'src/app/applied-geoscience/project/project.service';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { GeoTechService } from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import { DrillerValidation } from '../../driller.validation';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogConfig } from '@angular/material/dialog';
import { DrillerChecklistCreateComponent } from '../driller-checklist-create/driller-checklist-create.component';

@Component({
  selector: 'app-driller-checklist-list',
  templateUrl: './driller-checklist-list.component.html',
  styleUrls: ['./driller-checklist-list.component.scss']
})
export class DrillerChecklistListComponent implements OnInit {

  dataSourceChecklist: MatTableDataSource<DrillingChecklist> = new MatTableDataSource();
  displayedChecklistColumns = ['#', 'Request Number', 'Drilling Date', 'Checklist Type', 'Logged by', 'date', 'edit'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  drillingChecklists: DrillingChecklist[] = [];
  loading = true;
  validation_messages = this.drillerValidation.validation_messages;
  panelOpenState: boolean = false;
  drillingRequestId: string;

  constructor(
    private pageRoute: ActivatedRoute,
    private drillerService: DrillerService,
    private projectService: ProjectService,
    private companyService: CompanyService,
    private geoTechService: GeoTechService,
    private spinner: NgxSpinnerService,
    private drillerValidation: DrillerValidation,private dialog: MatDialog) {
    this.pageRoute.params.subscribe(params => {
      this.drillingRequestId = params.id;
    });
  }

  ngOnInit() {
    this.getAllDrillerChecklist();
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  resetPaginator() {
    this.dataSourceChecklist.sort = this.sort;
    this.dataSourceChecklist.paginator = this.paginator;
  }

  openChecklistForm(drillerChecklist: any) {
    let drillingChecklistData : DrillingChecklist =  new DrillingChecklist();

    if(drillerChecklist!==''){
      drillingChecklistData = drillerChecklist;
    }
    else{
      drillingChecklistData.drillingRequest.id = this.drillingRequestId;
    }

    const createDialogRef = this.dialog.open(DrillerChecklistCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: drillingChecklistData
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getAllDrillerChecklist();
    });
  }


  getAllDrillerChecklist() {
    this.drillerService.getAllDrillerChecklist()
      .subscribe(data => {
        this.drillingChecklists = data as DrillingChecklist[];
        this.dataSourceChecklist.data = data as DrillingChecklist[];
        this.loading = false;
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSourceChecklist.filter = filterValue.trim().toLowerCase();
  }

}
