import { Pipe, PipeTransform } from "@angular/core";
import { Company } from "../entity/applied-geoscience/company/company";
import { Person } from "../../_core/data/_models/people.model";

@Pipe({
  name: "personnelSortPipe",
})
export class PersonnelSortPipe implements PipeTransform {
  bgiPersonnel: Person[] = [];
  otherPersonnel: Person[] = [];
  bgiPersonnelLite: any[] = [];
  otherPersonnelLite: any[] = [];

  transform(companies: Company[], personnel: Person[]) {
    while (this.bgiPersonnel.length > 0 || this.otherPersonnel.length > 0) {
      if (this.bgiPersonnel.length > 0) this.bgiPersonnel.pop();
      if (this.otherPersonnel.length > 0) this.otherPersonnel.pop();
    }

    personnel.forEach((p) => {
      const companyTemp = companies.find((c) => c.id === p.company);

      if (companyTemp) {
        p["companyName"] = companyTemp.name;
        if (companyTemp.name === "Botswana Geoscience Institute") {
          this.bgiPersonnel.push(p);
        } else {
          this.otherPersonnel.push(p);
        }
      } else {
        this.otherPersonnel.push(p);
      }
    });

    this.bgiPersonnel.sort((a, b) => a.name.localeCompare(b.name));
    this.otherPersonnel.sort((a, b) => a.name.localeCompare(b.name));

    const finalArr = [...this.bgiPersonnel, ...this.otherPersonnel];

    return finalArr;
  }

  transformLite(companies: Company[], personnel: Array<Person>) {
    while (
      this.bgiPersonnelLite.length > 0 ||
      this.otherPersonnelLite.length > 0
    ) {
      if (this.bgiPersonnelLite.length > 0) this.bgiPersonnelLite.pop();
      if (this.otherPersonnelLite.length > 0) this.otherPersonnelLite.pop();
    }

    personnel.forEach((p) => {
      const companyTemp = companies.find((c) => c["id"] === p["company"]);

      if (companyTemp) {
        p["companyName"] = companyTemp["name"];
        if (companyTemp["name"] === "Botswana Geoscience Institute") {
          this.bgiPersonnelLite.push(p);
        } else {
          this.otherPersonnelLite.push(p);
        }
      } else {
        this.otherPersonnelLite.push(p);
      }
    });

    this.bgiPersonnelLite.sort((a, b) =>
      a["fullName"].localeCompare(b["fullName"])
    );
    this.otherPersonnelLite.sort((a, b) =>
      a["fullName"].localeCompare(b["fullName"])
    );

    const finalArr = [...this.bgiPersonnelLite, ...this.otherPersonnelLite];

    return finalArr;
  }
}
