import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';
import { HydrogeologyValidation } from '../../../hydrogeology.validation';
import { UserService } from '../../../../../../app/auth/user/user.service';
import { WaterPumpingTestTypeEnum } from '../../../../../shared-kernel/enumerations/water-pumping-test-type-enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { WaterPumpingTest } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTest';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WaterPumpingTestCalibration} from "../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestCalibration";
import {Unit} from "../../../../../shared-kernel/entity/common/unit";
import {UnitEnum} from "../../../../../shared-kernel/enumerations/unit-enum";
declare var $: any;

@Component({
  selector: 'app-water-pump-test-calibration-create',
  templateUrl: './water-pump-test-calibration-create.component.html',
  styleUrls: ['./water-pump-test-calibration-create.component.scss']
})
export class WaterPumpTestCalibrationCreateComponent implements OnInit {

  waterPumpingTestCalibrationCreateForm: FormGroup;
  title: string = 'Water Calibration Create';
  waterPumpingTestCalibration: WaterPumpingTestCalibration = new WaterPumpingTestCalibration();
  waterPumpingTestCalibrations: WaterPumpingTestCalibration[] = [];
  url: string;
  boreholeId: any;
  @Input('currentState') currentState: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private hydrogeologyValidation: HydrogeologyValidation,
    private userService: UserService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute,
    @Optional() public dialogRef: MatDialogRef<WaterPumpTestCalibrationCreateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any ) {}

  ngOnInit() {
    this.initForms();
  }

  initForms() {
    if (this.data.waterPumpTestCalibration) {

      this.waterPumpingTestCalibration = this.data.waterPumpTestCalibration;
    }
    this.waterPumpingTestCalibrationCreateForm = this.formBuilder.group({
      stepNumber: [this.waterPumpingTestCalibration ? this.waterPumpingTestCalibration.stepNumber : null, Validators.required],
      noOfLogs: [this.waterPumpingTestCalibration ? this.waterPumpingTestCalibration.noOfLogs : ''],
      depthToWater: [this.waterPumpingTestCalibration ? this.waterPumpingTestCalibration.depthToWater.measure : null],
      drawdown: [this.waterPumpingTestCalibration ? this.waterPumpingTestCalibration.drawdown.measure : null],
      pumpingRate: [this.waterPumpingTestCalibration ? this.waterPumpingTestCalibration.pumpingRate.measure : null],
    });
  }

  closeModal(){
    this.dialogRef.close(this.waterPumpingTestCalibration);
  }

  onClose(actions: any) {
    this.dialogRef.close(actions);
  }

  onSubmit() {

    this.waterPumpingTestCalibration.stepNumber = this.waterPumpingTestCalibrationCreateForm.controls.stepNumber.value;
    this.waterPumpingTestCalibration.noOfLogs = this.waterPumpingTestCalibrationCreateForm.controls.noOfLogs.value;
    this.waterPumpingTestCalibration.depthToWater.unit, this.waterPumpingTestCalibration.drawdown.unit, this.waterPumpingTestCalibration.pumpingRate.unit = new Unit();
    this.waterPumpingTestCalibration.depthToWater.unit.id = UnitEnum.meter;
    this.waterPumpingTestCalibration.drawdown.unit.id = UnitEnum.meter;
    this.waterPumpingTestCalibration.pumpingRate.unit.id = UnitEnum.pumpRate;
    this.waterPumpingTestCalibration.depthToWater.measure = this.waterPumpingTestCalibrationCreateForm.controls.depthToWater.value;
    this.waterPumpingTestCalibration.drawdown.measure = this.waterPumpingTestCalibrationCreateForm.controls.drawdown.value;
    this.waterPumpingTestCalibration.pumpingRate.measure = this.waterPumpingTestCalibrationCreateForm.controls.pumpingRate.value;
    this.waterPumpingTestCalibration.borehole = this.data.entity;
    this.spinner.show();
    this.waterPumpingTestCalibrations.push(this.waterPumpingTestCalibration);

    this.hydrogeologyService.createWaterPumpingTestCalibration(this.waterPumpingTestCalibrations).subscribe(
      value => {
        this.spinner.hide();
      }
      , error => {
        if(this.title === 'Water Pumping Test Create') {
          this.toastr.error(error, 'Water Pumping Test Create');
        } else {
          this.toastr.error(error, 'Update Water Pumping Test');
        }
      }
      , () => {
        this.closeModal();
        if (this.title === 'Water Pumping Test Create') {
          this.toastr.success('Water Pumping test Created Successfully', 'Water Pumping Test Create');
        } else {
          this.toastr.success('Water Pumping test Updated Successfully', 'Update Water Pumping Test');
        }
      }
    );

  }
}
