import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DCPTestService} from '../../dynamic-cone-penetrometer.service';
import {DcpTestFoundation} from '../dcp-test-foundation-create/DcpTestFoundation';
import {DcpTestImage} from '../../../../../shared-kernel/entity/common/DcpTestImage';
import {Person} from '../../../../../common/entity/security/profile/person';
import {UserService} from '../../../../../auth/user/user.service';
import { ApproveAction } from 'src/app/applied-geoscience/models';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ApprovalService } from '../../../borehole/services/approval.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { UploadedFilesService } from 'src/app/common/uploaded-files/uploaded-files.service';
import { FileToUpload } from 'src/app/common/uploaded-files/entity/file';
import { DcpTestFoundationCreateComponent } from '../dcp-test-foundation-create/dcp-test-foundation-create.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { Lightbox } from 'ngx-lightbox';
import { CompanyEnum } from '../../../../../shared-kernel/enumerations/company-enum';
import {StorageConstants} from "../../../../../_core/storage.constants";
import {LocalAuthorities} from "../../../../../_auth/_providers";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {Roles} from "../../../../../shared-kernel/entity/Roles";
import {BusinessUnitEnum} from '../../../../../shared-kernel/enumerations/business-unit-enum';

@Component({
  selector: 'app-dcp-test-foundation-detail',
  templateUrl: './dcp-test-foundation-detail.component.html',
  styleUrls: ['./dcp-test-foundation-detail.component.scss']
})
export class DcpTestFoundationDetailComponent implements OnInit {
  selectedSection: string | null = null;
  testId: string;
  dcpFoundationTest: DcpTestFoundation = new DcpTestFoundation();
  testImages: Array<DcpTestImage>;
  projectCoordinator: Person = new Person();
  projectManager: Person = new Person();
  projectGeologist: Person = new Person();
  currentComment: string;
  isBGIproject: boolean;
  companyEnum: typeof CompanyEnum = CompanyEnum;
  boreholeType: string = 'Geotechnical';
  totalNumberOfFiles = 0;
  uploadedFileList: FileToUpload[];
  loading = true;
  album: any = [];
  currentPerson: any;
  currentUser: any;
  isAdminVar: boolean;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;

  constructor(
    private route: ActivatedRoute,
    private dcpTestService: DCPTestService,
    private router: Router,
    private userService: UserService,
    private toast: ToastrService,
    private _approvalServ: ApprovalService,
    private spinner: NgxSpinnerService,
    private fileUploadService: UploadedFilesService,
    private dialog: MatDialog,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
    private _toastr: ToastrService,
    private lightbox: Lightbox) {
    this.route.params.subscribe(params => {
      this.testId = params.id;
    });
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
    }, err => {
      this._toastr.warning(err.message, "Current User Error");
    },() => {

    });

    this.getDcpFoundationTestById(this.testId);
    this.getAllFilesByKey(this.testId);
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  close(): void {
    this.lightbox.close();
  }

  editDcpFoundation(editDcpTestFoundation: DcpTestFoundation) {
    const newDCPDialogRef = this.dialog.open(DcpTestFoundationCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: editDcpTestFoundation
    });
    newDCPDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getDcpFoundationTestById(this.testId);
      }
    });
  }

  getAllFilesByKey(testId: string) {
    this.fileUploadService.getAllFilesByKey(testId).subscribe(response => {
      this.uploadedFileList = response as FileToUpload[];
      this.totalNumberOfFiles = this.uploadedFileList.length;
      this.loading = false;
    },
      error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  getDcpFoundationTestById(id: string) {
    this.dcpTestService.getAllDCPFoundationTestById(id).subscribe(data => {
      this.dcpFoundationTest = data as DcpTestFoundation;
      this.isBGIproject = this.dcpFoundationTest.dynamicConePenetrometerTest.project.company.name === this.companyEnum.BGI_NAME;

      for (let image of this.dcpFoundationTest.testImageList) {
        let albumImage: any = {};
        albumImage.src = 'data:image/jpg;base64,'+ image.image;
        albumImage.caption = image.name;
        albumImage.thumb = 'data:image/jpg;base64,'+ image.image;
        this.album.push(albumImage);
      }

      this.dcpFoundationTest.testImageList.forEach(value => {
      });
      this.testImages = this.dcpFoundationTest.testImageList;

    }, error => {
    }, () => {
      this.getPersonById(this.dcpFoundationTest.dynamicConePenetrometerTest.coordinator, 'projectCoordinator');
      this.getPersonById(this.dcpFoundationTest.dynamicConePenetrometerTest.manager, 'projectManager');
      this.getPersonById(this.dcpFoundationTest.dynamicConePenetrometerTest.geologist, 'projectGeologist');
    });
  }

  navBack() {
    this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/list']);
  }

  getPersonById(id: string, personDesignation: string) {
    this.userService.getPersonById(id).subscribe(data => {

      if (personDesignation === 'projectCoordinator') {
        this.projectCoordinator = data as Person;
      }
      if (personDesignation === 'projectManager') {
        this.projectManager = data as Person;
      }
      if (personDesignation === 'projectGeologist') {
        this.projectGeologist = data as Person;
      }
    });
  }

  redicrectToDocuments() {
    this.selectedSection = 'documents';
  }

  changeState(action: ApproveAction) {
    let successMessage = 'Dcp Foundation submitted successfully';
    let title = 'Dcp Foundation Submit';

    switch (+action) {
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'Dcp Foundation submitted successfully';
        title = 'Dcp Foundation Submit';
        break;
      case ApproveAction.QA_APPROVE:
        successMessage = 'Dcp Foundation approved successfully';
        title = 'Dcp Foundation Approve';
        break;
      case ApproveAction.QA_REJECT:
        successMessage = 'Dcp Foundation rejected successfully';
        title = 'Dcp Foundation Reject';
        break;
      case ApproveAction.MANAGER_APPROVE:
        successMessage = 'Dcp Foundation approved successfully';
        title = 'Dcp Foundation Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        successMessage = 'Dcp Foundation rejected successfully';
        title = 'Dcp Foundation Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        successMessage = 'Dcp Foundation published successfully';
        title = 'Dcp Foundation Published';
        break;
      default:
        throw new Error('Unknown change state');
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this.spinner.show();
        this._approvalServ.changeDcpFoundationState(this.testId, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toast.success(successMessage, title);
          this.getDcpFoundationTestById(this.testId);
        }, err => {
          this.spinner.hide();
          this.toast.warning(err.message, 'Failed');
        }, () => {
          this.spinner.hide();
        });
      }
    });

  }

  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
      if (res) {
        this.currentPerson = res;
        this.currentPerson['user'] = this.currentUser;
        this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    },() => {
      this.isAdminNew(this.currentPerson);
    });
  }
  isAdminNew(current) {
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {
        if (value.roles.find(x => x.name == Roles.SYSTEM_ADMIN)){
          this.isAdminVar = true;
        }
        else {
          this.isAdminVar = false;
        }
      }
    )
  }
}
