import { Size } from '../common/size';

export class CoreTrays {
    id: string;
    bq: Number;
    hq: Number;
    hq2: Number;
    waterTankQuantity: Number;
    waterTankVolume: Size = new Size();
    description: String;
    fuelTankQuantity: Number;
    fuelTankVolume: Size = new Size();
 }
