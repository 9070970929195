import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CheckoutHistory } from '../../../collections/entity/CheckoutHistory';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-collections-tray-history',
  templateUrl: './collections-tray-history.component.html',
  styleUrls: ['./collections-tray-history.component.scss']
})
export class CollectionsTrayHistoryComponent implements OnInit {

  public _dataSource: MatTableDataSource<CheckoutHistory> = new MatTableDataSource<CheckoutHistory>();
  displayedColumns: Array<string> = [ 'number', 'date','actionTaken', 'personnel',
    'reason'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  @Input('activitiesDataSource') set dataSource(val: MatTableDataSource<CheckoutHistory>) {
    this._dataSource = val;
    if (this._dataSource) {
      this.loading = false;
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
    }
  }

  loading = true;
  constructor() { }

  ngOnInit() {
  }

  get dataSource(): MatTableDataSource<CheckoutHistory> {
    this.loading = false;
    return this._dataSource;
  }


  openMyActivityDetails(activity){

  }
}
