export enum TrayHistory {
    PENDING_SENIOR_ARCHIVIST_SUBMISSION = '0',
    SUBMITTED_TO_SENIOR_ARCHIVIST = '1',
    REJECTED_BY_SENIOR_ARCHIVIST = '2',
    APPROVED = '3',
    PENDING_MANAGER_SUBMISSION = '4',
    SUBMITTED_TO_MANAGER = '5',
    REJECTED_BY_MANAGER = '6',
    APPROVED_MANAGER = '7',
    PENDING_SPECIMEN_MANAGER_SUBMISSION = '8'
  }