import {Component, OnInit} from '@angular/core';
import {AdminMenuItem} from './admin-menu';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {LoginService} from '../auth/login/login.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import {Session} from '../shared-kernel/services/usermanager/session';
import {Router} from '@angular/router';
import {LocalStorageManagerService} from '../shared-kernel/storage/local-storagemanager.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../_auth/_providers';
import { StorageConstants } from '../_core/storage.constants';

@Component({
  selector: 'app-admin',
  templateUrl: '../app-nav/app-nav.component.html',
  styleUrls: ['../app-nav/app-nav.component.scss']
})
export class AdminComponent implements OnInit {

  menuInfo: any = AdminMenuItem;
  localUserSession: Session;
  systemHeader = 'National Integrated Geoscience Information System';
  page = 'portal';
  settings = 'home';

  title = 'NIGIS';

  expanded = false;
  currentUser: any;
  currentPerson: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: LoginService,
    public userSession: Session,
    private storageManager: LocalStorageManagerService,
    private router: Router,
    private _localStorage: LocalStorage,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities
  ) {
    this.localUserSession = userSession;
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson();
    }, err => {
        this._toastr.warning(err.message, "Current User Error");
    });
  }

  onLogout() {
    Swal.fire(<SweetAlertOptions>{
        title: 'Logout',
        type: 'warning',
        html: 'Are you sure you want to <b>logout</b>?',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, logout!'
    }).then(result => {
        if (result.value) {
            Swal.fire(
              'Logged Out!',
              'Your have been logged out.',
              'success'
            );
            this.router.navigate(['auth']);
        }
    });
  }

  onLockSession() {
      //
  }

  setExpanded(bool : boolean){
    this.expanded = bool;
  }

  onLogOut() {
    Swal.fire({
      title: 'Are you sure ?',
      type: 'warning',
      confirmButtonText: 'Submit',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this.authService.attemptLogOut().subscribe(
          (response: any) => {
            if (this.storageManager.tokenManagerRemove() === true) {
              this.router.navigate(['login']);
            } else {
              Swal.fire({
                title: 'Logout Attempt',
                text: 'Logout failed please try again',
                type: 'warning',
                confirmButtonText: 'Ok'
              });
              this.router.navigate(['portal']);
            }
          });
      }else{
      }
    });
  }

  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    });
  }

}
