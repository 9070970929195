import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { State } from './../../../activities/models/state';
import { ToastrService } from 'ngx-toastr';
import { ActivitiesService } from './../../../activities/services/activities.service';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-unapproved-activities-list',
  templateUrl: './unapproved-activities-list.component.html',
  styleUrls: ['./unapproved-activities-list.component.scss']
})
export class UnapprovedActivitiesListComponent implements OnInit {

  displayedColumns: Array<string> = ['number', 'activityType', 'activityNumber', 'location', 'createdDate', 'activityDate', 'status', 'view'];

  public _dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @Input('projectId') projectId: string = '';

  state = new State();

  loading = true;

  constructor(
    private _activitiesServ: ActivitiesService,
    private _toaster: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getUnapprovedProjectActivityByProjectId();
  }

  private getUnapprovedProjectActivityByProjectId() {
    this.spinner.show();
    this._activitiesServ.getUnapprovedProjectActivityByProjectId(this.projectId).subscribe(res => {
      const sorted = res.sort((a, b) => (new Date(a.createdDate)).getTime() - (new Date(b.createdDate).getTime()));
      this._dataSource = new MatTableDataSource<any>(sorted);
      this.spinner.hide();
      this.loading = false;
    }, err => {
      this._toaster.warning(err.message, "Activities Fail");
    });
  }

  applyFilter(filterValue: string) {
      this._dataSource.filter = filterValue.trim().toLowerCase();
  }

  openMyActivityDetails(activity: any) {
    if (activity.activityType === 'DCP Foundation') {
      const urlParameters = {id: activity.id};
      this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/detail', urlParameters]);
    } else if (activity.activityType === 'Infiltration Test') {
      const urlParameters = { id: activity.id};
      this.router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', urlParameters]);
    } else if (activity.activityType === 'DCP Roadworks') {
      const urlParameters = {id: activity.id};
      this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/detail', urlParameters]);
    } else if (activity.activityType === 'Trial Pit') {
      const urlParameters = { id: activity.id};
      this.router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', urlParameters]);
    } else {
      if (activity.activityType === 'Water Borehole') {
        const urlParameters = {id: activity.id, type: 'Water'};
        this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', urlParameters]);
      } else {
        const urlParameters = { id: activity.id, type: 'BGI' };
        this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/details', urlParameters]);
      }

    }

  }

}
