import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-output-data-list',
  templateUrl: './output-data-list.component.html',
  styleUrls: ['./output-data-list.component.scss']
})
export class OutputDataListComponent implements OnInit {

  tabIndex: number = 0;
  fileTypeId: string;
  filter: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      this.fileTypeId = params.id;
      this.filter = params.filter;
    });
  }

  ngOnInit() {
    if (this.fileTypeId){
      this.tabIndex = 1;
    }
  }

}
