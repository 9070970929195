import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Person } from 'src/app/_core/data/_models/people.model';

@Injectable({
  providedIn: 'root'
})
export class AppUsersService implements OnInit {
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  registerUser(user) {
    this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.registerUrl, user);
  }

  /*setUserPassword(username, oldPassword, password) {
    return this.http.post(ServiceEndPoints.authBaseUrl + ServiceEndPoints.setUserPasswordUrl + '/' + username + '/' + oldPassword + '/' + password);
  }*/

  getAllTitles() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllTitlesUrl);
  }

  getAllCountries() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllCountriesUrl);
  }

  getAllIdTypes() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllIdTypesUrl);
  }

  getAllSexes() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllSexesUrl);
  }

  getAllDistricts() {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllDistrictsUrl);
  }

  getPersons(): Observable<Array<Person>> {
    return this.http.get<Array<Person>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.personsUrl);
  }

  getPersonById(person: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonByIdUrl + '/' + person);
  }

  getPersonByUserId(person: any) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonByUserIdUrl + '/' + person);
  }
}

