import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Company} from '../../shared-kernel/entity/applied-geoscience/company/company';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  getAllCompanies() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCompaniesUrl);
  }

  getAllPaged(pageNumber: number, pageSize: number): Observable<Company> {
    return this.http.get<Company>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/ags-common/v1/companies/paged?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  pagedCompaniesSearch(criteria: string, pageNumber: number, pageSize: number): Observable<Company> {
    return this.http.get<Company>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/ags-common/v1/companies/search/paged?pageNumber=${pageNumber}&pageSize=${pageSize}&criteria=${criteria}`);
  }

  searchCompanies(criteria?: string): Observable<Company[]> {
    return this.http.get<Company[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}${ServiceEndPoints.searchCompaniesUrl}?criteria=${criteria ? criteria : ''}`);
  }

  getAllCompaniesLite() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCompaniesLiteUrl);
  }


  getAllCompanyPersonnel(companyId: string) {
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.companyPersonnelUrl + `/${companyId}/people`);
  }

  getAllCompanyTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCompanyTypesUrl);
  }

  getAllCompanyCategories() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCompanyCategoriesUrl);
  }

  getAllContactDetailTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllContactDetailTypesUrl);
  }

  getAllCountries() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCountriesAGSUrl);
  }

  getAllDistricts() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDistrictsAGSUrl);
  }

  getCompanyById(companyId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCompanyByIdUrl + '/' + companyId);
  }

  getCompanyByName(name: string): Observable<Company> {
    return this.http.get<Company>(`${ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCompanyByIdUrl}/${name}/all`);
  }

  createCompany(company) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createCompanyUrl, company);
  }

  getAllCompaniesCount() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/ags-common/v1/companies/count');
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }
}
