import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {Breakpoints, BreakpointObserver} from '@angular/cdk/layout';
import {Honorific} from 'src/app/common/entity/security/profile/honorific';
import {Countries} from 'src/app/common/entity/security/profile/countries';
import {IdType} from 'src/app/common/entity/security/profile/id-type';
import {Sex} from 'src/app/common/entity/security/profile/sex';
import {District} from 'src/app/common/entity/security/util/common/district';
import {SystemRole} from 'src/app/common/entity/security/role/system-role';
import {Person} from 'src/app/common/entity/security/profile/person';
import {Kin} from 'src/app/common/entity/security/profile/kin';
import {ContactDetailType} from 'src/app/common/entity/security/util/common/contact-detail-type';
import {PostalAddress} from 'src/app/common/entity/security/util/common/postal-address';
import {PhysicalAddress} from 'src/app/common/entity/security/util/common/physical-address';
import {User} from 'src/app/common/entity/security/user/user';
import {ContactDetail} from 'src/app/common/entity/security/util/common/contact-detail';
import {UserService} from '../user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RoleService} from '../../roles/roles.service';
import {UrlSegment, UrlTree, Router, PRIMARY_OUTLET} from '@angular/router';
import {routerNgProbeToken} from '@angular/router/src/router_module';
import {UserValidation} from '../user.validations';
import {ToastrService} from 'ngx-toastr';
import {IdTypeEnum} from 'src/app/shared-kernel/enumerations/id-type-enum';
import {DcpTestFoundation} from '../../../applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-create/DcpTestFoundation';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  subtitle = 'Register User';
  public title: Honorific;
  public titles: Honorific[];
  public country: Countries;
  public countries: Countries[];
  public postalAddressCountry: Countries;
  public physicalAddressCountry: Countries;
  public postalAddressDistrict: District;
  public physicalAddressDistrict: District;
  public idType: IdType;
  public idTypes: IdType[];
  public sex: Sex;
  public sexes: Sex[];
  public district: District;
  public districts: District[];
  public role: SystemRole;
  public roles: SystemRole[];
  public person: Person;
  public kin: Kin;
  public postalAddress: PostalAddress;
  public physicalAddress: PhysicalAddress;
  public contactDetailsType: ContactDetailType;
  public user: User;
  public email: string;
  public telephoneNumber: string;
  public contactDetails: ContactDetail;
  public selectedRoles: SystemRole[];
  public url: string;
  public personId: {};
  idTypeEnum: typeof IdTypeEnum = IdTypeEnum;

  public contactDetailsTypeEmail = new ContactDetailType();
  public contactDetailsTypeTelephone = new ContactDetailType();
  panelOpenState: boolean = false;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private router: Router,
    private validate: UserValidation,
    private toastr: ToastrService) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[2].parameters;
    this.personId = urlData;
  }

  validation_messages = this.validate.validation_messages;

  idNumberTitle = 'ID Number';
  validIdLength: boolean;
  validIdGender: boolean;
  genderSelected: boolean;
  idTypeSelected: boolean;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  getErrorMessage() {
    return this.emailFormControl.hasError('required') ? 'You must enter a value' :
      this.emailFormControl.hasError('emailFormControl') ? 'Not a valid email' :
        '';
  }

  ngOnInit() {
    this.getRoles();
    this.getTitles();
    this.getCountries();
    this.getIdTypes();
    this.getSexes();
    this.getDistricts();
    this.getPersonById();
    this.person = new Person;
    this.country = new Countries();
    this.idType = new IdType();
    this.sex = new Sex();
    this.user = new User();
    this.title = new Honorific();
    this.postalAddress = new PostalAddress();
    this.postalAddressCountry = new Countries();
    this.postalAddressDistrict = new District();
    this.kin = new Kin();
    this.physicalAddress = new PhysicalAddress();
    this.physicalAddressCountry = new Countries();
    this.physicalAddressDistrict = new District();
    this.role = new SystemRole();
    this.validIdLength = true;
    this.validIdGender = true;
    this.genderSelected = true;
    this.idTypeSelected = true;
  }

  onSubmit() {
    let personDetails: Person;
    personDetails = new Person();
    const contactdetailsList: Array<ContactDetail> = [];
    this.contactDetailsTypeEmail = {id: '1', name: null};
    this.contactDetailsTypeTelephone = {id: '2', name: null};

    if (this.email) {
      contactdetailsList.push({
        id: null,
        address: this.email,
        contactDetailType: this.contactDetailsTypeEmail
      });
    }
    if (this.telephoneNumber) {
      contactdetailsList.push({
        id: null,
        address: this.telephoneNumber,
        contactDetailType: this.contactDetailsTypeTelephone
      });
    }

    this.user = {
      id: this.user.id,
      password: this.user.password,
      username: this.email,
      accountNonExpired: this.user.accountNonExpired,
      accountNonLocked: this.user.accountNonLocked,
      credentialsNonExpired: this.user.credentialsNonExpired,
      lastPasswordReset: this.user.lastPasswordReset,
      enabled: this.user.enabled,
      roles: this.selectedRoles,
      businessUnit: null
    };

    this.physicalAddress = {
      id: this.physicalAddress.id,
      plotNumber: this.physicalAddress.plotNumber,
      streetName: this.physicalAddress.streetName,
      cityTown: this.physicalAddress.cityTown,
      district: this.physicalAddressDistrict,
      country: this.physicalAddressCountry
    };

    this.postalAddress = {
      id: this.postalAddress.id,
      boxNumber: this.postalAddress.boxNumber,
      cityTown: this.postalAddress.cityTown,
      country: this.postalAddressCountry,
      district: this.postalAddressDistrict
    };

    personDetails.id = this.person.id;
    personDetails.idType = this.idType;
    personDetails.idNumber = this.person.idNumber;
    personDetails.dateOfBirth = this.person.dateOfBirth;
    personDetails.name = this.person.name;
    personDetails.surname = this.person.surname;
    personDetails.workIdNumber = this.person.workIdNumber;
    personDetails.user = this.user;
    personDetails.contactDetails = contactdetailsList;
    personDetails.sex = this.sex;
    personDetails.countries = this.country;

    if (this.kin.name) {
      personDetails.kin = this.kin;
    } else {
      personDetails.kin = null;
    }

    if (this.physicalAddress.plotNumber || this.physicalAddress.streetName || this.physicalAddress.cityTown) {
      personDetails.physicalAddress = this.physicalAddress;
    } else {
      personDetails.physicalAddress = null;
    }

    if (this.postalAddress.boxNumber || this.postalAddress.cityTown) {
      personDetails.postalAddress = this.postalAddress;
    } else {
      personDetails.postalAddress = null;
    }

    personDetails.profession = this.person.profession;
    personDetails.title = this.title;

    if (this.emailFormControl.hasError('email')) {
      this.errorsmsg('Please make sure provided usermane is a valid email address');
    } else {
      if (this.validateIdNumberOnSubmit()) {
        this.userService.registerUser(personDetails);
      }
    }
  }

  resetModal() {
    $('#registerUser').modal('hide');
  }

  getRoles() {
    this.roleService.getAllRoles()
      .subscribe(data => {
        this.roles = data as SystemRole[];
      });
  }

  getTitles() {
    this.userService.getAllTitles()
      .subscribe(data => {
        console.log('we are inside title and titles are' + data);
        this.titles = data as Honorific[];
      });
  }

  getCountries() {
    this.userService.getAllCountries()
      .subscribe(data => {
        this.countries = data as Countries[];
      });
  }

  getIdTypes() {
    this.userService.getAllIdTypes()
      .subscribe(data => {
        this.idTypes = data as IdType[];
      });
  }

  getSexes() {
    this.userService.getAllSexes()
      .subscribe(data => {
        this.sexes = data as Sex[];
      });
  }

  getDistricts() {
    this.userService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
      });
  }

  getPersonById() {
    let personId = {id: null};
    personId = this.personId as Person;
    if (personId.id) {
      this.subtitle = 'Edit User';
      this.userService.getPersonById(personId.id)
        .subscribe(data => {
          const personData = data as Person;
          this.person = new Person();
          this.person = personData;
          this.title = this.person.title;
          this.country = this.person.countries;
          this.idType = this.person.idType;
          this.sex = this.person.sex;
          this.user = this.person.user;
          this.email = this.user.username;
          this.selectedRoles = this.user.roles;

          if (this.person.kin) {
            this.kin = this.person.kin;
          }
          if (this.person.postalAddress) {
            this.postalAddress = this.person.postalAddress;
            this.postalAddressCountry = this.postalAddress.country;
            this.postalAddressDistrict = this.postalAddress.district;
          }
          if (this.person.physicalAddress) {
            this.physicalAddress = this.person.physicalAddress;
            this.physicalAddressCountry = this.physicalAddress.country;
            this.physicalAddressDistrict = this.physicalAddress.district;
          }
          if (this.person.contactDetails) {
            for (const key in this.person.contactDetails) {
              const id = this.person.contactDetails[key].contactDetailType.id;
              const address = this.person.contactDetails[key].address;
              if (id === '1') {
                this.email = address;
              }
              if (id === '2') {
                this.telephoneNumber = address;
              }
            }
          }
        });
    }
  }

  changeIdType() {
    this.genderSelected = true;
    this.validIdGender = true;
    this.validIdLength = true;
    this.person.idNumber = '';
    if (this.idType.id === this.idTypeEnum.Omang) {
      this.idNumberTitle = 'Omang Number';
    }
    if (this.idType.id === this.idTypeEnum.BirthCertificate) {
      this.idNumberTitle = 'Birth Certificate Number';
    }
    if (this.idType.id === this.idTypeEnum.Passport) {
      this.idNumberTitle = 'Passport Number';
    }
  }

  onGenderTypeChange() {
    this.genderSelected = true;
    if (this.person.idNumber !== null) {
      this.validateIdNumberOnTyping();
    }
  }

  validateIdNumberOnTyping() {
    if (this.idType.id !== null) {
      this.idTypeSelected = true;
      if ((this.idType.id === this.idTypeEnum.Omang) || (this.idType.id === this.idTypeEnum.BirthCertificate)) {
        if (this.validate.idNumberValidLength(this.person.idNumber, this.idType)) {
          this.validIdLength = true;
        } else {
          this.validIdLength = false;
        }
        if (this.sex.id !== null) {
          this.genderSelected = true;
          if (this.validate.idNumberValidGender(this.person.idNumber, this.idType, this.sex)) {
            this.validIdGender = true;
          } else {
            this.validIdGender = false;
          }
        } else {
          this.genderSelected = false;
        }
      } else if (this.idType.id === this.idTypeEnum.Passport) {
        this.validIdLength = true;
        if (this.sex.id !== null) {
          this.genderSelected = true;
          this.validIdGender = true;
        } else {
          this.genderSelected = false;
        }
      }
    } else {
      this.idTypeSelected = true;
    }
  }

  validateIdNumberOnSubmit(): boolean {
    if (this.validIdLength === false) {
      this.validate.errorsmsg('Omang/ Birth certificate number should be 9 digits');
      return false;
    }
    if (this.validIdGender === false) {
      this.validate.errorsmsg('Please make sure provided Omang/ Birth certificate number corresponds with selected gender');
      return false;
    }
    return true;
  }

  errorsmsg(message) {
    this.toastr.error(message);
  }
}
