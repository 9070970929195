import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../auth/user/user.service';
import { BusinessUnit } from '../../_settings/_models/business.models';
import { BusinessUnitEditComponent } from '../../_settings/users/business-unit/business-unit-edit/business-unit-edit.component';

@Injectable({
    providedIn: 'root'
})
export class UserBusinessUnits {

  businessUnit: BusinessUnit;
    constructor(
      private http: HttpClient,private userService :UserService
    ){}


}
