import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from "./../../../environments/environment";
import { CurrentPrincipal } from '../models/current-principal.model';

@Injectable({
    providedIn: 'root'
})
export class LocalAuthorities {

    constructor(
        private _http: HttpClient
    ) { }

    public getFullUser(username: string): Observable<CurrentPrincipal> {
        return this._http.get<CurrentPrincipal>(environment.authBaseUrl + `/api/user/v1/dto/user/${username}`);
    }

    public userExists(): Observable<boolean> {
        return this._http.get<boolean>(environment.authBaseUrl + `/api/user/v1/first-user/exist`);
    }

    public getPersonDetails(id: string): Observable<any> {
        return this._http.get<any>(environment.authBaseUrl + `/api/profile/v1/person/user/${id}`);
    }

    public getPersonByUsername(username: string): Observable<any> {
        return this._http.get<any>(environment.authBaseUrl + `/api/profile/v1/person/username/${username}`);
    }
}