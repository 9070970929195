import {Component, OnInit, ViewChild} from '@angular/core';
import {SystemRole} from '../../../common/entity/security/role/system-role';
import {Department} from '../../../common/entity/security/role/department';
import {BusinessUnit} from '../../../common/entity/security/role/business-unit';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {RoleService} from '../roles.service';
import {FormControl} from '@angular/forms';
import {Authority} from 'src/app/common/entity/security/authority/authority';
import {UrlSegment, UrlTree, Router, PRIMARY_OUTLET} from '@angular/router';

export interface DialogData {
  animal: string;
  name: string;
}

declare var $: any;

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})

export class CreateRolesComponent implements OnInit {

  public role = new SystemRole();
  public retrievedRole = {};
  public department = new Department();
  public businessUnit = new BusinessUnit();
  public roles: string[];
  public authorities: Authority[];
  public departments: Department[];
  public businessUnits: BusinessUnit[];
  public selectedAuthorities: Authority[];
  title = 'Roles';


  url: string;
  roleId: {};

  constructor(private router: Router, private roleService: RoleService) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[2].parameters;
    this.roleId = urlData;
  }

  displayedColumns: string[] = ['No', 'Name', 'Description', 'Action'];
  dataSource = new MatTableDataSource();
  authorityControl = new FormControl();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getRoles();
    this.getAuthorities();
    this.getDepartments();
  }

  showModal(): void {
    $('#myModal').modal('show');
  }

  hideModal(): void {
    document.getElementById('close-modal').click();
  }

  getRoles() {
    this.roleService.getAllRoles()
      .subscribe(data => {
        this.roles = data as string[];
        this.dataSource = new MatTableDataSource(this.roles);
      });
  }

  getAuthorities() {
    this.roleService.getAllAuthorities()
      .subscribe(data => {
        this.authorities = data as SystemRole[];
      });
  }

  getDepartments() {
    this.roleService.getAllDepartments()
      .subscribe(data => {
        this.departments = data as Department[];
      });
  }

  selectDepartment(department: any) {
    this.roleService.getAllBusinessUnitsByDepartment(department)
      .subscribe(data => {
        this.businessUnits = data as BusinessUnit[];
      });
  }

  onSubmit(): void {

    let authoritiesToSubmit: any[];
    authoritiesToSubmit = [];
    for (const key in this.selectedAuthorities) {
      const data = this.selectedAuthorities[key];
      authoritiesToSubmit.push({id: data, name: null});
    }

    this.businessUnit = {
      id: this.businessUnit.id,
      name: this.businessUnit.name,
      department: null,
      description: null
    };
    this.role = {
      id: this.role.id,
      name: this.role.name,
      description: this.role.description,
      authorities: authoritiesToSubmit,
      businessUnit: this.businessUnit
    };

    this.roleService.createrNewRole(this.role);

    this.role = new SystemRole();
    this.hideModal();

  }

  getRolesById(role) {
    this.roleService.getRoleById(role)
      .subscribe(data => {
        const roleData = data as SystemRole;
        this.role = new SystemRole();
        this.role.id = roleData.id;
        this.role.name = roleData.name;
        this.role.authorities = roleData.authorities;
        this.role.businessUnit = roleData.businessUnit;
        this.role.description = roleData.description;
        this.selectedAuthorities = this.role.authorities;
        this.department = this.role.businessUnit.department;
        this.selectDepartment(this.department.id);
        this.businessUnit = this.role.businessUnit;
        this.businessUnit.id = this.role.businessUnit.id;
      });
  }

  editRole(role: any) {
    let roleIdObj = {id: null};
    roleIdObj = this.roleId as SystemRole;
    this.getRolesById(roleIdObj.id);
    this.getRolesById(role);
    this.showModal();
  }
}


