import {Component, Inject, OnInit} from '@angular/core';
import {Borehole} from '../../../shared-kernel/entity/common/borehole';
import {UnitEnum} from '../../../shared-kernel/enumerations/unit-enum';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatTableDataSource} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PenetrationRateLog} from '../entity/penetrationRate';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CoreService} from '../../../applied-geoscience/geo-tech/core/core.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PenetrationRateService} from '../service/penetration-rate.service';

@Component({
  selector: 'app-penetration-rate-create',
  templateUrl: './penetration-rate-create.component.html',
  styleUrls: ['./penetration-rate-create.component.scss']
})
export class PenetrationRateCreateComponent implements OnInit {

  borehole: Borehole;
  penetrationRateLog: PenetrationRateLog;
  penetrationRateLogs: PenetrationRateLog[];
  depth = 0;
  boreholeId: string;
  enumUnits: typeof UnitEnum = UnitEnum;
  penetrationRateLogRecords: Array<PenetrationRateLog>;
  loading = true;
  dataSource: MatTableDataSource<PenetrationRateLog> = new MatTableDataSource();

  dialogConfig = new MatDialogConfig();

  penetrationRateLogFormGroup: FormGroup = new FormGroup({
    id: new FormControl(''),
    depthFrom: new FormControl(null, [Validators.required, Validators.min(0)]),
    depthTo: new FormControl(null, [Validators.required, Validators.min(0)]),
    minutes: new FormControl('', [Validators.required, Validators.min(0)]),
    time: new FormControl('',),
    seconds: new FormControl('', [Validators.required, Validators.min(0)]),
    penetrationRate: new FormControl('', [Validators.required, Validators.min(0)]),
    borehole: new FormControl(null)
  });

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<PenetrationRateCreateComponent>,
              private router: Router,
              private toaster: ToastrService, private route: ActivatedRoute, private coreService: CoreService,
              private penetrationRateLogService: PenetrationRateService, private dialog: MatDialog,
              private spinner: NgxSpinnerService) {
    this.route.params.subscribe(params => {
      this.boreholeId = params.id;
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.borehole = this.data.borehole;
    this.penetrationRateLog = this.data.penetrationRateLog;

    this.penetrationRateLogFormGroup.get('depthFrom').setValue(0);

    if (this.penetrationRateLog) {
      this.populateForm();
    }

    if (this.borehole.penetrationRateLogs && !this.penetrationRateLog) {
      const previous_log = this.borehole.penetrationRateLogs[this.borehole.penetrationRateLogs.length - 1];
      if (previous_log) {
        this.penetrationRateLogFormGroup.get('depthFrom').setValue((previous_log.depthTo.measure));
      }
    }
  }
  onSubmit() {

    const log = this.penetrationRateLogFormGroup.value;

    if (this.penetrationRateLog) {

      log['id'] = this.penetrationRateLog.id;

      this.penetrationRateLog.depthTo.measure = log['depthTo'];
      this.penetrationRateLog.depthFrom.measure = log['depthFrom'];
      this.penetrationRateLog.minutes = log['minutes'];
      this.penetrationRateLog.seconds = log['seconds'];
      this.penetrationRateLog.penetrationRate = log['penetrationRate'];

      log['minutes'] = this.penetrationRateLog.minutes;
      log['seconds'] = this.penetrationRateLog.seconds;
      log['depthTo'] = this.penetrationRateLog.depthTo;
      log['depthFrom'] = this.penetrationRateLog.depthFrom;
      log['penetrationRate'] = this.penetrationRateLog.penetrationRate;

    } else {

      log['borehole'] = this.borehole;
      log['depthTo'] = {
        id: '',
        measure: log['depthTo'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };

      log['depthFrom'] = {
        id: '',
        measure: log['depthFrom'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };
    }
    if ((+log['depthTo'].measure) > (+log['depthFrom'].measure)) {
      this.penetrationRateLogService.createPenetrationRateLog(log)
        .subscribe(response => {
            this.onClose({event: 'submit', data: log});
            if (this.penetrationRateLog) {
              this.toaster.success('Water drillers log updated successfully');
            } else {
              this.toaster.success('Water drillers log created successfully');
            }
          },
          error => {
            this.toaster.error(error.toString());
          });
    } else {
      this.toaster.error('`Depth To` must be greater than `Depth From`', 'Invalid Depth');
      this.penetrationRateLogFormGroup.get('depthTo').setValue('');
    }
  }

  reset() {
    this.penetrationRateLogFormGroup.setValue({
      id: '', minutes: '',
      seconds: '', depthFrom: null,
      depthTo: null, penetrationRate: '',
      borehole: '',
    });
    this.onClose({event: 'reset', data: null});
  }

  onClose(actions: any) {
    this.dialogRef.close(actions);
  }

  populateForm() {
    const log = {
      'id': this.penetrationRateLog.id,
      'borehole': this.borehole,
      'minutes': this.penetrationRateLog.minutes,
      'seconds': this.penetrationRateLog.seconds,
      'depthFrom': this.penetrationRateLog.depthFrom.measure,
      'depthTo': this.penetrationRateLog.depthTo.measure,
      'time': parseFloat(this.penetrationRateLog.minutes.toString()) + (parseFloat(this.penetrationRateLog.seconds.toString()) / 60),
      'penetrationRate': (1 / (parseFloat(this.penetrationRateLog.minutes.toString()) + (parseFloat(this.penetrationRateLog.seconds.toString()) / 60))),
    };

    this.penetrationRateLogFormGroup.setValue(log);
  }

  calculatePenetrationRate() {
    if ((this.penetrationRateLogFormGroup.controls['seconds'].value)>59){
      this.penetrationRateLogFormGroup.get('seconds').setErrors({ 'invalid': true });
    } else {
      this.penetrationRateLogFormGroup.get('seconds').setErrors(null);
      this.penetrationRateLogFormGroup.controls['penetrationRate'].setValue((1 / (this.penetrationRateLogFormGroup.controls['minutes'].value) +
      (parseFloat(this.penetrationRateLogFormGroup.controls['seconds'].value) / 60)));
    }
  }


}
