import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {SoilLogService} from '../soil-log.service';
import {ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlTree} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Project} from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import {Notes} from 'src/app/shared-kernel/entity/applied-geoscience/notes';
import {District} from 'src/app/common/entity/security/util/common/district';
import {Coordinates} from 'src/app/shared-kernel/entity/common/coordinates';
import {ExcavationMethod} from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/excavation-method';
import {SoilLog} from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log';
import {TrialPit} from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import {UserService} from 'src/app/auth/user/user.service';
import {Person} from 'src/app/common/entity/security/profile/person';
import {FileToUpload} from 'src/app/common/uploaded-files/entity/file';
import {UploadedFilesService} from 'src/app/common/uploaded-files/uploaded-files.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {LocationTemp} from 'src/app/shared-kernel/entity/common/locationTemp';
import {Village} from 'src/app/shared-kernel/entity/common/Village';
import {SoilChemistry} from '../../../../../../shared-kernel/entity/applied-geoscience/soil-log/soil-chemistry';
import {GeoTechService} from '../../../../geo-tech.service';
import {ApproveAction} from '../../../../../models';
import Swal from 'sweetalert2';
import {ApprovalService} from '../../../../borehole/services/approval.service';
import {TrialPitCreateComponent} from '../../trial-pit-create/trial-pit-create.component';
import { Lightbox } from 'ngx-lightbox';
import { ProjectTypeEnum } from '../../../../../../shared-kernel/enumerations/project-type-enum';
import { CompanyEnum } from '../../../../../../shared-kernel/enumerations/company-enum';
import { StorageConstants } from '../../../../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../../../../../../_core/data/_models/people.model';
import {BusinessUnitEnum} from "../../../../../../shared-kernel/enumerations/business-unit-enum";
import {Roles} from "../../../../../../shared-kernel/entity/Roles";
import {LocalAuthorities} from "../../../../../../_auth/_providers";

declare var $: any;


@Component({
  selector: 'app-soil-log-list',
  templateUrl: './soil-log-list.component.html',
  styleUrls: ['./soil-log-list.component.scss']
})
export class SoilLogListComponent implements OnInit {

  csvDdataSource: MatTableDataSource<SoilLog> = new MatTableDataSource();
  companyEnum: typeof CompanyEnum = CompanyEnum;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileImportInput') fileImportInput: any;

  url: string;
  trialPitId: string;
  trialPit: TrialPit;
  isBGIproject: boolean;
  soilLogForLabResultsUpload: SoilLog;
  soilLogForLabRequestUpload: SoilLog;
  soilLogs: SoilLog[];
  depthLogs: any = [];
  currentComment: string;
  coordinatorObject: Person;
  projectManagerObject: Person;
  geologistObject: Person;
  totalNumberOfFiles: Number;
  totalNumberOfLogs: Number;
  totalNumberOfTests: Number;
  selectedSection: string;
  selectedFileLabResults: File = null;
  selectedFileLabRequest: File = null;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  currentPerson: any;
  isAdminVar: boolean;

  progress: { percentage: number } = {percentage: 0};
  fileToBeUploadedLabResults: FileToUpload = new FileToUpload();
  fileToBeUploadedLabRequest: FileToUpload = new FileToUpload();
  selectedDocument: FileToUpload = new FileToUpload();
  displayName = 'Browse for a file';

  formDataLabResults = new FormData();
  formDataLabRequest = new FormData();
  album: any = [];
  loading = true;
  currentUser :User;

  constructor(
    private router: Router,
    private soilLogService: SoilLogService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userService: UserService,
    private geoTechService: GeoTechService,
    private fileUploadService: UploadedFilesService,
    private approvalService: ApprovalService,
    private _localAuthorities: LocalAuthorities,
    private dialog: MatDialog,
    private _localStorage: LocalStorage,
    private lightbox: Lightbox) {

    this.route.params.subscribe(params => {
      this.trialPitId = params.id;
    });
  }

  ngOnInit() {

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.initPerson(res.id);

    }, err => {
      this.toastr.warning(err.message, "Current User Error");
    });

    this.getSoilTestsSoilChemistry();
    this.getAllSoilLogsByTrialPitId();
    this.getTrialById();
    this.coordinatorObject = new Person();
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  close(): void {
    this.lightbox.close();
  }

  onSectionChange(selectedSection) {
    this.selectedSection = selectedSection;

    if(this.selectedSection === 'analyticalReports') {
      this.soilLogsToDepthLogs();
    }
  }


  createTrialPitObject(): TrialPit {
    this.trialPit = new TrialPit();
    this.trialPit.location = new LocationTemp();
    this.trialPit.location.village = new Village();
    this.trialPit.location.village.district = new District();
    this.trialPit.location.coordinates = new Coordinates();
    this.trialPit.location.coordinates.latitude = '';
    this.trialPit.location.coordinates.longitude = '';
    this.trialPit.project = new Project();
    this.trialPit.locationDescription = new Notes();
    this.trialPit.excavationMethod = new ExcavationMethod();
    this.trialPit.locationImages = [];

    return this.trialPit;
  }

  getTrialById() {
    if (this.trialPitId != null) {
      this.soilLogService.getTrialPitById(this.trialPitId)
        .subscribe(data => {
          this.trialPit = this.createTrialPitObject();
          this.trialPit = data as TrialPit;

          for (let image of this.trialPit.locationImages) {
            let albumImage: any = {};
            albumImage.src = 'data:image/jpg;base64,'+ image.image;
            albumImage.caption = image.name;
            albumImage.thumb = 'data:image/jpg;base64,'+ image.image;
            this.album.push(albumImage);
          }

          this.trialPit.id = this.trialPitId;
          this.isBGIproject = this.trialPit.project.company.name === this.companyEnum.BGI_NAME;
          this.getCoordinatorById(this.trialPit.coordinator);
          this.getManagerById(this.trialPit.projectManager);
          this.getGeologistById(this.trialPit.geologist);
          this.totalNumberOfFiles = this.trialPit.trialPitFiles.length;
        });
    }
  }

  getSoilTestsSoilChemistry() {
    this.geoTechService.getSoilTestSoilChemistry(this.trialPitId).subscribe(data => {
      let soilChemistry = data as SoilChemistry[];
      this.totalNumberOfTests = soilChemistry.length;
    });
  }

  getAllSoilLogsByTrialPitId() {
    if (this.trialPitId != null) {
      this.soilLogService.getAllSoilLogsByTrialPitId(this.trialPitId)
        .subscribe(data => {
          this.soilLogs = data as SoilLog[];
          this.soilLogs.sort((a, b) => <any>a.depthFrom.measure - <any>b.depthFrom.measure);
          this.totalNumberOfLogs = this.soilLogs.length;
          if (this.soilLogs.length !== 0) {
            this.soilLogs = data as SoilLog[];
            this.loading = false;
          } else {
            this.soilLogs = [];
            this.loading = false;
          }
        });
    }
  }

  getCoordinatorById(personId) {
    this.userService.getPersonById(personId)
      .subscribe(data => {
        this.coordinatorObject = data as Person;
      });
  }

  getManagerById(personId) {
    this.userService.getPersonById(personId)
      .subscribe(data => {
        this.projectManagerObject = data as Person;
      });
  }

  getGeologistById(personId) {
    this.userService.getPersonById(personId)
      .subscribe(data => {
        this.geologistObject = data as Person;
      });
  }

  public lessThan(depthFrom: number, depthTo: number) {
    return depthTo < depthFrom;
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  attachSampleLabResults(soilLog: SoilLog) {
    this.soilLogForLabResultsUpload = soilLog;
    $('#uploadLabResults').click();
  }

  attachSampleLabRequest(soilLog: SoilLog) {
    this.soilLogForLabRequestUpload = soilLog;
    $('#uploadLabRequest').click();
  }

  isCSVFile(file: any) {
    if (file.name.endsWith('.csv')) {
      return true;
    } else if (file.name.endsWith('.txt')) {
      return true;
    } else {
      return false;
    }
  }

  fileChangeListenerLabResults($event: any) {
    this.fileToBeUploadedLabResults = new FileToUpload();
    this.progress.percentage = 0;
    const files = $event.srcElement.files;
    this.selectedFileLabResults = files.item(0);
    this.fileToBeUploadedLabResults.type = this.getFileExtFromName(this.selectedFileLabResults.name);
    this.displayName = this.selectedFileLabResults.name;
    this.uploadFileLabResults(this.soilLogForLabResultsUpload);
  }

  fileChangeListenerLabRequestForm($event: any) {
    this.fileToBeUploadedLabRequest = new FileToUpload();
    this.progress.percentage = 0;
    const files = $event.srcElement.files;
    this.selectedFileLabRequest = files.item(0);
    this.fileToBeUploadedLabRequest.type = this.getFileExtFromName(this.selectedFileLabRequest.name);
    this.displayName = this.selectedFileLabRequest.name;
    this.uploadFileLabRequestForm(this.soilLogForLabRequestUpload);
  }

  getFileExtFromName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  uploadFileLabResults(soilLog: SoilLog) {
    this.formDataLabResults.append('file', this.selectedFileLabResults);
    this.fileToBeUploadedLabResults.fileName = this.selectedFileLabResults.name;
    this.fileUploadService.uploadFileToServer(this.formDataLabResults).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.fileToBeUploadedLabResults.uri = event.body.uri;
          this.fileToBeUploadedLabResults.size = event.body.size;
          this.fileToBeUploadedLabResults.fileName = event.body.name;
          soilLog.labResults = this.fileToBeUploadedLabResults;
          soilLog.trialPit = this.trialPit;
          this.soilLogService.createSoilLog(soilLog).subscribe(data => {
              this.selectedFileLabResults = null;
              this.fileToBeUploadedLabResults = new FileToUpload();
              this.formDataLabResults = new FormData();
              this.displayName = 'File Name';
              $('#resetForm').click();
              $('#uploadFileModal').modal('hide');
            },
            error => {
            }, () => {
            });
        }
      }, error => {
        this.toastr.error('File upload has failed, please close the dialog and try again...', 'File Upload Failure');
      },
      () => {
        this.toastr.success('File has been successfully uploaded', 'File Upload');
      });
  }

  uploadFileLabRequestForm(soilLog: SoilLog) {
    this.formDataLabRequest.append('file', this.selectedFileLabRequest);
    this.fileToBeUploadedLabRequest.fileName = this.selectedFileLabRequest.name;
    this.fileUploadService.uploadFileToServer(this.formDataLabRequest).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.fileToBeUploadedLabRequest.uri = event.body.uri;
          this.fileToBeUploadedLabRequest.size = event.body.size;
          this.fileToBeUploadedLabRequest.fileName = event.body.name;
          soilLog.labSampleRequestForm = new FileToUpload;
          soilLog.labSampleRequestForm = this.fileToBeUploadedLabRequest;
          soilLog.trialPit = this.trialPit;
          this.soilLogService.createSoilLog(soilLog).subscribe(data => {
              this.selectedFileLabRequest = null;
              this.fileToBeUploadedLabRequest = new FileToUpload();
              this.formDataLabRequest = new FormData();
              this.displayName = 'File Name';
              $('#resetForm').click();
              $('#uploadFileModal').modal('hide');
            },
            error => {
            }, () => {
            });
        }
      }, error => {
        this.toastr.error('File upload has failed, please close the dialog and try again...', 'File Upload Failure');
      },
      () => {
        this.toastr.success('File has been successfully uploaded', 'File Upload');
      });
  }

  downloadFile(file: FileToUpload) {
    this.fileUploadService.downloadFile(file.fileName).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (file.fileName) {
        downloadLink.setAttribute('download', file.fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    });
  }

  deleteRecordRequest(fileToBeUploaded: FileToUpload) {
    this.selectedDocument = fileToBeUploaded;
    $('#deleteModal').modal('show');
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }

  redirectToDocuments() {
    this.selectedSection = 'trialPitDocuments';
  }

  redirectToLogs() {
    this.selectedSection = 'soilLogs';
  }

  redirectToTests() {
    this.selectedSection = 'trialTest';
  }

  redirectToAnalyticalReports() {
    this.selectedSection = 'analyticalReports';
    this.soilLogsToDepthLogs();
  }

  soilLogsToDepthLogs() {
    this.depthLogs = [];

    this.soilLogs.forEach((log) => {
      let depthLog = {
        from: log.depthFrom.measure, 
        to: log.depthTo.measure,
        // color: log?.color,
        image: log.soil.soilImages[0].image,
        type: log.soil.soil,
        description: log.description,
      }

      this.depthLogs.push(depthLog);
    })
  }

  changeState(action: ApproveAction) {
    let successMessage = 'Trial pit  submitted successfully';
    let title = 'Trial pit  Submit';

    switch (+action) {
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'Trial pit  submitted successfully';
        title = 'Trial pit  Submit';
        break;
      case ApproveAction.QA_APPROVE:
        successMessage = 'Dcp Foundation approved successfully';
        title = 'Dcp Foundation Approve';
        break;
      case ApproveAction.QA_REJECT:
        successMessage = 'Trial pit  rejected successfully';
        title = 'Trial pit  Reject';
        break;
      case ApproveAction.MANAGER_APPROVE:
        successMessage = 'Trial pit  approved successfully';
        title = 'Dcp Foundation Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        successMessage = 'Trial pit rejected successfully';
        title = 'Trial pit  Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        successMessage = 'Trial pit  published successfully';
        title = 'Trial pit  Published';
        break;
      default:
        throw new Error('Unknown change state');
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this.spinner.show();
        this.approvalService.changeTrialPitState(this.trialPitId, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toastr.success(successMessage, title);
          this.getTrialById();
        }, err => {
          this.toastr.warning(err.message, 'Failed');
          this.spinner.hide();
        }, () => {
          this.spinner.hide();
        });
      }
    });

  }

  openTrialPitEditForm(trialPit: TrialPit) {
    const newUserDialogRef = this.dialog.open(TrialPitCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: trialPit
    });
    newUserDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getTrialById();
      }
    });
  }

  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
      if (res) {
        this.currentPerson = res;
        this.currentPerson['user'] = this.currentUser;
        this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});
      }
    }, err => {
      this.toastr.error(err.message, "Profile Details");
    }, ( ) => {
      this.isAdminNew(this.currentPerson);
    });
  }
  isAdminNew(current) {
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {
        if (value.roles.find(x => x.name == Roles.SYSTEM_ADMIN)){
          this.isAdminVar = true;
        }
        else {
          this.isAdminVar = false;
        }
      }
    )
  }

}
