import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ProjectMembers } from './project-detail/project-members/project-member.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService implements OnInit {

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService,
              private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
  }

  createProject(project) {
    this.spinner.show();
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createProjectUrl, project);

  }

  getAllProjects() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectsUrl);
  }

  getAllProjectName(){
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectNamesUrl);
  }

  getMyProjects() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyProjectsUrl);
  }

  getBgiProjects() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBgiProjectsUrl);
  }

  getNonBgiProjects() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNonBgiProjectsUrl);
  }

  getProjectMembers(projectId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectMembersUrl+ '/' + projectId);
  }

  addProjectMember(projectId: string, member: ProjectMembers): Observable<ProjectMembers> {
    return this.http.post<ProjectMembers>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/projects/v1/${projectId}/members`, member);
  }

  isProjectMember(projectId: string): Observable<boolean> {
    return this.http.get<boolean>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/projects/v1/is-member/${projectId}/members`);
  }
  getProjectsByMember(username: string, project?: string, company?: string){
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}${ServiceEndPoints.getAllProjectsByMember}/${username}?project=${project}&company=${company ? company : ''}`);
  }

  updateMemberRoles(projectId: string, memberId: string, update: { roles: Array<any> }): Observable<ProjectMembers> {
    return this.http.patch<ProjectMembers>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/projects/v1/${projectId}/members/${memberId}/roles`, update);
  }

  deleteProjectMember(projectId: string, memberId: string): Observable<void> {
    return this.http.delete<void>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/projects/v1/${projectId}/members/${memberId}`);
  }

  getProjectRoles() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectRolesUrl);
  }

  createProjectMembers(project) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createProjectUrl, project);
  }

  getAllBoreholesByProjectId(projectId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholeByProjectsIdUrl + '/' + projectId);
  }

  getAllProjectStatuses() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectStatusesUrl);
  }

  getAllCommodities() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCommoditiesUrl);
  }

  getAllMineralTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllMineralTypesUrl);
  }

  getCommoditiesByMineralTypeId(mineral: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCommoditiesByMineralIdUrl + '/' + mineral);
  }

  getMineralTypeByCommodityId(commodity: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCommodityByIdUrl + '/' + commodity);
  }

  getProjectById(projectId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProjectByIdUrl + '/' + projectId);
  }

  getAllProjectRoles() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectTypesUrl);
  }


  getProjectCategoriesByRoleId(projectTypeId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProjectCategoriesByProjectTypeUrl + '/' + projectTypeId);
  }

  getProjectCategoryTypesByProjectCategory(projectCategoryId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProjectCategoryTypesByProjectCategoryUrl + '/' + projectCategoryId);
  }

  projectInCompany(projectName: string, companyId: string): Observable<{ exist: boolean, message: string }> {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/projects/v1/check/${projectName}/company/${companyId}`);
  }

  getUnapprovedProjectActivityByProjectIdCount(projectId?: string): Observable<number> {
    return this.http.get<number>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/project/activities/unapproved` + '/' + projectId + '/count');
  }

  closeProject(project: Project): Observable<Project> {
    return this.http.post<Project>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/projects/v1/project/close`, project);
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }
}
