import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivityEnum } from '../../../shared-kernel/enumerations/activity-enum';
import { CollectionsActivity, CollectionsActivityType } from '../../../activities/models';
import { SelectionModel } from '@angular/cdk/collections';
import { State } from '../../../activities/models/state';
import { CollectionsState } from '../../../activities/models/CollectionsState';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CollectionsApprovalService } from '../../../collections/services/collections.approval.service';
import { ToastrService } from 'ngx-toastr';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { GeoTechService } from '../../../applied-geoscience/geo-tech/geo-tech.service';
import Swal from "sweetalert2";
import { Borehole } from '../../../shared-kernel/entity/common/borehole';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-collections-dashbord-table',
  templateUrl: './collections-dashbord-table.component.html',
  styleUrls: ['./collections-dashbord-table.component.scss']
})
export class CollectionsDashbordTableComponent{


  displayedColumns: Array<string> = ['select', 'number', 'borehole_number', 'tray_number',
    'position', 'sample_type', 'depth', 'status', 'view'];
  loading = true;
  activities: Array<CollectionsActivity>;
  currentComment: string;
  activityType: string;
  spinnerTitle = 'Loading Activities...';

  public _dataSource: MatTableDataSource<CollectionsActivity> = new MatTableDataSource<CollectionsActivity>();
  selection = new SelectionModel<CollectionsActivity>(true, []);

  get dataSource(): MatTableDataSource<CollectionsActivity> {
    this.loading = false;
    return this._dataSource;
  }

  @Input('activitiesDataSource') set dataSource(val: MatTableDataSource<CollectionsActivity>) {
    this._dataSource = val;
    if (this._dataSource) {
      this.loading = false;
      this._dataSource.paginator = this.paginator;
    }
  }

  @Input() filter: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  state = new State();
  stateCollections = new CollectionsState();

  constructor(
    private _router: Router,
    private spinner: NgxSpinnerService,
    private collectionsApprovalService: CollectionsApprovalService,
    private _toastr: ToastrService,
    private userDashBoardService: UserDashboardService,
    private geoTechService: GeoTechService,
    private activatedRoute: ActivatedRoute, ) {
    this.activatedRoute.params.subscribe(params => {
      this.activityType = params['type'];
    });
  }

  applyFilter(filterValue: string) {
    if (this.dataSource) {
      this._dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  openMyActivityDetails(activity) {
    const id = {id: activity.id};
    const shelvingId = activity.shelvingId;
    const inventoryId = activity.inventoryId;

    switch (activity.activityType) {
      case  CollectionsActivityType.CORE_TRAY: {
        this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'
        + inventoryId + '/shelving/' + shelvingId + '/Core/tray/' + activity.id]);
        break;
      }

      case  CollectionsActivityType.CHIPS_TRAY: {
        this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'
        + inventoryId + '/shelving/' + shelvingId + '/Chips/' + activity.id]);
        break;
      }
    }
  }




  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CollectionsActivity): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }
}
