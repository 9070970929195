import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRoles } from 'src/app/_auth/services/user-roles';

@Directive({
    selector: '[ifRole]'
})
export class IfRoleDirective {

    constructor(
        private templateRef : TemplateRef<any>,
        private viewContainer : ViewContainerRef,
        private roleServ : UserRoles
    ) {}

    @Input() set ifRole(roleName: string | Array<string>) {
        let validities: Array<boolean> = [];

        if (typeof roleName === 'string') {
            this.roleServ.hasRole(roleName).then(hasRole => {
                validities.push(hasRole);
                this.logic(validities);
            });
        } else {
            let promises = [];
            roleName.forEach(role => {
                promises.push(new Promise((resolve, reject) => {
                    this.roleServ.hasRole(role).then(hasRole => {
                        resolve(hasRole);
                    }, () => {
                        reject(null);
                    });
                }));
            });

            Promise.all(promises).then(results => {
                this.logic(results);
            });
        }
    }

    private logic(validities: Array<boolean>) {
        let hasRole: boolean;

        if (validities.length === 0) hasRole = false;
        else if (validities.length === 1) hasRole = validities[0];
        else {
            hasRole = validities.reduce((total, curr) => total || curr);
        }

        if (hasRole === true) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
