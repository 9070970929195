import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {HydrogeologyService} from "../../../hydrogeology.service";
import {WaterConstantRateTest} from "../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterConstantRateTest";

@Component({
  selector: 'app-constant-rate-test-upload',
  templateUrl: './constant-rate-test-upload.component.html',
  styleUrls: ['./constant-rate-test-upload.component.scss']
})
export class ConstantRateTestUploadComponent implements OnInit {

  csvDdataSource: MatTableDataSource <WaterConstantRateTest> = new MatTableDataSource();

  displayedColumnsForImport: string[] = ['No.',
    'Pumping Type',
    'Depth To Water (m)',
    'Start Date',
    'End Date',
    'Start Time',
    'End Time',
    'Static Water Level',
    'Draw Down',
    'Pumping Rate (mᵌ/hr)',
    'Time To Fill (l)',
    'Time To Fill  (time)',
    'Tds',
    'Temp',
    'pH (S/m)',
    'Electrical Conductivity (S/m)',
    'Observation Boreholes',
    'Comments',
    'Geologist'];
  loading: boolean =true;
  selectedCvsRecord: WaterConstantRateTest;
  constructor(public dialogRef: MatDialogRef<ConstantRateTestUploadComponent>,
              @Inject(MAT_DIALOG_DATA) public data:  WaterConstantRateTest[],
              private hydrogeologyService: HydrogeologyService,private toaster: ToastrService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.csvDdataSource.data = this.data;
    this.loading = false;
  }


  deleteCsvRecord(drl: WaterConstantRateTest) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvDdataSource.data.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvDdataSource.data);
    this.toaster.success('Record deleted', 'Record Deletion');
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveCsvRecords() {
    this.saveRecordsList(this.csvDdataSource.data);
  }

  saveRecordsList(records: WaterConstantRateTest[]) {
    if(this.loading){
      this.toaster.warning('No records to submit', 'Import Drillers Log');
      this.spinner.hide();
    }else{

      this.hydrogeologyService.createConstantRateTestBatch(records).subscribe(data => {
        }, error => {

          this.toaster.error('Records import successful', 'Import Successful');
          this.spinner.hide();

        },
        () => {
          this.closeModal();
          this.toaster.success('Records import successful', 'Import Failed');
          this.spinner.hide();
        });
    }
  }

}
