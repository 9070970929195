import { Notes } from './../../../../../shared-kernel/entity/applied-geoscience/notes';
import { GeoTechService } from './../../../../geo-tech/geo-tech.service';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { WaterPumpingTestRecovery } from './../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestRecovery';
import { CompanyEnum } from './../../../../../shared-kernel/enumerations/company-enum';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { take, takeUntil, startWith, map } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { WaterPumpingTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTest';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect } from '@angular/material';
import { Person } from 'src/app/common/entity/security/profile/person';
import { Router, ActivatedRoute } from '@angular/router';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';
import { HydrogeologyValidation } from '../../../hydrogeology.validation';
import { UserService } from 'src/app/auth/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import * as moment from 'moment';
import { Size } from 'src/app/shared-kernel/entity/common/size';
declare var $: any;

@Component({
  selector: 'app-water-pump-test-recovery-create',
  templateUrl: './water-pump-test-recovery-create.component.html',
  styleUrls: ['./water-pump-test-recovery-create.component.scss']
})
export class WaterPumpTestRecoveryCreateComponent implements OnInit {
  @ViewChild('collectorSelect') collectorSelect: MatSelect;
  @ViewChild('collectorSelect2') collectorSelect2: MatSelect;
  filteredOptionsPeople: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  collectedBy: FormControl = new FormControl();
  collectedByObservation: FormControl = new FormControl();
  people: Person[] = [];
  protected _onDestroy = new Subject<void>();
  collectorFilter: FormControl = new FormControl();
  unitEnum: typeof UnitEnum = UnitEnum;

  testCreateForm: FormGroup;
  loadingPeople = false;
  title: string;

  waterPumpingTestRecovery: WaterPumpingTestRecovery;

  filteredBoreholeNoOptions: any;
  boreholeNumberStillLoading = true;
  boreholeNos: Notes[];
  observationBoreholetestCreateForm: FormGroup;
  observationBorehole: Borehole;
  observe = false;

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private hydrogeologyValidation: HydrogeologyValidation,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<WaterPumpTestRecoveryCreateComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,
    private geoTechService: GeoTechService,
    ) {
    }

  ngOnInit() {
    this.intForm();
    this.intFormObservation();
    this.getBoreholeNumbers();
    this.getAllBGIPersonnel();
    if(this.data.transactionType === 'New') {
      this.title = 'Create Recovery Test';
    }
    if(this.data.transactionType === 'Edit') {
      this.title = 'Edit Recovery Test';
      if (this.data.test.waterPumpingTestRecoveryObservation) {
        this.observe = true;
      }
    }
  }

  intForm(){
    this.testCreateForm = this.formBuilder.group({
      depthToWaterLevel: [this.data.test ? this.data.test.depthToWaterLevel? this.data.test.depthToWaterLevel.measure : '' : ''],
      residualDrawdown: [this.data.test ? this.data.test.residualDrawdown ? this.data.test.residualDrawdown.measure : '' : ''],
      startDate: [this.data.test ? new Date(this.data.test.startDate) : null, Validators.required],
      endDate: [this.data.test ? new Date(this.data.test.endDate) : null, Validators.required],
      time: [this.data.test ? this.data.test.time : ''],
      startTime: [this.data.test ? moment(new Date(this.data.test.startTime)).format('hh:mm') : null, Validators.required],
      endTime: [this.data.test ? moment(new Date(this.data.test.endTime)).format('hh:mm') : null, Validators.required],

      comments: [this.data.test ? this.data.test.comments : '']
    });
  }

  intFormObservation(){
    this.observationBoreholetestCreateForm = this.formBuilder.group({
      boreholeNumber: [ this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? this.data.test.waterPumpingTestRecoveryObservation.borehole :null:null, Validators.required],
      depthToWaterLevel: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? this.data.test.waterPumpingTestRecoveryObservation.depthToWaterLevel? this.data.test.waterPumpingTestRecoveryObservation.depthToWaterLevel.measure :'': '' : ''],
      residualDrawdown: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? this.data.test.waterPumpingTestRecoveryObservation.residualDrawdown ? this.data.test.waterPumpingTestRecoveryObservation.residualDrawdown.measure : '' : '' : ''],
      startDate: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? new Date(this.data.test.waterPumpingTestRecoveryObservation.startDate) : null : null, Validators.required],
      endDate: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? new Date(this.data.test.waterPumpingTestRecoveryObservation.endDate) : null : null, Validators.required],
      time: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? this.data.test.waterPumpingTestRecoveryObservation.time : '' : ''],
      startTime: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? moment(new Date(this.data.test.waterPumpingTestRecoveryObservation.startTime)).format('hh:mm') : null :null, Validators.required],
      endTime: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? moment(new Date(this.data.test.waterPumpingTestRecoveryObservation.endTime)).format('hh:mm') : null : null, Validators.required],

      comments: [this.data.test? this.data.test.waterPumpingTestRecoveryObservation ? this.data.test.waterPumpingTestRecoveryObservation.comments : '' : '']
    });
  }

  getAllBGIPersonnel() {
    this.loadingPeople = true;
    this.companyService.getCompanyByName(CompanyEnum.BGI_NAME).subscribe(company => {
      this.userService.getPersonsByCompanyId(company.id).subscribe(
        data => {
          this.people = data as Person[];
          this.people.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
          this.filteredOptionsPeople.next(this.people.slice());
          this.collectorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.collectorFilterFnc();
          });

          this._setInitialCollector();
          this.loadingPeople = false;
        }
      );
    });
  }

  getBoreholeNumbers(){

    this.geoTechService.geBoreholeNumber().subscribe(value => {
      this.boreholeNumberStillLoading = false;
      this.boreholeNos = value as Notes[];
      this.filteredBoreholeNoOptions = this.observationBoreholetestCreateForm.controls.boreholeNumber.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterBoreholeNo(name) : this.boreholeNos.slice(0,30))
        );
        if(this.data.waterPumpingTestRecovery){
          if(this.data.waterPumpingTestRecovery.waterObservationBoreholes){
            let dababaseBoreholeNo = this.boreholeNos.filter(value1 => value1.id === this.data.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.boreholeNumber);
            this.observationBoreholetestCreateForm.controls.boreholeNumber.setValue(dababaseBoreholeNo);
          }
        }
    },error => {},() => {

    });
  }

  private _filterBoreholeNo(notes: string): Notes[] {
    const filterValue = notes.toLowerCase();
    return this.boreholeNos.filter(option => option.description.toLowerCase().indexOf(filterValue) === 0).slice(0,30);
  }

  displayBoreholeNo(note: Notes): string {
    return note && note.description ? note.description : '';
  }

  collectorFilterFnc() {
    if (!this.people) return;
    let search = this.collectorFilter.value;
    if (!search) {
      this.filteredOptionsPeople.next(this.people.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptionsPeople.next(
      this.people.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  protected _setInitialCollector() {
    this.filteredOptionsPeople.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.data.test) {
        // Edit
        let person1 = this.people.find(pM => pM.id === this.data.test.geologist);
        if (!person1) {
          found = false;
        } else {
          found = true;
          this.collectedBy.setValue(person1);
          this.collectorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (this.data.test && this.data.test.waterPumpingTestRecoveryObservation) {
        // Edit
        let person2 = this.people.find(pM => pM.id === this.data.test.geologist);
        if (!person2) {
          found = false;
        } else {
          found = true;
          this.collectedByObservation.setValue(person2);
          this.collectorSelect2.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }


      if (found === true) return;

      let person1 = this.people.find(pM => pM.id === this.data.test.geologist);
      this.collectedBy.setValue(person1);
      this.collectorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;


      let person2 = this.people.find(pM => pM.id === this.data.test.waterPumpingTestRecoveryObservation.geologist);
      this.collectedByObservation.setValue(person2);
      this.collectorSelect2.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  getBoreholeDetails(id) {
    this.geoTechService.getBoreholeById(id)
    .subscribe(data => {
      this.observationBorehole = data as Borehole;
    });
  }

  onSubmit() {
      this.waterPumpingTestRecovery = new WaterPumpingTestRecovery;

      if(this.data.test) {
        this.waterPumpingTestRecovery = this.data.test;
      }
      this.waterPumpingTestRecovery.geologist = this.collectedBy.value.id;

      this.waterPumpingTestRecovery.borehole = this.data.borehole;

      this.waterPumpingTestRecovery.depthToWaterLevel = new Size;
      this.waterPumpingTestRecovery.depthToWaterLevel.measure = this.testCreateForm.controls.depthToWaterLevel.value;
      this.waterPumpingTestRecovery.depthToWaterLevel.unit.id = this.unitEnum.meter;
      this.waterPumpingTestRecovery.residualDrawdown = new Size;
      this.waterPumpingTestRecovery.residualDrawdown.measure = this.testCreateForm.controls.residualDrawdown.value;
      this.waterPumpingTestRecovery.residualDrawdown.unit.id = this.unitEnum.meter;

      this.waterPumpingTestRecovery.startDate = new Date(this.testCreateForm.controls.startDate.value);
      this.waterPumpingTestRecovery.endDate = new Date(this.testCreateForm.controls.endDate.value);
    
      
      const st = this.testCreateForm.controls.startTime.value.split(':');
      this.waterPumpingTestRecovery.startTime = new Date(this.testCreateForm.controls.startDate.value);
      this.waterPumpingTestRecovery.startTime.setHours(st[0]);
      this.waterPumpingTestRecovery.startTime.setMinutes(st[1]);
   

      this.waterPumpingTestRecovery.endTime = new Date(this.testCreateForm.controls.endDate.value);
      this.waterPumpingTestRecovery.endTime.setHours(this.testCreateForm.controls.endTime.value.split(':')[0]);
      this.waterPumpingTestRecovery.endTime.setMinutes(this.testCreateForm.controls.endTime.value.split(':')[1]);
    

      this.waterPumpingTestRecovery.time = this.testCreateForm.controls.time.value;

      this.waterPumpingTestRecovery.comments = this.testCreateForm.controls.comments.value;



      if (this.observe === true){
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation = new WaterPumpingTestRecovery;
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.geologist = this.collectedByObservation.value.id;
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.borehole = this.observationBorehole;

        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.depthToWaterLevel = new Size;
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.depthToWaterLevel.measure = this.observationBoreholetestCreateForm.controls.depthToWaterLevel.value;
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.depthToWaterLevel.unit.id = this.unitEnum.meter;
      
        this.waterPumpingTestRecovery.startDate = new Date(this.observationBoreholetestCreateForm.controls.startDate.value);
        this.waterPumpingTestRecovery.endDate = new Date(this.observationBoreholetestCreateForm.controls.endDate.value); 

        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.residualDrawdown = new Size;
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.residualDrawdown.measure = this.observationBoreholetestCreateForm.controls.residualDrawdown.value;
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.residualDrawdown.unit.id = this.unitEnum.meter;
       
        const st = this.observationBoreholetestCreateForm.controls.startTime.value.split(':');
      this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.startTime = new Date(this.observationBoreholetestCreateForm.controls.startDate.value);
      this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.startTime.setHours(st[0]);
      this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.startTime.setMinutes(st[1]);
   
   
      this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.endTime = new Date(this.observationBoreholetestCreateForm.controls.endDate.value);
      this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.endTime.setHours(this.observationBoreholetestCreateForm.controls.endTime.value.split(':')[0]);
      this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.endTime.setMinutes(this.observationBoreholetestCreateForm.controls.endTime.value.split(':')[1]);
        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.time = this.observationBoreholetestCreateForm.controls.time.value;

        this.waterPumpingTestRecovery.waterPumpingTestRecoveryObservation.comments = this.observationBoreholetestCreateForm.controls.comments.value;
      }

      
     
      if (this.hydrogeologyValidation.projectDatesvalid(this.waterPumpingTestRecovery.startDate, this.waterPumpingTestRecovery.endDate,this.waterPumpingTestRecovery.startTime,this.waterPumpingTestRecovery.endTime)){
        this.spinner.show();
        this.hydrogeologyService.createWaterPumpingTestRecoveryTestOne(this.waterPumpingTestRecovery).subscribe(data => {
            const waterPumpingTest = data as WaterPumpingTest;
            this.toastr.success('Create Water Pumping Test', 'You have Successfully submitted Water Pumping Test');
            this.closeModal();
            this.spinner.hide();
          }, error => {
            this.toastr.error('Error', error.error.message);
            this.spinner.hide();
          },
            () => {
              this.spinner.hide();
            }
        );
      }
  }
}
