import {Component, Input, OnInit} from '@angular/core';
import {Activity} from "../../../activities/models";
import {MatTableDataSource} from "@angular/material/table";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Router} from "@angular/router";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ToastrService} from "ngx-toastr";
import {LocalAuthorities} from "../../../_auth/_providers";
import {ActivitiesService} from "../../../activities/services/activities.service";
import {map} from "rxjs/operators";
import {StorageConstants} from "../../../_core/storage.constants";
import {ApprovalState} from "../../../applied-geoscience/models";
import {UserDashboardService} from "../../user-dashboard/user-dashboard.service";
import {CollectionsState} from "../../../activities/models/CollectionsState";

@Component({
  selector: 'app-manager-geological-specimen-ui',
  templateUrl: './manager-geological-specimen-ui.component.html',
  styleUrls: ['./manager-geological-specimen-ui.component.scss']
})
export class ManagerGeologicalSpecimenUiComponent implements OnInit {

  allVettedCount: any;

  loading = true;
  cards :any;
  public pieChartType = 'pie';
  currentUser: any;
  currentPerson: any;
  isSet = true;
  colors: any ;
  filter: string;
  activityType: string;
  title: string;
  status: ApprovalState;

  activitiesCount :any;
  state = new CollectionsState();

  public myactivitiesLabels = ['Pending Approval', 'Returned', 'Approved'];
  public myactivitiesData = [ 91, 107, 73];

  geologicalSpecimenColumns: Array<string> = ['No', 'accessionNumber', 'specimenIdentification','status','currentState'];

  public geologicalSpecimenDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  tableDataSource: MatTableDataSource<any> ;

  constructor( private breakpointObserver: BreakpointObserver,
               private _router: Router,
               private _localStorage: LocalStorage,
               private _toaster: ToastrService,
               private userDashBoardService: UserDashboardService,
               private activitiesService: ActivitiesService,
               private _localAuthorities: LocalAuthorities,
               private _activitiesServ: ActivitiesService,) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
          { title: 'Pending Approval', cols: 1, rows: 3 ,id: 'pending'},
          { title: 'Approved', cols: 1, rows: 3 ,id: 'approved'},
          { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'}
        ];
      })
    );
  }

  ngOnInit() {
    this.getActivityCount();
    this.getApprovalRecentData();
    // this.getGeologicalSpecimenActivityCounts();

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
    }, err => {
      this._toaster.warning(err.message, "Current User Error");
    },() => {

    });
    this.colors = [{backgroundColor: [  '#573419','#00A2E5',
        '#009900','#C8A207','#CC0000', '#CC9933']}];

    switch(this.activityType){
      case 'manager':
        switch (this.filter) {
          case 'SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR':
            this.getApprovalGeologicalActivitiesByStatus(28);
            break;
          case 'APPROVED_MANAGER':
            this.getApprovalGeologicalActivitiesByStatus(7);
            break;
          case 'REJECTED_BY_MANAGER':
            this.getApprovalGeologicalActivitiesByStatus(10);
            break;
          case 'all':
            this.getApprovalGeologicalActivitiesByStatus(100);
            break;
        }
        break;
    }
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this.gotoPendingApprovalList();
        break
      case this.myactivitiesLabels[1]:
        this.gotoReturnedList();
        break
      case this.myactivitiesLabels[2]:
        this.gotoApprovedList();
        break
      case this.myactivitiesLabels[3]:
        this.gotoAllVettedList();
        break
      default:
    }
  }
  private initPerson(userId: string) {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toaster.error(err.message, "Profile Details");
    }, () => {

    });
  }



  getActivityCount(){
    this.userDashBoardService.getGeologicalSpecimenApprovalActivityCounts().subscribe(value => {
      this.myactivitiesData = [ value.managerSubmitted, value.returned, value.approved];
      this.allVettedCount =  Number(value.returned) + Number( value.approved);
    });
  }

  public geologicalSpecimenDataSource1: MatTableDataSource<any> = new MatTableDataSource<any>();
  public geologicalSpecimenDataSource2: MatTableDataSource<any> = new MatTableDataSource<any>();

  getApprovalRecentData(){
    this.userDashBoardService.getGeologicalSpecimenApprovalActivity().subscribe(value => {
      this.geologicalSpecimenDataSource = new MatTableDataSource<any>(value.pendingApproval);
      this.geologicalSpecimenDataSource1 = new MatTableDataSource<any>(value.approved);
      this.geologicalSpecimenDataSource2 = new MatTableDataSource<any>(value.returned);
      this.loading = false;
    });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }
  gotoPendingApprovalList(){
    this._router.navigate([`portal/activities/geological_specimen/manager/SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR`]);
  }

  gotoApprovedList(){
    this._router.navigate([`/portal/activities/geological_specimen/manager/APPROVED_MANAGER`]);
  }
  gotoReturnedList(){
    this._router.navigate([`portal/activities/geological_specimen/manager/REJECTED_BY_MANAGER`]);
  }

  gotoAllVettedList(){
    this._router.navigate([`portal/activities/geological_specimen/senior/ALL_VETTED_SENIOR`]);
  }

  getApprovalGeologicalActivitiesByStatus(status){
    this.activitiesService.getGeologicalSpecimenApprovalActivitiesByStatus(status).subscribe(value => {
      this.pendingTableDataSource = new MatTableDataSource<any>(value);
    },error => {
      this._toaster.error('Data retrival unsuccessful','Geological Specimen Activities')
    },() => {
    });
  }

}
