import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { StorageConstants } from './../../_core/storage.constants';
import { NgIfContext } from '@angular/common/src/directives/ng_if';

@Directive({
    selector: '[ifCreator]'
})
export class IfCreatorDirective {
    constructor(
        private templateRef : TemplateRef<any>,
        private viewContainer : ViewContainerRef,
        private _localStorage: LocalStorage
    ) {}

    @Input() set ifCreator(creator: string ) {

        let promise = new Promise((resolve, reject) => {
            this._localStorage.getItem<any>(StorageConstants.fullUserKey).toPromise().then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });

        promise.then((res:any) => {
            this.logic(creator, res.username);
        }).catch(_err => {
            this.logic(creator, '');
        });
    }


    private logic(userOne: string, userTwo: string) {
        if (userOne === userTwo) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
