export enum TrayApprovalState {
  PENDING_SENIOR_ARCHIVIST_SUBMISSION = 'PENDING_SENIOR_ARCHIVIST_SUBMISSION',
  SUBMITTED_TO_SENIOR_ARCHIVIST = 'SUBMITTED_TO_SENIOR_ARCHIVIST',
  REJECTED_BY_SENIOR_ARCHIVIST = 'REJECTED_BY_SENIOR_ARCHIVIST',
  APPROVED = 'APPROVED',
  PENDING_MANAGER_SUBMISSION = 'PENDING_SENIOR_ARCHIVIST_SUBMISSION',
  SUBMITTED_TO_MANAGER = 'SUBMITTED_TO_SENIOR_ARCHIVIST',
  REJECTED_BY_MANAGER = 'REJECTED_BY_SENIOR_ARCHIVIST',
  APPROVED_MANAGER = 'APPROVED_MANAGER',
  PENDING_SPECIMEN_MANAGER_SUBMISSION = 'PENDING_SPECIMEN_MANAGER_SUBMISSION',
  SUBMITTED_SPECIMEN_TO_MANAGER = 'SUBMITTED_SPECIMEN_TO_MANAGER',
  REJECTED_SPECIMEN_BY_MANAGER = 'REJECTED_SPECIMEN_BY_MANAGER',
  APPROVED_SPECIMEN_MANAGER = 'APPROVED_SPECIMEN_MANAGER'
}

export enum TrayApproveAction {
  SUBMIT_TO_SENIOR_ARCHIVIST = 0,
  SENIOR_ARCHIVIST_APPROVE = 1,
  SENIOR_ARCHIVIST_REJECT = 2,
  SUBMIT_TO_MANAGER = 3,
  MANAGER_APPROVE = 4,
  MANAGER_REJECT = 5,
  SUBMIT_SPECIMEN_TO_MANAGER = 6,
  MANAGER_APPROVE_SPECIMEN = 7,
  MANAGER_REJECT_SPECIMEN = 8
}
