import {Component, OnInit} from '@angular/core';
import {CalendarView, CalendarEvent, CalendarEventTimesChangedEvent} from 'angular-calendar';
import {
  startOfDay,
  isSameDay,
  isSameMonth,
  compareDesc, endOfDay,
} from 'date-fns';
import { Observable, Subject } from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CustomerRequestFormComponent} from '../customer-request-form/customer-request-form.component';
import {ScheduleService} from '../../../services/schedule.service';
import {PeopleService} from '../../../../_core/mock/people.service';
import {Router} from '@angular/router';
import {EventScheduleFormComponent} from '../event-schedule-form/event-schedule-form.component';
import {formatDate} from '@angular/common';
import { EventSchedule } from '../../../entity/EventSchedule';
import { StorageConstants } from '../../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../../../../_core/data/_models/people.model';
import { BusinessUnitEnum } from '../../../../shared-kernel/enumerations/business-unit-enum';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  green: {
    primary: '#50951b',
    secondary: '#d0f8c8',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  businessUnit = '';

  events: CalendarEvent[] = [];
  activeDayIsOpen = true;
  dialogConfig = new MatDialogConfig();
  currentUser: User;

  constructor(private dialog: MatDialog, private scheduleService: ScheduleService,
              private peopleService: PeopleService, private router: Router,private _localStorage: LocalStorage,) {

  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      if(this.currentUser.businessUnit.name === BusinessUnitEnum.mineralResources){
        this.businessUnit = '(Mineral Resource Assessment)';
        this.getMineralResourceEvents();
      }else{
        this.getAllScheduledEvents();
      }
    }, err => {
    },() => {
    });

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '600px';



  }

  getMineralResourceEvents(){
    this.events = [];
    this.scheduleService.getMineralEventSchedules().subscribe(
      data => {
        data.forEach(eventSchedule => {
          const today = new Date().setHours(0, 0, 0, 0);

          let color: any;
          if (compareDesc(today, new Date(eventSchedule.startDate)) === 0) { // two dates are equal : current active event
            color = colors.green;
          } else if (compareDesc(today, new Date(eventSchedule.endDate)) === -1) { // the first date is after the second : passed event
            color = colors.red;
          } else if (compareDesc(today, new Date(eventSchedule.startDate)) === 1) { // the first date is before the second : up-coming event
            color = colors.yellow;
          }

          const startDay = new Date(eventSchedule.startDate);
          startDay.setHours(+eventSchedule.startTime.split(':')[0], +eventSchedule.startTime.split(':')[1]);
          const endDay = new Date(eventSchedule.endDate);
          endDay.setHours(+eventSchedule.endTime.split(':')[0], +eventSchedule.endTime.split(':')[1]);

          const evnt = {
            start: eventSchedule.allDay ? startOfDay(startDay) : startDay,
            end: eventSchedule.allDay ? endOfDay(endDay) : endDay,
            title: `${eventSchedule.request.requestedServices} \t (${formatDate(startDay, 'dd/MM/yyyy HH:mm', 'en')}
             - ${formatDate(endDay, 'dd/MM/yyyy HH:mm', 'en')})`,
            color: color,
            allDay: eventSchedule.allDay,
            actions: [
              {
                label: '<span class="mdi mdi-eye mdi-18px text-info"></span>',
                onClick: ({event}: { event: CalendarEvent }): void => {
                  this.dialogConfig.data = {
                    edit: false,
                    eventSchedule: eventSchedule
                  };
                  this.dialog.open(EventScheduleFormComponent, this.dialogConfig).afterClosed().subscribe(result => {
                  });

                },
              },
              {
                label: '<span class="mdi mdi-file-document-edit mdi-18px text-success"></span>',
                onClick: ({event}: { event: CalendarEvent }): void => {
                  const dialogConfig = new MatDialogConfig();
                  this.router.navigate([`/portal/collections/requests/${eventSchedule.request.id}/details`]);
                },
              }
            ]
          };
          this.addEvent(evnt);
        });
      }
    );
  }

  getAllScheduledEvents() {
    this.events = [];
    let allEvent: Observable<EventSchedule[]> = new Observable<EventSchedule[]>();

    this.scheduleService.getAllEventSchedules().subscribe(
      data => {
        data.forEach(eventSchedule => {
          const today = new Date().setHours(0, 0, 0, 0);

          let color: any;
          if (compareDesc(today, new Date(eventSchedule.startDate)) === 0) { // two dates are equal : current active event
            color = colors.green;
          } else if (compareDesc(today, new Date(eventSchedule.endDate)) === -1) { // the first date is after the second : passed event
            color = colors.red;
          } else if (compareDesc(today, new Date(eventSchedule.startDate)) === 1) { // the first date is before the second : up-coming event
            color = colors.yellow;
          }

          const startDay = new Date(eventSchedule.startDate);
          startDay.setHours(+eventSchedule.startTime.split(':')[0], +eventSchedule.startTime.split(':')[1]);
          const endDay = new Date(eventSchedule.endDate);
          endDay.setHours(+eventSchedule.endTime.split(':')[0], +eventSchedule.endTime.split(':')[1]);

          const evnt = {
            start: eventSchedule.allDay ? startOfDay(startDay) : startDay,
            end: eventSchedule.allDay ? endOfDay(endDay) : endDay,
            title: `${eventSchedule.request.requestedServices} \t (${formatDate(startDay, 'dd/MM/yyyy HH:mm', 'en')}
             - ${formatDate(endDay, 'dd/MM/yyyy HH:mm', 'en')})`,
            color: color,
            allDay: eventSchedule.allDay,
            actions: [
              {
                label: '<span class="mdi mdi-eye mdi-18px text-info"></span>',
                onClick: ({event}: { event: CalendarEvent }): void => {
                  this.dialogConfig.data = {
                    edit: false,
                    eventSchedule: eventSchedule
                  };
                  this.dialog.open(EventScheduleFormComponent, this.dialogConfig).afterClosed().subscribe(result => {
                  });

                },
              },
              {
                label: '<span class="mdi mdi-file-document-edit mdi-18px text-success"></span>',
                onClick: ({event}: { event: CalendarEvent }): void => {
                  const dialogConfig = new MatDialogConfig();
                  this.router.navigate([`/portal/collections/requests/${eventSchedule.request.id}/details`]);
                },
              }
            ]
          };
          this.addEvent(evnt);
        });
      }
    );
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
  }

  /**
   * Create new event form
   */
  onCreate(): void {
    this.dialogConfig.data = {
      event: null
    };

    this.dialog.open(CustomerRequestFormComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getAllScheduledEvents();
    });
  }

  /**
   * Update event details
   */
  handleEditEvent(event: any): void {
    // this.router.navigate([`/portal/collections/requests/${}/details`]);
  }

  addEvent(event: any): void {
    this.events = [
      ...this.events,
      event
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent): any {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  /**
   * Change calendar view
   * @param view: CalendarView.Month | CalendarView.Week | CalendarView.Day
   */
  setView(view: CalendarView): any {
    this.view = view;
  }

  closeOpenMonthViewDay(): any {
    this.activeDayIsOpen = false;
  }

}
