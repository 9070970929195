import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {


  constructor(private router: Router, private spinner: NgxSpinnerService) {

    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        this.spinner.show();

      } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.spinner.hide();
      }
    }, () => {
      this.spinner.hide();
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
