import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DiameterFormationService } from './service/diameter-formation.service';
import { DiameterFormation } from './entity/diameterFormation';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Unit } from 'src/app/shared-kernel/entity/common/unit';
import { CoreService } from 'src/app/applied-geoscience/geo-tech/core/core.service';
import Swal, {SweetAlertOptions } from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-diameter-formation',
  templateUrl: './diameter-formation.component.html',
  styleUrls: ['./diameter-formation.component.scss']
})
export class DiameterFormationComponent implements OnInit {

  @Input('currentState') currentState;
  @Input('boreholeType') boreholeType;

  @Input('boreholeCreator') boreholeCreator: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input('projectId') projectId;

  diameterFormationList: DiameterFormation[];
  newDiameterFormation: DiameterFormation = new DiameterFormation();
  borehole: Borehole = new Borehole();
  boreholeId: string;

  displayedDiameterFormationsColumns = ['No.', 'From', 'To', 'Diameter', 'Edit', 'Delete'];
  diameterFormationDatasource: MatTableDataSource<DiameterFormation> = new MatTableDataSource();

  displayedColumnsForImport: string[] = ['depthFrom', 'depthTo', 'diameter', 'action'];
  csvDdataSource: MatTableDataSource<DiameterFormation> = new MatTableDataSource();
  csvRecords: any[] = [];

  @ViewChild('fileImportInput') fileImportInput: any;


  UnitEnum: typeof UnitEnum = UnitEnum;

  validation_messages = {
    'depthFrom': [
      { type: 'required', message: 'Depth From is required.' },
    ],
    'depthTo': [
      { type: 'required', message: 'Depth To is required.' },
    ],
    'diameter': [
      { type: 'required', message: 'Diameter is required.' },
    ]
  };
  loading = false;
  edit = false;
  modalHeader = 'Create Diameter Formation';
  selectedCvsRecord: DiameterFormation;


  constructor(private diameterFormationService: DiameterFormationService,
    private spinner: NgxSpinnerService, private toast: ToastrService, private router: ActivatedRoute,
    private coreService: CoreService) {
    router.params.subscribe(
      data => {
        this.boreholeId = data.id;
      }
    );
  }

  ngOnInit() {
    this.getAllDiameterFormationByBoreholeId(this.boreholeId);
    this.prepareDiameterFormation();
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  prepareDiameterFormation() {
    this.newDiameterFormation.depthFrom = new Size();
    this.newDiameterFormation.depthTo = new Size();
    this.newDiameterFormation.diameter = new Size();
    this.newDiameterFormation.depthFrom.unit = new Unit();
    this.newDiameterFormation.depthTo.unit = new Unit();
    this.newDiameterFormation.diameter.unit = new Unit();
    this.newDiameterFormation.depthFrom.unit.id = UnitEnum.meter;
    this.newDiameterFormation.depthTo.unit.id = UnitEnum.meter;
    this.newDiameterFormation.diameter.unit.id = UnitEnum.meter;
    this.newDiameterFormation.borehole = new Borehole();
    this.newDiameterFormation.borehole.id = this.boreholeId;
  }


  resetPaginator() {
    this.diameterFormationDatasource.paginator = this.paginator;
    this.diameterFormationDatasource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.diameterFormationDatasource.filter = filterValue.trim().toLowerCase();
  }

  showModal(): void {
    $('#editAddRecord').modal({ backdrop: 'static' });
    $('#editAddRecord').modal('show');
  }

  closeModal() {
    $('#editAddRecord').modal('hide');
    this.getAllDiameterFormationByBoreholeId(this.boreholeId);
    this.resetPaginator();
  }

  createRecord() {
    this.newDiameterFormation = new DiameterFormation();
    this.prepareDiameterFormation();
    this.showModal();
  }

  saveRecord() {
    if (parseFloat(this.newDiameterFormation.depthFrom.measure) > parseFloat(this.newDiameterFormation.depthTo.measure)) {
      this.toast.error('Depth From should be less than Depth To', 'Depth From');
    } else {
      this.createDiameterFromation(this.newDiameterFormation);
    }
  }

  createDiameterFromation(diameterFormation: DiameterFormation) {
    this.diameterFormationService.createDiameterFromation(diameterFormation).subscribe(response => {

    }, error => {
      this.toast.error('Record created failed.', 'Record Creation');

    }, () => {
      if (this.edit) {
        this.toast.success('Record edited successfully.', 'Record Edit');
        this.closeModal();
        this.edit = false;
        this.modalHeader = 'Create Diameter Formation';
        $('#resetForm').click();
      } else {
        this.toast.success('Record created successfully.', 'Record Creation');
        this.closeModal();
        $('#resetForm').click();
      }

    });
  }

  editRecordRequest(diameterFormation: DiameterFormation) {
    this.edit = true;
    this.modalHeader = 'Edit Diameter Formation';
    this.newDiameterFormation = diameterFormation;
    this.newDiameterFormation.borehole = new Borehole();
    this.newDiameterFormation.borehole.id = this.boreholeId;
    this.showModal();
  }


  deleteRecordRequest(diameterFormation: DiameterFormation) {
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
      if (result.value) {
        this.diameterFormationService.deleteDiameterFormation(diameterFormation).subscribe(response => {
        }, error => {
          this.toast.error('Records delete failed.', 'Record Delete');
        }, () => {
          this.toast.success('Records deleted successfully.', 'Record Delete');
          this.getAllDiameterFormationByBoreholeId(this.boreholeId);
          this.resetPaginator();
        });
      }
    });
/*    this.newDiameterFormation = diameterFormation;
    this.newDiameterFormation.borehole = new Borehole();
    this.newDiameterFormation.borehole.id = this.boreholeId;
    $('#deleteModal').modal({ backdrop: 'static' });
    $('#deleteModal').modal('show');*/
  }

  createAListOfDiameterFormation(diameterFormations: DiameterFormation[]) {
    this.diameterFormationService.createAListOfDiameterFormation(diameterFormations).subscribe(response => {
    }, error => {
      this.toast.error('Records import failed', 'Records Import');
      this.loading = false;
    }, () => {
      this.loading = false;
      this.toast.success('Records imported successfully.', 'Records Import');
      this.closeModal();
    });
  }



  getAllDiameterFormationByBoreholeId(id: string) {
    this.diameterFormationService.getAllDiameterFormationByBoreholeId(id).subscribe(response => {
      this.diameterFormationList = response as DiameterFormation[];
      this.diameterFormationList.sort((a, b) => <any>a.depthFrom.measure - <any>b.depthFrom.measure);
      this.diameterFormationDatasource.data = this.diameterFormationList;
    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
      this.spinner.hide();

    });
  }


  deleteCsvRecord(diameterFormation: DiameterFormation) {
    this.selectedCvsRecord = diameterFormation;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toast.success('Record deleted', 'Record Deletion');
  }

  showCsvModal(): void {
    $('#csvModal').modal({ backdrop: 'static' });
    $('#csvModal').modal('show');
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        const csvRecord: DiameterFormation = new DiameterFormation();
        csvRecord.depthFrom = new Size();
        csvRecord.depthTo = new Size();
        csvRecord.diameter = new Size();
        csvRecord.depthFrom.unit = new Unit();
        csvRecord.depthTo.unit = new Unit();
        csvRecord.diameter.unit = new Unit();
        csvRecord.depthFrom.unit.id = UnitEnum.meter;
        csvRecord.depthTo.unit.id = UnitEnum.meter;
        csvRecord.diameter.unit.id = UnitEnum.meter;
        csvRecord.depthFrom.measure = data[0];
        csvRecord.depthTo.measure = data[1];
        csvRecord.diameter.measure = data[2];
        csvRecord.borehole = new Borehole();
        csvRecord.borehole.id = this.boreholeId;
        dataArr.push(csvRecord);
      }
    }
    this.csvDdataSource.data = dataArr;
    return dataArr;
  }

  saveCsvRecords() {
    this.saveBatch(this.csvRecords);
    this.fileReset();
    $('#csvModal').modal('hide');
  }

  saveBatch(records: any) {
    this.spinner.show();
    this.createAListOfDiameterFormation(records);
  }


  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.getAllDiameterFormationByBoreholeId(this.boreholeId);
    this.resetPaginator();
  }



  importCSVFile() {
    $('#uploadCsv').click();
  }

}
