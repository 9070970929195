import { Component, Input, OnInit } from '@angular/core';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../../../core/core.service';

@Component({
  selector: 'app-waterborehole-logs',
  templateUrl: './waterborehole-logs.component.html',
  styleUrls: ['./waterborehole-logs.component.scss']
})
export class WaterboreholeLogsComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('projectId') projectId: string = '';
  boreholeNumber: string;
  boreholeId: string;
  borehole: Borehole;
  selectedTab: number;

  constructor(private router: ActivatedRoute, private coreService: CoreService) {

    router.params.subscribe(
      data => {
        this.boreholeId = data.id;
        if (data.tab) {
          this.selectedTab = data.tab;
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  getBoheholeNumber(id) {
    this.coreService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
        this.boreholeNumber = this.borehole.boreholeNumber;
      });
  }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.boreholeId = params.id;
    });
    this.boreholeNumber = '';
    this.getBoheholeNumber(this.boreholeId);
  }

}

