import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {MatDialogConfig} from '@angular/material/dialog';
import {MatDialog} from '@angular/material';
import { LocalStorage } from '@ngx-pwa/local-storage';

import {DrillingRequest} from 'src/app/shared-kernel/entity/driller/drilling-request';
import {DrillerService} from '../../driller.service';

import {DrillingRequestCreateComponent} from '../drilling-request-create/drilling-request-create.component';
import { DrillingChecklist } from 'src/app/shared-kernel/entity/driller/DrillingChecklist';
import { DocumentsService } from 'src/app/shared/documents/dococument.service';

import { StorageConstants } from './../../../_core/storage.constants';

declare var $: any;

@Component({
  selector: 'app-drilling-request-detail',
  templateUrl: './drilling-request-detail.component.html',
  styleUrls: ['./drilling-request-detail.component.scss']
})
export class DrillingRequestDetailComponent implements OnInit {
  selectedSection: string;
  onSectionDash: boolean = true;
  documentCount: number = 0;
  boreholeCount: number = 0;
  currentPrincipal: any;

  drillingRequest: DrillingRequest;
  drillingRequestId: string;
  drillingRequestFiles: string;
  loading = true;
  expanded: boolean = true;
  expandedBorehole: boolean = true;

  drillingChecklist: DrillingChecklist;
  drillingChecklists: DrillingChecklist[];
  drillingRequestHasChecklist: boolean = false;

  constructor(
    private pageRoute: ActivatedRoute,
    private router: Router,
    private drillerService: DrillerService,
    private dialog: MatDialog,
    private _localStorage: LocalStorage,
    private docServ: DocumentsService
  ) {
    this.pageRoute.params.subscribe(params => {
      this.drillingRequestId = params.id;
    });
  }

  ngOnInit() {

    this._currentPrincipal();
    this.getDrillingRequestById();
    this.getDrillingChecklistsByDrillingRequestId();
  }

  navBack() {
    const urlParams = {id: this.drillingRequest.project.id};
    this.router.navigate(['portal/applied-geoscience/project/detail', urlParams]);
  }

  onSectionChange(selectedSection) {
    this.onSectionDash = selectedSection ? false : true;
    this.expandedBorehole = true;
    this.selectedSection = selectedSection;
  }

  navigate(view: string) {
    this.selectedSection = view;
    this.onSectionDash = false;
  }

  refreshDocumentCount() {
    this.countDocuments();
  }

  refreshBoreholeCount() {
    this.countBoreholes();
  }

  getDrillingRequestById() {
    this.drillerService.getDrillingRequestById(this.drillingRequestId)
      .subscribe(data => {
        this.drillingRequest = data as DrillingRequest;
        if (this.drillingRequest.drillingRequestFiles && this.drillingRequest.drillingRequestFiles.length > 0) {
          this.drillingRequestFiles = this.drillingRequest.drillingRequestFiles.map(x => x.name).join(",");
        }

        this.countDocuments();
        this.countBoreholes();
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  onEdit() {
    this.dialog.open(DrillingRequestCreateComponent,
      <MatDialogConfig>{
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
        data: this.drillingRequest
      }).afterClosed().subscribe(result => {
      this.getDrillingRequestById();
    });
  }

  getDrillingChecklistsByDrillingRequestId() {
    this.drillerService.getChecklistsByDrillingRequestId(this.drillingRequestId)
    .subscribe(data => {
      this.drillingChecklists = data as DrillingChecklist[];
      if (this.drillingChecklists.length > 0) {
        this.drillingRequestHasChecklist = true;
      } else {
        this.drillingRequestHasChecklist = false;
      }
      this.drillingChecklist = this.drillingChecklists[0];
    });
}

refreshDrillingRequestDetails() {
  this.getDrillingRequestById();
  this.getDrillingChecklistsByDrillingRequestId()
}

  exportPDF() {
  }

  private countDocuments() {
    this.docServ.getDocumentCount(this.drillingRequestId).subscribe(res => {
      this.documentCount = res;
    });
  }

  private _currentPrincipal() {
    this._localStorage.getItem<any>(StorageConstants.fullUserKey).subscribe(res => {
      this.currentPrincipal = res;
    });
  }

  private countBoreholes() {
    this.drillerService.getDrillingRequestBoreholeCount(this.drillingRequestId).subscribe(res => {
      this.boreholeCount = res;
    });
  }
}
