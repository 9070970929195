import { Component, OnInit } from '@angular/core';
import { Activity } from '../../../activities/models';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { ApprovalState } from '../../../applied-geoscience/models';
import { State } from '../../../activities/models/state';

@Component({
  selector: 'app-minerals-tech-borehole-dashboard',
  templateUrl: './minerals-tech-borehole-dashboard.component.html',
  styleUrls: ['./minerals-tech-borehole-dashboard.component.scss']
})
export class MineralsTechBoreholeDashboardComponent implements OnInit {

  activitiesCount = {
    myActivities: 0,
    drafts: 0,
    submittedToManager: 0,
    submittedToQA: 0,
    returnedByQA: 0,
    returnedByManager: 0,
    approved: 0
  }
  numberOfMyProjects = 0;
  numberOfBGIProjects = 0;
  stillLoadingCounts = true;
  stillLoadingMyActivitiesCounts = true;
  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvalEnum: typeof ApprovalState = ApprovalState;
  stillLoadingDraftsCounts = true;
  stillLoadingSubmittedToGeologistCounts = true;
  stillLoadingSubmittedToManagerCounts = true;
  stillLoadingReturnedByGeologistCounts = true;
  stillLoadingReturnedByManagerCounts = true;
  stillLoadingApprovedCounts = true;
  state = new State();

  cards :any;
  colors: any ;
  pieChartType = 'pie';
  isSet = true;

  public myactivitiesLabels = [ 'Drafts', 'Submitted-QA', 'Submitted-Manager', 'Returned', 'Approved'];
  public myactivitiesData = [ 91, 107, 73, 4, 84];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _toaster: ToastrService,
    private userDashBoardService: UserDashboardService,
    private spinner: NgxSpinnerService,
  ) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'Returned', cols: 1, rows: 1 },
            { title: 'Drafts', cols: 1, rows: 1 },
            { title: 'Approved', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'My Boreholes', cols: 1, rows: 3 , id: 'myactivities'},
          { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'},
          { title: 'Drafts',cols: 1, rows: 3 ,id: 'pendingApproval'},
          { title: 'Approved', cols: 1, rows: 3 ,id: 'approved'}
        ];
      })
    );

  }

  getMyProjectsCount() {
    this.stillLoadingCounts = true;
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.numberOfMyProjects = number;
      this.getBGIProjectsCount();
    });
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
      this.stillLoadingCounts = false;
    });
  }

  getUserActivities(){
    this.userDashBoardService.getMyBoreholeMineralResourcesCount('all').subscribe(number => {
      this.activitiesCount.myActivities = number;
      this.stillLoadingMyActivitiesCounts = false;
    });
  }

  ngOnInit() {
    this.getMyProjectsCount();
    this.getUserActivities();
    this.getDrafts();
    this.colors = [{backgroundColor: [  '#573419','#C8A207', '#00A2E5',
        '#CC0000','#009900', '#CC9933']}];

    this.getActivities();
  }



  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager-borehole/pending-approval']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager-borehole/submitted_qa']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager-borehole/submitted_mng']);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager-borehole/returned']);
        break
      case this.myactivitiesLabels[4]:
        this._router.navigate(['portal/activities/mineral-resources/mineral-resources-manager-borehole/PUBLISHED']);
      default:
    }
  }

  getActivities() {
    this.spinner.show();
    this.userDashBoardService.getMyBoreholeMineralResourceRecents(this.state.PENDING_QA_SUBMISSION.label).subscribe(res => {
      this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
      this.spinner.hide();
    }, err => {
      this._toaster.warning(err.message, 'Failed to get Pending activities');
    });

    this.userDashBoardService.getMyBoreholeMineralResourceRecents(this.state.REJECTED_BY_QA.label).subscribe(res => {
      this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
      this.userDashBoardService.getMyBoreholeMineralResourceRecents(this.state.REJECTED_BY_MANAGER.label).subscribe(res1 => {
        this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res1)) : this.sliceResultArray(res1);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Rejected activities');
      });
    }, err => {
      this._toaster.warning(err.message, 'Failed to get Rejected activities');
    });

    this.userDashBoardService.getMyBoreholeMineralResourceRecents(this.state.PUBLISHED.label).subscribe(res => {
      this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
      this.spinner.hide();
    }, err => {
      this._toaster.warning(err.message, 'Failed to get activities');
    });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  gotoReturnedList(){
    this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/Returned']);
  }

  gotoPendingSubmission(){
    this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/pending-approval']);
  }

  gotoApproved(){
    this._router.navigate(['/portal/activities/mineral-resources/mineral-resources-manager-borehole/approved']);
  }

  getDrafts(){
    this.userDashBoardService.getMyBoreholeMineralResourcesCount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(boreholeCount => {
        this.activitiesCount.drafts =  boreholeCount;
        this.stillLoadingDraftsCounts = false;
        this.getSubmittedToQA();
      });
  }

  getSubmittedToQA(){
      this.userDashBoardService.getMyBoreholeMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(boreholeCount => {
        this.activitiesCount.submittedToQA = boreholeCount;
        this.stillLoadingSubmittedToGeologistCounts = false;
        this.getSubmittedToManagerByGeoForTech();
      });
  }

  getSubmittedToManagerByGeoForTech(){
      this.userDashBoardService.getMyBoreholeMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(boreholeCount => {
        this.activitiesCount.submittedToManager = boreholeCount;
        this.stillLoadingSubmittedToManagerCounts = false;
        this.getActivitiesRejectedByQA();
      });
  }

  getActivitiesRejectedByQA(){
      this.userDashBoardService.getMyBoreholeMineralResourcesCount(this.approvalEnum.REJECTED_BY_QA).subscribe(boreholeCount => {
        this.activitiesCount.returnedByQA = boreholeCount;
        this.stillLoadingReturnedByGeologistCounts = false;
        this.getApprovedActivities();
      });
  }

  getActivitiesRejectedByManager(){
      this.userDashBoardService.getMyBoreholeMineralResourcesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(boreholeCount => {
        this.activitiesCount.returnedByManager = boreholeCount;
        this.stillLoadingReturnedByManagerCounts = false;
      this.myactivitiesData = [this.activitiesCount.drafts, this.activitiesCount.submittedToQA,this.activitiesCount.submittedToManager,
        this.activitiesCount.returnedByQA+this.activitiesCount.returnedByManager, this.activitiesCount.approved];
      });
  }

  getApprovedActivities(){
      this.userDashBoardService.getMyBoreholeMineralResourcesCount(this.approvalEnum.PUBLISHED).subscribe(boreholeCount => {
        this.activitiesCount.approved = boreholeCount;
        this.stillLoadingApprovedCounts = false;
        this.getActivitiesRejectedByManager();
      });
  }

  goto(type,filter){
    this._router.navigate([`portal/activities/mineral-resources/${type}/${filter}`]);
  }

}
