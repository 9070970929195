import { NigisImages } from '../../common/nigisImages';
import { RockGroup } from './RockGroup';

export class RockName {
  id: string;
  rock: string;
  description: string;
  hasCharacter: boolean;
  deleted: boolean;
  rockImages: NigisImages[];
  rockGroup: RockGroup =  new RockGroup();
  parentRock: RockName;
  rockSubGroup: string;
  subDivisionSymbol: RockName;
  basicType: RockName;
}
