import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) { }

  getBoreholeCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeCountUrl);
  }

  getProjectCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProjectCountUrl);
  }

  getCompanyCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCompanyCountUrl);
  }

  getProspectingLicenseCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProspectingLicenseCountUrl);
  }

  getFilesCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getFilesCountUrl);
  }

  getDrillersLogCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDrillersLogCountUrl);
  }

  getTimeAndMotionCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getTimeAndMotionCountUrl);
  }

  getBoreholeLithologyLogCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeLithologyLogCountUrl);
  }

  getBoreholeRecoveryLogCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeRecoveryLogCountUrl);
  }

  getWaterBoreholeDrillersLogCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getWaterBoreholeDrillersLogCountUrl);
  }

  getWaterBoreholeLithologyLogCount(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getWaterBoreholeLithologyLogCountUrl);
  }

  getRecentActivities(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getRecentActivitiesUrl);
  }

  getMyRecentActivities(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyRecentActivitiesUrl);
  }

}
