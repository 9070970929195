import { Borehole } from './../../shared-kernel/entity/common/borehole';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mineral-resource-borehole-tests',
  templateUrl: './borehole-tests.component.html',
  styleUrls: ['./borehole-tests.component.scss']
})
export class BoreholeTestsComponent implements OnInit {

  selectedTab: number = 0;
  @Input('boreholeCreator') boreholeCreator;
  @Input('currentState') currentState: string = '';
  @Input('boreholeId') boreholeId: string = '';
  @Input('projectId') projectId: string = '';
  @Input('tagging') tagging: string = '';
  sampleNumber = 	5555;
  constructor() { }

  ngOnInit() {
  }

}
