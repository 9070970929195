import { PersonnelCreateComponent } from "../../../../personnel/personnel-create/personnel-create.component";
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Input,
} from "@angular/core";
import { CompanyService } from "src/app/applied-geoscience/company/company.service";
import { ProjectService } from "src/app/applied-geoscience/project/project.service";
import { DrillerService } from "src/app/driller/driller.service";
import { Borehole } from "src/app/shared-kernel/entity/common/borehole";
import { BoreholeType } from "src/app/shared-kernel/entity/common/boreholeType";
import { RigType } from "src/app/shared-kernel/entity/common/RigType";
import { Project } from "src/app/shared-kernel/entity/applied-geoscience/project/project";
import { Coordinates } from "src/app/shared-kernel/entity/common/coordinates";
import { District } from "src/app/common/entity/security/util/common/district";
import { Notes } from "src/app/shared-kernel/entity/applied-geoscience/notes";
import { DrillingMethod } from "src/app/shared-kernel/entity/driller/drilling-method";
import { Size } from "src/app/shared-kernel/entity/common/size";
import { Unit } from "src/app/shared-kernel/entity/common/unit";
import {
  MatAutocomplete,
  MatChipInputEvent,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSelect,
  MatDialog,
  MatDialogConfig,
  MatChipList,
  MatAutocompleteSelectedEvent,
} from "@angular/material";
import { GeoTechService } from "../../geo-tech.service";
import { BoreholeValidation } from "../borehole.validation";
import { BoreholeTypeEnum } from "src/app/shared-kernel/enumerations/borehole-type-enum";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Observable, ReplaySubject, Subject, of } from "rxjs";
import { Company } from "src/app/shared-kernel/entity/applied-geoscience/company/company";
import { SampleType } from "src/app/shared-kernel/entity/common/sample-type";
import { Person } from "../../../../_core/data/_models/people.model";
import { UserService } from "src/app/auth/user/user.service";
import { BoreholeLocationImage } from "src/app/shared-kernel/entity/common/boreholeLocationImage";
import { CompanyEnum } from "src/app/shared-kernel/enumerations/company-enum";
import { NigisImages } from "src/app/shared-kernel/entity/common/nigisImages";
import { CoordinatesType } from "src/app/shared-kernel/entity/common/coordinatesType";

import LatLon from "geodesy/latlon-nvector-spherical.js";
import { CoordinatesService } from "src/app/shared-kernel/entity/common/coordinatesValidator/coordinates.service";
import { Coordinate } from "src/app/shared-kernel/entity/common/coordinatesValidator/coordinate";
import { CoordinatesFormat } from "src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat";
import { CoordinatesTypesEnum } from "src/app/shared-kernel/enumerations/coordinates-type-enum";
import { CoordinatesFormatsEnum } from "src/app/shared-kernel/enumerations/coordinates-formats-enumt";
import { ServerRresponse } from "../../../../shared-kernel/error-handling/serverRresponse";
import { LocationTemp } from "../../../../shared-kernel/entity/common/locationTemp";
import { CommonService } from "src/app/common/common.service";
import { Village } from "src/app/shared-kernel/entity/common/Village";
import { Router } from "@angular/router";
import { PersonnelSortPipe } from "../../../../shared-kernel/pipes/personnel.sort.pipe";
import { DrillingRequest } from "src/app/shared-kernel/entity/driller/drilling-request";
import { DrillingRequestBorehole } from "src/app/shared-kernel/entity/driller/drilling-request-borehole";
import { UnitEnum } from "src/app/shared-kernel/enumerations/unit-enum";
import { map, startWith, take, takeUntil } from "rxjs/operators";
import { ProjectMembers } from "src/app/applied-geoscience/project/project-detail/project-members/project-member.model";
import { PeopleData } from "src/app/_core/data/people";
import { StorageConstants } from "src/app/_core/storage.constants";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { ProspectingLicense } from "src/app/shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license";
import { ProspectingLicenseService } from "src/app/applied-geoscience/prospecting-licenses/prospecting-license.service";
import { ProspectingLicenseSelectComponent } from "src/app/applied-geoscience/prospecting-licenses/prospecting-licence-select/prospecting-license-select.component";

declare var $: any;

@Component({
  selector: "app-borehole-editor",
  templateUrl: "./borehole-editor.component.html",
  styleUrls: ["./borehole-editor.component.scss"],
})
export class BoreholeEditorComponent implements OnInit {
  @Input() borehole: Borehole;
  @Input() boreholeEnumType: BoreholeTypeEnum;
  @Input() companyType: CompanyEnum;
  @Input() visibleFields: any;
  @Input() disableOwner: boolean = false;
  @Input() type: string;

  @Input() isBgiBorehole: boolean = false;
  @Input() isPlBorehole: boolean = false;
  @Input() isWaterBorehole: boolean = false;
  @Input() depthOfSurfaceCasingLabel: string = "Depth of Surface Casing";

  @Input() onSubmit;

  @ViewChild("managerSelect") managerSelect: MatSelect;
  @ViewChild("coordinatorSelect") coordinatorSelect: MatSelect;
  @ViewChild("geologistSelect") geologistSelect: MatSelect;
  @ViewChild("sampleTypesChipList") sampleTypesChipList: MatChipList;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  dmsDx: FormControl = new FormControl();
  dmsMx: FormControl = new FormControl();
  dmsSx: FormControl = new FormControl();

  dmsDy: FormControl = new FormControl();
  dmsMy: FormControl = new FormControl();
  dmsSy: FormControl = new FormControl();

  zone35SNorthing = new FormControl();
  zone35SEasting = new FormControl();

  zoneSNorthing = new FormControl();
  zoneSEasting = new FormControl();

  sampleTypesFilterInput = new FormControl();

  projectSearchInput = new FormControl();

  // boreholeType: BoreholeType;
  boreholeTypes: Observable<BoreholeType[]>;
  boreholeTypeEditable: boolean = true;

  rigTypes: RigType[];
  projects: any;
  currentUser: any;
  coordinates: Coordinates;
  districts: District[];
  villages: Village[];
  drillingMethods: DrillingMethod[];
  boreholeOwners: Company[];
  owners: any[];
  boreholeIndividualOwners: any[];
  sampleTypes: SampleType[];
  filteredSampleTypes: Observable<SampleType[]>;
  projectManagers: Person[];
  projectCoordinators: Person[];
  projectGeologists: Person[];
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  boreholeLocationImageCtrl = new FormControl();
  filteredboreholeLocationImages: Observable<BoreholeLocationImage[]>;
  coordinatesTypes: CoordinatesType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];
  // fullProjects: Project[];

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  allCompanies: Company[];
  boreholeForm: FormGroup;
  coordinatesValid = false;

  drillingRequests: DrillingRequest[] = [];
  drilingRequestBoreholes: DrillingRequestBorehole[] = [];
  unitEnum: typeof UnitEnum = UnitEnum;
  title: string = "";
  drilingRequestBorehole: DrillingRequestBorehole;
  filteredDrillingRequestBoreholes: Observable<DrillingRequestBorehole[]>;
  editingMode: boolean = false;
  prospectingLicenses: ProspectingLicense[];
  plStillLoading = false;

  filteredOptionsRigType: Observable<RigType[]>;
  filteredOptionsProject: Observable<Project[]>;
  filteredOptionsVillage: Observable<Village[]>;
  filteredOptionsDistrict: Observable<District[]>;
  filteredOptionsManager: Observable<Person[]>;
  filteredOptionsCoordinator: Observable<Person[]>;
  filteredOptionsGeologist: Observable<Person[]>;
  filteredOptionsProspectingLicense: Observable<ProspectingLicense[]>;
  filteredBoreholeOwners: Observable<any[]>;
  filteredBoreholeIndividualOwners: Observable<any[]>;

  projectMembers: Array<ProjectMembers> = [];
  personMembers: Array<Person> = [];

  currentManager: FormControl = new FormControl();
  currentCoordinator: FormControl = new FormControl();
  currentGeologist: FormControl = new FormControl();

  protected _onDestroy = new Subject<void>();

  projectStillLoading = false;
  districtStillLoading = false;
  villageStillLoading = false;
  boreholeTypeStillLoading = true;
  boreholeStillLoading = false;
  drillingMethodStillLoading = true;
  boreholeOwnerStillLoading = false;
  sampleTypeStillLoading = true;
  drillingRigTypeStillLoading = true;
  projectMemberStillLoading = true;
  boreholeOwnerIndividualStillLoading = true;

  selectedProject: Project;

  validation_messages = this.boreholeValidation.validation_messages;

  @ViewChild("boreholeLocationImageInput")
  boreholeLocationImageInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  constructor(
    private _router: Router,
    private personnelSortPipe: PersonnelSortPipe,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private geoTechService: GeoTechService,
    private drillerService: DrillerService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private boreholeValidation: BoreholeValidation,
    private userService: UserService,
    private coordinatesService: CoordinatesService,
    private prospectingLicenseService: ProspectingLicenseService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private peopleSrv: PeopleData,
    private dialog: MatDialog,
    private _localStorage: LocalStorage
  ) {}

  ngOnInit() {
    if (!this.borehole) {
      this.borehole = new Borehole();
    }

    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;

    this.initForms();
    this.getBotswanaGeofence();
    this.getAllCoordinatesTypes();
    // this.getAllDistricts();
    // this.getAllVillages();
    // this.getCompanies();

    // this.getAllProjects();

    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(
      (res) => {
        this.currentUser = res;
        // this.getProjectsByMember(this.currentUser.username);
      },
      (err) => {
        this.toast.warning(err.message, "Current User Error");
      },
      () => {}
    );
    this.getAllRigTypes();
    this.getAllBoreholeTypesPreSelectExploration();
    this.getAllDrillingMethods();
    this.getAllSampleTypes();
    if (this.borehole && this.borehole.project && this.borehole.project.id) {
      if (this.borehole.drillingRequestBorehole) {
        this.projectSelected(this.borehole.project.id);
        this.editingMode = true;
      }
    }

    // if (!this.visibleFields.prospectingLicense) {
    //   this._getProjectMembers();
    // } else {
    //   this.getAllProspectingLicenses();
    // }

    this.detectChanges();
  }

  private detectChanges() {
    // this.districtFormGroup.controls.name.valueChanges.subscribe((name) => {
    //   this.filteredOptionsDistrict = of(this._filterDistrict(name));
    // });

    // this.villageFormGroup.controls.name.valueChanges.subscribe((name) => {
    //   this.filteredOptionsVillage = of(this._filterVillage(name));
    // });

    // Filter the owners based on what is being enterd in the field
    // this.boreholeForm.get("ownerName").valueChanges.subscribe((name) => {
    //   if (typeof name == "string" || name === null || name === undefined) {
    //     this.filteredBoreholeOwners = of(this._filterBoreholeOwner(name));
    //   }
    // });

    this.sampleTypesFilterInput.valueChanges.subscribe((name) => {
      if (typeof name == "string" || name === null || name === undefined) {
        this.filterSampleTypes(name);
      }
    });

    // Filtering the rig types based on what is being enterd in the field
    this.boreholeForm.controls.drillRigType.valueChanges.subscribe((type) => {
      if (typeof type == "string" || type === null || type === undefined) {
        this.filteredOptionsRigType = of(this._filterRigType(type));
      }
    });

    // this.projectFormGroup.controls.name.valueChanges.subscribe((name) => {
    //   if (typeof name == "string" || name === null || name === undefined) {
    //     this.filteredOptionsProject = of(this._filterProject(name));
    //   }
    // });

    // this.prospectingLicenseFormGroup
    //   .get("prospectingLicenseNumber")
    //   .valueChanges.subscribe((name) => {
    //     if (typeof name == "string" || name === null || name === undefined) {
    //       this.filteredOptionsProspectingLicense = of(
    //         this._filterProspectingLicense(name)
    //       );
    //     }
    //   });

    this.currentManager.valueChanges.subscribe((name) => {
      if (typeof name == "string" || name === null || name === undefined) {
        this.filteredOptionsManager = of(
          this.filterPeople(name, this.projectManagers)
        );
      }
    });

    this.currentCoordinator.valueChanges.subscribe((name) => {
      if (typeof name == "string" || name === null || name === undefined) {
        this.filteredOptionsCoordinator = of(
          this.filterPeople(name, this.projectCoordinators)
        );
      }
    });

    this.currentGeologist.valueChanges.subscribe((name) => {
      if (typeof name == "string" || name === null || name === undefined) {
        this.filteredOptionsGeologist = of(
          this.filterPeople(name, this.projectGeologists)
        );
      }
    });
  }

  ngAfterViewInit() {
    this._setInitialManager();
    this._setInitialCoordinator();
    this._setInitialGeologist();

    if (this.companyType && this.allCompanies) {
      let boreholeOwner = this.allCompanies.filter(
        (x) => x.name === this.companyType
      )[0];

      this.boreholeForm.controls.boreholeOwner.setValue(boreholeOwner);
    }
  }

  displayFnRigType(rigType: RigType): string {
    return rigType && rigType.name ? rigType.name : "";
  }

  displayFnPlNumber(project: Project): string {
    return project && project.name ? project.name : "";
  }

  displayFnVillage(village: Village): string {
    return village && village.name ? village.name : "";
  }

  displayFnDistrict(district: District): string {
    return district && district.name ? district.name : "";
  }

  displayFnManager(person: any): string {
    if (person && person.fullName) {
      return person.fullName;
    } else {
      return person && person.name ? person.name + " " + person.surname : "";
    }
  }

  displayFnCoordinator(person: Person): string {
    return person && person.name ? person.name + " " + person.surname : "";
  }

  displayFnGeologist(person: Person): string {
    return person && person.name ? person.name + " " + person.surname : "";
  }

  private _filterBoreholeOwner(name: string): any[] {
    const filterValue = name ? name.toLowerCase() : "";
    if (!this.owners) {
      return [];
    }

    return this.owners.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  searchForProjects() {
    this.projectStillLoading = true;
    let company = ''

    if(this.type === 'BGI') {
      company = 'Botswana Geoscience Institute';
    }
    let projectName = this.projectFormGroup.controls.name.value;
    this.projectService.getProjectsByMember(this.currentUser.username, projectName, company).subscribe(
      (data) => {

        let tmp = data;

        this.projects = data;
        this.filteredOptionsProject = of(this.projects);
        this.projectStillLoading = false;
      },
      (err) => {}
    );
  }

  private _filterRigType(name: string): RigType[] {
    const filterValue = name ? name.toLowerCase() : "";
    return this.rigTypes.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  private _filterProject(project: string): Project[] {
    const filterValue = project ? project.toLowerCase() : "";
    return this.projects.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  private _filterVillage(name: string): Village[] {
    const filterValue = name ? name.toLowerCase() : "";
    return this.villages.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  private _filterVillagesByDistrict(districtId) {
    return this.villages.filter(
      (option) => option.district.id.indexOf(districtId) >= 0
    );
  }

  private _filterDistrict(name: string): District[] {
    const filterValue = name.toLowerCase();
    return this.districts.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  private filterPeople(name: string, people: any[]): Person[] {
    if (!people) return [];

    const filterValue = name ? name.toLowerCase() : "";

    return people.filter(
      (option) => option.fullName.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openProspectingLicenseSelectModal() {
    
    const selectLicenseDialogRef = this.dialog.open(
      ProspectingLicenseSelectComponent,
      <MatDialogConfig>{
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: true,
      }
    );

    selectLicenseDialogRef.afterClosed().subscribe((pl) => {
      if (pl && pl.prospectingLicenseNumber) {
        this.prospectingLicenseFormGroup.get("id").setValue(pl.id);
        this.prospectingLicenseFormGroup
          .get("prospectingLicenseNumber")
          .setValue(pl.prospectingLicenseNumber);

        this.boreholeForm.get("ownerType").setValue(0);

        this.boreholeForm.get("ownerName").setValue(pl.company.name);
        this.boreholeOwnerFormGroup.get("id").setValue(pl.company.id);

        this.boreholeOwnerFormGroup.get("name").setValue(pl.company.name);

        let ids = pl.projectMembers.map((m) => m.memberId);

        // Find person ids of different roles withing the project
        let coordinatorIds = pl.projectMembers
          .filter((m) => m.role.toLowerCase() === "project coodinator")
          .map((m: any) => m.memberId);

        let managerIds = pl.projectMembers
          .filter((m) => m.role.toLowerCase() === "manager")
          .map((m: any) => m.memberId);

        let geologistIds = pl.projectMembers
          .filter((m) => m.role.toLowerCase() === "geologist")
          .map((m: any) => m.memberId);

        // Get people linked to the prospecting license
        if (ids && ids.length > 0) {
          this.peopleSrv.getPeopleByIdList(ids).subscribe(
            (res) => {
              
              this.personMembers = res;
              let reduced = this.personMembers.map((p: any) => {
                return {
                  id: p.id,
                  fullName: `${p.name} ${p.middleName ? p.middleName : ""}${
                    p.surname
                  }`,
                };
              });

              this.projectManagers = reduced.filter((p) =>
                managerIds.includes(p.id)
              );
              this.filteredOptionsManager = of(this.projectManagers);

              this.projectCoordinators = reduced.filter((p) =>
                coordinatorIds.includes(p.id)
              );
              this.filteredOptionsCoordinator = of(this.projectCoordinators);

              this.projectGeologists = reduced.filter((p) =>
                geologistIds.includes(p.id)
              );
              this.filteredOptionsGeologist = of(this.projectGeologists);

              // this.projectMemberStillLoading = false;
            },
            (err) => {
              this.toast.error(err.message, "Project People Fail");
            }
          );
        }

        this.prospectingLicenseFormGroup
          .get("prospectingLicenseNumber")
          .setErrors(null);
      } else {
        this.prospectingLicenseFormGroup
          .get("prospectingLicenseNumber")
          .setErrors({ exist: true });
      }
    });
  }

  private initForms() {
    if (this.borehole) {
      this.selectedCoordinatesFormat = this.borehole.coordinatesFormat.format;
    } else {
      this.borehole = new Borehole();
    }

    this.boreholeForm = this.formBuilder.group({
      id: [this.borehole ? this.borehole.id : ""],
      boreholeNumber: [this.borehole ? this.borehole.boreholeNumber : ""],
      project: this.createProjectFormGroup(
        this.borehole ? this.borehole.project : null
      ),
      locationTemp: this.createLocationTempFormGroup(
        this.borehole ? this.borehole.locationTemp : null
      ),
      locationDescription: this.createNoteFormGroup(
        this.borehole ? this.borehole.locationDescription : null
      ),
      wellfield: [this.borehole ? this.borehole.wellfield : ""],
      startDate: [this.borehole ? this.borehole.startDate : ""],
      completedDate: [this.borehole ? this.borehole.completedDate : ""],
      rigType: this.createRigTypeFormGroup(
        this.borehole ? this.borehole.rigType : null
      ),
      boreholeDescription: this.createNoteFormGroup(
        this.borehole ? this.borehole.boreholeDescription : null
      ),
      boreholeObjective: this.createNoteFormGroup(
        this.borehole ? this.borehole.boreholeObjective : null
      ),
      comments: this.createNoteFormGroup(
        this.borehole ? this.borehole.comments : null
      ),
      drillingMethod: this.createDrillingMethodFormGroup(
        this.borehole ? this.borehole.drillingMethod : null
      ),
      boreholeType: this.createBoreholeTypeFormGroup(
        this.borehole ? this.borehole.boreholeType : null
      ),
      descriptionOfDrillingFluidsUsed: [
        this.borehole ? this.borehole.descriptionOfDrillingFluidsUsed : "",
      ],
      developmentType: [this.borehole ? this.borehole.developmentType : ""],
      typeOfDev: [this.borehole ? this.borehole.typeOfDev : ""],
      driller: [this.borehole ? this.borehole.driller : ""],
      client: [this.borehole ? this.borehole.client : ""],
      dip: [this.borehole ? this.borehole.dip : ""],
      consultant: [this.borehole ? this.borehole.consultant : ""],
      statusAtEndOfDrilling: [
        this.borehole ? this.borehole.statusAtEndOfDrilling : "",
      ],
      azimuth: [this.borehole ? this.borehole.azimuth : ""],
      casingHeight: this.createSizeFormGroup(
        this.borehole ? this.borehole.casingHeight : null
      ),
      staticWaterLevel: this.createSizeFormGroup(
        this.borehole ? this.borehole.staticWaterLevel : null
      ),
      elevation: this.createSizeFormGroup(
        this.borehole ? this.borehole.elevation : null
      ),
      endDepth: this.createSizeFormGroup(
        this.borehole ? this.borehole.endDepth : null
      ),
      plannedDepth: this.createSizeFormGroup(
        this.borehole ? this.borehole.plannedDepth : null
      ),
      corePicture: [this.borehole ? this.borehole.corePicture : ""],
      sampleTypes: this.createSampleTypeFormArray(
        this.borehole ? this.borehole.sampleTypes : []
      ),
      waterStrike: this.createSizeFormGroup(
        this.borehole ? this.borehole.waterStrike : null
      ),
      estimatedYield: this.createSizeFormGroup(
        this.borehole ? this.borehole.estimatedYield : null
      ),
      holeInclination: [this.borehole ? this.borehole.holeInclination : ""],
      depthOfSurfaceCasing: this.createSizeFormGroup(
        this.borehole ? this.borehole.depthOfSurfaceCasing : null
      ),
      projectManager: [this.borehole ? this.borehole.projectManager : ""],
      projectCoordinator: [
        this.borehole ? this.borehole.projectCoordinator : "",
      ],
      projectGeologist: [this.borehole ? this.borehole.projectGeologist : ""],
      boreholeLocationImages: this.createNigisImagesFormArray(
        this.borehole ? this.borehole.boreholeLocationImages : []
      ),
      holeDiameter: this.createSizeFormGroup(
        this.borehole ? this.borehole.holeDiameter : null
      ),
      boreholeOwner: this.createCompanyFormGroup(
        this.borehole ? this.borehole.boreholeOwner : null
      ),
      overburdenThickness: [
        this.borehole ? this.borehole.overburdenThickness : "",
      ],
      prospectingLicense: this.createProspectingLicenseFormGroup(
        this.borehole ? this.borehole.prospectingLicense : null
      ),
      samplesCollected: [this.borehole ? this.borehole.samplesCollected : ""],
      active: [this.borehole ? this.borehole.active : ""],
      coordinatesFormat: this.createCoordinatesFormatFormGroup(
        this.borehole ? this.borehole.coordinatesFormat : null
      ),
      currentState: [this.borehole ? this.borehole.currentState : ""],
      // statusHistory: [this.borehole ? this.borehole.statusHistory : ""],
      ownerType: [
        {
          value: this.borehole ? this.borehole.ownerType : "",
          disabled: this.disableOwner,
        },
      ],
      boreholeOwnerIndividual: [
        {
          value: this.borehole ? this.borehole.boreholeOwnerIndividual : "",
          disabled: this.disableOwner,
        },
      ],
      ownerName: [{ value: "", disabled: this.disableOwner }],
      boreholeOwnerIndividualFullName: [
        {
          value: this.borehole
            ? this.borehole.boreholeOwnerIndividualFullName
            : null,
          disabled: this.disableOwner,
        },
      ],
      creator: [this.borehole ? this.borehole.creator : ""],
      variance: [this.borehole ? this.borehole.variance : ""],
      drillRigType: [this.borehole ? this.borehole.drillRigType : ""],
      drillingRequestBorehole: this.createDrillingRequestBoreholeFormGroup(
        this.borehole ? this.borehole.drillingRequestBorehole : null
      ),
      holeDiameterString: [
        this.borehole ? this.borehole.holeDiameterString : "",
      ],
      boreholeLogRecords: this.createBoreholeLogRecordsFormArray(
        this.borehole ? this.borehole.boreholeLogRecords : []
      ),
      statusHistory: this.createStatusHistoryFormArray(
        this.borehole ? this.borehole.statusHistory : []
      ),
      boreholeLithologyLogs: this.createBoreholeLithologyLogsFormArray(
        this.borehole ? this.borehole.boreholeLithologyLogs : []
      ),
      waterBoreholeLithologyLogs: this.formBuilder.control(this.borehole.waterBoreholeLithologyLogs),
      timeAndMotions: this.formBuilder.control(this.borehole.timeAndMotions),
      boreholeCoreRecoveryLogs: this.formBuilder.control(this.borehole.boreholeCoreRecoveryLogs),
      waterBoreholeDrillersLogs: this.formBuilder.control(this.borehole.waterBoreholeDrillersLogs),
      waterPumpingTests: this.formBuilder.control(this.borehole.waterPumpingTests),
      constructionCasings: this.formBuilder.control(this.borehole.constructionCasings),
      constructionScreenings: this.formBuilder.control(this.borehole.constructionScreenings),
      drillingProtocols: this.formBuilder.control(this.borehole.drillingProtocols),
      waterBearingZonesLogs: this.formBuilder.control(this.borehole.waterBearingZonesLogs),
      boreholeFiles: this.formBuilder.control(this.borehole.boreholeFiles),
      diameterFormations: this.formBuilder.control(this.borehole.diameterFormations),
      penetrationRateLogs: this.formBuilder.control(this.borehole.penetrationRateLogs),
    });

    this.boreholeForm.get('ownerName').setValidators([Validators.required]);
  }

  createBoreholeLithologyLogsFormArray(boreholeLithologyLogs: any[]): any {
    return this.formBuilder.array(boreholeLithologyLogs ?
      boreholeLithologyLogs.map((boreholeLithologyLog) => {
        return this.createBoreholeLithologyLogFormGroup(boreholeLithologyLog);
      }): []
    );
  }

  createBoreholeLithologyLogFormGroup(boreholeLithologyLog: any): any {
    return this.formBuilder.control(boreholeLithologyLog);
  }

  createStatusHistoryFormArray(arg0: any[]): any {
    return this.formBuilder.array(arg0 ? 
      arg0.map((statusHistory) => {
        return this.createStatusHistoryFormGroup(statusHistory);
      }): []
    );
  }

  createStatusHistoryFormGroup(statusHistory: any): any {
    return this.formBuilder.control(statusHistory);
  }

  createBoreholeLogRecordsFormArray(boreholeLogRecords: any[]): any {
    return this.formBuilder.array(boreholeLogRecords ?
      boreholeLogRecords.map((boreholeLogRecord) => {
        return this.createBoreholeLogRecordFormGroup(boreholeLogRecord);
      }): []
    );  
  }
  createBoreholeLogRecordFormGroup(boreholeLogRecord: any): any {
    
    return this.formBuilder.control(boreholeLogRecord);
  }

  get drillingRequestFormGroup() {
    return this.boreholeForm.get("drillingRequestBorehole") as FormGroup;
  }

  createDrillingRequestBoreholeFormGroup(
    drillingRequestBorehole: DrillingRequestBorehole
  ) {
    return this.formBuilder.group({
      id: [drillingRequestBorehole ? drillingRequestBorehole.id : ""],
      boreholeNumber: [
        drillingRequestBorehole ? drillingRequestBorehole.boreholeNumber : "",
      ],
    });
  }

  get coordinatesFormatFormGroup() {
    return this.boreholeForm.get("coordinatesFormat") as FormGroup;
  }

  createCoordinatesFormatFormGroup(coordinatesFormat: CoordinatesFormat) {
    return this.formBuilder.group({
      id: [coordinatesFormat ? coordinatesFormat.id : ""],
      type: [coordinatesFormat ? coordinatesFormat.type : ""],
      format: [coordinatesFormat ? coordinatesFormat.format : ""],
    });
  }

  get projectFormGroup() {
    return this.boreholeForm.get("project") as FormGroup;
  }

  createProjectFormGroup(project: Project) {
    return this.formBuilder.group({
      id: [project ? project.id : ""],
      name: [project ? project.name : ""],
    });
  }

  searchProspectingLicences() {
    this.plStillLoading = true;
    let plNumber = this.prospectingLicenseFormGroup.controls
      .prospectingLicenseNumber.value;
    this.prospectingLicenseService
      .search(plNumber)
      .subscribe(
        (data) => {
          this.prospectingLicenses = data;
          this.filteredOptionsProspectingLicense = of(this.prospectingLicenses);
          this.plStillLoading = false;
        },
        (err) => {
          this.plStillLoading = false;
        }
      );
  }

  get locationTempFormGroup() {
    return this.boreholeForm.get("locationTemp") as FormGroup;
  }

  get villageFormGroup() {
    return this.locationTempFormGroup.get("village") as FormGroup;
  }

  get districtFormGroup() {
    return this.villageFormGroup.get("district") as FormGroup;
  }

  createLocationTempFormGroup(locationTemp: LocationTemp) {
    return this.formBuilder.group({
      id: [locationTemp ? locationTemp.id : ""],
      coordinates: this.createCoordinatesFormGroup(
        locationTemp ? locationTemp.coordinates : null
      ),
      village: this.createVillageFormGroup(
        locationTemp ? locationTemp.village : null
      ),
    });
  }

  createVillageFormGroup(village: Village): any {
    return this.formBuilder.group({
      id: [village ? village.id : ""],
      name: [village ? village.name : ""],
      district: this.createDistrictFormGroup(village ? village.district : null),
    });
  }

  createDistrictFormGroup(district: District): any {
    return this.formBuilder.group({
      id: [district ? district.id : ""],
      name: [district ? district.name : ""],
    });
  }

  get coordinatesFormGroup() {
    return this.locationTempFormGroup.get("coordinates") as FormGroup;
  }

  createCoordinatesFormGroup(coordinates: Coordinates): any {
    return this.formBuilder.group({
      id: [coordinates ? coordinates.id : ""],
      latitude: [coordinates ? coordinates.latitude : ""],
      longitude: [coordinates ? coordinates.longitude : ""],
    });
  }

  get locationDescriptionFormGroup() {
    return this.boreholeForm.get("locationDescription") as FormGroup;
  }

  createNoteFormGroup(locationDescription: Notes): any {
    return this.formBuilder.group({
      id: [locationDescription ? locationDescription.id : ""],
      description: [locationDescription ? locationDescription.description : ""],
    });
  }

  get rigTypeFormGroup() {
    return this.boreholeForm.get("rigType") as FormGroup;
  }

  createRigTypeFormGroup(rigType: RigType) {
    return this.formBuilder.group({
      id: [rigType ? rigType.id : ""],
      name: [rigType ? rigType.name : ""],
    });
  }

  onDrillingMethodSelected(drillingMethod: DrillingMethod) {
    this.drillingMethodFormGroup.get("id").setValue(drillingMethod.id);
  }

  get drillingMethodFormGroup() {
    return this.boreholeForm.get("drillingMethod") as FormGroup;
  }

  createDrillingMethodFormGroup(drillingMethod: DrillingMethod) {
    return this.formBuilder.group({
      id: [drillingMethod ? drillingMethod.id : ""],
      name: [drillingMethod ? drillingMethod.name : ""],
    });
  }

  get boreholeTypeFormGroup() {
    return this.boreholeForm.get("boreholeType") as FormGroup;
  }

  createBoreholeTypeFormGroup(boreholeType: BoreholeType) {
    if (this.boreholeEnumType) {
      this.boreholeTypeEditable = false;
    }

    if (!boreholeType) {
      boreholeType = {
        id: null,
        name: this.boreholeEnumType,
        description: null,
      };
    }

    if (!boreholeType.name) {
      boreholeType.name = this.boreholeEnumType;
    }

    return this.formBuilder.group({
      id: [
        { value: boreholeType.id, disabled: this.boreholeTypeEditable },
        Validators.required,
      ],
      name: [
        { value: boreholeType.name, disabled: this.boreholeTypeEditable },
        Validators.required,
      ],
    });
  }

  createSizeFormGroup(size: Size) {
    return this.formBuilder.group({
      id: [size ? size.id : ""],
      measure: [size ? size.measure : ""],
      unit: this.createUnitFormGroup(size ? size.unit : null),
    });
  }

  createUnitFormGroup(arg0: Unit): any {
    return this.formBuilder.group({
      id: [arg0 ? arg0.id : ""],
      unit: [arg0 ? arg0.unit : ""],
      description: [arg0 ? arg0.description : ""],
    });
  }

  get sampleTypesFormArray() {
    return this.boreholeForm.get("sampleTypes") as FormArray;
  }

  createSampleTypeFormArray(sampleTypes: SampleType[]) {
    const formControl = this.formBuilder.control([]);

    if (sampleTypes) {
      formControl.setValue(sampleTypes);
    }
    return formControl;
  }

  prospectingLicenseSelected(prospectingLicense) {
    this.prospectingLicenseFormGroup.get("id").setValue(prospectingLicense.id);
    
    this.boreholeForm.get("ownerType").setValue(0);

    this.boreholeOwnerStillLoading = false;

    this.prospectingLicenseService
      .getPLById(prospectingLicense.id)
      .subscribe((pl: ProspectingLicense) => {
        this.boreholeForm.get("ownerName").setValue(pl.company.name);
        this.boreholeOwnerFormGroup.get("id").setValue(pl.company.id);

        this.boreholeOwnerFormGroup.get("name").setValue(pl.company.name);

        let ids = pl.projectMembers.map((m) => m.memberId);

        // Find person ids of different roles withing the project
        let coordinatorIds = pl.projectMembers
          .filter((m) => m.role.toLowerCase() === "project coodinator")
          .map((m: any) => m.memberId);

        let managerIds = pl.projectMembers
          .filter((m) => m.role.toLowerCase() === "manager")
          .map((m: any) => m.memberId);

        let geologistIds = pl.projectMembers
          .filter((m) => m.role.toLowerCase() === "geologist")
          .map((m: any) => m.memberId);

        // Get people linked to the prospecting license
        if (ids && ids.length > 0) {
          this.peopleSrv.getPeopleByIdList(ids).subscribe(
            (res) => {
              
              this.personMembers = res;
              let reduced = this.personMembers.map((p: any) => {
                return {
                  id: p.id,
                  fullName: `${p.name} ${
                    p.middleName ? p.middleName + " " : ""
                  }${p.surname}`,
                };
              });

              this.projectManagers = reduced.filter((p) =>
                managerIds.includes(p.id)
              );
              this.filteredOptionsManager = of(this.projectManagers);

              this.projectCoordinators = reduced.filter((p) =>
                coordinatorIds.includes(p.id)
              );
              this.filteredOptionsCoordinator = of(this.projectCoordinators);

              this.projectGeologists = reduced.filter((p) =>
                geologistIds.includes(p.id)
              );
              this.filteredOptionsGeologist = of(this.projectGeologists);

              this.projectMemberStillLoading = false;
            },
            (err) => {
              this.toast.error(err.message, "Project People Fail");
            }
          );
        }
      });
  }

  onFocusOutPL() {
    let plNumber = this.prospectingLicenseFormGroup.get(
      "prospectingLicenseNumber"
    ).value;

    if (plNumber && plNumber !== "") {
      let pl = this.prospectingLicenses.find(
        (p) => p.prospectingLicenseNumber === plNumber
      );

      if (pl) {
        this.prospectingLicenseFormGroup
          .get("prospectingLicenseNumber")
          .setErrors(null);
      } else {
        this.prospectingLicenseFormGroup
          .get("prospectingLicenseNumber")
          .setErrors({ exist: true });
      }
    }
  }

  getAllProspectingLicenses() {
    this.prospectingLicenseService.getAllPLsLite().subscribe((data) => {
      
      this.prospectingLicenses = data as ProspectingLicense[];
      this.filteredOptionsProspectingLicense = of(data as ProspectingLicense[]);

      this.plStillLoading = false;
    });
  }

  private _filterProspectingLicense(
    prospectingLicenseNumber: string
  ): ProspectingLicense[] {
    const filterValue = prospectingLicenseNumber.toLowerCase();
    return this.prospectingLicenses.filter(
      (option) =>
        option.prospectingLicenseNumber.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onSampleTypeSelected(event: MatAutocompleteSelectedEvent) {
    if (!event.option) {
      return;
    }

    let sampleType = event.option.value;
    let types = this.sampleTypesFormArray.value as SampleType[];

    let filtered = types.filter((type) => type.id == sampleType.id);

    if (filtered.length > 0) {
      return;
    }

    types.push(sampleType);

    this.sampleTypesFilterInput.setValue("");
  }

  filterSampleTypes(name: string) {
    const filterValue = name.toLowerCase();

    this.filteredSampleTypes = of(
      this.sampleTypes.filter(
        (option) => option.name.toLowerCase().indexOf(filterValue) === 0
      )
    );
  }

  addSelectedSampleType($event: MatChipInputEvent) {}

  removeSampleType(index: number) {
    let sampleType: any[] = this.sampleTypesFormArray.value;
    sampleType.splice(index, 1);
  }

  createSampleTypeFormGroup(sampleType: SampleType) {
    return this.formBuilder.group({
      id: [sampleType ? sampleType.id : ""],
      name: [sampleType ? sampleType.name : ""],
    });
  }

  get boreholeLocationImagesFormArray() {
    return this.boreholeForm.get("boreholeLocationImages") as FormArray;
  }

  createNigisImagesFormArray(nigisImages: NigisImages[]) {
    const formArray = this.formBuilder.array([]);

    if (nigisImages) {
      nigisImages.forEach((nigisImage) => {
        formArray.push(this.createNigisImagesFormGroup(nigisImage));
      });
    }
    return formArray;
  }

  createNigisImagesFormGroup(nigisImages: NigisImages) {
    return this.formBuilder.group({
      id: [nigisImages ? nigisImages.id : ""],
      name: [nigisImages ? nigisImages.name : ""],
      type: [nigisImages ? nigisImages.type : ""],
      image: [nigisImages ? nigisImages.image : ""],
    });
  }

  get boreholeOwnerFormGroup() {
    return this.boreholeForm.get("boreholeOwner") as FormGroup;
  }

  createCompanyFormGroup(company: Company) {
    return this.formBuilder.group({
      id: [company ? company.id : ""],
      name: [company ? company.name : ""],
    });
  }

  get prospectingLicenseFormGroup() {
    return this.boreholeForm.get("prospectingLicense") as FormGroup;
  }

  createProspectingLicenseFormGroup(prospectingLicense: ProspectingLicense) {
    return this.formBuilder.group({
      id: [prospectingLicense ? prospectingLicense.id : ""],
      prospectingLicenseNumber: [
        prospectingLicense ? prospectingLicense.prospectingLicenseNumber : "",
      ],
    });
  }

  checkCoordinatesValidity() {
    switch (this.selectedCoordinatesFormat) {
      case this.coordinatesFormatsEnum.DMS:
        this.coordinatesValid =
          this.dmsSx.value != null &&
          this.dmsMx.value != null &&
          this.dmsDx.value != null &&
          this.dmsSy.value != null &&
          this.dmsMy.value != null &&
          this.dmsDy.value != null;
        break;
      case this.coordinatesFormatsEnum.DD:
        this.coordinatesValid =
          this.coordinatesFormGroup.controls.longitude.value != null &&
          this.coordinatesFormGroup.controls.latitude.value != null;
        break;
      case this.coordinatesFormatsEnum.ZONE34S:
      case this.coordinatesFormatsEnum.ZONE35S:
        this.coordinatesValid =
          this.zoneSNorthing.value != null && this.zoneSEasting.value != null;
        break;
    }
  }

  getDrillingRequestBoreholeById() {
    let borehole = this.boreholeForm.controls.drillingBoreholeNumber.value;
    this.drillerService
      .getDrillingRequestBoreholeById(borehole.id)
      .subscribe((data) => {
        this.drilingRequestBorehole = data as DrillingRequestBorehole;

        this.boreholeForm.controls.startDate.setValue(
          new Date(this.drilingRequestBorehole.drillingDate)
        );
        this.boreholeForm.controls.coordinatesType.setValue(
          this.drilingRequestBorehole.coordinatesFormat.type
        );
        this.boreholeForm.controls.coordinatesFormat.setValue(
          this.drilingRequestBorehole.coordinatesFormat.format
        );
        this.coordinatesFormGroup.controls.latitude.setValue(
          this.drilingRequestBorehole.locationTemp.coordinates.latitude
        );
        this.coordinatesFormGroup.controls.longitude.setValue(
          this.drilingRequestBorehole.locationTemp.coordinates.longitude
        );
        this.districtFormGroup.setValue(
          this.drilingRequestBorehole.locationTemp.village.district
        );
        this.villageFormGroup.setValue(
          this.drilingRequestBorehole.locationTemp.village
        );
        this.boreholeForm.controls.comments.setValue(
          this.drilingRequestBorehole.comments.description
        );

        this.boreholeForm.controls.boreholeType.setValue(
          this.drilingRequestBorehole.boreholeType
        );
        this.boreholeForm.controls.holeDiameter.setValue(
          this.drilingRequestBorehole.holeDiameter.measure
        );
        this.boreholeForm.controls.drillingMethod.setValue(
          this.drilingRequestBorehole.drillingMethod
        );
        this.boreholeForm.controls.sampleTypes.setValue(
          this.drilingRequestBorehole.sampleTypes
        );
        this.boreholeForm.controls.plannedDepth.setValue(
          this.drilingRequestBorehole.plannedDepth.measure
        );
        this.boreholeForm.controls.boreholeNumber.setValue(
          this.drilingRequestBorehole.boreholeNumber
        );
      });
  }

  onOwnerChange(owner) {
    if (owner === null) {
      return;
    }

    if (this.boreholeForm.controls.ownerType.value === 0) {
      this.boreholeOwnerFormGroup.get("id").setValue(owner.id);
      this.boreholeOwnerFormGroup.get("name").setValue(owner.name);
    } else if (this.boreholeForm.controls.ownerType.value === 1) {
      this.boreholeForm.get("boreholeOwnerIndividual").setValue(owner.id);
    }

    this.boreholeForm.get("ownerName").setValue(owner.name);
  }

  searchBoreholeOwners() {
    this.boreholeOwnerStillLoading = true;
    let ownerType = this.boreholeForm.controls.ownerType.value;
    let ownerName = this.boreholeForm.controls.ownerName.value;

    if(ownerType === 0) {
      this.companyService.searchCompanies(ownerName).subscribe((data: Company[]) => {
        this.boreholeOwners = data;
        this.owners = data.map((owner) => {
          return {
            id: owner.id,
            name: owner.name,
          };
        });

        this.filteredBoreholeOwners = of(this.owners);
        this.boreholeOwnerStillLoading = false;
      }, (error) => {
        this.boreholeOwnerStillLoading = false;
      
      });
    } else if(ownerType === 1) {
      this.userService.searchPersons(ownerName).subscribe((data: Person[]) => {
        this.boreholeIndividualOwners = data;
        this.owners = data.map((owner) => {
          return {
            id: owner.id,
            name: `${owner.name} ${owner.middleName ? ' ' + owner.middleName + ' ' : ""} ${owner.surname}`,
          };
        });

        this.filteredBoreholeOwners = of(this.owners);
        this.boreholeOwnerStillLoading = false;
      }, (error) => {
        this.boreholeOwnerStillLoading = false;
      
      });
    }

    this.boreholeOwnerFormGroup.get("id").setValue(null);
    this.boreholeOwnerFormGroup.get("name").setValue(null);
    this.boreholeForm.controls.boreholeOwnerIndividual.setValue(null);
    this.boreholeForm.controls.ownerName.setValue(null);
  }

  newPersonnel() {
    const newPersonnelDialogRef = this.dialog.open(PersonnelCreateComponent, <
      MatDialogConfig
    >{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
    });

    newPersonnelDialogRef.afterClosed().subscribe((result) => {
      this._getProjectMembers();
    });
  }

  getCompanies() {
    this.companyService.getAllCompanies().subscribe(
      (value: Company[]) => {
        this.allCompanies = value as Company[];

        if (this.borehole.ownerType === 0) {
          this.boreholeOwners = value;

          this.owners = value.map((owner) => {
            return {
              id: owner.id,
              name: owner.name,
            };
          });

          this.boreholeForm
            .get("ownerName")
            .setValue(this.borehole.boreholeOwner.name);

          this.filteredBoreholeOwners = of(this.owners);
          this.boreholeOwnerStillLoading = false;
        }
      },
      (error) => {},
      () => {
        this.getAllPersons();
      }
    );
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe((data) => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(
            new LatLon(
              this.latLonArray.coordinates[i][j][1],
              this.latLonArray.coordinates[i][j][0]
            )
          );
        }
      }
    });
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
   
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };

    this.resetRequiredValue();
    this.selectedCoordinatesType = selectedData.text;
    this.borehole.coordinatesFormat.type = this.selectedCoordinatesType;
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
    }

    this.borehole.coordinatesFormat.format = this.selectedCoordinatesFormat;
    this.coordinatesFormatFormGroup
      .get("format")
      .setValue(this.selectedCoordinatesFormat);
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo
      ? variableOne.id === variableTwo.id
      : variableOne === variableTwo;
  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim(),
    };

    this.setSelectedCoordinatesFormat(selectedData.text);

  }

  private setSelectedCoordinatesFormat(selectedValue: string) {
    this.resetRequiredValue();
    this.selectedCoordinatesFormat = selectedValue;
    switch (this.selectedCoordinatesFormat) {
      case this.coordinatesFormatsEnum.DMS:
        this.dmsSx.setValidators(Validators.required);
        this.dmsMx.setValidators(Validators.required);
        this.dmsDx.setValidators(Validators.required);
        this.dmsSy.setValidators(Validators.required);
        this.dmsMy.setValidators(Validators.required);
        this.dmsDy.setValidators(Validators.required);
        break;
      case this.coordinatesFormatsEnum.DD:
        this.coordinatesFormGroup.controls.longitude.setValidators(
          Validators.required
        );
        this.coordinatesFormGroup.controls.latitude.setValidators(
          Validators.required
        );
        break;
      case this.coordinatesFormatsEnum.ZONE34S:
      case this.coordinatesFormatsEnum.ZONE35S:
        this.zoneSNorthing.setValidators(Validators.required);
        this.zoneSEasting.setValidators(Validators.required);
        break;
    }
    this.borehole.coordinatesFormat.format = this.selectedCoordinatesFormat;
  }

  resetRequiredValue() {
    this.dmsSx.clearValidators();
    this.dmsSx.setErrors(null);
    this.dmsSx.updateValueAndValidity();

    this.dmsMx.clearValidators();
    this.dmsMx.setErrors(null);
    this.dmsMx.updateValueAndValidity();

    this.dmsDx.clearValidators();
    this.dmsDx.setErrors(null);
    this.dmsDx.updateValueAndValidity();

    this.dmsSy.clearValidators();
    this.dmsSy.setErrors(null);
    this.dmsSy.updateValueAndValidity();

    this.dmsMy.clearValidators();
    this.dmsMy.setErrors(null);
    this.dmsMy.updateValueAndValidity();

    this.dmsDy.clearValidators();
    this.dmsDy.setErrors(null);
    this.dmsDy.updateValueAndValidity();

    this.coordinatesFormGroup.controls.longitude.clearValidators();
    this.coordinatesFormGroup.controls.longitude.setErrors(null);
    this.coordinatesFormGroup.controls.longitude.updateValueAndValidity();

    this.coordinatesFormGroup.controls.latitude.clearValidators();
    this.coordinatesFormGroup.controls.latitude.setErrors(null);
    this.coordinatesFormGroup.controls.latitude.updateValueAndValidity();

    this.zoneSNorthing.clearValidators();
    this.zoneSNorthing.setErrors(null);
    this.zoneSNorthing.updateValueAndValidity();

    this.zoneSEasting.clearValidators();
    this.zoneSEasting.setErrors(null);
    this.zoneSEasting.updateValueAndValidity();
  }

  getAllCoordinatesTypes() {
    this.geoTechService.getAllCoordinatesTypes().subscribe((response) => {
      this.coordinatesTypes = response as CoordinatesType[];
    });
  }

  searchDistricts() {
    this.districtStillLoading = true;
    let districtName = this.districtFormGroup.get("name").value;

    this.commonService.searchDistricts(districtName).subscribe((data) => {
      this.districts = data as District[];
      this.filteredOptionsDistrict = of(this.districts);
      this.districtStillLoading = false;
    }, (error) => {
      this.districtStillLoading = false;
    });
  }

  searchVillages() {
    this.villageStillLoading = true;
    let villageName = this.villageFormGroup.get("name").value;
    let districtId = this.districtFormGroup.get("id").value;

    if(!districtId || districtId === '') {

      this.commonService.searchVillages(villageName).subscribe((data) => {
        this.villages = data as Village[];
        this.filteredOptionsVillage = of(this.villages);
        this.villageStillLoading = false;
      }, (error) => {
        this.villageStillLoading = false;
      });

    } else {

      this.commonService.searchVillageByDistrict(districtId, villageName).subscribe((data) => {
        this.villages = data as Village[];
        this.filteredOptionsVillage = of(this.villages);
        this.villageStillLoading = false;
      }, (error) => {
        this.villageStillLoading = false;
      });

    }
  }

  getAllDistricts() {
    this.districtStillLoading = true;
    this.commonService.getAllDistricts().subscribe((data) => {
      this.districts = data as District[];
      this.districts.sort((a, b) =>
        (a.name || "").toString().localeCompare((b.name || "").toString())
      );

      this.filteredOptionsDistrict = of(this.districts);
      this.districtStillLoading = false;
    });
  }

  // getAllVillages() {
  //   this.villageStillLoading = true;
  //   this.commonService.getAllVillages().subscribe((data) => {
  //     this.villages = data as Village[];
  //     this.villages.sort((a, b) =>
  //       (a.name || "").toString().localeCompare((b.name || "").toString())
  //     );

  //     this.filteredOptionsVillage = of(this.villages);
  //     this.villageStillLoading = false;
  //   });
  // }

  onDistrictChange(district: District) {
    this.villageFormGroup.get("id").patchValue("");
    this.villageFormGroup.get("name").patchValue("");

    this.districtFormGroup.get("id").patchValue(district.id);
    this.districtFormGroup.get("name").patchValue(district.name);

    this.searchVillages();
  }

  onVillageChange(village: Village) {
    this.villageFormGroup.get("id").patchValue(village.id);
    this.villageFormGroup.get("name").patchValue(village.name);

    this.districtFormGroup.get("id").patchValue(village.district.id);
    this.districtFormGroup.get("name").patchValue(village.district.name);
  }

  getAllSampleTypes() {
    this.drillerService.getAllSampleTypes().subscribe((data) => {
      this.sampleTypes = data as SampleType[];
      this.filteredSampleTypes = of(this.sampleTypes);
      this.sampleTypeStillLoading = false;
    });
  }

  private getProjectsByMember(user: string) {

    let company = ''

    if(this.type === 'BGI') {
      company = 'Botswana Geoscience Institute';
    }

    this.projectService.getProjectsByMember(user, company).subscribe(
      (data) => {

        let tmp = data;

        this.projects = data;
        this.filteredOptionsProject = of(this.projects);
        this.projectStillLoading = false;
      },
      (err) => {}
    );
  }

  boreholeSelected(borehole: DrillingRequestBorehole) {

    if(borehole.boreholeType){
      this.boreholeForm.setControl("boreholeType", this.createBoreholeTypeFormGroup(borehole.boreholeType));
    }
    
    if(borehole.comments) {
      this.boreholeForm.setControl("comments", this.createNoteFormGroup(borehole.comments));
    }

    if(borehole.coordinatesFormat) {

      this.coordinatesFormatFormGroup.get("id").setValue(borehole.coordinatesFormat.id);
      this.coordinatesFormatFormGroup.get("type").setValue(borehole.coordinatesFormat.type);
      this.coordinatesFormatFormGroup.get("format").setValue(borehole.coordinatesFormat.format);

      this.setSelectedCoordinatesFormat(borehole.coordinatesFormat.format);
    }

    if(borehole.drillingMethod) {
      this.boreholeForm.setControl("drillingMethod", this.createDrillingMethodFormGroup(borehole.drillingMethod));
    }

    if(borehole.holeDiameter) {
      this.boreholeForm.setControl("holeDiameter", this.createSizeFormGroup(borehole.holeDiameter));
    }

    if(borehole.locationTemp) {
      this.boreholeForm.setControl("locationTemp", this.createLocationTempFormGroup(borehole.locationTemp));
    }

    if(borehole.plannedDepth) {
      this.boreholeForm.setControl("plannedDepth", this.createSizeFormGroup(borehole.plannedDepth));
    }

    if(borehole.sampleTypes) {
      this.boreholeForm.setControl("sampleTypes", this.createSampleTypeFormArray(borehole.sampleTypes));
    }

    if(borehole.holeDiameterString) {
      this.boreholeForm.get('holeDiameterString').setValue(borehole.holeDiameterString);
    }

    if(borehole.drillingDate) {
      this.boreholeForm.get('startDate').setValue(new Date(borehole.drillingDate));
    }
  }

  projectSelected(project) {
    this.drillingRequests = [];
    this.drilingRequestBoreholes = [];
    this.filteredDrillingRequestBoreholes = of(this.drilingRequestBoreholes);

    if (project) {
      this.projectFormGroup.get("id").patchValue(project.id);

      this.projectService.getProjectById(project.id).subscribe((data) => {
        this.selectedProject = data as Project;
      });

      this._getProjectMembers();
    }

    this.boreholeStillLoading = true;
    this.drillerService
      .getDrillingRequestsByProjectId(project.id)
      .subscribe((data) => {
        this.drillingRequests = (data as DrillingRequest[]);
        
        if (this.drillingRequests.length > 0) {
          this.drillingRequests.forEach((drillingRequest) => {
            if (
              drillingRequest.currentState.currentStage ===
                "APPROVED_BY_MANAGER" ||
              drillingRequest.currentState.currentStage === "OUTSOURCED"
            ) {
              this.drillerService
                .getBoreholesByDrillingRequestId(drillingRequest.id)
                .subscribe((value) => {

                  let theDrillingRequestsBoreholes =
                    value as DrillingRequestBorehole[];
                  if (theDrillingRequestsBoreholes.length > 0) {
                    this.drilingRequestBoreholes =
                      this.drilingRequestBoreholes.concat(
                        theDrillingRequestsBoreholes
                      );

                    this.drilingRequestBoreholes =
                      this.drilingRequestBoreholes.filter(
                        (item) =>
                          item.boreholeType.name === this.boreholeEnumType
                      );

                    this.filteredDrillingRequestBoreholes = of(this.drilingRequestBoreholes);
                  }

                  this.getAllBoreholeTypesPreSelectExploration();
                }, (error) => {
                
                }, () => {
                  this.boreholeStillLoading = false;
                });
            } else {
              this.drilingRequestBoreholes = []
              this.filteredDrillingRequestBoreholes = of(this.drilingRequestBoreholes);
            }
          });
        } else {
          this.drilingRequestBoreholes = []
          this.filteredDrillingRequestBoreholes = of(this.drilingRequestBoreholes);
        }
      }, (error) => {},
      () => {
        this.boreholeStillLoading = false;
      });
  }

  getAllRigTypes() {
    this.geoTechService.getAllRigTypes().subscribe((data) => {
      this.rigTypes = data as RigType[];
      this.rigTypes.sort((a, b) =>
        (a.name || "").toString().localeCompare((b.name || "").toString())
      );
      this.filteredOptionsRigType = of(this.rigTypes);
      this.drillingRigTypeStillLoading = false;
    });
  }

  getAllBoreholeTypes() {
    this.geoTechService.getAllBoreholeTypes().subscribe((data) => {
      
      this.boreholeTypes = of(data as BoreholeType[]);
      for (let i = 0; i < (data as BoreholeType[]).length; i++) {
        if (this.boreholeTypes[i].name === this.borehole.boreholeType.name) {
          this.borehole.boreholeType = data[i];
        }
      }
    });
  }

  getAllBoreholeTypesPreSelectExploration() {
    this.geoTechService.getAllBoreholeTypes().subscribe((data) => {
      this.boreholeTypes = of(data as BoreholeType[]);

      if (this.drilingRequestBoreholes || this.drilingRequestBoreholes.length == 0) {

        let types = (data as BoreholeType[]).filter(
          (t) => t.name === this.boreholeEnumType
        );
  
        if (types.length > 0) {
          
          this.boreholeTypeFormGroup.get("id").setValue(types[0].id);
        }

      }
      this.boreholeTypeStillLoading = false;
    });
  }

  onBoreholeTypeChange(borehole) {
    if (!this.boreholeTypeEditable) {
      return;
    }

    this.boreholeTypeFormGroup.get("id").setValue(borehole.id);
  }

  getAllPersons() {
    this.userService.getPersons().subscribe((data: any[]) => {
      if (this.borehole.ownerType === 1) {
        this.boreholeIndividualOwners = data;

        this.owners = data.map((owner) => {
          return {
            id: owner.id,
            name: owner.fullName,
          };
        });

        this.boreholeForm
          .get("ownerName")
          .setValue(
            this.owners.filter(
              (o) => o.id === this.borehole.boreholeOwnerIndividual
            )[0].name
          );

        this.filteredBoreholeOwners = of(this.owners);
        this.boreholeOwnerStillLoading = false;
      }

      this.projectGeologists = this.personnelSortPipe.transformLite(
        this.allCompanies,
        data as Person[]
      );

      this.filteredOptionsGeologist = of(this.projectGeologists);

      this.projectManagers = this.personnelSortPipe.transformLite(
        this.allCompanies,
        data as Person[]
      );

      this.filteredOptionsManager = of(this.projectManagers);

      this.projectCoordinators = this.personnelSortPipe.transformLite(
        this.allCompanies,
        data as Person[]
      );

      this.filteredOptionsCoordinator = of(this.projectCoordinators);
      this.projectMemberStillLoading = false;
    });
  }

  getAllDrillingMethods() {
    this.drillerService.getAllDrillingMethods().subscribe((data) => {
      this.drillingMethods = data as DrillingMethod[];
      this.drillingMethodStillLoading = false;
    });
  }

  getBoreholeById() {
    this.geoTechService.getBoreholeById(this.borehole.id).subscribe((data) => {
      this.borehole = data as Borehole;
    });
  }

  onManagerChange($event) {
    this.boreholeForm.controls.projectManager.setValue($event.value.id);
  }

  onCoordinatorChange($event) {
    this.boreholeForm.controls.projectCoordinator.setValue($event.value.id);
  }

  onGeologistChange($event) {
    this.boreholeForm.controls.projectGeologist.setValue($event.value.id);
  }


  onFocusOutOwner() {
    
    let ownerId = this.boreholeOwnerFormGroup.get("id").value;

    if (ownerId && ownerId !== "") {
      this.boreholeOwnerFormGroup.setErrors(null);
    } else {
      let ownerName = this.boreholeForm.controls.ownerName.value;

      // Determine which error gets displayed
      if (ownerName && ownerName !== "") {
        this.boreholeOwnerFormGroup.setErrors({ required: this.visibleFields.boreholeOwner });
      } else {
        this.boreholeOwnerFormGroup.setErrors({ exist: true });
      }
      this.boreholeForm.controls.ownerName.setErrors({ exist: true, required: this.visibleFields.boreholeOwner });
    }

  }

  onFocusOutProject() {
    let project = this.boreholeForm.controls.project.value;
    if (project && project !== "") {

      let prj = this.projectFormGroup.value;

      if (prj.id && prj.id !== "") {
        this.boreholeForm.get("project").setErrors(null);
      } else {
        this.boreholeForm.get("project").setErrors({ exist: true });
      }
    }
  }

  onFocusOutDistrict() {
    let district = this.districtFormGroup.value;
      let isPresent: boolean = district && district.id && district.id !== ""
      if (isPresent === true) {
        this.boreholeForm.get("district").setErrors(null);
      } else {
        this.boreholeForm.get("district").setErrors({ exist: true });
      }
  }

  onFocusOutVillage() {

    let village = this.villageFormGroup.value;
    let isPresent: boolean;
    isPresent = village && village.id && village.id !== "";

    if (isPresent === true) {
      this.boreholeForm.get("village").setErrors(null);
    } else {
      this.boreholeForm.get("village").setErrors({ exist: true });
    }
  }

  onFocusOutRigType() {
    let drillingRigType = this.boreholeForm.controls.drillRigType.value;
    if (drillingRigType && drillingRigType !== "") {
      let isPresent: boolean;
      isPresent = this.rigTypes.some(function (ds) {
        if (drillingRigType.name) {
          return ds.name === drillingRigType.name;
        } else {
          return false;
        }
      });
      if (isPresent === true) {
        this.boreholeForm.get("drillRigType").setErrors(null);
      } else {
        this.boreholeForm.get("drillRigType").setErrors({ exist: true });
      }
    }
  }

  boreholeOwnerSelected(owner: any) {}

  projectUserSelected(userType: string, user: any) {
    
    let formControlName = this.boreholeForm.controls.projectManager;
    let displayControl = this.currentManager;

    if (userType === "coordinator") {
      formControlName = this.boreholeForm.controls.projectCoordinator;
      displayControl = this.currentCoordinator;
    } else if (userType === "geologist") {
      formControlName = this.boreholeForm.controls.projectGeologist;
      displayControl = this.currentGeologist;
    }

    formControlName.setValue(user && user.id ? user.id : "");
    displayControl.setValue(user && user.fullName ? user.fullName : "");
  }

  onFocusOutManager() {
    let projectManager = this.boreholeForm.controls.projectManager.value;
    let isPresent: boolean = projectManager && projectManager !== ""

    if (isPresent) {
      this.boreholeForm.get("projectManager").setErrors(null);
    } else {

      let cm = this.currentManager.value;

      if(cm && cm !== "") {
        this.boreholeForm.get("projectManager").setErrors({ exist: true });
        this.currentManager.setErrors({ exist: true });
      }
    }
  }

  onFocusOutCoordinator() {
    let projectCoordinator =
      this.boreholeForm.controls.projectCoordinator.value;
    let isPresent: boolean = projectCoordinator && projectCoordinator !== ""
      
    if (isPresent === true) {
      this.boreholeForm.get("projectCoordinator").setErrors(null);
    } else {
      let cp = this.currentCoordinator.value;
      if(cp && cp !== "") {
        this.boreholeForm.get("projectCoordinator").setErrors({ exist: true });
        this.currentCoordinator.setErrors({ exist: true });
      }
    }
    
  }

  onFocusOutGeologist() {
    let projectGeologist = this.boreholeForm.controls.projectGeologist.value;
    if (projectGeologist && projectGeologist !== "") {
      this.boreholeForm.get("projectGeologist").setErrors(null);
    } else {

      let cg = this.currentGeologist.value;
      if(cg && cg !== "") {
        this.boreholeForm.get("projectGeologist").setErrors({ exist: true });
        this.currentGeologist.setErrors({ exist: true });
      }
    }
  }

  calculateVariance() {
    if (this.borehole.plannedDepth && this.borehole.endDepth) {
      let plannedDepth: Size = this.boreholeForm.controls.plannedDepth.value;
      let endDepth: Size = this.boreholeForm.controls.endDepth.value;

      const variance = Math.abs(
        Number(plannedDepth.measure) - Number(endDepth.measure)
      );
      this.boreholeForm.controls.variance.setValue(variance);
    }
  }

  goTo(filter: string) {
    this._router.navigate([`portal/personnel/${filter}`]);
  }

  validateBoreholeDrillingDates() {
    if (
      !this.boreholeValidation.boreholeDrillingDatesvalid(
        this.boreholeForm.controls.startDate.value,
        this.boreholeForm.controls.completedDate.value
      )
    ) {
      this.boreholeForm.controls.completedDate.setErrors({
        invalid: true,
      });
    }
  }

  validateAzimuth() {
    if (
      !this.boreholeValidation.validateAzimuth(
        this.boreholeForm.controls.azimuth.value
      )
    ) {
      this.boreholeForm.controls.azimuth.setErrors({
        invalid: true,
      });
    }
  }

  validateInclination() {
    if (
      !this.boreholeValidation.validateInclination(
        this.boreholeForm.controls.holeInclination.value
      )
    ) {
      this.boreholeForm.controls.holeInclination.setErrors({
        invalid: true,
      });
    }
  }

  convertDmsToLatLon() {
    let coordinates = this.coordinatesService.convertDmsToLatLon(
      this.dmsDy.value,
      this.dmsMy.value,
      this.dmsSy.value,
      this.dmsDx.value,
      this.dmsMx.value,
      this.dmsSx.value
    );

    this.coordinatesFormGroup.controls.latitude.setValue(coordinates._lat);
    this.coordinatesFormGroup.controls.longitude.setValue(coordinates._lon);
  }

  convertUtmToLatLon() {
    let format =
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
        ? "34 S"
        : "35 S";
    let coordinates = this.coordinatesService.convertUtmToLatLon(
      format,
      this.zoneSNorthing.value,
      this.zoneSEasting.value
    );

    this.coordinatesFormGroup.controls.latitude.setValue(coordinates._lat);
    this.coordinatesFormGroup.controls.longitude.setValue(coordinates._lon);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(",");
          let boreholeLocationImage = new NigisImages();
          boreholeLocationImage.image = fileSplitdata[1];
          boreholeLocationImage.name = fileName;
          this.boreholeLocationImagesFormArray.push(
            this.createNigisImagesFormGroup(boreholeLocationImage)
          );
        };
      }
    }
  }

  remove(index: number): void {
    this.boreholeLocationImagesFormArray.removeAt(index);
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const chipBoreholeLocationImage = new NigisImages();
      chipBoreholeLocationImage.image = value;

      if (input) {
        input.value = "";
      }

      this.boreholeLocationImageCtrl.setValue(null);
    }
  }

  onFocusOut() {
    let boreholeNumber = this.boreholeForm.controls.boreholeNumber.value;
    if (boreholeNumber && boreholeNumber.trim() !== "") {
      let project = this.boreholeForm.controls.project.value;
      if (this.boreholeForm.controls.project.value) {
        let project = this.boreholeForm.controls.project.value;
        this.geoTechService
          .boreholeNumberAlreadyInProject(boreholeNumber, project.id)
          .subscribe((res) => {
            if (res.exist === true) {
              this.boreholeForm
                .get("boreholeNumber")
                .setErrors({ exist: true });
            } else {
              this.boreholeForm.get("boreholeNumber").setErrors(null);
            }
          });
      }
    }
  }

  onFocusOutDrillingRequestBorehole() {
    let boreholeNumber =
      this.boreholeForm.controls.drillingBoreholeNumber.value.boreholeNumber;
    if (boreholeNumber && boreholeNumber.trim() !== "") {
      let project = this.boreholeForm.controls.project.value;
      if (this.boreholeForm.controls.project.value) {
        let project = this.boreholeForm.controls.project.value;
        this.geoTechService
          .boreholeNumberAlreadyInProject(boreholeNumber, project.id)
          .subscribe((res) => {
            if (res.exist === true) {
              this.boreholeForm
                .get("drillingBoreholeNumber")
                .setErrors({ exist: true });
            } else {
              this.boreholeForm.get("drillingBoreholeNumber").setErrors(null);
            }
          });
      }
    }
  }

  private _setInitialPerson(
    role: string,
    boreholePerson: string,
    control: FormControl,
    select: MatSelect
  ) {
    let person = null;

    if (this.borehole.id) {
      // Edit
      person = this.personMembers.find((pM) => pM.id === boreholePerson);
    }

    if (!person) {
      person = this.personMembers.find((pM) => this._isOfRole(pM, role));
    }

    if (person) {
      control.setValue(person.fullName);

      if (person.fullName) {
        control.setValue(person.fullName);
      } else if (person.name) {
        control.setValue(`${person.name} ${person.surname}`);
      }

      if (select) {
        select.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
      }
    }
  }

  protected _setInitialManager() {
    this._setInitialPerson(
      "Project Manager",
      this.borehole.projectManager,
      this.currentManager,
      this.managerSelect
    );
  }

  protected _setInitialCoordinator() {
    this._setInitialPerson(
      "Project Coodinator",
      this.borehole.projectCoordinator,
      this.currentCoordinator,
      this.coordinatorSelect
    );
  }

  protected _setInitialGeologist() {
    this._setInitialPerson(
      "Geologist",
      this.borehole.projectGeologist,
      this.currentGeologist,
      this.geologistSelect
    );
  }

  private _getProjectMembers() {
    this.projectMemberStillLoading = true;
    if (this.projectFormGroup.value) {
      this.projectService
        .getProjectMembers(this.projectFormGroup.get("id").value)
        .subscribe(
          (res) => {
            this.projectMembers = res as Array<ProjectMembers>;
            let ids = this.projectMembers.map((m) => m.personId);

            // Find person ids of different roles withing the project
            let coordinatorIds = this.projectMembers
              .filter((m) =>
                m.projectRoles.some(
                  (r) => r.name.toLowerCase() === "project coodinator"
                )
              )
              .map((m: any) => m.personId);
            let managerIds = this.projectMembers
              .filter((m) =>
                m.projectRoles.some(
                  (r) => r.name.toLowerCase() === "project manager"
                )
              )
              .map((m: any) => m.personId);
            let geologistIds = this.projectMembers
              .filter((m) =>
                m.projectRoles.some((r) => r.name.toLowerCase() === "geologist")
              )
              .map((m: any) => m.personId);

            if (ids.length > 0) {
              // Get people linked to the project
              this.peopleSrv.getPeopleByIdList(ids).subscribe(
                (res) => {
                  
                  this.personMembers = res;
                  let reduced = this.personMembers.map((p: any) => {
                    return {
                      id: p.id,
                      fullName: `${p.name} ${p.middleName ? p.middleName : ""}${
                        p.surname
                      }`,
                    };
                  });

                  this.projectManagers = reduced.filter((p) =>
                    managerIds.includes(p.id)
                  );
                  this.filteredOptionsManager = of(this.projectManagers);

                  this.projectCoordinators = reduced.filter((p) =>
                    coordinatorIds.includes(p.id)
                  );
                  this.filteredOptionsCoordinator = of(
                    this.projectCoordinators
                  );

                  this.projectGeologists = reduced.filter((p) =>
                    geologistIds.includes(p.id)
                  );
                  this.filteredOptionsGeologist = of(this.projectGeologists);

                  this.projectMemberStillLoading = false;
                },
                (err) => {
                  this.toast.error(err.message, "Project People Fail");
                }
              );
            }
          },
          (err) => {
            this.toast.error(err.message, "Project Member Fail");
          }
        );
    }
  }

  private getPeopleByIds(ids) {
    this.peopleSrv.getPeopleByIdList(ids).subscribe(
      (res) => {
        
        this.personMembers = res;

        this._setInitialManager();

        this.filteredOptionsManager = of(this.personMembers);

        this.filteredOptionsCoordinator = of(this.personMembers);
        this._setInitialCoordinator();

        this.filteredOptionsGeologist = of(this.personMembers);
        this._setInitialGeologist();

        this.projectMemberStillLoading = false;
      },
      (err) => {
        this.toast.error(err.message, "Project People Fail");
      }
    );
  }

  private _getProjectPeople(members: Array<ProjectMembers>) {
    let ids = members.map((m) => m.personId);

    if (ids.length <= 0) {
      this.projectMemberStillLoading = false;
      return;
    }

    this.getPeopleByIds(ids);
  }

  private _isOfRole(person: Person, role: string) {
    let member = this.projectMembers.find(
      (pM) => pM.personId === person.id && pM.deleted === false
    );
    if (!member) return false;
    let roleFound = false;
    member.projectRoles.forEach((r) => {
      if (r.name.toLowerCase() === role.toLowerCase()) {
        roleFound = true;
      }
    });
    return roleFound;
  }

  companyChanged() {
    // if (
    //   this.borehole.project &&
    //   this.borehole.project.id &&
    //   this.borehole.project.id !== ""
    // ) {
    //   this.borehole.project = this.boreholeForm.controls.project.value;
    //   this._getProjectMembers();
    // }
  }
}
