import { OutputDataListComponent } from './output-data-list/output-data-list.component';
import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { LiteratureReviewListComponent } from './literature-review/literature-review-list/literature-review-list.component';
import { AuthGuard } from './../_auth/_guards/auth.guard';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MineralResourcesComponent } from './mineral-resources.component';
import {InputDataListComponent} from './input-data-list/input-data-list.component';
import { CustomerRequestListComponent } from '../collections/views/schedule/customer-request-list/customer-request-list.component';
import { CustomerRequestDetailsComponent } from '../collections/views/schedule/customer-request-details/customer-request-details.component';
import { EventCalendarComponent } from '../collections/views/schedule/event-calendar/event-calendar.component';


const route: Routes = [
  {
    path: '', component: MineralResourcesComponent,
    canActivate: [AuthGuard],
    children: [
    {
      path: 'samples',
      loadChildren: './samples/samples.module#SamplesModule'
    },
    {
      path: 'requests',
      component: CustomerRequestListComponent
    },
    {
      path: 'requests/:id/details',
      component: CustomerRequestDetailsComponent},
    {
        path: 'literature-review/list',
        component: LiteratureReviewListComponent,
    },
    {path: 'schedule', component: EventCalendarComponent},
    {
      path: 'report/list',
      component: ReportsListComponent,
    },
    {
      path: 'input-data/list',
      component: InputDataListComponent,
    },
    {
      path: 'output-data/list',
      component: OutputDataListComponent,
    }
  ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(route)],
  exports: [RouterModule]
})
export class MineralResourcesRouting {
}
