import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanyService} from '../company.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import jsPDF from 'jspdf';
import {Company} from '../../../shared-kernel/entity/applied-geoscience/company/company';
import {CompanyCreateComponent} from '../company-create/company-create.component';
import {MatDialog, MatDialogConfig} from '@angular/material';

declare var $: any;

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})

export class CompanyDetailComponent implements OnInit {
  dialogConfig = new MatDialogConfig();
  companyDetails: Company;

  constructor(private companyService: CompanyService,
              private route: ActivatedRoute, private dialog: MatDialog,
              private toast: ToastrService,
              private router: Router) {
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '950px';
    this.dialogConfig.maxHeight = '900px';

    this.getCompanyDetails();
  }

  getCompanyDetails() {
    this.route.params.subscribe(params => {
      this.companyService.getCompanyById(params.id).subscribe(result => {
        this.companyDetails = result as Company;
      }, error => {
        this.toast.error('Failed to get company details', 'Company Details');
      });
    });
  }

  /**
   * Open company for to edit company profile */
  onEdit(company: Company) {
    this.dialogConfig.data = {
      company: company
    };

    this.dialog.open(CompanyCreateComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getCompanyDetails();
    });
  }

  exportProfile() {

    const columns = [
      {title: '', dataKey: 'column1'},
      {title: '', dataKey: 'column2'},
      {title: '', dataKey: 'column3'},
      {title: '', dataKey: 'column4'},
    ];

    const company = [
      {
        'column1': 'NAME:',
        'column2': this.companyDetails.name,
        'column3': 'TYPE:',
        'column4': this.companyDetails.companyType.name
      },
      {
        'column1': 'CATEGORY:',
        'column2': this.companyDetails.companyCategory.name,
        'column3': 'WEBSITE:',
        'column4': (this.companyDetails.website ? this.companyDetails.website : '')
      },
    ];

    if (this.companyDetails.postalAddress) {
      company.push({
        'column1': 'ADDRESS:',
        'column2': (this.companyDetails.postalAddress.boxNumber ? this.companyDetails.postalAddress.boxNumber : ''),
        'column3': 'COUNTRY:',
        'column4': (this.companyDetails.postalAddress.country.country ? this.companyDetails.postalAddress.country.country : '')
      });

      company.push({
        'column1': 'DISTRICT:',
        'column2': (this.companyDetails.postalAddress.district.name ? this.companyDetails.postalAddress.district.name : ''),
        'column3': '',
        'column4': ''
      });
    }

    const doc = new jsPDF('p', 'pt', 'a4');

    doc.setFontSize(8);
    doc.setFont('courier');
    doc.setFontType('normal');
    doc.text(20, 20, 'Botswana Geoscience Institute');

    doc.setLineWidth(2);
    doc.line(20, 30, 100, 30);

    doc.setFontSize(16);
    doc.setFont('courier');
    doc.setFontType('normal');
    doc.text(20, 50, this.companyDetails.companyType.name + ' Profile');

    doc.autoTable(columns, company, {
      styles: {fontSize: 10},
      theme: 'striped',
      head: [[{content: 'Company Basic Information', colSpan: 4, styles: {halign: 'left', fillColor: [70, 188, 204]}}]],
      columnStyles: {
        column1: {
          textColor: [15, 15, 15], fontStyle: 'bold'
        },
        column3: {
          textColor: [15, 15, 15], fontStyle: 'bold'
        }
      },
      margin: {top: 70},
    });

    if (this.companyDetails.physicalAddress) {

      const physical = [
        {
          'column1': 'COUNTRY:',
          'column2': (this.companyDetails.physicalAddress ? this.companyDetails.physicalAddress.country.country : ''),
          'column3': 'TOWN/ CITY/ VILLAGE:',
          'column4': (this.companyDetails.physicalAddress ? this.companyDetails.physicalAddress.cityTown : '')
        },
        {
          'column1': 'PLOT NUMBER:',
          'column2': (this.companyDetails.physicalAddress ? this.companyDetails.physicalAddress.plotNumber : ''),
          'column3': 'STREET/ WARD:',
          'column4': (this.companyDetails.physicalAddress ? this.companyDetails.physicalAddress.streetName : '')
        },
        {
          'column1': 'DISTRICT:',
          'column2': (this.companyDetails.physicalAddress ? this.companyDetails.physicalAddress.district.name : ''),
          'column3': '',
          'column4': ''
        },
      ];

      doc.autoTable(columns, physical, {
        styles: {fontSize: 10},
        theme: 'striped',
        head: [[{content: 'Physical Address', colSpan: 4, styles: {halign: 'left', fillColor: [70, 188, 204]}}]],
        columnStyles: {
          column1: {
            textColor: [15, 15, 15], fontStyle: 'bold'
          },
          column3: {
            textColor: [15, 15, 15], fontStyle: 'bold'
          }
        },
        startY: doc.previousAutoTable.finalY + 10,
        margin: {top: 70},
      });

    } else {
      doc.setFontSize(8);
      doc.setFont('courier');
      doc.setFontType('normal');
      doc.text('** Physical address details not available', 30, doc.previousAutoTable.finalY + 20);
    }

    if (this.companyDetails.contactDetails && this.companyDetails.contactDetails.length > 0) {
      const contacts = [];

      this.companyDetails.contactDetails.forEach(contact => {
        contacts.push(
          {
            'column1': `${contact.contactDetailType.name.toUpperCase()}:`,
            'column2': contact.address,
            'column3': '',
            'column4': ''
          }
        );
      });

      doc.autoTable(columns, contacts, {
        styles: {fontSize: 10},
        theme: 'striped',
        head: [[{content: 'Contact Details', colSpan: 4, styles: {halign: 'left', fillColor: [70, 188, 204]}}]],
        columnStyles: {
          column1: {
            textColor: [15, 15, 15], fontStyle: 'bold'
          },
          column3: {
            textColor: [15, 15, 15], fontStyle: 'bold'
          }
        },
        startY: doc.previousAutoTable.finalY + 30,
        margin: {top: 70},
      });
    } else {
      doc.setFontSize(8);
      doc.setFont('courier');
      doc.setFontType('normal');
      doc.text('** Contact details not available', 30, doc.previousAutoTable.finalY + 30);
    }

    doc.setLineWidth(1);
    doc.line(20, 30, 100, 30);


    doc.save(this.companyDetails.name + '.pdf');
  }

  navBack() {
    this.router.navigate(['portal/applied-geoscience/company/list']);
  }
}
