import {Injectable, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { Company } from '../../shared-kernel/entity/applied-geoscience/company/company';
import { ContactDetail } from '../../common/entity/security/util/common/contact-detail';
import { FormControl, Validators } from '@angular/forms';
import { Commodity } from '../../shared-kernel/entity/applied-geoscience/prospecting-license/commodity';
import { LicenseType } from '../../shared-kernel/entity/applied-geoscience/prospecting-license/license-type';

@Injectable({
    providedIn: 'root'
})
export class ProspectingLicenseValidation implements OnInit{
    company: Company;
    contactDetail: ContactDetail;

    validation_messages = {
        'plnumber': [
          { type: 'required', message: 'Prospecting License number is required' }
        ],
        'company': [
          { type: 'required', message: 'Please select company' },
        ],
        'dateStarted': [
          { type: 'required', message: 'Drill start date is required' },
        ],
        'dateCompleted': [
          { type: 'required', message: 'Drill end date is required' },
        ],
        'description': [
          { type: 'required', message: 'Prospecting License description is required' },
        ],
        'licenseType': [
          { type: 'required', message: 'Prospecting License type is required' },
        ]
    };

    constructor(private toastr: ToastrService) {
    }

    ngOnInit() {}

    errorsmsg(message) {
      this.toastr.error(message);
    }

    successmsg(message) {
      this.toastr.success(message);
    }

    validateContactDetailsAsTheyAreAdded(contactDetail: ContactDetail): boolean {
        this.contactDetail = contactDetail;
        if (!contactDetail.address) {
            this.errorsmsg('Please make sure contact is provided');
            return false;
        } else if (!contactDetail.contactDetailType) {
            this.errorsmsg('Please make sure contact type is provided');
            return false;
        } else {
            return true;
        }
    }

    prospectingLicenseDatesvalid(openDate: Date, endDate: Date): boolean {
        if (openDate >= endDate) {
          this.errorsmsg('Please make sure Drill start date is BEFORE Drill end date');
          return false;
        } else {
          return true;
        }
    }

    commodityAddedToListValid(commodity: Commodity, plType: LicenseType): boolean {
        if (!plType.name) {
            this.errorsmsg('Please make sure Prospecting License Type is selected');
            return false;
        } else if (!commodity.name) {
            this.errorsmsg('Please make sure commodity is selected');
            return false;
        } else {
            return true;
        }
    }

    commodityAddedToFormBeingSubmitted (commodities: Commodity[]): boolean {
        if (commodities.length === 0) {
            this.errorsmsg('Please make sure commodites have been added');
            return false;
        } else {
            return true;
        }
    }

    validateInteger(digit): boolean {
        if (isNaN(Number(digit))) {
            return true;
        } else {
            return false;
        }
    }

}
