import { Notes } from '../applied-geoscience/notes';
import { SampleType } from '../common/sample-type';
import { Size } from '../common/size';
import { DrillingMethod } from './drilling-method';
import { DrillingRequestResponseNote } from './DrillingRequestResponseNote';
import { CoordinatesFormat } from '../common/coordinatesValidator/coordinatesFormat';
import {LocationTemp} from '../common/locationTemp';
import { BoreholeType } from '../common/boreholeType';
import { DrillingRequest } from './drilling-request';

export class DrillingRequestBorehole {
   id: string;
   drillingDate: Date = new Date();
   sampleTypes: SampleType[];
   sampleSize: Size = new Size();
   plannedDepth: Size = new Size();
   holeDiameter: Size = new Size();
   locationTemp: LocationTemp = new LocationTemp();
   drillingMethod: DrillingMethod = new DrillingMethod();
   comments: Notes = new Notes();
   boreholeNumber: string;
   boreholeType: BoreholeType = new BoreholeType();
   drillingRequest: DrillingRequest = new DrillingRequest();
   deleted: boolean;
   coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
   holeDiameterString: string;
}
