import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { State } from './../../../activities/models/state';
import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-minerals-manager-files-vetting-dashboard-ui',
  templateUrl: './minerals-manager-files-vetting-dashboard-ui.component.html',
  styleUrls: ['./minerals-manager-files-vetting-dashboard-ui.component.scss']
})
export class MineralsManagerFilesVettingDashboardUiComponent implements OnInit {


  @Input('nigisFileManagerCountsResponseDto') nigisFileManagerCountsResponseDto: any;

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  approvedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  cards :any;
  colors: any ;
  pieChartType = 'pie';
  isSet = true;
  state = new State();

  public myactivitiesLabels = [ 'Pending-Approval', 'Returned', 'Approved'];
  public myactivitiesData = [ 91, 107, 73];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userDashBoardService: UserDashboardService,
    private _router: Router,
    private _toaster: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

            return [
              { title: 'My Activities', cols: 1, rows: 1 },
              { title: 'Returned', cols: 1, rows: 1 },
              { title: 'Pending-Approval', cols: 1, rows: 1 },
              { title: 'Approved', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'},
            { title: 'Pending-Approval',cols: 1, rows: 3 ,id: 'drafts'},
            { title: 'Approved', cols: 1, rows: 3 ,id: 'approved'}
          ];
        })
      );
  }

  ngOnInit() {
    this.myactivitiesData = [this.nigisFileManagerCountsResponseDto.managerSubmitted, this.nigisFileManagerCountsResponseDto.returned,
      this.nigisFileManagerCountsResponseDto.approved];

      this.getIsDataset();
      this.getActivities();

      this.colors = [{backgroundColor: [  '#573419',
      '#CC0000','#009900', '#CC9933']}];
  }

  getIsDataset(){
    this.isSet = (Number(this.nigisFileManagerCountsResponseDto.managerSubmitted)+Number(this.nigisFileManagerCountsResponseDto.returned)+
      Number(this.nigisFileManagerCountsResponseDto.approved))>0;
  }

  getActivities() {
    this.spinner.show();
    this.userDashBoardService.getVettingFileActivities('pending_approval').subscribe(res => {
        this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Pending activities');
      });

      this.userDashBoardService.getVettingFileActivities('returned').subscribe(res => {
        this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Rejected activities');
      });

      this.userDashBoardService.getVettingFileActivities('approved').subscribe(res => {
        this.approvedTableDataSource.data = this.approvedTableDataSource.data ? this.approvedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
        this.spinner.hide();
      }, err => {
        this._toaster.warning(err.message, 'Failed to get Approved activities');
      });
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  goTo(type: string, filter: string, role: string) {
    this._router.navigate([`portal/activities/mineral-resources-files/${type}/${filter}/${role}`]);
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/mineral-resources-files/project/SUBMITTED_TO_SENIOR_GEOCHEMIST/snr']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/mineral-resources-files/project/REJECTED_BY_SENIOR_GEOCHEMIST/snr']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/mineral-resources-files/project/PUBLISHED/mng']);
        break
      default:
    }
  }

}
