import { WaterBearingZonesDetailsComponent } from './applied-geoscience/hydrogeology/hydrologeology-borehole-construction/water-bearing-zones/water-bearing-zones-details/water-bearing-zones-details.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppNavComponent} from './app-nav/app-nav.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {LoginComponent} from './auth/login/login.component';
import {AdminComponent} from './admin/admin.component';
import {LockscreenComponent} from './auth/lockscreen/lockscreen.component';
import {UserComponent} from './auth/user/user.component';
import {RolesComponent} from './auth/roles/roles.component';
import {CreateRolesComponent} from './auth/roles/create/create.component';
import {RegisterComponent} from './auth/user/register/register.component';
import {UserListComponent} from './auth/user/list/list.component';
import {FirstSetupComponent} from './auth/user/first-setup/first-setup.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {AppliedGeoscienceComponent} from './applied-geoscience/applied-geoscience.component';
import {CompanyComponent} from './applied-geoscience/company/company.component';
import {ProjectComponent} from './applied-geoscience/project/project.component';
import {GeoTechComponent} from './applied-geoscience/geo-tech/geo-tech.component';
import {BoreholeGeoTechComponent} from './applied-geoscience/geo-tech/borehole/borehole.component';
import {CoreComponent} from './applied-geoscience/geo-tech/core/core.component';
import {CoreLithologyComponent} from './applied-geoscience/geo-tech/core/core-lithology/core-lithology.component';
import {DrillerComponent} from './driller/driller.component';
import {DrillingRequestComponent} from './driller/drilling-request/drilling-request.component';
import {BoreholeLogsComponent} from './applied-geoscience/geo-tech/borehole/logs/borehole-logs.component';

import {CompanyListComponent} from './applied-geoscience/company/company-list/company-list.component';
import {ProjectCreateComponent} from './applied-geoscience/project/project-create/project-create.component';
import {ProjectListComponent} from './applied-geoscience/project/project-list/project-list.component';
import {DrillingRequestCreateComponent} from './driller/drilling-request/drilling-request-create/drilling-request-create.component';
import {DrillingRequestListComponent} from './driller/drilling-request/drilling-request-list/drilling-request-list.component';
import {RoleListComponent} from './auth/roles/role-list/role-list.component';
import {CoreRecoveryListComponent} from './applied-geoscience/geo-tech/core/core-recovery/core-recovery-list/core-recovery-list.component';
import {CoreRecoveryComponent} from './applied-geoscience/geo-tech/core/core-recovery/core-recovery.component';
import {DcpFoundationComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-foundation.component';
import {DcpTestFoundationListComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-list/dcp-test-foundation-list.component';
import {DcpTestFoundationCreateComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-create/dcp-test-foundation-create.component';
import {DynamicConePenotrometerComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dynamic-cone-penotrometer.component';
import {DiscontinuityLogComponent} from './applied-geoscience/geo-tech/core/core-recovery/discontinuity-log/discontinuity-log.component';
import {DiscontinuityLogListComponent} from './applied-geoscience/geo-tech/core/core-recovery/discontinuity-log/discontinuity-log-list/discontinuity-log-list.component';
import {DiscontinuityLogCreateComponent} from './applied-geoscience/geo-tech/core/core-recovery/discontinuity-log/discontinuity-log-create/discontinuity-log-create.component';
import {WaterBoreholeComponent} from './applied-geoscience/geo-tech/borehole/water-borehole/water-borehole.component';
import {WaterBoreholeLithologyLogComponent} from './applied-geoscience/hydrogeology/water-borehole-lithology-log/water-borehole-lithology-log.component';
import {WaterBoreholeLithologyLogListComponent} from './applied-geoscience/hydrogeology/water-borehole-lithology-log/water-borehole-lithology-log-list/water-borehole-lithology-log-list.component';
import {WaterboreholeLogsComponent} from './applied-geoscience/geo-tech/borehole/water-borehole/waterborehole-logs/waterborehole-logs.component';
import {CompanyDetailComponent} from './applied-geoscience/company/company-detail/company-detail.component';
import {DrillerChecklistComponent} from './driller/driller-checklist/driller-checklist.component';
import {DrillerChecklistListComponent} from './driller/driller-checklist/driller-checklist-list/driller-checklist-list.component';
import {DrillingProtocolComponent} from './driller/drilling-protocol/drilling-protocol.component';
import {DrillingProtocolListComponent} from './driller/drilling-protocol/drilling-protocol-list/drilling-protocol-list.component';
import {HydrogeologyComponent} from './applied-geoscience/hydrogeology/hydrogeology.component';
import {WaterPumpTestComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test.component';
import {WaterPumpTestStepTestComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test.component';
import {WaterPumpTestStepTestListComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test-list/water-pump-test-step-test-list.component';
import {ProjectDetailComponent} from './applied-geoscience/project/project-detail/project-detail.component';

import {DcpRoadworksListComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-list/dcp-roadworks-list.component';
import {DcpRoadworksCreateComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-create/dcp-roadworks-create.component';
import {DcpSubgradeLogsComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-subgrade-logs/dcp-subgrade-logs.component';

import {WaterPumpTestCalibrationComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration.component';
import {WaterPumpTestCalibrationListComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration-list/water-pump-test-calibration-list.component';
import {WaterBoreholeViewComponent} from './applied-geoscience/geo-tech/borehole/water-borehole/water-borehole-view/water-borehole-view.component';
import {WaterPumpTestStepTestViewComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test-view/water-pump-test-step-test-view.component';
import {WaterPumpTestCalibrationViewComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration-view/water-pump-test-calibration-view.component';
import {TrialPitListComponent} from './applied-geoscience/geo-tech/soil/trial-pit/trial-pit-list/trial-pit-list.component';
import {TrialPitComponent} from './applied-geoscience/geo-tech/soil/trial-pit/trial-pit.component';
import {BoreholeConstructionCasingComponent} from './applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-casing/borehole-construction-casing.component';
import {BoreholeConstructionScreeningComponent} from './applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-screening/borehole-construction-screening.component';
import {WaterPumpTestConstantRateTestComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test.component';
import {WaterPumpTestConstantRateTestListComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test-list/water-pump-test-constant-rate-test-list.component';
import {WaterPumpTestConstantRateTestViewComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test-view/water-pump-test-constant-rate-test-view.component';
import {WaterBearingZonesComponent} from './applied-geoscience/hydrogeology/hydrologeology-borehole-construction/water-bearing-zones/water-bearing-zones.component';
import {BoreholeConstructionDrillingProtocolComponent} from './applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-drilling-protocol/borehole-construction-drilling-protocol.component';
import {WaterPumpTestRecoveryComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery.component';
import {WaterPumpTestRecoveryListComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery-list/water-pump-test-recovery-list.component';
import {WaterPumpTestRecoveryViewComponent} from './applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery-view/water-pump-test-recovery-view.component';
import {PLDetailsComponent, PLListComponent} from './applied-geoscience/prospecting-licenses/index';
import {PlotToMapComponent} from './common/plot-to-map/plot-to-map.component';
import {DcpTestFoundationDetailComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-detail/dcp-test-foundation-detail.component';

import {SoilLogListComponent} from './applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-list/soil-log-list.component';
import {AuthorityComponent} from './auth/authority/authority.component';
import {AuthorityGroupComponent} from './auth/authority-group/authority-group.component';
import {SettingsComponent} from './_settings/settings.component';

import {InfiltrationTestListComponent} from './applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-list/infiltration-test-list.component';
import {InfiltrationTestDetailComponent} from './applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-detail/infiltration-test-detail.component';
import {DcpRoadworksDetailComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-detail/dcp-roadworks-detail.component';
import {DcpSubgradeComponent} from './applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-subgrade.component';
import {DrillingRequestDetailComponent} from './driller/drilling-request/drilling-request-detail/drilling-request-detail.component';
import {BulletinListComponent} from './applied-geoscience/seismology/bulletin-list/bulletin-list.component';
import {DashboardComponent} from './dashboards/dashboard/dashboard.component';
// Authentication guard
import {AuthGuard} from './_auth/_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/portal',
    pathMatch: 'full',
  },
  {
    path: 'portal',
    component: AppNavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'activities',
        loadChildren: './activities/activities.module#ActivitiesModule'
      },
      {
        path: 'personnel',
        canActivate: [AuthGuard],
        loadChildren: './personnel/personnel.module#PersonnelModule'
      },
      {
        path: 'collections',
        loadChildren: './collections/collections.module#CollectionsModule'
      },
      {
        path: 'mineral-resources',
        loadChildren: './mineral-resources/mineral-resources.module#MineralResourcesModule'
      },
      {
        path: 'nigis-reporting',
        loadChildren: './nigis-reporting/nigis-reporting.module#NigisReportingModule'
      },
      {
        path: 'userprofile',
        component: UserProfileComponent,
      },
      {
        path: 'applied-geoscience',
        component: AppliedGeoscienceComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'company',
            component: CompanyComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'list',
                component: CompanyListComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'detail',
                component: CompanyDetailComponent,
                canActivate: [AuthGuard]
              },
            ]
          }
          ,
          {
            path: 'prospecting-license',
            component: ProjectComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'list',
                component: PLListComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'detail',
                component: PLDetailsComponent,
                canActivate: [AuthGuard]
              },
            ]
          },
          {
            path: 'project',
            component: ProjectComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'create',
                component: ProjectCreateComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'list',
                component: ProjectListComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'detail',
                component: ProjectDetailComponent,
                canActivate: [AuthGuard]
              },
            ]
          },
          {
            path: 'seismology',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'bulletin',
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: BulletinListComponent,
                    canActivate: [AuthGuard]
                  },
                ]
              },
            ]
          },
          {
            path: 'geo-tech',
            component: GeoTechComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'water-borehole',
                component: WaterBoreholeComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: BoreholeListComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'locations',
                    component: PlotToMapComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'create',
                    component: BoreholeCreateComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'lithology',
                    component: WaterBoreholeLithologyLogComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: WaterBoreholeLithologyLogListComponent,
                        canActivate: [AuthGuard]
                      },
                    ]
                  },
                  {
                    path: 'drilling-protocol',
                    component: DrillingProtocolComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: DrillingProtocolListComponent,
                        canActivate: [AuthGuard]
                      },
                    ],
                  },
                  {
                    path: 'logs',
                    component: WaterboreholeLogsComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: 'borehole',
                component: BoreholeGeoTechComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: BoreholeListComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'locations',
                    component: PlotToMapComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'details',
                    component: BoreholeDetailComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'borehole-logs',
                    component: BoreholeLogsComponent,
                    canActivate: [AuthGuard],

                  },
                  {
                    path: 'timeandmotion/details',
                    component: TimeandmotionDetailsComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'drilling-logs/details',
                    component: DrillingLogDetailsComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'lithology/details/:id',
                    component: CoreLithologyDetailsComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: 'pl-borehole',
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: BoreholeListComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'details',
                    component: BoreholeDetailComponent,
                    canActivate: [AuthGuard]
                  },
                ]
              },
              {
                path: 'dynamic-cone-penetrometer',
                component: DynamicConePenotrometerComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'dcp-test-foundation',
                    component: DcpFoundationComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: DcpTestFoundationListComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'locations',
                        component: PlotToMapComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'create',
                        component: DcpTestFoundationCreateComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'detail',
                        component: DcpTestFoundationDetailComponent,
                        canActivate: [AuthGuard]
                      }
                    ]
                  },
                  {
                    path: 'dcp-test-subgrade',
                    component: DcpSubgradeComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: DcpRoadworksListComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'locations',
                        component: PlotToMapComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'create',
                        component: DcpRoadworksCreateComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'logs',
                        component: DcpSubgradeLogsComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'detail',
                        component: DcpRoadworksDetailComponent,
                        canActivate: [AuthGuard]
                      }
                    ]
                  },
                ]
              },
              {
                path: 'infiltration-test',
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: InfiltrationTestListComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'locations',
                    component: PlotToMapComponent,
                    canActivate: [AuthGuard]
                  },
                  {
                    path: 'details',
                    component: InfiltrationTestDetailComponent,
                    canActivate: [AuthGuard],
                  },
                ]
              },
              {
                path: 'core',
                component: CoreComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'lithology',
                    component: CoreLithologyComponent,
                    canActivate: [AuthGuard],
                    children: []
                  },
                  {
                    path: 'recovery',
                    component: CoreRecoveryComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'create',
                        component: CoreRecoveryListComponent,
                        canActivate: [AuthGuard]
                      },
                      {
                        path: 'discontinuity',
                        component: DiscontinuityLogComponent,
                        canActivate: [AuthGuard],
                      },
                    ]
                  }
                  , {
                    path: 'discontinuity',
                    component: DiscontinuityLogComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: DiscontinuityLogListComponent,
                        canActivate: [AuthGuard],
                      },
                      {
                        path: 'create',
                        component: DiscontinuityLogCreateComponent,
                        canActivate: [AuthGuard],
                      },
                    ]
                  },
                ]
              },
              {
                path: 'trial-pit',
                component: TrialPitComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: '',
                    component: TrialPitListComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'details',
                    component: SoilLogListComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'locations',
                    component: PlotToMapComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'soil-log/details',
                    component: SoilLogDetailsComponent,
                    canActivate: [AuthGuard],
                  },
                ]
              },
              {
                path: 'samples',
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: SamplesComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'list/details',
                    component: SamplesDetailsComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'locations',
                    component: PlotToMapComponent,
                    canActivate: [AuthGuard]
                  },
                ]
              },
            ]
          },
          {
            path: 'hydrogeology',
            component: HydrogeologyComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'water-pump-test',
                component: WaterPumpTestComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'step-test',
                    component: WaterPumpTestStepTestComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: WaterPumpTestStepTestListComponent,
                        canActivate: [AuthGuard],
                      },
                      {
                        path: 'view',
                        component: WaterPumpTestStepTestViewComponent,
                        canActivate: [AuthGuard],
                      }
                    ]
                  },
                  {
                    path: 'calibration',
                    component: WaterPumpTestCalibrationComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: WaterPumpTestCalibrationListComponent,
                        canActivate: [AuthGuard],
                      },
                      {
                        path: 'view',
                        component: WaterPumpTestCalibrationViewComponent,
                        canActivate: [AuthGuard],
                      }
                    ]
                  },
                  {
                    path: 'constant-rate-test',
                    component: WaterPumpTestConstantRateTestComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: WaterPumpTestConstantRateTestListComponent,
                        canActivate: [AuthGuard],
                      },
                      {
                        path: 'view',
                        component: WaterPumpTestConstantRateTestViewComponent,
                        canActivate: [AuthGuard],
                      }
                    ]
                  },
                  {
                    path: 'recovery',
                    component: WaterPumpTestRecoveryComponent,
                    canActivate: [AuthGuard],
                    children: [
                      {
                        path: 'list',
                        component: WaterPumpTestRecoveryListComponent,
                        canActivate: [AuthGuard],
                      },
                      {
                        path: 'view',
                        component: WaterPumpTestRecoveryViewComponent,
                        canActivate: [AuthGuard],
                      }
                    ]
                  }
                ]
              },
              {
                path: 'lithology',
                component: WaterBoreholeLithologyLogComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'list',
                    component: WaterBoreholeLithologyLogListComponent,
                    canActivate: [AuthGuard]
                  }
                ]
              },
              {
                path: 'borehole/details',
                component: BoreholeDetailComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'casing',
                    component: BoreholeConstructionCasingComponent,
                    canActivate: [AuthGuard],

                  },
                  {
                    path: 'screening',
                    component: BoreholeConstructionScreeningComponent,
                    canActivate: [AuthGuard],

                  },
                  {
                    path: 'waterBearingZones',
                    component: WaterBearingZonesComponent,
                    canActivate: [AuthGuard],

                  },
                  {
                    path: 'drilling',
                    component: BoreholeConstructionDrillingProtocolComponent,
                    canActivate: [AuthGuard],
                  }

                  ,
                ]
              },
              {
                path: 'water-bearing-zones/details',
                component: WaterBearingZonesDetailsComponent,
                canActivate: [AuthGuard],
              }
            ]
          },

        ]
      },
      {
        path: 'drilling',
        component: DrillerComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'request',
            component: DrillingRequestComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'create',
                component: DrillingRequestCreateComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'list',
                component: DrillingRequestListComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'detail',
                component: DrillingRequestDetailComponent,
                canActivate: [AuthGuard],

              },
              {
                path: 'detail/borehole-details',
                component: DrillingRequestBoreholeDetailsComponent,
                canActivate: [AuthGuard]
              },
            ]
          },
          {
            path: 'checklist',
            component: DrillerChecklistComponent,
            canActivate: [AuthGuard],
            children: [

              {
                path: 'list',
                component: DrillerChecklistListComponent,
                canActivate: [AuthGuard]
              },
            ],
          }
        ]
      },
      {
        path: 'relinquished-documents',
        component: RelinquishedDocumentsComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'lockscreen',
    component: LockscreenComponent,
  },
  {
    path: 'setup',
    component: FirstSetupComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'register',
            component: RegisterComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'list',
            component: UserListComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'role',
        component: RolesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: CreateRolesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'list',
            component: RoleListComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'authority',
        component: AuthorityComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'group',
            component: AuthorityGroupComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'userprofile',
        component: UserProfileComponent,
      }
    ]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    children: [
      {
        path: '',
        loadChildren: './_settings/settings.module#SettingsModule'
      }, {
        path: 'userprofile',
        component: UserProfileComponent,
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: './_auth/auth.module#AuthModule'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  // otherwise redirect to home
  {
    path: '**',
    component: AppNavComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pageNotFound',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
  },
];

import {environment} from './../environments/environment';
import {SamplesComponent} from './applied-geoscience/geo-tech/samples/samples.component';
import {SamplesDetailsComponent} from './applied-geoscience/geo-tech/samples/samples-details/samples-details.component';
import {DrillingRequestBoreholeDetailsComponent} from './driller/drilling-request/drilling-request-boreholes/drilling-request-borehole-details/drilling-request-borehole-details.component';
import {DrillingLogDetailsComponent} from './driller/drillerlog/drilling-log-details/drilling-log-details.component';
import {CoreLithologyDetailsComponent} from './applied-geoscience/geo-tech/core/core-lithology/core-lithology-details/core-lithology-details.component';
import {TimeandmotionDetailsComponent} from './driller/timeandmotion/timeandmotion-details/timeandmotion-details.component';
import {SoilLogDetailsComponent} from './applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-details/soil-log-details.component';
import {RelinquishedDocumentsComponent} from "./landfolio/relinquished-documents/relinquished-documents.component";
import { BoreholeCreateComponent } from './applied-geoscience/geo-tech/borehole/borehole-create/borehole-create.component';
import { BoreholeListComponent } from './applied-geoscience/geo-tech/borehole/borehole-list/borehole-list.component';
import { BoreholeDetailComponent } from './applied-geoscience/geo-tech/borehole/borehole-details/borehole-detail.component';

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: environment.routes.enableTracing})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
