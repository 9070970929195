import { District } from 'src/app/common/entity/security/util/common/district';
import { CoordinatesFormat } from './coordinatesValidator/coordinatesFormat';
import { Coordinates } from './coordinates';
import { Countries } from 'src/app/common/entity/security/profile/countries';

export class Village {
  id?: string;
  name?: string;
  district: District = new District();
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
  coordinates: Coordinates = new Coordinates();
  country: Countries = new Countries();
}
