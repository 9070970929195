import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    Swal.fire({
      title: '404',
      text: 'Page not Found ' ,
      type: 'warning',
      confirmButtonText: 'Ok'
    });
  }

}
