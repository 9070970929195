import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-user',
  template: `<router-outlet></router-outlet>`
})
export class UserComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }
}
