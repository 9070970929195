import { Component, OnInit } from '@angular/core';
import { TimeAndMotion } from '../entity/timeandmotion';
import { SampleCreateComponent } from '../../../applied-geoscience/geo-tech/samples/sample-create/sample-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeAndMotionService } from '../timeandmotion.service';
import { ActivatedRoute } from '@angular/router';
import { Sample } from '../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { Borehole } from '../../../shared-kernel/entity/common/borehole';
import { GeoTechService } from '../../../applied-geoscience/geo-tech/geo-tech.service';
import { TimeandmotionCreateComponent } from '../timeandmotion-create/timeandmotion-create.component';
import { TimeAndMotionDto } from '../entity/timeandmotionDto';

@Component({
  selector: 'app-timeandmotion-details',
  templateUrl: './timeandmotion-details.component.html',
  styleUrls: ['./timeandmotion-details.component.scss']
})
export class TimeandmotionDetailsComponent implements OnInit {

  timeandmotionDetails : TimeAndMotionDto;
  timeandmotionId : string ;
  expanded: boolean = true;
  borehole: Borehole;
  durationMinutes: string;
  durationHours: string;

  constructor(private dialog: MatDialog,private timeAndMotionService: TimeAndMotionService,private route: ActivatedRoute,private geoTechService: GeoTechService) {
    this.route.params.subscribe(params => {
      this.timeandmotionId = params.id;
    });
  }

  ngOnInit() {
    this.getTimeandmotionById(this.timeandmotionId);
  }

  editTimeandmotion(timeAndMotion){
    const editDialogRef = this.dialog.open(TimeandmotionCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: timeAndMotion
    });

    editDialogRef.afterClosed().subscribe(result => {
      this.getTimeandmotionById(this.timeandmotionDetails.id);
    });
  }



  getTimeandmotionById(timeandmotionId) {
    this.timeAndMotionService.getTimeAndMotionById(timeandmotionId).subscribe(value => {
      this.timeandmotionDetails = value as TimeAndMotionDto;
      this.durationHours = Math.floor(parseFloat(this.timeandmotionDetails.duration))+'';
      this.durationMinutes = Math.ceil((parseFloat(this.timeandmotionDetails.duration) - Math.floor(parseFloat(this.timeandmotionDetails.duration)))*60)+'';
    },error => {},() => {

    });

  }




}
