import { Injectable, OnInit, Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MineralChemistry } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/MineralChemistry';
import { Observable } from 'rxjs';
import { ProspectingLicense } from 'src/app/shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license';


@Injectable({
  providedIn: 'root'
})
export class ProspectingLicenseService implements OnInit {

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
  }

  createProspectingLicense(pl) {
    // this.spinner.show();
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createProspectingLicenseUrl, pl);

  }

  getAllPLs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProspectingLicenseUrl);
  }

  getAllPLsPaged(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl }${ServiceEndPoints.getAllProspectingLicenseUrlPaged}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  pagedPLsSearch(criteria: string, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl }${ServiceEndPoints.pagedSearchUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}&criteria=${criteria}`);
  }

  search(criteria: string): Observable<ProspectingLicense[]> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl }${ServiceEndPoints.searchUrl}?criteria=${criteria}`);
  }

  getAllPLsLite() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProspectingLicenseLiteUrl);
  }

  getMyAllPLs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllMyProspectingLicenseUrl);
  }

  getAllPLStatuses() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProspectingLicenseStatusesUrl);
  }

  getAllCommodities() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCommoditiesUrl);
  }

  getAllPLTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllMineralTypesUrl);
  }

  getCommoditiesByPLTypeId(mineral: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCommoditiesByMineralIdUrl + '/' + mineral);
  }

  getPLTypeByCommodityId(commodity: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCommodityByIdUrl + '/' + commodity);
  }

  getPLTypeById(commodity: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCommodityByIdUrl + '/' + commodity);
  }

  getPLById(projectId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProspectingLicenseByIdUrl + '/' + projectId);
  }

  createBatchProspectingLicenceMineralChemistry(mineralChemistry: MineralChemistry []) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createProspectingLicenceMineralChemistryUrl, mineralChemistry);
  }

  getProspectingLicenceMineralChemistry(prospectingLicenceId: string) :Observable<Array<MineralChemistry>>{
    return this.http.get<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProspectingLicenseeMineralChemistryUrl + '/' + prospectingLicenceId);
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }
}
