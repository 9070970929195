import { ToastrService } from 'ngx-toastr';
import { ApprovalService } from './../../../../applied-geoscience/geo-tech/borehole/services/approval.service';
import { ApproveAction } from './../../../../applied-geoscience/models/approval-state';
import { DocumentsService } from './../../../../shared/documents/dococument.service';
import { TechnicianSampleCreateComponent } from './../technician-sample-create/technician-sample-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UploadedFilesService } from './../../../../common/uploaded-files/uploaded-files.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoTechService } from './../../../../applied-geoscience/geo-tech/geo-tech.service';
import { FileToUpload } from './../../../../common/uploaded-files/entity/file';
import { Sample } from './../../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { Component, OnInit } from '@angular/core';
import Swal from "sweetalert2";
import {Lightbox} from "ngx-lightbox";

@Component({
  selector: 'app-technician-sample-detail',
  templateUrl: './technician-sample-detail.component.html',
  styleUrls: ['./technician-sample-detail.component.scss']
})
export class TechnicianSampleDetailComponent implements OnInit {

  sampleId: string;
  sample: Sample;
  album: any = [];
  sampleLocationImages: any[];
  expanded: boolean = true;
  loading:boolean = true;
  selectedSection: string;
  totalNumberOfFiles: number;
  uploadedFileList: FileToUpload[];
  sampleCount:number = 0;
  currentComment: string;

  constructor(
    private geoTechService: GeoTechService,
    private route: ActivatedRoute,
    private router: Router,
    private fileUploadService: UploadedFilesService,
    private dialog: MatDialog,
    private lightbox: Lightbox,
    private _docServ: DocumentsService,
    private _approvalServ: ApprovalService,
    private toast: ToastrService,
  ) {
    this.route.params.subscribe(params => {
      this.sampleId = params.id;
    });
  }

  ngOnInit() {
    this.getSampleBySampleId();
    this.countDocuments();
  }

  getSampleBySampleId() {
    this.geoTechService.getSampleById(this.sampleId).subscribe(data => {
      this.getSampleLocationImagery();
      this.sample = data as Sample;
    }, error => {
    }, () => {
    });
  }

  private countDocuments() {
    this._docServ.getDocumentCount(this.sampleId).subscribe(res => {
        this.totalNumberOfFiles = res;
    });
  }

  redirect(){
    this.router.navigate(['/portal/mineral-resources/samples/technician/list']);
  }

  redicrectToDocuments() {
    this.selectedSection = 'sampleDocuments';
  }

  onEdit(sample) {
    const obj = {sample: sample, sampleCount: this.sampleCount};
    const newGeoHeritageDialogRef = this.dialog.open(TechnicianSampleCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getSampleBySampleId();
    });
  }
  getSampleLocationImagery() {
    this.geoTechService.getSampleLocationImageryByID(this.sampleId).subscribe(value => {
      this.sampleLocationImages =  value as any [];
      for (let image of this.sampleLocationImages) {
        let albumImage: any = {};
        albumImage.src = 'data:image/jpg;base64,'+ image.image;
        albumImage.caption = image.name;
        albumImage.thumb = 'data:image/jpg;base64,'+ image.image;
        this.album.push(albumImage);
      }
    } )
  }
  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  changeState(action: ApproveAction) {
    let successMessage: string = 'Sample submitted successfully';
    let title: string = 'Sample Submit';

    switch (action) {
      case ApproveAction.SUBMIT_TO_GEOLOGIST:
        successMessage = 'Sample submitted successfully';
        title = 'Sample Submit';
        break;
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'Sample submitted successfully';
        title = 'Sample Submit';
        break;
      case ApproveAction.QA_APPROVE:
        successMessage = 'Sample approved successfully';
        title = 'Sample Approve';
        break;
      case ApproveAction.QA_REJECT:
        successMessage = 'Sample rejected successfully';
        title = 'Sample Reject';
        break;
      case ApproveAction.GEOLOGIST_SUBMIT_TO_MANAGER:
          successMessage = 'Sample approved successfully';
          title = 'Sample Approve';
          break;
      case ApproveAction.GEOLOGIST_REJECT:
          successMessage = 'Sample rejected successfully';
          title = 'Sample Reject';
          break;
      case ApproveAction.MANAGER_APPROVE:
        successMessage = 'Sample approved successfully';
        title = 'Sample Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        successMessage = 'Sample rejected successfully';
        title = 'Sample Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        successMessage = 'Sample published successfully';
        title = 'Sample Published';
        break;
      default:
        throw new Error('Unknown change state');
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this._approvalServ.changeSampleState(this.sampleId, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toast.success(successMessage, title);
          this.getSampleBySampleId();
        }, err => {
          this.toast.warning(err.message, 'Failed');
        });
      }
    });

  }

}
