import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { MockDataModule } from './mock/mock-data.module';

import { CountryData } from './data/country';
import { CountryService } from './mock/country.service';
import { PeopleData } from './data/people';
import { PeopleService } from './mock/people.service';
import { DcpFoundationData } from './data/dcp-foundation';
import { DcpFoundationService } from './mock/dcp-foundation.service';
import { UnitsData } from './data/units';
import { UnitsService } from './mock/units.service';
import { BusinessUnitData } from './data/BusinessUnitData';
import { BusinessService } from './mock/business.service';

const DATA_SERVICES = [
  { provide: CountryData, useClass: CountryService },
  { provide: PeopleData, useClass: PeopleService },
  { provide: DcpFoundationData, useClass: DcpFoundationService },
  { provide: UnitsData, useClass: UnitsService },
  { provide: BusinessUnitData, useClass: BusinessService }
];

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ]
    };
  }
}
