export class State {
  PENDING_QA_SUBMISSION = {stateName: 'Pending QA Submission', label: 'PENDING_QA_SUBMISSION', color: 'blue', code: 0};
  SUBMITTED_TO_QA = {stateName: 'Submitted To QA', label: 'SUBMITTED_TO_QA', color: 'orange', code: 1};
  REJECTED_BY_QA = {stateName: 'Returned By QA', label: 'REJECTED_BY_QA', color: 'red', code: 2};
  SUBMITTED_TO_MANAGER = {stateName: 'Submitted To Manger', label: 'SUBMITTED_TO_MANAGER', color: 'teal', code: 3};
  REJECTED_BY_MANAGER = {stateName: 'Returned By Manager', label: 'REJECTED_BY_MANAGER', color: 'red', code: 4};
  PUBLISHED = {stateName: 'Published', label: 'PUBLISHED', color: 'green', code: 5};
  APPROVED_MANAGER = {stateName: 'Published', label: 'APPROVED_MANAGER', color: 'green'};

  
  PENDING_SENIOR_SUBMISSION = {stateName: 'Pending Submission', label: 'PENDING_SENIOR_SUBMISSION', color: 'blue'};
  PENDING_MANAGER_SUBMISSION = {stateName: 'Pending Submission', label: 'PENDING_MANAGER_SUBMISSION', color: 'blue', status: 'PENDING_MANAGER_SUBMISSION', code: 10};

  //Mineral Resources Technician Sample Collection Approval Process
  PENDING_GEOLOGIST_SUBMISSION = {stateName: 'Pending Submission', label: 'PENDING_GEOLOGIST_SUBMISSION', color: 'blue', code: 6};
  SUBMITTED_TO_GEOLOGIST = {stateName: 'Submitted To Geologist', label: 'SUBMITTED_TO_GEOLOGIST', color: 'orange', code: 7};
  REJECTED_BY_GEOLOGIST = {stateName: 'Returned By Geologist', label: 'REJECTED_BY_GEOLOGIST', color: 'red', code: 9};
  SUBMITTED_TO_MANAGER_BY_GEOLOGIST = {stateName: 'Submitted To Manager By Geologist', label: 'SUBMITTED_TO_MANAGER_BY_GEOLOGIST', color: 'orange', code: 8};

  // Economic Gelogist Minerals Resources
  SUBMITTED_TO_MANAGER_BY_MINERAL_ECONOMIST = {stateName: 'Submitted To Manager By Mineral Economist', label: 'SUBMITTED_TO_MANAGER_BY_MINERAL_ECONOMIST', color: 'orange'};
  SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST = {stateName: 'Submitted To Manager By Senior Mineral Economist', label: 'SUBMITTED_TO_MANAGER_BY_SENIOR_MINERAL_ECONOMIST', color: 'teal'};
  SUBMITTED_TO_SENIOR_MINERAL_ECONOMIST = {stateName: 'Submitted To Senior Mineral Economist', label: 'SUBMITTED_TO_SENIOR_MINERAL_ECONOMIST', color: 'orange'};
  REJECTED_BY_SENIOR_MINERAL_ECONOMIST = {stateName: 'Returned By Senior Mineral Economist', label: 'REJECTED_BY_SENIOR_MINERAL_ECONOMIST', color: 'red'};

    //Mineral Resources GEOCHEMIST
  SUBMITTED_TO_SENIOR_GEOCHEMIST = {stateName: 'Submitted To Senior Geochemist', label: 'SUBMITTED_TO_SENIOR_GEOCHEMIST', color: 'orange'};
  REJECTED_BY_SENIOR_GEOCHEMIST = {stateName: 'Returned By Senior Geochemist', label: 'REJECTED_BY_SENIOR_GEOCHEMIST', color: 'red'};
  SUBMITTED_TO_MANAGER_BY_GEOCHEMIST = {stateName: 'Submitted To Manager By Geochemist', label: 'SUBMITTED_TO_MANAGER_BY_GEOCHEMIST', color: 'teal'};
  SUBMITTED_TO_MANAGER_BY_SENIOR_GEOCHEMIST = {stateName: 'Submitted To Manager By Senior Geochemist', label: 'SUBMITTED_TO_MANAGER_BY_SENIOR_GEOCHEMIST', color: 'teal'};


//Mineral Resources Economic Geologist
  SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST = {stateName: 'Submitted To Senior Economic Geologist', label: 'SUBMITTED_TO_SENIOR_ECONOMIC_GEOLOGIST', color: 'orange'};
  SUBMITTED_TO_MANAGER_BY_ECONOMIC_GEOLOGIST = {stateName: 'Submitted To Manager By Economic Geologist', label: 'SUBMITTED_TO_MANAGER_BY_ECONOMIC_GEOLOGIST', color: 'teal'};
  SUBMITTED_TO_MANAGER_BY_SENIOR_ECONOMIC_GEOLOGIST = {stateName: 'Submitted To Manager By Senior Economic Geologist', label: 'SUBMITTED_TO_MANAGER_BY_SENIOR_ECONOMIC_GEOLOGIST', color: 'teal'};
  REJECTED_BY_SENIOR_ECONOMIC_GEOLOGIST = {stateName: 'Returned By Senior Economic Geologist', label: 'REJECTED_BY_SENIOR_ECONOMIC_GEOLOGIST', color: 'red'};


}

