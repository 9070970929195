import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { ApprovalState } from './../../../applied-geoscience/models/approval-state';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minerals-manager-sample-vetting-dashboard',
  templateUrl: './minerals-manager-sample-vetting-dashboard.component.html',
  styleUrls: ['./minerals-manager-sample-vetting-dashboard.component.scss']
})
export class MineralsManagerSampleVettingDashboardComponent implements OnInit {

  stillFetchingBGIProjectCount = true;
  stillFetchingMyProjectCount = true;
  stillFetchingPending = true;
  stillFetchingReturned = true;
  stillFetchingApproved = true;

  activitiesCount = {
    pending: 0,
    returned: 0,
    approved: 0,
    numberOfBGIProjects: 0,
    numberOfMyProjects: 0
    }

    approvalEnum: typeof ApprovalState = ApprovalState;
    userName: string;

  constructor(
    private userDashBoardService: UserDashboardService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
  }

  ngOnInit() {
    this.getBGIProjectsCount();
    this.getMyProjectsCount();
    this.getPendingManagerApproval();
    this.getReturnedFromManager();
    this.getPublished();
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.activitiesCount.numberOfBGIProjects = number;
      this.stillFetchingBGIProjectCount = false;
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.activitiesCount.numberOfMyProjects = number;
      this.stillFetchingMyProjectCount = false;
    });
  }

    //Manager Activities
    getPendingManagerApproval(){
      this.userDashBoardService.getMyActivitiesMineralResourcesManagerCount('Submitted').subscribe(number => {
        this.activitiesCount.pending = number;
        this.stillFetchingPending = false;
      });
    }

    getReturnedFromManager(){
      this.userDashBoardService.getMyActivitiesMineralResourcesManagerCount('Returned').subscribe(number => {
        this.activitiesCount.returned = number;
        this.stillFetchingReturned = false;
      });
    }

    getPublished(){
      this.userDashBoardService.getMyActivitiesMineralResourcesManagerCount('Approved').subscribe(number => {
        this.activitiesCount.approved = number;
        this.stillFetchingApproved = false;
      });
    }

}
