import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from "./../../../environments/environment";
import { User } from "./../../_core/data/_models/people.model";

export interface AuthRequest {
    username: string;
    password: string;
}

export interface AuthRes {
    token: string;
    firstLogin: boolean;
    message: string;
    user: User;
}

@Injectable()
export class AuthService {
    constructor(
        private _http: HttpClient
    ) {}    

    login(loginRequest: AuthRequest): Observable<AuthRes> {
        return this._http.post<AuthRes>(environment.authBaseUrl + `/api/user/v1/login`, loginRequest);
    }

    requestPasswordReset(username: string): Observable<any> {
        return this._http.post<any>(environment.authBaseUrl + `/api/user/v1/password/reset/${username}`, {});
    }
}