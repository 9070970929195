import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-efficiency-forms',
  templateUrl: './efficiency-forms.component.html',
  styleUrls: ['./efficiency-forms.component.scss']
})
export class EfficiencyFormsComponent implements OnInit {

  selectedTab: number;

  constructor(private router: ActivatedRoute) {


    router.params.subscribe(
      data => {
        if (data.tab) {
          this.selectedTab = data.tab;
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  ngOnInit() {
  }

}
