import { Borehole } from '../../common/borehole';

export class DrillerLogRecord {
    public id: string;
    public date: Date;
    public numberOfRods: number;
    public coreBarrel: number;
    public drillString: number;
    public tableHeight: number;
    public stickUp: number;
    public totalStickUp: number;
    public depth: number;
    public previousDepth: number;
    public holeRun: number;
    public coreRecovered: number;
    public coreLoss: number;
    public boreholeLogRecord: string;
    public sampleNumber: string;
    public description: string;
    public creator: string;


    constructor() { }
}
