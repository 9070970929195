import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PeopleData } from './../../../../_core/data/people';
import { ProjectService } from '../../project.service';
import { ProjectMembers } from './project-member.model';
import { Person } from '../../../../_core/data/_models/people.model';

export interface ProjectInfo {
    projectId: string;
    companyId: string;
}

@Component({
    selector: 'project-members',
    templateUrl: './project-members.component.html',
    styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit {
    members: Array<any>;
    projectMembers: Array<ProjectMembers> = [];

    constructor(
        public dialogRef: MatDialogRef<ProjectMembersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjectInfo,
        private _toastr: ToastrService,
        private _projectServ: ProjectService,
        private _peopleServ: PeopleData
    ) {}

    ngOnInit() {
        this._getProjectMembers();
    }

    closeMembers() {
        this.dialogRef.close();
    }

    onMemberAdded(added: boolean) {
        if (added === true) this.dialogRef.close();
    }

    onMemberDeleted(id: string) {
        this._projectServ.deleteProjectMember(this.data.projectId, id).subscribe(() => {
            this._toastr.success("Member deleted successfully", "Member deleted");
            this._getProjectMembers();
        }, err => {
            this._toastr.error(err.message, "Failed to delete Member");
        });
    }

    private _getProjectMembers() {
        this._projectServ.getProjectMembers(this.data.projectId).subscribe(res => {
            this.members = res as Array<any>;
            this._getMembers();
        }, err => {
            this._toastr.error(err.message, "Failed to get Members");
        });
    }

    private _getMembers() {
        const ids = [];
        this.members.forEach(m => {
            ids.push(m.personId);
        });

        if (ids.length <= 0) return;

        this._peopleServ.getPeopleByIdList(ids).subscribe(res => {
            this._populateMembers(res);
        }, err => {
            this._toastr.error(err.message, 'Failed to retrive members');
        });
    }

    private _populateMembers(people: Array<any>) {
        while(this.projectMembers.length > 0) this.projectMembers.pop();
        const temp = [];

        people.forEach(p => {
            const currM = this.members.find(m => m.personId === p.id);

            temp.push(<ProjectMembers>{
                id: currM.id,
                personId: p.id,
                project: currM.project,
                deleted: currM.deleted,
                projectRoles: currM.projectRoles,
                nonBgiRole: currM.nonBgiRole,
                extra: p.name + ' ' + p.surname
            });
        });

        this.projectMembers = temp;
    }
}
