import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatAutocomplete, MatChipInputEvent, MatDialogConfig, MatDialog } from '@angular/material';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlTree, ActivatedRoute } from '@angular/router';
import { TrialPit } from '../../../../../shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveFlagEnum } from '../../../../../shared-kernel/enumerations/active-flag-enum';
import { SoilLogService } from '../soil-log/soil-log.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { UnitEnum } from '../../../../../shared-kernel/enumerations/unit-enum';
import { Village } from 'src/app/shared-kernel/entity/common/Village';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { TrialPitCreateComponent } from '../trial-pit-create/trial-pit-create.component';
declare var $: any;

@Component({
  selector: 'app-trial-pit-list',
  templateUrl: './trial-pit-list.component.html',
  styleUrls: ['./trial-pit-list.component.scss']
})
export class TrialPitListComponent implements OnInit {
  dataSourceTrialPits: MatTableDataSource<TrialPit> = new MatTableDataSource();
  selectedRecord: TrialPit = new TrialPit();
  displayedTrialPitColumns = ['Pit', 'TrialPitNumber', 'Project', 'Location', 'Start Date', 'View', 'Edit', 'Delete'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['#', 'TrialPitNumber', 'ProjectName', 'Stability',
    'District', 'LocationCoordinates', 'Comments', 'TrailPitDates'];

  dataSource: MatTableDataSource<TrialPit> = new MatTableDataSource();
  enumUnits: typeof UnitEnum = UnitEnum;

  url: string;
  trialPitId: {};
  trialPitToDelete: TrialPit;
  trialPits: TrialPit[];

  panelOpenState: boolean = false;
  village: string;
  activeFlagEnum: typeof ActiveFlagEnum = ActiveFlagEnum;
  exportType: any;
  reportName: any;
  setExport: string;
  villages: Village[];

  recordEdit = false;
  borehole: Borehole;
  loading: boolean;

  constructor(
    private router: Router,
    private soilLogService: SoilLogService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[3].parameters;
    this.trialPitId = urlData;
  }

  ngOnInit() {
    this.getAllTrialPits();
    this.trialPits = [];
    this.exportType = 'Export';
    this.spinner.hide(); // temp fix for the spinner thats always showing on this page
    this.dataSourceTrialPits.paginator = this.paginator;
    this.dataSourceTrialPits = new MatTableDataSource(this.trialPits);

  }

  resetPaginator() {
    this.dataSourceTrialPits.sort = this.sort;
    this.dataSourceTrialPits.paginator = this.paginator;
  }

  sanitizeTrialPitsList() {
    this.trialPits.forEach(element => {
      element.project = null;
      element.excavationMethod = null;
      element.equipment = null;
      element.stability = null;
      element.locationImages = null;
    });
  }

  redirectoMap() {
    this.sanitizeTrialPitsList();
    localStorage.setItem('list', JSON.stringify(this.trialPits));
    const from = { list: 'trial-pits' };
    this.router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/locations', from]);
  }

  applyFilter(filterValue: string) {
    this.dataSourceTrialPits.filter = filterValue.trim().toLowerCase();
  }

  openTrialPitCreateForm() {
    const newUserDialogRef = this.dialog.open(TrialPitCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true
    });
    newUserDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllTrialPits();
        this.dataSourceTrialPits = new MatTableDataSource(this.trialPits);
        this.resetPaginator()
      }
    });
  }

  openTrialPitEditForm(trialPit: TrialPit) {
    const newUserDialogRef = this.dialog.open(TrialPitCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: trialPit
    });
    newUserDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllTrialPits();
        this.dataSourceTrialPits = new MatTableDataSource(this.trialPits);
        this.resetPaginator();
      }
    });
  }

  openSoilLogCreateForm(trialPitId) {
    const idObject = { id: trialPitId };
    this.router.navigate(['portal/applied-geoscience/geo-tech/soil-log/create', idObject]);
  }

  openSoilLogs(trialPitId) {
    const idObject = { id: trialPitId };
    this.router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', idObject]);
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  getAllTrialPits() {
    this.loading = true;
    this.soilLogService.getAllActiveTrialPits(this.activeFlagEnum.Active).subscribe(data => {
      this.trialPits = data as TrialPit[];
      this.trialPits.sort((a, b) => (a.project.name || '').toString().localeCompare((b.project.name || '').toString())
      || (a.trialPitNumber || '').toString().localeCompare((b.trialPitNumber || '').toString()));
      this.dataSourceTrialPits.data = data as TrialPit[];
      this.dataSourceTrialPits = new MatTableDataSource(this.trialPits);
      this.resetPaginator();
    }, err => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  onDeleteRecord(trialPit: TrialPit) {
    this.spinner.show();
    this.soilLogService.createTrialPit(trialPit).subscribe(data => {
      $('#deleteModal').modal('hide');
      this.successmsg('Delete Trial Pit', 'You have Successfully deleted Trial Pit record');
      this.spinner.hide();
      this.getAllTrialPits();
      this.dataSourceTrialPits = new MatTableDataSource(this.trialPits);
    }, error => {
      this.errorsmsg('Error', error.error.message);
      this.spinner.hide();
    },
      () => {
        this.spinner.hide();
      }
    );
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }

  deleteRecord() {
    this.onDeleteRecord(this.trialPitToDelete);
    this.getAllTrialPits();
    this.dataSourceTrialPits = new MatTableDataSource(this.trialPits);
  }

  deleteRecordRequest(trialPit) {
    this.trialPitToDelete = trialPit;
    this.trialPitToDelete.active = this.activeFlagEnum.Deactive;
    $('#deleteModal').modal({ backdrop: 'static' });
    $('#deleteModal').modal('show');
  }

  exportDocument(expType: string) {
    this.exportType = expType;
    this.dataSource.paginator = this.paginator;
    this.dataSource = new MatTableDataSource(this.trialPits);
    $('#reportModal').modal('show');
  }

  saveFileAs() {
    $('#reportNameModal').modal('show');
  }

  saveDocument() {
    if (this.exportType == "pdf") {
      this.exportToPDF();
    } else {
      this.exportToCSV();
    }

    $('#reportNameModal').modal('hide');
    $('#reportModal').modal('hide');
  }

  exportToPDF() {

    var columns = [
      { title: "", dataKey: "id" },
      { title: "PIT NUMBER", dataKey: "pit_number" },
      { title: "PROJECT NAME", dataKey: "project_name" },
      { title: "EXCAVATION", dataKey: "excavation" },
      { title: "STABILITY", dataKey: "stability" },
      { title: "LOCATION", dataKey: "location" },
      { title: "COORDINATES", dataKey: "coordinates" },
      { title: "DS-DATE", dataKey: "drill_start_date" },
      { title: "DE-DATE", dataKey: "drill_end_date" },
      { title: "COMMENTS", dataKey: "comments" },
    ];
    var rows = [];

    this.dataSource.data.forEach(function (tp, i) {
      rows.push({
        'id': (++i), 'pit_number': tp.trialPitNumber, 'project_name': tp.project.name, 'excavation': tp.excavationMethod.name, 'stability': tp.stability,
        'location': tp.location.village, 'coordinates': (`Latitude: ${tp.location.coordinates.latitude} , Longitude: ${tp.location.coordinates.longitude}`),
        'project_manager': tp.projectManager, 'coordinator': tp.coordinator, 'geologist': tp.geologist, 'drill_start_date': tp.startDate.toString().substring(0, 10),
        'drill_end_date': tp.endDate.toString().substring(0, 10), 'status': tp.status, 'comments': tp.generalComments
      });
    });

    var doc = new jsPDF('l', 'pt', 'a4');
    doc.autoTable(columns, rows, {
      styles: { fontSize: 6 },
      margin: { top: 60 },
      beforePageContent: function (data) {
        doc.text("TRIAL PIT LOGS", 40, 30);
      }
    });

    doc.save(this.reportName + '.pdf');
  }

  exportToCSV() {
    var data = [];
    this.dataSource.data.forEach(function (tp, i) {
      data.push({
        'project_name': tp.project.name, 'excavation': tp.excavationMethod.name, 'stability': tp.stability,
        'location': tp.location.village, 'coordinates': (`Latitude: ${tp.location.coordinates.latitude} ,
        'Longitude: ${tp.location.coordinates.longitude}`), 'drill_start_date': tp.startDate.toString().substring(0, 10),
        'drill_end_date': tp.endDate.toString().substring(0, 10), 'comments': tp.generalComments
      });
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'TRIAL PIT LOGS',
      useTextFile: false,
      useBom: true,
      filename: this.reportName,
      // useKeysAsHeaders: true,
      headers: ["PROJECT NAME", "EXCAVATION", "STABILITY", "LOCATION", "COORDINATES", "DS-DATE", "DE-DATE", "COMMENTS"]
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }
}

