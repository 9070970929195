import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { MineralResourcesComponent } from './mineral-resources.component';

import {NgModule} from '@angular/core';
import {MineralResourcesRouting} from './mineral-resources.routing';

import { SharedModule } from '../shared/shared.module';
import { LiteratureReviewListComponent } from './literature-review/literature-review-list/literature-review-list.component';
import { InputDataListComponent } from './input-data-list/input-data-list.component';
import { OutputDataListComponent } from './output-data-list/output-data-list.component';

@NgModule({
  declarations: [
    MineralResourcesComponent,
    LiteratureReviewListComponent,
    ReportsListComponent,
    InputDataListComponent,
    OutputDataListComponent,
  ],
  imports: [
    MineralResourcesRouting,
    SharedModule,
  ],
  entryComponents: [],
  exports: [
  ]
})
export class MineralResourcesModule {
}
