import { BoreholeType } from '../common/boreholeType';
import { CoreTrays } from './CoreTrays';
import { DrillingConsumables } from './DrillingConsumables';
import { DrillRig } from './DrillRig';
import { MudPumpSpares } from './MudPumpSpares';
import { DrillingString } from './DrillingString';
import { DrillingTools } from './DrillingTools';
import { DrillingRequest } from './drilling-request';

export class DrillingChecklist {
    id: string;
    drillRig: DrillRig = new DrillRig();
    mudPumpSpares: MudPumpSpares = new MudPumpSpares();
    drillingString: DrillingString = new DrillingString();
    drillingTools: DrillingTools = new DrillingTools();
    drillingConsumables: DrillingConsumables = new DrillingConsumables();
    coreTrays: CoreTrays = new CoreTrays();
    drillingRequest: DrillingRequest = new DrillingRequest();
    creator: String;
    created: Date;
 }
