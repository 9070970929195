import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discontinuity-log',
  template: `<router-outlet></router-outlet>`
})
export class DiscontinuityLogComponent implements OnInit {


  constructor() {
  }

  ngOnInit() {
  }

}
