import {BusinessUnitData} from '../data/BusinessUnitData';
import {BusinessUnit} from '../../common/entity/security/role/business-unit';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {District} from '../data/_models';
import {ServiceEndPoints} from '../../shared-kernel/apiCalls/ServiceEndPoints';
import {Inject, Injectable} from '@angular/core';

@Injectable()
export class BusinessService extends BusinessUnit {
  constructor(private http: HttpClient) {
    super();
  }

  getBusinessUnits(): Observable<Array<BusinessUnit>> {
    return this.http.get<Array<BusinessUnit>>(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getAllBusinessUnitsUrl);
  }
}
