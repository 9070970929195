import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAuthoritiesService } from 'src/app/_auth/services/user-authorities';

@Directive({
    selector: '[ifNotAuthority]'
})
export class IfNotAuthorityDirective {

    constructor(
        private templateRef : TemplateRef<any>,
        private viewContainer : ViewContainerRef,
        private authService : UserAuthoritiesService
    ) {}

    @Input() set ifNotAuthority(authorityName: string | Array<string>) {
        let validities: Array<boolean> = [];

        if (typeof authorityName === 'string') {
            this.authService.hasAuthority(authorityName).then(hasAuthority => {
                validities.push(hasAuthority);
                this.logic(validities);
            });
            
        } else {
            let promises = [];
            authorityName.forEach(authority => {
                promises.push(new Promise((resolve, reject) => {
                    this.authService.hasAuthority(authority).then(hasAuthority => {
                        resolve(hasAuthority);
                    }, () => {
                        reject(null);
                    });
                }));
            });

            Promise.all(promises).then(results => {
                this.logic(results);
            });
        }

        
    }

    private logic(validities: Array<boolean>) {
        let hasAuthority: boolean;

        if (validities.length === 0) hasAuthority = false;
        else if (validities.length === 1) hasAuthority = validities[0];
        else {
            hasAuthority = validities.reduce((total, curr) => total || curr);
        }

        if (hasAuthority === false) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}