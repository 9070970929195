import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from './../../../../../../environments/environment';
import { Formation, Group, SuperGroup } from './../models/formation.model';
import { SpecimenRockCategory, SpecimenRockType } from '../models/rock-types.model';
import { StoragePlace } from '../models/models';
import { GeologicalSpecimen } from '../models/geological-specimem.model';
import { PreservationHistory } from '../../../../entity/PreservationHistory';
import { ServiceEndPoints } from '../../../../../shared-kernel/apiCalls/ServiceEndPoints';

@Injectable({
    providedIn: 'root'
})
export class SpecimenService {
    constructor(
        private http: HttpClient
    ) {}

    getAllFormations(): Observable<Array<Formation>> {
        return this.http.get<Array<Formation>>(environment.collectionsBaseUrl + '/api/specimen_formation/v1');
    }

    getAllGroups(): Observable<Array<Group>> {
        return this.http.get<Array<Group>>(environment.collectionsBaseUrl + '/api/specimen_formation/v1/groups');
    }

    getAllSuperGroups(): Observable<Array<SuperGroup>> {
        return this.http.get<Array<SuperGroup>>(environment.collectionsBaseUrl + '/api/specimen_formation/v1/super_groups');
    }

    getRockCategories(): Observable<Array<SpecimenRockCategory>> {
        return this.http.get<Array<SpecimenRockCategory>>(environment.collectionsBaseUrl + '/api/specimen_rock_types/v1/rock_categories');
    }

    getRockTypes(): Observable<Array<SpecimenRockType>> {
        return this.http.get<Array<SpecimenRockType>>(environment.collectionsBaseUrl + '/api/specimen_rock_types/v1/rock_types');
    }

    storagePlacements(): Observable<Array<StoragePlace>> {
        return this.http.get<Array<SpecimenRockType>>(environment.collectionsBaseUrl + '/api/storage_placements/v1')
    }

    createSpecimen(specimen: GeologicalSpecimen): Observable<GeologicalSpecimen> {
        return this.http.post<GeologicalSpecimen>(environment.collectionsBaseUrl + '/api/geological_specimen/v1', specimen);
    }

    updateSpecimen(specimen: GeologicalSpecimen): Observable<GeologicalSpecimen> {
        return this.http.put<GeologicalSpecimen>(environment.collectionsBaseUrl + `/api/geological_specimen/v1/${specimen.id}`, specimen);
    }

    getSpecimen(id: string): Observable<GeologicalSpecimen> {
        return this.http.get<GeologicalSpecimen>(environment.collectionsBaseUrl + `/api/geological_specimen/v1/${id}`);
    }

    getSpecimens(): Observable<Array<GeologicalSpecimen>> {
        return this.http.get<Array<GeologicalSpecimen>>(environment.collectionsBaseUrl + '/api/geological_specimen/v1/list');
    }

    getPreservationHistoryByDeleted(id:string){
      return this.http.get(environment.collectionsBaseUrl + `/api/geological_specimen/v1/preservation_history/${id}`);
    }

    getPreservationHistoryById(id:string){
      return this.http.get(environment.collectionsBaseUrl + `/api/geological_specimen/v1/preservation_history/record/${id}`);
    }

    getPreservationHistoryCountById(id:string){
      return this.http.get(environment.collectionsBaseUrl + `/api/geological_specimen/v1/preservation_history/record/${id}/count`);
    }

    getImagesByPresevationId(id:string){
      return this.http.get(environment.collectionsBaseUrl + `/api/geological_specimen/v1/preservation_history/record/${id}/images`);
    }

    createPreservationHistory(preservationHistory: PreservationHistory) {
      return this.http.post(environment.collectionsBaseUrl + '/api/geological_specimen/v1/preservation_history', preservationHistory);
    }

    deletePreservationHistory(preservationHistoryId) {
      return this.http.post(environment.collectionsBaseUrl + '/api/geological_specimen/v1/preservation_history/delete', preservationHistoryId);
    }

    getPersonnel(businessUnit) {
      return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonnelByBusinessUnitUrl+ '/' + businessUnit);
    }
}
