import { Component, Input } from '@angular/core';
import { LocalAuthorities } from './../../../../_auth/_providers/local-authorities';

@Component({
    selector: 'drill-request-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentComponent {
    officer: any;
    state: any;
    
    @Input('state') set s(val: any) {
        this.state = val;

        this._localAuthorities.getPersonByUsername(val.creator).subscribe(res => {
            this.officer = res;
        });
    }

    constructor(
        private _localAuthorities: LocalAuthorities
    ) {}

    getTitle(currentStage: string) {
        switch(currentStage) {
            case 'PENDING_MANAGER_SUBMISSION':
            case 'SUBMITTED_TO_MANAGER':
                return 'Geologist';
            case 'APPROVED_BY_MANAGER':
            case 'PENDING_AT_MANAGER':
            case 'REJECTED_BY_MANAGER':
            case 'SUBMITTED_TO_SUPERINTENDENT':
            case 'APPROVED_FOR_OUTSOURCING':
                return 'Manager';
            case 'REJECTED_BY_SUPERINTENDENT':
            case 'SUBMITTED_TO_MANAGER_BY_SUPERINTENDENT':
                return 'Superintendent';
            default:
                return 'Title Here';
        }
    }
}