import { Component, OnInit, ViewChild } from '@angular/core';
import { Bulletin } from 'src/app/shared-kernel/entity/applied-geoscience/seismology/bulletin';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SeismologyService } from '../seismology.service';
import { FileToUpload } from 'src/app/common/uploaded-files/entity/file';
import { ToastrService } from 'ngx-toastr';
import { UploadedFilesService } from 'src/app/common/uploaded-files/uploaded-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-bulletin-list',
  templateUrl: './bulletin-list.component.html',
  styleUrls: ['./bulletin-list.component.scss']
})
export class BulletinListComponent implements OnInit {


  bulletin: Bulletin;
  bulletins: Bulletin[];
  modalHeader: string;


  constructor(
    private seismologyService: SeismologyService,
    private toaster: ToastrService,
    private fileUploadService: UploadedFilesService
  ) { }

  dataSourceBulletins = new MatTableDataSource();
  displayedBulletinColumns = ['#', 'Name', 'Description', 'File Type', 'Date Created', 'Download', 'Edit', 'Delete'];
  loading = true;
  displayName = 'Browse for a file';
  fileToBeUploaded: FileToUpload = new FileToUpload();
  selectedFile: File = null;
  isFileUploaded: boolean;
  formData: FormData;
  formType: string;

  validation_messages = {
    'name': [
      { type: 'required', message: 'File name is required' },
    ]
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  progress: { percentage: number } = { percentage: 0 };

  ngOnInit() {
    this.getAllBulletins();
    this.bulletin = this.createNewBulletinObject();
    this.bulletin.bulletinFile.name = '';
  }

  createNewBulletinObject(): Bulletin {
    this.bulletin = new Bulletin();
    this.bulletin.bulletinFile = new FileToUpload();
    this.bulletin.bulletinFile.name = '';
    return this.bulletin;
  }

  getAllBulletins() {
    this.seismologyService.getAllBulletins()
    .subscribe(data => {
        this.bulletins = data as Bulletin[];
        this.bulletins = this.bulletins.sort((a, b) => new Date(b.created).getDate() - new Date(a.created).getDate()
        || (a.bulletinFile.name || '').toString().localeCompare((b.bulletinFile.name  || '').toString()));
        this.dataSourceBulletins.data = this.bulletins;
        this.dataSourceBulletins.sort = this.sort;
        this.dataSourceBulletins.paginator = this.paginator;
        this.loading = false;
      }, error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  createBulletinForm() {
    this.modalHeader = 'Upload New Bulletin';
    this.formType = 'Create';
    this.isFileUploaded = false;
    this.bulletin = this.createNewBulletinObject();
    $('#createBulletin').modal({backdrop: 'static'});
    $('#createBulletin').modal('show');
  }

  attachFile() {
    $('#browseFile').click();
  }

  fileChangeListenerBulletin(files: FileList) {
    this.isFileUploaded = true;
    this.selectedFile = files.item(0);
    this.fileToBeUploaded.type = this.getFileExtFromName(this.selectedFile.name);
    this.bulletin.bulletinFile.name = this.selectedFile.name;
  }

  getFileExtFromName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  uploadFile() {
    if (this.formType === 'Create') {
      this.createBulletin();
    }
    if (this.formType === 'Edit') {
      this.editBulletin();
    }
  }

  createBulletin() {
    this.formData = new FormData();
    this.formData.append('file', this.selectedFile);
    this.fileUploadService.uploadFileToServer(this.formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress.percentage = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.fileToBeUploaded.uri = event.body.uri;
        this.fileToBeUploaded.size = event.body.size;
        this.fileToBeUploaded.fileName = event.body.name;
        this.fileToBeUploaded.name = this.bulletin.bulletinFile.name;
        this.fileToBeUploaded.description = this.bulletin.bulletinFile.description;
        this.bulletin.bulletinFile = this.fileToBeUploaded;
        this.bulletin.deleted = false;
        this.seismologyService.createBulletin(this.bulletin).subscribe(response => {
          this.selectedFile = null;
          this.isFileUploaded = false;
          this.bulletin = this.createNewBulletinObject();
          this.getAllBulletins();
          $('#createBulletin').modal('hide');
          $('#resetForm').click();
          $('#uploadFileModal').modal('hide');
        },
          error => {
          }, () => {
          });
      }
    }, error => {
        this.toaster.error('Bulletin upload has failed, please close the dialog and try again...', 'Bulletin Upload Failure');
    },
      () => {
          this.toaster.success('Bulletin has been successfully uploaded', 'Bulletin Upload');
      });
  }

  editBulletin(){
    if(this.selectedFile){
      this.formData = new FormData();
      this.formData.append('file', this.selectedFile);
      this.fileUploadService.uploadFileToServer(this.formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.fileToBeUploaded.uri = event.body.uri;
          this.fileToBeUploaded.size = event.body.size;
          this.fileToBeUploaded.fileName = event.body.name;
          this.fileToBeUploaded.name = this.bulletin.bulletinFile.name;
          this.fileToBeUploaded.description = this.bulletin.bulletinFile.description;
          this.bulletin.bulletinFile = this.fileToBeUploaded;
          this.bulletin.deleted = false;
          this.seismologyService.createBulletin(this.bulletin).subscribe(response => {
            this.selectedFile = null;
            this.isFileUploaded = false;
            this.bulletin = this.createNewBulletinObject();
            this.getAllBulletins();
            $('#createBulletin').modal('hide');
            $('#resetForm').click();
            $('#uploadFileModal').modal('hide');
          },
            error => {
            }, () => {
            });
        }
      }, error => {
          this.toaster.error('Bulletin update has failed, please close the dialog and try again...', 'Bulletin Update Failure');
      },
        () => {
            this.toaster.success('Bulletin has been successfully Updated', 'Bulletin Update');
        });
    } else {
      this.seismologyService.createBulletin(this.bulletin).subscribe(event => {
        this.isFileUploaded = false;
        this.bulletin = this.createNewBulletinObject();
        this.getAllBulletins();
        $('#createBulletin').modal('hide');
      }, error => {
          this.toaster.error('Bulletin update has failed, please close the dialog and try again...', 'Bulletin Update Failure');
      },
        () => {
            this.toaster.success('Bulletin has been successfully Updated', 'Bulletin Update');
        });

    }

  }

  deleteBulletin() {
    this.bulletin.deleted = true;
    this.seismologyService.createBulletin(this.bulletin).subscribe(event => {
      this.isFileUploaded = false;
      this.bulletin = this.createNewBulletinObject();
      this.getAllBulletins();
      $('#deleteModal').modal('hide');
    }, error => {
        this.toaster.error('Bulletin deleting has failed, please close the dialog and try again...', 'Bulletin delete Failure');
    },
      () => {
          this.toaster.success('Bulletin has been successfully deleted', 'Bulletin Delete');
      });

  }

  downloadFile(file: FileToUpload) {
    this.fileUploadService.downloadFile(file.fileName).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      if (file.fileName) {
        downloadLink.setAttribute('download', file.fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    });
  }

  openEditForm(bulletin: Bulletin) {
    this.modalHeader = 'Edit/Update Bulletin';
    this.formType = 'Edit';
    this.bulletin = bulletin;
    this.isFileUploaded = true;
    $('#createBulletin').modal({backdrop: 'static'});
    $('#createBulletin').modal('show');
  }

  deleteRecordRequest(bulletin: Bulletin) {
    this.bulletin = bulletin;
    $('#deleteModal').modal({backdrop: 'static'});
    $('#deleteModal').modal('show');
  }

  applyFilter(filterValue: string) {
    this.dataSourceBulletins.filter = filterValue.trim().toLowerCase();
  }

  closeAddModal() {
    $('#createBulletin').modal('hide');
    $('#deleteModal').modal('hide');
  }

}
