import { Component, OnInit } from '@angular/core';
import {ApprovalState} from '../../../applied-geoscience/models';
import {Person} from '../../../common/entity/security/profile/person';
import {State} from '../../../activities/models/state';
import {Router} from '@angular/router';
import {UserDashboardService} from '../../user-dashboard/user-dashboard.service';
import {UserService} from '../../../auth/user/user.service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'app-minerals-dashboard',
  templateUrl: './minerals-dashboard.component.html',
  styleUrls: ['./minerals-dashboard.component.scss']
})
export class MineralsDashboardComponent implements OnInit {
  private userName: string;
  private userReference: string;
  private authorities;
  private roles;
  numberOfDrafts: any;
  numberOfActivities: any;
  numberOfFilesSubbittedToManager: any;
  nigisFileActivityCountsResponseDto: any;
  nigisFileSeniorCountsResponseDto: any;
  nigisFileManagerCountsResponseDto: any;
  approvalEnum: typeof ApprovalState = ApprovalState;
  person: Person;

  state = new State();

  constructor(private _router: Router,
              private userDashBoardService: UserDashboardService,
              private userService: UserService) {
    const jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userReference = data.jti;
    this.authorities = data.userAuthorises;
    this.roles = data.roles;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(res => {
        this.person = res as Person;
      });
  }

  ngOnInit() {
    this.getActivitiesCount();
    this.getSeniorActivitiesCount();
    this.getManagerActivitiesCount();
  }


  getMyFilesActivitiesCount() {
    this.userDashBoardService.getMyFilesActivitiesCount().subscribe(number => {
      this.numberOfActivities = number;
    });
  }

  getActivitiesCount() {
    this.userDashBoardService.getActivitiesCounts('individual').subscribe(value => {
        this.nigisFileActivityCountsResponseDto = value;
    });
  }



  getSeniorActivitiesCount() {
    this.userDashBoardService.getActivitiesCounts('senior').subscribe(value => {
      this.nigisFileSeniorCountsResponseDto = value;
    });
  }

  getManagerActivitiesCount() {
    this.userDashBoardService.getActivitiesCounts('manager').subscribe(value => {
      this.nigisFileManagerCountsResponseDto = value;
    });
  }

  goTo(type: string, filter: string, role: string) {
    this._router.navigate([`portal/activities/mineral-resources-files/${type}/${filter}/${role}`]);
  }
}
