import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roles',
  template: `<router-outlet></router-outlet>`
})
export class RolesComponent implements OnInit {
  title = "User Roles";
  constructor() { }

  ngOnInit() {
  }

}
