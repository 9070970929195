import {ProjectRole} from './project-role';

export class Members {
    id: string;
    project: string = '';
    member: string = '';
    projectRoles: ProjectRole [] = [];
    nonBgiRole: string = '';
    deleted: boolean = false;
    username: string;
    profession: string;

    constructor () {
    }
  }
