import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoordinatesService } from '../../../../shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import { CommonService } from '../../../../common/common.service';
import { CompanyService } from '../../../../applied-geoscience/company/company.service';
import { District } from '../../../../common/entity/security/util/common/district';
import { Village } from '../../../../shared-kernel/entity/common/Village';
import { BoreholeType } from '../../../../shared-kernel/entity/common/boreholeType';
import { DrillingMethod } from '../../../../shared-kernel/entity/driller/drilling-method';
import { DrillerService } from '../../../driller.service';
import { GeoTechService } from '../../../../applied-geoscience/geo-tech/geo-tech.service';
import { CoordinatesTypesEnum } from '../../../../shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from '../../../../shared-kernel/enumerations/coordinates-formats-enumt';
import { UnitEnum } from '../../../../shared-kernel/enumerations/unit-enum';
import { SampleType } from '../../../../shared-kernel/entity/common/sample-type';
import { Size } from '../../../../shared-kernel/entity/common/size';
import { ToastrService } from 'ngx-toastr';
import LatLon from 'geodesy/latlon-nvector-spherical';
import { Coordinate } from '../../../../shared-kernel/entity/common/coordinatesValidator/coordinate';
import { DrillingRequestBorehole } from '../../../../shared-kernel/entity/driller/drilling-request-borehole';

@Component({
  selector: 'app-add-drborehole',
  templateUrl: './add-drborehole.component.html',
  styleUrls: ['./add-drborehole.component.scss']
})
export class AddDRBoreholeComponent implements OnInit {

  title: string = 'Create Borehole';
  villages: Village[];
  districts: District[];
  boreholeTypes: BoreholeType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  sampleTypes: SampleType [];
  botswanaPolygon: any[];
  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  enumUnits: typeof UnitEnum = UnitEnum
  drillingMethods: DrillingMethod[];
  drillingRequestBorehole: DrillingRequestBorehole = new DrillingRequestBorehole();
  boreholeCreateForm: FormGroup;
  latLonArray: Coordinate = new Coordinate();
  constructor( private _router: Router,private coordinatesService: CoordinatesService,private toastr: ToastrService,
               private commonService: CommonService, private  companyService: CompanyService,private drillerService: DrillerService,private geotechService: GeoTechService,
               private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddDRBoreholeComponent>, @Inject(MAT_DIALOG_DATA) public data: DrillingRequestBorehole) { }

  ngOnInit() {
    if (this.data.id) {
      this.title = 'Edit Borehole';
    }
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;

    this.getAlBoreholeTypes() ;
    this.getAllDrillingMethods();
    this.initForms();
    this.getAllVillages();
    this.getAllDistricts();
    this.getAllSampleTypes();
    this.getBotswanaGeofence()
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };

    this.selectedCoordinatesType = selectedData.text;

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    }
    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
    }
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });
  }


  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };

    this.selectedCoordinatesFormat = selectedData.text;
  }

  onDistrictChange() {

    let district = this.boreholeCreateForm.controls.district.value;
    this.commonService.getVillagesByDistrictId(district.id)
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => ( a.name || '' ).toString().localeCompare(( b.name || '' ).toString()));
      });
  }

  onVillageChange() {
    let village = this.boreholeCreateForm.controls.village.value;
    this.boreholeCreateForm.controls.district.setValue(village.district);
  }


  getAllDrillingMethods() {
    this.drillerService.getAllDrillingMethods()
      .subscribe(data => {
        this.drillingMethods = data as DrillingMethod[];
      });
  }

  getAlBoreholeTypes() {
    this.geotechService.getAllBoreholeTypes()
      .subscribe(data => {
        this.boreholeTypes = data as BoreholeType[];

      });
  }

  private initForms() {

    this.boreholeCreateForm = this.formBuilder.group({
      drillingDate : [this.data.id ? new Date(this.data.drillingDate) : null, Validators.required],
      boreholeNumber : [this.data.id ? this.data.boreholeNumber : null, Validators.required],
      boreholeType : [this.data.id ? this.data.boreholeType : this.data.drillingRequest.boreholeType, Validators.required],
      drillingMethod : [this.data.id ? this.data.drillingMethod : this.data.drillingRequest.drillingMethod, Validators.required],
      sampleType : [this.data.id ? this.data.sampleTypes : null, Validators.required],
      plannedDepth : [this.data.id ? this.data.plannedDepth.measure : null, Validators.required],
     // holeDiameter : [this.data.id ? this.data.holeDiameter.measure : null, Validators.required],
      holeDiameter: [this.data ? this.data.holeDiameterString ? this.data.holeDiameterString : '' : '', Validators.required],
      sampleSizeUnit : [this.data.id ? this.data.holeDiameter.unit.unit : null, Validators.required],
      sampleSize : [this.data.id ? this.data.sampleSize.measure : null, Validators.required],
      district : [this.data.id ? this.data.locationTemp.village.district : null, Validators.required],
      village : [this.data.id ? this.data.locationTemp.village : null, Validators.required],
      comments : [this.data.id ? this.data.comments.description : null],
      coordinatesFormat: [this.data.id ? this.data.coordinatesFormat.format : null, Validators.required],
      latitude: [this.data.id ? this.data.locationTemp.coordinates.latitude : ''],
      longitude: [this.data.id ? this.data.locationTemp.coordinates.longitude : ''],
      coordinatesType: [this.data.id ? this.data.coordinatesFormat.type : '', Validators.required],
      dmsSx: [this.data.id ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._lonSValue) : ''],// decimal minutes seconds, to hold x/longitude seconds value
      dmsMx: [this.data.id ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._lonMValue) : ''], // decimal minutes seconds, to hold x/longitude minutes value
      dmsDx: [this.data.id ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._lonDValue) : ''], // decimal minutes seconds, to hold x/longitude decimal value
      dmsSy: [this.data.id ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._latSValue) : ''], // decimal minutes seconds, to hold y/latitude seconds value
      dmsMy: [this.data.id ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._latMValue) : ''],// decimal minutes seconds, to hold y/latitude minutes value
      dmsDy: [this.data.id ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._latDVale) : ''],// decimal minutes seconds, to hold y/latitude decimal value
      zone34SNorthing: [this.data.id ? this.coordinatesService.convertLatLonToUtm(34, this.data.locationTemp.coordinates.latitude, this.data.locationTemp.coordinates.longitude)._lat : ''],
      zone34SEasting: [this.data.id ? this.coordinatesService.convertLatLonToUtm(34, this.data.locationTemp.coordinates.latitude, this.data.locationTemp.coordinates.longitude)._lon : ''],
      zone35SNorthing: [''],
      zone35SEasting: [''],

    });


    this.boreholeCreateForm.controls.sampleSizeUnit.setValue(this.enumUnits.gram)

  }

  getAllSampleTypes() {
    this.drillerService.getAllSampleTypes()
      .subscribe(data => {
        this.sampleTypes = data as SampleType[];
      });
  }

  getAllDistricts() {
    this.companyService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => ( a.name || '' ).toString().localeCompare(( b.name || '' ).toString()));
      });
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => ( a.name || '' ).toString().localeCompare(( b.name || '' ).toString()));
      });
  }

  compareFn(objectOne: any, objectTwo: any): boolean {
    return objectOne && objectTwo ? objectOne.id === objectTwo.id : objectOne === objectTwo;
  }

  compareStrings(objectOne: any, objectTwo: any): boolean {
    return objectOne && objectTwo ? objectOne=== objectTwo : objectOne === objectTwo;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit(){
    if(this.data.id){
      this.drillingRequestBorehole = this.data;
    }

    this.drillingRequestBorehole.drillingRequest = this.data.drillingRequest;
    this.drillingRequestBorehole.sampleSize.measure = this.boreholeCreateForm.controls.sampleSize.value;
    this.drillingRequestBorehole.sampleSize.unit.id = this.boreholeCreateForm.controls.sampleSizeUnit.value;
    this.drillingRequestBorehole.holeDiameter.measure = null;
    this.drillingRequestBorehole.holeDiameter.unit.id = this.enumUnits.meter;
    this.drillingRequestBorehole.holeDiameterString = this.boreholeCreateForm.controls.holeDiameter.value;
    this.drillingRequestBorehole.plannedDepth.measure = this.boreholeCreateForm.controls.plannedDepth.value;
    this.drillingRequestBorehole.plannedDepth.unit.id = this.enumUnits.meter;
    this.drillingRequestBorehole.sampleTypes = this.boreholeCreateForm.controls.sampleType.value;
    this.drillingRequestBorehole.drillingMethod = this.boreholeCreateForm.controls.drillingMethod.value;
    this.drillingRequestBorehole.boreholeType = this.boreholeCreateForm.controls.boreholeType.value;
    this.drillingRequestBorehole.boreholeNumber = this.boreholeCreateForm.controls.boreholeNumber.value;
    this.drillingRequestBorehole.drillingDate = this.boreholeCreateForm.controls.drillingDate.value;
    this.drillingRequestBorehole.locationTemp.village = this.boreholeCreateForm.controls.village.value;
    this.drillingRequestBorehole.coordinatesFormat.type = this.boreholeCreateForm.controls.coordinatesType.value;
    this.drillingRequestBorehole.coordinatesFormat.format = this.boreholeCreateForm.controls.coordinatesFormat.value;
    this.drillingRequestBorehole.locationTemp.coordinates.latitude = this.boreholeCreateForm.controls.latitude.value;
    this.drillingRequestBorehole.locationTemp.coordinates.longitude = this.boreholeCreateForm.controls.longitude.value;
    this.drillingRequestBorehole.comments.description = this.boreholeCreateForm.controls.comments.value;

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.drillingRequestBorehole.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.boreholeCreateForm.controls.dmsDy.value, this.boreholeCreateForm.controls.dmsMy.value, this.boreholeCreateForm.controls.dmsSy.value
          , this.boreholeCreateForm.controls.dmsDx.value, this.boreholeCreateForm.controls.dmsMx.value, this.boreholeCreateForm.controls.dmsSx.value)._lat;
      this.drillingRequestBorehole.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.boreholeCreateForm.controls.dmsDy.value, this.boreholeCreateForm.controls.dmsMy.value, this.boreholeCreateForm.controls.dmsSy.value, this.boreholeCreateForm.controls.dmsDx.value
          , this.boreholeCreateForm.controls.dmsMx.value, this.boreholeCreateForm.controls.dmsSx.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.drillingRequestBorehole.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.boreholeCreateForm.controls.zone34SNorthing.value, this.boreholeCreateForm.controls.zone34SEasting.value)._lat;
      this.drillingRequestBorehole.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.boreholeCreateForm.controls.zone34SNorthing.value, this.boreholeCreateForm.controls.zone34SEasting.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.drillingRequestBorehole.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.boreholeCreateForm.controls.zone35SNorthing.value, this.boreholeCreateForm.controls.zone35SEasting.value)._lat;
      this.drillingRequestBorehole.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.boreholeCreateForm.controls.zone35SNorthing.value, this.boreholeCreateForm.controls.zone35SEasting.value)._lon;
    }
    this.drillingRequestBorehole.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.drillingRequestBorehole.locationTemp.coordinates.latitude,
        this.drillingRequestBorehole.locationTemp.coordinates.longitude)._lat;
    this.drillingRequestBorehole.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.drillingRequestBorehole.locationTemp.coordinates.latitude,
        this.drillingRequestBorehole.locationTemp.coordinates.longitude)._lon;

    if(new LatLon(this.drillingRequestBorehole.locationTemp.coordinates.latitude, this.drillingRequestBorehole.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      if(this.data.id){
        this.drillerService.createDrillingRequestBorehole(this.drillingRequestBorehole).subscribe(
          value => {
            this.closeModal();
          },error => {
            this.toastr.error(error.message, 'Drilling Request Borehole Create');
          },() => {
            this.closeModal();
            this.toastr.success('Borehole Created Successfully', 'Drilling Request Borehole Create');
          });
      }else{
      this.drillerService.drillingBoreholeNumberAlreadyInProject(this.drillingRequestBorehole.boreholeNumber, this.data.drillingRequest.project.id).subscribe(res => {
        if (res.exist === true) {
          this.toastr.error('Borehole Number already exist in project', 'Drilling Request Borehole Create');
        } else {
          this.drillerService.createDrillingRequestBorehole(this.drillingRequestBorehole).subscribe(
            value => {
              this.closeModal();
            },error => {
              this.toastr.error(error.message, 'Drilling Request Borehole Create');
            },() => {
              this.closeModal();
              this.toastr.success('Borehole Created Successfully', 'Drilling Request Borehole Create');
            });
        }
      });
    }
    }else {
      this.toastr.error(this.coordinatesService.outSide);
    }
  }

  onFocusOut() {
    let boreholeNumber = this.boreholeCreateForm.get('boreholeNumber').value.trim();
    if (boreholeNumber && boreholeNumber !== '') {
      this.drillerService.drillingBoreholeNumberAlreadyInProject(boreholeNumber, this.data.drillingRequest.project.id).subscribe(res => {
        if (res.exist === true) {
          this.boreholeCreateForm.get('boreholeNumber').setErrors({ 'exist': true });
        } else {
          if (this.boreholeCreateForm.get('boreholeNumber').hasError('exist')) {
            this.boreholeCreateForm.get('boreholeNumber').setErrors({ 'exist': null });
          }
        }
      });
    }
  }

}
