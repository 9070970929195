import {Component, Inject, OnInit} from '@angular/core';
import {Borehole} from '../../../../../shared-kernel/entity/common/borehole';
import {PenetrationRateLog} from '../../../../../common/penetration-rate/entity/penetrationRate';
import {UnitEnum} from '../../../../../shared-kernel/enumerations/unit-enum';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatTableDataSource} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ScreeningLog} from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/ScreeningLog';
import {ScreeningMaterial} from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/ScreeningMaterial';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CoreService} from '../../../../geo-tech/core/core.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../../../../auth/user/user.service';
import {HydrogeologyService} from '../../../hydrogeology.service';

@Component({
  selector: 'app-borehole-construction-screening-create',
  templateUrl: './borehole-construction-screening-create.component.html',
  styleUrls: ['./borehole-construction-screening-create.component.scss']
})
export class BoreholeConstructionScreeningCreateComponent implements OnInit {

  borehole: Borehole;
  screeningLog: ScreeningLog;
  screeningLogs: ScreeningLog [];
  depth = 0;
  boreholeId: string;
  enumUnits: typeof UnitEnum = UnitEnum;
  screeningMaterial: ScreeningMaterial;
  screeningLogsRecords: Array<ScreeningLog>;
  loading = true;
  dataSource: MatTableDataSource<PenetrationRateLog> = new MatTableDataSource();

  dialogConfig = new MatDialogConfig();

  screeningLogsFormGroup: FormGroup = new FormGroup({
    screeningId: new FormControl('', [Validators.required, Validators.min(0)]),
    depthFrom: new FormControl(null, [Validators.required, Validators.min(0)]),
    depthTo: new FormControl(null, [Validators.required, Validators.min(0)]),
    diameter: new FormControl(null, [Validators.required, Validators.min(0)]),
    slotNumber: new FormControl(''),
    constructionType: new FormControl('', [Validators.required]),
    material: new FormControl(null, [Validators.required]),
    borehole: new FormControl(null)
  });
  screeningMaterials: ScreeningMaterial [];
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<BoreholeConstructionScreeningCreateComponent>,
              private userService: UserService, private hydrogeologyService: HydrogeologyService,
              private dialog: MatDialog, private route: ActivatedRoute,
              private toaster: ToastrService, private coreService: CoreService, private spinner: NgxSpinnerService) {
    this.route.params.subscribe(params => {
      this.boreholeId = params.id;
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.borehole = this.data.borehole;
    this.screeningLog = this.data.screeningLogDataLog;

    this.screeningLogsFormGroup.get('depthFrom').setValue(0);

    if (this.screeningLog) {
      this.populateForm();
    }

    if (this.borehole.constructionScreenings && !this.screeningLog) {
      const previous_log = this.borehole.constructionScreenings[this.borehole.constructionScreenings.length - 1];
      if (previous_log) {
        this.screeningLogsFormGroup.get('depthFrom').setValue((previous_log.depthTo.measure));
      }
    }
    this.getAllConstructionTypes();
  }

  onSubmit() {

    const log = this.screeningLogsFormGroup.value;

    if (this.screeningLog) {

      this.screeningLog.depthTo.measure = log['depthTo'];
      this.screeningLog.depthFrom.measure = log['depthFrom'];
      this.screeningLog.diameter.measure = log['diameter'];
      this.screeningLog.slotNumber = log['slotNumber'];
      this.screeningLog.constructionType = log['constructionType'];
      this.screeningLog.material = log['material'];
      this.screeningLog.screeningId = log['screeningId'];

      log['screeningId'] = this.screeningLog.screeningId;
      log['id'] = this.screeningLog.id;
      log['diameter'] = this.screeningLog.diameter;
      log['slotNumber'] = this.screeningLog.slotNumber;
      log['depthTo'] = this.screeningLog.depthTo;
      log['depthFrom'] = this.screeningLog.depthFrom;
      log['constructionType'] = this.screeningLog.constructionType;
      log['material'] = this.screeningLog.material;

    } else {

      log['borehole'] = this.borehole;
      log['depthTo'] = {
        id: '',
        measure: log['depthTo'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };

      log['depthFrom'] = {
        id: '',
        measure: log['depthFrom'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };
      log['diameter'] = {
        id: '',
        measure: log['diameter'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };
    }
    if ((+log['depthTo'].measure) > (+log['depthFrom'].measure)) {
      this.hydrogeologyService.createBoreholeConstructionScreeningLog(log, this.boreholeId)
        .subscribe(response => {
            this.onClose({event: 'submit', data: log});
            if (this.screeningLog) {
              this.toaster.success('Water screening log updated successfully');
            } else {
              this.toaster.success('Water screening log created successfully');
            }``
          },
          error => {
            this.toaster.error(error.toString());
          });
    } else {
      this.toaster.error('`Depth To` must be greater than `Depth From`', 'Invalid Depth');
      this.screeningLogsFormGroup.get('depthTo').setValue('');
    }
  }

  reset() {
    this.screeningLogsFormGroup.setValue({
      screeningId: '', diameter: '',
      slotNumber: '', depthFrom: null,
      depthTo: null, constructionType: '',
      material: '',
      borehole: '',
    });
    this.onClose({event: 'reset', data: null});
  }

  onClose(actions: any) {
    this.dialogRef.close(actions);
  }

  getAllConstructionTypes() {
    this.hydrogeologyService.getAllConstructionTypes().subscribe(
      data => {
        this.screeningMaterials = data as ScreeningMaterial [];

      }
    );
  }

  populateForm() {
    const log = {
      'screeningId': this.screeningLog.screeningId,
      'borehole': this.borehole,
      'diameter': this.screeningLog.diameter.measure,
      'slotNumber': this.screeningLog.slotNumber,
      'depthFrom': this.screeningLog.depthFrom.measure,
      'depthTo': this.screeningLog.depthTo.measure,
      'constructionType': this.screeningLog.constructionType,
      'material': this.screeningLog.material
    };

    this.screeningLogsFormGroup.setValue(log);
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

}
