import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';


@Component({
  selector: 'app-plot-to-map',
  templateUrl: './plot-to-map.component.html',
  styleUrls: ['./plot-to-map.component.scss']
})
export class PlotToMapComponent implements OnInit {
  listType: string;
  screenHeight: number;
  boreholeList: Borehole[];
  zoom = 6;
  latitude: number = -21.916715;
  longitude: number = 24.225747;

  trialPitsList: any;
  infiltrationTests: any;
  dcpFoundationList: any;
  dcpRoadworksList: any;
  sample: any;
  pageTitle: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => {
      this.listType = params.list;
      if (this.listType === 'trial-pits') {
        this.trialPitsList = JSON.parse(localStorage.getItem('list'));
        this.pageTitle = 'Trial Pits';
      } else if (this.listType === 'infiltration-tests'){
        this.infiltrationTests = JSON.parse(localStorage.getItem('list'));
        this.pageTitle = 'Infiltration Tests';
      } else if (this.listType === 'dcp-foundation'){
        this.dcpFoundationList = JSON.parse(localStorage.getItem('list'));
        this.pageTitle = 'DCP Foudation';
      } else if (this.listType === 'dcp-roadworks'){
        this.dcpRoadworksList = JSON.parse(localStorage.getItem('list'));
        this.pageTitle = 'DCP Roadworks';
      } else if (this.listType === 'samples'){
        this.sample = JSON.parse(localStorage.getItem('list'));
        this.pageTitle = 'Samples';
      } else {
        this.boreholeList = JSON.parse(localStorage.getItem('list'));
        this.pageTitle = 'Borehole';
      }
    });
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
  }

  ngOnInit() {
    this.screenHeight = window.innerHeight - 250;
  }

  navBack() {
    if (this.listType === 'water') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/water-borehole/list', {type: 'Water'}]);
    } else if (this.listType === 'bgi-borehole') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/list', {type: 'BGI'}]);
    } else if (this.listType === 'pl-borehole') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/list', {type: 'PL'}]);
    } else if (this.listType === 'infiltration-tests') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/list']);
    } else if (this.listType === 'dcp-foundation') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/list']);
    } else if (this.listType === 'dcp-roadworks') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/list']);
    }else if (this.listType === 'samples') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/samples/list']);
    } else {
      this.router.navigate(['portal/applied-geoscience/geo-tech/trial-pit']);
    }

  }

  detailedView(id: string) {
    const idObj = { id: id };
    if (this.listType === 'water') {
      this.router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details', {id: id, type: 'Water'}]);
    } else if (this.listType === 'bgi-borehole') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/details', {id: id, type: 'BGI'}]);
    } else if (this.listType === 'pl-borehole') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/details', {id: id, type: 'PL'}]);
    } else if (this.listType === 'infiltration-tests') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', idObj]);
    } else if (this.listType === 'dcp-foundation') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/detail', idObj]);
    } else if (this.listType === 'dcp-roadworks') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/detail', idObj]);
    }else if (this.listType === 'samples') {
      this.router.navigate(['portal/applied-geoscience/geo-tech/samples/list/details', idObj]);
    } else {
      this.router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', idObj]);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('list');
  }

}

