import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ServiceEndPoints} from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import {ToastrService} from 'ngx-toastr';
import { SoilLog } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/soil-log';
import { TrialPit } from 'src/app/shared-kernel/entity/applied-geoscience/soil-log/trial-pit';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SoilLogService implements OnInit {

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  createSoilLog(soilLog: SoilLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createSoilLogUrl,
      soilLog);
  }

  createBatchSoilLog(soilLogs) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/soil/v1/soil-logs/create',
      soilLogs);
  }

  getAllSoilLogRecords() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSoilLogRecordsUrl);
  }

  getAllActiveTrialPits(activeFlag) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllActiveTrialPitsUrl + '/' + activeFlag);
  }
  getAllSoilLogsByTrialPitId(trialPit: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSoilLogsByTrialPitIdUrl + '/' + trialPit);
  }

  getTrialPitById(trialPit: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getTrialPitByIdUrl + '/' + trialPit);
  }


  getAllExcavationMethods() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllExcavationMethodsUrl);
  }

  getAllProjects() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllProjectsUrl);
  }

  getAllTrialPits() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllTrialPitsUrl);
  }

  errorsmsg() {
    this.toastr.error('Unsuccessful');
  }

  successmsg() {
    this.toastr.success('Success');
  }

  createTrialPit(trialPit: TrialPit) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createTrialPitUrl,
      trialPit);
  }

  getAllPersons(){
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.personsUrl);
  }

  getSinglePerson(referenceID: any){
    return this.http.get(ServiceEndPoints.authBaseUrl + ServiceEndPoints.getPersonByIdUrl + "/" + referenceID);
  }

  trialPitInProject(pitNumber: string, projectId: string): Observable<{ exist: boolean, message: string }> {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/trial_pits/v1/check/${pitNumber}/project/${projectId}`);
  }

  getSoilLogById(soilLogId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/soil/v1/soil-log' + '/' + soilLogId);
  }
}
