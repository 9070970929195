import { UserService } from './../../../auth/user/user.service';
import { UserDashboardService } from './../user-dashboard.service';
import { State } from './../../../activities/models/state';
import { ApprovalState, ApproveAction } from './../../../applied-geoscience/models/approval-state';
import { Person } from './../../../_core/data/_models/people.model';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {

  title = 'User Dashboard';
  numberOfBGIProjects: number;
  numberOfMyProjects: number;
  numberOfProjectsActivitiesSubmittedToManager: number;
  private userName: string;
  private userReference: string;
  private authorities;
  private roles;
  numberOfActivities: any;
  numberOfDrafts: any;
  person: Person;
  issetDataMyActivities = false;
  issetDataMyProject = false;

  myactivities = {};

  myproject = {
    numberOfProjectsDrafts:0,
    numberOfProjectsSubmittedToQA:0,
    numberOfProjectsSubmittedToManager:0,
    numberOfProjectsApproved:0,
    numberOfProjectsReturned:0
  };

  state = new State();

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private userService: UserService) {

    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userReference = data.jti;
    this.authorities = data.userAuthorises;
    this.roles = data.roles;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
  }

  ngOnInit() {
    this.getMyProjectsCount();
    this.getUserProjectActivities();
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectApprovalCount().subscribe(result => {
      let projectApprovals = result as [];
      this.myproject['numberOfMyProjects'] =0;
      projectApprovals.forEach(value => {
        this.myproject['numberOfMyProjects'] =  this.myproject['numberOfMyProjects']  + value['counts'];
        switch(value['state']) {
          case ApprovalState.PENDING_MANAGER_SUBMISSION:
            this.myproject.numberOfProjectsDrafts = value['counts'];
            break;
          case ApprovalState.SUBMITTED_TO_MANAGER:
            this.myproject.numberOfProjectsSubmittedToManager = value['counts'];
            break;
          case ApprovalState.REJECTED_BY_MANAGER:
            this.myproject.numberOfProjectsReturned =  value['counts'];
            break;
          case ApprovalState.PUBLISHED:
            this.myproject.numberOfProjectsApproved = value['counts'];
            break;
          default:
            break;
        };

      });

      this.issetDataMyProject = true;
    });

  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.myactivities['numberOfBGIProjects'] = number;
    });
  }

  getUserProjectActivities(){
    this.userDashBoardService.getMyProjectActivitiesCount('all').subscribe(value => {
      this.myactivities = value;
      this.issetDataMyActivities = true;
      this.getBGIProjectsCount();
    });
  }


}
