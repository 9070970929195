import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WaterBearingZones } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import { UnitEnum } from '../../../../../shared-kernel/enumerations/unit-enum';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-borehole-construction-water-bearing-zones-create',
  templateUrl: './water-bearing-zones-create.component.html',
  styleUrls: ['./water-bearing-zones-create.component.scss']
})
export class WaterBearingZonesCreateComponent implements OnInit {

  bearingZonesCreateForm: FormGroup;
  waterBearingZonesTemplate : WaterBearingZones = new WaterBearingZones();
  title : string = 'Create Water Bearing Zones';
  measurementUnit: typeof UnitEnum = UnitEnum;

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<WaterBearingZonesCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WaterBearingZones, private hydrogeologyService: HydrogeologyService,private toast: ToastrService) { }

  ngOnInit() {
    this.initForms();
  }

  initForms() {
    if(this.data.id){
      this.title = 'Edit Water Bearing Zones';
    }

    this.bearingZonesCreateForm = this.formBuilder.group({
    waterStrike : [this.data ? this.data.waterStrike.measure : null, Validators.required],
    yield : [this.data ? this.data.yield.measure : null, Validators.required],
    ph : [this.data ? this.data.ph.measure : null, Validators.required],
    ec : [this.data ? this.data.ec.measure : null, Validators.required],
    tds : [this.data ? this.data.tds.measure : null, Validators.required],
    temp : [this.data ? this.data.temp.measure : null, Validators.required],
    staticWaterLevel : [this.data ? this.data.staticWaterLevel.measure : ''],
    comments : [this.data ? this.data.comments : ''],
    });
  }

  closeModal(){
    this.dialogRef.close();
  }

  validation_messages = {
    'waterStrike': [
      {type: 'required', message: 'Please insert water strike value'},
    ],
    'yield': [
      {type: 'required', message: 'Please insert yield value'},
    ],
    'pH': [
      {type: 'required', message: 'Please insert pH value'},
    ], 'eC': [
      {type: 'required', message: 'Please insert EC value'},
    ],
    'temp': [
      {type: 'required', message: 'Please insert temp value '},
    ],
    'staticWaterLevel': [
      {type: 'required', message: 'Please insert static water level value'},
    ], 'tDS': [
      {type: 'required', message: 'Please insert TDS value'},
    ],
    'comments': [
      {type: 'required', message: 'Please insert Description'},
    ]
  };

  onSubmit() {
    if(this.data.id){
      this.waterBearingZonesTemplate = this.data;
    }

    this.waterBearingZonesTemplate.borehole = this.data.borehole;
    this.waterBearingZonesTemplate.comments = this.bearingZonesCreateForm.controls.comments.value;
    this.waterBearingZonesTemplate.staticWaterLevel.measure = this.bearingZonesCreateForm.controls.staticWaterLevel.value;
    this.waterBearingZonesTemplate.staticWaterLevel.unit.id = this.measurementUnit.waterlevel;
    this.waterBearingZonesTemplate.temp.measure = this.bearingZonesCreateForm.controls.temp.value;
    this.waterBearingZonesTemplate.temp.unit.id = this.measurementUnit.temperature;
    this.waterBearingZonesTemplate.tds.measure = this.bearingZonesCreateForm.controls.tds.value;
    this.waterBearingZonesTemplate.tds.unit.id = this.measurementUnit.TDS;
    this.waterBearingZonesTemplate.ec.measure = this.bearingZonesCreateForm.controls.ec.value;
    this.waterBearingZonesTemplate.ec.unit.id = this.measurementUnit.ec;
    this.waterBearingZonesTemplate.ph.measure = this.bearingZonesCreateForm.controls.ph.value;
    this.waterBearingZonesTemplate.ph.unit.id = this.measurementUnit.ph;
    this.waterBearingZonesTemplate.yield.measure = this.bearingZonesCreateForm.controls.yield.value;
    this.waterBearingZonesTemplate.yield.unit.id = this.measurementUnit.yield;
    this.waterBearingZonesTemplate.waterStrike.measure = this.bearingZonesCreateForm.controls.waterStrike.value;
    this.waterBearingZonesTemplate.waterStrike.unit.id = this.measurementUnit.meter;
    this.waterBearingZonesTemplate.blowOutYield = null;

    this.hydrogeologyService.createWaterBearingZoneLog(this.waterBearingZonesTemplate, this.data.borehole.id).subscribe(
      responce => {

      },
      error => {
        this.toast.error('','Water Bearing Zones Create');
      },()=> {
        this.dialogRef.close();
        this.toast.success('','Water Bearing Zones Create');
      }
    );
  }

}
