import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router, UrlTree, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { DCPTestSubgrade } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DCPTestSubgrade';
import { DynamicConePenetrometerTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTest';
import { DCPTestService } from '../../dynamic-cone-penetrometer.service';
import { DynamicConePenetrometerTestLog } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTestLog';

@Component({
  selector: 'app-dcp-subgrade-logs',
  templateUrl: './dcp-subgrade-logs.component.html',
  styleUrls: ['./dcp-subgrade-logs.component.scss']
})
export class DcpSubgradeLogsComponent implements OnInit {

  dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource();
  displayedDynamicConePenetrometerTestLogsColumns = ['NumberOfBlows', 'Penetration', 'PenetrationRate',
  'StartDepth', 'FinishDepth', 'Comments'];

  url: string;
  dcpTestId: {};
  dynamicConePenetrometerTestSubgrade: DCPTestSubgrade;
  dynamicConePenetrometerTestSubgrades: DCPTestSubgrade[];
  dynamicConePenetrometerTestLog: DynamicConePenetrometerTestLog;
  dynamicConePenetrometerTestLogs: DynamicConePenetrometerTestLog[];

  constructor(private router: Router, private dcpTestService: DCPTestService) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[4].parameters;
    this.dcpTestId = urlData;
  }

  ngOnInit() {
    this.getAllDCPTestSubgradeByDynamicConePenetrometerTestId();
    this.dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource(this.dynamicConePenetrometerTestLogs);
  }

  loadDCPTestLog(testNumber) {
    this.dynamicConePenetrometerTestSubgrade = this.dynamicConePenetrometerTestSubgrades[testNumber.index];
    this.dynamicConePenetrometerTestLogs = this.dynamicConePenetrometerTestSubgrade.dynamicConePenetrometerTestLogs;
    this.dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource(this.dynamicConePenetrometerTestLogs);
  }

  getAllDCPTestSubgradeByDynamicConePenetrometerTestId() {
    let dcpTestId = {id: null};
    dcpTestId = this.dcpTestId as DynamicConePenetrometerTest;
    if (dcpTestId.id) {
      this.dcpTestService.getAllDCPTestSubgradeByDynamicConePenetrometerTestId(dcpTestId.id)
      .subscribe(data => {
       this.dynamicConePenetrometerTestSubgrades = data as DCPTestSubgrade[];
       if (this.dynamicConePenetrometerTestSubgrades.length !== 0) {
          for (const key  in this.dynamicConePenetrometerTestSubgrades) {
            this.dynamicConePenetrometerTestSubgrade = this.dynamicConePenetrometerTestSubgrades[key];
            this.dynamicConePenetrometerTestLogs = this.dynamicConePenetrometerTestSubgrade.dynamicConePenetrometerTestLogs
            this.dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource(this.dynamicConePenetrometerTestLogs);
          }
       } else {
         this.dynamicConePenetrometerTestSubgrades = null;
       }
      });
    }

  }

  applyFilter(filterValue: string) {
    this.dataSourceDynamicConePenetrometerTestLogs.filter = filterValue.trim().toLowerCase();
  }

}
