import { startWith, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HydrogeologyService } from './../../../../applied-geoscience/hydrogeology/hydrogeology.service';
import { GeoTechService } from './../../../../applied-geoscience/geo-tech/geo-tech.service';
import { MatAutocomplete, MatChipInputEvent, MAT_DIALOG_DATA, MatDialogRef, MatSelect, MatDialog, MatDialogConfig } from '@angular/material';
import { CommonService } from './../../../../common/common.service';
import { ProjectService } from './../../../../applied-geoscience/project/project.service';
import { CoordinatesService } from './../../../../shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import { Observable } from 'rxjs';
import { BusinessUnitEnum } from './../../../../shared-kernel/enumerations/business-unit-enum';
import { Sample } from './../../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { Project } from './../../../../shared-kernel/entity/applied-geoscience/project/project';
import { Coordinate } from './../../../../shared-kernel/entity/common/coordinatesValidator/coordinate';
import { District } from './../../../../common/entity/security/util/common/district';
import { Village } from './../../../../shared-kernel/entity/common/Village';
import { CoordinatesFormatsEnum } from './../../../../shared-kernel/enumerations/coordinates-formats-enumt';
import { CoordinatesTypesEnum } from './../../../../shared-kernel/enumerations/coordinates-type-enum';
import {Component, OnInit, Inject, Input, ViewChild, ElementRef} from '@angular/core';
import LatLon from 'geodesy/latlon-nvector-spherical';
import { Size } from '../../../../shared-kernel/entity/common/size';
import { UnitEnum } from '../../../../shared-kernel/enumerations/unit-enum';
import {NigisImages} from "../../../../shared-kernel/entity/common/nigisImages";
import {BoreholeLocationImage} from "../../../../shared-kernel/entity/common/boreholeLocationImage";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import { StorageConstants } from '../../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-technician-sample-create',
  templateUrl: './technician-sample-create.component.html',
  styleUrls: ['./technician-sample-create.component.scss']
})
export class TechnicianSampleCreateComponent implements OnInit {

  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  villages: Village[];
  districts: District[];
  district: District = new District();

  botswanaPolygon: any[];
  latLonArray: Coordinate = new Coordinate();

  projects: any;

  projectStillLoading = true;
  districtStillLoading = true;
  villageStillLoading = true;
  title: string;
  sampleCreateForm: FormGroup;
  enumUnits:  typeof UnitEnum = UnitEnum;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  sampleTemplate: Sample = new Sample();
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  filteredOptionsVillage: Observable<Village[]>;
  filteredOptionsDistrict: Observable<District[]>;
  currentUser: any;
  locationDescription: string;
  samplesLocationImages: NigisImages[];
  sampleLocationImage: NigisImages;
  filteredsampleLocationImages: Observable<BoreholeLocationImage[]>;

  @ViewChild('sampleLocationImageInput') sampleLocationImageInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  sampleLocationImageCtrl = new FormControl();

  constructor(
    private coordinatesService: CoordinatesService,
    private projectService: ProjectService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TechnicianSampleCreateComponent>,
    private formBuilder: FormBuilder,
    private geoTechService: GeoTechService,
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private _localStorage: LocalStorage
  ) { }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.getProjectsByMember(this.currentUser.username);
    }, err => {
      this.toastr.warning(err.message, "Current User Error");
    },() => {

    });

    this.samplesLocationImages = [];
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    this.getBotswanaGeofence();
    this.getProjects();
    this.getAllDistricts();
    this.getAllVillages();

    this.initForms();

    if (this.data.sample) {
      this.title = 'Edit Sample';
    } else {
      this.title = 'Create Sample';
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private initForms() {

    if (this.data.sample) {
      this.samplesLocationImages = this.data.samplesLocationImages;
      this.selectedCoordinatesFormat = this.data.sample.coordinatesFormat.format;
    }



    this.sampleCreateForm = this.formBuilder.group({
      sampleDate: [this.data.sample ? new Date(this.data.sample.sampleDate) : null, Validators.required],
      depthFrom: [this.data.sample && this.data.entityId ? this.data.sample.depthFrom.measure : ''],
      depthTo: [this.data.sample && this.data.entityId ? this.data.sample.depthTo.measure : ''],
      project: [this.data.sample ? this.data.sample.project : null, Validators.required],
      district: [this.data.sample ? this.data.sample.locationTemp.village.district : null, Validators.required],
      village: [this.data.sample ? this.data.sample.locationTemp.village : null, Validators.required],
      sampleNumber: [this.data.sample ? this.data.sample.sampleNumber : null, Validators.required],
      collectedBy: [this.data.sample ? this.data.sample.collectedBy : null, Validators.required],
      coordinatesFormat: [this.data.sample ? this.data.sample.coordinatesFormat.format : null, Validators.required],

      sampleType: [this.data.sample ? this.data.sample.sampleType : ''],
      horizonSampled: [this.data.sample ? this.data.sample.horizonSampled : ''],
      duplicate: [this.data.sample ? this.data.sample.duplicate : ''],
      colour: [this.data.sample ? this.data.sample.colour : ''],
      texture: [this.data.sample ? this.data.sample.texture : ''],
      relief: [this.data.sample ? this.data.sample.relief : ''],
      vegetation: [this.data.sample ? this.data.sample.vegetation : null, Validators.required],
      moisture: [this.data.sample ? this.data.sample.moisture : null, Validators.required],
      reactionWithHcl: [this.data.sample ? this.data.sample.reactionWithHcl : null, Validators.required],
      samplesLocationImages: [this.data.sample ? this.data.sample.samplesLocationImages : ''],
      locationDescription: [this.data.sample ? this.data.sample.locationDescription.description : ''],

      comments: [this.data.sample ? this.data.sample.comments : ''],
      latitude: [this.data.sample ? this.data.sample.locationTemp.coordinates.latitude : ''],
      longitude: [this.data.sample ? this.data.sample.locationTemp.coordinates.longitude : ''],
      coordinatesType: [this.data.sample ? this.data.sample.coordinatesFormat.type : null, Validators.required],
      dmsSx: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._lonSValue) : ''],// decimal minutes seconds, to hold x/longitude seconds value
      dmsMx: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._lonMValue) : ''], // decimal minutes seconds, to hold x/longitude minutes value
      dmsDx: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._lonDValue) : ''], // decimal minutes seconds, to hold x/longitude decimal value
      dmsSy: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._latSValue) : ''], // decimal minutes seconds, to hold y/latitude seconds value
      dmsMy: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._latMValue) : ''],// decimal minutes seconds, to hold y/latitude minutes value
      dmsDy: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._latDVale) : ''],// decimal minutes seconds, to hold y/latitude decimal value
      zone34SNorthing: [this.data.sample ? this.coordinatesService.convertLatLonToUtm(34, this.data.sample.locationTemp.coordinates.latitude, this.data.sample.locationTemp.coordinates.longitude)._lat : ''],
      zone34SEasting: [this.data.sample ? this.coordinatesService.convertLatLonToUtm(34, this.data.sample.locationTemp.coordinates.latitude, this.data.sample.locationTemp.coordinates.longitude)._lon : ''],
      zone35SNorthing: [''],
      zone35SEasting: [''],
    });
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });
  }

  private getProjects() {
    this.projectService.getAllProjectName().subscribe(res => {
      this.projects = res;
      this.projectStillLoading = false;
    }, err => {
    });
  }

  getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user).subscribe(res => {
      this.projects = res;
      this.projectStillLoading = false;
    }, err => {
    });
  }

  getAllDistricts() {
    this.commonService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        this.filteredOptionsDistrict = this.sampleCreateForm.controls.district.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterDistrict(name) : this.districts.slice())
        );
        this.districtStillLoading = false;
      });
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        this.filteredOptionsVillage = this.sampleCreateForm.controls.village.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterVillage(name) : this.villages.slice())
        );
        this.villageStillLoading = false;
      });
  }

  private _filterVillage(name: string): Village[] {
    const filterValue = name.toLowerCase();
    return this.villages.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterDistrict(name: string): District[] {
    const filterValue = name.toLowerCase();
    return this.districts.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  compareFn(projectOne: any, projectTwo: any): boolean {
    return projectOne && projectTwo ? projectOne.id === projectTwo.id : projectOne === projectTwo;
  }

  compareFnDropDowns(stringOne: any, stringTwo: any): boolean {
    return stringOne && stringTwo ? stringOne === stringTwo : stringOne === stringTwo;
  }

  compareStrings(stringOne: any, stringTwo: any): boolean {
    return stringOne === stringTwo;
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };

    this.selectedCoordinatesType = selectedData.text;

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    }
    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
    }
  }

  onDistrictChange(district: District) {
    this.commonService.getVillagesByDistrictId(district.id)
    .subscribe(data => {
      this.villages = data as Village[];
      this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      this.filteredOptionsVillage = this.sampleCreateForm.controls.village.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterVillage(name) : this.villages.slice())
      );
    });
  }

  onVillageChange(village: Village) {
    for (let i = 0; i < this.districts.length; i++) {
      if (this.districts[i].id === village.district.id) {
        this.sampleCreateForm.controls.district.setValue(this.districts[i]);
      }
    }
  }

  onSubmit() {
    if (this.data.sample) {
      this.sampleTemplate = this.data.sample;
    }

    if (this.data.entityId) {
      this.sampleTemplate.entityLink = this.data.entityId;
      this.sampleTemplate.depthFrom = new Size();
      this.sampleTemplate.depthTo = new Size();
      this.sampleTemplate.depthFrom.measure = this.sampleCreateForm.controls.depthFrom.value;
      this.sampleTemplate.depthFrom.unit.id = this.enumUnits.meter;
      this.sampleTemplate.depthTo.measure = this.sampleCreateForm.controls.depthTo.value;
      this.sampleTemplate.depthTo.unit.id = this.enumUnits.meter;
    }

    this.sampleTemplate.deleted = false;
    this.sampleTemplate.comments = this.sampleCreateForm.controls.comments.value;
    this.sampleTemplate.sampleDate = this.sampleCreateForm.controls.sampleDate.value;
    this.sampleTemplate.sampleNumber = this.sampleCreateForm.controls.sampleNumber.value;
    this.sampleTemplate.locationTemp.village = this.sampleCreateForm.controls.village.value;
    this.sampleTemplate.coordinatesFormat.type = this.sampleCreateForm.controls.coordinatesType.value;
    this.sampleTemplate.coordinatesFormat.format = this.sampleCreateForm.controls.coordinatesFormat.value;
    this.sampleTemplate.locationTemp.coordinates.latitude = this.sampleCreateForm.controls.latitude.value;
    this.sampleTemplate.locationTemp.coordinates.longitude = this.sampleCreateForm.controls.longitude.value;
    this.sampleTemplate.sampleOwnerUnit = this.businessUnitEnum.mineralResources;
    this.sampleTemplate.sampleRole = 'Technician';

    this.sampleTemplate.sampleType = this.sampleCreateForm.controls.sampleType.value;
    this.sampleTemplate.horizonSampled = this.sampleCreateForm.controls.horizonSampled.value;
    this.sampleTemplate.duplicate = this.sampleCreateForm.controls.duplicate.value;
    this.sampleTemplate.colour = this.sampleCreateForm.controls.colour.value;
    this.sampleTemplate.texture = this.sampleCreateForm.controls.texture.value;
    this.sampleTemplate.relief = this.sampleCreateForm.controls.relief.value;
    this.sampleTemplate.vegetation = this.sampleCreateForm.controls.vegetation.value;
    this.sampleTemplate.moisture = this.sampleCreateForm.controls.moisture.value;
    this.sampleTemplate.reactionWithHcl = this.sampleCreateForm.controls.reactionWithHcl.value;
    this.sampleTemplate.samplesLocationImages = this.samplesLocationImages;
    if(this.samplesLocationImages ==undefined){
      this.sampleTemplate.samplesLocationImages = [];
    }else{
      this.sampleTemplate.samplesLocationImages = this.samplesLocationImages;
    }
    this.sampleTemplate.locationDescription.description = this.sampleCreateForm.controls.locationDescription.value;

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.sampleCreateForm.controls.dmsDy.value, this.sampleCreateForm.controls.dmsMy.value, this.sampleCreateForm.controls.dmsSy.value
          , this.sampleCreateForm.controls.dmsDx.value, this.sampleCreateForm.controls.dmsMx.value, this.sampleCreateForm.controls.dmsSx.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.sampleCreateForm.controls.dmsDy.value, this.sampleCreateForm.controls.dmsMy.value, this.sampleCreateForm.controls.dmsSy.value, this.sampleCreateForm.controls.dmsDx.value
          , this.sampleCreateForm.controls.dmsMx.value, this.sampleCreateForm.controls.dmsSx.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.sampleCreateForm.controls.zone34SNorthing.value, this.sampleCreateForm.controls.zone34SEasting.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.sampleCreateForm.controls.zone34SNorthing.value, this.sampleCreateForm.controls.zone34SEasting.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.sampleCreateForm.controls.zone35SNorthing.value, this.sampleCreateForm.controls.zone35SEasting.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.sampleCreateForm.controls.zone35SNorthing.value, this.sampleCreateForm.controls.zone35SEasting.value)._lon;
    }
    this.sampleTemplate.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.sampleTemplate.locationTemp.coordinates.latitude,
        this.sampleTemplate.locationTemp.coordinates.longitude)._lat;
    this.sampleTemplate.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.sampleTemplate.locationTemp.coordinates.latitude,
        this.sampleTemplate.locationTemp.coordinates.longitude)._lon;


    if(new LatLon(this.sampleTemplate.locationTemp.coordinates.latitude, this.sampleTemplate.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)
      || this.currentUser.businessUnit.name === this.businessUnitEnum.mineralResources
    ) {
      if (this.sampleCreateForm.controls.sampleNumber.valid  && this.sampleCreateForm.controls.coordinatesType.valid && this.sampleCreateForm.controls.coordinatesFormat.valid
        && this.sampleCreateForm.controls.longitude.valid  && this.sampleCreateForm.controls.latitude.valid
        && this.sampleCreateForm.controls.district.valid  && this.sampleCreateForm.controls.village.valid
        && this.sampleCreateForm.controls.vegetation.valid  && this.sampleCreateForm.controls.moisture.valid
        && this.sampleCreateForm.controls.reactionWithHcl.valid) {

        this.projectService.getProjectById( this.sampleCreateForm.controls.project.value.id).subscribe(value => {
          this.sampleTemplate.project = value as Project;
        this.geoTechService.createSamples(this.sampleTemplate).subscribe(
          value => {
            this.samplesLocationImages = [];
          }
          , error => {
            if(this.title === 'Create Sample') {
              this.toastr.error(error, 'Sample Create');
            } else {
              this.toastr.error(error, 'Update Create');
            }
          }
          , () => {
            this.closeModal();

            if(this.title === 'Create Sample') {
              this.toastr.success('Sample Created Successfully', 'Sample Create');
            } else {
              this.toastr.success('Sample Updated Successfully', 'Sample Update');
            }
          }
        );
        });
      }
    } else {
      this.toastr.error(this.coordinatesService.outSide);
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.sampleLocationImage = new NigisImages();
          this.sampleLocationImage.image = fileSplitdata[1];
          this.sampleLocationImage.name = fileName;
          this.samplesLocationImages.push(this.sampleLocationImage);
          this.sampleTemplate.samplesLocationImages = this.samplesLocationImages;
          this.sampleLocationImageInput.nativeElement.value = '';
          this.sampleLocationImageCtrl.setValue(null);
        };
      }
    }
  }

  onFocusOutDistrict() {
    let district = this.sampleCreateForm.controls.district.value;
    if (district && district !== '') {
      let isPresent: boolean;
      isPresent = this.districts.some(function(ds){
          if (district.name) {
            return ds.name === district.name;
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleCreateForm.get('district').setErrors(null);
        } else {
          this.sampleCreateForm.get('district').setErrors({ 'exist': true });
        }
    }
  }
  remove(sampleLocationImage: NigisImages): void {
    const index = this.samplesLocationImages.indexOf(sampleLocationImage);
    if (index >= 0) {
      this.samplesLocationImages.splice(index, 1);
    }
  }
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const chipSampleLocationImage = new NigisImages;
      chipSampleLocationImage.image = value;

      if ((value || '').trim()) {
        this.samplesLocationImages.push(chipSampleLocationImage);
      }

      if (input) {
        input.value = '';
      }

      this.sampleLocationImageCtrl.setValue(null);
    }
  }

  onFocusOutVillage() {
    let village = this.sampleCreateForm.controls.village.value;
    if (village && village !== '') {
      let isPresent: boolean;
      isPresent = this.villages.some(function(ds){
          if (village.name) {
            return ds.name === village.name;
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleCreateForm.get('village').setErrors(null);
        } else {
          this.sampleCreateForm.get('village').setErrors({ 'exist': true });
        }
    }
  }

  displayFnVillage(village: Village): string {
    return village && village.name ? village.name : '';
  }

  displayFnDistrict(district: District): string {
    return district && district.name ? district.name : '';
  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesFormat = selectedData.text;
    this.sampleTemplate.coordinatesFormat.format = this.selectedCoordinatesFormat;
  }


}
