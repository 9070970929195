import {Component, Inject, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MatChipInputEvent } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';   //importing spinner
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DialogData, NigisDocument, UploadFileResponse } from './../nigis-doc.model';
import { DocumentLink } from '../nigis-doc.model';
import {VERSION} from '@angular/material';


import { DocumentsService } from '../dococument.service';



@Component({
    selector: 'doc-upload',
    templateUrl: './doc-upload.component.html',
    styleUrls: ['./doc-upload.component.scss'],
})
export class DocUploadComponent implements OnInit {
    docForm: FormGroup;
    @Input('isInputData') isInputData: boolean = false;

    onEditDoc: NigisDocument;
    randomNumber =  Math.floor(Math.random() * (4000 - 1901 + 1)) + 1901;
    links: Array<string> = [];
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    submitted = false;
    selectedFile: File;
    uploadProgress: { inProgress: boolean, progress: number } = { inProgress: false, progress: 0 };
    version = VERSION;
    date = new Date();
    chosenYearDate: Date;
    hideYear:boolean = true;

    visible = true;

    constructor (
        private _fb: FormBuilder,
        public dialogRef: MatDialogRef<DocUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private _docServ: DocumentsService
    ) {}

    ngOnInit () {

        this.getYears();

        if (this.data.edit === true && this.data.fileId) {
          if (this.data.approval) {
            this._getEditFile();
          }
          else {
            this._getEditDoc();
          }

        } else {
            this._initForms();
        }

        if(this.data.id === 'INPUT-DATA'){
          this.hideYear = false;
        } else if (this.data.id === 'OUTPUT-DATA-DOCUMENTS'){
          this.hideYear = false;
        } else if (this.data.id === 'OUTPUT-DATA-FILES'){
          this.hideYear = false;
        } else if (this.data.id === 'INPUT-DATA-FILES'){
          this.hideYear = false;
        } else if (this.data.id === 'LITERATURE-REVIEW'){
          this.hideYear = false;
        } else if (this.data.id === 'MINERAL-RESOURCE-REPORT'){
          this.hideYear = false;
        } else {
          this.hideYear = true;
        }
    }

    onSelectFile(files: FileList) {
        if (files.length > 0) {
            let file = files.item(0);
            this.docForm.controls['doc'].setValue(file.name);
            this.selectedFile = file;
            this.docForm.controls.name.setValue(file.name.split('.')[0]);
            const reader = new FileReader();
            reader.onload = (evt) => {
            }

            reader.readAsDataURL(file);
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    _initForms() {
        this.docForm = this._fb.group({
            doc: new FormControl({ value: (this.data.edit && this.onEditDoc) ? this.onEditDoc.fileName : null, disabled: this.data.edit }, Validators.required),
            name: [(this.data.edit && this.onEditDoc) ? this.onEditDoc.name : null, Validators.required],
            description: [(this.data.edit && this.onEditDoc) ? this.onEditDoc.description : null],
            inputYear: [(this.data.edit && this.onEditDoc) ?   new Date(this.onEditDoc.inputYear) : null, this.data.id === 'INPUT-DATA' ? Validators.required : ''],
            links: [this.links]
        });

        this.docForm.controls['links'].setValue(this.links);
    }

    onSubmit() { // Not allowed to edit file, otherwise new doc

        if (this.docForm.invalid) return;

        this.submitted = true;


        if (this.data.edit === true && this.onEditDoc) { // Edit existing doc
            let updateDoc: NigisDocument = {
                id: this.onEditDoc.id,
                name: this.docForm.get('name').value as string,
                description: this.docForm.get('description').value as string,
                fileName: this.onEditDoc.fileName,
                inputYear: this.docForm.get('inputYear').value as Date,
                uri: this.onEditDoc.uri,
                type: this.onEditDoc.type,
                size: this.onEditDoc.size,
                deleted: false,
                linkedEntity: this.onEditDoc.linkedEntity,
                links: [],
                statusHistory:  this.onEditDoc.statusHistory,

                creator: this.onEditDoc.creator,
                created: this.onEditDoc.created,
                updater: this.onEditDoc.updater
            };

            this.links.forEach(l => {
                if (l && l.trim() !== '')
                    updateDoc.links.push(<DocumentLink>{ uri: l });
            });

          if(this.data.approval){
            this._docServ.addCollectionFileDetails(updateDoc).subscribe(res => {
              this.submitted = false;
              this._toastr.success("Document uploaded successfully", "Document Upload");
              this.dialogRef.close(res);
            }, _err => {
              this.submitted = false;
              this._toastr.error("Failed to upload document", "Document Upload");
            });
          }else{
              this._docServ.addFileDetails(updateDoc).subscribe(res => {
                  this.submitted = false;
                  this._toastr.success("Document uploaded successfully", "Document Upload");
                  this.dialogRef.close(res);
              }, _err => {
                  this.submitted = false;
                  this._toastr.error("Failed to upload document", "Document Upload");
              });
          }
        }
        else {    // Create a new doc
            let final = {
                file: this.selectedFile,
                document: <NigisDocument>{
                    inputYear: this.docForm.get('inputYear').value as Date,
                    name: this.docForm.get('name').value as string,
                    description: this.docForm.get('description').value as string,
                    fileName: this.selectedFile.name,
                    type: this.selectedFile.type,
                    size: this.selectedFile.size,
                    deleted: false,
                    linkedEntity: this.data.id,
                    links: []
                }
            };

            this.links.forEach(l => {
                if (l && l.trim() !== '')
                    final.document.links.push(<DocumentLink>{ uri: l });
            });

            let formData = new FormData();
            formData.append("file", final.file);
            this.uploadProgress.inProgress = true;

            this._docServ.uploadFile(formData).pipe(map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.spinner.show();
                        this.uploadProgress.progress = Math.round(event.loaded * 100 / event.total);
                        break;
                    case HttpEventType.Response:
                        this.spinner.hide();
                        return event;

                }
            })).subscribe(event => {
                if (event) {
                    let uploadRes: UploadFileResponse = event.body;
                    final.document.uri = uploadRes.fileDownloadUri;
                    if(this.data.approval){
                      this._docServ.addApprovalFileDetails(final.document).subscribe(res => {
                          this.submitted = false;
                          this._toastr.success("Document uploaded successfully", "Document Upload");
                          this.dialogRef.close(res);
                      }, _err => {
                          this.submitted = false;
                          this._toastr.error("Failed to upload document", "Document Upload");
                      });
                    }else{
                      this._docServ.addFileDetails(final.document).subscribe(res => {
                        this.submitted = false;
                        this._toastr.success("Document uploaded successfully", "Document Upload");
                        this.dialogRef.close(res);
                      }, _err => {
                        this.submitted = false;
                        this._toastr.error("Failed to upload document", "Document Upload");
                      });
                    }
                }
            }, err => {
                this.submitted = false;
                this._toastr.error("Failed to upload document", "Document Upload");
            });

        }

    }


    getYears() {

    }

    addLink(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value;

        const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const regEx = new RegExp(expression);

        if (value.trim().match(regEx)) {
            if (value.trim() !== '') {
                this.docForm.controls['links'].setErrors(null);
                const tempLinks = this.docForm.controls['links'].value;
                tempLinks.push(value.trim());
                this.docForm.controls['links'].setValue(tempLinks);

                if (this.docForm.controls['links'].valid) {
                    this.docForm.controls['links'].markAsDirty();
                    input.value = '';
                } else {
                    const index = this.links.findIndex(v => v === value.trim());
                    if (index !== -1) {
                        this.links.splice(index, 1);
                    }
                }
            } else {
                this.docForm.controls['links'].updateValueAndValidity();
            }
        } else {
            this._toastr.warning(`${value} is not a valid link`, "Invalid link");
            this.docForm.controls['links'].updateValueAndValidity();
        }
    }

    onRemoveLink(link: any) {
        let controller = this.docForm.controls['links'];
        let index = this.links.indexOf(link, 0);

        if (index > -1) {
            this.links.splice(index, 1);
        }

        controller.updateValueAndValidity();
        controller.markAsDirty();
    }

    private _getEditDoc() {
        this._docServ.getDocument(this.data.fileId).subscribe(res => {
            this.onEditDoc = res;
            if (this.onEditDoc.links && this.onEditDoc.links.length)
                this.onEditDoc.links.forEach(l => {
                    this.links.push(l.uri);
                });

            this._initForms();
        })
    }

  private _getEditFile() {
    this._docServ.getFile(this.data.fileId).subscribe(res => {
      this.onEditDoc = res;
      if (this.onEditDoc.links && this.onEditDoc.links.length)
        this.onEditDoc.links.forEach(l => {
          this.links.push(l.uri);
        });

      this._initForms();
    });
  }


}
