import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ScreeningLog } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/ScreeningLog';
import { WaterPumpingTestStepTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestStepTest';
import { WaterBoreholeLithologyLog } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBoreholeLithologyLog';
import { BoreholeConstructionEnum } from '../../shared-kernel/enumerations/borehole-construction-enum';
import { WaterPumpingTestCalibration } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestCalibration';
import { WaterPumpingTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTest';
import { WaterPumpingTestConstantRateTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestConstantRateTest';
import { WaterBearingZones } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import { WaterPumpingTestRecovery } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestRecovery';
import { CasingLog } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/CasingLog';
import { Observable } from 'rxjs';
import { WaterConstantRateTest } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterConstantRateTest';
import { WaterObservationBorehole } from '../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterObservationBorehole';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})


export class HydrogeologyService implements OnInit {

  constructionTypeEnum: typeof BoreholeConstructionEnum = BoreholeConstructionEnum;
  page: string;

  constructor(private http: HttpClient, private router: Router, private toast: ToastrService) {

  }

  ngOnInit(): void {
  }

  errorsmsg() {
    this.toast.error('Unsuccessful');
  }

  successmsg() {
    this.toast.success('Success');
  }

  getAllConstructionTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllConstructionMaterialTypesUrl);
  }

  createBoreholeConstructionCasingLog(casingLog: CasingLog, boreholeId: string) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeConstructionCasingLogUrl + '/' + boreholeId,
      casingLog);
  }

  createBoreholeConstructionScreeningLog(screeningLog: ScreeningLog, boreholeId: string) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeConstructionScreeningLogUrl + '/' + boreholeId,
      screeningLog);
  }


  createWaterBearingZoneLog(waterBearingZones: WaterBearingZones, boreholeId: string) {
    return  this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterBearingZonesLogUrl+'/'+boreholeId,
       waterBearingZones)
   }

  createWaterBearingZoneLogBatch(waterBearingZones: WaterBearingZones  []) {
    return  this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterBearingZonesLogBatchUrl,
       waterBearingZones)
   }



  getAllCasing(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllCasingUrl + '/' + id);
  }


  getAllScreening(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllScreeningUrl + '/' + id);
  }

  getAllRockTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllRockTypesUrl);
  }


  getAllGrainSizes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllGrainSizesUrl);
  }

  getWaterBoreholeLithologyLogById(waterBoreholeLithologyLog: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getWaterBoreholeLithologyLogByIdUrl + '/' + waterBoreholeLithologyLog);
  }

  getAllWaterBoreholeLithologyLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllWaterBoreholeLithologyLogsUrl);
  }

  getAllWaterBoreholeLithologyLogsByBoreholeId(borehole: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllWaterBoreholeLithologyLogsByBoreholeIdUrl + '/' + borehole);
  }

  createWaterBoreholeLithologyLog(waterBoreholeLithologyLog: WaterBoreholeLithologyLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createWaterBoreholeLithologyLogUrl, waterBoreholeLithologyLog);
  }

  createWaterPumpingTest(waterPumpingTest: WaterPumpingTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterPumpingTestUrl,
      waterPumpingTest);
  }

  getAllWaterPumpingTestByBoreholeId(boreholeId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestByBoreholeIdUrl + '/' + boreholeId);
  }

  getAllWaterPumpingTestByWaterPumpingTestTypeId(testTypeId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestByWaterPumpingTestTypeIdUrl + '/' + testTypeId);
  }

  getAllWaterPumpingTestByBoreholeIdAndWaterPumpingTestTypeId(boreholeId: any, testTypeId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestByBoreholeIdAndWaterPumpingTestTypeIdUrl + '/' + boreholeId + '/' + testTypeId);
  }

  getAllWaterPumpingTestStepTestByWaterPumpingTestId(testId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestStepTestByWaterPumpingTestIdUrl + '/' + testId);
  }

  getAllWaterPumpingTestCalibrationByWaterPumpingTestId(testId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestCalibrationByWaterPumpingTestIdUrl + '/' + testId);
  }
  getAllWaterPumpingTestCalibrationByBoreholeId(boreholeId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestCalibrationByBoreholeIdUrl + '/' + boreholeId);
  }
  getWaterPumpingTestCalibrationById(testId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getWaterPumpingTestCalibrationByIdUrl + '/' + testId);
  }


  getAllWaterConstantRateTestByWaterPumpingTestId(testId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestConstantRateTestByWaterPumpingTestIdUrl + '/' + testId );
  }

  getWaterConstantRateTestById(constantRateId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getWaterConstantRateTestByIdUrl + '/' + constantRateId );
  }

  getWaterObservationBoreholeByConstantRateTest(constantRateId: any) {
    let tempApiUrl: string = ServiceEndPoints.getWaterObservationBoreholeByConstantRateTestUrl;
    let apiUrl = tempApiUrl.replace('{constantRate}', constantRateId);
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl+ apiUrl);
  }

  getAllWaterPumpingTestRecoveryByWaterPumpingTestId(testId: any, testType: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterPumpingTestRecoveryByWaterPumpingTestIdUrl + '/' + testId + '/' + testType);
  }

  getWaterPumpingTestById(testId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getWaterPumpingTestByIdUrl + '/' + testId);
  }

  createWaterPumpingTestStepTest(waterPumpingTestStepTests: WaterPumpingTestStepTest[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterPumpingTestStepTestUrl,
      waterPumpingTestStepTests);
  }

  createWaterPumpingTestStepTestOne(waterPumpingTestStepTest: WaterPumpingTestStepTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/step-test/create/one', waterPumpingTestStepTest);
  }

  deleteWaterPumpingTestStepTestOne(waterPumpingTestStepTest: WaterPumpingTestStepTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/step-test/delete/one', waterPumpingTestStepTest);
  }

  deleteWaterPumpingTestStepTests(waterPumpingTestStepTests: WaterPumpingTestStepTest[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/step-test/delete/bulk',waterPumpingTestStepTests);
  }

  getAllWaterPumpingTestStepTestByBoreholeId(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/borehole/water-pump-test/step-test/' + boreholeId);
  }

  getWaterPumpingTestStepTestById(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/borehole/water-pump-test/step-test/detail/' + id);
  }



  createWaterPumpingTestRecoveryTestOne(waterPumpingTestRecovery: WaterPumpingTestRecovery) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/recovery/create/one', waterPumpingTestRecovery);
  }

  deleteWaterPumpingTestRecoveryTestOne(waterPumpingTestRecovery: WaterPumpingTestRecovery) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/recovery/delete/one', waterPumpingTestRecovery);
  }

  deleteWaterPumpingTestRecoveryTests(waterPumpingTestRecovery: WaterPumpingTestRecovery[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/recovery/delete/bulk',waterPumpingTestRecovery);
  }

  getAllWaterPumpingTestRecoveryTestByBoreholeId(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/borehole/water-pump-test/recovery/' + boreholeId);
  }

  getWaterPumpingTestRecoveryTestById(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/borehole/water-pump-test/recovery/detail/' + id);
  }



  createWaterPumpingTestCalibration(waterPumpingTestCalibrations: WaterPumpingTestCalibration[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterPumpingTestCalibrationUrl,
      waterPumpingTestCalibrations);
  }

  createWaterPumpingTestConstantRateTest(waterPumpingTestConstantRateTest: WaterPumpingTestConstantRateTest[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterPumpingTestConstantRateTestUrl,
      waterPumpingTestConstantRateTest);
  }

  createConstantRateTestBatch(constantRateTests: WaterConstantRateTest[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createConstantRateTestBatchUrl,
      constantRateTests);
  }

  createWaterConstantRateTest(waterConstantRateTest: WaterConstantRateTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createTestConstantRateTestUrl,
      waterConstantRateTest);
  }

  createWaterObservationBorehole(waterObservationBorehole: WaterObservationBorehole) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createObservationBoreholeUrl,
      waterObservationBorehole);
  }

  getAllWaterBearingZonesByBoreholeId(testId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl
      + ServiceEndPoints.getAllWaterBearingZonesByBoreholeIdUrl + '/' + testId);
  }

  getAllWaterBearingZoneLogsById(id: String) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllWaterBearingZonesLogUrl + '/' + id);
  }

  getWaterBearingZoneLogById(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-bearing-zones/water-bearing-zone-log/one/' + id);
  }


  createWaterPumpingTestRecovery(waterPumpingTestRecovery: WaterPumpingTestRecovery[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createWaterPumpingTestRecoveryUrl,
      waterPumpingTestRecovery);
  }
  deleteWaterPumpingTestCalibration(waterPumpingTestCalibrationId) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteWaterPumpingTestCalibrationUrl, waterPumpingTestCalibrationId);
  }

  deleteWaterPumpingTestCalibrationOne(waterPumpingTestCalibration: WaterPumpingTestCalibration) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/calibration/delete/one', waterPumpingTestCalibration);
  }

  deleteWaterPumpingTestCalibrationBulk(waterPumpingTestCalibrations: WaterPumpingTestCalibration[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/calibration/delete/bulk',waterPumpingTestCalibrations);
  }

  deleteWaterPumpingTestConstantRateTestOne(waterPumpingTestConstantRateTest: WaterConstantRateTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/constant-rate/delete/one', waterPumpingTestConstantRateTest);
  }

  deleteWaterPumpingTestConstantRateTestBulk(waterPumpingTestConstantRateTests: WaterConstantRateTest[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/hydro-geology/v1/water-pump-test/constant-rate/delete/bulk',waterPumpingTestConstantRateTests);
  }


  deleteCasingLogBatch(casingLogs: CasingLog[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/hydro-geology/v1/borehole-construction-casing/delete/batch`,casingLogs);
  }

  deleteScreeningLogBatch(screeningLogs: ScreeningLog[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/hydro-geology/v1/borehole-construction-screening/delete/batch`,screeningLogs);
  }

  deleteWaterBearingZoneLogBatch(waterBearingZones: WaterBearingZones[]) {
    return  this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/hydro-geology/v1/water-bearing-zones/delete/batch`,waterBearingZones)
   }

  batchCreateCalibaration(calibration: WaterPumpingTestCalibration[]){
    return  this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/hydro-geology/v1/water-bearing-zones/delete/batch`,calibration);
  }
}
