import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
// import { BoreholeTypeEnum } from "src/app/shared-kernel/enumerations/borehole-type-enum";
import { BoreholeEditorComponent } from "../borehole-editor/borehole-editor.component";
import { CompanyEnum } from "src/app/shared-kernel/enumerations/company-enum";
import { GeoTechService } from "../../geo-tech.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Borehole } from "src/app/shared-kernel/entity/common/borehole";
import { UnitEnum } from "src/app/shared-kernel/enumerations/unit-enum";

declare var $: any;

@Component({
  selector: "app-borehole-create",
  templateUrl: "./borehole-create.component.html",
  styleUrls: ["./borehole-create.component.scss"],
})
export class BoreholeCreateComponent implements OnInit {
  @ViewChild("boreholeEditorComponent")
  boreholeEditorComponent: BoreholeEditorComponent;

  companyEnum: typeof CompanyEnum = CompanyEnum;
  borehole: Borehole;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BoreholeCreateComponent>,
    private geoTechService: GeoTechService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    let borehole = this.boreholeEditorComponent.boreholeForm.value;

    if (
      borehole.casingHeight &&
      (borehole.casingHeight.measure === "" || !borehole.casingHeight.measure)
    ) {
      borehole.casingHeight = null;
    } else if (borehole.casingHeight) {
      borehole.casingHeight.unit.id = UnitEnum.meter;
    }

    if (
      borehole.estimatedYield &&
      (!borehole.estimatedYield.measure ||
        borehole.estimatedYield.measure === "")
    ) {
      borehole.estimatedYield = null;
    } else if (borehole.estimatedYield) {
      borehole.estimatedYield.unit.id = UnitEnum.yield;
    }

    if (
      borehole.waterStrike &&
      (!borehole.waterStrike.measure || borehole.waterStrike.measure === "")
    ) {
      borehole.waterStrike = null;
    } else if (borehole.waterStrike) {
      borehole.waterStrike.unit.id = UnitEnum.meter;
    }

    if (
      borehole.staticWaterLevel &&
      (!borehole.staticWaterLevel.measure ||
        borehole.staticWaterLevel.measure === "")
    ) {
      borehole.staticWaterLevel = null;
    } else if (borehole.staticWaterLevel) {
      borehole.staticWaterLevel.unit.id = UnitEnum.meter;
    }

    if (
      borehole.holeDiameter &&
      (!borehole.holeDiameter.measure || borehole.holeDiameter.measure === "")
    ) {
      borehole.holeDiameter = null;
    } else if (borehole.holeDiameter) {
      borehole.holeDiameter.unit.id = UnitEnum.meter;
    }

    if (
      borehole.elevation &&
      (!borehole.elevation.measure || borehole.elevation.measure === "")
    ) {
      borehole.elevation = null;
    } else if (borehole.elevation) {
      borehole.elevation.unit.id = UnitEnum.meter;
    }

    if (
      borehole.depthOfSurfaceCasing &&
      (!borehole.depthOfSurfaceCasing.measure ||
        borehole.depthOfSurfaceCasing.measure === "")
    ) {
      borehole.depthOfSurfaceCasing = null;
    } else if (borehole.depthOfSurfaceCasing) {
      borehole.depthOfSurfaceCasing.unit.id = UnitEnum.meter;
    }

    if (
      borehole.plannedDepth &&
      (!borehole.plannedDepth.measure || borehole.plannedDepth.measure === "")
    ) {
      borehole.plannedDepth = null;
    } else if (borehole.plannedDepth) {
      borehole.plannedDepth.unit.id = UnitEnum.meter;
    }

    if (
      borehole.endDepth &&
      (!borehole.endDepth.measure || borehole.endDepth.measure === "")
    ) {
      borehole.endDepth = null;
    } else if (borehole.endDepth) {
      borehole.endDepth.unit.id = UnitEnum.meter;
    }

    if (
      borehole.boreholeDescription &&
      (borehole.boreholeDescription.description === "" ||
        borehole.boreholeDescription.description === null)
    ) {
      borehole.boreholeDescription = null;
    }

    if (
      borehole.boreholeObjective &&
      (borehole.boreholeObjective.description === "" ||
        borehole.boreholeObjective.description === null)
    ) {
      borehole.boreholeObjective = null;
    }

    if (
      borehole.comments &&
      (borehole.comments.description === "" ||
        borehole.comments.description === null)
    ) {
      borehole.comments = null;
    }

    if (
      borehole.locationDescription &&
      (borehole.locationDescription.description === "" ||
        borehole.locationDescription.description === null)
    ) {
      borehole.locationDescription = null;
    }

    if (
      borehole.project &&
      (!borehole.project.id || borehole.project.id === "")
    ) {
      borehole.project = null;
    } else if (borehole.project) {

      borehole.project = this.boreholeEditorComponent.selectedProject;
    }

    if (
      borehole.rigType &&
      (borehole.rigType.id === "" || !borehole.rigType.id)
    ) {
      borehole.rigType = null;
    }

    if (
      borehole.drillingRequestBorehole &&
      (borehole.drillingRequestBorehole.id === "" ||
        !borehole.drillingRequestBorehole.id)
    ) {
      borehole.drillingRequestBorehole = null;
    }

    if (
      borehole.prospectingLicense &&
      (borehole.prospectingLicense.id === "" || !borehole.prospectingLicense.id)
    ) {
      borehole.prospectingLicense = null;
    }

    if (
      borehole.boreholeOwner &&
      (borehole.boreholeOwner.id === "" || !borehole.boreholeOwner.id)
    ) {
      borehole.boreholeOwner = null;
    }

    if (!borehole.boreholeCoreRecoveryLogs) {
      borehole.boreholeCoreRecoveryLogs = [];
    }

    if (!borehole.boreholeLithologyLogs) {
      borehole.boreholeLithologyLogs = [];
    }

    if (!borehole.boreholeFiles) {
      borehole.boreholeFiles = [];
    }

    if (!borehole.boreholeLogRecords) {
      borehole.boreholeLogRecords = [];
    }

    if (!borehole.waterBoreholeLithologyLogs) {
      borehole.waterBoreholeLithologyLogs = [];
    }

    if (!borehole.diameterFormations) {
      borehole.diameterFormations = [];
    }

    if (!borehole.timeAndMotions) {
      borehole.timeAndMotions = [];
    }

    if (!borehole.waterBoreholeDrillersLogs) {
      borehole.waterBoreholeDrillersLogs = [];
    }

    if (!borehole.waterPumpingTests) {
      borehole.waterPumpingTests = [];
    }

    if (!borehole.constructionCasings) {
      borehole.constructionCasings = [];
    }

    if (!borehole.constructionScreenings) {
      borehole.constructionScreenings = [];
    }

    if (!borehole.drillingProtocols) {
      borehole.drillingProtocols = [];
    }

    if (!borehole.waterBearingZonesLogs) {
      borehole.waterBearingZonesLogs = [];
    }

    if (!borehole.sampleTypes) {
      borehole.sampleTypes = [];
    }

    if (!borehole.boreholeLocationImages) {
      borehole.boreholeLocationImages = [];
    }

    if (!borehole.waterPumpingTestStepTest) {
      borehole.waterPumpingTestStepTest = [];
    }

    if (!borehole.penetrationRateLogs) {
      borehole.penetrationRateLogs = [];
    }

    if (!borehole.statusHistory) {
      borehole.statusHistory = [];
    }

    this.spinner.show();

    this.geoTechService.createBorehole(borehole).subscribe(
      (response) => {
        borehole = response as Borehole;
        this.boreholeEditorComponent.boreholeForm
          .get("id")
          .setValue(borehole.id);
      },
      (error) => {
        this.spinner.hide();
        this.toast.error(error.message, this.data.title);
      },
      () => {
        this.spinner.hide();
        this.toast.success(
          "You have Successfully created a New Borehole",
          this.data.title
        );
        this.dialogRef.close(borehole);
      }
    );
  }
}
