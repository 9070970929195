
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder} from '@angular/forms';

import 'jspdf-autotable';
import {CompanyAlphabeticalOrderPipe} from 'src/app/shared-kernel/pipes/company-alpha-order.pipe';
import { AddDRBoreholeComponent } from './add-drborehole/add-drborehole.component';
import { DrillingRequest } from '../../../shared-kernel/entity/driller/drilling-request';
import { DrillerService } from '../../driller.service';
import { DrillingRequestBorehole } from '../../../shared-kernel/entity/driller/drilling-request-borehole';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';

@Component({
  selector: 'app-drilling-request-boreholes',
  templateUrl: './drilling-request-boreholes.component.html',
  styleUrls: ['./drilling-request-boreholes.component.scss']
})

export class DrillingRequestBoreholesComponent implements OnInit {
  dataSourceBorehole: MatTableDataSource<DrillingRequestBorehole> = new MatTableDataSource();
  displayedCompaniesColumns = ['#', 'boreholeNo', 'location', 'boreholeType', 'drillingMethod', 'sampleType', 'view', 'edit' ,'del'];
  loading = true;
  drillingRequestId: string;
  drillingRequest: DrillingRequest;
  project: Project = { id: '', creator: '' };
  drilingRequestBoreholes : DrillingRequestBorehole [];
  isChecked: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dialogConfig = new MatDialogConfig();

  @Output() refreshEvent = new EventEmitter<boolean>();

  constructor(
    private router: Router,private drillerService: DrillerService,
    private companyAlphabeticalOrderPipe: CompanyAlphabeticalOrderPipe,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.drillingRequestId = params.id;
    });
    this.isChecked = false;
  }

  ngOnInit() {
    this.getDrilingRequestById();
    this.getAllDrillingRequestBoreholes();
  }

  getDrilingRequestById() {
    this.drillerService.getDrillingRequestById(this.drillingRequestId)
      .subscribe(data => {
        this.drillingRequest = data as DrillingRequest;
        this.project = this.drillingRequest.project;
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  onView(drillingRequestBorehole:DrillingRequestBorehole){
    const urlParams = {id: drillingRequestBorehole.id};

    this.router.navigate(['portal/drilling/request/detail/borehole-details', urlParams]);
  }

  onCreate(drillingRequestBorehole : any) {
    let drillingRequestBoreholeData : DrillingRequestBorehole = new DrillingRequestBorehole();
    if(drillingRequestBorehole!==''){
      drillingRequestBoreholeData = drillingRequestBorehole;
    }
    drillingRequestBoreholeData.drillingRequest = this.drillingRequest;
    const createDialogRef = this.dialog.open(AddDRBoreholeComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data:drillingRequestBoreholeData
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getAllDrillingRequestBoreholes();
    });
  }

  getAllDrillingRequestBoreholes(){
    this.drillerService.getBoreholesByDrillingRequestId(this.drillingRequestId).subscribe(value => {
        this.drilingRequestBoreholes = value as DrillingRequestBorehole[];
        this.dataSourceBorehole = new MatTableDataSource<DrillingRequestBorehole>(this.drilingRequestBoreholes);

        this.refreshEvent.emit(true);
      }
    ,error => {},
      () => {
        this.resetPaginator();
      });
  }


  applyFilter(filterValue: string) {
    this.dataSourceBorehole.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  resetPaginator() {
    this.dataSourceBorehole.sort = this.sort;
    this.dataSourceBorehole.paginator = this.paginator;
  }

}


