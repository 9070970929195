import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog} from '@angular/material';
import { ActivatedRoute} from '@angular/router';
import {DCPTestService} from '../../../dynamic-cone-penetrometer.service';
import {DcpRoadworksLogsCreateComponent} from './dcp-roadworks-logs-create/dcp-roadworks-logs-create.component';
import {DcpRoadworksLogsDeleteComponent} from './dcp-roadworks-logs-delete/dcp-roadworks-logs-delete.component';
import {DcpSubgradeTest} from '../../dcpSubgradeTest';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Unit} from '../../../../../../shared-kernel/entity/common/unit';
import {UnitEnum} from '../../../../../../shared-kernel/enumerations/unit-enum';
import {DcpRoadworksLog} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/DcpRoadworksLog';

declare var $: any;

@Component({
  selector: 'app-dcp-roadworks-logs',
  templateUrl: './dcp-roadworks-logs.component.html',
  styleUrls: ['./dcp-roadworks-logs.component.scss']
})
export class DcpRoadworksLogsComponent implements OnInit {

  @Input('currentState') currentState: string ='';
  private dcpRoadworkTestId: string;
  dcpRoadworksTest: DcpSubgradeTest;
  selectedCvsRecord: DcpRoadworksLog = new DcpRoadworksLog();
  @ViewChild('fileImportInput') fileImportInput: any;
  dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource();
  displayedColumnsForImport: string[] = ['No.', 'Test Number', 'Latitude', 'Longitude',
    'Location', 'Location Description', 'Number of Blows', 'Penetration', 'Start Depth', 'End Depth', 'del', 'Validation State'];
  displayedDynamicConePenetrometerTestLogsColumns = ['No.', 'NumberOfBlows', 'Penetration', 'PenetrationRate',
    'StartDepth', 'FinishDepth', 'Comments', 'Edit', 'Delete'];
  UnitEnum: typeof UnitEnum = UnitEnum;
  dynamicConePenetrometerTestLog: DcpRoadworksLog;
  csvRecords: any[] = [];
  importValid: boolean = true;
  csvDdataSource: MatTableDataSource<DcpRoadworksLog> = new MatTableDataSource();

  testsPointList: DcpRoadworksLog[] = [];

  @ViewChild(DcpRoadworksLogsCreateComponent) dcpRoadworksLogsCreateModal;
  @ViewChild(DcpRoadworksLogsDeleteComponent) dcpRoadworksLogsDeleteModal;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private dcpTestService: DCPTestService,
    private route: ActivatedRoute,
    private dialog: MatDialog,) {
    this.route.params.subscribe(params => {
      this.dcpRoadworkTestId = params.id;
    });
  }

  ngOnInit() {
    this.getAllDCPTestSubgradeByDynamicConePenetrometerTestId();
    this.getDcpRoadworksTestById();
  }

  resetPaginator() {
    this.dataSourceDynamicConePenetrometerTestLogs.sort = this.sort;
    this.dataSourceDynamicConePenetrometerTestLogs.paginator = this.paginator;
  }

  getAllDCPTestSubgradeByDynamicConePenetrometerTestId() {
    this.dcpTestService.getDcpRoadworksRecordById(this.dcpRoadworkTestId).subscribe(
      data => {
        this.testsPointList = data as DcpRoadworksLog[];
        this.testsPointList.sort((a, b) =>  <any>a.startDepth.measure - <any>b.startDepth.measure);
        this.dataSourceDynamicConePenetrometerTestLogs.data = this.testsPointList;
      }, () => {

      }, () => {
        this.dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource(this.dataSourceDynamicConePenetrometerTestLogs.data);
        this.resetPaginator();
      }
    );
  }

  openCreateForm() {
      const newTestPointDialogRef = this.dialog.open(DcpRoadworksLogsCreateComponent, <MatDialogConfig>{
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: true,
          data: { dcpRoadworksTestId: this.dcpRoadworksTest.id,  dcpRoadworksLog: null}
      });

      newTestPointDialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.getAllDCPTestSubgradeByDynamicConePenetrometerTestId();
            this.getDcpRoadworksTestById();
          }
      });
  }

  openEditForm(dynamicConePenetrometerTestLog: DcpRoadworksLog) {
      const newTestPointDialogRef = this.dialog.open(DcpRoadworksLogsCreateComponent, <MatDialogConfig>{
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: true,
          data: { dcpRoadworksTestId: this.dcpRoadworksTest.id,  dcpRoadworksLog: dynamicConePenetrometerTestLog}
      });

      newTestPointDialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.getAllDCPTestSubgradeByDynamicConePenetrometerTestId();
            this.getDcpRoadworksTestById();
          }
      });
  }

  importCSVFile() {
    this.fileReset();
    $('#uploadCsv').click();
  }


  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }


  saveCsvRecords() {
    this.saveBatch(this.csvRecords);
    this.fileReset();
    $('#csvModal').modal('hide');
  }

  deleteCsvRecord(drl: DcpRoadworksLog) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toaster.success('Record deleted', 'Record Deletion');
  }

  saveBatch(records: any) {
    this.spinner.show();
    this.saveReordsList(records);
  }

  getDcpRoadworksTestById() {
    this.dcpTestService.getAllDCPTestSubgradeByDynamicConePenetrometerTestId(this.dcpRoadworkTestId).subscribe(data => {
        this.dcpRoadworksTest = data as DcpSubgradeTest;
      }
    );
  }

  saveReordsList(records) {

    this.dcpTestService.createDynamicConePenetroMeterRoadworkLogRecordBatch(records).subscribe(
      data => {
      }
      , () => {
        this.toaster.error('An error has occured', 'RoadworksLog Create');
      }
      , () => {
        $('#csvModal').modal('hide');
        this.toaster.success('You have Successfully created a New Roadworks Logs', 'RoadworksLog Create');
        $('#refreshTable').click();
      }
    );
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];
    this.importValid =true;
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        const csvRecord: DcpRoadworksLog = new DcpRoadworksLog();
        csvRecord.testNumber = parseFloat(data[0].trim());
        csvRecord.numberOfBlows = parseFloat(data[5].trim());
        csvRecord.penetration.measure = data[6];
        csvRecord.startDepth.measure = data[7].trim();
        csvRecord.endDepth.measure = data[8].trim();
        csvRecord.location.village.name = data[3].trim();
        csvRecord.location.coordinates.latitude = data[1].trim();
        csvRecord.location.coordinates.longitude = data[2].trim();
        csvRecord.deleted = false;

        csvRecord.endDepth.unit = new Unit();
        csvRecord.endDepth.unit.id = this.UnitEnum.meter;
        csvRecord.startDepth.unit = new Unit();
        csvRecord.startDepth.unit.id = this.UnitEnum.meter;
        csvRecord.penetration.unit = new Unit();
        csvRecord.penetration.unit.id = this.UnitEnum.millimeter;
        csvRecord.dynamicConePenetrometerTestRoadworks = this.dcpRoadworksTest;

        csvRecord.dynamicConePenetrometerTestRoadworks = this.dcpRoadworksTest;
        csvRecord.comments.description = data[4].trim();
        csvRecord.dynamicConePenetrometerTestRoadworks = this.dcpRoadworksTest;
        if (parseFloat(csvRecord.startDepth.measure) > parseFloat(csvRecord.endDepth.measure)) {
          this.importValid = false;
        }

        dataArr.push(csvRecord);
      }
    }
    this.csvDdataSource.data = dataArr;
    return dataArr;
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
  }

  showCsvModal() {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  deleteRoadworksLog(dcpRoadworksLog: DcpRoadworksLog) {
    $('#deleteModal').modal({
      open:
        this.dcpRoadworksLogsDeleteModal.deleteModal(dcpRoadworksLog)
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceDynamicConePenetrometerTestLogs.filter = filterValue.trim().toLowerCase();
  }

}
