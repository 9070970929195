import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { ApproveAction } from '../../models';
import { ProjectService } from '../project.service';
import { ApprovalService } from '../../geo-tech/borehole/services/approval.service';
import { DocumentsService } from '../../../shared/documents/dococument.service';
import { ProjectInfo, ProjectMembersComponent } from './project-members/project-members.component';
import { PeopleData } from '../../../_core/data/people';
import { ProjectMembers } from './project-members/project-member.model';
import { UpdateMemberRoleComponent } from './project-members/update-role/update-role.component';
import {ProjectCreateComponent} from '../project-create/project-create.component';
import { StorageConstants } from '../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
    selector: 'project-details',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.scss']
})

export class ProjectDetailComponent implements OnInit {
    project: any;
    projectId: string;
    expanded: boolean = true;
    documentCount: number = 0;
    filesCount: number = 0;
    selectedSection: string;
    file = false;
    filter : string;
    currentComment: string;
    mineralOrProjectType: string = '--';
    projectCategories: string = '--';
    projectMembers: Array<ProjectMembers> = [];
    dbMembers: Array<any> = [];
     currentPrincipal: any;

    dialogConfig = new MatDialogConfig();

    unapprovedActivitiesCount = 0;

    isLoggedPersonCreator:boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _dialog: MatDialog,
        private _toastr: ToastrService,
        private _projectSrv: ProjectService,
        private _approvalServ: ApprovalService,
        private _docServ: DocumentsService,
        private _peopleServ: PeopleData,
        private _localStorage: LocalStorage,
    ) { }

    ngOnInit() {

        this._route.params.subscribe(params => {
            this.projectId = params.id;
            if(params.file){
              this.file = true;
              this.filter = params.filter;
            }
            this._getProject();
        });

        if(this.file){
          this.onSectionChange('projectFiles');
        }

        this.getUnapprovedProjectActivityByProjectIdCount();

    }

    private getUnapprovedProjectActivityByProjectIdCount() {
      this._projectSrv.getUnapprovedProjectActivityByProjectIdCount(this.projectId).subscribe(res => {
        this.unapprovedActivitiesCount = res;
      });
  }

  private _currentPrincipal() {
    this._localStorage.getItem<any>(StorageConstants.fullUserKey).subscribe(res => {
      this.currentPrincipal = res;
      if (this.currentPrincipal.username === this.project.creator) {
        this.isLoggedPersonCreator = true;
      } else {
        this.isLoggedPersonCreator = false;
      }
    });
  }

  onSectionChange(selectedSection: string) {
        this.expanded = false;
        this.selectedSection = selectedSection;
    }

    exportPDF() {
        let d = this.project;

        const doc = new jsPDF('p', 'pt', 'a4');

        doc.setFontSize(8);
        doc.setFont('courier');
        doc.setFontType('normal');
        doc.text(20, 20, 'Botswana Geoscience Institute');

        doc.setLineWidth(2);
        doc.line(20, 30, 100, 30);
        doc.setFontSize(16);
        doc.setFont('courier');
        doc.setFontType('normal');
        doc.text(20, 50, 'Project  Profile');

        var commoditiesString = d.commodities.map(x => x.name).join(",");

        const columns = [
            { title: '', dataKey: 'column1' },
            { title: '', dataKey: 'column2' },
            { title: '', dataKey: 'column3' },
            { title: '', dataKey: 'column4' },
        ];

        let data = [d.name, d.company.name, new Date(d.startDate).toLocaleString().substr(0, 10),
        new Date(d.endDate).toLocaleString().substr(0, 10), d.status[0].status, d.objective.description, commoditiesString.substr(1)];
        let labels = ['Project Name:', 'Company Name:', 'Start Date:', 'End Date:', 'Project Status:', 'Objective(s):', 'Commodities:'];

        doc.autoTable(columns, this.pdfFormart(data, labels), {
            styles: { fontSize: 10 },
            theme: 'striped',
            head: [[{ content: 'Company Information', colSpan: 4, styles: { halign: 'left', fillColor: [87, 52, 25], } }]],
            columnStyles: {
                column1: {
                    textColor: [15, 15, 15], fontStyle: 'bold'
                },
                column3: {
                    textColor: [15, 15, 15], fontStyle: 'bold'
                }
            },
            margin: { top: 70 },
        });

        doc.save('project( ' + d.name + ' )-details-' + Date() + '.pdf');
    }

    pdfFormart(data, labels) {
        const documentObject = [
            {
                'column1': labels[0].toUpperCase(),
                'column2': data[0],
                'column3': labels[1].toUpperCase(),
                'column4': data[1]
            },
            {
                'column1': labels[2].toUpperCase(),
                'column2': data[2],
                'column3': labels[3].toUpperCase(),
                'column4': data[3]
            },

            {
                'column1': labels[4].toUpperCase(),
                'column2': data[4],
                'column3': '',
                'column4': ''
            },
            {
                'column1': labels[5].toUpperCase(),
                'column2': data[5],
                'column3': '',
                'column4': ''
            },
            {
                'column1': labels[6].toUpperCase(),
                'column2': data[6],
                'column3': '',
                'column4': ''
            }
        ];

        return documentObject;
    }

    openEditForm() {
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = true;
      this.dialogConfig.width = '700px';
      this.dialogConfig.data = {
        project: this.project
      };

      this._dialog.open(ProjectCreateComponent, this.dialogConfig)
        .afterClosed().subscribe(result => {
        this._getProject();
      });
    }

    viewMembers() {
        const membersDialogRef = this._dialog.open(ProjectMembersComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: <ProjectInfo>{
                projectId: this.project.id,
                companyId: this.project.company.id,
                members: this.projectMembers
            }
        });

        membersDialogRef.afterClosed().subscribe(res => {
            this._getProject();
        });
    }

    editProjectMember(m) {
        const editMemberRoleDialog = this._dialog.open(UpdateMemberRoleComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: m
        });

        editMemberRoleDialog.afterClosed().subscribe(res => {
            this._getProject();
        });
    }

    changeState(action: ApproveAction) {
        let successMessage: string = 'Project submitted successfully';
        let title: string = 'Project Submit';
        let actionCode: number;

        switch (+action) {
            case ApproveAction.SUBMIT_TO_MANAGER:
                successMessage = 'Project submitted successfully';
                title = 'Project Submit';
                this.currentComment = 'Submit To Manager';
                actionCode = 9;
                break;
            case ApproveAction.MANAGER_APPROVE:
                successMessage = 'Project approved successfully';
                title = 'Project Approved';
                actionCode = 3;
                break;
            case ApproveAction.MANAGER_REJECT:
                successMessage = 'Project rejected successfully';
                title = 'Project Rejected';
                actionCode = 4;
                break;
            case ApproveAction.UN_PUBLISH:
                successMessage = 'Project published successfully';
                title = 'Project Published';
                actionCode = 5;
                break;
            default:
                throw new Error('Unknown change state');
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(result => {
            if (result.value) {
                this._approvalServ.changeProjectState(this.project.id, {
                    action: actionCode, comment: this.currentComment
                }).subscribe(res => {
                    this._toastr.success(successMessage, title);
                    this._getProject();
                }, err => {
                    this._toastr.warning(err.message, 'Failed');
                });
            }
        });

    }

    private _getProject() {
        this._projectSrv.getProjectById(this.projectId).subscribe(res => {
            this.project = res;
            this.countDocuments();
            this.countFiles();
            this._mineralProjectTypes();
            this._getMembers();
            this._currentPrincipal();
        }, err => {
            this._toastr.error(err.message, 'Failed to retrieve project');
        });
    }

    private countDocuments() {
        this._docServ.getDocumentCount(this.project.id).subscribe(res => {
            this.documentCount = res;
        });
    }

      private countFiles() {
        this._docServ.getFileCount(this.project.id).subscribe(res => {
          this.filesCount = res;
        });
      }



    private _mineralProjectTypes() {
        if (this.project.projectCategoryTypes && this.project.projectCategoryTypes.length > 0) {
            this.projectCategories = this.project.projectCategoryTypes.map(x => x.name).join(',');

            this.project.projectCategoryTypes.forEach(projectCategoryType => {
                this.mineralOrProjectType = projectCategoryType.projectCategory ? projectCategoryType.projectCategory.name : '--';
            });
        }

        if (this.project.commodities && this.project.commodities.length > 0) {
            this.projectCategories = this.project.commodities.map(x => x.name).join(',');

            this.project.commodities.forEach(commodity => {
                this.mineralOrProjectType = commodity.mineralType ? commodity.mineralType.name : '--';
            });
        }
    }

    private _getMembers() {
        this._projectSrv.getProjectMembers(this.projectId).subscribe(res => {
            const ids = [];
            this.dbMembers = res as Array<any>;
            this.dbMembers.forEach(m => {
                ids.push(m.personId);
            });

            if (ids.length <= 0) return;

            this._peopleServ.getPeopleByIdList(ids).subscribe(res => {
                this._populateMembers(res);
            }, err => {
                this._toastr.error(err.message, 'Failed to retrieve members');
            });
        }, err => {
            this._toastr.error(err.message, 'Failed to retrieve members');
        });
    }

    private _populateMembers(people: Array<any>) {
        while (this.projectMembers.length > 0) this.projectMembers.pop();

        people.forEach(p => {
            const currM = this.dbMembers.find(m => m.personId === p.id);

            if (currM.deleted === false) {
                this.projectMembers.push(<ProjectMembers>{
                    id: currM.id,
                    personId: p.id,
                    project: currM.project,
                    deleted: currM.deleted,
                    projectRoles: currM.projectRoles,
                    nonBgiRole: currM.nonBgiRole,
                    extra: p.name + ' ' + p.surname
                });
            }
        });
    }

    closeProject() {
      this._projectSrv.closeProject(this.project).subscribe(res => {
        this.project = res;
        this._toastr.success('Project closed successfully', 'Close Project');
        this._getProject();
      }, err => {
          this._toastr.warning(err.message, 'Failed');
      });
    }
}
