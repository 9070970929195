import { Component, OnInit, Inject, PipeTransform, Pipe } from "@angular/core";
import {
  MatDialogRef,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ContactDetail,
  ContactDetailType,
  Honorific,
  Person,
  Sex,
} from "../../_core/data/_models/people.model";
import { Company } from "../../shared-kernel/entity/applied-geoscience/company/company";
import { CompanyValidation } from "../../applied-geoscience/company/company.validation";
import { PeopleData } from "../../_core/data/people";
import { ToastrService } from "ngx-toastr";
import { CompanyService } from "../../applied-geoscience/company/company.service";
import { NigisImages } from "../../shared-kernel/entity/common/nigisImages";
import { Observable } from "rxjs";
import { CompanyEnum } from "../../shared-kernel/enumerations/company-enum";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-personnel-create",
  templateUrl: "./personnel-create.component.html",
  styleUrls: ["./personnel-create.component.scss"],
})
export class PersonnelCreateComponent implements OnInit {
  personalForm: FormGroup;
  contactDetailsForm: FormGroup;
  companyOptions: Observable<Company[]>;
  _loadingCompany = false;
  titles: Array<Honorific>;
  gender: Array<Sex>;
  companies: Array<Company>;
  contactDetailTypes: Array<ContactDetailType>;
  profilePicture: NigisImages;
  profilePictureString: string;
  contactDetailForTable: ContactDetail;
  contactDetail: ContactDetail;
  contact: string;
  submitted: boolean = false;
  contactDetailsValid: boolean = false;
  companyHidden: boolean = false;

  emailFormControl = this.companyValidation.emailFormControl;
  telephoneFormControl = this.companyValidation.telephoneFormControl;
  myForm: FormGroup;

  title = "Create New Personnel”";

  constructor(
    public dialogRef: MatDialogRef<PersonnelCreateComponent>,
    private formBuilder: FormBuilder,
    private companyValidation: CompanyValidation,
    private peopleData: PeopleData,
    private toastr: ToastrService,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getTitles();
    this.getGender();
    this.getAllContactDetailTypes();
    this.getAllCompanies();
  }

  hideCompany() {
    let p = this.personalForm.value;
    this.companyHidden =
      !p.company || p.company == null || !p.company.id || p.company.id == null; //!this.companyHidden;

    this.personalForm.controls.company.reset();
    if (this.companyHidden === false) {
      this.personalForm.get("company").setValidators([Validators.required]);
      this.personalForm.get("company").updateValueAndValidity();
    } else {
      this.personalForm.get("company").clearValidators();
      this.personalForm.get("company").updateValueAndValidity();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  displayFn(company: Company): string {
    return company && company.name ? company.name : "";
  }

  private loadForm() {
    if (this.data != null) {
      let person = this.data.person ? this.data.person : this.data;

      if (person && person.id) {
        this.title = "Edit Personnel";
      }

      let companyDetail;
      let sexDetail: Sex;
      if (person.company != null || person != null) {
        companyDetail = person.company;
      } else {
        companyDetail = null;
      }

      if (person.sex != null || person != null) {
        sexDetail = person.sex;
      } else {
        sexDetail = null;
      }

      if (person && person != null && person.profilePicture != null) {
        this.profilePictureString = person.profilePicture.image;
      }

      let p = companyDetail
        ? this.companies.find((value) => value.id === companyDetail)
        : companyDetail;

      this.companyHidden =
        person.company == undefined || person.company == null;

      this.personalForm = this.formBuilder.group({
        title: [person.title],
        name: [
          {
            value: person.name,
            disabled: this.data.options ? this.data.options : false,
          },
        ],
        surname: [
          {
            value: person.surname,
            disabled: this.data.options ? this.data.options : false,
          },
        ],
        middleName: [
          {
            value: person.middleName,
            disabled: this.data.options ? this.data.options : false,
          },
        ],
        profession: [person.profession],
        myCheckbox: [this.companyHidden],
        sex: [sexDetail],
        company: [
          companyDetail
            ? this.companies.find((value) => value.id === companyDetail)
            : companyDetail,
        ],
        contactDetails: this.createContactDetailsFormArray(
          person.contactDetails
        ),
      });

      this.initContactDetails();
    } else {
      this.initForms();
    }
  }

  private initForms() {
    this.personalForm = this.formBuilder.group({
      title: [null, Validators.required],
      name: ["", Validators.required],
      surname: ["", Validators.required],
      middleName: [""],
      workIdNumber: [""],
      profession: [""],
      myCheckbox: [false],
      sex: [null, Validators.required],
      company: [null, Validators.required],
      contact: [""],
      contactDetails: this.createContactDetailsFormArray([]),
    });
    this.initContactDetails();
  }

  get contactDetailsFormArray(): FormArray {
    return this.personalForm.get("contactDetails") as FormArray;
  }

  createContactDetailsFormArray(contactDetails: ContactDetail[]): FormArray {
    const formArray = this.formBuilder.array([]);
    contactDetails.forEach((s) => {
      formArray.push(this.createContactDetailFormGroup(s));
    });
    return formArray;
  }

  createContactDetailFormGroup(contactDetail: ContactDetail): FormGroup {
    return this.formBuilder.group({
      id: [contactDetail ? contactDetail.id : ""],
      address: [
        contactDetail ? contactDetail.address : "",
        Validators.required,
      ],
      contactDetailType: this.createContactDetailTypeFormGroup(
        contactDetail ? contactDetail.contactDetailType : { id: "", name: "" }
      ),
    });
  }

  createContactDetailTypeFormGroup(
    contactDetailType: ContactDetailType
  ): FormGroup {
    return this.formBuilder.group({
      id: [contactDetailType ? contactDetailType.id : "", Validators.required],
      name: [
        contactDetailType ? contactDetailType.name : "",
        Validators.required,
      ],
    });
  }

  initContactDetails() {
    this.contactDetailsForm = this.formBuilder.group({
      id: ["", [Validators.pattern("[0-9]*"), Validators.maxLength(12)]],
      contactDetailType: [null],
      address: [""],
    });
    const contactType: ContactDetailType = new (class
      implements ContactDetailType
    {
      created: string | Date | any;
      creator: any | null;
      id: string;
      name: string;
      reference: null;
      updated: string | Date | any;
      updater: any | string | Date | null;
    })();
    this.contact = "";
  }

  onSubmit() {
    const personalInfo = this.personalForm.getRawValue();

    if (this.data != null) {
      const person = this.data.person ? this.data.person : this.data;

      person.name = personalInfo.name;
      person.middleName = personalInfo.middleName;
      person.profession = personalInfo.profession;
      person.sex = personalInfo.sex;
      person.surname = personalInfo.surname;
      person.title = personalInfo.title;
      person.contactDetails = personalInfo.contactDetails;
      person.profilePicture = this.profilePicture;

      if (personalInfo.company) {
        person.company = personalInfo.company.id;
      } else {
        person.company = null;
      }

      this.peopleData.editUser(person).subscribe(
        (res) => {
          this.submitted = false;
          this.dialogRef.close(res);
          this.toastr.success("Person Updated Successfully", "Update Person");
        },
        (err) => {
          this.toastr.error(err.message, "Failed to update a person");
          this.submitted = false;
        }
      );
    } else {
      const person: Person = new (class implements Person {
        name: string = personalInfo.name;
        middleName: string = personalInfo.middleName;
        profession: string = personalInfo.profession;
        sex: Sex = personalInfo.sex;
        surname: string = personalInfo.surname;
        title: Honorific = personalInfo.title;
      })();

      if (personalInfo.company) {
        person.company = personalInfo.company.id;
      } else {
        person.company = null;
      }

      person.profilePicture = this.profilePicture;
      person.contactDetails = personalInfo.contactDetails;

      this.peopleData.createPerson(person).subscribe(
        (res) => {
          this.submitted = false;
          this.dialogRef.close(res);
          this.toastr.success("Person Created Successfully", "Create Person");
        },
        (err) => {
          this.toastr.error(err.message, "Failed to create a person");
          this.submitted = false;
        }
      );
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileName = file.name;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        const fileSplitdata = reader.result.toString().split(",");
        this.profilePicture = new NigisImages();
        this.profilePicture.image = fileSplitdata[1];
        this.profilePicture.name = fileName;
        this.profilePictureString = fileSplitdata[1];
      };
    }
  }

  removeContactDetailFromList(index: number) {
    if (index < 0 || index >= this.contactDetailsFormArray.length) {
      return;
    }
    this.contactDetailsFormArray.removeAt(index);
  }

  addToContactDetailsTable() {
    this.contactDetailsFormArray.insert(
      0,
      this.createContactDetailFormGroup({
        address: "",
        contactDetailType: undefined,
      })
    );
  }

  clearContactForm() {
    this.contactDetailsForm.reset();
  }

  private getTitles() {
    this.peopleData.getTitles().subscribe(
      (res) => {
        this.titles = res;
      },
      (err) => {
        this.toastr.warning(err.message, "Failed to get titles");
      }
    );
  }

  private getGender() {
    this.peopleData.getGender().subscribe(
      (res) => {
        this.gender = res;
      },
      (err) => {
        this.toastr.warning(err.message, "Failed to get gender types");
      }
    );
  }

  getAllCompanies() {
    this._loadingCompany = true;
    this.companyService.getAllCompanies().subscribe((data) => {
      this.companies = (data as Company[]).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      this._loadingCompany = false;
      this.loadForm();
      this.companyOptions = this.personalForm.get("company").valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.companies.slice()))
      );
    });
  }

  private _filter(name: string): Company[] {
    const filterValue = name.toLowerCase();
    return this.companies.filter((company) =>
      company.name.toLowerCase().includes(filterValue)
    );
  }

  getAllContactDetailTypes() {
    this.companyService.getAllContactDetailTypes().subscribe((data) => {
      this.contactDetailTypes = data as ContactDetailType[];
    });
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo
      ? variableOne.id === variableTwo.id
      : variableOne === variableTwo;
  }

  contactTypeSelected(contactDetailType, index) {
    let selectedDetailType = this.contactDetailsFormArray
      .at(index)
      .get("contactDetailType");
    selectedDetailType.get("id").setValue(contactDetailType.id);

    if (contactDetailType.name === "Email") {
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .setValidators([Validators.required, Validators.email]);
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .updateValueAndValidity();
    } else if (contactDetailType.name === "Telephone") {
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .setValidators([
          Validators.required,
          Validators.pattern("[0-9]*"),
          Validators.maxLength(12),
        ]);
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .updateValueAndValidity();
    } else if (contactDetailType.name === "Cellphone") {
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .setValidators([
          Validators.required,
          Validators.pattern("[0-9]*"),
          Validators.maxLength(12),
        ]);
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .updateValueAndValidity();
    } else if (contactDetailType.name === "Fax") {
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .setValidators([
          Validators.required,
          Validators.pattern("[0-9]*"),
          Validators.maxLength(12),
        ]);
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .updateValueAndValidity();
    } else if (contactDetailType.name === "Website") {
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .setValidators([
          Validators.required,
          Validators.pattern(
            "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
          ),
        ]);
      this.contactDetailsFormArray
        .at(index)
        .get("address")
        .updateValueAndValidity();
    }

  }
}

@Pipe({
  name: "companyAlphaOrder",
})
export class CompanyAlphaOrderPipe implements PipeTransform {
  transform(value: Company[]) {
    return value.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
}
