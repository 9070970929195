import { Injectable } from "@angular/core";

import { LocalStorage } from '@ngx-pwa/local-storage';

import { StorageConstants } from 'src/app/_core/storage.constants';

@Injectable({
    providedIn: 'root'
})
export class UserAuthoritiesService {
    constructor(
        private _localStorage: LocalStorage
    ) { }

    public async getAuthorities(): Promise<Array<any>> {
        const fullUser = await this._localStorage.getItem<any>(StorageConstants.fullUserKey).toPromise();

        if (!fullUser) return [];
        
        let tempAuth: string[] = [];

        fullUser.roles.forEach(role => {
            role.authorityGroups.forEach(authorityGroup => {
                authorityGroup.authorities.forEach(authority => {
                    tempAuth.push(authority.name);
                });
            });
        });

        let authorities: Set<string> = new Set<string>(tempAuth);

        while(tempAuth.length) { tempAuth.pop() }

        authorities.forEach(authority => { tempAuth.push(authority) });

        return tempAuth;
    }

    public async getAuthorityByName(name: string): Promise<string> {
        let all = await this.getAuthorities();

        return all.find(authority => authority.trim().toLowerCase() === name.trim().toLowerCase());
    }

    public async hasAuthority(name: string): Promise<boolean> {
        const authorityExists = await this.getAuthorityByName(name);
        return authorityExists ? true : false;
    }
}