import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Size} from '../../../../../../shared-kernel/entity/common/size';
import {DiscontinuityType} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/DiscontinuityType';
import {MacroRoughness} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/MacroRoughness';
import {MicroRoughness} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/MicroRoughness';
import {InFillType} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/InFillType';
import {Separation} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/Separation';
import {JointWallAlteration} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/JointWallAlteration ';
import {DiscontinuityLog} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/DiscontinuityLog';
import {UserService} from '../../../../../../auth/user/user.service';
import {CoreRecoveryLog} from '../../../../../../shared-kernel/entity/applied-geoscience/geoTech/CoreRecoveryLog';
import {MatTableDataSource} from '@angular/material';
import {CoreService} from '../../../core.service';
import {Unit} from '../../../../../../shared-kernel/entity/common/unit';
import {UnitEnum} from '../../../../../../shared-kernel/enumerations/unit-enum';
declare var $: any;
@Component({
  selector: 'app-discontinuity-log-create',
  templateUrl: './discontinuity-log-create.component.html',
  styleUrls: ['./discontinuity-log-create.component.scss']
})
export class DiscontinuityLogCreateComponent implements OnInit {

  model: any = {};
  runNumber: string;
  depthDownMeasure: string;
  discontinuityLog: DiscontinuityLog;
  depthDown: Size;

  comments: string;
  coreRecoveryId: string;
  coreRecoveryLog: CoreRecoveryLog;
  discontinuityType: DiscontinuityType;
  discontinuityTypeList: DiscontinuityType [];
  dipDirection: string;
  unit: Unit;
  UnitEnum: typeof UnitEnum = UnitEnum;
  angleFromHorizontal: string;
  macroRoughness: MacroRoughness;
  macroRoughnessList: MacroRoughness [];
  microRoughness: MicroRoughness;
  microRoughnessList: MicroRoughness [];
  inFillType: InFillType;
  infillTypeList: InFillType [];
  separation: Separation;
  separationList: Separation [];
  jointWallAlteration: JointWallAlteration;
  jointWallAlterationList: JointWallAlteration[];

  constructor(private router: ActivatedRoute, private pageroute: Router, private coreService: CoreService) {

    router.params.subscribe(
      data => {
        this.runNumber = data.run;
        this.coreRecoveryId = data.core;
      }
    );
  }

  ngOnInit() {
    this.depthDown = new Size();
    this.depthDownMeasure = '';
    this.unit = new Unit();
    this.discontinuityLog = new DiscontinuityLog;
    this.discontinuityType = new DiscontinuityType;
    this.dipDirection = '';
    this.coreRecoveryLog = new CoreRecoveryLog();
    this.angleFromHorizontal = '';
    this.macroRoughness = new MacroRoughness;
    this.microRoughness = new MicroRoughness;
    this.inFillType = new InFillType;
    this.comments = '';
    this.separation = new Separation;
    this.jointWallAlteration = new JointWallAlteration;
    this.getAllDiscontinuityType();
    this.getMacroRoughnessList();
    this.getMicroRoughnessList();
    this.getFillTypeList();
    this.getCoreRecoveryLogById(this.coreRecoveryId);
    this.getSeparationList();
    this.getJointWallAlterationList();
  }

  getCoreRecoveryLogById(id) {
    this.coreService.getCoreRecoveryById(id).subscribe(
      data => {
        this.coreRecoveryLog = data as CoreRecoveryLog;
      }
    );
  }

  getAllDiscontinuityType() {
    this.coreService.getAllDiscontinuityType().subscribe(
      data => {
        this.discontinuityTypeList = data as DiscontinuityType[];
      }
    );

  }

  getMacroRoughnessList() {
    this.coreService.getAllMacroRoughness().subscribe(
      data => {
        this.macroRoughnessList = data as MacroRoughness[];
      }
    );

  }

  getMicroRoughnessList() {
    this.coreService.getAllMicroRoughness().subscribe(
      data => {
        this.microRoughnessList = data as MicroRoughness[];
      }
    );

  }

  getFillTypeList() {
    this.coreService.getAllFillTypeLogs().subscribe(
      data => {
        this.infillTypeList = data as InFillType[];
      }
    );

  }

  getSeparationList() {
    this.coreService.getAllSeparationLogs().subscribe(
      data => {
        this.separationList = data as Separation[];
      }
    );

  }

  getJointWallAlterationList() {
    this.coreService.getAllJointWallAlterationLogs().subscribe(
      data => {
        this.jointWallAlterationList = data as JointWallAlteration[];
      }
    );

  }


  onSubmit() {
    this.discontinuityLog.coreRecoveryLog = this.coreRecoveryLog;
    this.depthDown.measure = this.model.depthDownMeasure;
    this.discontinuityLog.depthDown = this.depthDown;
    this.unit.id = UnitEnum.meter;
    this.discontinuityLog.depthDown.unit = this.unit;
    this.discontinuityLog.angleFromHorizontal = this.model.angleFromHorizontal;
    this.discontinuityLog.dipDirection = this.model.dipDirection;
    this.discontinuityLog.discontinuityType = this.model.discontinuityType;
    this.discontinuityLog.infillType = this.model.inFillType;
    this.discontinuityLog.jointWallAlteration = this.model.jointWallAlteration;
    this.discontinuityLog.macroRoughness = this.model.macroRoughness;
    this.discontinuityLog.microRoughness = this.model.microRoughness;
    this.discontinuityLog.comments = this.model.comments;
    this.discontinuityLog.separation = this.model.separation;
    this.coreService.createBoreholeCoreRecoveryDiscontinuityLog(this.discontinuityLog, this.runNumber);
    $('#discontinuityLogCreate').modal('hide');
  }


}
