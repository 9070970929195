import { Commodity } from './../../../shared-kernel/entity/applied-geoscience/project/commodity';
import { Project } from './../../../shared-kernel/entity/applied-geoscience/project/project';
import { MineralType } from './../../../shared-kernel/entity/applied-geoscience/prospecting-license/mineral-type';
import { Company } from './../../../shared-kernel/entity/applied-geoscience/company/company';
import { ProjectCategoryType } from './../../../shared-kernel/entity/applied-geoscience/project/ProjectCategoryType';
import { ProjectTypeEnum } from './../../../shared-kernel/enumerations/project-type-enum';
import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProjectService} from '../project.service';
import {CompanyService} from '../../company/company.service';
import {ProjectValidation} from '../project.validation';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {UserRoles} from '../../../_auth/services/user-roles';
import {ProjectType} from '../../../shared-kernel/entity/applied-geoscience/project/ProjectType';
import {AuthConstants} from '../../../_auth/auth.constants';
import {Notes} from '../../../shared-kernel/entity/applied-geoscience/notes';
import {map, startWith} from 'rxjs/operators';
import {CompanyEnum} from '../../../shared-kernel/enumerations/company-enum';
import { BusinessUnitEnum } from '../../../shared-kernel/enumerations/business-unit-enum';
import { StorageConstants } from '../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

  projectForm: FormGroup;
  companies: Company[] = [];
  companyOptions: Observable<Company[]>;
  projectTypes: ProjectType[] = [];
  selectedProjectType: ProjectType;
  mineralTypes: MineralType[] = [];
  commodities: Commodity[] = [];
  selectedCommodities: Commodity[] = [];
  hasDrillingRequest = false;
  userRoles: any[];
  projectTypeEnum = ProjectTypeEnum;
  projectCategories: any[] = [];
  projectCategoryTypes: any[] = [];
  selectedProjectCategoryTypes: any[] = [];
  project: Project;

  /**
   *  Loading indicators */
  _loadingProjectTypes = false;
  _loadingProjectTypeRoles = false;

  _loadingCompany = false;

  _loadingMineralTypes = false;
  _loadingCommodities = false;
  _loadingProjectCategories = false;

  _showMineralType = false;
  _showProjectType = false;

  _hasAdminRole = false;
  currentUser: any;
  clayMineral:MineralType= new MineralType();

  selectOther: boolean = false;


  constructor(
    private projectService: ProjectService,
    private companyService: CompanyService,
    private projectValidation: ProjectValidation,
    private spinner: NgxSpinnerService,
    private userRole: UserRoles,
    private toastr: ToastrService,
    private _localStorage: LocalStorage,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProjectCreateComponent>) {

  }

  ngOnInit() {
    
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

    }, err => {
      this.toastr.warning(err.message, "Current User Error");
    },() => {

    });
    if (this.data.project) {
      this._getProject();
    }

    this.initForm();

    this.userRole.hasRole(AuthConstants.systemAdminRole).then(hasRole => {
      if (hasRole) {
        this._hasAdminRole = true;
      }
    });
    this.userRole.getAllRoles().then(roles => {
      this.userRoles = roles;
    });
    /**
     * Initialize form on edit     */

    this.getAllCompanies();
    this.getProjectRoles();
  }

  private _getProject() {
    this.projectService.getProjectById(this.data.project.id).subscribe(res => {
        this.project = res as Project;
        this.initForm();

      this.selectedProjectCategoryTypes = this.project.projectCategoryTypes;
      this.selectedCommodities = this.project.commodities;
      this.hasDrillingRequest = this.project.hasDrillingRequest;

      if(this.selectedProjectCategoryTypes.length === 0) {
        if(this.selectedCommodities.length === 0) {
          this.selectOther = true;
          this.selectedProjectCategoryTypes = [];
          this.selectedCommodities = [];
          this._showMineralType = false;
          this.projectForm.get('selectOthers').setValue(this.selectOther);
        } else {
          this.projectForm.get('selectOthers').setValue(false);
        }
      }
    }, err => {
        this.toastr.error(err.message, 'Failed to retrieve project');
    });
}

  initForm() {

    this.projectForm = this.formBuilder.group({
      id: [this.project ? this.project.id : null],
      company: [this.project ? this.project.company : null, Validators.required],
      name: [this.project ? this.project.name : null, Validators.required],
      startDate: [this.project ? new Date(this.project.startDate) : null, Validators.required],
      endDate: [this.project ? new Date(this.project.endDate) : null, Validators.required],
      objective: [this.project ? this.project.objective? this.project.objective.description : '' : ''],
      roleType: [this.project ? this.project.projectType : null, Validators.required],
      selectOthers: new FormControl(false),
    });
  }

  getUserRoles(systemRole:string){

    this.userRole.hasRole(systemRole).then(hasRole => {
      if (hasRole) {
        this._hasAdminRole = true;
      }
    });
  }

  private _filter(name: string): Company[] {
    const filterValue = name.toLowerCase();
    return this.companies.filter(company => company.name.toLowerCase().includes(filterValue));
  }

  displayFn(company: Company): string {
    return company && company.name ? company.name : '';
  }

  onClose() {
    this.dialogRef.close();
  }

  getAllCompanies() {
    this._loadingCompany = true;
    this.companyService.getAllCompanies()
      .subscribe(data => {
        this.companies = data as Company[];
        this._loadingCompany = false;

        // -- Pre-select BGI --
        if(!this.data.project){
          this.companies.forEach(company => {
            if (company.name === CompanyEnum.BGI_NAME) {

            }
          });
        }else{
          this.projectForm.get('company').setValue(this.data.project.company);
        }

        this.companyOptions = this.projectForm.get('company').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.companies.slice())
          );

      });
  }

  getAllMineralTypes() {
    this._loadingMineralTypes = true;
    this.commodities = [];
    this.projectService.getAllMineralTypes()
      .subscribe(data => {
        this.mineralTypes = data as MineralType[];//e498ae2c-f787-4d98-a768-1e6e0f93d849
          this.clayMineral = this.mineralTypes.filter(value => value.name==='Clay Minerals')[0];

          this.mineralTypes = this.mineralTypes.filter(value => value.name!=='Clay Minerals'
            && value.name!=='Regional Mapping'&& value.name!=='Platinum Group Elements' && value.name!=='Rare Earth Elements' && value.name!=='Ground Water');//Platinum Group Elements

        this._loadingMineralTypes = false;
      });
  }

  getProjectCategoriesByRoleId(projectRoleId: string) {
    this._loadingProjectTypes = true;
    this.projectCategoryTypes = [];
    this.projectService.getProjectCategoriesByRoleId(projectRoleId)
      .subscribe(data => {
        this.projectCategories = data as [];
        this.projectCategories = this.projectCategories.filter(value => value.id!=='e498ae2c-f787-4d98-a768-1e6e0f93d849')
        this._loadingProjectTypes = false;
      });
  }

  preSelectUserRole() {
    this.userRoles.forEach((role :string) => {

      if (role.toLowerCase() === 'Hydrogeologist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.Hydrogeology)[0];
        this.updateSelectedType();
      }
      if (role.toLowerCase() === 'Engineering Geologist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.EngineeringGeology)[0];
        this.updateSelectedType();
      }

      if (role.toLowerCase() === 'Geologist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.Geology)[0];
        this.updateSelectedType();
      }
      if (role.toLowerCase() === 'Economic Geologist'.toLowerCase()) {

        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.EconomicGeology)[0];
        this.updateSelectedType();
      }

      if (role.toLowerCase() === 'Senior Economic Geologist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.SeniorEconomicGeology)[0];
        this.updateSelectedType();
      }

      if (role.toLowerCase() === 'Geochemist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.Geochemist)[0];
        this.updateSelectedType();
      }

      if (role.toLowerCase() === 'Senior Geochemist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.SeniorGeochemist)[0];
        this.updateSelectedType();
      }
      if (role.toLowerCase() === 'Mineral Economist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.MineralEconomist)[0];
        this.updateSelectedType();
      }

      if (role.toLowerCase() === 'Senior Mineral Economist'.toLowerCase()) {
        this.selectedProjectType = this.projectTypes.filter(x => x.name === this.projectTypeEnum.SeniorMineralEconomist)[0];
        this.updateSelectedType();
      }
    });
  }

  updateSelectedType() {
    if (!this.data.project) {
      this.projectForm.controls['roleType'].setValue(this.selectedProjectType);
      return;
    }
  }

  getProjectRoles() {
    this._loadingProjectTypeRoles = true;
    this.projectService.getAllProjectRoles()
      .subscribe(projectRoles => {
        this.projectTypes = projectRoles as ProjectType[];
        this._loadingProjectTypeRoles = false;
        this.preSelectUserRole();
      });
  }

  getProjectTypesByRole(projectRole: any) {
    // -- In case pre-selected role changed --
    this.selectedProjectType = projectRole;
    this.toggleDisplay();

    if (this.selectedProjectType.name === this.projectTypeEnum.EconomicGeology ||
        this.selectedProjectType.name === this.projectTypeEnum.SeniorEconomicGeology ||
        this.selectedProjectType.name === this.projectTypeEnum.Geochemist ||
        this.selectedProjectType.name === this.projectTypeEnum.SeniorGeochemist ||
        this.selectedProjectType.name === this.projectTypeEnum.MineralEconomist ||
        this.selectedProjectType.name === this.projectTypeEnum.SeniorMineralEconomist) {
      this.getAllMineralTypes();
      this.selectedProjectCategoryTypes = [];
      this.projectCategories = [];
      this.projectCategoryTypes = [];
      this.commodities = [];

    } else {
      this.mineralTypes = [];
      this.selectedCommodities = [];
      this.commodities = [];
      this.projectCategoryTypes = [];
      this.getProjectCategoriesByRoleId(this.selectedProjectType.id);
    }
  }

  toggleDisplay() {
    if ((this.selectedProjectType.name === this.projectTypeEnum.EngineeringGeology) ||
      ((this.selectedProjectType.name === this.projectTypeEnum.Hydrogeology)) ||
      (this.selectedProjectType.name === this.projectTypeEnum.Geology)) {

      this._showMineralType = false;
      this._showProjectType = true;

    } else if (this.selectedProjectType.name === this.projectTypeEnum.EconomicGeology
        || this.selectedProjectType.name === this.projectTypeEnum.Geochemist
        || this.selectedProjectType.name === this.projectTypeEnum.SeniorEconomicGeology
        || this.selectedProjectType.name === this.projectTypeEnum.SeniorGeochemist
    ) {
      this._showMineralType = true;
      this._showProjectType = false;
    } else if (this.selectedProjectType.name === this.projectTypeEnum.MineralEconomist
        || this.selectedProjectType.name === this.projectTypeEnum.SeniorMineralEconomist
    ) {
      this._showMineralType = true;
      this._showProjectType = false;

      if (this.data.project) {
        if(this.selectedProjectCategoryTypes.length === 0) {
          if(this.selectedCommodities.length === 0) {
            this.selectOther = true;
            this.selectedProjectCategoryTypes = [];
            this.selectedCommodities = [];
            this._showMineralType = false;
            this.projectForm.get('selectOthers').setValue(this.selectOther);
          }
        }
      }
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  getCommoditiesByMineralTypeId(mineralType: MineralType) {
    this._loadingCommodities = true;

    if(mineralType.id==='5' || mineralType.id==='7ed0de13-207e-4e56-a184-da5bd77b1bcb'){//
          this.projectService.getCommoditiesByMineralTypeId(mineralType.id)
          .subscribe(data => {
            this.commodities = data as Commodity[];
            this._loadingCommodities = false;
            this.projectService.getCommoditiesByMineralTypeId(this.clayMineral.id)//clayMinerals
                .subscribe(data => {
                  let commodities1 = data as Commodity[];
                  this.commodities = [...this.commodities,...commodities1];
                  this.commodities = this.commodities.filter(value => value.id!=='ab1c9ba3-375e-4239-8d49-d12b6abf84ca');
                  this.commodities = this.commodities.filter(value => value.id!=='f9856602-7da8-458f-afec-086f1576c6a5'  );//remove Asbetos
                  this.commodities = this.commodities.filter(value => value.id!=='0fe61d3c-3a4f-4f93-be01-a9cc4bd2622f'  );//remove Granite
                  this._loadingCommodities = false;
                });
          });
    }
      else{
      this.projectService.getCommoditiesByMineralTypeId(mineralType.id)
          .subscribe(data => {
            this.commodities = data as Commodity[];
            if(mineralType.name.toLowerCase() === 'Energy'.toLowerCase()){
              this.commodities = this.commodities.filter(value => value.id!=='e498ae2c-f787-4d98-a768-1e6e0f93d849')
            }
            if(mineralType.name.toLowerCase() === 'Semi-Precious Stones'.toLowerCase()){
              this.commodities = this.commodities.filter(value => value.id!=='6b293302-7656-4d80-ba25-c7e71f86c200' &&  value.id!=='2d863c9d-df70-4d27-b4a2-651ccd35ff9a');
            }
            if(mineralType.name.toLowerCase() === 'Precious Stones'.toLowerCase()){
              this.commodities = this.commodities.filter(value => value.id!=='181f8ecf-4984-4cc7-88d0-c368f7bce9c9' );//remove silver
            }
            this._loadingCommodities = false;
          });
    }

  }

  onSubmit() {
    const formData = this.projectForm.value;

    const status = {id: '1', status: 'Open', description: ''};
    const objective: Notes = {id: '', description: formData.objective};
    const project = new Project();
    project.commodities = this.selectedCommodities;
    project.company = formData.company;
    project.currentStatus = status;
    project.status = [status];
    project.hasDrillingRequest = this.hasDrillingRequest;

    project.name = formData.name;
    project.objective = objective;
    project.startDate = formData.startDate;
    project.endDate = formData.endDate;
    project.projectCategoryTypes = this.selectedProjectCategoryTypes;
    project.projectType = formData.roleType;

    if (this.projectValidation.projectDatesvalid(project.startDate, project.endDate)) {
      if (this.data.project) {
        this.project.company = formData.company;
        this.project.startDate = formData.startDate;
        this.project.endDate = formData.endDate;
        this.project.name = formData.name;
        this.project.projectCategoryTypes = this.selectedProjectCategoryTypes;
        this.project.commodities = this.selectedCommodities;
        this.project.objective = (formData.objective === '') ? null : objective;
        this.project.projectType = formData.roleType;
        this.project.hasDrillingRequest = this.hasDrillingRequest;


        this.projectService.createProject(this.project).subscribe(response => {
            this.toastr.success('Project updated successfully', 'Project Update');
            this.onClose();
          },
          error => {
            this.spinner.hide();
            this.toastr.error(error, 'Project Update');
          },
          () => {
            this.spinner.hide();
            this.onClose();
          });

      } else {
        this.spinner.show();
        this.projectService.createProject(project).subscribe(response => {
            this.toastr.success('Project created successfully', 'Project Create');
            this.onClose();
          },
          error => {
            this.spinner.hide();
            this.toastr.error(error, 'Project Create');
            this.onClose();
          },
          () => {
            this.spinner.hide();
          });
      }
    }
  }

  getProjectCategoryTypesByCategoryId(projectType: any) {
    this._loadingProjectCategories = true;
    this.projectService.getProjectCategoryTypesByProjectCategory(projectType.id)
      .subscribe(data => {
        this.projectCategoryTypes = data as ProjectCategoryType[];
        this._loadingProjectCategories = false;
      });
  }

  addToSelectedCommodities(commodity: Commodity) {
    // -- check for duplicates --
    if (this.selectedCommodities.indexOf(commodity) < 0) {
      this.selectedCommodities.push(commodity);
    }
  }

  removeCommodity(commodity: Commodity) {
    const index = this.selectedCommodities.indexOf(commodity);
    if (index >= 0) {
      this.selectedCommodities.splice(index, 1);
    }
  }

  addToSelectedProjectCategories(projectCategory: ProjectCategoryType) {
    if (this.selectedProjectCategoryTypes.indexOf(projectCategory) < 0) {
      this.selectedProjectCategoryTypes.push(projectCategory);
    }
  }

  removeProjectCategory(projectCategory: ProjectCategoryType) {
    const index = this.selectedProjectCategoryTypes.indexOf(projectCategory);
    if (index >= 0) {
      this.selectedProjectCategoryTypes.splice(index, 1);
    }
  }

  onReset() {
    this.projectForm.reset();
  }

  toggleSelectOthers() {
    let newSelector = !this.selectOther;
    this.selectOther = !this.selectOther;
    if (newSelector === true){
      this.selectedCommodities = [];
      this.selectedProjectCategoryTypes = [];
      this._showMineralType = false;
      this.projectForm.get('selectOthers').setValue(newSelector);
    }else {
      this._showMineralType = true;
      this.projectForm.get('selectOthers').setValue(newSelector);
    }
  }
}
