import { GlobalDashboardService } from './../global-dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from './../../_auth/_providers/local-authorities';
import { StorageConstants } from './../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ApprovalState } from './../../applied-geoscience/models/approval-state';
import { UserService } from './../../auth/user/user.service';
import { UserDashboardService } from './../user-dashboard/user-dashboard.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Person } from '../../common/entity/security/profile/person';
import { State } from '../../activities/models/state';

@Component({
  selector: 'app-mineral-resource-dashboard',
  templateUrl: './mineral-resource-dashboard.component.html',
  styleUrls: ['./mineral-resource-dashboard.component.scss']
})
export class MineralResourceDashboardComponent implements OnInit {

  numberOfMyProjects: number = 0;
  stillFetchingMyProjectCount = true;

  numberOfBGIProjects: number = 0;
  stillFetchingBGIProjectCount = true;

  numberOfActivities: number = 0;
  stillFetchingMyActivitiesCount = true;

  numberOfActivitiesSubmittedToQA: number = 0;
  numberOfActivitiesSubmittedToManager: number = 0;
  numberOfActivitiesRejectedByQA: number = 0;
  numberOfActivitiesRejectedByManager: number = 0;
  numberOfActivitiesApproved: number = 0;
  numberOfActivitiesRejected: number = 0;
  numberOfDrafts: number = 0;

  numberOfPendingApproval: number = 0;
  numberOfRejectedByQA: number = 0;
  numberOfSubmittedToManager: number = 0;

  numberOfPendingApprovalManager: number = 0;
  numberOfReturnedManager: number = 0;
  numberOfApprovedManager: number = 0;

  //Tech
  numberOfTechPendingApproval: number = 0;
  numberOfTechRejectedByGeologist: number = 0;
  numberOfTechSubmittedToManager: number = 0;
  numberOfTechActivities: number = 0;
  numberOfTechDrafts: number = 0;
  numberOfTechActivitiesSubmittedToGeologist: number = 0;
  numberOfTechActivitiesSubmittedToManager: number = 0;
  numberOfTechActivitiesRejectedByManager: number = 0;
  numberOfTechActivitiesRejectedByGeologist: number = 0;
  numberOfTechActivitiesApproved: number = 0;

  userName: string;
  userReference: string;
  person: Person;

  approvalEnum: typeof ApprovalState = ApprovalState;
  state = new State();

  currentPerson: any;
  currentUser: any;

  dashboardCounts = {
    DRILLING_REQUESTS: 0,
    SUBMITTED_TO_SUPERINTENDENT: 0,
    RETURNED: 0,
    APPROVED: 0,
    ALL_VETTED: 0,
    RETURNED_SUPERINTENDENT: 0
  };

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private userService: UserService,
    private _localStorage: LocalStorage,
    private _localAuthorities: LocalAuthorities,
    private _toastr: ToastrService,
    private managerDashBoardService: GlobalDashboardService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
      }, err => {
          this._toastr.warning(err.message, "Current User Error");
      },() => {

      });
  }

  loadGeologistActivities() {
    this.getMyProjectsCount();
    this.getBGIProjectsCount();
    this.getUserActivities();
    this.getUserDrafts();
    this.getSubmittedToQA();
    this.getSubmittedToManager();
    this.getActivitiesRejectedByQA();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
  }

  loadQAActivities() {
    this.getMyProjectsCount();
    this.getBGIProjectsCount();
    this.getPendingQAApproval();
    this.getReturnedFromQA();
    this.getSubmittedToManagerByQA();
  }

  loadManagerActivities() {
    this.getMyProjectsCount();
    this.getBGIProjectsCount();
    this.getPendingManagerApproval();
    this.getReturnedFromManager();
    this.getPublished();
  }

  loadGeologistVettingActivities(){
    this.getPendingGeologistApproval();
    this.getReturnedFromGeologist();
    this.getSubmittedToManagerByGeologist();
  }

  loadGeologistTechActivities(){
    this.getMyProjectsCount();
    this.getBGIProjectsCount();
    this.getUserActivities();
    this.getTechDrafts();
    this.getSubmittedToGeologist();
    this.getSubmittedToManagerByGeoForTech();
    this.getActivitiesRejectedByGeologist();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
  }

  private initPerson(userId: string) {
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
        if (res) {
            this.currentPerson = res;
            this.currentPerson['user'] = this.currentUser;
            this._localStorage.setItem(StorageConstants.personKey, this.currentPerson).subscribe(() => {});

            this.currentPerson.user.roles.forEach(role => {
              if (role.name === 'Manager'){
                this.loadManagerActivities();
              }

              if (role.name === 'Economic Geologist' || 'Senior Economic Geologist' || 'Senior Geochemist' || 'Geochemist') {
                this.loadGeologistActivities();
                this.loadGeologistVettingActivities();
              }

              if (role.name === 'Quality Assurance officer') {
                this.loadQAActivities();
              }

              if(role.name === 'Technician Mineral Resources'){
                this.loadGeologistTechActivities();
              }
            });

            this.managerDashBoardService.managerDrillRequestCountsByUnit(this.currentPerson.user.businessUnit.id).subscribe(res => {
              let result: Array<{ title: string, count: number }> = res as Array<{ title: string, count: number }>;
              result.forEach(r => { this.dashboardCounts[r.title] = r.count;
              });
            });
        }
    }, err => {
        this._toastr.error(err.message, "Profile Details");
    },() => {
    });
  }

  gotoProject(uri: string) {
    let urlParams: any;
    if('my_project' === uri){
      urlParams = { id: 'my_project' };
    }
    if('bgi_project' === uri){
      urlParams = { id: 'bgi_project' };
    }
    this._router.navigate([`portal/applied-geoscience/project/list`,urlParams]);
  }

  goTo(type: string, filter: string) {
    this._router.navigate([`portal/activities/mineral-resources/${type}/${filter}`]);
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
      this.stillFetchingBGIProjectCount = false;
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.numberOfMyProjects = number;
      this.stillFetchingMyProjectCount = false;
    });
  }

  getUserActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount('all').subscribe(number => {
      this.numberOfActivities = number;
      this.numberOfTechActivities = number;
      this.stillFetchingMyActivitiesCount = false;
    });
  }

  getUserDrafts(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(number => {
      this.numberOfDrafts = number;
    });
  }

  getSubmittedToQA(){
      this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
        this.numberOfActivitiesSubmittedToQA = number;
      });
  }

  getTechDrafts(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PENDING_GEOLOGIST_SUBMISSION).subscribe(number => {
      this.numberOfTechDrafts = number;
    });
  }

  getSubmittedToGeologist(){
      this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_GEOLOGIST).subscribe(number => {
        this.numberOfTechActivitiesSubmittedToGeologist = number;
      });
  }

  getSubmittedToManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.numberOfActivitiesSubmittedToManager = number;
    });
  }

  getSubmittedToManagerByGeoForTech(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.SUBMITTED_TO_MANAGER_BY_GEOLOGIST).subscribe(number => {
      this.numberOfTechActivitiesSubmittedToManager = number
    });
  }

  getActivitiesRejectedByQA(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.numberOfActivitiesRejectedByQA = number;
    });
  }

  getActivitiesRejectedByGeologist(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_GEOLOGIST).subscribe(number => {
      this.numberOfTechActivitiesRejectedByGeologist = number;
    });
  }

  getActivitiesRejectedByManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.numberOfActivitiesRejectedByManager = number;
      this.numberOfTechActivitiesRejectedByManager = number;
    });
  }

  getApprovedActivities(){
    this.userDashBoardService.getMyActivitiesMineralResourcesCount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.numberOfActivitiesApproved = number;
      this.numberOfTechActivitiesApproved = number;
    });
  }

  //QA Activities
  getPendingQAApproval(){
    this.userDashBoardService.getMyActivitiesMineralResourcesQACount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
      this.numberOfPendingApproval = number;
    });
  }

  getReturnedFromQA(){
    this.userDashBoardService.getMyActivitiesMineralResourcesQACount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.numberOfRejectedByQA = number;
    });
  }

  getSubmittedToManagerByQA(){
    this.userDashBoardService.getMyActivitiesMineralResourcesQACount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.numberOfSubmittedToManager = number;
    });
  }

  //Geologist Vetting Activities
  getPendingGeologistApproval(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeoCount(this.approvalEnum.SUBMITTED_TO_GEOLOGIST).subscribe(number => {
      this.numberOfTechPendingApproval = number;
    });
  }

  getReturnedFromGeologist(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeoCount(this.approvalEnum.REJECTED_BY_GEOLOGIST).subscribe(number => {
      this.numberOfTechRejectedByGeologist = number;
    });
  }

  getSubmittedToManagerByGeologist(){
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeoCount(this.approvalEnum.SUBMITTED_TO_MANAGER_BY_GEOLOGIST).subscribe(number => {
      this.numberOfTechSubmittedToManager = number;
    });
  }

  //Manager Activities
  getPendingManagerApproval(){
    this.userDashBoardService.getMyActivitiesMineralResourcesManagerCount('Submitted').subscribe(number => {
      this.numberOfPendingApprovalManager = number;
    });
  }

  getReturnedFromManager(){
    this.userDashBoardService.getMyActivitiesMineralResourcesManagerCount('Returned').subscribe(number => {
      this.numberOfReturnedManager = number;
    });
  }

  getPublished(){
    this.userDashBoardService.getMyActivitiesMineralResourcesManagerCount('Approved').subscribe(number => {
      this.numberOfApprovedManager = number;
    });
  }

  goToDrillingRequests(requestState: string) {
    this._router.navigate([`portal/activities/drilling_requests/${requestState}`]);
  }

}
