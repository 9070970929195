import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

import { ProjectRole } from './../../../../../shared-kernel/entity/applied-geoscience/project/project-role';
import { ProjectService } from '../../../project.service';
import { PeopleData } from './../../../../../_core/data/people';

@Component({
    selector: 'update-member-role',
    templateUrl: './update-role.component.html',
    styleUrls: ['./update-role.component.scss']
})
export class UpdateMemberRoleComponent implements OnInit {
    roles: Array<ProjectRole>;
    person: any;
    selectedMember: any;

    constructor(
        public dialogRef: MatDialogRef<UpdateMemberRoleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _toastr: ToastrService,
        private _projectSrv: ProjectService,
        private _peopleServ: PeopleData
    ) {
        this.selectedMember = data;
    }

    ngOnInit() {
        this._getRoles();
    }

    onUpdateRole() {
        if (this.selectedMember.projectRoles.length <= 0) {
            this._toastr.error("Select at leact one role", "Cannot send empty");
            return;
        }

        this._projectSrv.updateMemberRoles(this.selectedMember.project, this.selectedMember.id, { roles: this.selectedMember.projectRoles }).subscribe(res => {
            this._toastr.success("Project role added", "Role Added");
            this.closeDialog();
        }, err => {
            this._toastr.error(err.message, "Failed to update Project Roles");
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    compareFn(object1: any, object2: any): boolean {
        return object1 && object2 ? object1.id === object2.id : (object1 === object2);
    }

    private _getRoles() {
        this._projectSrv.getProjectRoles().subscribe(res => {
            this.roles = res as Array<ProjectRole>;
            this._getPerson();
        }, err => {
            this._toastr.error(err.message, "Failed to get Project Roles");
        });
    }

    private _getPerson() {
        this._peopleServ.getPersonById(this.data.personId).subscribe(res => {
            this.person = res;
        }, err => {
            this._toastr.error(err.message, "Failed to get Person Info");
        });
    }
}