import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/auth/user/user.service';
import { Person } from 'src/app/common/entity/security/profile/person';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyEnum } from '../../../../../shared-kernel/enumerations/company-enum';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Notes } from '../../../../../shared-kernel/entity/applied-geoscience/notes';
import { GeoTechService } from '../../../../geo-tech/geo-tech.service';
import { MatSelect } from '@angular/material/select';
import { debounceTime, finalize, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Project } from '../../../../../shared-kernel/entity/applied-geoscience/project/project';
import { WaterConstantRateTest } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterConstantRateTest';
import { CompanyService } from '../../../../company/company.service';
import { UnitEnum } from '../../../../../shared-kernel/enumerations/unit-enum';
import { WaterObservationBorehole } from '../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterObservationBorehole';
declare var $: any;
const TIMETOFILL = ['10','20','50','100','200'];
@Component({
  selector: 'app-water-pump-test-constant-rate-test-create',
  templateUrl: './water-pump-test-constant-rate-test-create.component.html',
  styleUrls: ['./water-pump-test-constant-rate-test-create.component.scss']
})
export class WaterPumpTestConstantRateTestCreateComponent implements OnInit {

  filteredBoreholeNoOptions: any;
  boreholeNumberStillLoading = true;
  boreholeNos: Notes[];

  boreholeNumber: string;
  contantRateTestCreateForm : FormGroup;
  observationBoreholeCreateForm: FormGroup;
  observe = false;
  timetofills = TIMETOFILL;
  people : Person [];
  editingMode = false;
  projectMemberStillLoading = true;
  currentGeologist: FormControl = new FormControl();
  currentGeologist1: FormControl = new FormControl();
  geologistFilter: FormControl = new FormControl();
  geologistFilter1: FormControl = new FormControl();
  filteredPeopleNoOptions: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredPeopleNoOptions1: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);


  @ViewChild('geologistSelect1') geologistSelect1: MatSelect;
  @ViewChild('geologistSelect') geologistSelect: MatSelect;
  waterConstantRateTest : WaterConstantRateTest;
  waterObservationBorehole: WaterObservationBorehole;
  protected _onDestroy = new Subject<void>();
  isLoading = false;
  filteredGeologist: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  constructor(
    private geoTechService: GeoTechService,
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WaterPumpTestConstantRateTestCreateComponent>,
    private userService: UserService,
    private companyService: CompanyService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute) {

    }

  ngOnInit() {
      if(this.data.waterConstantRateTest){
        this.editingMode = true;
        if(this.data.waterConstantRateTest.waterObservationBoreholes){
          this.observe = true;
        }
      }


      this.contantRateTestCreateForm = this.formBuilder.group({
        pumpType: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.pumpType : null, Validators.required],
        depthToWater: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.depthToWater.measure : null, Validators.required],
        startDate: [this.data.waterConstantRateTest ? new Date(this.data.waterConstantRateTest.startDate) : null, Validators.required],
        endDate: [this.data.waterConstantRateTest ? new Date(this.data.waterConstantRateTest.endDate) : null, Validators.required],

        staticWaterLevel: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.staticWaterLevel : null],
        drawdown: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.drawdown.measure : null],
        startTime: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.startTime : null],
        endTime: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.endTime : null],

        pumpRate: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.pumpingRate.measure : null],
        timeToFillLiters: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.timeToFillLiters.measure.toString() : null],
        timeToFillTime: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.timeToFillTime : null],
        tds: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.tds.measure : null],

        pH: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.ph.measure : null],
        temperature: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.temperature.measure : null],
        conductivity: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.electricalConductivity.measure : null],

        geologist: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.geologist : null],
        comments: [this.data.waterConstantRateTest ? this.data.waterConstantRateTest.comments.description : null],

        enableObserve: ''
      });

    const waterObsBoreholes = this.data.waterConstantRateTest ? this.data.waterConstantRateTest.waterObservationBoreholes ?
      this.data.waterConstantRateTest.waterObservationBoreholes[0]: null:  null;
    this.observe = waterObsBoreholes ? true : false;

    this.observationBoreholeCreateForm = this.formBuilder.group({
      boreholeNumber: [waterObsBoreholes ? waterObsBoreholes.boreholeNumber :null, Validators.required],
      pumpType: [waterObsBoreholes ?waterObsBoreholes.pumpType : null, Validators.required],
      depthToWater: [waterObsBoreholes ?waterObsBoreholes.depthToWater.measure: null, Validators.required],
      startDate: [waterObsBoreholes ?waterObsBoreholes.startDate ? new Date(waterObsBoreholes.startDate) : null: null, Validators.required],
      endDate: [waterObsBoreholes ?waterObsBoreholes.endDate ? new Date(waterObsBoreholes.endDate) : null: null, Validators.required],
      staticWaterLevel: [waterObsBoreholes ?waterObsBoreholes.staticWaterLevel: null],
      drawdown: [waterObsBoreholes ?waterObsBoreholes.drawdown.measure: null],
      startTime: [waterObsBoreholes ?waterObsBoreholes.startTime: null],
      endTime: [waterObsBoreholes ?waterObsBoreholes.endTime: null],
      pumpRate: [waterObsBoreholes ?waterObsBoreholes.pumpingRate.measure: null],
      timeToFillLiters: [waterObsBoreholes ?waterObsBoreholes.timeToFillLiters.measure.toString(): null],
      timeToFillTime: [waterObsBoreholes ?waterObsBoreholes.timeToFillTime: null],
      tds: [waterObsBoreholes ?waterObsBoreholes.tds.measure: null],
      pH: [waterObsBoreholes ?waterObsBoreholes.ph.measure: null],
      temperature: [waterObsBoreholes ?waterObsBoreholes.temperature.measure: null],
      conductivity: [waterObsBoreholes ?waterObsBoreholes.electricalConductivity.measure: null],
      geologist: [waterObsBoreholes ?waterObsBoreholes.geologist: null],
      comments: [waterObsBoreholes ?waterObsBoreholes.comments.description: null],
    });

    this.getPersons();
    this.getBoreholeNumbers();

  }

  compareStrings(objectOne: any, objectTwo: any): boolean {
    return objectOne && objectTwo ? objectOne === objectTwo : objectOne === objectTwo;
  }

  getBoreholeNumbers(){

    this.geoTechService.geBoreholeNumber().subscribe(value => {
      this.boreholeNumberStillLoading = false;
      this.boreholeNos = value as Notes[];
      this.filteredBoreholeNoOptions = this.observationBoreholeCreateForm.controls.boreholeNumber.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterBoreholeNo(name) : this.boreholeNos.slice(0,30))
        );
        if(this.data.waterConstantRateTest){
          if(this.data.waterConstantRateTest.waterObservationBoreholes){
            let dababaseBoreholeNo = this.boreholeNos.filter(value1 => value1.id === this.data.waterConstantRateTest.waterObservationBoreholes[0].boreholeNumber);
            this.observationBoreholeCreateForm.controls.boreholeNumber.setValue(dababaseBoreholeNo[0]);
          }
        }
    },error => {},() => {

    });
  }

  private _filterBoreholeNo(notes: string): Notes[] {
    const filterValue = notes.toLowerCase();
    return this.boreholeNos.filter(option => option.description.toLowerCase().indexOf(filterValue) === 0).slice(0,30);
  }


  ngAfterViewInit() {
    this._setInitialGeologist();
  }

  displayBoreholeNo(note: Notes): string {
    return note && note.description ? note.description : '';
  }

  protected _setInitialGeologist() {
    this.filteredGeologist.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  goTo(filter: string) {
    this.router.navigate([`portal/personnel/${filter}`]);
    this.closeModal();
  }

  closeModal(){
    this.dialogRef.close();
  }

  getPersons(){
    this.companyService.getCompanyByName(CompanyEnum.BGI_NAME).subscribe(company => {
      this.userService.getPersonsByCompanyId(company.id).subscribe(
        data => {
          this.people = data as Person[];
          this.filteredPeopleNoOptions1.next(this.people);
          this.geologistFilter1.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterCoordinators1();
          });
          this.filteredPeopleNoOptions.next(this.people);
          this.geologistFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterCoordinators();
          });
          this.projectMemberStillLoading = false;
          if(this.data.waterConstantRateTest){
            let person = this.people.filter(value => value.id === this.data.waterConstantRateTest.geologist )[0];
            this.currentGeologist1.setValue(person);
              if(this.data.waterConstantRateTest.waterObservationBoreholes){
                let person = this.people.filter(value => value.id === this.data.waterConstantRateTest.waterObservationBoreholes[0].geologist)[0];
                this.currentGeologist.setValue(person);
              }
          }

        }
      );
    });
  }

  filterCoordinators1(){
    if (!this.people) return;
    let search = this.geologistFilter1.value;
    if (!search) {
      this.filteredPeopleNoOptions1.next(this.people.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPeopleNoOptions1.next(
      this.people.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterCoordinators(){
    if (!this.people) return;
    let search = this.geologistFilter.value;
    if (!search) {
      this.filteredPeopleNoOptions.next(this.people.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPeopleNoOptions.next(
      this.people.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }




  private _filterGeologistNo(geolgist: string): Person[] {
    const filterValue = geolgist.toLowerCase();
    return this.people.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0).slice(0,30);
  }

  onSubmit(){
      this.waterConstantRateTest = new WaterConstantRateTest();
      if(this.data.waterConstantRateTest){
          this.waterConstantRateTest =  this.data.waterConstantRateTest;
      }
      this.waterConstantRateTest.borehole = this.data.url;
      this.waterConstantRateTest.pumpType = this.contantRateTestCreateForm.controls.pumpType.value;
      this.waterConstantRateTest.startDate = this.contantRateTestCreateForm.controls.startDate.value;
      this.waterConstantRateTest.endDate = this.contantRateTestCreateForm.controls.endDate.value;
      this.waterConstantRateTest.staticWaterLevel = this.contantRateTestCreateForm.controls.staticWaterLevel.value;
      this.waterConstantRateTest.depthToWater.measure = this.contantRateTestCreateForm.controls.depthToWater.value;
      this.waterConstantRateTest.depthToWater.unit.id = UnitEnum.meter;
      this.waterConstantRateTest.drawdown.unit.id  = UnitEnum.meter;
      this.waterConstantRateTest.drawdown.measure = this.contantRateTestCreateForm.controls.drawdown.value;
      this.waterConstantRateTest.pumpingRate.measure = this.contantRateTestCreateForm.controls.pumpRate.value;
      this.waterConstantRateTest.pumpingRate.unit.id = UnitEnum.pumpRate;
      this.waterConstantRateTest.timeToFillLiters.measure = this.contantRateTestCreateForm.controls.timeToFillLiters.value;
      this.waterConstantRateTest.timeToFillLiters.unit.id = UnitEnum.liter;
      this.waterConstantRateTest.timeToFillTime = this.contantRateTestCreateForm.controls.timeToFillTime.value;
      this.waterConstantRateTest.tds.measure = this.contantRateTestCreateForm.controls.tds.value;
      this.waterConstantRateTest.tds.unit.id = UnitEnum.TDS;
      this.waterConstantRateTest.ph.measure = this.contantRateTestCreateForm.controls.pH.value;
      this.waterConstantRateTest.ph.unit.id = UnitEnum.ph;
      this.waterConstantRateTest.temperature.measure = this.contantRateTestCreateForm.controls.temperature.value;
      this.waterConstantRateTest.temperature.unit.id = UnitEnum.temperature;
      this.waterConstantRateTest.electricalConductivity.measure = this.contantRateTestCreateForm.controls.conductivity.value;
      this.waterConstantRateTest.electricalConductivity.unit.id = UnitEnum.electricalConductivity;
      this.waterConstantRateTest.startTime = this.contantRateTestCreateForm.controls.startTime.value;
      this.waterConstantRateTest.endTime = this.contantRateTestCreateForm.controls.endTime.value;
      if(this.currentGeologist1.value){
        this.waterConstantRateTest.geologist = this.currentGeologist1.value.id;
      }
      this.waterConstantRateTest.comments.description = this.contantRateTestCreateForm.controls.comments.value;

      if(this.observe ) {
        this.waterObservationBorehole = new WaterObservationBorehole();
        if(this.data.waterConstantRateTest) {
          if(this.data.waterConstantRateTest.waterObservationBoreholes) {
            this.waterObservationBorehole = this.data.waterConstantRateTest.waterObservationBoreholes[0];
          }
        }
        this.waterObservationBorehole.boreholeNumber = this.observationBoreholeCreateForm.controls.boreholeNumber.value.id;
        this.waterObservationBorehole.pumpType = this.observationBoreholeCreateForm.controls.pumpType.value;
        this.waterObservationBorehole.startDate = this.observationBoreholeCreateForm.controls.startDate.value;
        this.waterObservationBorehole.endDate = this.observationBoreholeCreateForm.controls.endDate.value;
        this.waterObservationBorehole.staticWaterLevel = this.observationBoreholeCreateForm.controls.staticWaterLevel.value;
        this.waterObservationBorehole.depthToWater.measure = this.observationBoreholeCreateForm.controls.depthToWater.value;
        this.waterObservationBorehole.depthToWater.unit.id = UnitEnum.meter;
        this.waterObservationBorehole.drawdown.unit.id = UnitEnum.meter;
        this.waterObservationBorehole.drawdown.measure = this.observationBoreholeCreateForm.controls.drawdown.value;
        this.waterObservationBorehole.pumpingRate.measure = this.observationBoreholeCreateForm.controls.pumpRate.value;
        this.waterObservationBorehole.pumpingRate.unit.id = UnitEnum.pumpRate;
        this.waterObservationBorehole.timeToFillLiters.measure = this.observationBoreholeCreateForm.controls.timeToFillLiters.value;
        this.waterObservationBorehole.timeToFillLiters.unit.id = UnitEnum.liter;
        this.waterObservationBorehole.timeToFillTime = this.observationBoreholeCreateForm.controls.timeToFillTime.value;
        this.waterObservationBorehole.tds.measure = this.observationBoreholeCreateForm.controls.tds.value;
        this.waterObservationBorehole.tds.unit.id = UnitEnum.TDS;
        this.waterObservationBorehole.ph.measure = this.observationBoreholeCreateForm.controls.pH.value;
        this.waterObservationBorehole.ph.unit.id = UnitEnum.ph;
        this.waterObservationBorehole.temperature.measure = this.observationBoreholeCreateForm.controls.temperature.value;
        this.waterObservationBorehole.temperature.unit.id = UnitEnum.temperature;
        this.waterObservationBorehole.electricalConductivity.measure = this.observationBoreholeCreateForm.controls.conductivity.value;
        this.waterObservationBorehole.electricalConductivity.unit.id = UnitEnum.electricalConductivity;
        this.waterObservationBorehole.startTime = this.observationBoreholeCreateForm.controls.startTime.value;
        this.waterObservationBorehole.endTime = this.observationBoreholeCreateForm.controls.endTime.value;
        if(this.currentGeologist.value){
          this.waterObservationBorehole.geologist = this.currentGeologist.value.id;
        }
        this.waterObservationBorehole.comments.description = this.observationBoreholeCreateForm.controls.comments.value;
        this.waterConstantRateTest.waterObservationBoreholes = [];
        this.waterConstantRateTest.waterObservationBoreholes.push(this.waterObservationBorehole);

      }
      this.hydrogeologyService.createWaterConstantRateTest(this.waterConstantRateTest).subscribe(value => {
        this.toastr.success('Successful ','Constant Rate Create');
        this.dialogRef.close(value);
      },error => {
        this.toastr.error(error.message,'Create Unsuccessful')
      },() => {

      })

  }



}
