import {Size} from '../../common/size';
import {Borehole} from '../../common/borehole';

export class WaterBearingZones {
  id: string;
  waterStrike: Size = new Size();
  yield: Size = new Size();
  blowOutYield: Size = new Size();
  ph: Size = new Size();
  ec: Size = new Size();
  temp: Size = new Size();
  tds: Size = new Size();
  staticWaterLevel: Size = new Size();
  comments: string;
  borehole: Borehole = new Borehole()
  deleted: boolean = false;
}
