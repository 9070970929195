import { Link } from './link';

export class FileToUpload {
    id: string;
    name: string;
    description: string;
    fileName: string;
    uri: string;
    type: string;
    size: number;
    deleted: boolean;
    fileMapperKey: string;
    links: Link[];
}
