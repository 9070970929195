import { PersonnelCreateComponent } from './../../../../../personnel/personnel-create/personnel-create.component';
import { Component, ElementRef, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { MatAutocomplete, MatChipInputEvent,
  MatPaginator, MatSort, MatTableDataSource,
  MAT_DIALOG_DATA, MatDialogRef, MatSelect,
  MatDialog, MatDialogConfig } from '@angular/material';
import { DynamicConePenetrometerTestLog } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTestLog';
import { ProjectService } from 'src/app/applied-geoscience/project/project.service';
import { District } from 'src/app/common/entity/security/util/common/district';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import { SurfaceType } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/SurfaceType';
import { DCPTestService } from '../../dynamic-cone-penetrometer.service';
import { Size } from 'src/app/shared-kernel/entity/common/size';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { DcpTestImage } from '../../../../../shared-kernel/entity/common/DcpTestImage';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DcpTestFoundation } from './DcpTestFoundation';
import { Person } from '../../../../../_core/data/_models/people.model';
import { UserService } from '../../../../../auth/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FoundationValidation } from './foundation-validation';
import { CoordinatesType } from 'src/app/shared-kernel/entity/common/coordinatesType';
import { Coordinate } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinate';
import { CoordinatesTypesEnum } from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import LatLon from 'geodesy/latlon-nvector-spherical.js';
import { CoordinatesService } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';
import { CommonService } from 'src/app/common/common.service';
import { Village } from 'src/app/shared-kernel/entity/common/Village';
import { DynamicConePenetrometerTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTest';
import { LocationTemp } from 'src/app/shared-kernel/entity/common/locationTemp';
import { Coordinates } from 'src/app/shared-kernel/entity/common/coordinates';
import { Router } from '@angular/router';
import { PersonnelSortPipe } from '../../../../../shared-kernel/pipes/personnel.sort.pipe';
import { Company } from '../../../../../shared-kernel/entity/applied-geoscience/company/company';
import { CompanyService } from '../../../../company/company.service';
import { ReplaySubject, Subject, Observable, of } from 'rxjs';
import { ProjectMembers } from 'src/app/applied-geoscience/project/project-detail/project-members/project-member.model';
import { take, takeUntil } from 'rxjs/operators';
import { PeopleData } from 'src/app/_core/data/people';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';

declare var $: any;

@Component({
  selector: 'app-dcp-test-foundation-create',
  templateUrl: './dcp-test-foundation-create.component.html',
  styleUrls: ['./dcp-test-foundation-create.component.scss']
})

export class DcpTestFoundationCreateComponent implements OnInit {

  isEdit: Boolean = false;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  @Input() dataSourceDcpTestFoundations: MatTableDataSource<DcpTestFoundation> = new MatTableDataSource();
  @Input() paginator: MatPaginator;
  @Input() sort: MatSort;
  @Input() dcpTestFoundations;
  @Input() dcpTestFoundation: DcpTestFoundation;

  projectCoordinators: Person[];
  projectManagers: Person[];
  projectGeologists: Person[];
  dynamicConePenetrometerTestLog: DynamicConePenetrometerTestLog;
  dynamicConePenetrometerTestLogs: DynamicConePenetrometerTestLog[];
  dcpTestLocationImages: Array<DcpTestImage> = [];
  surfaceTypes: SurfaceType[];
  dcptestLocationImage;
  district: District;
  districts: District[];
  project: Project;
  projects: Project[];
  villages: Village[];
  filteredVillages: Observable<Village[]> = of([]);
  filteredDistricts: Observable<District[]> = of([]);

  panelOpenState: boolean = false;
  penetration: Size;
  penetrationRate: Size;
  startDepth: Size;
  finishDepth: Size;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  dcpTestLocationImageCtrl = new FormControl();
  loading = true;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  validation_messages = this.foundationValidation.validation_messages;

  coordinatesTypes: CoordinatesType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];
  currentUser: any;

  dmsSx: number; // decimal minutes seconds, to hold x/longitude seconds value
  dmsMx: number; // decimal minutes seconds, to hold x/longitude minutes value
  dmsDx: number; // decimal minutes seconds, to hold x/longitude decimal value

  dmsSy: number; // decimal minutes seconds, to hold y/latitude seconds value
  dmsMy: number; // decimal minutes seconds, to hold y/latitude minutes value
  dmsDy: number; // decimal minutes seconds, to hold y/latitude decimal value

  zone34SNorthing: number;
  zone34SEasting: number;

  zone35SNorthing: number;
  zone35SEasting: number;

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  title: string;
  allCompanies: Company [];

  @ViewChild('testNumberModel') testNumberModel: NgModel;

  projectMembers: Array<ProjectMembers> = [];
  personMembers: Array<Person> = [];

  // filteredManagers: Array<Person> = [];
  filteredManagers: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredCoordinators: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredGeologists: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  currentManager: FormControl = new FormControl();
  managerFilter: FormControl = new FormControl();

  currentCoordinator: FormControl = new FormControl();
  coordinatorFilter: FormControl = new FormControl();

  currentGeologist: FormControl = new FormControl();
  geologistFilter: FormControl = new FormControl();

  @ViewChild('managerSelect') managerSelect: MatSelect;
  @ViewChild('coordinatorSelect') coordinatorSelect: MatSelect;
  @ViewChild('geologistSelect') geologistSelect: MatSelect;
  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,private personnelSortPipe: PersonnelSortPipe,
    private projectService: ProjectService,private  companyService: CompanyService,
    private dcpTestService: DCPTestService,
    private toastr: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService, private foundationValidation: FoundationValidation,
    private coordinatesService: CoordinatesService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DcpTestFoundationCreateComponent>,
    private peopleSrv: PeopleData,
    private dialog: MatDialog,
    private _localStorage: LocalStorage) {
  }

  ngOnInit() {
    this.getBotswanaGeofence();
    this.getAllDistricts();
    this.getAllVillages();
    this.getCompanies();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.getProjectsByMember(this.currentUser.username);
    }, err => {
      this.toastr.warning(err.message, "Current User Error");
    },() => {

    });
    this.dcpTestFoundation = this.createDcpTestFoundationObject();
    this.dynamicConePenetrometerTestLog = new DynamicConePenetrometerTestLog();
    this.penetration = new Size();
    this.penetrationRate = new Size();
    this.startDepth = new Size();
    this.finishDepth = new Size();
    this.dynamicConePenetrometerTestLogs = [];

    this.dcpTestFoundation.coordinatesFormat = new CoordinatesFormat();
    this.dcpTestFoundation.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.dcpTestFoundation.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;

    if (this.data) {
      this.title = 'Edit DCP Foundation Test';
      this.dcpTestFoundation = this.data;
      this.dcpTestLocationImages = this.dcpTestFoundation.testImageList;
      this.dcpTestFoundation.dynamicConePenetrometerTest.date = new Date(this.dcpTestFoundation.dynamicConePenetrometerTest.date);
    } else {
      this.title = 'Create DCP Foundation Test';
    }

    this._getProjectMembers();
  }

  ngAfterViewInit() {
    this._setInitialManager();
    this._setInitialCoordinator();
    this._setInitialGeologist();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCompanies(){
    this.companyService.getAllCompanies().subscribe(value => {
      this.allCompanies = value as Company [];
    },error => {},() => {this.getAllPersons()})
  }

  closeDialog() {
    this.dialogRef.close(this.dcpTestFoundation);
  }

  goTo(filter: string) {
    this._router.navigate([`portal/personnel/${filter}`]);
    this.closeDialog();
  }

  newPersonnel() {
    const newPersonnelDialogRef = this.dialog.open(PersonnelCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true
    });

    newPersonnelDialogRef.afterClosed().subscribe((result) => {
      this._getProjectMembers();
    });
  }

  onFocusOut() {
    let testNum = this.dcpTestFoundation.dynamicConePenetrometerTest.testNumber;
    if (testNum && testNum.trim() !== '') {
      if (this.dcpTestFoundation.dynamicConePenetrometerTest.project && this.dcpTestFoundation.dynamicConePenetrometerTest.project.id) {
        this.dcpTestService.dcpFoundationInProject(testNum, this.dcpTestFoundation.dynamicConePenetrometerTest.project.id).subscribe(res => {
          if (res.exist === true) {
            this.testNumberModel.control.setErrors({ 'exist': true });
          } else {
            this.testNumberModel.control.setErrors(null);
          }
        });
      }
    }
  }

  createDcpTestFoundationObject(): DcpTestFoundation {
    this.dcpTestFoundation = new DcpTestFoundation();
    this.dcpTestFoundation.dynamicConePenetrometerTest = new DynamicConePenetrometerTest();
    this.dcpTestFoundation.dynamicConePenetrometerTest.project = new Project();
    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp = new LocationTemp();
    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates = new Coordinates();
    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.village = new Village();
    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.village.district = new District();
    this.dcpTestFoundation.coordinatesFormat = new CoordinatesFormat();
    this.dcpTestFoundation.coordinatesFormat = new CoordinatesFormat();
    this.dcpTestFoundation.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.dcpTestFoundation.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    return this.dcpTestFoundation;
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });

  }

  getAllDCPTestFoundations() {
    this.dcpTestService.getAllDCPTestFoundation()
      .subscribe(data => {
        this.dcpTestFoundations = data as DcpTestFoundation[];
        this.dcpTestFoundations.sort((a, b) => (a.dynamicConePenetrometerTest.project.name || '').toString().localeCompare((b.dynamicConePenetrometerTest.project.name || '').toString())
          || (a.dynamicConePenetrometerTest.testNumber || '').toString().localeCompare((b.dynamicConePenetrometerTest.testNumber  || '').toString()));
        this.dataSourceDcpTestFoundations.data = this.dcpTestFoundations;
        this.loading = false;
      }, error => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesType = selectedData.text;
    this.dcpTestFoundation.coordinatesFormat.type = this.selectedCoordinatesType;
    if (this.isEdit) {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.dcpTestFoundation.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.dcpTestFoundation.coordinatesFormat.format = this.selectedCoordinatesFormat;
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
      }

    } else {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.dcpTestFoundation.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.dcpTestFoundation.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
    }

  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesFormat = selectedData.text;
    this.dcpTestFoundation.coordinatesFormat.format = this.selectedCoordinatesFormat;


    if (this.isEdit) {
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
        this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
        this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
          this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
        this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonSValue);
        this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonMValue);
        this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonDValue);

        this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latSValue);
        this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latMValue);
        this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
          parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latDVale);
      }

    } else {

    }
  }

  getProjectById(projectId) {
    this.projectService.getProjectById(projectId).subscribe(value => {
      this.dcpTestFoundation.dynamicConePenetrometerTest.project = value as Project;
      this.executeSubmission();
    });
  }

  onSubmit() {
    this.getProjectById(this.dcpTestFoundation.dynamicConePenetrometerTest.project.id);

  }

  executeSubmission(){
    this.dcpTestFoundation.dynamicConePenetrometerTest.manager = this.currentManager && this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
    this.dcpTestFoundation.dynamicConePenetrometerTest.coordinator = this.currentCoordinator && this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
    this.dcpTestFoundation.dynamicConePenetrometerTest.geologist = this.currentGeologist && this.currentGeologist.value && this.currentGeologist.value.id ? this.currentGeologist.value.id : '';


    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }


    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;

    if (new LatLon(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
      this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
      this.spinner.show();
      this.dcpTestFoundation.testImageList = this.dcpTestLocationImages;

      this.dcpTestService.createDynamicConePenetroMeterFoundationTest(this.dcpTestFoundation).subscribe(
        response => {
          if (!this.isEdit) {
            this.successmsg('Create Dynamic Cone Penetrometer Test',
              'You have Successfully created a New Dynamic Cone Penetrometer Test (Foundation)');
          } else {
            this.successmsg('Create Dynamic Cone Penetrometer Test',
              'You have Successfully Edited Dynamic Cone Penetrometer Test (Foundation)');
          }
        },
        error => {
          this.errorsmsg('Error', error.error.message);
          this.spinner.hide();
        },
        () => {
          this.getAllDCPTestFoundations();
          this.resetPaginator();
          this.spinner.hide();
          this.resetModal();
          // this.dcpTestFoundation = this.createDcpTestFoundationObject();
        });
      // $('#dcpTestCreate').modal('hide');
    } else {
      this.toastr.error(this.coordinatesService.outSide);
    }
  }

  resetPaginator() {
    this.dataSourceDcpTestFoundations.sort = this.sort;
    this.dataSourceDcpTestFoundations.paginator = this.paginator;
    this.dataSourceDcpTestFoundations.data = this.dcpTestFoundations;
  }

  resetModal() {
    this.closeDialog();
    this.dcpTestFoundation = this.createDcpTestFoundationObject()
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo ? variableOne.id === variableTwo.id : variableOne === variableTwo;
  }

  getAllPersons() {
    this.userService.getPersons()
      .subscribe(data => {
        this.projectCoordinators = this.personnelSortPipe.transform(this.allCompanies,data as Person[]);
        this.projectManagers = this.personnelSortPipe.transform(this.allCompanies,data as Person[]);
        this.projectGeologists = this.personnelSortPipe.transform(this.allCompanies,data as Person[]);
      });
  }

  editDetails(editDcpTest: DcpTestFoundation, isEdit) {
    this.isEdit = isEdit;
    editDcpTest.dynamicConePenetrometerTest.date = new Date(editDcpTest.dynamicConePenetrometerTest.date);
    this.dcpTestLocationImages = editDcpTest.testImageList;
    this.dcpTestFoundation = editDcpTest;

    this.dcpTestFoundation.coordinatesFormat.type =  this.dcpTestFoundation.coordinatesFormat.type;
    this.selectedCoordinatesType = this.dcpTestFoundation.coordinatesFormat.type;
    this.dcpTestFoundation.coordinatesFormat.format = this.dcpTestFoundation.coordinatesFormat.format;
    this.selectedCoordinatesFormat = this.dcpTestFoundation.coordinatesFormat.format;

    if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
      this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
      this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
    }
    if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
      this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lat;
      this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude,
        this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude)._lon;
    }
    if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
      this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonSValue);
      this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonMValue);
      this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._lonDValue);

      this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latSValue);
      this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latMValue);
      this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.latitude),
        parseFloat(this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.coordinates.longitude))._latDVale);
    }
  }

  getAllDistricts() {
    this.commonService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      });
  }


  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      });
  }

  onDistrictChange(districtId) {
    this.commonService.getVillagesByDistrictId(districtId)
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      });
  }

  districtChanged($event) {
    this.filteredDistricts = of(this._filterDistricts($event));
  }

  villageChanged($event) {
    this.filteredVillages = of(this._filterVillages($event));
  }

  villageFocusAcquired() {
    this.filteredVillages = of(this.villages);
  }

  districtFocusAcquired() {
    this.filteredDistricts = of(this.districts);
  }

  private _filterDistricts(value: string): District[] { 
    
    const filterValue = value.toLowerCase();
    return this.districts ? this.districts.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  private _filterVillages(value: string): Village[] {
    const filterValue = value.toLowerCase();
    return this.villages ? this.villages.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  onVillageChange(village: Village) {
    this.dcpTestFoundation.dynamicConePenetrometerTest.locationTemp.village = village;
  }

  getAllProjects() {
    this.projectService.getAllProjects()
      .subscribe(data => {
        this.projects = data as Project[];
      });
  }
  getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user, '').subscribe(res => {
      this.projects = res as Project[];
    }, err => {
    });
  }

  getAllSurfaceTypes() {
    this.dcpTestService.getAllSurfaceType()
      .subscribe(data => {
        this.surfaceTypes = data as SurfaceType[];
      });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.dcptestLocationImage = new DcpTestImage();
          this.dcptestLocationImage.image = fileSplitdata[1];
          this.dcptestLocationImage.name = fileName;
          this.dcptestLocationImage.type = fileSplitdata[0];
          this.dcpTestLocationImages.push(this.dcptestLocationImage);
          this.dcpTestLocationImageCtrl.setValue(null);
        };
      }
    }
  }

  remove(images: Array<DcpTestImage>, image: DcpTestImage) {
    const index = images.indexOf(image);
    if (index >= 0) {
      images.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const dcpTestLocationImage = new DcpTestImage();
    dcpTestLocationImage.name = value;

    if ((value || '').trim()) {
      this.dcpTestLocationImages.push(dcpTestLocationImage);
    }

    if (input) {
      input.value = '';
    }

    this.dcpTestLocationImageCtrl.setValue(null);
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }

  /// HERE= =================

  companyChanged() {
    this._getProjectMembers();
  }

  filterManager() {
    if (!this.personMembers) return;

    let search = this.managerFilter.value;

    if (!search) {
      this.filteredManagers.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredManagers.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterCoordinators() {
    if (!this.personMembers) return;

    let search = this.coordinatorFilter.value;

    if (!search) {
      this.filteredCoordinators.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCoordinators.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterGeologist() {
    if (!this.personMembers) return;

    let search = this.geologistFilter.value;

    if (!search) {
      this.filteredGeologists.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGeologists.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  comparePerson(a: Person, b: Person) {
    return a && b && a.id === b.id;
  }

  protected _setInitialManager() {
    this.filteredManagers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.dcpTestFoundation.id && this.dcpTestFoundation.dynamicConePenetrometerTest && this.dcpTestFoundation.dynamicConePenetrometerTest.manager) {
        // Edit
        let manager = this.personMembers.find(pM => pM.id === this.dcpTestFoundation.dynamicConePenetrometerTest.manager);

        if (!manager) {
          found = false;
        } else {
          found = true;

          this.currentManager.setValue(manager);
          this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let manager = this.personMembers.find(pM => this._isOfRole(pM, 'Project Manager'));

      if (!manager) return;

      this.currentManager.setValue(manager);
      this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialCoordinator() {
    this.filteredCoordinators.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.dcpTestFoundation.id && this.dcpTestFoundation.dynamicConePenetrometerTest && this.dcpTestFoundation.dynamicConePenetrometerTest.coordinator) {
        // Edit
        let coordinator = this.personMembers.find(pM => pM.id === this.dcpTestFoundation.dynamicConePenetrometerTest.coordinator);

        if (!coordinator) {
          found = false;
        } else {
          found = true;

          this.currentCoordinator.setValue(coordinator);
          this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let coordinator = this.personMembers.find(pM => this._isOfRole(pM, 'Project Coodinator'));

      if (!coordinator) return;

      this.currentCoordinator.setValue(coordinator);
      this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialGeologist() {
    this.filteredGeologists.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.dcpTestFoundation.id && this.dcpTestFoundation.dynamicConePenetrometerTest && this.dcpTestFoundation.dynamicConePenetrometerTest.geologist) {
        // Edit
        let geologist = this.personMembers.find(pM => pM.id === this.dcpTestFoundation.dynamicConePenetrometerTest.geologist);

        if (!geologist) {
          found = false;
        } else {
          found = true;

          this.currentGeologist.setValue(geologist);
          this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let geologist = this.personMembers.find(pM => this._isOfRole(pM, 'Geologist'));

      if (!geologist) return;

      this.currentGeologist.setValue(geologist);
      this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  private _getProjectMembers() {
    if (this.dcpTestFoundation.dynamicConePenetrometerTest.project) {
      this.projectService.getProjectMembers(this.dcpTestFoundation.dynamicConePenetrometerTest.project.id).subscribe(res => {
        this.projectMembers = res as Array<ProjectMembers>;
        this._getProjectPeople(this.projectMembers);
      }, err => {
        this.toastr.error(err.message, "Project Member Fail");
      });
    }
  }

  private _getProjectPeople(members: Array<ProjectMembers>) {
    let ids = [];

    members.forEach(m => {
      ids.push(m.personId);
    });

    if (ids.length <= 0) return;

    this.peopleSrv.getPeopleByIdList(ids).subscribe(res => {
      this.personMembers = res;
      // this.filteredManagers = res;
      this.filteredManagers.next(res.slice());
      this.managerFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterManager();
      });

      this.filteredCoordinators.next(res.slice());
      this.coordinatorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCoordinators();
      });

      this.filteredGeologists.next(res.slice());
      this.geologistFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterGeologist();
      });
    }, err => {
      this.toastr.error(err.message, "Project People Fail");
    });
  }

  private _isOfRole(person: Person, role: string) {
    let member = this.projectMembers.find(pM => pM.personId === person.id && pM.deleted === false);

    if (!member) return false;

    let roleFound = false;
    member.projectRoles.forEach(r => {
      if (r.name.toLowerCase() === role.toLowerCase()) {
        roleFound = true;
      }
    });

    return roleFound;
  }
}
