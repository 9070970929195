import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApproveAction } from 'src/app/applied-geoscience/models';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import {NigisDocument} from "../../../../shared/documents/nigis-doc.model";
import {environment} from "../../../../../environments/environment";

export interface ChangeStateModel {
    action: ApproveAction,
    comment: string;
}

@Injectable({
    providedIn: 'root'
})
export class ApprovalService {
    constructor(
        private _http: HttpClient
    ) {}

  changeState(boreholeId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/approval/v1/boreholes/${boreholeId}/approve_states`, approval);
  }

  changeDcpFoundationState(dcpfoundationId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl +
      `/api/approval/v1/dcpfoundation/${dcpfoundationId}/approve_states`, approval);
  }

  chnageStateDCPSubgrade(dcpSubgradeId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/approval/v1/dcp_subgrade/${dcpSubgradeId}/approve_states`, approval);
  }

  changeStateInfiltrationTest(infiltrationTestId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/approval/v1/infiltration-test/${infiltrationTestId}/approve_states`, approval);
  }

  changeProjectState(projectId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/approval/v1/project/${projectId}/approve_states`, approval);
  }


  changeSampleState(sampleId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/approval/v1/sample/${sampleId}/approve_states`, approval);
  }

  changeTrialPitState(trialPitId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(ServiceEndPoints.appliedGeoscienceBaseUrl +
      `/api/approval/v1/trialPit/${trialPitId}/approve_states`, approval);
  }


  changeStateFiles(fileId: string, changeStateModel: ChangeStateModel): Observable<any>  {
    return  this._http.patch<any>(environment.collectionsBaseUrl + `/api/activities/v1/nigis_file/${fileId}/approve_states`, changeStateModel);
  }



  changeStateGeologicalSpecimen(specimenId: string, approval: ChangeStateModel): Observable<any> {
    return this._http.patch<any>(environment.collectionsBaseUrl + `/api/approval/v1/geological-specimen/${specimenId}/approve_states`, approval);
  }

}

