import {DcpTestImage} from '../../../../shared-kernel/entity/common/DcpTestImage';
import {DynamicConePenetrometerTest} from '../../../../shared-kernel/entity/applied-geoscience/geoTech/DynamicConePenetrometerTest';
import {Time} from '@angular/common';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';

export class DcpSubgradeTest {
  id: string;
  stationNumber: string;
  time: Date;
  testImages: Array<DcpTestImage>;
  dynamicConePenetrometerTest: DynamicConePenetrometerTest = new DynamicConePenetrometerTest();
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
  currentState: any;
  statusHistory: any[];
  creator?:    any | null | any;
  created?:    string | Date | any;
  updater?:    any | null | string | Date;
}
