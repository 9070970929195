import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../../auth/user/user.service';
import { Person } from '../../../../common/entity/security/profile/person';
import { RockName } from '../../../../shared-kernel/entity/applied-geoscience/geoTech/RockName';
import { CoreService } from '../core.service';
import { CoreRecoveryLog } from '../../../../shared-kernel/entity/applied-geoscience/geoTech/CoreRecoveryLog';
import { Size } from '../../../../shared-kernel/entity/common/size';
import { Router, UrlSegment, UrlTree, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { Borehole } from '../../../../shared-kernel/entity/common/borehole';
import { Unit } from '../../../../shared-kernel/entity/common/unit';
import { MatTableDataSource } from '@angular/material';
import { UnitEnum } from '../../../../shared-kernel/enumerations/unit-enum';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreRecoveryListComponent } from './core-recovery-list/core-recovery-list.component';
import { element } from '@angular/core/src/render3';
import { PeopleService } from '../../../../_core/mock/people.service';
import { CompanyService } from 'src/app/applied-geoscience/company/company.service';
import { Observable, of } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-core-recovery',
  templateUrl: './core-recovery.component.html',
  styleUrls: ['./core-recovery.component.scss']
})
export class CoreRecoveryComponent implements OnInit {

  @Input ('corerecoverylogs') corerecoverylogs: CoreRecoveryLog[];
  @Input ('boreholeOwner') boreholeOwner: string = '';
  @Input ('companyId') companyId: string = '';

  @Output() messageEvent = new EventEmitter<string>();

  model: any = {};

  totalCoreRecoveryMeasure: string;
  depthToMeasure: string;
  depthFromMeasure: string;

  persons: Person[];
  filteredPersons: Observable<Person[]>;
  rockQuality: string;
  borehole = new Borehole;
  measure: string;
  runNumber: string;
  UnitEnum: typeof UnitEnum = UnitEnum;
  coreRecoveryLog: CoreRecoveryLog;
  coreRecoveryLogs: CoreRecoveryLog[];

  totalCoreRecovery: Size;
  recoveryDate: Date;
  depthTo: Size;
  depthFrom: Size;
  geologist: string;
  person = new Person();
  rockNames: RockName[];
  rockName = new RockName();
  name: String;
  surname: String;
  unit: Unit;
  url: string;
  boreholeId: string;
  tabPosition = 3;

  validation_messages = {
    'date': [
      { type: 'required', message: 'Please insert date' },
    ],
    'runNumber': [
      { type: 'required', message: 'Runmber is required' },
    ],
    'geologist': [
      { type: 'required', message: 'Please select geologist first.' },
    ],
    'depthFrom': [
      { type: 'required', message: 'Depth From is required.' },
    ],
    'depthTo': [
      { type: 'required', message: 'Depth To is required.' },
    ],
    'totalCoreRecory': [
      { type: 'required', message: 'Total Core Recovery is required.' },
    ],
    'rockQualityDescription': [
      { type: 'required', message: 'Rock Quality Description is required.' },
    ],
    'lithology': [
      { type: 'required', message: 'Lithology is required' },
    ]
  };


  constructor(private companyService: CompanyService, private peopleService: PeopleService, private coreService: CoreService, private router: Router,
    private toast: ToastrService, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
    this.route.params.subscribe(
      data => {
        this.boreholeId = data.id;
      }
    );

  }

  ngOnInit() {
    
    this.getRunNumber();
    this.getAllGeologists();
    this.getAllRockNames();
    this.depthFrom = new Size();
    this.depthTo = new Size();
    this.geologist = '';
    this.totalCoreRecovery = new Size();
    this.getBoreholeById(this.boreholeId);
    this.rockQuality = '';
    this.rockName = new RockName();
    this.person = new Person();
    this.coreRecoveryLog = new CoreRecoveryLog();
    this.name = '';
    this.recoveryDate = new Date();
    this.unit = new Unit();
    this.coreRecoveryLogs = [];

  }

  getRunNumber() {
    if (this.corerecoverylogs.length !== 0) {
      this.runNumber =
        Number(this.corerecoverylogs.reverse().pop().runNumber) + 1 + '';
    } else {
      this.runNumber = '1';
    }

  }

  getAllGeologists() {

    this.companyService.getAllCompanyPersonnel(this.companyId).subscribe(d => {
      this.persons =  d as [];
      this.filteredPersons = of(this.persons.sort((a, b) => a.name.localeCompare(b.name)));
    });
  }

  getAllRockNames() {
    this.coreService.getAllRockNames().subscribe(
      data => {
        this.rockNames = data as RockName[];
      }
    );
  }

  filterPersons(value) {

    this.filteredPersons = of(this.persons.filter(p => {
      return p.name.toLowerCase().includes(value.toLowerCase())
        || p.surname.toLowerCase().includes(value.toLowerCase())
      }).sort((a, b) => a.name.localeCompare(b.name)));
  }

  cancelForm() {
    const idObject = { id: this.boreholeId, tab: this.tabPosition };
    this.router.navigate(['/portal/applied-geoscience/geo-tech/borehole/borehole-logs', idObject]);

  }

  getBoreholeById(boreholeId: string) {
    this.coreService.getBoreholeById(boreholeId).subscribe(
      data => {
        this.borehole = data as Borehole;
      }
    );
  }

  saveRecord() {
    this.coreRecoveryLog = new CoreRecoveryLog();
    this.coreRecoveryLog.borehole = this.borehole;
    this.coreRecoveryLog.recoveryDate = this.recoveryDate;

    this.coreRecoveryLog.depthFrom = this.depthFrom;
    this.coreRecoveryLog.depthTo = this.depthTo;
    this.coreRecoveryLog.totalCoreRecovery = this.totalCoreRecovery;

    this.coreRecoveryLog.depthFrom.measure = this.model.depthFromMeasure;
    this.coreRecoveryLog.depthTo.measure = this.model.depthToMeasure;
    this.coreRecoveryLog.totalCoreRecovery.measure = this.model.totalCoreRecoveryMeasure;

    this.coreRecoveryLog.depthFrom.unit = this.unit;
    this.coreRecoveryLog.depthTo.unit = this.unit;
    this.coreRecoveryLog.totalCoreRecovery.unit = this.unit;

    this.coreRecoveryLog.depthFrom.unit.id = UnitEnum.meter;
    this.coreRecoveryLog.depthTo.unit.id = UnitEnum.meter;
    this.coreRecoveryLog.totalCoreRecovery.unit.id = UnitEnum.meter;

    this.coreRecoveryLog.geologist = this.model.geologist;
    this.coreRecoveryLog.lithology = this.model.lithology;
    this.coreRecoveryLog.rockQuality = this.model.rockQuality;
    this.coreRecoveryLog.runNumber = this.runNumber;

    if (parseFloat(this.coreRecoveryLog.depthFrom.measure) > parseFloat(this.coreRecoveryLog.depthTo.measure)) {
      this.toast.error('Depth From should be less than Depth To', 'Depth To');
      this.coreRecoveryLogs = [];
    } else if (parseFloat(this.coreRecoveryLog.rockQuality) > 101 || parseFloat(this.coreRecoveryLog.rockQuality) < 0) {
      this.toast.error('Rock Quality should be between 0 and 100', 'Rock Quality');
      this.coreRecoveryLogs = [];
    } else {
      this.coreRecoveryLogs.push(this.coreRecoveryLog);
      this.createBoreholeCoreRecoveryLog(this.coreRecoveryLogs, this.boreholeId);
    }
  }

  createBoreholeCoreRecoveryLog(recoveryLogs: CoreRecoveryLog[], boreholeId: string) {
    this.coreService.createBoreholeCoreRecoveryLog(recoveryLogs).subscribe(data => {
    },
      error => {
        this.toast.error('Recovery log creation failed', 'Recovery Log Creation');
        this.spinner.hide();
      },
      () => {
        $('#resetAddForm').click();
        this.spinner.hide();
        this.toast.success('Recovery log creation successful', 'Recovery Log Creation');
        this.messageEvent.emit('done')
        $('#coreRecoveryCreate').modal('hide');

      });
  }

  compareStrings(variableOne: any, variableTwo: any): boolean {
    return variableOne && variableTwo ? variableOne.id === variableTwo.id : variableOne === variableTwo;
  }

  geologistSelected(person) {
    this.model.geologist = person.id;
  }


}
