import {Component, Input, OnInit, ViewChild, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DcpSubgradeTest} from '../../../dcpSubgradeTest';
import {DCPTestService} from '../../../../dynamic-cone-penetrometer.service';
import {Unit} from '../../../../../../../shared-kernel/entity/common/unit';
import {UnitEnum} from '../../../../../../../shared-kernel/enumerations/unit-enum';
import {ToastrService} from 'ngx-toastr';
import {MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RoadWorksLogsValidations} from './road-works-logs-validations';
import {DcpRoadworksLog} from '../../../../../../../shared-kernel/entity/applied-geoscience/geoTech/DcpRoadworksLog';
import { CoordinatesType } from 'src/app/shared-kernel/entity/common/coordinatesType';
import { Coordinate } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinate';
import { CoordinatesTypesEnum } from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import { CoordinatesService } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import LatLon from 'geodesy/latlon-nvector-spherical.js';
import { CoordinatesFormat } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinatesFormat';
import { Village } from 'src/app/shared-kernel/entity/common/Village';
import { CommonService } from 'src/app/common/common.service';

declare var $: any;

@Component({
  selector: 'app-dcp-roadworks-logs-create',
  templateUrl: './dcp-roadworks-logs-create.component.html',
  styleUrls: ['./dcp-roadworks-logs-create.component.scss']
})
export class DcpRoadworksLogsCreateComponent implements OnInit {

  dataSourceDynamicConePenetrometerTestLogs: MatTableDataSource<DcpRoadworksLog> = new MatTableDataSource();
  dcpRoadworksLog: DcpRoadworksLog = new DcpRoadworksLog();
  dcpRoadworksTestId: string;
  dcpRoadworksTest: DcpSubgradeTest = new DcpSubgradeTest();
  UnitEnum: typeof UnitEnum = UnitEnum;
  modalHeading = '';
  isAdd: boolean = true;
  villages: Village[];

  coordinatesTypes: CoordinatesType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];

  dmsSx: number; // decimal minutes seconds, to hold x/longitude seconds value
  dmsMx: number; // decimal minutes seconds, to hold x/longitude minutes value
  dmsDx: number; // decimal minutes seconds, to hold x/longitude decimal value

  dmsSy: number; // decimal minutes seconds, to hold y/latitude seconds value
  dmsMy: number; // decimal minutes seconds, to hold y/latitude minutes value
  dmsDy: number; // decimal minutes seconds, to hold y/latitude decimal value

  zone34SNorthing: number;
  zone34SEasting: number;

  zone35SNorthing: number;
  zone35SEasting: number;

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private route: ActivatedRoute,
    private dcpTestService: DCPTestService,
    private toast: ToastrService,
    private roadWorksValidation: RoadWorksLogsValidations,
    private coordinatesService: CoordinatesService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DcpRoadworksLogsCreateComponent>) {
  }

  validation_messages = this.roadWorksValidation.validation_messages;

  ngOnInit() {
    this.getBotswanaGeofence();
    this.getAllVillages();
    this.dcpRoadworksLog.comments.description = '';
    this.dcpRoadworksTestId = this.data.dcpRoadworksTestId;
    if (this.data.dcpRoadworksLog){
      this.dcpRoadworksLog = this.data.dcpRoadworksLog;
      this.modalHeading = 'Update Roadworklog Record';
    } else {
      this.dcpRoadworksLog = new DcpRoadworksLog();
      this.modalHeading = 'Create Roadworklog Record';
    }
    this.getDcpRoadworksTestById();

    this.dcpRoadworksLog.coordinatesFormat = new CoordinatesFormat();
    this.dcpRoadworksLog.coordinatesFormat.type = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.dcpRoadworksLog.coordinatesFormat.format = this.coordinatesFormatsEnum.DD;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
  }

  closeDialog() {
    this.dialogRef.close(this.dcpRoadworksLog);
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
      });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });

  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesType = selectedData.text;
    this.dcpRoadworksLog.coordinatesFormat.type = this.selectedCoordinatesType;
    if (!this.isAdd) {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.dcpRoadworksLog.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.dcpRoadworksLog.coordinatesFormat.format = this.selectedCoordinatesFormat;
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpRoadworksLog.location.coordinates.latitude,
          this.dcpRoadworksLog.location.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpRoadworksLog.location.coordinates.latitude,
          this.dcpRoadworksLog.location.coordinates.longitude)._lon;
      }

    } else {
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
        this.dcpRoadworksLog.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
      if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
        this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
        this.dcpRoadworksLog.coordinatesFormat.format = this.selectedCoordinatesFormat;
      }
    }

  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesFormat = selectedData.text;
    this.dcpRoadworksLog.coordinatesFormat.format = this.selectedCoordinatesFormat;


    if (!this.isAdd) {
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S) {
        this.zone34SNorthing = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpRoadworksLog.location.coordinates.latitude,
          this.dcpRoadworksLog.location.coordinates.longitude)._lat;
        this.zone34SEasting = this.coordinatesService.convertLatLonToUtm(34,
          this.dcpRoadworksLog.location.coordinates.latitude,
          this.dcpRoadworksLog.location.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S) {
        this.zone35SNorthing = this.coordinatesService.convertLatLonToUtm(35,
          this.dcpRoadworksLog.location.coordinates.latitude,
          this.dcpRoadworksLog.location.coordinates.longitude)._lat;
        this.zone35SEasting = this.coordinatesService.convertLatLonToUtm(35,
          this.dcpRoadworksLog.location.coordinates.latitude,
          this.dcpRoadworksLog.location.coordinates.longitude)._lon;
      }
      if (this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
        this.dmsSx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpRoadworksLog.location.coordinates.latitude),
          parseFloat(this.dcpRoadworksLog.location.coordinates.longitude))._lonSValue);
        this.dmsMx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpRoadworksLog.location.coordinates.latitude),
          parseFloat(this.dcpRoadworksLog.location.coordinates.longitude))._lonMValue);
        this.dmsDx = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpRoadworksLog.location.coordinates.latitude),
          parseFloat(this.dcpRoadworksLog.location.coordinates.longitude))._lonDValue);

        this.dmsSy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpRoadworksLog.location.coordinates.latitude),
          parseFloat(this.dcpRoadworksLog.location.coordinates.longitude))._latSValue);
        this.dmsMy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpRoadworksLog.location.coordinates.latitude),
          parseFloat(this.dcpRoadworksLog.location.coordinates.longitude))._latMValue);
        this.dmsDy = parseFloat(this.coordinatesService.convertLatLonToDms(
          parseFloat(this.dcpRoadworksLog.location.coordinates.latitude),
          parseFloat(this.dcpRoadworksLog.location.coordinates.longitude))._latDVale);
      }

    } else {

    }
  }

  getDcpRoadworksTestById() {
    this.dcpTestService.getAllDCPTestSubgradeByDynamicConePenetrometerTestId(this.dcpRoadworksTestId).subscribe(data => {
        this.dcpRoadworksTest = data as DcpSubgradeTest;
      }
    );
  }

  getAllDCPTestSubgradeByDynamicConePenetrometerTestId() {
    this.dcpTestService.getDcpRoadworksRecordById(this.dcpRoadworksTestId).subscribe(
      data => {
        this.dataSourceDynamicConePenetrometerTestLogs.data = data as DcpRoadworksLog[];
      }, () => {

      }, () => {
        this.dataSourceDynamicConePenetrometerTestLogs = new MatTableDataSource(this.dataSourceDynamicConePenetrometerTestLogs.data);
      }
    );
  }

  resetPaginator() {
    this.dataSourceDynamicConePenetrometerTestLogs.sort = this.sort;
    this.dataSourceDynamicConePenetrometerTestLogs.paginator = this.paginator;
  }

  resetModal() {
    this.dcpRoadworksLog = new DcpRoadworksLog();
    this.selectedCoordinatesFormat = '';
    this.selectedCoordinatesType = '';
    this.resetPaginator();
    $('#addModal').modal('hide');
  }

  onSubmit() {
    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS) {
      this.dcpRoadworksLog.location.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lat;
      this.dcpRoadworksLog.location.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.dmsDy, this.dmsMy, this.dmsSy, this.dmsDx, this.dmsMx, this.dmsSx)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.dcpRoadworksLog.location.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lat;
      this.dcpRoadworksLog.location.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.zone34SNorthing, this.zone34SEasting)._lon;
    }

    if (this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.dcpRoadworksLog.location.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lat;
      this.dcpRoadworksLog.location.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.zone35SNorthing, this.zone35SEasting)._lon;
    }


    this.dcpRoadworksLog.location.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.dcpRoadworksLog.location.coordinates.latitude,
        this.dcpRoadworksLog.location.coordinates.longitude)._lat;
    this.dcpRoadworksLog.location.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.dcpRoadworksLog.location.coordinates.latitude,
        this.dcpRoadworksLog.location.coordinates.longitude)._lon;

    if (parseFloat(this.dcpRoadworksLog.endDepth.measure) > parseFloat(this.dcpRoadworksLog.startDepth.measure)) {
      if (new LatLon(this.dcpRoadworksLog.location.coordinates.latitude,
        this.dcpRoadworksLog.location.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
          if (this.isAdd) {
            this.dcpRoadworksLog.endDepth.unit = new Unit();
            this.dcpRoadworksLog.endDepth.unit.id = this.UnitEnum.meter;
            this.dcpRoadworksLog.startDepth.unit = new Unit();
            this.dcpRoadworksLog.startDepth.unit.id = this.UnitEnum.meter;
            this.dcpRoadworksLog.penetration.unit = new Unit();
            this.dcpRoadworksLog.penetration.unit.id = this.UnitEnum.millimeter;
            this.dcpRoadworksLog.dynamicConePenetrometerTestRoadworks = this.dcpRoadworksTest;
          } else {
            this.dcpRoadworksLog.dynamicConePenetrometerTestRoadworks = this.dcpRoadworksTest;
          }
          this.dcpTestService.createDynamicConePenetroMeterRoadworkLogRecord(this.dcpRoadworksLog).subscribe(
            data => {
            }
            , () => {
            }
            , () => {
              if (this.modalHeading === 'Create Roadworklog Record') {
                this.toast.success('You have Successfully created a New Roadworks Logs', 'RoadworksLog Create');
              } else {
                this.toast.success('You have Successfully updated a Roadworks Logs', 'RoadworksLog Update');
              }
              this.closeDialog();
              this.dcpRoadworksLog = new DcpRoadworksLog();
            }
          );

      } else {
        this.toast.error(this.coordinatesService.outSide);
      }
    } else {
      this.toast.error('End Depth should be greater than Start Depth', 'RoadworksLog Create Error!');
    }

  }
}
