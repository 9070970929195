import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FoundationValidation implements OnInit {

  constructor() {
  }

  validation_messages = {
    'testNumber': [
      {type: 'required', message: 'Test number is required'}
    ],
    'projectName': [
      {type: 'required', message: 'Project name is required'},
    ],
    'date': [
      {type: 'required', message: 'date is required'},
    ],
    'stationNumber': [
      {type: 'required', message: 'Station number is required'},
    ],
    'longitude': [
      {type: 'required', message: 'longitude is required'},
    ],
    'latitude': [
      {type: 'required', message: 'latitude is required'},
    ],
    'village': [
      {type: 'required', message: 'village is required'},
    ],
    'district': [
      {type: 'required', message: 'district is required'},
    ],
    'surfaceType': [
      {type: 'required', message: 'Surface Type is required'},
    ],
    'time': [
      {type: 'required', message: 'time is required'},
    ],
    'hummerWeight': [
      {type: 'required', message: 'hummer weight required'}
    ],
    'projectCoordinator': [
      {type: 'required', message: 'This is a required filed'}
    ],
    'lithologyDescription': [
      {type: 'required', message: 'Lithology formation description required'}
    ],
    'type': [
      { type: 'required', message: 'Select Coordinates Type' },
    ],
  };

  ngOnInit(): void {
  }

}
