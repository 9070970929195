import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { DiameterFormation } from '../entity/diameterFormation';

@Injectable({
  providedIn: 'root'
})
export class DiameterFormationService {

  constructor(private http: HttpClient) { }

  createDiameterFromation( diameterFormation: DiameterFormation) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createDiameterFormationUrl, diameterFormation );
  }

  createAListOfDiameterFormation( diameterFormations: DiameterFormation[] ) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createAListOfDiameterFormationdUrl, diameterFormations);
  }

  deleteDiameterFormation( diameterFormation: DiameterFormation ) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteDiameterFormationdUrl, diameterFormation );
  }

  getAllDiameterFormationByBoreholeId(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDiameterFormationdByBoreholeIdUrl + '/' + id);
  }

  getAllDiameterFormations() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDiameterFormationsUrl);
  }

  getTotalNumberOfDiameterFormations() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getTotalNumberFormationsUrl);
  }
}
