import { Person } from './../../../_core/data/_models/people.model';
import { UserService } from './../../../auth/user/user.service';
import { StorageConstants } from './../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Roles } from './../../../shared-kernel/entity/Roles';
import { ToastrService } from 'ngx-toastr';
import { ActivitiesService } from './../../../activities/services/activities.service';
import { Activity } from './../../../activities/models/activity.model';
import { LocalAuthorities } from './../../../_auth/_providers/local-authorities';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qa-dashboard-ui',
  templateUrl: './qa-dashboard-ui.component.html',
  styleUrls: ['./qa-dashboard-ui.component.scss']
})
export class QaDashboardUiComponent implements OnInit {

  cards :any;
  @Input('pendingApprovalCount') pendingApprovalCount: any;
  @Input('allVettedCount') allVettedCount: any;
  @Input('myactivities') myactivities: any;
  isSet = true;
  isSet1 = true;
  @Input('myproject') myproject: any;

  public pieChartType = 'pie';

  public myactivitiesLabels = [ 'Pending-Approval', 'Returned', 'Submitted-Manager'];
  public myactivitiesData = [ 91, 107, 73, 107];

  pendingTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  rejectedTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  submittedToManagerTableDataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  currentUser: any;
  currentPerson: any;
  colors: any ;  

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _localAuthorities: LocalAuthorities,
    private _activitiesServ: ActivitiesService,
    private _toaster: ToastrService,
    private _localStorage: LocalStorage,) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

            return [
              { title: 'My Activities', cols: 1, rows: 1 },
              { title: 'Submitted To Manager', cols: 1, rows: 1 },
              { title: 'Pending Approval', cols: 1, rows: 1 },
              { title: 'Returned', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: '', cols: 2, rows: 1 , id: 'cards'},
            { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
            { title: 'Submitted To Manager', cols: 1, rows: 3 ,id: 'managerSubmitted'},
            { title: 'Pending Approval',cols: 1, rows: 3 ,id: 'pendingApproval'},
            { title: 'Returned', cols: 1, rows: 3 ,id: 'returned'}
          ];
        })
      );
    }

    ngOnInit() {


      this.myactivitiesData = [this.myactivities.numberOfPendingActivities, this.myactivities.numberOfReturnedActivities,
        this.myactivities.numberOfActivitiesSubmittedToManager];
        this.getIsDataset();
        this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
          this.currentUser = res;

          this.initPerson(res.id);
        }, err => {
          this._toaster.warning(err.message, "Current User Error");
        },() => {

        });
        this.colors = [{backgroundColor: [  '#573419','#CC0000',
        '#C8A207','#CC0000','#009900', '#CC9933']}];
    }

    private initPerson(userId: string) {
      this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
        if (pRes) {
          this.currentPerson = pRes;
        }
      }, err => {
        this._toaster.error(err.message, "Profile Details");
      }, () => {
        (async () => {
          await this.getPendingActivities(this.currentPerson);
        })();
      });
    }

    ngAfterViewInit() {
    }

    goTo(uri: string) {
      let urlParams: any;
      if('qa_activities_approved'=== uri){
        urlParams = { id: 'activities_approved' };
      }
      else if('qa_activities_vetted'=== uri){
        uri = 'qa_activities_approved';
        urlParams = { id: 'activities_vetted' };
      }
      else {
        urlParams= {};
      }

      this._router.navigate([`portal/activities/${uri}`,urlParams]);
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/qa_activities_pending_approval']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/qa_activities_rejected']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate([`portal/activities/qa_activities_approved`,{id: 'activities_approved'}]);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate([`portal/activities/qa_activities_approved`,{id: 'activities_vetted'}]);
        break
      default:
    }
  }

  getIsDataset(){
    this.isSet = (this.myactivities.numberOfPendingActivities + this.myactivities.numberOfReturnedActivities
      + this.myactivities.numberOfActivitiesSubmittedToManager)>0;
  }

  sliceResultArray(list: any[]): any[] {
    let respArray: any[];
    if (list.length <= 8) {
      respArray = list;
    } else {
      respArray = list.slice(list.length-8);
    }
    return respArray
  }

  getPendingActivities(current) {
    this._localAuthorities.getFullUser(current.user.username).subscribe(
      value => {

        if(value.roles.find(x => x.name.toLowerCase() == Roles.NONBGIQA.toLowerCase())) {
          this._activitiesServ.getNonBGIQaActivitiesPendingApproval().subscribe(res => {
            this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);

          }, err => {
            this._toaster.warning(err.message, 'Failed to get Pending activities');
          });

          this._activitiesServ.getAllNonBGIQaActivitiesRejected().subscribe(res => {
            this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get Rejected activities');
          });

          this._activitiesServ.getAllNonBGIQaActivitiesApproved().subscribe(res => {
            this.submittedToManagerTableDataSource.data = this.submittedToManagerTableDataSource.data ? this.submittedToManagerTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get activities');
          });
        }

        if(value.roles.find(x => x.name.toLowerCase() == Roles.QA.toLowerCase())) {
          this._activitiesServ.getQaActivitiesPendingApproval().subscribe(res => {
            this.pendingTableDataSource.data = this.pendingTableDataSource.data ? this.pendingTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get activities');
          });

          this._activitiesServ.getAllQaActivitiesRejected().subscribe(res => {
            this.rejectedTableDataSource.data = this.rejectedTableDataSource.data ? this.rejectedTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get Rejected activities');
          });

          this._activitiesServ.getAllQaActivitiesApproved().subscribe(res => {
            this.submittedToManagerTableDataSource.data = this.submittedToManagerTableDataSource.data ? this.submittedToManagerTableDataSource.data.concat(this.sliceResultArray(res)) : this.sliceResultArray(res);
          }, err => {
            this._toaster.warning(err.message, 'Failed to get activities');
          });
        }
      }
    );

  }

  gotoManagerSubmittedList(){
    let urlParams = { id: 'activities_approved' };
    this._router.navigate([`portal/activities/qa_activities_approved`,urlParams]);
  }

  gotoPendingList(){
    this._router.navigate([`portal/activities/qa_activities_pending_approval`]);
  }

  gotoReturnedList(){
    this._router.navigate([`portal/activities/qa_activities_rejected`]);
  }


}
