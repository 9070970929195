import { Duration } from './duration';
import { Notes } from '../../../shared-kernel/entity/applied-geoscience/notes';

export class TimeAndMotion {
    id: string;
    date: Date;
    timeFrom: string;
    timeTo: string;
    metersFrom: string;
    metersTo: string;
    travellingHoursTo: Duration = new Duration();
    drillingHours: Duration = new Duration();
    pullingAndLowering: Duration = new Duration();
    maintenanceHours: Duration = new Duration();
    mobilisationHours: Duration = new Duration();
    preStartChecks: Duration = new Duration();
    others: Duration = new Duration();
    duration: string;
    comments: string;
    deleted: Boolean = false;
    borehole: string;
}
