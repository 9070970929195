import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProspectingLicense } from 'src/app/shared-kernel/entity/applied-geoscience/prospecting-license/prospecting-license';
import { PeopleData } from 'src/app/_core/data/people';
import { ProspectingLicenseService } from '../../prospecting-license.service';


@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit {

  person: any;
  persoName: any;
  selectedMember: any;
  prospectingLicense: any;
  role: '';
  


  constructor(
    public dialogRef: MatDialogRef<UpdateRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _peopleServ: PeopleData,
    private _toastr: ToastrService,
    private pLService: ProspectingLicenseService,
  ) { 
    this.selectedMember = data.member;
    this.prospectingLicense = this.data.prospectingLicenseDetails;
  }

  ngOnInit() {
    //this.prospectingLicense = this.data.prospectingLicense;
    this._getPerson();

  }

  closeDialog() {
    this.dialogRef.close();
}
private _getPerson() {
  this._peopleServ.getPersonById(this.selectedMember.memberId).subscribe(res => {
      this.person = res;
      this.persoName = res.name+" "+res.surname
  }, err => {
      this._toastr.error(err.message, "Failed to get Person Info");
  });
}

onUpdateRole() {
  this.prospectingLicense.projectMembers.forEach(mbr => {
    if (mbr.memberId === this.selectedMember.memberId) {
      this.prospectingLicense.projectMembers.role = this.selectedMember.role;
      
    }
  });

  this.pLService.createProspectingLicense(this.prospectingLicense).subscribe(
    response => {
      this._toastr.success('Prospecting license project members updated successfully', 'Update Project Members');
      this.dialogRef.close(this.prospectingLicense);
    },
    error => {
      this._toastr.error(error.error.message, 'Error Occurred During An Update');
      this.dialogRef.close(this.prospectingLicense);
    }, () => {
      this.getPLByID();
     
    });

}
getPLByID() {
  this.pLService.getPLById(this.prospectingLicense.id).subscribe(
    pl => {
      this.prospectingLicense = pl as ProspectingLicense;
    }
  );
}
}
