import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { StorageConstants } from './../../_core/storage.constants';

@Directive({
    selector: '[ifUnit]'
})
export class IfUnitDirective {
    constructor(
        private templateRef : TemplateRef<any>,
        private viewContainer : ViewContainerRef,
        private _localStorage: LocalStorage
    ) {}

    @Input() set ifUnit(unit: string) {
        let promise = new Promise((resolve, reject) => {
            this._localStorage.getItem<any>(StorageConstants.personKey).toPromise().then(res => {
                resolve(res);
            }, err => {
                reject(err);
            });
        });

        promise.then((res:any) => {
            this.logic(unit, (res.user && res.user.businessUnit) ? res.user.businessUnit.name : '');
        }).catch(_err => {
            this.logic(unit, '');
        });
    }

    private logic(unitOne: string, unitTwo: string) {
        if (unitOne.toLocaleLowerCase() === unitTwo.toLocaleLowerCase()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
