import {Size} from '../../common/size';
import {CasingMaterial} from '../geoTech/CasingMaterial';
import {ProspectingLicense} from '../prospecting-license/prospecting-license';
import {
  ChemistryCompound
} from "../../../../applied-geoscience/geo-tech/samples/samples-details/geo-chemistry/csv-record.model";

export class MineralChemistry {
  sampleId:       any;
  mineralId?:      any;
  compounds:      Array<ChemistryCompound>;

  creator?:       any | null | any;
  created?:       string | Date | any;
  updater?:       any | null | string | Date;
  updated?:       string | Date | any;
  id?:            string;
  reference?:     string;
}


