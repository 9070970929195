import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ChemistryCompound, SampleGeoChemistry } from './csv-record.model';
import { SampleGeoChemistryService } from './geo-chemistry.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MineralResourcesActivities } from '../../../../../activities/models';
import { FormControl, FormGroup } from '@angular/forms';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
    selector: 'sample-geo-chemistry',
    templateUrl: './geo-chemistry.component.html',
    styleUrls: ['./geo-chemistry.component.scss'],
    providers: [
        SampleGeoChemistryService
    ]
})
export class SampleGeoChemistryComponent implements OnInit {
    @Input('currentState') currentState: string ='';
    @Input('sampleNumber') sampleNumber?: string;
    @Input('linkedEntity') linkedEntity?: string;
    @Input('projectId') projectId: string = '';
    @ViewChild('uploadInput') uploadInput: ElementRef;

    dataSource: Array<any> = [];
    tableDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    displayedColumns: Array<string> = [];
    fetching = false;
    selection = new SelectionModel<any>(true, []);


  constructor(
        private toastr: ToastrService,
        private geoChemServ: SampleGeoChemistryService
    ) {

  }

    ngOnInit () {
        this._getMineralChemistry();
    }

    applyFilter(filterValue: string) {
    }

    importCSVFile() {
        this.uploadInput.nativeElement.click();
    }

    onSelectFile(files: FileList) {
        const csvFile = files[0];

        if (!this.isCsvExtension(csvFile)) {
            this.toastr.error("Invalid file extension", "Invalid File");
            return;
        }

        const reader = new FileReader();
        reader.readAsText(csvFile);
        reader.onerror = () => {
            this.toastr.error("Error reading file", "File Error");
        }

        reader.onload = () => {
            let csvData = reader.result;
            let recordsArr = (<string>csvData).split(/\r\n|\n/);

            const recordsToUpload: Array<SampleGeoChemistry> = [];
            const headers = recordsArr[0].split(',');

            for (let i = 1; i < recordsArr.length; i++) {
                if (recordsArr[i].trim() === "") continue;

                let currentRecord = recordsArr[i].split(',');

                let tempRecord: SampleGeoChemistry ;
                if(this.sampleNumber){
                  tempRecord = <SampleGeoChemistry>{
                      sampleId: currentRecord[0],
                      mineralId: currentRecord[1],
                      compounds: []
                  };

                  for (let j = 4; j< currentRecord.length; j++) {
                    if(Number(currentRecord[j])){
                      tempRecord.compounds.push(<ChemistryCompound>{
                        compound: headers[j],
                        number: +(currentRecord[j])
                      });
                    }
                  }
                }

                if(Number(currentRecord[1]).valueOf() >= 0 && Number(currentRecord[2]).valueOf() >= 0){
                  if(Number(currentRecord[1])<Number(currentRecord[2])){

                    if(i==1){
                      if(this.linkedEntity){
                        tempRecord = <SampleGeoChemistry>{
                          sampleId: currentRecord[0],
                          depthFrom: currentRecord[1],
                          depthTo: currentRecord[2],
                          linkedEntity: this.linkedEntity,
                          compounds: []
                        };
                      }
                      for (let j = 4; j< currentRecord.length; j++) {
                        if(Number(currentRecord[j])){
                          tempRecord.compounds.push(<ChemistryCompound>{
                            compound: headers[j],
                            number: +(currentRecord[j])
                          });
                        }
                      }
                    }
                    else{
                      let previousRecord = recordsArr[i-1].split(',');
                      if(Number(currentRecord[1]).valueOf()<Number(previousRecord[2]).valueOf()){
                        this.toastr.warning('Current Depth-From has to be greater than Previous Depth-To value','Import Data Validation');
                      }
                      else{
                        if(this.linkedEntity){
                          tempRecord = <SampleGeoChemistry>{
                            sampleId: currentRecord[0],
                            depthFrom: currentRecord[1],
                            depthTo: currentRecord[2],
                            linkedEntity: this.linkedEntity,
                            compounds: []
                          };
                          for (let j = 4; j< currentRecord.length; j++) {
                            if(Number(currentRecord[j])){
                              tempRecord.compounds.push(<ChemistryCompound>{
                                compound: headers[j],
                                number: +(currentRecord[j])
                              });
                            }
                          }
                        }
                      }
                    }



                  }else{
                    this.toastr.warning('Depth-From has to be greater than Depth-To','Import Data Validation');
                  }
                }
                else{
                  this.toastr.warning('Depth-From and Depth-To should be greater than 0','Import Data Validation');
                }

              recordsToUpload.push(tempRecord);

            }

          if(this.linkedEntity){
            this.geoChemServ.addGeoChemistryInBorehole(recordsToUpload, this.linkedEntity).subscribe(_res => {
              this._getMineralChemistry();
            }, err => {
              this.toastr.error(err.error.error +': '+err.error.message, "Failed to upload");
            },() => {
              this.toastr.success("Geo-chemistry samples successfully uploaded", "Upload Successful");
            });
          }

          if(this.sampleNumber){
            this.geoChemServ.addGeoChemistry(recordsToUpload, this.sampleNumber).subscribe(_res => {
              this._getMineralChemistry();
            }, err => {
              this.toastr.error(err.error.error +': '+err.error.message, "Failed to upload");
            },() => {
              this.toastr.success("Geo-chemistry samples successfully uploaded", "Upload Successful");
            });
          }

        }
    }



    isCsvExtension(file: File): Boolean {
        return file.name.endsWith('.csv');
    }

    private _getMineralChemistry() {
      if (this.sampleNumber) {
            this.geoChemServ.getGeoChem(this.sampleNumber).subscribe(res => {
                this.tableDataSource.data = res ;
                this._initColums(res);
                this._initRows(res);
            }, err => {
                this.toastr.error(err.message, "Failed to get mineral chemistry");
            });
      }

      if(this.linkedEntity){
          this.geoChemServ.getGeoChemByBoreholeId(this.linkedEntity).subscribe(res => {
            this.tableDataSource.data = res ;
            this._initColums(res);
            this._initRows(res);
          }, err => {
            this.toastr.error(err.message, "Failed to get mineral chemistry");
          });
      }
    }

    private _initColums(rawChemistry: Array<SampleGeoChemistry>) {

        let total : string;
        while (this.displayedColumns && this.displayedColumns.length > 0) this.displayedColumns.pop();

        if(this.sampleNumber) {
          this.displayedColumns.push("Check","No.", "Sample No.", "Mineral Id");
        }
        if(this.linkedEntity){
          this.displayedColumns.push("Check","No.", "Sample No.","Depth From","Depth To","Interval");
        }

        if (rawChemistry && rawChemistry.length > 0) {
            rawChemistry[0].compounds.forEach(c => {
              if('Total'.toLowerCase() === c.compound.trim().toString().toLowerCase()) {
                total = c.compound;
              }
              else{
                this.displayedColumns.push(c.compound);
              }
            });
            this.displayedColumns.push(total);
        }
    }

    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() : this.dataSource.forEach(row => this.selection.select(row));
    }

    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource ? this.dataSource.length : 0;
      return numSelected === numRows;
    }

    checkboxLabel(row?: any): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
    }

  onDeleteAll(){

    let geochemistryList: string = '';
    let geochemistryInBorehole: any[] = [];
    this.selection.selected.forEach(value => {
      geochemistryList = geochemistryList + ' <li>No. : '+value['No.']+ ', Test Sample No. : ' + value['Sample No.'] + '</li>';
      geochemistryInBorehole.push(this.tableDataSource.data.filter(value1 => value1.id ===value['Check'])[0]);
    });
    geochemistryInBorehole.forEach(value => value.deleted = true);

    Swal.fire(<SweetAlertOptions>{
        title: 'Geochemistry Delete',
        type: 'warning',/*
        text: sampleCollection,*/
        html: '<b>Are you sure you want to delete the following Geochemistry test(s) ? </b>  ' +
          '<ul class="col-md-12 ">' + geochemistryList + '</ul>',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(result => {
        if(result.dismiss){}
        else {
          this.selection = new SelectionModel<any>(true, []);
          this.confirmDelete(geochemistryInBorehole);
        }
      });
  }

  confirmDelete(geochemistryInBorehole : any){

    if(this.sampleNumber) {
      this.geoChemServ.addGeoChemistry(geochemistryInBorehole, this.sampleNumber).subscribe(_res => {
        this._getMineralChemistry();
      }, err => {
        this.toastr.error(err.error.error +': '+err.error.message, "Failed to upload");
      },() => {
        this.toastr.success("Geo-chemistry samples successfully uploaded", "Upload Successful");
      });

    }
    if(this.linkedEntity){
      this.geoChemServ.addGeoChemistryInBorehole(geochemistryInBorehole, this.linkedEntity).subscribe(_res => {
        this._getMineralChemistry();
      }, err => {
        this.toastr.error(err.error.error +': '+err.error.message, "Failed to upload");
      },() => {
        this.toastr.success("Geo-chemistry samples successfully uploaded", "Upload Successful");
      });
    }

  }

  private _initRows(rawChemistry: Array<SampleGeoChemistry>) {

       this.dataSource = [];
       let localDataSource = [];

        for (let i = 0; i < rawChemistry.length; i++) {
          let temp : any;
          let total : number;
          if(this.sampleNumber){
            temp = {
              "Check": rawChemistry[i].id,
                "No.": i + 1,
                "Sample No.": rawChemistry[i].sampleId,
                "Mineral Id": rawChemistry[i].mineralId
            };
          }

          if(this.linkedEntity){
            temp = {
              "Check": rawChemistry[i].id,
              "No.": i + 1,
              "Sample No.": rawChemistry[i].sampleId,
              "Depth From": rawChemistry[i].depthFrom,
              "Depth To": rawChemistry[i].depthTo,
              "Interval": rawChemistry[i].depthTo - rawChemistry[i].depthFrom,
            };
          }
          rawChemistry[i].compounds.forEach(c => {
                  temp[c.compound] = c.number;
          });
          localDataSource.push(temp);
        }
        this.dataSource = localDataSource;
    }
}
