import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeoTechService } from '../../geo-tech.service';
import { Person } from 'src/app/common/entity/security/profile/person';
import { UserService } from 'src/app/auth/user/user.service';
import { Lightbox } from 'ngx-lightbox';
import { BoreholeCreateComponent } from '../borehole-create/borehole-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ApproveAction } from '../../../models';
import Swal from "sweetalert2";
import { ApprovalService } from '../services/approval.service';
import { CompanyEnum } from '../../../../shared-kernel/enumerations/company-enum';
import { DocumentsService } from "../../../../shared/documents/dococument.service";
import { StorageConstants } from '../../../../_core/storage.constants';
import { LocalAuthorities } from '../../../../_auth/_providers';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BoreholeTypeEnum } from 'src/app/shared-kernel/enumerations/borehole-type-enum';
import { ENTER, COMMA } from "@angular/cdk/keycodes";
declare var $: any;
@Component({
  selector: 'app-borehole-detail',
  templateUrl: './borehole-detail.component.html',
  styleUrls: ['./borehole-detail.component.scss']
})
export class BoreholeDetailComponent implements OnInit {
  boreholeDetails: Borehole;
  boreholeStatusHistory: any[];
  boreholeLocationImages: any[]
  boreholeDetailsListView: any;
  boreholeCount: any;
  sampleCount: any = '--';
  isBGIproject: boolean;
  companyEnum: typeof CompanyEnum = CompanyEnum;
  selectedSection: string;
  currentComment: string;
  totalNumberOfFiles: number;
  documentCount: number = 0;
  totalNumberOfLogs: number;
  expanded = true;
  url: string;
  boreholeId: string;
  projectCoordinator: Person = new Person();
  projectGeologist: Person = new Person();
  projectManager: Person = new Person();
  album: any = [];
  currentUser: any;
  currentPerson: any;
  type: string;
  visibleFields: {};
  sampleTypes: string = '--';
  depthOfSurfaceCasingLabel: string = 'Depth of Surface Casing';

  totalNumberOfContructionLogs: number;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  boreholeAccessRoles: string[] = ['Technician Mineral Resources', 'system admin', 'Geochemist', 'Economic Geologist', 'Senior Geochemist', 'Senior Economic Geologist'];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private geoTechService: GeoTechService,
    private route: ActivatedRoute,
    private userService: UserService,
    private _approvalServ: ApprovalService,
    private _docServ: DocumentsService,
    private lightbox: Lightbox,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
    private dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.boreholeId = params.id;
      this.type = params.type;
    });
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

    }, err => {
      this.toastr.warning(err.message, "Current User Error");
    }, () => {

    });
    this.getBoreholeByBoreholeId(this.boreholeId);

    if (this.type === 'BGI') {
      this.boreholeAccessRoles.push('Geologist', 'Driller');
    } else if (this.type === 'Water') {
      this.boreholeAccessRoles.push('Hydrogeologist');
    }
    this.visibleFields = {
      prospectingLicense: this.type === 'PL',
      developmentType: this.type !== 'BGI',
      descriptionOfDrillingFluidsUsed: this.type === 'Water',
      depthOfSurfaceCasing: true,
      boreholeOwner: this.type !== 'BGI',
      staticWaterLevel: this.type === 'Water',
      samplesCollected: this.type === 'PL',
      variance: true,
      plannedDepth: true,
      overburdenThickness: this.type != 'Water',
      drillRigType: this.type != 'Water',
      waterStrike: this.type === 'BGI',
      estimatedYield: this.type === 'Water',
    }

    this.depthOfSurfaceCasingLabel = this.type === 'Water' ? 'Top of Casing (TOC)' : 'Depth of Surface Casing';
  }

  open(index: number): void {
    this.lightbox.open(this.album, index);
  }

  close(): void {
    this.lightbox.close();
  }

  getPersonById(id: String, personDesignation: string) {
    this.userService.getPersonById(id).subscribe(data => {

      if (personDesignation === 'projectCoordinator') {
        this.projectCoordinator = data as Person;
      }
      if (personDesignation === 'projectGeologist') {
        this.projectGeologist = data as Person;
      }
      if (personDesignation === 'projectManager') {
        this.projectManager = data as Person;
      }

    });
  }

  getBoreholeByBoreholeId(id: string) {

    this.geoTechService.getBoreholeById(id).subscribe(
      value => {
        this.boreholeDetailsListView = value;
        if (this.boreholeDetailsListView.sampleTypes) {
          this.sampleTypes = this.boreholeDetailsListView.sampleTypes.map(x => x.name).join(', ');
        }

        if(this.boreholeDetailsListView.comments){
          if(this.boreholeDetailsListView.comments.id){
            this.currentComment = this.boreholeDetailsListView.comments.description;

          } else if(this.boreholeDetailsListView.comments === '') {
            this.currentComment = null;
          }
        }

        this.getBoreholeCounts();
        this.getSampleCounts();
        this.getBoreholeStatusHistory();
        this.getBoreholeLocationImagery();
        this.calculateConstructionLogSum();
        this.isBGIproject = this.boreholeDetailsListView.boreholeOwner === this.companyEnum.BGI_NAME;

        this.getPersonById(this.boreholeDetailsListView.projectCoordinator, 'projectCoordinator');
        this.getPersonById(this.boreholeDetailsListView.projectGeologist, 'projectGeologist');
        this.getPersonById(this.boreholeDetailsListView.projectManager, 'projectManager');
      }, error1 => { }, () => {

      }
    );


  }

  getBoreholeLocationImagery() {
    this.geoTechService.getBGIBoreholeLocationImageryByID(this.boreholeId).subscribe(value => {
      this.boreholeLocationImages = value as any[];
      for (let image of this.boreholeLocationImages) {
        let albumImage: any = {};
        albumImage.src = 'data:image/jpg;base64,' + image.image;
        albumImage.caption = image.name;
        albumImage.thumb = 'data:image/jpg;base64,' + image.image;
        this.album.push(albumImage);
      }
    })
  }


  cancel() {
    localStorage.removeItem('borehole');
    this.router.navigate(['portal/applied-geoscience/geo-tech/borehole/list']);
  }

  onSectionChange(selectedSection) {
    this.expanded = false;
    this.selectedSection = selectedSection;
  }

  ngOnDestroy() {
    localStorage.removeItem('borehole');
  }

  redirectToDocuments() {
    this.expanded = false;
    this.selectedSection = 'boreholeDocuments';
  }

  changeState(action: ApproveAction) {
    let successMessage: string = 'BGI Borehole submitted successfully';
    let title: string = 'BGI Borehole Submit';

    switch (+action) {
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'BGI Borehole submitted to QA successfully';
        title = 'BGI Borehole Submit';
        break;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this._approvalServ.changeState(this.boreholeId, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toastr.success(successMessage, title);
          this.getBoreholeByBoreholeId(this.boreholeId);
        }, err => {
          this.toastr.warning(err.message, 'Failed');
        });
      }
    });

  }

  calculateConstructionLogSum() {
    this.totalNumberOfContructionLogs = this.boreholeDetailsListView.constructionScreenings.length
      + this.boreholeDetailsListView.constructionCasings.length
      + this.boreholeDetailsListView.drillingProtocols.length + this.boreholeDetailsListView.waterBearingZonesLogs.length;
  }

  redirectToLogs() {
    this.expanded = false;
    this.selectedSection = 'boreholeLogs';
  }

  redirectToEmForms() {
    this.expanded = false;
    this.selectedSection = 'timeAndMotion';
  }

  redirectToSamples() {
    this.expanded = false;
    this.selectedSection = 'samples';
  }

  redirectToTests() {
    if (this.type === 'BGI') {
      this.expanded = false;
      this.toastr.warning('Feature coming soon...', 'Tests');
    } else if (this.type === 'Water') {
      this.selectedSection = 'waterPumpingTestSection';
    }
  }

  redirectToMineralResourceTests() {
    this.expanded = false;
    this.selectedSection = 'mineralResourceTestSection';
  }

  redirectToAnalyticalReports() {
    this.expanded = false;
    this.selectedSection = 'analyticalReports';
  }

  redirectToBoreholeConstruction() {
    this.selectedSection = 'boreholeConstructionSection';
  }

  calculateLogSum() {
    this.totalNumberOfLogs = this.boreholeDetailsListView.boreholeLogRecords.length +
      this.boreholeDetailsListView.boreholeLithologyLogs.length +
      this.boreholeDetailsListView.waterBoreholeLithologyLogs.length +
      this.boreholeDetailsListView.boreholeCoreRecoveryLogs.length +
      this.boreholeDetailsListView.waterBoreholeDrillersLogs.length +
      this.boreholeDetailsListView.waterPumpingTests.length +
      this.boreholeDetailsListView.constructionCasings.length +
      this.boreholeDetailsListView.constructionScreenings.length +
      this.boreholeDetailsListView.drillingProtocols.length +
      this.boreholeDetailsListView.waterBearingZonesLogs.length +
      this.boreholeDetailsListView.diameterFormations.length +
      this.boreholeDetailsListView.penetrationRateLogs.length;
  }

  openBoreholeForEdit() {

    let dialogData = {
      borehole: this.boreholeDetailsListView,
      title: `Edit ${this.type} Borehole`,
      boreholeType: this.type === 'Water' ? BoreholeTypeEnum.Water : BoreholeTypeEnum.Exploration,
      depthOfSurfaceCasingLabel: this.depthOfSurfaceCasingLabel,
      type: this.type,
      disableOwner: this.type != 'Water',
      visibleFields: this.visibleFields
    }

    const newDCPDialogRef = this.dialog.open(BoreholeCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: dialogData
    });
    newDCPDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getBoreholeByBoreholeId(this.boreholeId);
      }
    });
  }

  getBoreholeCounts() {
    this.geoTechService.getBoreholeCountsById(this.boreholeId).subscribe(value => {
      this.boreholeCount = value;
    })
  }

  getSampleCounts() {
    this.geoTechService.getSampleCountsById(this.boreholeId).subscribe(value => {
      this.sampleCount = value;
    })
  }

  getBoreholeStatusHistory() {
    this.geoTechService.getBoreholeStatusHistoryById(this.boreholeId).subscribe(value => {
      this.boreholeStatusHistory = value as [];
      this.boreholeStatusHistory.sort((a, b) => <any>new Date(a.created) - <any>new Date(b.created));
    })
  }


}
