import {Component, OnInit} from '@angular/core';
import {ApprovalState} from '../../applied-geoscience/models';
import {State} from '../../activities/models/state';
import {Router} from '@angular/router';
import {UserDashboardService} from '../user-dashboard/user-dashboard.service';
import {UserService} from '../../auth/user/user.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Person} from '../../common/entity/security/profile/person';
import {Activity} from "../../activities/models";

@Component({
  selector: 'app-surveys-dashboard',
  templateUrl: './surveys-dashboard.component.html',
  styleUrls: ['./surveys-dashboard.component.scss']
})
export class SurveysDashboardComponent implements OnInit {
  title = 'User Dashboard';
  plActivitiesPerDistrict :[];
  numberOfNonBGIProjects: number;
  numberOfBGIProjects: number;
  numberOfMyProjects: number;
  numberOfProjectsActivitiesSubmittedToQA: number;
  numberOfProjectsActivitiesSubmittedToManager: number;
  numberOfProjectsActivitiesRejectedByQA: number = 0;
  numberOfProjectsActivitiesRejectedByManager: number = 0;
  numberOfProjectsActivitiesApproved: number;
  numberOfMyPLs: number;
  numberOfPLs: number;
  numberOfMyPlBoreholes:number;
  numberOfNonPlBoreholes:number;
  numberOfUserTrialPits:number;
  private userName: string;
  private userReference: string;
  private authorities;
  private roles;
  numberOfActivities: any;
  numberOfDrafts: any;
  person: Person;

  myactivities = {
    numberOfMyProjects:0,
    numberOfBGIProjects:0,
    numberOfActivities:0,
    numberOfDrafts:0,
    numberOfProjectsActivitiesSubmittedToQA:0,
    numberOfProjectsActivitiesSubmittedToManager:0,
    approved:0,
    returned:0
  };

  myplactivities = {
    numberOfMyPl:0,
    numberOfAllPl:0,
    numberOfActivities:0,
    numberOfDrafts:0,
    numberOfPlActivitiesSubmittedToQA:0,
    numberOfPlActivitiesSubmittedToManager:0,
    approved:0,
    returned:0
  };

  myproject = {
    numberOfProjectsDrafts:0,
    numberOfProjectsSubmittedToQA:0,
    numberOfProjectsSubmittedToManager:0,
    numberOfProjectsApproved:0,
    numberOfProjectsReturned:0
  };

  mypl = {
    numberOfPlDrafts:0,
    numberOfPlSubmittedToQA:0,
    numberOfPlSubmittedToManager:0,
    numberOfPlApproved:0,
    numberOfPlReturned:0
  };

  approvalEnum: typeof ApprovalState = ApprovalState;

  state = new State();

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private userService: UserService) {

    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userReference = data.jti;
    this.authorities = data.userAuthorises;
    this.roles = data.roles;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
  }

  ngOnInit() {
    this.getBGIProjectsCount();
    this.getMyProjectsCount();
    this.getPLsCount();
    this.getMyPLsCount();
    this.getMyPlBoreholesCount();
    this.getNonPlBoreholesCount();
    this.getUserTrialPitCount();
    this.getUserProjectActivities();
    this.getSubmittedToQA();
    this.getSubmittedToQAPL();
    this.getSubmittedToManagerPl();
    this.getSubmittedToManager();
    this.getActivitiesRejectedByQA();
    this.getActivitiesRejectedByManager();
    this.getApprovedActivities();
    this.getNonBGIProjectsCount();
    this.getApprovedActivitiesPL();
    this.getUserDrafts();
    this.getUserPlDrafts();
    this.getActivitiesRejectedByQAPl();
    this.getPlcountsPerDistrict();
  }


  getPlcountsPerDistrict(){
      this.userDashBoardService.getMyProspectingLicensesActivitiesCountPerDistrict("all").subscribe(value => {
       this.plActivitiesPerDistrict = value;
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.numberOfMyProjects = number;
      this.myactivities.numberOfMyProjects = this.numberOfMyProjects;
      this.myproject.numberOfProjectsDrafts = this.numberOfMyProjects; // TODO  Project approval counts
    });
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.numberOfBGIProjects = number;
      this.myactivities.numberOfBGIProjects = this.numberOfBGIProjects;
    });
  }

  getNonBGIProjectsCount() {
    this.userDashBoardService.getNonBGIProjectCount().subscribe(number => {
      this.numberOfNonBGIProjects = number;
    });
  }

  getPLsCount(){
    this.userDashBoardService.getPLsCount().subscribe(number => {
      this.numberOfPLs = number;
      this.myplactivities.numberOfAllPl = this.numberOfPLs;
    });
  }
  getMyPLsCount(){
    this.userDashBoardService.getMyPLsCount(this.userName).subscribe(number => {
      this.numberOfMyPLs = number;
      this.myplactivities.numberOfMyPl = this.numberOfMyPLs;
      this.mypl.numberOfPlDrafts = number;// TODO prospecting licence approval counts
    });
  }

  getMyPlBoreholesCount(){
    this.userDashBoardService.getMyPlBoreholesCount(this.userName).subscribe(number => {
      this.numberOfMyPlBoreholes = number;
      this.myplactivities.numberOfActivities = number;
    });
  }

  getNonPlBoreholesCount(){
    this.userDashBoardService.getNonPlBoreholesCount(this.userName).subscribe(number => {
      this.numberOfNonPlBoreholes = number;
    });
  }

  getUserTrialPitCount(){
    this.userDashBoardService.getUserTrialPitCount(this.userName).subscribe(number => {
      this.numberOfUserTrialPits = number;
    });
  }

  getUserPlActivities(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount('all').subscribe(number => {
      this.numberOfActivities = number;
      this.myactivities.numberOfActivities = this.numberOfActivities;
    });
  }

  getUserProjectActivities(){
    this.userDashBoardService.getMyProjectActivitiesCount('all').subscribe(number => {
      this.numberOfActivities = number;
      this.myactivities.numberOfActivities = number;
    });
  }

  getUserDrafts(){
    this.userDashBoardService.getMyProjectActivitiesCount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(number => {
      this.numberOfDrafts = number;
      this.myactivities.numberOfDrafts = this.numberOfDrafts;
    });
  }

  getUserPlDrafts(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount(this.approvalEnum.PENDING_QA_SUBMISSION).subscribe(number => {
      this.myplactivities.numberOfDrafts = number;
    });
  }

  getSubmittedToQA(){
    this.userDashBoardService.getMyProjectActivitiesCount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
      this.numberOfProjectsActivitiesSubmittedToQA = number;
      this.myactivities.numberOfProjectsActivitiesSubmittedToQA = this.numberOfProjectsActivitiesSubmittedToQA;
    });
  }

  getSubmittedToQAPL(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount(this.approvalEnum.SUBMITTED_TO_QA).subscribe(number => {
      this.myplactivities.numberOfPlActivitiesSubmittedToQA = number;
    });
  }

  getSubmittedToManager(){
    this.userDashBoardService.getMyProjectActivitiesCount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.numberOfProjectsActivitiesSubmittedToManager = number;
      this.myactivities.numberOfProjectsActivitiesSubmittedToManager = this.numberOfProjectsActivitiesSubmittedToManager;
    });
  }

  getSubmittedToManagerPl(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount(this.approvalEnum.SUBMITTED_TO_MANAGER).subscribe(number => {
      this.myplactivities.numberOfPlActivitiesSubmittedToManager = number;
    });
  }

  getActivitiesRejectedByQA(){
    this.userDashBoardService.getMyProjectActivitiesCount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.numberOfProjectsActivitiesRejectedByQA = number;
      this.getActivitiesRejectedByManager();
    });
  }

  getActivitiesRejectedByQAPl(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount(this.approvalEnum.REJECTED_BY_QA).subscribe(number => {
      this.myplactivities.returned = number;
      this.getActivitiesRejectedByManagerPl();
    });
  }

  getActivitiesRejectedByManager(){
    this.userDashBoardService.getMyProjectActivitiesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.numberOfProjectsActivitiesRejectedByManager = number;
      this.myactivities.returned = this.numberOfProjectsActivitiesRejectedByManager+this.numberOfProjectsActivitiesRejectedByQA;
    });
  }

  getActivitiesRejectedByManagerPl(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount(this.approvalEnum.REJECTED_BY_MANAGER).subscribe(number => {
      this.myplactivities.returned = number+this.myplactivities.returned;
    });
  }

  getApprovedActivities(){
    this.userDashBoardService.getMyProjectActivitiesCount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.numberOfProjectsActivitiesApproved = number;
      this.myactivities.approved = this.numberOfProjectsActivitiesApproved;
    });
  }

  getApprovedActivitiesPL(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCount(this.approvalEnum.PUBLISHED).subscribe(number => {
      this.myplactivities.approved = number;
    });
  }

  goTo(type: string, filter: string) {
    this._router.navigate([`portal/activities/geologist/${type}/${filter}`]);
  }

  gotoProject(uri: string) {
    let urlParams: any;
    if('my_project' === uri){
      urlParams = { id: 'my_project' };
    }
    if('bgi_project' === uri){
      urlParams = { id: 'bgi_project' };
    }
    this._router.navigate([`portal/applied-geoscience/project/list`,urlParams]);
  }


  gotoProspectingLicences(uri: string) {
    let urlParams: any;
    if('my_pl'=== uri){
      urlParams = { id: 'my_pl' };
    }
    else {
      urlParams= {};
    }

    this._router.navigate([`portal/applied-geoscience/prospecting-license/list`,urlParams]);
  }

}
