import {Component, OnInit} from '@angular/core';
import {Session} from '../shared-kernel/services/usermanager/session';
import {Router} from '@angular/router';
import {UserService} from '../auth/user/user.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {User} from '../common/entity/security/user/user';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {EditUserProfileComponent} from './edit-user-profile/edit-user-profile.component';
import { Person } from '../_core/data/_models/people.model';
declare var $: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  public user: User;
  person: Person;
  email:string = '';
  password: string;
  newPassword: string;
  passValid: boolean;
  submitted: boolean = false;
  model: any = {};

  constructor(
    public userSession: Session,
    private dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private toastr: ToastrService) {

  }

  ngOnInit() {
      this.userSession.getLoggedPerson().contactDetails.forEach(contact => {
        if (contact.contactDetailType.name === 'email') {
          this.email = contact.address + ',' + this.email  ;
        }
      });

  }

  editUser() {
    const editUserDialogRef = this.dialog.open(EditUserProfileComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data:this.userSession.getLoggedPerson(),
    });


    editUserDialogRef.afterClosed().subscribe((result) => {
      if (result) {

      }
    });
  }

  errorsmsg() {
    this.toastr.error('password reset failed','Password Reset');
  }

  incorrect() {
    this.toastr.warning('password incorrect','Password Reset');
  }

  successmsg() {
    this.toastr.success('Success','Password Reset');
  }

  showModal(): void {
    $('#resetButton').click();
    $('#myModal').modal('show');
  }

  hideModal(): void {
    $('#resetButton').click();
    $('#myModal').modal('hide');
  }

  onSubmit() {
    this.submitted = true;
    let passwordStore: any = {
      username: this.userSession.getUsername(),
      newPassword:  this.model.newPassword,
      oldPassword: this.password ,
      isReset: true
    };
    this.userService.setUserPassword(passwordStore).subscribe(
      (data: any) => {
        if (data) {
          this.hideModal();
          this.successmsg();
        } else {
          this.incorrect();
        }

      },()=>{
        this.errorsmsg();
      });
  }





}
