import { Size } from '../../common/size';
import { Borehole } from '../../common/borehole';

export class WaterPumpingTestStepTest {
    id: String;
    stepNumber: string;
    pumpType: string;
    startDate: Date;
    endDate: Date;
    depthToWater: Size;
    drawdown: Size;
    startTime: Date;
    endTime: Date;
    staticWaterLevel: Number;
    pumpingRate: Size;
    timeToFillLitres: Size;
    timeToFillDuration: Number;
    geologist: string;
    comments: string;
    creator: string;
    created: Date;
    borehole:Borehole;
  }
