import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { GeoTechService } from '../geo-tech.service';
import { BoreholeLithologyLog } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/BoreholeLithologyLog';
import { CoreRecoveryLog } from '../../../shared-kernel/entity/applied-geoscience/geoTech/CoreRecoveryLog';
import { ToastrService } from 'ngx-toastr';
import { DiscontinuityLog } from '../../../shared-kernel/entity/applied-geoscience/geoTech/DiscontinuityLog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CoreService implements OnInit {

  tabPosition = 2;
  tabPositionRecovery = 3;

  constructor(private http: HttpClient, private router: Router,
              private geoTechService: GeoTechService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  errorsmsg() {
    this.toastr.error('Unsuccessful');
  }

  successmsg() {
    this.toastr.success('Success');
  }

  getAllBoreholes() {
    return this.geoTechService.getAllBoreholes();
  }

  getBoreholeById(borehole: any) {
    return this.geoTechService.getBoreholeById(borehole);
  }

  getCoreRecoveryById(coreRecoveryId: any) {
    return this.geoTechService.getCoreRecoveryById(coreRecoveryId);
  }

  getAllHardness() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllHardnessUrl);
  }

  getAllRockNames() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllRockNameUrl);
  }

  getAllWeatherings() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllWeatheringUrl);
  }

  getAllBoreholeLithologyLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholeLithologyLogsUrl);
  }

  getBoreholeLithologyLogById(boreholeLithologyLog: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeLithologyLogByIdUrl
      + '/' + boreholeLithologyLog);
  }

  getBoreholeByLithologyLogId(id: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeLithologyLogByIdUrl
      + '/' + id + '/borehole');
  }

  deleteBoreholeLithologLog(boreholeLithologyLog: BoreholeLithologyLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteBoreholeLithologyLog, boreholeLithologyLog);
  }

  getBoreholeCoreRecoveryLogs(boreholeId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholeLCoreRecoveryLogUrl + '/' + boreholeId);
  }

  getBoreholeLithologyLogByBoreholeId(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeLithologyLogByBoreholeIdUrl
      + '/' + boreholeId);
  }

  createBoreholeLithologyLog(boreholeLithologyLogs: BoreholeLithologyLog[], id: any): Observable<any> {
    const idObject = {id: id, tab: this.tabPosition};
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeLithologyLogUrl,
      boreholeLithologyLogs);
  }
  createBoreholeLithology(boreholeLithologyLog: BoreholeLithologyLog, id: any): Observable<any> {
    const idObject = {id: id, tab: this.tabPosition};
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeLithologyUrl+'/'+id,
      boreholeLithologyLog);
  }

  createBoreholeCoreRecoveryLog(coreRecoveryLogs: CoreRecoveryLog[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeCoreRecoveryLogUrl,
      coreRecoveryLogs);
  }

  deleteCoreRecoveryLog(coreRecoveryLog: CoreRecoveryLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteCoreRecoveryUrl, coreRecoveryLog);
  }

  getAllBoreholeCoreRecoveryDiscontinuityLog(runNumber: string, coreRecoveryId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholeCoreRecoveryDiscontinuityLogUrl
      + '/' + coreRecoveryId + '/' + runNumber);
  }

  createBoreholeCoreRecoveryDiscontinuityLog(discontinuityLog: DiscontinuityLog, runNumber: string) {
    this.spinner.show();
    const idObject = {run: runNumber, core: discontinuityLog.coreRecoveryLog.id};
    this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeCoreRecoveryDiscontinuityLogUrl,
      discontinuityLog).subscribe(
      response => {
        this.router.navigate(['portal/applied-geoscience/geo-tech/core/discontinuity/list', idObject]);
        this.successmsg();
        this.spinner.hide();
      },
      error => {
        this.errorsmsg();
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      }
    );
  }

  getAllDiscontinuityType() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDiscontinuityTypeUrl);
  }

  getAllMacroRoughness() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllMacroRoughness);
  }

  getAllMicroRoughness() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllMicroRoughness);
  }

  getAllFillTypeLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllFillTypeListUrl);
  }

  getAllSeparationLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSeparationLogsUrl);
  }

  getAllJointWallAlterationLogs() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllJointWallAlterationLogsUrl);
  }

  getAllSampleStatuses() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSampleStatusesUrl);
  }

  deleteBoreholeLithologLogBatch(boreholeLithologyLogs: BoreholeLithologyLog[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/borehole-lithology-log/delete/batch`, boreholeLithologyLogs);
  }

}
