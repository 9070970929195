import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit {

  public _dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  loading = false;
  @Input('loading') set loadingChange(val:boolean){
    this.loading = val;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input('actualColumns') actualColumns: Array<string> = [];
  @Input('title') title: string = '';
  @Input('total') total: any = 0;
  @Input('activitiesDataSource') set dataSource(val: MatTableDataSource<any>) {
    this._dataSource = val;
    if (this._dataSource) {
      this._dataSource.paginator = this.paginator;
    }
  }

  applyFilter(filterValue: string) {
    if (this._dataSource) {
      this._dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  getTotalCost() {
    return 0;
  }

  constructor() { }

  ngOnInit() {
  }

}
