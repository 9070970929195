import { Project } from '../applied-geoscience/project/project';
import { Location } from './location';
import { Notes } from '../applied-geoscience/notes';
import { DrillingMethod } from '../driller/drilling-method';
import { Size } from './size';
import { RigType } from './RigType';
import { BoreholeType } from './boreholeType';
import { BoreholeLithologyLog } from '../applied-geoscience/geoTech/BoreholeLithologyLog';
import {CoreRecoveryLog} from '../applied-geoscience/geoTech/CoreRecoveryLog';
import { WaterPumpingTestStepTest } from '../applied-geoscience/hydrogeology/WaterPumpingTestStepTest';
import { FileToUpload } from 'src/app/common/uploaded-files/entity/file';
import { TimeAndMotion } from 'src/app/driller/timeandmotion/entity/timeandmotion';
import { WaterDrillersLogRecord } from 'src/app/applied-geoscience/geo-tech/borehole/water-drillers-log/entity/waterdrillerslog';
import { DrillerLogRecord } from '../applied-geoscience/drillerlog/drillerlogrecord';
import { SampleType } from './sample-type';
import { Company } from '../applied-geoscience/company/company';
import { ProspectingLicense } from '../applied-geoscience/prospecting-license/prospecting-license';
import { DiameterFormation } from 'src/app/common/diameter-formation/entity/diameterFormation';
import { PenetrationRateLog } from 'src/app/common/penetration-rate/entity/penetrationRate';
import {NigisImages} from './nigisImages';
import {CasingLog} from '../applied-geoscience/hydrogeology/CasingLog';
import {ScreeningLog} from '../applied-geoscience/hydrogeology/ScreeningLog';
import {DrillingProtocol} from '../driller/DrillingProtocol';
import {WaterBearingZones} from '../applied-geoscience/hydrogeology/WaterBearingZones';
import { CoordinatesFormat } from './coordinatesValidator/coordinatesFormat';
import { LocationTemp } from './locationTemp';
import { DrillingRequestBorehole } from '../driller/drilling-request-borehole';

export class Borehole {
  id: string;
  boreholeNumber: string;
  project: Project = new Project();
  locationTemp: LocationTemp = new LocationTemp();
  locationDescription: Notes = new Notes();
  wellfield: string;
  startDate: Date;
  completedDate: Date;
  rigType: RigType;
  boreholeDescription: Notes = new Notes();
  boreholeObjective: Notes = new Notes();
  comments: Notes = new Notes();
  drillingMethod: DrillingMethod = new DrillingMethod();
  boreholeType: BoreholeType = new BoreholeType();
  descriptionOfDrillingFluidsUsed: string;
  developmentType: string;
  typeOfDev: string;
  driller: string;
  client: string;
  dip: string;
  consultant: string;
  statusAtEndOfDrilling: string;
  azimuth: number;
  casingHeight: Size = new Size();
  staticWaterLevel: Size = new Size();
  elevation: Size = new Size();
  endDepth: Size = new Size();
  plannedDepth: Size = new Size();
  corePicture: string;
  boreholeLithologyLogs: BoreholeLithologyLog[];
  boreholeCoreRecoveryLogs: CoreRecoveryLog[];
  waterPumpingTestStepTest: WaterPumpingTestStepTest[];
  boreholeFiles: FileToUpload[];
  boreholeLogRecords: DrillerLogRecord[];
  waterBoreholeLithologyLogs: BoreholeLithologyLog[];
  diameterFormations: DiameterFormation[];
  timeAndMotions: TimeAndMotion[];
  waterBoreholeDrillersLogs: WaterDrillersLogRecord[];
  waterPumpingTests: WaterPumpingTestStepTest[];
  constructionCasings: CasingLog[];
  constructionScreenings: ScreeningLog[];
  drillingProtocols: DrillingProtocol[];
  waterBearingZonesLogs: WaterBearingZones[];
  sampleTypes: SampleType[];
  waterStrike: Size = new Size();
  estimatedYield: Size = new Size();
  holeInclination: number;
  depthOfSurfaceCasing: Size = new Size();
  projectManager: string;
  projectCoordinator: string;
  projectGeologist: string;
  boreholeLocationImages: NigisImages[];
  holeDiameter: Size = new Size();
  boreholeOwner: Company = new Company();
  overburdenThickness: string;
  prospectingLicense: ProspectingLicense;
  penetrationRateLogs: PenetrationRateLog[];
  samplesCollected: string;
  active: string;
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
  currentState: any;
  statusHistory: any[];
  ownerType: number;
  boreholeOwnerIndividual: string;
  boreholeOwnerIndividualFullName: string;
  creator: string;
  variance: string;
  drillRigType: string;
  drillingRequestBorehole: DrillingRequestBorehole = new DrillingRequestBorehole();
  holeDiameterString: string;
}
