import {Size} from '../common/size';
import {DrillingMethod} from './drilling-method';
import {Borehole} from '../common/borehole';

export class DrillingProtocol {
  id: string;
  depthFrom: Size = new Size();
  depthTo: Size = new Size();
  diameter: Size = new Size();
  inclination: Size = new Size();
  azimuth: Size = new Size();
  drillingMethod: DrillingMethod = new DrillingMethod();
  borehole: Borehole = new Borehole();
  deleted: boolean;
}
