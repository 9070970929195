import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ServiceEndPoints } from '../shared-kernel/apiCalls/ServiceEndPoints';
import { DrillingChecklist } from '../shared-kernel/entity/driller/DrillingChecklist';
import { DrillingProtocol } from '../shared-kernel/entity/driller/DrillingProtocol';
import { DrillingRequestResponseNote } from '../shared-kernel/entity/driller/DrillingRequestResponseNote';
import { Observable } from 'rxjs';
import { DrillingRequest, DrillingRequestApproveAction } from '../shared-kernel/entity/driller/drilling-request';

@Injectable({
  providedIn: 'root'
})
export class DrillerService implements OnInit {

  constructor(private http: HttpClient, private router: Router) {

  }

  ngOnInit(): void {
  }

  getAllDrillingMethods() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDrillingMethodsUrl);
  }

  getAllSampleTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSampleTypesUrl);
  }

  getAllDrillingRequests() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDrillingRequestsUrl );
  }

  getDrillingRequestsByProjectId(projectId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDrillingRequestByProjectIdUrl + '/' + projectId);
  }

  getDrillingRequestById(drillingRequestId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDrillingRequestByIdUrl + '/' + drillingRequestId);
  }

  createDrillingRequest(drillingRequest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createDrillingRequestUrl,
      drillingRequest);
  }

  createDrillingRequestBorehole(drillingRequestBorehole) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createDrillingRequestBoreholeUrl,
      drillingRequestBorehole);
  }

  getDrillingRequestBoreholeById(drillingRequestBoreholeId) {
    let tempApiUrl :string =  ServiceEndPoints.getDrillingRequestBoreholeByIdUrl;
    let apiUrl = tempApiUrl.replace('{borehole_id}',drillingRequestBoreholeId);
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + apiUrl);
  }

  getBoreholesByDrillingRequestId(drillingRequestId) {
    let tempApiUrl :string =  ServiceEndPoints.getDrillingRequestBoreholeByDrillingRequestIdUrl;
    let apiUrl = tempApiUrl.replace('{drilling_request_id}',drillingRequestId);
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + apiUrl);
  }

  getDrillingRequestBoreholeCount(drillingRequestId: string): Observable<number> {
    return this.http.get<number>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/driller/v1/drilling-requests/${drillingRequestId}/boreholes/count`);
  }


  createDrillingChecklist(drillingChecklist: DrillingChecklist) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createDrillingChecklistUrl, drillingChecklist);
  }

  getAllDrillerChecklist() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDrillingChecklistUrl);
  }

  getChecklistsByDrillingRequestId(drillingRequestId: any): Observable<DrillingChecklist[]> {
    return this.http.get<DrillingChecklist[]>(ServiceEndPoints.appliedGeoscienceBaseUrl +
      `/api/driller/v1/drilling-request/${drillingRequestId}/drilling-checklists`);
  }

  getAllDrillingProtocols() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDrillingProtocolsUrl);
  }

  getAllDrillingProtocolsByBoreholeId(borehole: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.getAllDrillingProtocolsByBoreholeIdUrl + '/' + borehole);
  }

  createDrillingProtocol(drillingProtocol: DrillingProtocol) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createDrillingProtocolUrl, drillingProtocol);
  }

  batchCreateDrillingProtocol(drillingProtocol: DrillingProtocol []) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.batchCreateDrillingProtocolUrl, drillingProtocol);
  }

  getAllDrillingRequestNotesByRequestId(requestId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDrillingRequestNotesByRequestIdUrl + '/' + requestId);
  }

  createDrillingRequestResponseNote(drillingRequestResponseNote: DrillingRequestResponseNote) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createDrillingRequestResponseNoteUrl, drillingRequestResponseNote);
  }

  changeState(action: DrillingRequestApproveAction, drillingRequestId: string, comment: string = null): Observable<DrillingRequest> {
    return this.http.post<DrillingRequest>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/driller/v1/drilling_requests/${drillingRequestId}/approve_states`, { action, comment });
  }

  drillingBoreholeNumberAlreadyInProject(boreholeNumber, projectId) {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/driller/v1/drilling-request-borehole/check/${boreholeNumber}/project/${projectId}`);
  }

  batchDeleteDrillingProtocol(drillingProtocols: DrillingProtocol []) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +`/api/driller/v1/drilling-protocol/delete/batch`, drillingProtocols);
  }
}
