import { Component, Input } from "@angular/core";

@Component({
    selector: "drill-comments",
    templateUrl: './drill-comments.html',
    styleUrls: ['./drill-comments.scss']
})
export class DrillCommentsComponent {
    public statesList: Array<any>;

    @Input('history') set history(val: any[]) {
        this.statesList = this.filterOutAndSort(val);
    }

    constructor () {}

    private filterOutAndSort(history: any[]): any[] {
        let filtered: any[] = [];

        history.forEach(h => {
            if (h.comments && h.comments.trim() !== '') {
                filtered.push(h);
            }
        });

        return filtered.sort((a, b) => new Date(a.chagedAt).getTime() - new Date(b.chagedAt).getTime());
    }
}