import { FormGroup } from "@angular/forms";
import { Directive, Input } from '@angular/core';
// custom validator to check that two fields match
@Directive({
  selector: '[ngInit]',
  exportAs: 'ngInit'
})
export class NgInit {
  @Input() values: any = {};

  @Input() ngInit;
  ngOnInit() {
    if(this.ngInit) { this.ngInit(); }
  }
}
