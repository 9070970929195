import {Injectable} from '@angular/core';
import {Roles} from '../../entity/Roles';
import {UserService} from '../../../auth/user/user.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LocalStorageManagerService} from '../../storage/local-storagemanager.service';
import { Person, User } from '../../../_core/data/_models/people.model';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorageConstants } from '../../../_core/storage.constants';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../../../_auth/_providers';

@Injectable()
export class Session {

  private userName: string;
  private authorities;
  private roles;
  person: Person;
  public currentUser: User;
  public currentPerson: Person;

  constructor(private userService: UserService,  private storageManager: LocalStorageManagerService,private _localAuthorities: LocalAuthorities,
              private _localStorage: LocalStorage,private jwtHelper: JwtHelperService,private _toastr: ToastrService) {

    /*const data = this.jwtHelper.decodeToken(storageManager.getToken());
    this.userName = data.sub;
    this.authorities = data.userAuthorises;
    this.roles = data.roles;
    this.userService.getPersonByUserId(data.jti)
      .subscribe(data => {
        this.person = data as Person;
      });*/


    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;

      this.initPerson(res.id);
    }, err => {
      this._toastr.warning(err.message, "Current User Error");
    });
  }


  private initPerson(userId: string) {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    });
  }

  getLoggedPerson(): Person {
    return this.currentPerson;
  }

  getUsername() {
    return this.currentPerson.user.username;
  }

  getRoles(){
    return this.roles;
  }

  checkAdminRights() {
    for (const value in this.roles) {
      if (this.roles[value].name === Roles.SYSTEM_ADMIN) {
        return true;
      } else {
        return false;
      }
    }
  }

}
