import { Activity } from './../../../activities/models/activity.model';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-manager-activities-dashboard-table',
  templateUrl: './manager-activities-dashboard-table.component.html',
  styleUrls: ['./manager-activities-dashboard-table.component.scss']
})
export class ManagerActivitiesDashboardTableComponent implements OnInit {

  loading = true;
  displayedColumns: Array<string> = ['number', 'name', 'geologist', 'date'];
  public _dataSource: MatTableDataSource<Activity> = new MatTableDataSource<Activity>();

  get dataSource(): MatTableDataSource<Activity> {
    this.loading = false;
    return this._dataSource;

  }

  @Input('activitiesDataSource') set dataSource(val: MatTableDataSource<Activity>) {
    this._dataSource = val;
    if (this._dataSource) {
      this.loading = false;
      this._dataSource.paginator = this.paginator;
    }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  title: string

  constructor() { }

  ngOnInit(): void {

  }

  applyFilter(filterValue: string) {
    this._dataSource.filter = filterValue.trim().toLowerCase();
  }

}
