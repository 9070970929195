export class CollectionsState {
  PENDING_SENIOR_ARCHIVIST_SUBMISSION = {label: 'Draft', color: 'blue', status: 'PENDING_SENIOR_ARCHIVIST_SUBMISSION'};
  SUBMITTED_TO_SENIOR_ARCHIVIST = {label: 'Submitted-Senior Archivist', color: 'orange', status: 'SUBMITTED_TO_SENIOR_ARCHIVIST'};
  REJECTED_BY_SENIOR_ARCHIVIST = {label: 'Returned-Senior Archivist', color: 'red', status: 'REJECTED_BY_SENIOR_ARCHIVIST'};
  APPROVED = {label: 'Approved', color: 'green', status:'APPROVED'};
  PENDING_MANAGER_SUBMISSION = {label: 'Draft', color: 'blue', status: 'PENDING_MANAGER_SUBMISSION'};
  SUBMITTED_TO_MANAGER = {label: 'Submitted-Manager', color: 'orange', status: 'SUBMITTED_TO_MANAGER'};
  REJECTED_BY_MANAGER = {label: 'Returned-Manager', color: 'red', status: 'REJECTED_BY_MANAGER'};
  APPROVED_MANAGER = {label: 'Approved', color: 'green', status:'APPROVED_MANAGER'};
  PENDING_SPECIMEN_MANAGER_SUBMISSION = {label: 'Draft', color: 'blue', status: 'PENDING_SPECIMEN_MANAGER_SUBMISSION'};
  SUBMITTED_SPECIMEN_TO_MANAGER = {label: 'Submitted-Manager', color: 'orange', status: 'SUBMITTED_SPECIMEN_TO_MANAGER'};
  REJECTED_SPECIMEN_BY_MANAGER = {label: 'Returned-Manager', color: 'red', status: 'REJECTED_SPECIMEN_BY_MANAGER'};
  APPROVED_SPECIMEN_MANAGER = {label: 'Approved', color: 'green', status:'APPROVED_SPECIMEN_MANAGER'};

  PENDING_SENIOR_CURATOR_SUBMISSION = {label: 'Draft', color: 'blue', status: 'PENDING_SENIOR_SUBMISSION'};
  SUBMITTED_TO_SENIOR_CURATOR = {label: 'Submitted-Senior', color: 'blue', status: 'SUBMITTED_TO_SENIOR_CURATOR'};
  NO_STATUS = {label: 'No Status', color: 'gray', status: 'NO_STATUS'};
  REJECTED_BY_SENIOR_CURATOR = {label: 'Returned-Senior', color: 'red', status: 'REJECTED_BY_SENIOR_CURATOR'};
  SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR = {label: 'Submitted-Manager', color: 'blue', status: 'SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR'};
  //APPROVED = {label: 'Approved', color: 'green', status:'APPROVED'};
}
