import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrillerService } from '../../../driller.service';
import { DrillingRequestBorehole } from '../../../../shared-kernel/entity/driller/drilling-request-borehole';
import { SampleType } from '../../../../shared-kernel/entity/common/sample-type';

@Component({
  selector: 'app-drilling-request-borehole-details',
  templateUrl: './drilling-request-borehole-details.component.html',
  styleUrls: ['./drilling-request-borehole-details.component.scss']
})
export class DrillingRequestBoreholeDetailsComponent implements OnInit {

  drillingRequestBoreholeId: string;
  drillingRequestBoreholeDetails : DrillingRequestBorehole = new DrillingRequestBorehole();
  loading : boolean;
  sampleTypes: SampleType [];
  boreholeSampleTypes: string = '';

  constructor(private pageRoute: ActivatedRoute,private drillerService: DrillerService,private router: Router,) {
    this.pageRoute.params.subscribe(params => {
      this.drillingRequestBoreholeId = params.id;
    });

  }

  ngOnInit() {
    this.getDrillingRequestBoreholeDetails();
    this.getAllSampleTypes();
  }

  getAllSampleTypes() {
    this.drillerService.getAllSampleTypes()
      .subscribe(data => {
        this.sampleTypes = data as SampleType[];
      });
  }

  compareFn(objectOne: any, objectTwo: any): boolean {
    return objectOne && objectTwo ? objectOne.id === objectTwo.id : objectOne === objectTwo;
  }


  navBack() {
    const urlParams = {id: this.drillingRequestBoreholeDetails.drillingRequest.id};
    this.router.navigate(['portal/applied-geoscience/project/detail', urlParams]);
  }

  getDrillingRequestBoreholeDetails(){
    this.drillerService.getDrillingRequestBoreholeById(this.drillingRequestBoreholeId)
      .subscribe(data => {
      this.drillingRequestBoreholeDetails = data as DrillingRequestBorehole;
        this.drillingRequestBoreholeDetails.sampleTypes.forEach(value => {

          this.boreholeSampleTypes = value.name +','+ this.boreholeSampleTypes;
        });

    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
  }

