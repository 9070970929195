import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ManagerDashboardCountModel} from '../model/manager-dasboard-count.model';
import {VettingPortfolioCounts} from '../model/VettingPortfolioCounts';
import {GlobalDashboardService} from '../global-dashboard.service';
import {MatTableDataSource} from '@angular/material/table';
import {DrillingRequestModel} from '../../activities/models/drilling-request.model';
import {DrillingRequestStage} from '../../shared-kernel/entity/driller/drilling-request';
import {ActivitiesService} from '../../activities/services/activities.service';
import {DrillerService} from '../../driller/driller.service';
import { StorageConstants } from '../../_core/storage.constants';
import { ToastrService } from 'ngx-toastr';
import { LocalAuthorities } from '../../_auth/_providers';
import { User } from '../../_core/data/_models/people.model';
import { Person } from '../../common/entity/security/profile/person';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BusinessUnitEnum } from '../../shared-kernel/enumerations/business-unit-enum';

@Component({
  selector: 'app-superintendent-dashboard',
  templateUrl: './superintendent-dashboard.component.html',
  styleUrls: ['./superintendent-dashboard.component.scss']
})
export class SuperintendentDashboardComponent implements OnInit {


  public currentUser: User;
  public currentPerson: Person;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;

  constructor(private _router: Router,
              private _toaster: ToastrService,
              private _localAuthorities: LocalAuthorities,private _localStorage: LocalStorage,
              private _activitiesServ: ActivitiesService, private drillerService: DrillerService) {
  }

  ngOnInit() {
  }


}
