import {Project} from '../project/project';
import {Location} from '../../common/location';
import { LocationTemp } from '../../common/locationTemp';
import {Notes} from '../notes';

export class DynamicConePenetrometerTest {
  id: string;
  project: Project;
  testNumber: string;
  testLevel: string;
  comment: Notes = new Notes();
  elevation: string;
  hammerWeight: string;
  locationDescription: string;
  surfaceType: string;
  date: Date;
  locationTemp: LocationTemp = new LocationTemp();
  coordinator: string;
  manager: string;
  geologist: string;

}
