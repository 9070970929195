import { Authority } from '../authority/authority';
import { BusinessUnit } from './business-unit';

export class SystemRole {

  public id: string;
  public name: string;
  public description: string;
  public authorities: Authority[];
  public businessUnit: BusinessUnit;

  constructor() {  }
}
