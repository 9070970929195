import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

import { ProjectMembers } from './../project-member.model';

@Component({
    selector: 'pr-member-list',
    templateUrl: './member-list.component.html',
    styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent {
    displayedColumns: Array<string> = ['#', 'MemberName', 'ProjectRole', 'Delete'];
    dataSource: MatTableDataSource<ProjectMembers>;

    @Input('members') set members(val: Array<ProjectMembers>) {
        this._initTable(val);
    }
    @Output('onDeleteMember') onDeleteMember: EventEmitter<string> = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor() {}

    deleteMember(member) {
        this.onDeleteMember.emit(member.id);

    }

    private _initTable(projectMembers: Array<ProjectMembers>) {
        this.dataSource = new MatTableDataSource(projectMembers);
        this.dataSource.paginator = this.paginator;
    }
}
