import { UploadedFilesComponent } from './../uploaded-files.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatChipInputEvent, MatTableDataSource, MatDialogConfig } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileToUpload } from '../entity/file';
import { Link } from '../entity/link';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UploadedFilesService } from '../uploaded-files.service';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  uploadedFileList: FileToUpload[];
  documentsForm: FormGroup;
  selectedFile: File = null;
  selectedFiles: FileList;
  edit = false;
  modalHeader = 'Upload File';
  formData: FormData;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  researchLinks: Link[] = [];

  displayName = 'Browse for a file';

  selectedDocument: FileToUpload = new FileToUpload();
  fileToBeUploaded: FileToUpload = new FileToUpload();
  progress: { percentage: number } = { percentage: 0 };
  isNewUpload = false;
  uploadedFileDatasource: MatTableDataSource<FileToUpload> = new MatTableDataSource();
  displayedColumns = ['#', 'Name', 'Description', 'File Type', 'Creation Date', 'Action'];
  loading = true;
  entityId: string;

  constructor(private formBuilder: FormBuilder,
    private fileUploadService: UploadedFilesService,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<UploadedFilesComponent>,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.initForms();
    this.entityId = this.data.id;
    this.modalHeader = this.data.modalHeader;
    this.edit = this.data.edit;
    if (this.edit) {
      this.populateForm();
    }
  }

  populateForm() {
    this.fileToBeUploaded = this.data.fileToBeUploaded;
    this.researchLinks = this.fileToBeUploaded.links;
    const documentData = {
      'browse': this.fileToBeUploaded.fileName + '.' + this.fileToBeUploaded.type,
      'upload': '',
      'name': this.fileToBeUploaded.fileName,
      'description': this.fileToBeUploaded.description,
      'links': this.fileToBeUploaded.links
    };
    this.documentsForm.setValue(documentData);
  }


  private initForms() {
    this.documentsForm = this.formBuilder.group({
      browse: ['', Validators.required],
      upload: [null, Validators.required],
      name: ['', Validators.required],
      description: [''],
      links: ['' ]
    });

  }

  attachFile() {
    $('#browseFile').click();
  }
  CloseDialog() {
    this.dialogRef.close();
  }

  addUrl(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const link = new Link;
    link.uri = value;

    if ((value || '').trim()) {
      this.researchLinks.push(link);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeUrl(rLinks: any): void {
    const index = this.researchLinks.indexOf(rLinks);

    if (index >= 0) {
      this.researchLinks.splice(index, 1);
    }
  }
  getFileExtFromName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  fileChangeListener(files: FileList) {
    this.progress.percentage = 0;
    this.selectedFile = files.item(0);
    this.documentsForm.get('browse').setValue(this.selectedFile.name);
    this.fileToBeUploaded.type = this.getFileExtFromName(this.selectedFile.name);

  }


  getAllFilesByKey(boreholeId: string) {
    this.fileUploadService.getAllFilesByKey(boreholeId).subscribe(response => {
      this.uploadedFileList = response as FileToUpload[];
      this.uploadedFileDatasource.data = this.uploadedFileList;
      this.loading = false;
    },
      error => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }
  onSubmit() {
    if (this.edit) {
      const documentData = this.documentsForm.value;
      this.fileToBeUploaded.name = documentData['name'];
      this.fileToBeUploaded.description = documentData['description'];
      this.fileToBeUploaded.links = documentData['links'];
      this.fileUploadService.createFile(this.fileToBeUploaded).subscribe(response => {
        this.getAllFilesByKey(this.entityId);
        this.displayName = 'File Name';
      },
        error => {
        }, () => {
          this.CloseDialog();
          this.toaster.success('File edited successfully', 'File Edit');
        });
    } else if (this.edit && this.isNewUpload) { // when editing file and/or its deatils
      this.formData = new FormData();
      this.formData.append('file', this.selectedFile);
      this.fileToBeUploaded.fileName = this.selectedFile.name;
      this.fileUploadService.uploadFileToServer(this.formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.fileToBeUploaded.uri = event.body.uri;
          this.fileToBeUploaded.size = event.body.size;
          this.fileToBeUploaded.fileName = event.body.name;
          this.fileToBeUploaded.links = this.researchLinks;
          this.fileUploadService.createFile(this.fileToBeUploaded).subscribe(response => {
            this.getAllFilesByKey(this.entityId);
            this.selectedFile = null;
            this.displayName = 'File Name';
          },
            error => {
            }, () => {
              this.CloseDialog();
            });
        }
      }, error => {
        this.toaster.error('File upload has failed, please close the dialog and try again...', 'File Upload Failure');
      },
        () => {
          this.toaster.success('File has been successfully uploaded', 'File Upload');
        });
    } else { // fresh upload

      const documentsData = this.documentsForm.value;
      this.formData = new FormData();
      this.formData.append('file', this.selectedFile);
      this.fileUploadService.uploadFileToServer(this.formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.fileToBeUploaded.uri = event.body.uri;
          this.fileToBeUploaded.size = event.body.size;
          this.fileToBeUploaded.fileName = event.body.name;
          this.fileToBeUploaded.links = this.researchLinks;
          this.fileToBeUploaded.fileMapperKey = this.entityId;
          this.fileToBeUploaded.fileName = documentsData.name;
          this.fileToBeUploaded.name = documentsData.name;
          this.fileToBeUploaded.description = documentsData.description;
          this.fileUploadService.createFile(this.fileToBeUploaded).subscribe(response => {
            this.getAllFilesByKey(this.entityId);
            this.selectedFile = null;
            this.displayName = 'File Name';
          },
            error => {
            }, () => {
              this.CloseDialog();
            });
        }
      }, error => {
        this.toaster.error('File upload has failed, please close the dialog and try again...', 'File Upload Failure');
      },
        () => {
          this.toaster.success('File has been successfully uploaded', 'File Upload');
        });

    }
  }


  downloadFile(file: FileToUpload) {
    this.fileUploadService.downloadFile(file.fileName).subscribe((response: any) => {
      const dataType = response.type;
      const binaryData = [];
      binaryData.push(response);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      if (file.fileName) {
        downloadLink.setAttribute('download', file.fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    }, error => {
      this.toaster.warning(file.fileName + ' could not be found on the server');
    }, () => {

    });
  }


}
