import {Injectable, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { Company } from 'src/app/shared-kernel/entity/applied-geoscience/company/company';
import { ContactDetail } from 'src/app/common/entity/security/util/common/contact-detail';
import { FormControl, Validators } from '@angular/forms';
import { Commodity } from 'src/app/shared-kernel/entity/applied-geoscience/project/commodity';
import { MineralType } from 'src/app/shared-kernel/entity/applied-geoscience/project/mineral-type';

@Injectable({
    providedIn: 'root'
})
export class ProjectValidation implements OnInit {
    company: Company;
    contactDetail: ContactDetail;

    validation_messages = {
        'name': [
          { type: 'required', message: 'Project name is required' }
        ],
        'company': [
          { type: 'required', message: 'Please select company' },
        ],
        'dateStarted': [
          { type: 'required', message: 'Project start date is required' },
        ],
        'dateCompleted': [
          { type: 'required', message: 'Project end date is required' },
        ],
        'objective': [
            { type: 'required', message: 'Project objective is required' },
          ],
        'hasDrillingRequest': [
            { type: 'required', message: 'Please Select one of the options' },
          ]
      };

  constructor(private toastr: ToastrService) {
  }

  ngOnInit() {}

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }


  validateContactDetailsAsTheyAreAdded(contactDetail: ContactDetail): boolean {
    this.contactDetail = contactDetail;
    if (!contactDetail.address) {
        this.errorsmsg('Please make sure contact is provided');
        return false;
    } else if (!contactDetail.contactDetailType) {
        this.errorsmsg('Please make sure contact type is provided');
        return false;
    } else {
        return true;
    }
  }

  projectDatesvalid(openDate: Date, endDate: Date): boolean {
      if (openDate >= endDate) {
        this.errorsmsg('Please make sure Project open date is BEFORE Project close date');
        return false;
      } else {
        return true;
      }
  }

  commodityAddedToListValid(commodity: Commodity, mineral: MineralType): boolean {
    if (!mineral.name) {
        this.errorsmsg('Please make sure mineral is selected');
        return false;
    } else if (!commodity.name) {
        this.errorsmsg('Please make sure commodity is selected');
        return false;
    } else {
        return true;
    }
  }

  commodityAddedToFormBeingSubmitted (commodities: Commodity[]): boolean {
      if (commodities.length === 0) {
        this.errorsmsg('Please make sure commodites have been added');
        return false;
      } else {
        return true;
      }
  }

  validateInteger(digit): boolean {
      if (isNaN(Number(digit))) {
          return true;
      } else {
        return false;
      }
  }
}
