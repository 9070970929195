import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Petrography } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/Petrography';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatAutocomplete, MatDialogRef, MatChipInputEvent } from '@angular/material';
import { NigisImages } from 'src/app/shared-kernel/entity/common/nigisImages';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ActivatedRoute } from '@angular/router';
import { Sample } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { GeoTechService } from 'src/app/applied-geoscience/geo-tech/geo-tech.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-petrography-test-create',
  templateUrl: './petrography-test-create.component.html',
  styleUrls: ['./petrography-test-create.component.scss']
})
export class PetrographyTestCreateComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PetrographyTestCreateComponent>,
    private geotechService: GeoTechService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
  }

  petrography: Petrography = new Petrography;
  crossSectionImagery: NigisImages[] = [];
  crossSectionImage: NigisImages;
  title: string;
  sampleId: string;
  sample: Sample;
  petrographyCreateForm: FormGroup;
  crossSectionImageCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  selectable = true;
  removable = true;
  addOnBlur = true;
  @ViewChild('crossSectionImageryInput') crossSectionImageryInput: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.spinner.hide();
    this.getSampleBySampleId();
    if (this.data.petrography) {
      this.title = 'Edit Petrography';
      this.petrography.id = this.data.petrography.id;
    } else {
      this.title = 'Create New Petrography';
    }
  }

  private initForms() {
    if(this.data.petrography) {
      this.crossSectionImagery = this.data.petrography.crossSectionImagery;
    }

    this.petrographyCreateForm = this.formBuilder.group({
      date: [this.data.petrography ? new Date(this.data.petrography.date) : null, Validators.required],
      sampleNumber: [{value: this.data.petrography ? this.data.petrography.sample.sampleNumber : this.sample.sampleNumber, disabled: true}, Validators.required],
      crossSectionImagery: [this.data.petrography ? this.data.petrography.crossSectionImagery : null, Validators.required],
      description: [this.data.petrography ? this.data.petrography.description.description : ''],
    });
  }

  getSampleBySampleId() {
    this.geotechService.getSampleById(this.data.sampleId).subscribe(data => {
      this.sample = data as Sample;
      this.initForms();

    }, error => {
    }, () => {
    });
  }

  onSubmit() {
    if(this.petrographyCreateForm.controls.date.valid) {
      this.spinner.show();
      this.petrography.date = this.petrographyCreateForm.controls.date.value;
      this.petrography.crossSectionImagery = this.crossSectionImagery;
      this.petrography.sample = this.sample;
      this.petrography.description.description = this.petrographyCreateForm.controls.description.value;
      this.petrography.deleted = false;
      this.geotechService.createPetrography(this.petrography).subscribe(
        value => {
        }
        , error => {
          if(this.title === 'Create New Petrography') {
            this.toastr.error(error, 'Petrography Test Create');
          } else {
            this.toastr.error(error, 'Petrography Test Update');
          }
          this.spinner.hide();
        }
        , () => {
          this.closeModal();
          this.spinner.hide();
          if(this.title === 'Create New Petrography') {
            this.toastr.success('Petrography Test Created Successfully', 'Petrography Test Create');
          } else {
            this.toastr.success('Petrography Test Updated Successfully', 'Petrography Test Update');
          }
        }
      );
      

    }
  }

  add(event: MatChipInputEvent): void {
    if(!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const chipCrossSectionImage = new NigisImages;
      chipCrossSectionImage.name = value;

      if(( value || '' ).trim()) {
        this.crossSectionImagery.push(chipCrossSectionImage);
      }

      if(input) {
        input.value = '';
      }

      this.crossSectionImageCtrl.setValue(null);
    }
  }

  remove(crossLocationImage: NigisImages): void {
    const index = this.crossSectionImagery.indexOf(crossLocationImage);
    if(index >= 0) {
      this.crossSectionImagery.splice(index, 1);
    }
  }

  onSelectFile(event) {
    if(event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.crossSectionImage = new NigisImages();
          this.crossSectionImage.image = fileSplitdata[1];
          this.crossSectionImage.name = fileName;
          this.crossSectionImagery.push(this.crossSectionImage);
          this.crossSectionImageCtrl.setValue(null);
        };
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
