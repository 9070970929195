import { Lightbox } from 'ngx-lightbox';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocImageService } from '../photos.service';
import Swal from "sweetalert2";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'doc-image-list',
    templateUrl: './photos-list.component.html',
    styleUrls: ['./photos-list.component.scss']
})
export class PhotosListComponent implements OnInit {
    expand: boolean = false;
    selected: boolean = false;
    checked: boolean = false;
    album: any = [];

    @Input('linkedEntity') linkedEntity;
    @Input('currentState') currentState;
    @Input('entityCreator') entityCreator;
    @Input('tagging') tagging;
    @Input('projectId') projectId;

    @Output() onUpload = new EventEmitter<boolean>()

    imagesList: any[];
    imagesListDel: any[] = [];

    constructor( private toaster: ToastrService,
        private _docImgServ: DocImageService,
        private lightbox: Lightbox,
    ) {
    }

    ngOnInit() {
        this._getImages();
    }

    upload() {
        this.onUpload.emit(true);
    }


    changeAll(event) {
      this.selected = event;
      this.checked = !this.checked;
      if(event){
        this.imagesListDel.push(...this.imagesList);
      }else{
        this.imagesListDel=[];
      }

    }

    change(event,item) {
      if(event){
        this.selected = event;
        this.imagesListDel.push(item);
      }
      else{
        this.imagesListDel=this.imagesListDel.filter(value => value!==item);
      }
    }

    deleteImage(id: string) {
      Swal.fire({
        title: 'Delete Image',
        text: 'Are you sure you want to delete this image?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it!',
      }).then(result => {
        if (result.value) {
          this._docImgServ.deleteImageFile(id).subscribe(res => {
            this._getImages();
            this.toaster.success('Image deleted successfully', 'Delete Image');
          });
        }
      });
    }

    public refresh() {
        this._getImages();
    }

    onDeleteAll(){
      Swal.fire({
        title: 'Delete Image List',
        text: 'Are you sure you want to delete this images?',
        type:  'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it!',
      }).then(result => {
        if (result.value) {
          this._docImgServ.batchDeleteImageFile(this.imagesListDel).subscribe(res => {
            this._getImages();
            this.toaster.success('Image list deleted successfully', 'Delete Images');
          });
        }
      });
    }

    toggleExpand() {
        this.expand = !this.expand;
    }

    _getImages() {
        this._docImgServ.getEntityImages(this.linkedEntity, this.tagging).subscribe(res => {
            this.imagesList = res;
            for (let image of this.imagesList) {

              let albumImage: any = {};

              albumImage.src = image.uri;

              albumImage.caption = image.fileName;

              albumImage.thumb = image.uri;

              this.album.push(albumImage);

            }
        });
    }

    open(index: number): void {
      this.lightbox.open(this.album, index);

    }
}
