import { Component, Inject, Input, OnInit } from '@angular/core';

import { nanoid } from 'nanoid';
import { Observable, of } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UploadFileResponse } from './../../documents/nigis-doc.model';
import { DocumentsService } from './../../documents/dococument.service';
import { DocImageService } from '../photos.service';
import { NigisDocImage } from '../nigi-image.model';

export interface ImageFile {
    id?: string;
    dataUrl?: string | ArrayBuffer;
    filename?: string;
    file: File;
    state: number;
}

@Component({
    selector: 'photos-upload',
    templateUrl: 'photos-upload.component.html',
    styleUrls: ['./photos-upload.component.scss']
})
export class PhotosUploadComponent implements OnInit {
    filesToUpload: ImageFile[] = [];
    uploading: boolean = false;

    linkedEntity: string;
    tagging: string;

    constructor(
        public dialogRef: MatDialogRef<PhotosUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _docServ: DocumentsService,
        private _docImgServ: DocImageService
    ) {}

    ngOnInit() {
        this.linkedEntity = this.data.linkedEntity,
        this.tagging = this.data.tagging;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    onSelectFile(files: FileList) {
        if (files.length > 0) {
            const numOfFiles = files.length;

            for (let i = 0; i < numOfFiles; i++) {
                const arrFile = files.item(i);

                let currFile: ImageFile = {
                    id: nanoid(),
                    file: arrFile,
                    filename: arrFile.name,
                    state: 0
                }

                const reader = new FileReader();

                reader.onload = (event) => {
                    currFile.dataUrl = (<FileReader>event.target).result;
                    this.filesToUpload.push(currFile);
                }

                reader.readAsDataURL(arrFile);
            }
        }
    }

    onImageDelete(imageId: string) {
        const found = this.filesToUpload.find(f => f.id === imageId);

        if (found) {
            this.filesToUpload.splice(this.filesToUpload.indexOf(found), 1);
        }
    }

    async onUpload() {
        this.uploading = true;
        this.filesToUpload.forEach(f => {
            if (f.state !== 1) { f.state = 2 }
        });

        for (let i = 0; i < this.filesToUpload.length; i++) {
            try {
                if (this.filesToUpload[i].state != 1) {
                    const res = await this.uploadImage(this.filesToUpload[i]).toPromise();
                    const final: UploadFileResponse = res.body;

                    let metaObj: NigisDocImage = {
                        fileName: this.filesToUpload[i].filename,
                        uri: final.fileDownloadUri,
                        type: final.fileType,
                        deleted: false,
                        linkedEntity: this.linkedEntity,
                        tagging: this.tagging
                    };
                    await this.uploadImageMeta(metaObj).toPromise();

                    this.filesToUpload[i].state = 1;
                }
            } catch (err) {
                this.filesToUpload[i].state = 3;
            }
        }

        this._finish();
    }

    uploadImage(image: ImageFile): Observable<any> {
        let formData = new FormData();
        formData.append("file", image.file);

        return this._docServ.uploadFile(formData);
    }

    uploadImageMeta(data: NigisDocImage): Observable<any> {
        return this._docImgServ.addFileDetails(data);
    }

    _finish() {
        let close = true;

        this.filesToUpload.forEach(f => {
            if (f.state != 1) close = false;
        });

        if (close === true) {
            this.dialogRef.close(this.filesToUpload);
        }

        this.uploading = false;
    }
}