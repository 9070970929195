import { LocalAuthorities } from './../../../_auth/_providers/local-authorities';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'collections-activity-comment',
    templateUrl: './activity-comment.component.html',
    styleUrls: ['./activity-comment.component.scss']
})
export class CollectionsActivityCommentComponent {
    officer: any;
    state: any;

    @Input('state') set s(val: any) {
        this.state = val;

        this._localAuthorities.getPersonByUsername(val.creator).subscribe(res => {
            this.officer = res;
        });
    }

    constructor(
        private _localAuthorities: LocalAuthorities
    ) {}

    getTitle(currentState: string) {
        switch(currentState) {
            case 'REJECTED_BY_SENIOR_ARCHIVIST':
                return 'Senior Archivist';
            case 'APPROVED':
                return 'Senior Archivist';
            case 'PENDING_SENIOR_ARCHIVIST_SUBMISSION':
                return 'Archivist';
            case 'SUBMITTED_TO_SENIOR_ARCHIVIST':
                return 'Archivist';
            case 'REJECTED_BY_MANAGER':
                return 'Manager';
            case 'APPROVED_MANAGER':
                return 'Manager';
            case 'PENDING_MANAGER_SUBMISSION':
                return 'Senior Archivist';
            case 'SUBMITTED_TO_MANAGER':
                return 'Senior Archivist';
            case 'REJECTED_SPECIMEN_BY_MANAGER':
                return 'Manager';
            case 'APPROVED_SPECIMEN_MANAGER':
                return 'Manager';
            case 'PENDING_SPECIMEN_MANAGER_SUBMISSION':
                return 'Senior Curator';
            case 'SUBMITTED_SPECIMEN_TO_MANAGER':
                return 'Senior Curator';
            default:
                return 'Title Here';
        }
    }
}
