import { StorageConstants } from './../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { LocalAuthorities } from './../../../_auth/_providers';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../../auth/user/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User, Person } from './../../../_core/data/_models/people.model';
import { Router } from '@angular/router';
import { GlobalDashboardService } from './../../global-dashboard.service';
import { BusinessUnitEnum } from './../../../shared-kernel/enumerations/business-unit-enum';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-project-dashboard',
  templateUrl: './manager-project-dashboard.component.html',
  styleUrls: ['./manager-project-dashboard.component.scss']
})
export class ManagerProjectDashboardComponent implements OnInit {

  projectDashboardCounts = {
  projectSubmittedToManagerCount: 0,
  projectReturnedToUserCount: 0,
  projectApprovedByManagerCount: 0,
  allProjectsVettedByManagerCount: 0
  }

  pendingCountStillLoading = true;
  returnedCountStillLoading = true;
  approvedCountStillLoading = true;
  vettedCountStillLoading = true;

  public currentUser: User;
  public currentPerson: Person;
  public currentLoggedPerson: Person;
  person: Person;
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;

  private userReference: string;

  constructor(
    private managerDashBoardService: GlobalDashboardService,
    private _router: Router,
    private jwtHelper: JwtHelperService,
    private userService: UserService,
    private _toastr: ToastrService,
    private _localAuthorities: LocalAuthorities,
    private _localStorage: LocalStorage,
  ) {

    const data = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userReference = data.jti;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(res => {
        this.person = res as Person;
      });
  }

  ngOnInit() {
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.initPerson();
      this.initLoggedPerson(this.currentUser.id);
      this.getProjectActivitiesSubmittedCount();
      this.getProjectActivitiesReturnedCount();
      this.getProjectActivitiesApprovedCount();
      this.getProjectActivitiesAllVettedCount();
    }, err => {
      this._toastr.warning(err.message, "Current User Error");
    },() => {
    });
  }

  private initPerson() {
    this._localStorage.getItem(StorageConstants.personKey).subscribe(pRes => {
      if (pRes) {
        this.currentPerson = pRes;
      }
    }, err => {
      this._toastr.error(err.message, "Profile Details");
    }, () => {
    });
  }

  private initLoggedPerson(userId: string){
    this._localAuthorities.getPersonDetails(userId).subscribe(res => {
      if (res) {
          this.currentLoggedPerson = res;
          this.currentLoggedPerson['user'] = this.currentUser;
          this._localStorage.setItem(StorageConstants.personKey, this.currentLoggedPerson).subscribe(() => {});
      }
  }, err => {
      this._toastr.error(err.message, "Profile Details");
  },() => {
  });
  }

  getProjectActivitiesSubmittedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('SUBMIT_TO_MANAGER').subscribe(number => {
      this.projectDashboardCounts.projectSubmittedToManagerCount = number;
      this.pendingCountStillLoading = false;
    });
  }

  getProjectActivitiesReturnedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('MANAGER_REJECT').subscribe(number => {
      this.projectDashboardCounts.projectReturnedToUserCount = number;
      this.returnedCountStillLoading = false;
    });
  }

  getProjectActivitiesApprovedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('MANAGER_APPROVE').subscribe(number => {
      this.projectDashboardCounts.projectApprovedByManagerCount = number;
      this.approvedCountStillLoading = false;
    });
  }

  getProjectActivitiesAllVettedCount(){
    this.managerDashBoardService.getProjectActivitesByStageCount('MANAGER_ALL_VETTED').subscribe(number => {
      this.projectDashboardCounts.allProjectsVettedByManagerCount = number;
      this.vettedCountStillLoading = false;
    });
  }

  goToProjectActivities(requestState: string) {
    this._router.navigate([`portal/activities/manager-project-activities/${requestState}`]);
  }


}
