import { StorageConstants } from './../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { DocUploadComponent } from './../doc-upload/doc-upload.component';
import { DocumentsService } from '../dococument.service';
import { DialogData, NigisDocument } from './../nigis-doc.model';

@Component({
    selector: 'documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit {
    fetching: boolean = true;
    documentCount: number = 0;
    @Input('canAdd') canAdd: boolean = true;
    @Input('canEdit') canEdit: boolean = true;
    @Input('currentState') currentState: string = '';
    @Input('canDelete') canDelete: boolean = true;
    @Input('entityId') entityId: string = '';
    @Input('isLiteratureReview') isLiteratureReview: boolean = false;
    @Input('isInputData') isInputData: boolean = false;
    @Input('projectId') projectId: string = '';
    @Input('isPLReport') isPLReport: boolean = false;
    @Input('plReportAlreadyAdded') plReportAlreadyAdded: boolean= false;

    @Output() refreshEvent = new EventEmitter<boolean>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    currentPrincipal: any;

    tableDataSource: MatTableDataSource<NigisDocument>;
    displayedColumns: string[] = ['#', 'Name', 'Description', 'File Type', 'Year', 'Creation Date', 'Action'];

    constructor (
        private dialog: MatDialog,
        private _toastr: ToastrService,
        private docServ: DocumentsService,
        private _localStorage: LocalStorage,
    ) {}

    ngOnInit() {
        this._currentPrincipal();
    }

    applyFilter(filterValue: string) {
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }

    openDialog() {
        this.dialog.open(DocUploadComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: <DialogData>{
                id: this.entityId, edit: false, delete: false, fileId: null, approval: false
            }
        }).afterClosed().subscribe(r => {
            if (r) {
                this._getDocuments();
            }
        });
    }

    editDocumet(doc: NigisDocument) {
        this.dialog.open(DocUploadComponent, <MatDialogConfig>{
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            data: <DialogData>{
                id: this.entityId, fileId: doc.id, edit: true, delete: false, approval: false
            }
        }).afterClosed().subscribe(r => {
            if (r) {
                this._getDocuments();
            }
        });
    }

    deleteDocument(doc: NigisDocument) {
        Swal.fire(<SweetAlertOptions>{
            title: 'Delete Document',
            type: 'warning',
            html: `Are you sure you want to <b>Delete</b>? ${doc.name}`,
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete!'
        }).then(result => {
            if (result && result.value === true) {
                this.docServ.deleteDocument(doc.id).subscribe(res => {
                    this._toastr.success("File deleted successfully", "File deleted");
                    this._getDocuments();
                }, _err => {
                    this._toastr.error("Failed to delete a file", "Delete Failed");
                });
            }
        });
    }

    downloadDocument(doc: NigisDocument) {
        this.docServ.getDocumentFile(doc.uri).subscribe(data => {
            this._downloadFile(doc, data);
        }, _err => {
            this._toastr.error("Failed to download file", "File Download");
        });
    }

    getFileExtension(filename: string): string {
        return filename.split('.').pop();
    }

    private _getDocuments() {
        this.docServ.getEntityDocuments(this.entityId).subscribe(res => {
            this.fetching = false;
            this.tableDataSource = new MatTableDataSource<NigisDocument>(res);
            this.tableDataSource.paginator = this.paginator;
            this.refreshEvent.emit(true);
            this._countDocuments();
        }, _err => {
            this._toastr.error("Failed to retrieve documents", "Documents List");
        });
    }

    private _countDocuments() {
        this.docServ.getDocumentCount(this.entityId).subscribe(res => {
            this.documentCount = res;
        })
    }

    private _downloadFile(doc: NigisDocument, data: any) {
        let binaryData = [];
        binaryData.push(data);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: data.dataType}));
        downloadLink.setAttribute('download', doc.fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    private _currentPrincipal() {
      this._localStorage.getItem<any>(StorageConstants.fullUserKey).subscribe(res => {
        this.currentPrincipal = res;
        this._getDocuments();
      });
    }
}
