import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Borehole } from '../../../shared-kernel/entity/common/borehole';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlTree } from '@angular/router';
import { DrillingProtocol } from '../../../shared-kernel/entity/driller/DrillingProtocol';
import { DrillerService } from '../../driller.service';

@Component({
  selector: 'app-drilling-protocol-list',
  templateUrl: './drilling-protocol-list.component.html',
  styleUrls: ['./drilling-protocol-list.component.scss']
})
export class DrillingProtocolListComponent implements OnInit {

  dataSourceDrillingProtocols = new MatTableDataSource();
  displayedDrillingProtocolsColumns = ['Depth From', 'Depth To', 'Diameter', 'Drilling Method', 'Inclination', 'Azimuth', 'Edit Delete'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  url: string;
  boreholeId: {};
  drillingProtocol: DrillingProtocol;
  drillingProtocolLogs: DrillingProtocol[];
  borehole: Borehole;
  drillingProtocolRecords: Array<DrillingProtocol>;

  dataSource: MatTableDataSource<DrillingProtocol> = new MatTableDataSource();
  loading = true;

  constructor(private router: Router, private drillerService: DrillerService) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[4].parameters;
    this.boreholeId = urlData;
  }

  ngOnInit() {
    this.getAllDrillingProtocolsByBoreholeId();
    this.dataSourceDrillingProtocols = new MatTableDataSource(this.drillingProtocolLogs);
  }

  ngAfterViewInit() {
    this.dataSourceDrillingProtocols.sort = this.sort;
    this.dataSourceDrillingProtocols.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSourceDrillingProtocols.filter = filterValue.trim().toLowerCase();
  }

  openDrillingProtocolForm() {
    this.router.navigate(['portal/applied-geoscience/geo-tech/water-borehole/drilling-protocol/create', this.boreholeId]); // fix route
  }

  getAllDrillingProtocols() {
    this.drillerService.getAllDrillingProtocols().subscribe(data => {
      this.drillingProtocolRecords = data as DrillingProtocol[];
      this.dataSource.data = this.drillingProtocolRecords;
    });
  }

  getAllDrillingProtocolsByBoreholeId() {
    let boreholeId = { id: null };
    boreholeId = this.boreholeId as Borehole;
    if (boreholeId.id) {
      this.drillerService.getAllDrillingProtocolsByBoreholeId(boreholeId.id)
        .subscribe(data => {
          this.drillingProtocolLogs = data as DrillingProtocol[];
          if (this.drillingProtocolLogs.length !== 0) {
            this.dataSourceDrillingProtocols.data = data as DrillingProtocol[];
          } else {
            this.drillingProtocolLogs = null;
          }
          this.loading = false;
        },
          error => {
            this.loading = false;
          }, () => {
            this.loading = false;
          }
        );
    }

  }

}
