import {Borehole} from '../../common/borehole';
import {Size} from '../../common/size';
import {RockName} from './RockName';

export class CoreRecoveryLog {
  id: string;
  runNumber: string;
  depthFrom: Size;
  depthTo: Size;
  rockQuality: string;
  recoveryDate: Date;
  totalCoreRecovery: Size;
  borehole: Borehole;
  lithology: string;
  geologist: string;
  geologistName: string;
  deleted: boolean;

}
