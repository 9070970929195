import { PeopleService } from './../../../_core/mock/people.service';
import { BusinessUnitEnum } from './../../../shared-kernel/enumerations/business-unit-enum';
import { SamplesCreateComponent } from './../samples-create/samples-create.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GeoTechService } from './../../../applied-geoscience/geo-tech/geo-tech.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Sample } from '../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import Swal, {SweetAlertOptions} from "sweetalert2";

@Component({
  selector: 'app-samples-list',
  templateUrl: './samples-list.component.html',
  styleUrls: ['./samples-list.component.scss']
})
export class SamplesListComponent implements OnInit {

  samples: Sample[];
  filteredSamples: Sample[];
  dataSourceSamples: MatTableDataSource<Sample> = new MatTableDataSource();
  displayedSampleColumns = ['#', 'projectName', 'sampleNumber', 'collectedBy', 'sampleDate', 'view', 'edit', 'delete'];
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  sampleCount:number = 0;
  selectedSection: string = 'all';
  @Input('entityId') entityId: string = '';

  loading:boolean = true;
  idOfRecordToDelete: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private geoTechService: GeoTechService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private peopleService: PeopleService,
    public toast: ToastrService,
  ) { }

  ngOnInit() {
    this.dataSourceSamples.paginator = this.paginator;
    this.getSamplesByUnit();

  }

  getSamplesByUnit() {

      this.geoTechService.getSamplesByUnit(this.businessUnitEnum.mineralResources).subscribe(
        value => {

          let retrievedSamples = value as any [];
          for (let sample of retrievedSamples) {
            sample.sampleType = '';
            for (let sampleType of sample.sampleTypes) {
              sample.sampleType += sampleType.name + ', ';
            }
            sample.year = new Date(sample.sampleDate).getFullYear();
          }

          retrievedSamples.forEach(sample => {
            this.peopleService.getPersonById(sample.collectedBy).subscribe(
              person => {
                sample.collectorName = person;
              }
            );
          });

          const currentYear = new Date().getFullYear();
          const thisYearSamples = retrievedSamples.filter(sample => sample.year === currentYear);

          this.sampleCount = thisYearSamples.length;
          this.samples = retrievedSamples;
          this.filteredSamples = retrievedSamples;

          const datasourceArray = this.filteredSamples;

          this.dataSourceSamples.data = datasourceArray.sort((x, y) => +new Date(y.sampleDate) - +new Date(x.sampleDate)) as Sample[];
          this.dataSourceSamples.paginator = this.paginator;
          this.loading = false;
        }, error => {
          this.loading = false;
          this.toast.error('Error', error.message);
        });



  }

  getSamplesSubmittedToLab() {
    this.geoTechService.getSamplesSubmittedToLab().subscribe(
      value => {
        let retrievedSamples = value as any [];
        for (let sample of retrievedSamples) {
          sample.sampleType = '';
          for (let sampleType of sample.sampleTypes) {
            sample.sampleType += sampleType.name + ', ';
          }
          sample.year = new Date(sample.sampleDate).getFullYear();
        }

        const currentYear = new Date().getFullYear();
        const thisYearSamples = retrievedSamples.filter(sample => sample.year === currentYear);

        this.sampleCount = thisYearSamples.length;
        this.samples = retrievedSamples;
        this.filteredSamples = retrievedSamples;
        this.dataSourceSamples.data = this.filteredSamples as Sample[];
        this.dataSourceSamples.paginator = this.paginator;
        this.dataSourceSamples.sort = this.sort;
        this.loading = false;
      },error => {
        this.loading = false;
        this.toast.error('Error', error.message);
      });
  }

  getSamplesNotSubmittedToLab() {
    this.geoTechService.getSamplesNotSubmittedToLab().subscribe(
      value => {
        let retrievedSamples = value as any [];
        for (let sample of retrievedSamples) {
          sample.sampleType = '';
          for (let sampleType of sample.sampleTypes) {
            sample.sampleType += sampleType.name + ', ';
          }
          sample.year = new Date(sample.sampleDate).getFullYear();
        }

        const currentYear = new Date().getFullYear();
        const thisYearSamples = retrievedSamples.filter(sample => sample.year === currentYear);

        this.sampleCount = thisYearSamples.length;
        this.samples = retrievedSamples;
        this.filteredSamples = retrievedSamples;
        this.dataSourceSamples.data = this.filteredSamples as Sample[];
        this.dataSourceSamples.paginator = this.paginator;
        this.dataSourceSamples.sort = this.sort;
        this.loading = false;
      },error => {
        this.loading = false;
        this.toast.error('Error', error.message);
      });
  }

  onCreate() {
    const obj = {sample: null, sampleCount: this.sampleCount, transactionType:'New'};
    const newGeoHeritageDialogRef = this.dialog.open(SamplesCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getSamplesByUnit();
    });
  }

  onCreateExisting() {
    const obj = {sample: null, sampleCount: this.sampleCount, transactionType:'Existing'};
    const newGeoHeritageDialogRef = this.dialog.open(SamplesCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getSamplesByUnit();
    });
  }

//  open details
  openDetails(sampleId) {
    const urlParams = { id: sampleId };
    this.router.navigate(['/portal/mineral-resources/samples/details', urlParams]);
  }

  // on edit
  onEdit(sample) {
    const obj = {sample: sample, sampleCount: this.sampleCount, transactionType:'Edit'};
    const newGeoHeritageDialogRef = this.dialog.open(SamplesCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newGeoHeritageDialogRef.afterClosed().subscribe((result) => {
      this.getSamplesByUnit();
    });
  }

  onDelete(sample: Sample) {
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete Sample',
      type: 'warning',
      html: `Are you sure you want to <b>Delete</b> this Sample?`,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(result => {
      if (result && result.value === true) {
        this.spinner.show();
        this.geoTechService.deleteSample(sample).subscribe(
          value => {
            this.getSamplesByUnit();
            this.spinner.hide();
          }
          , error => {
            this.toast.error(error, 'Delete Sample');
          }
          , () => {
            this.toast.success('SampleDeleted Successfully', 'Delete Sample');
          }
        );
      }
    });
  }

  navigateToMap() {
    let shortenedSamples = this.sanitizeList();
    if (shortenedSamples.length > 0) {
      localStorage.setItem('list', JSON.stringify(shortenedSamples));
      const from = { list: 'samples' };
      this.router.navigate(['portal/applied-geoscience/geo-tech/samples/locations', from]);
    } else {
      this.toast.warning('Please make sure that Borehole list is not Empty', 'Plot To Map');
    }
  }

  sanitizeList():any[] {
    let sample :any[] = [];
    this.samples.forEach(element => {
      sample.push({id:element.id,locationTemp:{
          latitude:element.locationTemp.coordinates.latitude,
          longitude:element.locationTemp.coordinates.longitude}
        ,sampleNumber:element.sampleNumber,
        locationDescription:element.locationDescription.description
      });

    });

    return sample;
  }



  exportPDF() {

  }

  exportCSV() {

  }

  applyFilter(filterValue: string) {
    this.dataSourceSamples.filter = filterValue.trim().toLowerCase();
  }

  onSectionChange(selectedSection) {
    this.selectedSection = selectedSection;

    if (this.selectedSection === 'all') {
      this.getSamplesByUnit();
    } else if (this.selectedSection === 'notSubmitted') {
      this.getSamplesNotSubmittedToLab();
    } else if (this.selectedSection === 'submitted') {
      this.getSamplesSubmittedToLab();
    }
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  resetPaginator() {
    this.dataSourceSamples.sort = this.sort;
    this.dataSourceSamples.paginator = this.paginator;
  }

}
