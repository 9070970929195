import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { CoreshedService } from '../../../collections/services/coreshed.service';
import { Activity } from '../../../activities/models';
import { MatTableDataSource } from '@angular/material/table';
import { Person } from '../../../_core/data/_models/people.model';
import { LocalAuthorities } from '../../../_auth/_providers';
import { ApprovalState } from '../../../applied-geoscience/models';
import { TrayHistory } from '../../../collections/enums/TrayHistory';

@Component({
  selector: 'app-collections-technician',
  templateUrl: './collections-technician.component.html',
  styleUrls: ['./collections-technician.component.scss']
})
export class CollectionsTechnicianComponent implements OnInit {

  cards :any;
  stillFetchingBGIActiviesCount = true;
  bgiProjectCounts = 0;
  state: ApprovalState ;
  activitiesDataSource : MatTableDataSource<Activity> = new MatTableDataSource<Activity>();
  breakpoint: number;
  constructor(private _router: Router,private breakpointObserver: BreakpointObserver,private _localAuthorities: LocalAuthorities,
              private userDashBoardService:UserDashboardService,private  coreshedService:CoreshedService){
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'Core/Chip History', cols: 2, rows: 3 , id: 'myactivities'},
        ];
      })
    );

  }


  ngOnInit() {
      this.getBGIProjectsCount();
      this.getAllCheckout();
  }

  gotoProject(uri: string) {
    let urlParams: any;
    if('my_project' === uri){
      urlParams = { id: 'my_project' };
    }
    if('bgi_project' === uri){
      urlParams = { id: 'bgi_project' };
    }
    this._router.navigate([`portal/applied-geoscience/project/list`,urlParams]);
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.bgiProjectCounts = number;
      this.stillFetchingBGIActiviesCount =false;
    });
  }

  getAllCheckout() {
    this.coreshedService.getAllCheckoutHistory().subscribe(value => {
      let theValue = value as any[];
      this.formatInformation(theValue);
      this.activitiesDataSource  = new MatTableDataSource(theValue);
    });
  }

  formatInformation(checkoutHistory){
    checkoutHistory.forEach(checkoutHistoryRecord => {
      checkoutHistoryRecord.presevationDetails = false;

      switch (checkoutHistoryRecord.actionTaken) {
        case TrayHistory.PENDING_SENIOR_ARCHIVIST_SUBMISSION :
          checkoutHistoryRecord.actionTaken = 'Created';
          break;
        case TrayHistory.SUBMITTED_TO_SENIOR_ARCHIVIST :
          checkoutHistoryRecord.actionTaken = 'Submitted To Senior Archivist';
          break;
        case TrayHistory.REJECTED_BY_SENIOR_ARCHIVIST:
          checkoutHistoryRecord.actionTaken = "Rejected By Senior Archivist";
          break;
        case TrayHistory.APPROVED:
          checkoutHistoryRecord.actionTaken = "Approved By Senior Archivist";
          break;
        case TrayHistory.PENDING_MANAGER_SUBMISSION:
          checkoutHistoryRecord.actionTaken = "Pending Manager Submission";
          break;
        case TrayHistory.SUBMITTED_TO_MANAGER:
          checkoutHistoryRecord.actionTaken = "Submitted To Manager";
          break;

        case TrayHistory.REJECTED_BY_MANAGER:
          checkoutHistoryRecord.actionTaken = "Rejected By Manager";
          break;

        case TrayHistory.APPROVED_MANAGER:
          checkoutHistoryRecord.actionTaken = "Approved By Manager";
          break;

        case TrayHistory.PENDING_SPECIMEN_MANAGER_SUBMISSION:
          checkoutHistoryRecord.actionTaken = "Pending Manager Submission";
          break;
      }

      this._localAuthorities.getPersonByUsername(checkoutHistoryRecord.creator ).subscribe(res => {
        let person = res as Person;
        checkoutHistoryRecord.creator = person.name+ ' '+person.surname;
      });
      if(checkoutHistoryRecord.checkoutReasons && checkoutHistoryRecord.actionTaken==='CHECKIN'){
        if(checkoutHistoryRecord.checkoutReasons.includes('Core Preservation') || checkoutHistoryRecord.checkoutReasons.includes('Chips Preservation')){
          checkoutHistoryRecord.presevationDetails = true;
        }
      }

    })
  }

}
