import { CompanyService } from './../../../applied-geoscience/company/company.service';
import { UserDashboardService } from './../../../dashboards/user-dashboard/user-dashboard.service';
import { Person } from './../../../_core/data/_models/people.model';
import { UserService } from './../../../auth/user/user.service';
import { CompanyEnum } from './../../../shared-kernel/enumerations/company-enum';
import { startWith, map, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { BusinessUnitEnum } from './../../../shared-kernel/enumerations/business-unit-enum';
import { ToastrService } from 'ngx-toastr';
import { HydrogeologyService } from './../../../applied-geoscience/hydrogeology/hydrogeology.service';
import { GeoTechService } from './../../../applied-geoscience/geo-tech/geo-tech.service';
import { RockName } from '../../../shared-kernel/entity/applied-geoscience/geoTech/RockName';
import { RockGroup } from './../../../shared-kernel/entity/applied-geoscience/geoTech/RockGroup';
import { RockType } from './../../../shared-kernel/entity/applied-geoscience/hydrogeology/RockType';
import { RockGroupEnum } from './../../../shared-kernel/enumerations/rock-group-enum';
import { RockSubGroupEnum } from './../../../shared-kernel/enumerations/rock-sub-group-enum';
import { RockTypeEnum } from './../../../shared-kernel/enumerations/rock-type-enum';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { Sample } from './../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { District } from './../../../common/entity/security/util/common/district';
import { Village } from './../../../shared-kernel/entity/common/Village';
import { CommonService } from './../../../common/common.service';
import { Project } from './../../../shared-kernel/entity/applied-geoscience/project/project';
import { ProjectService } from './../../../applied-geoscience/project/project.service';
import { CoordinatesService } from '../../../shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { CoordinatesTypesEnum } from '../../../shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from '../../../shared-kernel/enumerations/coordinates-formats-enumt';
import { Coordinate } from '../../../shared-kernel/entity/common/coordinatesValidator/coordinate';
import LatLon from 'geodesy/latlon-nvector-spherical';
import { UnitEnum } from '../../../shared-kernel/enumerations/unit-enum';
import { Size } from '../../../shared-kernel/entity/common/size';
import { StorageConstants } from '../../../_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {NigisImages} from "../../../shared-kernel/entity/common/nigisImages";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {BoreholeLocationImage} from "../../../shared-kernel/entity/common/boreholeLocationImage";
import {MatChipInputEvent} from "@angular/material/chips";


@Component({
  selector: 'app-samples-create',
  templateUrl: './samples-create.component.html',
  styleUrls: ['./samples-create.component.scss']
})
export class SamplesCreateComponent implements OnInit {

  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  observe = false;
  maxDate = new Date();
  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  villages: Village[];
  districts: District[];
  district: District = new District();

  botswanaPolygon: any[];
  latLonArray: Coordinate = new Coordinate();

  projects: any;

  projectStillLoading = true;
  districtStillLoading = true;
  villageStillLoading = true;
  title: string;
  sampleCreateForm: FormGroup;
  rockTypeEnum: typeof RockTypeEnum = RockTypeEnum;
  rockGroupEnum: typeof RockGroupEnum = RockGroupEnum;
  rockSubGroupEnum: typeof RockSubGroupEnum = RockSubGroupEnum;
  rockGroups: RockGroup[] = [];
  rockNames: RockName[] = [];
  rockSymbolsForSubDivision: RockName[] = [];
  correspondingDykes: RockName[] = [];
  mixedTypes: RockName[] = [];
  groupSymbols: RockName[] = [];
  rockTypes: RockType[];

  submitted: boolean =  false;
  rockTypeSelected: boolean =  false;
  isBasicType: boolean =  false;
  isGroupSymbols: boolean =  false;
  isVarious: boolean =  false;
  isMixedSymbol: boolean =  false;
  isGeneralRock : boolean =  false;
  isSubdivision: boolean =  false;
  isCorrespondingDyke: boolean =  false;
  rockGroupId: string;
  isPure: boolean = false;
  isImpurePure: boolean = false;
  isMetamorphicRocks: boolean =  false;
  isMagmaticRocksAndVarious: boolean =  false;
  isSedimentsAndSedimentaryRocks;

  visible = true;

  selectable = true;

  removable = true;

  addOnBlur = true;
  locationDescription: string;

  samplesLocationImages: NigisImages[] = [];

  sampleLocationImage: NigisImages;

  filteredsampleLocationImages: Observable<BoreholeLocationImage[]>;

  @ViewChild('sampleLocationImageInput') sampleLocationImageInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  sampleLocationImageCtrl = new FormControl();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  sampleTemplate: Sample = new Sample();
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  filteredOptionsVillage: Observable<Village[]>;
  filteredOptionsDistrict: Observable<District[]>;
  filteredOptionsPeople: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  loadingPeople = false;
  people: Person[] = [];
  person: Person = {};
  collectorName: string = '--';
  activities: Array<any>;
  filteredOptionsSampleNumber: Observable<any[]>;

  @ViewChild('collectorSelect') collectorSelect: MatSelect;
  collectedBy: FormControl = new FormControl();
  collectorFilter: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  currentUser: any;

  constructor(
    private coordinatesService: CoordinatesService,
    private projectService: ProjectService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SamplesCreateComponent>,
    private formBuilder: FormBuilder,
    private geoTechService: GeoTechService,
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private userService: UserService,
    private userDashBoardService: UserDashboardService,
    private _localStorage: LocalStorage
  ) {
  }

  ngOnInit() {

    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    this.initForms();
    this.getBotswanaGeofence();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.getProjectsByMember(this.currentUser.username);
      }, err => {
        this.toastr.warning(err.message, "Current User Error");
      },() => {

      });
    this.getAllDistricts();
    this.getAllVillages();
    this.getAllRockTypes();
    this.getAllBGIPersonnel();

    if (this.data.sample) {
      this.title = 'Edit Sample';
    } else {
      this.title = 'Create Sample';
    }

    if (this.data.transactionType === 'Existing') {
      this.getTechSamplesApprovedByGeologist();
    }
  }

  getTechSamplesApprovedByGeologist() {
    this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeo('SUBMITTED_TO_MANAGER_BY_GEOLOGIST').subscribe(res => {
      this.activities = res;
      this.filteredOptionsSampleNumber = this.sampleCreateForm.controls.sampleNumber.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.number),
        map(number => number ? this._filterSampleNumber(number) : this.activities.slice())
      );
    }, err => {
      this.toastr.warning(err.message, 'Failed to get activities');
    }, () => {
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  private initForms() {

    if (this.data.sample) {
        this.selectedCoordinatesFormat = this.data.sample.coordinatesFormat.format;
    }


    this.sampleCreateForm = this.formBuilder.group({
      sampleDate: [this.data.sample && !this.data.entityId ? new Date(this.data.sample.sampleDate) : null, Validators.required],
      depthFrom: [this.data.sample && this.data.entityId ? this.data.sample.depthFrom.measure : null, Validators.required],
      depthTo: [this.data.sample && this.data.entityId ? this.data.sample.depthTo.measure : null, Validators.required],
      project: [this.data.sample ? this.data.sample.project : null, Validators.required],
      district: [this.data.sample ? this.data.sample.locationTemp.village  ? this.data.sample.locationTemp.village.district :null : null, Validators.required],
      village: [this.data.sample ? this.data.sample.locationTemp.village ? this.data.sample.locationTemp.village : this.data.sample.villageText : null , Validators.required],
      sampleNumber: [this.data.sample ? this.data.sample.sampleNumber : null, Validators.required],
      coordinatesFormat: [this.data.sample ? this.data.sample.coordinatesFormat.format : null, Validators.required],
      sampleDescription: [this.data.sample ? this.data.sample.sampleDescription : ''],
      structuresMeasurements: [this.data.sample ? this.data.sample.structuresMeasurements : ''],
      comments: [this.data.sample ? this.data.sample.comments : ''],
      latitude: [this.data.sample ? this.data.sample.locationTemp.coordinates.latitude : ''],
      longitude: [this.data.sample ? this.data.sample.locationTemp.coordinates.longitude : ''],
      samplesLocationImages: [this.data.sample ? this.data.sample.samplesLocationImages : ''],
      locationDescription: [this.data.sample ? this.data.sample.locationDescription.description : ''],
      coordinatesType: [this.data.sample ? this.data.sample.coordinatesFormat.type : '', Validators.required],
      dmsSx: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._lonSValue) : ''],// decimal minutes seconds, to hold x/longitude seconds value
      dmsMx: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._lonMValue) : ''], // decimal minutes seconds, to hold x/longitude minutes value
      dmsDx: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._lonDValue) : ''], // decimal minutes seconds, to hold x/longitude decimal value
      dmsSy: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._latSValue) : ''], // decimal minutes seconds, to hold y/latitude seconds value
      dmsMy: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._latMValue) : ''],// decimal minutes seconds, to hold y/latitude minutes value
      dmsDy: [this.data.sample ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.sample.locationTemp.coordinates.latitude),
        parseFloat(this.data.sample.locationTemp.coordinates.longitude))._latDVale) : ''],// decimal minutes seconds, to hold y/latitude decimal value
      zone34SNorthing: [this.data.sample ? this.coordinatesService.convertLatLonToUtm(34, this.data.sample.locationTemp.coordinates.latitude, this.data.sample.locationTemp.coordinates.longitude)._lat : ''],
      zone34SEasting: [this.data.sample ? this.coordinatesService.convertLatLonToUtm(34, this.data.sample.locationTemp.coordinates.latitude, this.data.sample.locationTemp.coordinates.longitude)._lon : ''],
      zone35SNorthing: [''],
      zone35SEasting: [''],
      rockType: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.rockGroup.rockType : null : null,[Validators.required]],
      rockName: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName : null : null],
      purity: [],
      various: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.Various ? this.data.sample.rockName.rock: null : null : null],
      mixedSymbols: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks ? this.data.sample.rockName : null: null : null],
      correspondingDyke: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks? this.data.sample.rockName.parentRock? this.data.sample.rockName : null: null: null : null],
      groupSymbols: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.parentRock : null: null],
      subDivision: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.subDivisionSymbol : null: null],
      mixedType: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.MixedTypes ? this.data.sample.rockName : null: null: null],
      types: [this.data.sample ? this.data.sample.rockName? this.data.sample.rockName.rockGroup : null: null, [Validators.required]],
    });

    if(this.data.sample){
      if(this.data.sample.villageText){
        this.observe = true;
        this.sampleCreateForm.controls.village.setValue(this.data.sample.villageText);
      }
      if(this.data.sample.id) {
        if(this.data.sample.rockName){
          if(this.data.sample.rockName.rockGroup) {
            this.rockGroupId = this.data.sample.rockName.rockGroup.id;
            this.rockTypeSelected = true;
            this.changeRockType(this.data.sample.rockName.rockGroup.rockType);

            if(this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.MixedMagmaticRocks) {
              this.isMixedSymbol = true;
              this.getBasicRocks(this.data.sample.rockName.rockGroup.id)
            }
            if(this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.PlutonicRocks) {
              this.isGroupSymbols = true;
              this.isCorrespondingDyke = true;
              this.isSubdivision = true;
              this.getRockNamesByRockGroupId(this.rockGroupId);

              if(this.data.sample.rockName.parentRock) {
                this.changeGroupSymbols(this.data.sample.rockName.parentRock);
                this.changeCorrespondingByParent(this.data.sample.rockName.parentRock);
              }
              else{
                this.sampleCreateForm.controls.groupSymbols.setValue(this.data.sample.rockName);
                this.changeGroupSymbols(this.data.sample.rockName);
              }
            }

            if(this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.VolcanicRocks) {
              this.isGroupSymbols = true;
              this.isSubdivision = true;
              this.getRockNamesByRockGroupId(this.rockGroupId);
              if(this.data.sample.rockName.parentRock) {
                this.changeGroupSymbols(this.data.sample.rockName.parentRock);
                this.changeCorrespondingByParent(this.data.sample.rockName.parentRock);
              }
            }

            if(this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.Various) {
              this.isVarious = true;
              this.getBasicRocks(this.rockGroupId);
            }

            if(this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.BasicTypes) {
              this.isBasicType = true;
              this.getBasicRocks(this.rockGroupId);
              if(this.data.sample.rockName.basicType){
                this.sampleCreateForm.controls.purity.setValue('1');
                this.isImpurePure = true;
              }
              else{
                this.sampleCreateForm.controls.purity.setValue('0');
              }
            }

            if(this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.GeneralRockTypes || this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.SedimentsAndSedimentary
              || this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.Soils || this.data.sample.rockName.rockGroup.name === this.rockGroupEnum.TypesOfGravelAndSands) {
              this.isGeneralRock = true;
              this.getBasicRocks(this.rockGroupId);
            }
          }
        }
      }
    }


  }

  getAllBGIPersonnel() {
    this.loadingPeople = true;
    this.companyService.getCompanyByName(CompanyEnum.BGI_NAME).subscribe(company => {
      this.userService.getPersonsByCompanyId(company.id).subscribe(
        data => {
          this.people = data as Person[];
          this.people.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
          this.filteredOptionsPeople.next(this.people.slice());
          this.collectorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.collectorFilterFnc();
          });

          this._setInitialCollector();
          this.loadingPeople = false;
        }
      );
    });
  }

  collectorFilterFnc() {
    if (!this.people) return;
    let search = this.collectorFilter.value;
    if (!search) {
      this.filteredOptionsPeople.next(this.people.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptionsPeople.next(
      this.people.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });
  }

  private getProjects() {
    this.projectService.getAllProjectName().subscribe(res => {
      this.projects = res;
      this.projectStillLoading = false;
    }, err => {
    });
  }

  getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user).subscribe(res => {
      this.projects = res;
      this.projectStillLoading = false;
    }, err => {
    });
  }


  getAllDistricts() {
    this.commonService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        this.filteredOptionsDistrict = this.sampleCreateForm.controls.district.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterDistrict(name) : this.districts.slice())
        );
        this.districtStillLoading = false;
      });
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        this.filteredOptionsVillage = this.sampleCreateForm.controls.village.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterVillage(name) : this.villages.slice())
        );
        this.villageStillLoading = false;
      });
  }

  private _filterVillage(name: string): Village[] {
    const filterValue = name.toLowerCase();
    return this.villages.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterDistrict(name: string): District[] {
    const filterValue = name.toLowerCase();
    return this.districts.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterPeople(name: string): Person[] {
    const filterValue = name.toLowerCase();
    return this.people.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  changeRockType(value: RockType) {
    this.resetDropDown();
    this.getRockGroups(value.id);
    this.rockTypeSelected = true;
  }

  resetDropDown(){
    this.isMixedSymbol = false;
    this.isGeneralRock = false;
    this.isVarious = false;
    this.isGroupSymbols = false;
    this.isBasicType = false;
    this.isCorrespondingDyke = false;
    this.isSubdivision = false;
  }


  getRockGroups(id) {
    this.geoTechService.getRockGroupByRockTypeId(id).subscribe(res => {
      this.rockGroups = res as RockGroup [];
      this.rockGroups = this.rockGroups.filter(value => value.name !== 'Mixed Types');
      this.rockGroups.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
    });
    if(this.rockTypeEnum.MetamorphicRocks){
      this.isMetamorphicRocks = true;
    }
    if(this.rockTypeEnum.SedimentsAndSedimentaryRocksAndVariousSoilsAndGeneralRockTypes){
      this.isSedimentsAndSedimentaryRocks = true;
    }
    if(this.rockTypeEnum.MagmaticRocksAndVarious){
      this.isMagmaticRocksAndVarious = true;
    }
  }

  getAllRockTypes() {
    this.hydrogeologyService.getAllRockTypes().subscribe(
      data => {
        this.rockTypes = data as RockType[];
      }
    );
  }

  changeType(value: RockType) {
    this.rockGroupId = value.id;

    this.sampleCreateForm.controls.groupSymbols.setValue(null);
    if (value.name === this.rockGroupEnum.BasicTypes){
      this.getBasicRocks(value.id);
      this.isBasicType = true;
      this.isSubdivision = false;
      this.isGeneralRock = false;
      this.isMixedSymbol = false;
      this.isGroupSymbols = false;
      this.isCorrespondingDyke = false;

    } else  if (value.name === this.rockGroupEnum.VolcanicRocks){
      this.getRockNamesByRockGroupId(value.id);
      this.isGroupSymbols = true;
      this.isMixedSymbol = false;
      this.isVarious = false;
      this.isGeneralRock = false;
      this.isSubdivision = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;

    } else  if (value.name === this.rockGroupEnum.PlutonicRocks){
      this.getRockNamesByRockGroupId(value.id);
      this.isGroupSymbols = true;
      this.isVarious = false;
      this.isGeneralRock = false;
      this.isMixedSymbol = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;
      this.isSubdivision = false;

    } else  if (value.name === this.rockGroupEnum.Various){
      this.getBasicRocks(value.id);
      this.isVarious = true;
      this.isGroupSymbols = false;
      this.isMixedSymbol = false;
      this.isGeneralRock = false;
      this.isSubdivision = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;

    } else  if (value.name === this.rockGroupEnum.Mesozone || value.name === this.rockGroupEnum.Catazone
      || value.name === this.rockGroupEnum.Epizone  || value.name === this.rockGroupEnum.MetamorphicGeneralAndOthers){
      this.getBasicRocks(value.id);
      this.isGeneralRock = true;
      this.isMixedSymbol = false;
      this.isVarious = false;
      this.isSubdivision = false;
      this.isGroupSymbols = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;

    } else  if ( value.name === this.rockGroupEnum.MixedMagmaticRocks ){
      this.getBasicRocks(value.id);
      this.isMixedSymbol = true;
      this.isVarious = false;
      this.isGeneralRock = false;
      this.isSubdivision = false;
      this.isGroupSymbols = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;

    } else  if (value.name === this.rockGroupEnum.GeneralRockTypes || value.name === this.rockGroupEnum.SedimentsAndSedimentary
      || value.name === this.rockGroupEnum.Soils || value.name === this.rockGroupEnum.TypesOfGravelAndSands   ){
      this.getBasicRocks(value.id);
      this.isGeneralRock = true;
      this.isMixedSymbol = false;
      this.isVarious = false;
      this.isSubdivision = false;
      this.isGroupSymbols = false;
      this.isBasicType = false;
      this.isCorrespondingDyke = false;

    } else {
      this.resetDropDown();
    }
  }

  getMixedTypes(parent:RockName){
    this.geoTechService.getMixedTypeByBasicId(parent.id).subscribe(value => {
      this.mixedTypes = value as RockName [];
      this.mixedTypes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  getRockNamesByRockGroupId(rockGroupId){
    this.geoTechService.getRockNamesByGroupAndBySubGroup(rockGroupId, this.rockSubGroupEnum.GroupSymbols).subscribe(res => {
      this.groupSymbols = res as RockName [];
      this.groupSymbols.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  getBasicRocks(rockGroupId){
    this.geoTechService.getBasicTypeRockNames(rockGroupId).subscribe(res => {
      this.rockNames = res as RockName [];
      this.rockNames.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  changePurity(value){
    if (value === '0') {
      this.isPure = true;
      this.isImpurePure = false;
    } else {
      this.isPure = false;
      this.isImpurePure = true;
    }
  }

  changeGroupSymbols(value:RockName) {
    this.geoTechService.getRockNamesByParentNameAndSubgroup(value.rock, this.rockSubGroupEnum.SymbolsForFurtherSubdivision).subscribe(res => {
      this.rockSymbolsForSubDivision = res as RockName [];
      if(this.rockSymbolsForSubDivision.length>0){
        this.isSubdivision = true;
        this.isCorrespondingDyke = true;
      }
      this.rockSymbolsForSubDivision.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    },error => {},() => {
    });

    this.geoTechService.getRockNamesByParentNameAndSubgroup(value.rock, this.rockSubGroupEnum.CorrespondingDykeAndSillRocks).subscribe(res => {
      this.correspondingDykes = res as RockName [];
      if(this.correspondingDykes.length>0){
        this.isCorrespondingDyke = true;
      }
      this.correspondingDykes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  changeCorrespondingBySubdivision(subdivision:RockName){
    this.geoTechService.getRockNamesBySubdivision(subdivision.id).subscribe(res => {
      this.correspondingDykes = res as RockName [];
      if(this.correspondingDykes.length>0){
        this.isCorrespondingDyke = true;
      }
      this.correspondingDykes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  changeCorrespondingByParent(value){
    this.geoTechService.getRockNamesByParentNameAndSubgroup(value.rock, this.rockSubGroupEnum.CorrespondingDykeAndSillRocks).subscribe(res => {
      this.correspondingDykes = res as RockName [];
      if(this.correspondingDykes.length>0){
        this.isCorrespondingDyke = true;
      }
      this.correspondingDykes.sort((a, b) => (a.rock || '').toString().localeCompare((b.rock || '').toString()));
    });
  }

  compareFn(projectOne: any, projectTwo: any): boolean {
    return projectOne && projectTwo ? projectOne.id === projectTwo.id : projectOne === projectTwo;
  }

  compareStrings(stringOne: any, stringTwo: any): boolean {
    return stringOne === stringTwo;
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };

    this.selectedCoordinatesType = selectedData.text;

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    }
    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
    }
  }

  onDistrictChange(district: District) {
    this.commonService.getVillagesByDistrictId(district.id)
    .subscribe(data => {
      this.villages = data as Village[];
      this.villages.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
      this.filteredOptionsVillage = this.sampleCreateForm.controls.village.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterVillage(name) : this.villages.slice())
      );
    });
  }

  onVillageChange(village: Village) {
    for (let i = 0; i < this.districts.length; i++) {
      if (this.districts[i].id === village.district.id) {
        this.sampleCreateForm.controls.district.setValue(this.districts[i]);
      }
    }
  }

  onSubmit() {


    if (this.data.sample) {
      this.sampleTemplate = this.data.sample;
    }


    this.sampleTemplate.collectedBy = this.collectedBy.value.id;

    this.sampleTemplate.deleted = false;
    this.sampleTemplate.comments = this.sampleCreateForm.controls.comments.value;
    this.sampleTemplate.structuresMeasurements = this.sampleCreateForm.controls.structuresMeasurements.value;

    this.sampleTemplate.sampleDate = this.sampleCreateForm.controls.sampleDate.value;
    this.sampleTemplate.project = this.sampleCreateForm.controls.project.value;
    this.sampleTemplate.sampleNumber = this.sampleCreateForm.controls.sampleNumber.value;
    if(!this.observe){
      this.sampleTemplate.locationTemp.village = this.sampleCreateForm.controls.village.value;
    }
    else{
        this.sampleTemplate.locationTemp.village = null;
        this.sampleTemplate.villageText = this.sampleCreateForm.controls.village.value;
    }
    this.sampleTemplate.sampleDescription = this.sampleCreateForm.controls.sampleDescription.value;
    this.sampleTemplate.coordinatesFormat.type = this.sampleCreateForm.controls.coordinatesType.value;
    this.sampleTemplate.coordinatesFormat.format = this.sampleCreateForm.controls.coordinatesFormat.value;
    this.sampleTemplate.locationTemp.coordinates.latitude = this.sampleCreateForm.controls.latitude.value;
    this.sampleTemplate.locationTemp.coordinates.longitude = this.sampleCreateForm.controls.longitude.value;
    this.sampleTemplate.sampleOwnerUnit = this.businessUnitEnum.mineralResources;
    this.sampleTemplate.samplesLocationImages = this.samplesLocationImages;
    this.sampleTemplate.locationDescription.description = this.sampleCreateForm.controls.locationDescription.value;

    this.sampleTemplate.rockName = this.isBasicType && !this.isImpurePure ? this.sampleCreateForm.controls.rockName.value :
    this.isBasicType && this.isImpurePure? this.sampleCreateForm.controls.mixedType.value:
    this.isVarious? this.sampleCreateForm.controls.various.value:
    this.isGeneralRock ? this.sampleCreateForm.controls.rockName.value :
      this.isMixedSymbol ? this.sampleCreateForm.controls.mixedSymbols.value:
        this.isGroupSymbols && !this.sampleCreateForm.controls.subDivision.value
      && !this.sampleCreateForm.controls.correspondingDyke.value ?this.sampleCreateForm.controls.groupSymbols.value :
       this.isSubdivision && this.sampleCreateForm.controls.subDivision.value && !this.sampleCreateForm.controls.correspondingDyke.value? this.sampleCreateForm.controls.subDivision.value :
         this.isCorrespondingDyke && this.sampleCreateForm.controls.correspondingDyke.value && !this.isBasicType ? this.sampleCreateForm.controls.correspondingDyke.value:'';


    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.sampleCreateForm.controls.dmsDy.value, this.sampleCreateForm.controls.dmsMy.value, this.sampleCreateForm.controls.dmsSy.value
          , this.sampleCreateForm.controls.dmsDx.value, this.sampleCreateForm.controls.dmsMx.value, this.sampleCreateForm.controls.dmsSx.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.sampleCreateForm.controls.dmsDy.value, this.sampleCreateForm.controls.dmsMy.value, this.sampleCreateForm.controls.dmsSy.value, this.sampleCreateForm.controls.dmsDx.value
          , this.sampleCreateForm.controls.dmsMx.value, this.sampleCreateForm.controls.dmsSx.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.sampleCreateForm.controls.zone34SNorthing.value, this.sampleCreateForm.controls.zone34SEasting.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.sampleCreateForm.controls.zone34SNorthing.value, this.sampleCreateForm.controls.zone34SEasting.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.sampleCreateForm.controls.zone35SNorthing.value, this.sampleCreateForm.controls.zone35SEasting.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.sampleCreateForm.controls.zone35SNorthing.value, this.sampleCreateForm.controls.zone35SEasting.value)._lon;
    }
    this.sampleTemplate.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.sampleTemplate.locationTemp.coordinates.latitude,
        this.sampleTemplate.locationTemp.coordinates.longitude)._lat;
    this.sampleTemplate.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.sampleTemplate.locationTemp.coordinates.latitude,
        this.sampleTemplate.locationTemp.coordinates.longitude)._lon;

    if (this.validateForm()) {

      if(new LatLon(this.sampleTemplate.locationTemp.coordinates.latitude, this.sampleTemplate.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)
        || this.observe) {
        this.submitted = true;
        if (this.sampleCreateForm.controls.sampleNumber.valid) {

          this.projectService.getProjectById( this.sampleCreateForm.controls.project.value.id).subscribe(value => {
            this.sampleTemplate.project = value as Project;
            this.geoTechService.createSamples(this.sampleTemplate).subscribe(
              value => {
                this.samplesLocationImages = [];
              }
              , error => {
                if(this.title === 'Create Sample') {
                  this.toastr.error(error, 'Sample Create');
                } else {
                  this.toastr.error(error, 'Update Create');
                }
              }, () => {
                this.closeModal();
                if(this.title === 'Create Sample') {
                  this.toastr.success('Sample Created Successfully', 'Sample Create');
                } else {
                  this.toastr.success('Sample Updated Successfully', 'Sample Update');
                }
              }
            );
          });

        }
      } else {
        this.submitted = false;
        this.toastr.error(this.coordinatesService.outSide);
      }
    }
  }

  validateForm() : boolean {
    if (!this.sampleCreateForm.controls.project.value) {
      this.toastr.error('Select Project');
      return false;
    } else if (!this.sampleCreateForm.controls.sampleNumber.value) {
      this.toastr.error('Sample Number missing');
      return false;
    } else if (!this.sampleCreateForm.controls.sampleDate.value && !this.data.entityId) {
      this.toastr.error('Date collected missing');
      return false;
    } else if (!this.collectedBy.value && !this.data.entityId) {
      this.toastr.error('Collected By missing');
      return false;
    } else if (!this.sampleCreateForm.controls.coordinatesType.value) {
      this.toastr.error('Coordinates Type missing');
      return false;
    } else if (!this.sampleCreateForm.controls.coordinatesFormat.value) {
      this.toastr.error('Coordinates Format missing');
      return false;
    } else if (!this.sampleCreateForm.controls.latitude.value) {
      this.toastr.error('Latitude missing');
      return false;
    } else if (!this.sampleCreateForm.controls.longitude.value) {
      this.toastr.error('Longitude missing');
      return false;
    } else {
      return true;
    }
  }

  onFocusOutDistrict() {
    let district = this.sampleCreateForm.controls.district.value;
    if (district && district !== '') {
      let isPresent: boolean;
      isPresent = this.districts.some(function(ds){
          if (district.name) {
            return ds.name === district.name;
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleCreateForm.get('district').setErrors(null);
        } else {
          this.sampleCreateForm.get('district').setErrors({ 'exist': true });
        }
    }
  }

  onFocusOutVillage() {
    let village = this.sampleCreateForm.controls.village.value;
    if (village && village !== '') {
      let isPresent: boolean;
      isPresent = this.villages.some(function(ds){
          if (village.name) {
            return ds.name === village.name;
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleCreateForm.get('village').setErrors(null);
        } else {
          this.sampleCreateForm.get('village').setErrors({ 'exist': true });
        }
    }
  }

  onFocusOutPeople() {
    let person = this.sampleCreateForm.controls.collectedBy.value;
    if (person && person !== '') {
      let isPresent: boolean;
      isPresent = this.people.some(function(ds){
          if (person.id) {
            return ds.id === person.id;
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleCreateForm.get('collectedBy').setErrors(null);
        } else {
          this.sampleCreateForm.get('collectedBy').setErrors({ 'exist': true });
        }
    }
  }

  displayFnVillage(village: Village): string {
    return village && village.name ? village.name : '';
  }

  displayFnDistrict(district: District): string {
    return district && district.name ? district.name : '';
  }

  displayFnPerson(person: Person): string {
    return person && person.name ? (person.name+ ' ' + person.surname) : '';
  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.selectedCoordinatesFormat = selectedData.text;
    this.sampleTemplate.coordinatesFormat.format = this.selectedCoordinatesFormat;
  }

  protected _setInitialCollector() {
    this.filteredOptionsPeople.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.data.sample) {
        // Edit
        let person = this.people.find(pM => pM.id === this.data.sample.collectedBy);
        if (!person) {
          found = false;
        } else {
          found = true;
          this.collectedBy.setValue(person);
          this.collectorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;
      if(this.data.sample){
        let person = this.people.find(pM => pM.id === this.data.sample.collectedBy);
        this.collectedBy.setValue(person);
        this.collectorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
      }

    });
  }

  onFocusOutSampleType() {
    let sampleNumber = this.sampleCreateForm.controls.sampleNumber.value;
    if (sampleNumber !== '') {
      let isPresent: boolean;
      isPresent = this.activities.some(function(ds){
          if (sampleNumber) {
            if (sampleNumber.id) {
              return ds === sampleNumber;
            } else if (sampleNumber === ds.number) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        if (isPresent === true) {
          this.sampleCreateForm.get('sampleNumber').setErrors(null);
        } else {
          this.sampleCreateForm.get('sampleNumber').setErrors({ 'exist': true });
        }
    }
  }

  displayFnSampleNumber(activity: any): string {
    let activityOrNumber: any = activity;
    if (activity){
      if (activity.id) {
        return activity && activity.number ? activity.number : '';
      } else {
        return  activityOrNumber;
      }
    }
  }

  private _filterSampleNumber(sampleNumber: string): string[] {
    const filterValue = sampleNumber.toLowerCase();
    return this.activities.filter(option => option.number.toLowerCase().indexOf(filterValue) === 0);
  }

  loadSampleDetails(value:any) {
      this.geoTechService.getSampleById(value.id).subscribe(data => {
        this.data.sample = data as Sample;
        this.initForms();
        this.displayFnSampleNumber(this.data.sample.sampleNumber);
        this._setInitialCollector();
      }, error => {
        this.toastr.warning(error.message, 'Failed to get Sample details');
      }, () => {
      });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.sampleLocationImage = new NigisImages();
          this.sampleLocationImage.image = fileSplitdata[1];
          this.sampleLocationImage.name = fileName;
          this.samplesLocationImages.push(this.sampleLocationImage);
          this.sampleTemplate.samplesLocationImages = this.samplesLocationImages;
          this.sampleLocationImageInput.nativeElement.value = '';
          this.sampleLocationImageCtrl.setValue(null);
        };
      }
    }
  }
  remove(sampleLocationImage: NigisImages): void {
    const index = this.samplesLocationImages.indexOf(sampleLocationImage);
    if (index >= 0) {
      this.samplesLocationImages.splice(index, 1);
    }
  }
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const sampleLocationImage = new NigisImages;
      sampleLocationImage.image = value;

      if ((value || '').trim()) {
        this.samplesLocationImages.push(sampleLocationImage);
      }

      if (input) {
        input.value = '';
      }

      this.sampleLocationImageCtrl.setValue(null);
    }
  }

}
