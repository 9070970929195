import { startWith, map, take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { CompanyEnum } from './../../../../../shared-kernel/enumerations/company-enum';
import { CompanyService } from './../../../../company/company.service';
import { WaterPumpingTestStepTest } from './../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestStepTest';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { WaterPumpingTest } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTest';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect } from '@angular/material';
import { WaterPumpingTestTypeEnum } from 'src/app/shared-kernel/enumerations/water-pumping-test-type-enum';
import { Router, UrlTree, PRIMARY_OUTLET, UrlSegment, ActivatedRoute } from '@angular/router';
import { HydrogeologyService } from '../../../hydrogeology.service';
import { ToastrService } from 'ngx-toastr';
import { HydrogeologyValidation } from '../../../hydrogeology.validation';
import { UserService } from 'src/app/auth/user/user.service';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { WaterPumpingTestType } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestType';
import { Person } from 'src/app/common/entity/security/profile/person';
import { NgxSpinnerService } from 'ngx-spinner';
import { WaterPumpingTestHelper } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterPumpingTestHelper';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import * as moment from'moment';
import { Size } from 'src/app/shared-kernel/entity/common/size';
declare var $: any;

@Component({
  selector: 'app-water-pump-test-step-test-create',
  templateUrl: './water-pump-test-step-test-create.component.html',
  styleUrls: ['./water-pump-test-step-test-create.component.scss']
})
export class WaterPumpTestStepTestCreateComponent implements OnInit {
  @Input('currentState') currentState: string = '';
  dataSourceWaterPumpingTest: MatTableDataSource<WaterPumpingTest> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  url: string;
  boreholeId: any;
  waterPumpingTest: WaterPumpingTest;
  waterPumpingTestStepTest: WaterPumpingTestStepTest;
  waterPumpingTests: WaterPumpingTest[];
  waterPumpingTestTypeEnum: typeof WaterPumpingTestTypeEnum = WaterPumpingTestTypeEnum;
  person: Person;
  persons: Person[];
  loading = true;
  testCreateForm: FormGroup;
  title = '';

  people: Person[] = [];
  loadingPeople = false;

  filteredOptionsPeople: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  @ViewChild('collectorSelect') collectorSelect: MatSelect;
  collectedBy: FormControl = new FormControl();
  collectorFilter: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  currentUser: any;
  unitEnum: typeof UnitEnum = UnitEnum;

  validation_messages_water_pumping_test = this.hydrogeologyValidation.validation_messages_water_pumping_test;

  constructor(
    private hydrogeologyService: HydrogeologyService,
    private toastr: ToastrService,
    private hydrogeologyValidation: HydrogeologyValidation,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WaterPumpTestStepTestCreateComponent>,
    private companyService: CompanyService) {

      this.route.params.subscribe(
        data => {
          this.boreholeId = data.id;
        }
      );

    }

  ngOnInit() {
    this.intForm();
    this.getAllGeologists();
    this.getAllWaterPumpingTestStepTestByBoreholeId();
    this.person = new Person();
    this.waterPumpingTest = new WaterPumpingTest();
    this.waterPumpingTest.borehole = new Borehole();
    this.waterPumpingTest.waterPumpingTestType = new WaterPumpingTestType();
    this.waterPumpingTests = [];

    this.getAllBGIPersonnel();
    if(this.data.transactionType === 'New') {
      this.title = 'Create Step Test';
    }
    if(this.data.transactionType === 'Edit') {
      this.title = 'Edit Step Test';
    }
  }

  getAllBGIPersonnel() {
    this.loadingPeople = true;
    this.companyService.getCompanyByName(CompanyEnum.BGI_NAME).subscribe(company => {
      this.userService.getPersonsByCompanyId(company.id).subscribe(
        data => {
          this.people = data as Person[];
          this.people.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
          this.filteredOptionsPeople.next(this.people.slice());
          this.collectorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.collectorFilterFnc();
          });

          this._setInitialCollector();
          this.loadingPeople = false;
        }
      );
    });
  }

  protected _setInitialCollector() {
    this.filteredOptionsPeople.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.data.test) {
        // Edit
        let person = this.people.find(pM => pM.id === this.data.test.geologist);
        if (!person) {
          found = false;
        } else {
          found = true;
          this.collectedBy.setValue(person);
          this.collectorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }
      if (found === true) return;

      let person = this.people.find(pM => pM.id === this.data.test.geologist);
      this.collectedBy.setValue(person);
      this.collectorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  collectorFilterFnc() {
    if (!this.people) return;
    let search = this.collectorFilter.value;
    if (!search) {
      this.filteredOptionsPeople.next(this.people.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptionsPeople.next(
      this.people.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }


  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  intForm(){

    this.testCreateForm = this.formBuilder.group({
      stepNumber: [this.data.test ? this.data.test.stepNumber : null, Validators.required],
      pumpType: [this.data.test ? this.data.test.pumpType : null, Validators.required],
      startDate: [this.data.test ? new Date(this.data.test.startDate) : null, Validators.required],
      endDate: [this.data.test ? new Date(this.data.test.endDate) : null, Validators.required],
      depthToWater: [this.data.test ? this.data.test.depthToWater? this.data.test.depthToWater.measure : '' : ''],
      drawdown: [this.data.test ? this.data.test.drawdown ? this.data.test.drawdown.measure : '' : ''],
      startTime: [this.data.test ? moment(new Date(this.data.test.startTime)).format('hh:mm') : null, Validators.required],
      endTime: [this.data.test ? moment(new Date(this.data.test.endTime)).format('hh:mm') : null, Validators.required],
      staticWaterLevel: [this.data.test ? this.data.test.staticWaterLevel : ''],
      pumpingRate: [this.data.test ? this.data.test.pumpingRate ? this.data.test.pumpingRate.measure : '' : ''],
      timeToFillLitres: [this.data.test ? this.data.test.timeToFillLitres ? this.data.test.timeToFillLitres.measure : '' : ''],
      timeToFillDuration: [this.data.test ? this.data.test.timeToFillDuration : ''],
      geologist: [this.data.test ? this.data.test.geologist : null, Validators.required],
      comments: [this.data.test ? this.data.test.comments : '']
    });

  }


  closeModal() {
    this.dialogRef.close();
  }


  onSubmit() {
      if (this.hydrogeologyValidation.projectDatesvalid(this.waterPumpingTest.startDate, this.waterPumpingTest.endDate,this.waterPumpingTest.startTime,this.waterPumpingTest.endTime)){

        this.waterPumpingTestStepTest = new WaterPumpingTestStepTest;

        if(this.data.test) {
          this.waterPumpingTestStepTest = this.data.test;
        }
        this.waterPumpingTestStepTest.geologist = this.collectedBy.value.id;

        this.waterPumpingTestStepTest.borehole = this.data.borehole;

        this.waterPumpingTestStepTest.stepNumber = this.testCreateForm.controls.stepNumber.value;
        this.waterPumpingTestStepTest.pumpType = this.testCreateForm.controls.pumpType.value;
        this.waterPumpingTestStepTest.startDate = new Date(Date.parse(new Date(this.testCreateForm.controls.startDate.value).toLocaleDateString()));
        this.waterPumpingTestStepTest.endDate = new Date(Date.parse(new Date(this.testCreateForm.controls.endDate.value).toLocaleDateString()));
        this.waterPumpingTestStepTest.depthToWater = new Size;
        this.waterPumpingTestStepTest.depthToWater.measure = this.testCreateForm.controls.depthToWater.value;
        this.waterPumpingTestStepTest.depthToWater.unit.id = this.unitEnum.meter;
        this.waterPumpingTestStepTest.drawdown = new Size;
        this.waterPumpingTestStepTest.drawdown.measure = this.testCreateForm.controls.drawdown.value;
        this.waterPumpingTestStepTest.drawdown.unit.id = this.unitEnum.meter;
        this.waterPumpingTestStepTest.startTime = new Date(Date.parse(new Date(this.testCreateForm.controls.startDate.value).toLocaleDateString()
        + ' ' + this.testCreateForm.controls.startTime.value));
        this.waterPumpingTestStepTest.endTime = new Date(Date.parse(new Date(this.testCreateForm.controls.endDate.value).toLocaleDateString()
        + ' ' + this.testCreateForm.controls.endTime.value));
        this.waterPumpingTestStepTest.staticWaterLevel = this.testCreateForm.controls.staticWaterLevel.value;
        this.waterPumpingTestStepTest.pumpingRate = new Size;
        this.waterPumpingTestStepTest.pumpingRate.measure = this.testCreateForm.controls.pumpingRate.value;
        this.waterPumpingTestStepTest.pumpingRate.unit.id = this.unitEnum.pumpRate;
        this.waterPumpingTestStepTest.timeToFillLitres = new Size;
        this.waterPumpingTestStepTest.timeToFillLitres.measure = this.testCreateForm.controls.timeToFillLitres.value;
        this.waterPumpingTestStepTest.timeToFillLitres.unit.id =this.unitEnum.liter;
        this.waterPumpingTestStepTest.timeToFillDuration = this.testCreateForm.controls.timeToFillDuration.value;

        this.waterPumpingTestStepTest.comments = this.testCreateForm.controls.comments.value;

        this.spinner.show();
        this.hydrogeologyService.createWaterPumpingTestStepTestOne(this.waterPumpingTestStepTest).subscribe(data => {
            const waterPumpingTest = data as WaterPumpingTest;
            this.waterPumpingTests.push(waterPumpingTest);
            this.dataSourceWaterPumpingTest = new MatTableDataSource(this.waterPumpingTests);
            this.successmsg('Create Water Pumping Test', 'You have Successfully submitted Water Pumping Test');
            this.closeModal();
            this.spinner.hide();
          }, error => {
            this.errorsmsg('Error', error.error.message);
            this.spinner.hide();
          },
            () => {
              this.spinner.hide();
            }
        );


    }
  }

  getAllGeologists() {
    this.userService.getPersons()
      .subscribe(data => {
        this.persons = data as Person[];
      });
  }

  getAllWaterPumpingTestStepTestByBoreholeId() {
    this.hydrogeologyService.getAllWaterPumpingTestByBoreholeIdAndWaterPumpingTestTypeId(this.boreholeId, this.waterPumpingTestTypeEnum.StepTest)
    .subscribe(data => {
      this.waterPumpingTests = data as WaterPumpingTest[];
      this.dataSourceWaterPumpingTest.data = data as WaterPumpingTest[];
      this.loading = false;
    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  errorsmsg(title, message) {
    this.toastr.error(message, title);
  }

  successmsg(title, message) {
    this.toastr.success(message, title);
  }
}
