import { Injectable, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class BoreholeValidation implements OnInit {

  validation_messages = {
    'project': [
      { type: 'required', message: 'Please select project' }
    ],
    'boreholeNumber': [
      { type: 'required', message: 'Borehole number is required' },
    ],
    'startDate': [
      { type: 'required', message: 'Borehole drilling start date is required' },
    ],
    'completedDate': [
      { type: 'required', message: 'Borehole drilling completion date is required' },
    ],
    'longitude': [
      { type: 'required', message: 'Borehole longitude is required' },
    ],
    'latitude': [
      { type: 'required', message: 'Borehole latitude is required' },
    ],
    'district': [
      { type: 'required', message: 'Please select district' },
    ],
    'village': [
      { type: 'required', message: 'Village is required' },
    ],
    'objective': [
      { type: 'required', message: 'Borehole objective is required' },
    ],
    'rigType': [
      { type: 'required', message: 'Please select Rig type' },
    ],
    'drillingMethod': [
      { type: 'required', message: 'Please select Drilling method' },
    ],
    'sampleType': [
      { type: 'required', message: 'Please select Sample Type' },
    ],
    'prospectingLicense': [
      { type: 'required', message: 'Please select Prospecting License Number' },
    ],
    'boreholeOwner': [
      { type: 'required', message: 'Please select Borehole Owner' },
    ],
    'endDepth': [
      { type: 'required', message: 'End depth is required' },
    ],
    'elevation': [
      { type: 'required', message: 'Elevation is required' },
    ],
    'driller': [
      { type: 'required', message: 'Driller is required' },
    ]
    ,
    'client': [
      { type: 'required', message: 'Client is required' },
    ],
    'consultant': [
      { type: 'required', message: 'Consultant is required' },
    ],
    'wellfield': [
      { type: 'required', message: 'Wellfield is required' },
    ],
    'descriptionOfDrillingFluidsUsed': [
      { type: 'required', message: 'Description Of Drilling Fluids Used is required' },
    ],
    'typeOfDev': [
      { type: 'required', message: 'Type Of Dev is required' },
    ],
    'casingHeight': [
      { type: 'required', message: 'Casing Height is required' },
    ],
    'swl': [
      { type: 'required', message: 'Swl is required' },
    ],
    'dip': [
      { type: 'required', message: 'Dip is required' },
    ],
    'azimuth': [
      { type: 'required', message: 'Azimuth is required' },
    ],
    'statusAtEndOfDrilling': [
      { type: 'required', message: 'Status At End Of Drilling is required' },
    ],
    'type': [
      { type: 'required', message: 'Select Coordinates Type' },
    ],
    'developmentType': [
      { type: 'required', message: 'development Type' },
    ],
    'format': [
      { type: 'required', message: 'Select Coordinates Format' },
    ],
    'zone34SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone34SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'zone5SNorthing': [
      { type: 'required', message: 'Type nothing value' },
    ],
    'zone35SEasting': [
      { type: 'required', message: 'Type easting value' },
    ],
    'dmsSx': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMx': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDx': [
      { type: 'required', message: 'Type degree value' },
    ],
    'dmsSy': [
      { type: 'required', message: 'Type seconds value' },
    ],
    'dmsMy': [
      { type: 'required', message: 'Type minutes value' },
    ],
    'dmsDy': [
      { type: 'required', message: 'Type degree value' },
    ],
  'holeInclination': [
    {type: 'required', message: 'holeInclination is required'},
  ],
  'ownerType': [
    { type: 'required', message: 'Select Owner Type' },
  ],
  };

  constructor(private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  boreholeDrillingDatesvalid(openDate: Date, endDate: Date): boolean {
    if (openDate > endDate) {
      this.errorsmsg('Please make sure Borehole drilling start date is BEFORE completion date');
      return false;
    } else {
      return true;
    }
}

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }
  validateInclination(valueTobeValidated: number) {
    if ((valueTobeValidated >= -90) && (valueTobeValidated <= 90)) {
      return true;
    } else {
      this.toastr.error('Inclination should be between -90 and 90', 'Borehole Inclination');
      return false;
    }
  }

  validateAzimuth(valueTobeValidated: number) {
    if ((valueTobeValidated >= 0) && (valueTobeValidated <= 360)) {
      return true;
    } else {
      this.toastr.error('Azimuth should be between 0 and 360', 'Azimuth');
      return false;
    }
  }

}
