import { ProspectingLicensesComponent } from './prospecting-licenses.component';
import { ProspectingLicenceDetailComponent } from './prospecting-licence-detail/prospecting-licence-detail.component';
import { ProspectingLicenceListComponent } from './prospecting-licence-list/prospecting-licence-list.component';
import { ProspectingLicenseService } from './prospecting-license.service';
import { ProspectingLicenseValidation } from './prospecting-license.validation';

export const PLComponent = ProspectingLicensesComponent;
export const PLDetailsComponent = ProspectingLicenceDetailComponent;
export const PLListComponent = ProspectingLicenceListComponent;
export const PLService = ProspectingLicenseService;
export const PLValidation = ProspectingLicenseValidation;
