import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { DrillerLogRecord } from 'src/app/shared-kernel/entity/applied-geoscience/drillerlog/drillerlogrecord';

@Injectable({
  providedIn: 'root'
})
export class DrillerlogService {

  constructor(private http: HttpClient) {
  }

  getAllDrillersLogRecords(): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllDrillerLogRecordsUrl);
  }

  deleteDrillerLogRecord(drillerLogRecord: DrillerLogRecord): Observable<any>  {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteDrillerLogRecordsUrl, drillerLogRecord);
  }

  createDrillersLogRecord(drillerLogRecord: DrillerLogRecord): Observable<any>  {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createDrillerLogRecordUrl, drillerLogRecord);
  }

  createDrillersLogRecords(drillerLogRecord: DrillerLogRecord[]): Observable<any> {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createDrillerLogRecordsUrl, drillerLogRecord);
  }

  getAllDrillerLogRecordsByBoreholeId(id: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDrillerLogRecordsByBoreholeUrl + '/' + id);
  }

  getDrillerLogRecordById(id: string): Observable<any> {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDrillerLogRecordByIdUrl + '/' + id);
  }

  updateDrillerLogRecord(drillerLogRecord: DrillerLogRecord): Observable<any> {
    return this.http.put(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.updateDrillerLogRecordUrl, drillerLogRecord);

  }

}
