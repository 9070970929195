import { PersonnelCreateComponent } from './../../../../personnel/personnel-create/personnel-create.component';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeoTechService } from '../../geo-tech.service';
import { ToastrService } from 'ngx-toastr';
import { Sample} from '../../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Project } from '../../../../shared-kernel/entity/applied-geoscience/project/project';
import { ProjectService } from '../../../project/project.service';
import { CoordinatesTypesEnum } from '../../../../shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from '../../../../shared-kernel/enumerations/coordinates-formats-enumt';
import { CoordinatesService } from '../../../../shared-kernel/entity/common/coordinatesValidator/coordinates.service';
import { Village } from '../../../../shared-kernel/entity/common/Village';
import { CommonService } from '../../../../common/common.service';
import { District } from '../../../../common/entity/security/util/common/district';
import { CompanyService } from '../../../company/company.service';
import { SampleType } from '../../../../shared-kernel/entity/common/sample-type';
import { DrillerService } from '../../../../driller/driller.service';
import { NigisImages } from '../../../../shared-kernel/entity/common/nigisImages';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {MatAutocomplete, MatChipInputEvent, MatSelect,
  MatDialog, MatDialogConfig} from '@angular/material';
import { Person } from '../../../../_core/data/_models/people.model';
import LatLon from 'geodesy/latlon-nvector-spherical';
import { Coordinate } from '../../../../shared-kernel/entity/common/coordinatesValidator/coordinate';
import { Router } from '@angular/router';
import { Company } from '../../../../shared-kernel/entity/applied-geoscience/company/company';
import { ProjectMembers } from 'src/app/applied-geoscience/project/project-detail/project-members/project-member.model';
import { ReplaySubject, Subject, Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { take, takeUntil } from 'rxjs/operators';
import { PeopleData } from 'src/app/_core/data/people';
import { StorageConstants } from 'src/app/_core/storage.constants';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { BusinessUnitEnum } from 'src/app/shared-kernel/enumerations/business-unit-enum';

@Component({
  selector: 'app-sample-create',
  templateUrl: './sample-create.component.html',
  styleUrls: ['./sample-create.component.scss']
})
export class SampleCreateComponent implements OnInit {

  sampleTemplate: Sample = new Sample();
  businessUnitEnum: typeof BusinessUnitEnum = BusinessUnitEnum;
  sampleCreateForm: FormGroup;
  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  businessUnittype = "";
  villages: Village[];
  filteredVillages: Observable<Village[]>;
  filteredDistricts: Observable<District[]>;
  districts: District[];
  district: District = new District();
  sampleTypes: SampleType[];
  projects: any;
  projectsByMember: any;
  sampleLocationImages: NigisImages[] = [];
  sampleLocationImage: NigisImages;
  selectable = true;
  removable = true;
  addOnBlur = true;
  sampleLocationImageCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('sampleLocationImageInput') sampleLocationImageInput: ElementRef<HTMLInputElement>;
  botswanaPolygon: any[];
  latLonArray: Coordinate = new Coordinate();
  title: string;
  allCompanies: Company [];
  currentUser: any;

  projectMembers: Array<ProjectMembers> = [];
  personMembers: Array<Person> = [];

  // filteredManagers: Array<Person> = [];
  filteredManagers: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredCoordinators: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);
  filteredGeologists: ReplaySubject<Array<Person>> = new ReplaySubject<Array<Person>>(1);

  currentManager: FormControl = new FormControl();
  managerFilter: FormControl = new FormControl();

  currentCoordinator: FormControl = new FormControl();
  coordinatorFilter: FormControl = new FormControl();

  currentGeologist: FormControl = new FormControl();
  geologistFilter: FormControl = new FormControl();

  @ViewChild('managerSelect') managerSelect: MatSelect;
  @ViewChild('coordinatorSelect') coordinatorSelect: MatSelect;
  @ViewChild('geologistSelect') geologistSelect: MatSelect;
  protected _onDestroy = new Subject<void>();

  projectStillLoading = true;
  projectByMemberStillLoading = true;
  districtStillLoading = true;
  villageStillLoading = true;
  sampleTypeStillLoading = true;
  projectMemberStillLoading = true;

  constructor(
    private _router: Router,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SampleCreateComponent>,
    private geotechService: GeoTechService,
    private projectService: ProjectService,
    private drillerService: DrillerService,
    private toast: ToastrService,
    private toastr: ToastrService,
    private coordinatesService: CoordinatesService,
    private commonService: CommonService,
    private  companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: Sample,
    private peopleSrv: PeopleData,
    private dialog: MatDialog,
    private _localStorage: LocalStorage) {
  }


  ngOnInit() {
    this.selectedCoordinatesType = this.coordinatesTypesEnum.WGS84;
    this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    this.getBotswanaGeofence();
    this._localStorage.getItem(StorageConstants.fullUserKey).subscribe(res => {
      this.currentUser = res;
      this.businessUnittype = this.currentUser.businessUnit.name;
      this.getProjectsByMember(this.currentUser.username);
      }, err => {
        this.toastr.warning(err.message, "Current User Error");
      },() => {

      });
    this.getAllDistricts();
    this.getAllVillages();
    this.getAllSampleTypes();
    this.initForms();

    this.districtNameControl.valueChanges.subscribe(
      value => {
        this.filteredDistricts = of(this._filterDistricts(value || ''))
      }
    );

    this.villageNameControl.valueChanges.subscribe(
      value => {
        this.filteredVillages = of(this._filterVillages(value || ''))
      }
    );

    if (this.data) {
      this.title = 'Edit Sample';
    } else {
      this.title = 'Create Sample';
    }
    this._getProjectMembers();

  }

  get districtNameControl() {
    return this.sampleCreateForm.get('district').get('name');
  }
  
  get districtControl() {
    return this.sampleCreateForm.get('district');
  }

  get villageNameControl() {
    return this.sampleCreateForm.get('village').get('name');
  }

  get villageControl() {
    return this.sampleCreateForm.get('village');
  }

  ngAfterViewInit() {
    this._setInitialManager();
    this._setInitialCoordinator();
    this._setInitialGeologist();
  }

  getBotswanaGeofence() {
    this.botswanaPolygon = [];
    this.coordinatesService.getGeofenceData().subscribe(data => {
      this.latLonArray = data as Coordinate;
      for (const i in this.latLonArray.coordinates) {
        // tslint:disable-next-line:forin
        for (const j in this.latLonArray.coordinates[i]) {
          this.botswanaPolygon.push(new LatLon(this.latLonArray.coordinates[i][j][1], this.latLonArray.coordinates[i][j][0]));
        }
      }
    });
  }

  remove(sampleLocationImage: NigisImages): void {
    const index = this.sampleLocationImages.indexOf(sampleLocationImage);
    if(index >= 0) {
      this.sampleLocationImages.splice(index, 1);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private initForms() {

    if(this.data) {
      this.sampleLocationImages = this.data.samplesLocationImages;
      this.selectedCoordinatesFormat = this.data.coordinatesFormat.format;
    }

    this.sampleCreateForm = this.formBuilder.group({
      sampleDate: [this.data ? new Date(this.data.sampleDate) : null, Validators.required],
      project: [this.data ? this.data.project : null, Validators.required],
      district: this.formBuilder.group({
        id: [this.data ? this.data.locationTemp.village.district.id : null, Validators.required],
        code: [this.data ? this.data.locationTemp.village.district.code : null, Validators.required],
        name: [this.data ? this.data.locationTemp.village.district.name : null, Validators.required],
      }),
      village: this.formBuilder.group({
        id: [this.data ? this.data.locationTemp.village.id : null, Validators.required],
        name: [this.data ? this.data.locationTemp.village.name : null, Validators.required],
      }),
      sampleNumber: [this.data ? this.data.sampleNumber : null, Validators.required],
      sampleObjective: [this.data ? this.data.sampleObjective.description : ''],
      sampleTypes: [this.data ? this.data.sampleTypes : null, Validators.required],
      coordinatesFormat: [this.data ? this.data.coordinatesFormat.format : null, Validators.required],
      samplesLocationImages: [this.data ? this.data.samplesLocationImages : null, Validators.required],
      sampleManager: [this.data ? this.data.projectManager : ''],
      sampleCoordinator: [this.data ? this.data.projectManager : ''],
      sampleGeologist: [this.data ? this.data.projectGeologist : ''],
      locationDescription: [this.data ? this.data.locationDescription.description : ''],
      latitude: [this.data ? this.data.locationTemp.coordinates.latitude : ''],
      longitude: [this.data ? this.data.locationTemp.coordinates.longitude : ''],
      coordinatesType: [this.data ? this.data.coordinatesFormat.type : '', Validators.required],
      dmsSx: [this.data ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._lonSValue) : ''],// decimal minutes seconds, to hold x/longitude seconds value
      dmsMx: [this.data ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._lonMValue) : ''], // decimal minutes seconds, to hold x/longitude minutes value
      dmsDx: [this.data ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._lonDValue) : ''], // decimal minutes seconds, to hold x/longitude decimal value
      dmsSy: [this.data ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._latSValue) : ''], // decimal minutes seconds, to hold y/latitude seconds value
      dmsMy: [this.data ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._latMValue) : ''],// decimal minutes seconds, to hold y/latitude minutes value
      dmsDy: [this.data ? parseFloat(this.coordinatesService.convertLatLonToDms(parseFloat(this.data.locationTemp.coordinates.latitude),
        parseFloat(this.data.locationTemp.coordinates.longitude))._latDVale) : ''],// decimal minutes seconds, to hold y/latitude decimal value
      zone34SNorthing: [this.data ? this.coordinatesService.convertLatLonToUtm(34, this.data.locationTemp.coordinates.latitude, this.data.locationTemp.coordinates.longitude)._lat : ''],
      zone34SEasting: [this.data ? this.coordinatesService.convertLatLonToUtm(34, this.data.locationTemp.coordinates.latitude, this.data.locationTemp.coordinates.longitude)._lon : ''],
      zone35SNorthing: [''],
      zone35SEasting: [''],
    });

  }

  goTo(filter: string) {
    this._router.navigate([`portal/personnel/${filter}`]);
    this.closeModal();
  }

  newPersonnel() {
    const newPersonnelDialogRef = this.dialog.open(PersonnelCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true
    });

    newPersonnelDialogRef.afterClosed().subscribe((result) => {
      this._getProjectMembers();
    });
  }

  getAllSampleTypes() {
    this.drillerService.getAllSampleTypes()
      .subscribe(data => {
        this.sampleTypes = data as SampleType[];
        this.sampleTypeStillLoading = false;
      });
  }

  getAllDistricts() {
    this.companyService.getAllDistricts()
      .subscribe(data => {
        this.districts = data as District[];
        this.districts.sort((a, b) => ( a.name || '' ).toString().localeCompare(( b.name || '' ).toString()));

        this.filteredDistricts =
          this.districtNameControl.valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name ? this._filterDistricts(name) : this.districts.slice()
            )
          );
        this.districtStillLoading = false;
      });
  }

  getAllVillages() {
    this.commonService.getAllVillages()
      .subscribe(data => {
        this.villages = data as Village[];
        this.villages.sort((a, b) => ( a.name || '' ).toString().localeCompare(( b.name || '' ).toString()));

        this.filteredVillages =
          this.villageNameControl.valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) =>
              name ? this._filterVillages(name) : this.villages.slice()
            )
          );
        this.villageStillLoading = false;
      });
  }

  private getProjects() {
    this.projectService.getAllProjectName().subscribe(res => {
      this.projects = res;
      this.projectStillLoading = false;
    }, err => {
    });
  }
  private getProjectsByMember(user: string) {
    this.projectService.getProjectsByMember(user).subscribe(res => {
      this.projectsByMember = res;
      this.projectStillLoading = false;
    }, err => {
    });
  }

  onSubmit() {
    if(this.data) {
      this.sampleTemplate = this.data;
    }

    if(this.sampleTemplate.id){
      if (this.currentManager.value) {
        if (this.currentManager.value.id && this.sampleTemplate.projectManager !== this.currentManager.value.id) {
          this.sampleTemplate.projectManager = this.currentManager.value.id;
        }
      }

      if (this.currentCoordinator.value) {
        if (this.currentCoordinator.value.id && this.sampleTemplate.projectCoordinator !== this.currentCoordinator.value.id) {
          this.sampleTemplate.projectCoordinator = this.currentCoordinator.value.id;
        }
      }

      if (this.currentGeologist.value) {
        if (this.currentGeologist.value.id && this.sampleTemplate.projectGeologist !== this.currentGeologist.value.id) {
          this.sampleTemplate.projectGeologist = this.currentGeologist.value.id;
        }
      }
    } else {
      this.sampleTemplate.projectManager = this.currentManager.value && this.currentManager.value.id ? this.currentManager.value.id : '';
      this.sampleTemplate.projectCoordinator = this.currentCoordinator.value && this.currentCoordinator.value.id ? this.currentCoordinator.value.id : '';
      this.sampleTemplate.projectGeologist = this.currentGeologist.value && this.currentGeologist.value.id ? this.currentGeologist.value.id : '';
    }

    this.sampleTemplate.deleted = false;
    this.sampleTemplate.sampleObjective.description = this.sampleCreateForm.controls.sampleObjective.value;
    this.sampleTemplate.sampleDate = this.sampleCreateForm.controls.sampleDate.value;
    this.sampleTemplate.sampleNumber = this.sampleCreateForm.controls.sampleNumber.value;
    this.sampleTemplate.locationTemp.village = this.sampleCreateForm.controls.village.value;
    this.sampleTemplate.locationDescription.description = this.sampleCreateForm.controls.locationDescription.value;
    this.sampleTemplate.samplesLocationImages = this.sampleLocationImages;
    this.sampleTemplate.sampleTypes = this.sampleCreateForm.controls.sampleTypes.value;
    this.sampleTemplate.coordinatesFormat.type = this.sampleCreateForm.controls.coordinatesType.value;
    this.sampleTemplate.coordinatesFormat.format = this.sampleCreateForm.controls.coordinatesFormat.value;
    this.sampleTemplate.locationTemp.coordinates.latitude = this.sampleCreateForm.controls.latitude.value;
    this.sampleTemplate.locationTemp.coordinates.longitude = this.sampleCreateForm.controls.longitude.value;
    if(this.businessUnittype == "Mineral Resources/Hydrocarbon"){

      this.sampleTemplate.sampleOwnerUnit = this.businessUnitEnum.mineralResources;

    }



    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84 &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.DMS
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertDmsToLatLon(this.sampleCreateForm.controls.dmsDy.value, this.sampleCreateForm.controls.dmsMy.value, this.sampleCreateForm.controls.dmsSy.value
          , this.sampleCreateForm.controls.dmsDx.value, this.sampleCreateForm.controls.dmsMx.value, this.sampleCreateForm.controls.dmsSx.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertDmsToLatLon(this.sampleCreateForm.controls.dmsDy.value, this.sampleCreateForm.controls.dmsMy.value, this.sampleCreateForm.controls.dmsSy.value, this.sampleCreateForm.controls.dmsDx.value
          , this.sampleCreateForm.controls.dmsMx.value, this.sampleCreateForm.controls.dmsSx.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE34S
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.sampleCreateForm.controls.zone34SNorthing.value, this.sampleCreateForm.controls.zone34SEasting.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('34 S', this.sampleCreateForm.controls.zone34SNorthing.value, this.sampleCreateForm.controls.zone34SEasting.value)._lon;
    }

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM &&
      this.selectedCoordinatesFormat === this.coordinatesFormatsEnum.ZONE35S
    ) {
      this.sampleTemplate.locationTemp.coordinates.latitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.sampleCreateForm.controls.zone35SNorthing.value, this.sampleCreateForm.controls.zone35SEasting.value)._lat;
      this.sampleTemplate.locationTemp.coordinates.longitude =
        this.coordinatesService.convertUtmToLatLon('35 S', this.sampleCreateForm.controls.zone35SNorthing.value, this.sampleCreateForm.controls.zone35SEasting.value)._lon;
    }
    this.sampleTemplate.locationTemp.coordinates.latitude =
      this.coordinatesService.formatLatLon(this.sampleTemplate.locationTemp.coordinates.latitude,
        this.sampleTemplate.locationTemp.coordinates.longitude)._lat;
    this.sampleTemplate.locationTemp.coordinates.longitude =
      this.coordinatesService.formatLatLon(this.sampleTemplate.locationTemp.coordinates.latitude,
        this.sampleTemplate.locationTemp.coordinates.longitude)._lon;



      if(new LatLon(this.sampleTemplate.locationTemp.coordinates.latitude, this.sampleTemplate.locationTemp.coordinates.longitude).isEnclosedBy(this.botswanaPolygon)) {
        if (this.sampleCreateForm.controls.sampleNumber.valid) {

          this.projectService.getProjectById( this.sampleCreateForm.controls.project.value.id).subscribe(value =>
          {
            this.sampleTemplate.project = value as Project;
            this.geotechService.createSamples(this.sampleTemplate).subscribe(
                value => {
                }
                , error => {
                  if(this.title === 'Create Sample') {
                    this.toastr.error(error, 'Sample Create');
                  } else {
                    this.toastr.error(error, 'Update Create');
                  }
                }
                , () => {
                  this.closeModal();

                  if(this.title === 'Create Sample') {
                    this.toastr.success('Sample Created Successfully', 'Sample Create');
                  } else {
                    this.toastr.success('Sample Updated Successfully', 'Sample Update');
                  }
                }
              );
            });

        }
      } else {
        this.toast.error(this.coordinatesService.outSide);
      }
  }

  selectedType(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.resetRequiredValue();
    this.selectedCoordinatesType = selectedData.text;

    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.WGS84) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.DD;
    }
    if(this.selectedCoordinatesType === this.coordinatesTypesEnum.UTM) {
      this.selectedCoordinatesFormat = this.coordinatesFormatsEnum.ZONE34S;
    }
  }

  onDistrictChange(district: any) {

      let selectedDistrict = this.districtControl.value;
      this.filteredVillages = of(this.villages.filter(village => village.district.id === district.id))

      this.sampleCreateForm.controls.district.setValue({
        id: district.id,
        name: district.name,
        code: district.code
      });

      if(district.id !== selectedDistrict.id) {
        this.sampleCreateForm.controls.village.setValue({
          id: '',
          name: ''
        });
      }
  }

  onVillageChange(village: any) {
    this.sampleCreateForm.controls.district.setValue({
      id: village.district.id,
      name: village.district.name,
      code: village.district.code
    });

    this.sampleCreateForm.controls.village.setValue({
      id: village.id,
      name: village.name
    });
  }

  private _filterDistricts(value: string): District[] { 
    
    const filterValue = value.toLowerCase();
    return this.districts ? this.districts.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  private _filterVillages(value: string): Village[] {

    const filterValue = value.toLowerCase();

    if(this.villages) {
      return this.villages.filter(option => {
        let district = this.districtControl.value;

        if(district && district.id) {
          return option.name.toLowerCase().includes(filterValue) && option.district.id === district.id;
        } else {
          return option.name.toLowerCase().includes(filterValue);
        }
      });
    } else {
      return [];
    }
  }

  add(event: MatChipInputEvent): void {
    if(!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      const chipBoreholeLocationImage = new NigisImages;
      chipBoreholeLocationImage.name = value;

      if(( value || '' ).trim()) {
        this.sampleLocationImages.push(chipBoreholeLocationImage);
      }

      if(input) {
        input.value = '';
      }

      this.sampleLocationImageCtrl.setValue(null);
    }
  }

  onSelectFile(event) {
    if(event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        const file = event.target.files[i];
        const fileName = file.name;
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const fileSplitdata = reader.result.toString().split(',');
          this.sampleLocationImage = new NigisImages();
          this.sampleLocationImage.image = fileSplitdata[1];
          this.sampleLocationImage.name = fileName;
          this.sampleLocationImages.push(this.sampleLocationImage);
          this.sampleLocationImageCtrl.setValue(null);
        };
      }
    }
  }

  selectedFormat(event) {
    const target = event.source.selected._element.nativeElement;
    const selectedData = {
      value: event.value,
      text: target.innerText.trim()
    };
    this.resetRequiredValue();
    this.selectedCoordinatesFormat = selectedData.text;
    switch (this.selectedCoordinatesFormat) {
      case this.coordinatesFormatsEnum.DMS:
        this.sampleCreateForm.controls.dmsSx.setValidators(Validators.required);
        this.sampleCreateForm.controls.dmsMx.setValidators(Validators.required);
        this.sampleCreateForm.controls.dmsDx.setValidators(Validators.required);
        this.sampleCreateForm.controls.dmsSy.setValidators(Validators.required);
        this.sampleCreateForm.controls.dmsMy.setValidators(Validators.required);
        this.sampleCreateForm.controls.dmsDy.setValidators(Validators.required);
        break;
      case this.coordinatesFormatsEnum.DD:
        this.sampleCreateForm.controls.longitude.setValidators(Validators.required);
        this.sampleCreateForm.controls.latitude.setValidators(Validators.required);
        break;
      case this.coordinatesFormatsEnum.ZONE34S:
        this.sampleCreateForm.controls.zone34SNorthing.setValidators(Validators.required);
        this.sampleCreateForm.controls.zone34SEasting.setValidators(Validators.required);
        break;
      case this.coordinatesFormatsEnum.ZONE35S:
        this.sampleCreateForm.controls.zone35SNorthing.setValidators(Validators.required);
        this.sampleCreateForm.controls.zone35SEasting.setValidators(Validators.required);
        break;
    }
  }

  resetRequiredValue(){
    this.sampleCreateForm.controls.dmsSx.clearValidators();
    this.sampleCreateForm.controls.dmsSx.setErrors(null);
    this.sampleCreateForm.controls.dmsSx.updateValueAndValidity();

    this.sampleCreateForm.controls.dmsMx.clearValidators();
    this.sampleCreateForm.controls.dmsMx.setErrors(null);
    this.sampleCreateForm.controls.dmsMx.updateValueAndValidity();

    this.sampleCreateForm.controls.dmsDx.clearValidators();
    this.sampleCreateForm.controls.dmsDx.setErrors(null);
    this.sampleCreateForm.controls.dmsDx.updateValueAndValidity();

    this.sampleCreateForm.controls.dmsSy.clearValidators();
    this.sampleCreateForm.controls.dmsSy.setErrors(null);
    this.sampleCreateForm.controls.dmsSy.updateValueAndValidity();

    this.sampleCreateForm.controls.dmsMy.clearValidators();
    this.sampleCreateForm.controls.dmsMy.setErrors(null);
    this.sampleCreateForm.controls.dmsMy.updateValueAndValidity();

    this.sampleCreateForm.controls.dmsDy.clearValidators();
    this.sampleCreateForm.controls.dmsDy.setErrors(null);
    this.sampleCreateForm.controls.dmsDy.updateValueAndValidity();

    this.sampleCreateForm.controls.longitude.clearValidators();
    this.sampleCreateForm.controls.longitude.setErrors(null);
    this.sampleCreateForm.controls.longitude.updateValueAndValidity();

    this.sampleCreateForm.controls.latitude.clearValidators();
    this.sampleCreateForm.controls.latitude.setErrors(null);
    this.sampleCreateForm.controls.latitude.updateValueAndValidity();

    this.sampleCreateForm.controls.zone34SNorthing.clearValidators();
    this.sampleCreateForm.controls.zone34SNorthing.setErrors(null);
    this.sampleCreateForm.controls.zone34SNorthing.updateValueAndValidity();

    this.sampleCreateForm.controls.zone34SEasting.clearValidators();
    this.sampleCreateForm.controls.zone34SEasting.setErrors(null);
    this.sampleCreateForm.controls.zone34SEasting.updateValueAndValidity();

    this.sampleCreateForm.controls.zone35SNorthing.clearValidators();
    this.sampleCreateForm.controls.zone35SNorthing.setErrors(null);
    this.sampleCreateForm.controls.zone35SNorthing.updateValueAndValidity();

    this.sampleCreateForm.controls.zone35SEasting.clearValidators();
    this.sampleCreateForm.controls.zone35SEasting.setErrors(null);
    this.sampleCreateForm.controls.zone35SEasting.updateValueAndValidity();
  }

  compareFn(projectOne: any, projectTwo: any): boolean {
    return projectOne && projectTwo ? projectOne.id === projectTwo.id : projectOne === projectTwo;
  }

  compareStrings(stringOne: any, stringTwo: any): boolean {
    return stringOne === stringTwo;
  }

  onFocusOut() {
    let sampleNumber = this.sampleCreateForm.controls.sampleNumber.value;;
    if (sampleNumber && sampleNumber.trim() !== '') {
      let project = this.sampleCreateForm.controls.project.value
      if (this.sampleCreateForm.controls.project.value) {
        let project = this.sampleCreateForm.controls.project.value
        this.geotechService.sampleNumberAlreadyInProject(sampleNumber, project.id).subscribe(res => {
          if (res.exist === true) {
            this.sampleCreateForm.get('sampleNumber').setErrors({ 'exist': true });
          } else {
            this.sampleCreateForm.get('sampleNumber').setErrors(null);
          }
        });
      }
    }
  }

  protected _setInitialManager() {
    this.filteredManagers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.sampleTemplate.id) {
        // Edit
        let manager = this.personMembers.find(pM => pM.id === this.sampleTemplate.projectManager);

        if (!manager) {
          found = false;
        } else {
          found = true;

          this.currentManager.setValue(manager);
          this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let manager = this.personMembers.find(pM => this._isOfRole(pM, 'Project Manager'));

      if (!manager) return;

      this.currentManager.setValue(manager);
      this.managerSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialCoordinator() {
    this.filteredCoordinators.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.sampleTemplate.id) {
        // Edit
        let coordinator = this.personMembers.find(pM => pM.id === this.sampleTemplate.projectCoordinator);

        if (!coordinator) {
          found = false;
        } else {
          found = true;

          this.currentCoordinator.setValue(coordinator);
          this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let coordinator = this.personMembers.find(pM => this._isOfRole(pM, 'Project Coodinator'));

      if (!coordinator) return;

      this.currentCoordinator.setValue(coordinator);
      this.coordinatorSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  protected _setInitialGeologist() {
    this.filteredGeologists.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // Edit and pre-select
      let found = false;
      if (this.sampleTemplate.id) {
        // Edit
        let geologist = this.personMembers.find(pM => pM.id === this.sampleTemplate.projectGeologist);

        if (!geologist) {
          found = false;
        } else {
          found = true;

          this.currentGeologist.setValue(geologist);
          this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
        }
      }

      if (found === true) return;

      // Pre-select
      let geologist = this.personMembers.find(pM => this._isOfRole(pM, 'Geologist'));

      if (!geologist) return;

      this.currentGeologist.setValue(geologist);
      this.geologistSelect.compareWith = (a: Person, b: Person) => a && b && a.id === b.id;
    });
  }

  private _getProjectMembers() {
    this.projectMemberStillLoading = true;
    if (this.sampleTemplate.project) {
      this.projectService.getProjectMembers(this.sampleTemplate.project.id).subscribe(res => {
        this.projectMembers = res as Array<ProjectMembers>;
        this._getProjectPeople(this.projectMembers);
      }, err => {
        this.toast.error(err.message, "Project Member Fail");
      });
    }
  }

  private _getProjectPeople(members: Array<ProjectMembers>) {
    let ids = [];

    members.forEach(m => {
      ids.push(m.personId);
    });

    if (ids.length <= 0) {
      this.projectMemberStillLoading = false;
      return;
    }

    this.peopleSrv.getPeopleByIdList(ids).subscribe(res => {
      this.personMembers = res;
      // this.filteredManagers = res;
      this.filteredManagers.next(res.slice());
      this.managerFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterManager();
      });

      this.filteredCoordinators.next(res.slice());
      this.coordinatorFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCoordinators();
      });

      this.filteredGeologists.next(res.slice());
      this.geologistFilter.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterGeologist();
      });
      this.projectMemberStillLoading = false;
    }, err => {
      this.toast.error(err.message, "Project People Fail");
    });
  }

  private _isOfRole(person: Person, role: string) {
    let member = this.projectMembers.find(pM => pM.personId === person.id && pM.deleted === false);

    if (!member) return false;

    let roleFound = false;
    member.projectRoles.forEach(r => {
      if (r.name.toLowerCase() === role.toLowerCase()) {
        roleFound = true;
      }
    });

    return roleFound;
  }

  filterManager() {
    if (!this.personMembers) return;

    let search = this.managerFilter.value;

    if (!search) {
      this.filteredManagers.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredManagers.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterCoordinators() {
    if (!this.personMembers) return;

    let search = this.coordinatorFilter.value;

    if (!search) {
      this.filteredCoordinators.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCoordinators.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  filterGeologist() {
    if (!this.personMembers) return;

    let search = this.geologistFilter.value;

    if (!search) {
      this.filteredGeologists.next(this.personMembers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGeologists.next(
      this.personMembers.filter(pM => {
        let fullName = pM.name + pM.middleName + pM.surname;
        return fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  companyChanged() {
    this.sampleTemplate.project = this.sampleCreateForm.controls.project.value;
    this._getProjectMembers();
  }


}
