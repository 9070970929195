export enum ApprovalState {
    PENDING_QA_SUBMISSION = 'PENDING_QA_SUBMISSION',
    PENDING_SENIOR_CURATOR_SUBMISSION = 'PENDING_SENIOR_CURATOR_SUBMISSION',
    SUBMITTED_TO_QA = 'SUBMITTED_TO_QA',
    SUBMITTED_TO_SENIOR_CURATOR = 'SUBMITTED_TO_SENIOR_CURATOR',
    NO_STATUS = 'NO_STATUS',
    SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR = 'SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR',
    REJECTED_BY_QA = 'REJECTED_BY_QA',
    SUBMITTED_TO_MANAGER = 'SUBMITTED_TO_MANAGER',
    REJECTED_BY_MANAGER = 'REJECTED_BY_MANAGER',
    PUBLISHED = 'PUBLISHED',
    APPROVED_MANAGER = 'APPROVED_MANAGER',

    PENDING_GEOLOGIST_SUBMISSION = 'PENDING_GEOLOGIST_SUBMISSION',
    SUBMITTED_TO_GEOLOGIST = 'SUBMITTED_TO_GEOLOGIST',
    REJECTED_BY_GEOLOGIST = 'REJECTED_BY_GEOLOGIST',
    SUBMITTED_TO_MANAGER_BY_GEOLOGIST = 'SUBMITTED_TO_MANAGER_BY_GEOLOGIST',
    PENDING_SENIOR_SUBMISSION = 'PENDING_SENIOR_SUBMISSION',
    PENDING_MANAGER_SUBMISSION = 'PENDING_MANAGER_SUBMISSION'
}

export enum ApproveAction {
    SUBMIT_TO_QA = 0,
    QA_APPROVE = 1,
    QA_REJECT = 2,
    MANAGER_APPROVE = 3,
    MANAGER_REJECT = 4,
    MANAGER_REJECT_SPECIMEN = 8,
    UN_PUBLISH = 5,
    SUBMIT_TO_GEOLOGIST = 6,
    GEOLOGIST_SUBMIT_TO_MANAGER = 7,
    GEOLOGIST_REJECT = 8,
    SUBMIT_TO_SENIOR_GEOCHEMIST = 9,
    SUBMIT_TO_SENIOR_ECONOMIC_GEOLOGIST = 10,
    SENIOR_ECONOMIC_GEOLOGIST_REJECT = 11,
    SENIOR_GEOCHEMIST_REJECT = 12,
    SUBMIT_TO_MANAGER_SENIOR_ECONOMIC_GEOLOGIST = 13,
    SUBMIT_TO_MANAGER_SENIOR_GEOCHEMIST = 14,
    SUBMIT_TO_MANAGER_ECONOMIC_GEOLOGIST = 15,
    SUBMIT_TO_MANAGER_GEOCHEMIST = 16,
    SUBMIT_TO_MANAGER_SENIOR_MINERAL_ECONOMIST = 17,
    SUBMIT_TO_MANAGER_MINERAL_ECONOMIST = 18,
    SENIOR_MINERAL_ECONOMIST_REJECT = 19,
    SUBMIT_TO_SENIOR_MINERAL_ECONOMIST = 20,
    SUBMIT_TO_MANAGER = 21,
    //COLLECTION - GEOLOGICALSPECIMEN
    SUBMIT_TO_SENIOR_CURATOR = 21,
    SENIOR_CURATOR_REJECT = 22,
    SENIOR_CURATOR_APPROVE = 23,




}
