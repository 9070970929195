import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { StorageConstants } from './../../_core/storage.constants';
import { ProjectService } from '../../applied-geoscience/project/project.service';

@Directive({
    selector: '[ifProjectMember]'
})
export class IfProjectMemberDirective {
    constructor(
        private templateRef : TemplateRef<any>,
        private viewContainer : ViewContainerRef,
        private _localStorage: LocalStorage,
        private projectService: ProjectService
    ) {}

    @Input() set ifProjectMember(projectId: string,) {
        let promise = new Promise((resolve, reject) => {
            this.projectService.isProjectMember(projectId).subscribe(value => {
                resolve(value);
            },error => {
                reject(null);
            })
        });

        promise.then((res:any) => {
            this.logic(res);
        }).catch(_err => {
            this.logic(false);
        });
    }

    private logic(isMember: boolean) {
        if (isMember) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
