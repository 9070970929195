import { DashboardService } from './../../dashboard/dashboard.service';
import { CollectionsActivityType, ActivityType } from './../../../activities/models/activity.model';
import { GlobalDashboardService } from './../../global-dashboard.service';
import { User } from './../../../_core/data/_models/people.model';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Color,Label} from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-project-dashboard-ui',
  templateUrl: './project-dashboard-ui.component.html',
  styleUrls: ['./project-dashboard-ui.component.scss']
})
export class ProjectDashboardUiComponent implements OnInit {

  @Input('myactivities') myactivities: any;
  @Input('myproject') myproject: any;
  loading = true;
  isSet = true;
  isSet1 = true;
  public currentUser: User;
  displayedColumns: string[] = ['#', 'activities', 'status', 'date'];
  projectDataSource = new MatTableDataSource<any>();
  dataSource = new MatTableDataSource<any>();
  /** Based on the screen size, switch from standard to one column per row */
  activityPerDistrictCounts :any;
  cards :any;
  public myProjectsOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      onHover: function(e) {
        e.target.style.cursor = 'pointer';
      }
    },
    hover: {
      onHover: function(e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    }
  };

  public myactivitiesLabels:Label[]= [ 'Draft', 'Submitted-QA', 'Submitted-Manager', 'Returned','Approved'];
  public myProjectsLabels:string[] = ['Draft', 'Submitted-Manager', 'Returned','Approved'];
  public myactivitiesData:number[]= [ 91, 107, 73, 107, 73];
  public myProjectsData = [ 91, 107, 73, 107];
  public pieChartType = 'pie';
  public pieChartLegend : boolean= true;

  options: {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  colors: any ;
  barChartcolors: any ;
  colorProjects:any;
  colorBarchart: any;
  isssetData = false;

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    }
  };

  public barChartLabels: string[] = [];

  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public barChartData: any[] = [];
  private myactivityTotals: number;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private dashBoardService: DashboardService,
    private globalDashboardService: GlobalDashboardService,
    private spinner: NgxSpinnerService ) {

    //this.spinner.show();
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'My Activities', cols: 1, rows: 3 , id: 'myactivities'},
          { title: 'BGI Activities per District', cols: 1, rows: 3 ,id: 'districts'},
          { title: 'My Projects',cols: 1, rows: 3 ,id: 'myprojects'},
          { title: 'Recent Activities', cols: 1, rows: 3 ,id: 'recents'}
        ];
      })
    );


  }

  ngOnInit() {
    this.myactivityTotals = Number(this.myactivities.drafts) + Number(this.myactivities.sumbmittedQA )+
      Number(this.myactivities.sumbmittedManager) + Number(this.myactivities.returned) + Number(this.myactivities.approved);

    this.myactivitiesData = [this.myactivities.drafts, this.myactivities.sumbmittedQA,
        this.myactivities.sumbmittedManager,this.myactivities.returned, this.myactivities.approved];

   this.myProjectsData = [ this.myproject.numberOfProjectsDrafts, this.myproject.numberOfProjectsSubmittedToManager,
      this.myproject.numberOfProjectsReturned, this.myproject.numberOfProjectsApproved];
    this.getRecentActivties();
     this.getActivitiesByDistrictsRef();
    this.colors = [{backgroundColor: [  '#573419','#00A2E5',
        '#C8A207','#CC0000','#009900', '#CC9933']}];

    this.getIsDataset();
    this.colorProjects = [{backgroundColor: ['#573419','#CC9933','#CC0000','#009900']}];
    this.colorBarchart= [{backgroundColor: ['#00A2E5','#573419',
    '#C8A207','#CC0000','#009900', '#CC9933']}];

  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/geologist/project/PENDING_QA_SUBMISSION']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/geologist/project/SUBMITTED_TO_QA']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/geologist/project/SUBMITTED_TO_MANAGER']);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate(['portal/activities/geologist/project/REJECTED_BY_QA']);
        break
      case this.myactivitiesLabels[4]:
        this._router.navigate(['portal/activities/geologist/project/PUBLISHED']);
      default:
    }
  }


  getActivitiesByDistrictsRef(){

    this.globalDashboardService.districtDashboardCounts().subscribe(value => {
        this.activityPerDistrictCounts = value;
        let tempArr = this.activityPerDistrictCounts as [];
        let activityNames = new Set();

      var bar = new Promise((resolve, reject) => {
        tempArr.forEach(districtActivities => {
          this.barChartLabels.push(districtActivities['districtName']);
          let activityRecords = districtActivities['activityRecord'] as [];
          activityRecords.forEach(value2 => {
            activityNames.add(value2['activityName']);
          });
        });

        this.barChartcolors = [{backgroundColor:  '#573419'},{backgroundColor: '#009900'}
        ,{backgroundColor: '#C8A207'},{backgroundColor: '#CC0000'},{backgroundColor: '#009900'},{backgroundColor: '#CC9933'}];
        var index = 0;
        activityNames.forEach(activityName => {
          let data = [];
          tempArr.forEach(rec => {
            let activityRecords = rec['activityRecord'] as [];
            let foundActivityRecord = activityRecords.find(activityRecord => activityRecord['activityName'] === activityName);
            if(foundActivityRecord) {
              data.push(foundActivityRecord['count']);
            } else {
              data.push(0);
            }
          });
          this.barChartData.push({data: data, label: activityName});
          this.spinner.hide();
          this.isssetData = true;
        });

      });


    });

  }

  chartClickedProjects(event){
    switch (event.active[0]._view.label) {
      case this.myProjectsLabels[0]:
        this._router.navigate(['portal/activities/user-project-activities/PENDING_MANAGER_SUBMISSION']);
        break
      case this.myProjectsLabels[1]:
        this._router.navigate(['portal/activities/user-project-activities/SUBMITTED_TO_MANAGER']);
        break
      case this.myProjectsLabels[2]:
        this._router.navigate(['portal/activities/user-project-activities/REJECTED_BY_MANAGER']);
        break
      case this.myProjectsLabels[3]:
        this._router.navigate(['portal/activities/user-project-activities/PUBLISHED']);
        break
      default:
    }
  }

  getIsDataset(){
    this.isSet = (this.myactivities.numberOfDrafts + this.myactivities.numberOfProjectsActivitiesSubmittedToQA
      + this.myactivities.numberOfProjectsActivitiesSubmittedToManager +this.myactivities.returned +
      this.myactivities.approved)>0;

    this.isSet1 = (
      this.myproject.numberOfProjectsSubmittedToQA +
      this.myproject.numberOfProjectsSubmittedToManager +
      this.myproject.numberOfProjectsReturned+
      this.myproject.numberOfProjectsApproved)>0;
  }

  getRecentActivties()
  {
      this.loading = false;
      this.dashBoardService.getMyRecentActivities().subscribe(value => {
        this.dataSource.data = value as [];
        this.projectDataSource.data = this.dataSource.data.filter(value1 => value1.activityType !== 1).slice(0,this.dataSource.data.length>7?7:this.dataSource.data.length);
      });
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }


  openMyActivityDetails(activity) {
    const id = {id: activity.id};
    const shelvingId = activity.shelvingId;
    const inventoryId = activity.inventoryId;

    if (inventoryId && shelvingId) {
      switch (activity.activityType) {
        case  CollectionsActivityType.CORE_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Core/tray/'+activity.id]);
          break;
        }

        case  CollectionsActivityType.CHIPS_TRAY: {
          this._router.navigate(['portal/collections/coreshed/inventory_borehole_details/'+inventoryId+'/shelving/'+shelvingId+'/Chips/'+activity.id]);
          break;
        }
      }

    } else {
      switch (activity.activityType) {

        case  ActivityType.PL_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/pl-borehole/details',  {id: activity.id, type: 'PL'}]);
          break;
        }
        case  ActivityType.BGI_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/borehole/details',  {id: activity.id, type: 'BGI'}]);
          break;
        }
        case  ActivityType.WATER_BOREHOLE: {
          this._router.navigate(['portal/applied-geoscience/hydrogeology/borehole/details',  {id: activity.id, type: 'Water'}]);
          break;
        }
        case  ActivityType.TRIAL_PIT: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/trial-pit/details', id]);
          break;
        }
        case  ActivityType.INFILTRATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', id]);
          break;
        }
        case  ActivityType.DCP_FOUNDATION: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-foundation/detail', id]);
          break;
        }
        case  ActivityType.DCP_ROADWORKS: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/dynamic-cone-penetrometer/dcp-test-subgrade/detail', id]);
          break;
        }
        case  ActivityType.SAMPLE: {
          this._router.navigate(['portal/applied-geoscience/geo-tech/samples/list/details', id]);
          break;
        }
      }
    }
  }

}
