import { Size } from '../../common/size';
import { WaterPumpingTest } from './WaterPumpingTest';
import {Borehole} from "../../common/borehole";

export class WaterPumpingTestCalibration {
  id: string;
  stepNumber: number;
  relativeTimeMinutes: number;
  depthToWater: Size = new Size();
  drawdown: Size = new Size();
  pumpingRate: Size = new Size();
  timeToFill: number;
  timeToFill10L: number;
  timeToFill20L: number;
  timeToFill50L: number;
  comments: String;
  swl: number;
  waterPumpingTest: WaterPumpingTest;
  creator: String;
  created: Date;
  noOfLogs: number;
  borehole: Borehole = new Borehole();

}
