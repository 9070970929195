import { SelectionModel } from '@angular/cdk/collections';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {UnitEnum} from '../../../../shared-kernel/enumerations/unit-enum';
import {HydrogeologyService} from '../../hydrogeology.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Size} from '../../../../shared-kernel/entity/common/size';
import {Unit} from '../../../../shared-kernel/entity/common/unit';
import {WaterBearingZones} from '../../../../shared-kernel/entity/applied-geoscience/hydrogeology/WaterBearingZones';
import {GeoTechService} from '../../../geo-tech/geo-tech.service';
import {Borehole} from '../../../../shared-kernel/entity/common/borehole';
import {NgxSpinnerService} from 'ngx-spinner';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WaterBearingZonesCreateComponent } from './water-bearing-zones-create/water-bearing-zones-create.component';
import {ExportToCsv} from 'export-to-csv';

declare var $: any;

@Component({
  selector: 'app-hydrogeology-borehole-bearing-zones',
  templateUrl: './water-bearing-zones.component.html',
  styleUrls: ['./water-bearing-zones.component.scss']
})
export class WaterBearingZonesComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('projectId') projectId: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  unitEnum: typeof UnitEnum = UnitEnum;
  dataSourceWaterBearingZones: MatTableDataSource<WaterBearingZones> = new MatTableDataSource();
  displayedColumns: string[] = ['check', '#', 'waterstrike', 'yield', 'ph', 'eC', 'tDS', 'temp', 'editing', 'details', 'delete'];
  modalColumns: string[] = ['#', 'waterstrike', 'yield', 'ph', 'eC', 'tDS', 'temp', 'delete'];
  waterBearingZones: WaterBearingZones;
  borehole: Borehole;
  waterBearingZonesLogs: WaterBearingZones [];
  boreholeId: string;
  modalHeading: string;
  selectedBorehole: any;
  idObj: any;
  csvRecords: WaterBearingZones[] = [];
  csvDdataSource: MatTableDataSource<WaterBearingZones> = new MatTableDataSource();
  @ViewChild('fileImportInput') fileImportInput: any;
  selectedCvsRecord: WaterBearingZones = new WaterBearingZones();
  selection = new SelectionModel<WaterBearingZones>(true, []);

  deleteCsvRecord(drl: WaterBearingZones) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toast.success('Record deleted', 'Record Deletion');
  }

  constructor(
    private router: Router,
    private toaster: ToastrService,
    private hydrogeologyService: HydrogeologyService,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private geotechService: GeoTechService,
    private dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.selectedBorehole = params.id;
      this.boreholeId = params.id;
      this.idObj = {id: this.boreholeId};
    });
  }


  openModal(waterBearingZones: any){

    let waterBearingZonesData : WaterBearingZones = new WaterBearingZones();
    if(waterBearingZones!==''){
        waterBearingZonesData = waterBearingZones;
    }

    waterBearingZonesData.borehole = this.borehole;

    const createDialogRef = this.dialog.open(WaterBearingZonesCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: waterBearingZonesData
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getAllWaterBearingZones(this.boreholeId);
    });
  }

  compareFn(c1: WaterBearingZones, c2: WaterBearingZones): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  resetPaginator() {
    this.dataSourceWaterBearingZones.paginator = this.paginator;
    this.dataSourceWaterBearingZones.sort = this.sort;

  }

  ngAfterViewInit() {
    this.resetPaginator();
  }

  saveCsvRecords() {

    this.saveBatch(this.csvRecords);
    this.fileReset();

    $('#csvModal').modal('hide');
  }


  ngOnInit() {

    this.waterBearingZones = new WaterBearingZones();
    this.waterBearingZones.waterStrike = new Size();
    this.waterBearingZones.waterStrike.unit = new Unit();
    this.waterBearingZones.yield = new Size();
    this.waterBearingZones.yield.unit = new Unit();
    this.waterBearingZones.ph = new Size();
    this.waterBearingZones.ph.unit = new Unit();
    this.waterBearingZones.ec = new Size();
    this.waterBearingZones.ec.unit = new Unit();
    this.waterBearingZones.tds = new Size();
    this.waterBearingZones.tds.unit = new Unit();
    this.waterBearingZones.temp = new Size();
    this.waterBearingZones.temp.unit = new Unit();
    this.waterBearingZones.staticWaterLevel = new Size();
    this.waterBearingZones.staticWaterLevel.unit = new Unit();
    this.waterBearingZones.comments = '';
    this.getAllWaterBearingZonesLogsById();
    this.getBoreholeById(this.boreholeId);
  }

  getBoreholeById(id) {
    this.geotechService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
      }
    )
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  showCsvModal(): void {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  saveBatch(records: WaterBearingZones []) {
    this.spinner.show();
    let loopCount = 0;
      this.hydrogeologyService.createWaterBearingZoneLogBatch(records).subscribe(data => {
          this.getAllWaterBearingZones(this.borehole.id)
          this.spinner.hide();
          this.successmsg();
        },
        error => {
            this.toast.error('Records import failed', 'Import Failed');
            this.spinner.hide();
        }, () => {

        });



  }


  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {
        const waterBearingZonesLogCsv = new WaterBearingZones();
        waterBearingZonesLogCsv.borehole = this.borehole;

        waterBearingZonesLogCsv.blowOutYield = new Size();
        waterBearingZonesLogCsv.blowOutYield.unit = new Unit();
        waterBearingZonesLogCsv.blowOutYield.unit.id = this.unitEnum.yield;

        waterBearingZonesLogCsv.waterStrike = new Size();
        waterBearingZonesLogCsv.waterStrike.unit = new Unit();
        waterBearingZonesLogCsv.waterStrike.unit.id = this.unitEnum.meter;

        waterBearingZonesLogCsv.yield = new Size();
        waterBearingZonesLogCsv.yield.unit = new Unit();
        waterBearingZonesLogCsv.yield.unit.id = this.unitEnum.yield;

        waterBearingZonesLogCsv.ph = new Size();
        waterBearingZonesLogCsv.ph.unit = new Unit();
        waterBearingZonesLogCsv.ph.unit.id = this.unitEnum.ph;

        waterBearingZonesLogCsv.ec = new Size();
        waterBearingZonesLogCsv.ec.unit = new Unit();
        waterBearingZonesLogCsv.ec.unit.id = this.unitEnum.ec;

        waterBearingZonesLogCsv.temp = new Size();
        waterBearingZonesLogCsv.temp.unit = new Unit();
        waterBearingZonesLogCsv.temp.unit.id = this.unitEnum.temperature;

        waterBearingZonesLogCsv.staticWaterLevel = new Size();
        waterBearingZonesLogCsv.staticWaterLevel.unit = new Unit();
        waterBearingZonesLogCsv.staticWaterLevel.unit.id = this.unitEnum.waterlevel;

        waterBearingZonesLogCsv.tds = new Size();
        waterBearingZonesLogCsv.tds.unit = new Unit();
        waterBearingZonesLogCsv.tds.unit.id = this.unitEnum.TDS;

        waterBearingZonesLogCsv.waterStrike.measure = parseFloat(data[0]).toString();
        waterBearingZonesLogCsv.yield.measure = parseFloat(data[1]).toString();
        waterBearingZonesLogCsv.ph.measure = parseFloat(data[3]).toString();
        waterBearingZonesLogCsv.ec.measure = parseFloat(data[4]).toString();
        waterBearingZonesLogCsv.temp.measure = parseFloat(data[5]).toString();
        waterBearingZonesLogCsv.tds.measure = parseFloat(data[6]).toString();
        waterBearingZonesLogCsv.staticWaterLevel.measure = parseFloat(data[7]).toString();
        waterBearingZonesLogCsv.comments = data[8];



        dataArr.push(waterBearingZonesLogCsv);

      }
    }
    this.csvDdataSource.data = dataArr;

    return dataArr;
  }

  fileChangeListener($event: any): void {

    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }


  getWaterBearingZonesByIdFromList(id) {
    for (const waterBearingRecord of this.dataSourceWaterBearingZones.data) {
      if (waterBearingRecord.id === id) {
        waterBearingRecord.borehole = this.waterBearingZones.borehole;
        return waterBearingRecord;
      }
    }
  }

  errorsmsg() {
    this.toast.error('Borehole construction save Unsuccessful', 'Water bearing zone Create');
  }

  successmsg() {
    this.toast.success('Borehole construction saved successfully', 'Water bearing zone Create');
  }

  getAllWaterBearingZones(boreholeId) {
    this.hydrogeologyService.getAllWaterBearingZonesByBoreholeId(boreholeId).subscribe(
      data => {
        this.waterBearingZonesLogs = data as WaterBearingZones [];
        this.dataSourceWaterBearingZones = new MatTableDataSource(this.waterBearingZonesLogs);
      }, error => {

      },
      () => {
        this.resetPaginator();
      }
    );
  }

  getAllWaterBearingZonesLogsById() {
    this.hydrogeologyService.getAllWaterBearingZoneLogsById(this.boreholeId).subscribe(
      data => {
        this.waterBearingZonesLogs = data as WaterBearingZones [];
        this.dataSourceWaterBearingZones = new MatTableDataSource(this.waterBearingZonesLogs);
        this.resetPaginator();

      }
    );
  }

  onDelete(waterBearingZones: WaterBearingZones) {
    this.waterBearingZones = waterBearingZones;
    this.waterBearingZones.deleted = true;
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.createWaterBearingZoneLog(this.waterBearingZones, this.boreholeId).subscribe(() => {
              this.getAllWaterBearingZonesLogsById();
              this.toaster.success('Delete Water Bearing Zone', 'You have Successfully deleted Water Bearing Zone');
            }, err => {
                this.toaster.error(err.message, "Failed Delete");
            });
        }
    });
  }

  openDetails(waterBearingZone) {
     let urlObject = {id: waterBearingZone.id, boreholeId: this.boreholeId};
    this.router.navigate(['portal/applied-geoscience/hydrogeology/water-bearing-zones/details', urlObject]);
  }

  onSubmit(modalId) {
    this.waterBearingZones.waterStrike.unit.id = this.unitEnum.meter;
    this.waterBearingZones.blowOutYield = null;
    this.waterBearingZones.staticWaterLevel.unit.id = this.unitEnum.waterlevel;
    this.waterBearingZones.ph.unit.id = this.unitEnum.ph;
    this.waterBearingZones.ec.unit.id = this.unitEnum.ec;
    this.waterBearingZones.temp.unit.id = this.unitEnum.temperature;
    this.waterBearingZones.yield.unit.id = this.unitEnum.yield;
    this.waterBearingZones.tds.unit.id = this.unitEnum.TDS;
    this.hydrogeologyService.createWaterBearingZoneLog(this.waterBearingZones, this.boreholeId).subscribe(
      responce => {

      },
      error => {
        this.errorsmsg();
      },()=> {
        this.getAllWaterBearingZonesLogsById();
          $('#' + modalId).modal('hide');

          if (modalId === 'deleteModal') {
            this.toast.success('Operation successful', 'Delete Record');
          } else {
            this.toast.success('Record created successfully', 'Add Record');
          }

      }
    );

  }


  public lessThan(depthFrom: number, depthTo: number) {
    return depthTo < depthFrom;
  }

  applyFilter(filterValue: string) {
    this.dataSourceWaterBearingZones.filter = filterValue.trim().toLowerCase();
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.resetPaginator();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceWaterBearingZones.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceWaterBearingZones.data ? this.dataSourceWaterBearingZones.data.length : 0;
    return numSelected === numRows;
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.hydrogeologyService.deleteWaterBearingZoneLogBatch(this.selection.selected).subscribe(() => {
              this.getAllWaterBearingZonesLogsById();;
              this.toast.success('Delete Water Bearing Zones', 'You have Successfully deleted Water Bearing Zones');
            }, err => {
                this.toast.error(err.message, "Failed Delete");
            });
        }
    });
  }

  exportCSV() {
    const data = [];
    this.dataSourceWaterBearingZones.data.forEach(element => {
      const waterBearingZones = element as WaterBearingZones;

      data.push({
        waterStrike: waterBearingZones.waterStrike.measure,
        yield: waterBearingZones.yield.measure,
        ph: waterBearingZones.ph.measure,
        ec: waterBearingZones.ec.measure,
        tds: waterBearingZones.tds.measure,
        temp: waterBearingZones.temp.measure,
        staticWaterLevel: waterBearingZones.staticWaterLevel.measure,
        comments: waterBearingZones.comments

      });
    });


    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'waterBearingZone',
      useTextFile: false,
      useBom: true,
      headers: ['Water Strike (m)', 'Yield(m3/h)', 'pH', 'EC (us/cm)', 'TDS (mg/L)', 'Temp', 'Static Water Level (mbgl)',  'Comments']
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

}
