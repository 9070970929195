
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';

// Directives
import { IfAuthorityDirective } from './../_auth/_directives/if-authority.directive';
import { IfRoleDirective } from './../_auth/_directives/if-role.directive';
import { IfNotRoleDirective } from './../_auth/_directives/if-not-role.directive';
import { IfNotAuthorityDirective } from './../_auth/_directives/if-not-authority.directive';
import { IfCreatorDirective } from "./../shared/_directives/if-creator.directive";
import { IfUnitDirective } from "./../shared/_directives/if-unit.directive";

import {
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatChipsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatCommonModule
} from '@angular/material';

import { ActivityCommentsComponent } from "./activity-comment-container/activity-comments.component";
import { ActivityCommentComponent } from "./activity-comment/activity-comment.component";
import { DocUploadComponent } from './documents/doc-upload/doc-upload.component';
import { DocumentsListComponent } from './documents/document-list/documents-list.component';
import {CanEditActivityDirective} from './_directives/can-edit-activity.directive';
import {CanDeleteActivityDirective} from './_directives/can-delete-activity.directive';
import { OrderByPipe } from './pipes/order-by.pipe';

import { DrillRequestApprovalComponent } from './drill-request-approval/drill-request-approval';

import { CommentComponent } from './drill-request-approval/comments/comment/comment.component';
import { ApprovalModalComponent } from "./drill-request-approval/approval-modal/approval-modal";
import { DrillCommentsComponent } from './drill-request-approval/comments/drill-comments';
import { CanEditDrillingRequestActivityDirective } from './_directives/can-edit-drilling-request-activity.directive';

// Photos
import { PhotosContainerComponent } from './photos/photos-container/photos-container.component';
import { PhotosUploadComponent } from './photos/photos-upload/photos-upload.component';
import { ImagePhotoComponent } from './photos/image-photo/image-photo.component';
import { PhotosListComponent } from './photos/photos-list/photos-list.component';
import { EnumToWords } from './pipes/enum-to-words.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DocumentUploadComponent } from '../mineral-resources/document-upload/document-upload.component';
import { YearPickerComponent } from './year-picker/year-picker.component';
import { MultiDatePickerComponent } from './multi-date-picker/multi-date-picker.component';
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import { CustomerRequestFormComponent } from '../collections/views/schedule/customer-request-form/customer-request-form.component';
import { CustomerRequestListComponent } from '../collections/views/schedule/customer-request-list/customer-request-list.component';
import { CustomerRequestDetailsComponent } from '../collections/views/schedule/customer-request-details/customer-request-details.component';
import { RouterModule } from '@angular/router';
import { AddAttendantsComponent } from '../collections/views/schedule/add-attendants/add-attendants.component';
import { EventCalendarComponent } from '../collections/views/schedule/event-calendar/event-calendar.component';
import { EventScheduleFormComponent } from '../collections/views/schedule/event-schedule-form/event-schedule-form.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CollectionsActivityCommentsComponent } from '../collections/shared/activity-comment-container/activity-comments.component';
import { CollectionsActivityCommentComponent } from '../collections/shared/activity-comment/activity-comment.component';
import { IfProjectMemberDirective } from './_directives/if-project-member.directive';
import { UserEditableDirective } from './_directives/user-editable.directive';
import {ChartsModule} from "ng2-charts";
import {AppModule} from "../app.module";
import {
  ReportPlaceholderComponent
} from "../nigis-reporting/collections-reports/report-placeholder/report-placeholder.component";
import {ReportTableComponent} from "../nigis-reporting/shared-views/report-table/report-table.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    // Angular Material
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatChipsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatCommonModule,
    MatExpansionModule,
    MatTabsModule,
    MatSortModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatAutocompleteModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatMomentDateModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    RouterModule,
    MatSlideToggleModule,
    ChartsModule,

  ],
    declarations: [
        ActivityCommentsComponent, ActivityCommentComponent,

        // Documents
        DocUploadComponent, DocumentsListComponent,

        // Drill Request
        DrillRequestApprovalComponent, ApprovalModalComponent,
        DrillCommentsComponent, CommentComponent,

        // Directives
        IfCreatorDirective,
        IfProjectMemberDirective,
        IfAuthorityDirective,
        IfNotAuthorityDirective,
        IfRoleDirective,
        IfNotRoleDirective,
        IfUnitDirective,

        // Pipes
        OrderByPipe,
        EnumToWords,

        CanEditActivityDirective,
        CanDeleteActivityDirective,
        CanEditDrillingRequestActivityDirective,

        // Photos
        PhotosContainerComponent,
        PhotosUploadComponent,
        ImagePhotoComponent,
        PhotosListComponent,
        DocumentUploadComponent,
        YearPickerComponent,
        MultiDatePickerComponent,
        CustomerRequestFormComponent,
        CustomerRequestListComponent,
        CustomerRequestDetailsComponent,
      AddAttendantsComponent,
      EventCalendarComponent,
      EventScheduleFormComponent,
      CollectionsActivityCommentsComponent,
      CollectionsActivityCommentComponent,
      UserEditableDirective,ReportPlaceholderComponent,
      ReportTableComponent,

    ],
  exports: [ReportPlaceholderComponent,
    ActivityCommentsComponent, ActivityCommentComponent,
    ReportTableComponent,

    // Documents
    DocUploadComponent, DocumentsListComponent,

    // Drill Request
    DrillRequestApprovalComponent, ApprovalModalComponent,
    DrillCommentsComponent, CommentComponent,
    DocumentUploadComponent,
    CustomerRequestFormComponent,
    CustomerRequestListComponent,
    CustomerRequestDetailsComponent,

    // Directives
    IfCreatorDirective,
    IfProjectMemberDirective,
    IfAuthorityDirective,
    IfNotAuthorityDirective,
    IfRoleDirective,
    IfNotRoleDirective,
    IfUnitDirective,

    // Pipes
    OrderByPipe,
    EnumToWords,

    CanEditActivityDirective,
    CanDeleteActivityDirective,
    CanEditDrillingRequestActivityDirective,
    // Photos
    PhotosContainerComponent,
    PhotosUploadComponent,
    ImagePhotoComponent,
    PhotosListComponent,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatCommonModule,
    MatExpansionModule,
    MatTabsModule,
    MatSortModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MultiDatePickerComponent,
    EventCalendarComponent,
    EventScheduleFormComponent,
    CollectionsActivityCommentsComponent,
    CollectionsActivityCommentComponent,
    UserEditableDirective,
    ChartsModule
  ],
    entryComponents: [
        DocUploadComponent, ApprovalModalComponent, PhotosUploadComponent, CustomerRequestFormComponent,AddAttendantsComponent,EventScheduleFormComponent
    ]
})
export class SharedModule {}
