import { Component, Input } from '@angular/core';
import { LocalAuthorities } from './../../_auth/_providers/local-authorities';

@Component({
    selector: 'activity-comment',
    templateUrl: './activity-comment.component.html',
    styleUrls: ['./activity-comment.component.scss']
})
export class ActivityCommentComponent {
    officer: any;
    state: any;

    @Input('state') set s(val: any) {
        this.state = val;

        this._localAuthorities.getPersonByUsername(val.creator).subscribe(res => {
            this.officer = res;
        });
    }

    constructor(
        private _localAuthorities: LocalAuthorities
    ) {}

    getTitle(currentState: string) {
        switch(currentState) {
            case 'REJECTED_BY_QA':
                return 'Geologist';
            case 'REJECTED_BY_GEOLOGIST':
              return 'Geologist';
            case 'SUBMITTED_TO_SENIOR_CURATOR':
              return 'Curator';
            case 'SUBMITTED_TO_MANAGER_BY_SENIOR_CURATOR':
              return 'Senior Curator';
            case 'SUBMITTED_TO_MANAGER':
                return 'QA Officer/Senior';
            case 'SUBMITTED_TO_MANAGER_BY_GEOLOGIST':
                return 'Geologist';
            case 'REJECTED_BY_MANAGER':
                return 'Manager';
            case 'PUBLISHED':
            case 'APPROVED_MANAGER':
                return 'Manager';
            case 'PENDING_QA_SUBMISSION':
                return 'Geologist';
            case 'PENDING_GEOLOGIST_SUBMISSION':
              return 'Geologist/Technician';
            default:
                return 'Title Here';
        }
    }
}
