import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoginService} from './login.service';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserService} from '../user/user.service';
import {UserAuthoritiesService} from '../../_auth/services/user-authorities';

@Component({
  selector: 'app-auth',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: string;
  pass: string;
  passView: boolean;
  typePass: string;
  submitted = false;
  firstUserExists: boolean = true;

  constructor(private router: Router,
              private authService: LoginService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private userService: UserService) {

  }

  ngOnInit() {
    this.typePass = 'password';
    this.passView = true;
    this.userService.checkIfFirstUserExist().subscribe(data => {
      this.firstUserExists = data as boolean;
    }, err => {
      // TODO: Log Analytics
    });
  }

  onLoginSuccess(user, pass) {
    this.submitted = true;

    this.authService.AttemptLogin(user, pass).subscribe((data: any) => {
      this.submitted = false;
      this.router.navigate(['portal']);
      this.successmsg();
    }, (err: any) => {
      this.submitted = false;


    });
  }


  errorsmsg() {
    this.toastr.error('Username and Password combination invalid');
  }

  successmsg() {
    this.toastr.success('Success');
  }

  firstUser() {
    return this.authService.getIsFirstUserRegistered();
  }

  showPass() {
    this.passView = !this.passView;
    if (!this.passView) {
      this.typePass = 'text';
    } else {
      this.typePass = 'password';
    }
  }

  registerPage() {
    this.router.navigate(['/setup']);
  }


  onForgotPassword() {
    Swal.fire({
      title: 'Passsword Reset Form',
      input: 'email',
      inputPlaceholder: 'Enter your email address',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true
    }).then(result => {
      if (result.value) {
        this.authService.resetPassword(result.value);
      } else if (result.dismiss) {

      }
    });

  }

}
