import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Borehole } from '../../../../../../shared-kernel/entity/common/borehole';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RockType } from '../../../../../../shared-kernel/entity/applied-geoscience/hydrogeology/RockType';
import { ToastrService } from 'ngx-toastr';
import { HydrogeologyService } from '../../../../../hydrogeology/hydrogeology.service';
import { UnitEnum } from '../../../../../../shared-kernel/enumerations/unit-enum';
import { WaterBoreholeLithologyLog } from 'src/app/shared-kernel/entity/applied-geoscience/hydrogeology/WaterBoreholeLithologyLog';

@Component({
  selector: 'app-water-borehole-lithology-form',
  templateUrl: './water-borehole-lithology-form.component.html',
  styleUrls: ['./water-borehole-lithology-form.component.scss']
})
export class WaterBoreholeLithologyFormComponent implements OnInit {

  borehole: Borehole;
  waterLithologyLog: WaterBoreholeLithologyLog;

  lithologyLogFormGroup: FormGroup = new FormGroup({
    id: new FormControl(''),
    grainSize: new FormControl(null, Validators.required),
    color: new FormControl('', Validators.required),
    freshness: new FormControl('', Validators.required),
    depthFrom: new FormControl(null, [Validators.required, Validators.min(0)]),
    depthTo: new FormControl(null, [Validators.required, Validators.min(0)]),
    date: new FormControl(null, Validators.required),
    borehole: new FormControl(null),
    rockType: new FormControl(null, Validators.required),
    mineralogy: new FormControl(null, Validators.required),
    lithologyDescription: new FormControl(null, Validators.required)
  });

  rockTypes: RockType[];

  enumUnits: typeof UnitEnum = UnitEnum;


  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<WaterBoreholeLithologyFormComponent>,
              private toastr: ToastrService, private hydrogeologyService: HydrogeologyService) {
  }

  ngOnInit() {
    this.borehole = this.data.borehole;
    this.waterLithologyLog = this.data.lithologyLog;

    this.lithologyLogFormGroup.get('depthFrom').setValue(0);

    if (this.waterLithologyLog) {
      this.populateForm();
    }

    if (this.borehole.waterBoreholeLithologyLogs && !this.waterLithologyLog) {
      const previous_log = this.borehole.waterBoreholeLithologyLogs[this.borehole.waterBoreholeLithologyLogs.length - 1];
      if (previous_log) {
        this.lithologyLogFormGroup.get('depthFrom').setValue((previous_log.depthTo.measure));
      }
    }

    this.getAllRockTypes();
  }

  getAllRockTypes() {
    this.hydrogeologyService.getAllRockTypes().subscribe(
      data => {
        this.rockTypes = data as RockType[];
      }
    );
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  populateForm() {
    const log = {
      'id': this.waterLithologyLog.id,
      'borehole': this.borehole,
      'grainSize': this.waterLithologyLog.grainSize,
      'color': this.waterLithologyLog.color,
      'date': new Date(this.waterLithologyLog.date),
      'freshness': this.waterLithologyLog.freshness,
      'mineralogy': this.waterLithologyLog.mineralogy,
      'depthFrom': this.waterLithologyLog.depthFrom.measure,
      'depthTo': this.waterLithologyLog.depthTo.measure,
      'lithologyDescription': this.waterLithologyLog.lithologyDescription.description,
      'rockType': this.waterLithologyLog.rockType
    };

    this.lithologyLogFormGroup.setValue(log);
  }

  onSubmit() {

    const log = this.lithologyLogFormGroup.value;

    if (this.waterLithologyLog) {

      log['id'] = this.waterLithologyLog.id;

      this.waterLithologyLog.depthTo.measure = log['depthTo'];
      this.waterLithologyLog.depthFrom.measure = log['depthFrom'];
      this.waterLithologyLog.lithologyDescription.description = log['lithologyDescription'];

      log['depthTo'] = this.waterLithologyLog.depthTo;
      log['depthFrom'] = this.waterLithologyLog.depthFrom;
      log['lithologyDescription'] = this.waterLithologyLog.lithologyDescription;

    } else {

      log['borehole'] = this.borehole;
      log['depthTo'] = {
        id: '',
        measure: log['depthTo'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };

      log['depthFrom'] = {
        id: '',
        measure: log['depthFrom'],
        unit: {id: this.enumUnits.meter, description: '', unit: ''}
      };

      log['lithologyDescription'] = {id: null, description: log['lithologyDescription'], reference: null};

    }

    if ((+log['depthTo'].measure) > (+log['depthFrom'].measure)) {

      this.hydrogeologyService.createWaterBoreholeLithologyLog(log)
        .subscribe(response => {
            this.onClose({event: 'submit', data: log});
            if (this.waterLithologyLog) {
              this.toastr.success('Water lithology log updated successfully');
            } else {
              this.toastr.success('Water lithology log created successfully');
            }
          },
          error => {
            this.toastr.error(error.toString());
          });
    } else {
      this.toastr.error('`Depth To` must be greater than `Depth From`', 'Invalid Depth');
      this.lithologyLogFormGroup.get('depthTo').setValue('');
    }
  }

  reset() {
    this.lithologyLogFormGroup.setValue({
      id: '', grainSize: '', color: '',
      freshness: '', mineralogy: '', depthFrom: null,
      depthTo: null, date: '', lithologyDescription: '',
      borehole: '', rockType: '',
    });

    this.onClose({event: 'reset', data: null});
  }


  /**
   * Close mat-dialog and send back some data to the calling parent.
   * @param actions - json object format
   * @return e.g {event:'reset': data:'data_object' }
   */
  onClose(actions: any) {
    this.dialogRef.close(actions);
  }
}
