import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sample } from '../../../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { DrillingProtocol } from '../../../../../shared-kernel/entity/driller/DrillingProtocol';
import { UnitEnum } from '../../../../../shared-kernel/enumerations/unit-enum';
import { DrillingMethod } from '../../../../../shared-kernel/entity/driller/drilling-method';
import { DrillerService } from '../../../../../driller/driller.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-borehole-construction-drilling-protocol-create',
  templateUrl: './drilling-protocol-create.component.html',
  styleUrls: ['./drilling-protocol-create.component.scss']
})
export class  DrillingProtocolCreateComponent implements OnInit {

  drillingProtocolform: FormGroup;
  drillingProtocolTemplate : DrillingProtocol = new DrillingProtocol();
  modalHeading :string = 'Create Drilling Protocol';
  enumUnits: typeof UnitEnum = UnitEnum;
  drillingMethods: DrillingMethod [] = [];


  constructor(private drillerService: DrillerService,private formBuilder: FormBuilder,public dialogRef: MatDialogRef<DrillingProtocolCreateComponent>,
  private toast: ToastrService,@Inject(MAT_DIALOG_DATA) public data: any,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getAllDrillingMethods();
    this.initForms();
  }

  private initForms() {

    let drillingProtocolData : DrillingProtocol =this.data.drillingProtocolsData;
    let drillingProtocols : DrillingProtocol [] =this.data.drillingProtocols;

    this.drillingProtocolform = this.formBuilder.group({
      depthFrom:[drillingProtocolData.id ? drillingProtocolData.depthFrom.measure : 0,[Validators.required, Validators.min(0)]],
      depthTo:[drillingProtocolData.id ? drillingProtocolData.depthTo.measure : '', [Validators.required, Validators.min(0)]],
      diameter:[drillingProtocolData.id ? drillingProtocolData.diameter.measure : '', [Validators.required, Validators.min(0)]],
      inclination:[drillingProtocolData.id ? drillingProtocolData.inclination.measure : '',[Validators.required, Validators.min(-90),Validators.max(90)]],
      azimuth:[drillingProtocolData.id ? drillingProtocolData.azimuth.measure : '',[Validators.required, Validators.min(0),Validators.max(360)]],
      drillingMethod:[drillingProtocolData.id ? drillingProtocolData.drillingMethod : '',Validators.required],
    });

    let depthFromSort = drillingProtocols.sort((a, b) => { return parseFloat(a.depthTo.measure) > parseFloat(b.depthTo.measure) ? 1:-1 });
    this.drillingProtocolform.controls.depthFrom.setValue(depthFromSort[drillingProtocols.length-1].depthTo.measure);

    if(drillingProtocolData.id) {
      this.modalHeading = 'Edit Drilling Protocol';
    }

  }

  closeModal() {
    this.dialogRef.close();
  }

  getAllDrillingMethods() {
    this.drillerService.getAllDrillingMethods()
      .subscribe(data => {
        this.drillingMethods = data as DrillingMethod[];
      });
  }

  compareFn(c1: DrillingProtocol, c2: DrillingProtocol): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  validateInclination(valueTobeValidated: number) {
    if ((valueTobeValidated >= -90) && (valueTobeValidated <= 90)) {
      return true;
    } else {
      this.toast.error('Inclination should be between -90 and 90', 'Borehole Inclination');
      return false;
    }
  }

  validateAzimuth(valueTobeValidated: number) {
    if ((valueTobeValidated >= 0) && (valueTobeValidated <= 360)) {
      return true;
    } else {
      this.toast.error('Azimuth should be between 0 and 360', 'Azimuth');
      return false;
    }
  }

  validation_messages = {
    'number': [
      {type: 'required', message: 'Please insert a number'},
    ],
    'length': [
      {type: 'required', message: 'Should at least be 5 letters'},
    ],
    'depthFrom': [
      {type: 'required', message: 'depthFrom is required'},
    ],
    'depthTo': [
      {type: 'required', message: 'depthTo is required'},
    ],
    'diameter': [
      {type: 'required', message: 'diameter is required'},
    ],
    'inclination': [
      {type: 'required', message: 'inclination is required'},
    ],
    'azimuth': [
      {type: 'required', message: 'azimuth is required'},
    ],
    'drillingMethod': [
      {type: 'required', message: 'drilling Method is required'},
    ]
  };

  onSubmit() {
    if(this.data.id) {
      this.drillingProtocolTemplate = this.data;
    } else {
      this.drillingProtocolTemplate.borehole = this.data.drillingProtocolsData.borehole;
    }

    this.drillingProtocolTemplate.depthFrom.unit.id = this.enumUnits.meter;
    this.drillingProtocolTemplate.depthFrom.measure = this.drillingProtocolform.controls.depthFrom.value;
    this.drillingProtocolTemplate.depthTo.unit.id = this.enumUnits.meter;
    this.drillingProtocolTemplate.depthTo.measure = this.drillingProtocolform.controls.depthTo.value;
    this.drillingProtocolTemplate.diameter.unit.id = this.enumUnits.meter;
    this.drillingProtocolTemplate.diameter.measure = this.drillingProtocolform.controls.diameter.value;
    this.drillingProtocolTemplate.inclination.unit.id = this.enumUnits.meter;
    this.drillingProtocolTemplate.inclination.measure = this.drillingProtocolform.controls.inclination.value;
    this.drillingProtocolTemplate.azimuth.unit.id = this.enumUnits.meter;
    this.drillingProtocolTemplate.azimuth.measure = this.drillingProtocolform.controls.azimuth.value;
    this.drillingProtocolTemplate.drillingMethod = this.drillingProtocolform.controls.drillingMethod.value;

    if(this.drillingProtocolform.controls.depthTo.value >= this.drillingProtocolform.controls.depthFrom.value) {
      if (this.validateInclination(parseFloat(this.drillingProtocolTemplate.inclination.measure))) {
        if (this.validateAzimuth(parseFloat(this.drillingProtocolTemplate.azimuth.measure))) {
          this.spinner.show();
      this.drillerService.createDrillingProtocol(this.drillingProtocolTemplate)
        .subscribe(data => {
          }, error => {
            this.spinner.hide();
            this.toast.error('Sorry, try again, record creation failed', 'Create Drilling Protocol');
          },
          () => {
            this.spinner.hide();
            this.closeModal();
            this.toast.success('Record created successfully', 'Create Drilling Protocol');
          });
    } }}else {
      this.toast.error('Depth To value should be greater than the Depth From value','Create Drilling Protocol');
    }
  }
}
