import { JwtHelperService } from '@auth0/angular-jwt';
import { ApprovalState } from './../../../applied-geoscience/models/approval-state';
import { UserDashboardService } from 'src/app/dashboards/user-dashboard/user-dashboard.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minerals-geologist-sample-vetting-dashboard',
  templateUrl: './minerals-geologist-sample-vetting-dashboard.component.html',
  styleUrls: ['./minerals-geologist-sample-vetting-dashboard.component.scss']
})
export class MineralsGeologistSampleVettingDashboardComponent implements OnInit {

  stillFetchingBGIProjectCount = true;
  stillFetchingMyProjectCount = true;
  stillFetchingPending = true;
  stillFetchingReturnedByGeologist = true;
  stillFetchingSubmittedToManager = true;

  activitiesCount = {
    pending: 0,
    submittedToManager: 0,
    returnedByGeologist: 0,
    numberOfBGIProjects: 0,
    numberOfMyProjects: 0
    }

    approvalEnum: typeof ApprovalState = ApprovalState;
    userName: string;

  constructor(
    private userDashBoardService: UserDashboardService,
  ) {
    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
  }

  ngOnInit() {
    this.getBGIProjectsCount();
    this.getMyProjectsCount();
    this.getPendingGeologistApproval();
    this.getReturnedFromGeologist();
    this.getSubmittedToManagerByGeologist();
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.activitiesCount.numberOfBGIProjects = number;
      this.stillFetchingBGIProjectCount = false;
      });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.activitiesCount.numberOfMyProjects = number;
      this.stillFetchingMyProjectCount = false;
    });
  }

    //Geologist Vetting Activities
    getPendingGeologistApproval(){
      this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeoCount(this.approvalEnum.SUBMITTED_TO_GEOLOGIST).subscribe(number => {
        this.activitiesCount.pending = number;
        this.stillFetchingPending = false;
      });
    }

    getReturnedFromGeologist(){
      this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeoCount(this.approvalEnum.REJECTED_BY_GEOLOGIST).subscribe(number => {
        this.activitiesCount.returnedByGeologist = number;
        this.stillFetchingReturnedByGeologist = false;
      });
    }

    getSubmittedToManagerByGeologist(){
      this.userDashBoardService.getMyActivitiesMineralResourcesVettingGeoCount(this.approvalEnum.SUBMITTED_TO_MANAGER_BY_GEOLOGIST).subscribe(number => {
        this.activitiesCount.submittedToManager = number;
        this.stillFetchingSubmittedToManager = false;
      });
    }

}
