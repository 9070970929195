import { Size } from '../common/size';
import { DrillBit } from './DrillBit';

export class DrillingString {
    id: string;
    coreBarrelsAssembly: Number;
    drillRodsQuantity: Number;
    waterSwivel: Number;
    drillRodsSize: Size = new Size();
    drillBit: DrillBit = new DrillBit();
 }
