import { Observable } from 'rxjs';
import { Activity } from '../../activities/models';

export class ServiceEndPoints {
  constructor() {
  }

  public static readonly authBaseUrl: string = 'https://nigis-qa.bitri-ist.co.bw/gateway/bitri-security';
  public static readonly loginUrl: string = '/api/user/v1/login';
  public static readonly logOutUrl: string = '/api/user/v1/logout';
  public static readonly resetUrl: string = '/api/user/v1/password/reset';
  public static readonly setUserPasswordUrl: string = '/api/user/v1/password/reset';
  public static readonly registerUrl: string = '/api/user/v1/register';
  public static readonly getAllRolesUrl: string = '/api/role/v1/roles/all';
  public static readonly createTitleUrl: string = '/api/profile/v1/title/create';

  public static readonly editUserUrl: string = '/api/profile/v1/person/edit';
  public static readonly getAllTitlesUrl: string = '/api/profile/v1/titles/all';
  public static readonly getAllCountriesUrl: string = '/api/profile/v1/countries/all';
  public static readonly getAllIdTypesUrl: string = '/api/profile/v1/id_types/all';
  public static readonly getAllSexesUrl: string = '/api/profile/v1/sexes/all';
  public static readonly getAllDistrictsUrl: string = '/api/profile/v1/districts/all';
  public static readonly companyPersonnelUrl: string = '/api/profile/v1/companies';
  public static readonly registerFirstUserUrl: string = '/api/user/v1/firstsetup';
  public static readonly checkIfFirstUserExistUrl: string = '/api/user/v1/first-user/exist';
  public static readonly isFirstUserAvailableUrl: string = '/api/user/v1/firstsetup/user';
  public static readonly getAllAuthoritiesUrl: string = '/api/v1/authority/all';
  public static readonly getAllAuthorityGroups: string = '/api/v1/authority-group/all';
  public static readonly createAuthorityGroup: string = '/api/v1/authority-group/create';
  public static readonly createNewRoleUrl: string = '/api/role/v1/role/create';
  public static readonly getRoleByIdUrl: string = '/api/role/v1/role';
  public static readonly personsUrl: string = '/api/profile/v1/person/all';
  public static readonly searchPersonsUrl: string = '/api/profile/v1/people/search';
  public static readonly individualsUrl: string = '/api/profile/v1/individuals/all';
  public static readonly getBGINonBGIPersonUrl: string = '/api/profile/v1/person-bgi-non/all';
  public static readonly createPersonUrl: string = '/api/profile/v1/person/create';
  public static readonly getPersonByIdUrl: string = '/api/profile/v1/person';
  public static readonly getPersonsLiteUrl: string = '/api/user/v1/people/all/lite';
  public static readonly getPersonByBusinessUnitUrl: string = '/api/profile/v1/person/business-unit';
  public static readonly getPersonnelByBusinessUnitUrl: string = '/api/profile/v1/personnel/business-unit';
  public static readonly getBusinessUnitByUserIdUrl: string = '/api/user/v1/users/{userId}/business-unit';
  public static readonly getPersonByUserIdUrl: string = '/api/profile/v1/person/user';
  public static readonly getPersonsByCompanyIdUrl: string = '/api/profile/v1/companies/{companyId}/people';
  public static readonly appliedGeoscienceBaseUrl: string = 'https://nigis-qa.bitri-ist.co.bw/gateway/applied-geo-science';
  public static readonly getAllConstructionMaterialTypesUrl: string = '/api/hydro-geology/v1/borehole-construction-type/material/all';
  public static readonly getAllCasingUrl: string = '/api/hydro-geology/v1/borehole-construction-casing/borehole-construction-log';
  public static readonly createBoreholeConstructionCasingLogUrl: string = '/api/hydro-geology/v1/borehole-construction-casing/create';
  public static readonly createWaterBearingZonesLogUrl: string = '/api/hydro-geology/v1/water-bearing-zones-log/create';
  public static readonly createWaterBearingZonesLogBatchUrl: string = '/api/hydro-geology/v1/water-bearing-zones-log/create/batch';

  public static readonly createBoreholeConstructionScreeningLogUrl: string = '/api/hydro-geology/v1/borehole-construction-screening/create';

  public static readonly getAllScreeningUrl: string = '/api/hydro-geology/v1/borehole-construction-screening/borehole-construction-log';
  public static readonly createCompanyUrl: string = '/api/ags-common/v1/company/create';
  public static readonly getAllCompaniesUrl: string = '/api/ags-common/v1/companies/all';
  public static readonly searchCompaniesUrl: string = '/api/ags-common/v1/companies/search';
  public static readonly getAllCompaniesLiteUrl: string = '/api/ags-common/v1/companies/all/lite';
  public static readonly getAllCompanyCategoriesUrl: string = '/api/ags-common/v1/company-categories/all';
  public static readonly getAllCompanyTypesUrl: string = '/api/ags-common/v1/company-types/all';
  public static readonly getCompanyByIdUrl: string = '/api/ags-common/v1/company';
  public static readonly getAllContactDetailTypesUrl: string = '/api/ags-common/v1/contact-detail-types/all';
  public static readonly getAllCountriesAGSUrl: string = '/api/ags-common/v1/countries/all';
  public static readonly getAllDistrictsAGSUrl: string = '/api/ags-common/v1/districts/all';
  public static readonly getAllVillagesUrl: string = '/api/ags-common/v1/villages/all';
  public static readonly getAllVillageLiteUrl: string = '/api/ags-common/v1/villages/all/lite';
  public static readonly getVillagesByDistrictIdUrl: string = '/api/ags-common/v1/villages';
  public static readonly searchDistrictsUrl: string = '/api/ags-common/v1/districts';
  public static readonly searchVillagesUrl: string = '/api/ags-common/v1/villages';

  public static readonly createVillageUrl: string = '/api/ags-common/v1/village/create';
  public static readonly editVillageUrl: string = '/api/ags-common/v1/village/update';
  public static readonly deleteVillageUrl: string = '/api/ags-common/v1/village/delete';

  public static readonly createProjectUrl: string = '/api/ags-common/v1/project/create';
  public static readonly getAllProjectsUrl: string = '/api/ags-common/v1/projects/all';
  public static readonly getAllProjectsByMember: string = '/api/ags-common/v1/projects/projects-by-member';
  public static readonly getAllProjectNamesUrl: string = '/api/projects/v1/project/list';
  public static readonly getMyProjectsUrl: string = '/api/ags-common/v1/projects/individual';
  public static readonly getBgiProjectsUrl: string = '/api/ags-common/v1/projects/bgi';
  public static readonly getNonBgiProjectsUrl: string = '/api/ags-common/v1/projects/non-bgi';
  public static readonly getAllProjectMembersUrl: string = '/api/ags-common/v1/project/members';
  public static readonly getAllProjectRolesUrl: string = '/api/ags-common/v1/project/members/roles/all';
  public static readonly getAllProjectTypesUrl: string = '/api/ags-common/v1/projects/type/all';
  public static readonly getProjectCategoriesByProjectTypeUrl: string = '/api/ags-common/v1/projects/categories';
  public static readonly getProjectCategoryTypesByProjectCategoryUrl: string = '/api/ags-common/v1/projects/category-type';

  public static readonly getAllBoreholeByProjectsIdUrl: string = '/api/ags-common/v1/project/boreholes/all';
  public static readonly getAllProjectStatusesUrl: string = '/api/ags-common/v1/project/status/all';
  public static readonly getProjectByIdUrl: string = '/api/ags-common/v1/project';
  public static readonly getAllCommoditiesUrl: string = '/api/ags-common/v1/commodities/all';
  public static readonly getCommoditiesByMineralIdUrl: string = '/api/ags-common/v1/commodities';
  public static readonly getCommodityByIdUrl: string = '/api/ags-common/v1/commodities';
  public static readonly getAllMineralTypesUrl: string = '/api/ags-common/v1/mineral-types/all';

  public static readonly createDrillingRequestBoreholeUrl: string = '/api/driller/v1/drilling-request/borehole/create';
  public static readonly getDrillingRequestBoreholeByIdUrl: string = '/api/driller/v1/drilling-request/borehole/{borehole_id}/details';
  public static readonly getDrillingRequestBoreholeByDrillingRequestIdUrl: string = '/api/driller/v1/drilling-requests/{drilling_request_id}/borehole';

  public static readonly createDrillingRequestUrl: string = '/api/driller/v1/drilling-request/create';
  public static readonly getDrillingRequestByIdUrl: string = '/api/driller/v1/drilling-request';
  public static readonly getAllDrillingRequestsUrl: string = '/api/driller/v1/drilling-requests/all';
  public static readonly getDrillingRequestByProjectIdUrl: string = '/api/driller/v1/drilling-requests/project';

  public static readonly getAllDrillingMethodsUrl: string = '/api/driller/v1/drilling-methods/all';
  public static readonly getAllSampleTypesUrl: string = '/api/driller/v1/sample-types/all';
  public static readonly createDrillingRequestResponseNoteUrl: string = '/api/driller/v1/drilling-request/drilling-request-response-notes/create';
  public static readonly getAllDrillingRequestNotesByRequestIdUrl: string = '/api/driller/v1/drilling-request/drilling-request-response-notes';

  public static readonly getAllRigTypesUrl: string = '/api/ags-common/v1/rig-types/all';
  public static readonly getAllBoreholeTypesUrl: string = '/api/ags-common/v1/borehole-types/all';
  public static readonly getAllBoreholesUrl: string = '/api/ags-common/v1/boreholes/all';
  public static readonly getAllBoreholesLiteUrl: string = '/api/ags-common/v1/boreholes/all/lite';
  public static readonly getBoreholeByIdUrl: string = '/api/ags-common/v1/borehole';
  public static readonly createBoreholeUrl: string = '/api/ags-common/v1/borehole/create';
  public static readonly updateBoreholeUrl: string = '/api/ags-common/v1/borehole/update';
  public static readonly checkBoreholeOwnershipUrl: string = '/api/ags-common/v1/borehole/ownership';
  public static readonly getBoreholeByOwnershipUrl: string = '/api/ags-common/v1/borehole/creator';
  public static readonly getOwnedBoreholeNumberUrl: string = '/api/ags-common/v1/borehole/creator/number';
  public static readonly getNumberOfBoreholesByBoreholeTypeUrl: string = '/api/ags-common/v1/borehole/count';
  public static readonly getBoreholeCountInDistrictUrl: string = '/api/ags-common/v1/borehole/district/count';
  public static readonly getBoreholesByBoreholeTypeIdUrl: string = '/api/ags-common/v1/boreholes';
  public static readonly getBGIBoreholesByBoreholeTypeIdUrl: string = '/api/ags-common/v1/bgi-boreholes';
  public static readonly getProspectingLicenseBoreholesByBoreholeTypeIdUrl: string = '/api/ags-common/v1/pl-boreholes';
  public static readonly getMyActivitiesUrl: string = '/api/ags-common/v1/myactivities';
  public static readonly getMyRecentActivitiesUrl: string = '/api/activities/v1/myactivities/recent';
  public static readonly getRecentActivitiesUrl: string = '/api/activities/v1/activities/recent';

  public static readonly createBoreholeLithologyLogUrl: string = '/api/ags-common/v1/borehole/borehole-lithology-log/create';
  public static readonly createBoreholeLithologyUrl: string = '/api/ags-common/v1/borehole/borehole-lithology/create';
  public static readonly getAllDrillerLogRecordsUrl: string = '/api/drillerlog/v1/driller-log-record/all';
  public static readonly deleteDrillerLogRecordsUrl: string = '/api/drillerlog/v1/driller-log-record/delete';
  public static readonly createDrillerLogRecordUrl: string = '/api/drillerlog/v1/driller-log-record/create';
  public static readonly createDrillerLogRecordsUrl: string = '/api/drillerlog/v1/driller-log-records/create';
  public static readonly updateDrillerLogRecordUrl: string = '/api/drillerlog/v1/driller-log-record/update';
  public static readonly getDrillerLogRecordsByBoreholeUrl: string = '/api/drillerlog/v1/driller-log-records';
  public static readonly getDrillerLogRecordByIdUrl: string = '/api/drillerlog/v1/driller-log-record/details';

  public static readonly getAllTimeAndMotionRecordsUrl: string = '/api/borehole-logs/v1/time-and-motion/all';
  public static readonly getTimeAndMotionRecordUrl: string = '/api/borehole-logs/v1/time-and-motion/individual';
  public static readonly getAllTimeAndMotionRecordsByBoreholeUrl: string = '/api/borehole-logs/v1/time-and-motion';
  public static readonly deleteTimeAndMotionRecordUrl: string = '/api/borehole-logs/v1/time-and-motion/delete';
  public static readonly createTimeAndMotionRecordUrl: string = '/api/borehole-logs/v1/time-and-motion/create';
  public static readonly createTimeAndMotionRecordBatchUrl: string = '/api/borehole-logs/v1/time-and-motion/create/batch';

  public static readonly getAllWaterDrillersLogRecordsUrl: string = '/api/water-drillers-log/v1/water-drillers-logs/all';
  public static readonly getAllWaterDrillersLogRecordByBoreholeUrl: string = '/api/water-drillers-log/v1/water-drillers-logs/';
  public static readonly deleteWaterDrillersLogRecordUrl: string = '/api/water-drillers-log/v1/water-drillers-log/delete';
  public static readonly createWaterDrillersLogRecordUrl: string = '/api/water-drillers-log/v1/water-drillers-log/create';

  public static readonly getAllBoreholeLithologyLogsUrl: string = '/api/geotech/v1/borehole-lithology-logs/all';
  public static readonly getAllHardnessUrl: string = '/api/geotech/v1/hardness/all';
  public static readonly getAllRockNameUrl: string = '/api/geotech/v1/rock-names/all';
  public static readonly getAllWeatheringUrl: string = '/api/geotech/v1/weathering/all';
  public static readonly getBoreholeLithologyLogByIdUrl: string = '/api/geotech/v1/borehole-lithology-log';
  public static readonly getBoreholeLithologyLogByBoreholeIdUrl: string = '/api/geotech/v1/borehole/borehole-lithology-log';
  public static readonly deleteBoreholeLithologyLog: string = '/api/geotech/v1/borehole-lithology-log/delete';

  public static readonly createBoreholeCoreRecoveryLogUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-log/create';
  public static readonly getAllBoreholeLCoreRecoveryLogUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-log/all';
  public static readonly getCoreRecoveryByIdUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-log';
  public static readonly deleteCoreRecoveryUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-log/delete';

  public static readonly createSoilLogUrl: string = '/api/soil/v1/soil-log/create';
  public static readonly getUserTrialPitsCountUrl: string = '/api/soil/v1/userTrailPits/count';
  public static readonly createTrialPitUrl: string = '/api/soil/v1/soil-log/trial-pit/create';
  public static readonly getTrialPitByIdUrl: string = '/api/soil/v1/trial-pit';
  public static readonly getAllSoilLogRecordsUrl: string = '/api/soil/v1/soil-log-records/all';
  public static readonly getAllExcavationMethodsUrl: string = '/api/soil/v1/excavation-methods/all';
  public static readonly getAllTrialPitsUrl: string = '/api/soil/v1/trial-pits/all';
  public static readonly getAllActiveTrialPitsUrl: string = '/api/soil/v1/trial-pits/active/all';
  public static readonly getAllSoilLogsByTrialPitIdUrl: string = '/api/soil/v1/soil-logs';

  public static readonly getAllSoilTestSoilChemistry: string = '/api/soil/v1/trial-pit/{id}/soil-chemistry';
  public static readonly createSoilTestSoilChemistry: string = '/api/soil/v1/trial-pit/soil-chemistry/create';

  public static readonly createDynamicConePenetrometerTestLogUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/create';
  public static readonly createDynamicConePenetrometerFoundationTestUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/foundation/create';

  public static readonly createDynamicConePenetrometerSubgradeTestUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/subgrade/create';
  public static readonly createDynamicConePenetrometerRoadworkLogUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/roadworks-log/create';
  public static readonly createDynamicConePenetrometerRoadworkBatchLogUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/roadworks-log/batch/create';


  public static readonly getAllSurfaceTypesUrl: string = '/api/geotech/v1/surface-type/all';
  public static readonly getAllDCPTestFoundationUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/foundation/all';
  public static readonly getDCPFoundationTestByIdUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/foundation/one';

  public static readonly getAllDCPSubgradeTestUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/subgrade/all';
  public static readonly getDCPSubgradeTestByIdUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/subgrade/one';
  public static readonly getDCPRoadworkTestByIdUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/roadworks-log';

  public static readonly getAllDCPTestSubgradeByDynamicConePenetrometerTestIdUrl: string = '/api/geotech/v1/dynamic-cone-penetrometer-test/subgrade';
  public static readonly createBoreholeCoreRecoveryDiscontinuityLogUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-log/discontinuity/create';
  public static readonly getAllBoreholeCoreRecoveryDiscontinuityLogUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-log/discontinuity/all';

  public static readonly getAllDiscontinuityTypeUrl: string = '/api/geotech/v1/discontinuity-type/all';
  public static readonly getAllMacroRoughness: string = '/api/geotech/v1/macro-roughness/all';
  public static readonly getAllMicroRoughness: string = '/api/geotech/v1/micro-roughness/all';
  public static readonly getAllFillTypeListUrl: string = '/api/geotech/v1/fill-type/all';
  public static readonly getAllSeparationLogsUrl: string = '/api/geotech/v1/separation/all';
  public static readonly getAllJointWallAlterationLogsUrl: string = '/api/geotech/v1/joint-wall-alteration/all';

  public static readonly createDrillingChecklistUrl: string = '/api/driller/v1/drilling-checklist/create';
  public static readonly getAllDrillingChecklistUrl: string = '/api/driller/v1/drilling-checklist/all';

  public static readonly getWaterBoreholeLithologyLogByIdUrl: string = '/api/hydro-geology/v1/water-borehole-lithology-log';
  public static readonly getAllWaterBoreholeLithologyLogsUrl: string = '/api/hydro-geology/v1/water-borehole-lithology-log/all';
  public static readonly getAllWaterBoreholeLithologyLogsByBoreholeIdUrl: string = '/api/hydro-geology/v1/water-borehole-lithology-log';
  public static readonly createWaterBoreholeLithologyLogUrl: string = '/api/hydro-geology/v1/water-borehole-lithology-log/create';
  public static readonly createWaterBoreholeLithologyLogsUrl: string = '/api/hydro-geology/v1/water-borehole-lithology-logs/create';
  public static readonly getAllRockTypesUrl: string = '/api/hydro-geology/v1/rock-types/all';
  public static readonly getRockGroupByRockTypeIdUrl: string = '/api/geotech/v1/rock-group/rock-type';
  public static readonly getAllGrainSizesUrl: string = '/api/geotech/v1/grain-sizes/all';

  public static readonly getAllDrillingProtocolsUrl: string = '/api/driller/v1/drilling-protocols/all';
  public static readonly getAllDrillingProtocolsByBoreholeIdUrl: string = '/api/driller/v1/drilling-protocols';
  public static readonly createDrillingProtocolUrl: string = '/api/driller/v1/drilling-protocol/create';
  public static readonly batchCreateDrillingProtocolUrl: string = '/api/driller/v1/drilling-protocol/create/batch';

  public static readonly createWaterPumpingTestUrl: string = '/api/hydro-geology/v1/water-pump-test/create';
  public static readonly createWaterPumpingTestCalibrationUrl: string = '/api/hydro-geology/v1/water-pump-test/calibration/create';
  public static readonly deleteWaterPumpingTestCalibrationUrl: string = '/api/hydro-geology/v1/water-pump-test/calibration/delete';
  public static readonly createWaterPumpingTestStepTestUrl: string = '/api/hydro-geology/v1/water-pump-test/step-test/create';
  public static readonly createWaterPumpingTestConstantRateTestUrl: string = '/api/hydro-geology/v1/water-pump-test/constant-rate-test/create';
  public static readonly createConstantRateTestBatchUrl: string = '/api/hydro-geology/v1/water-pump-test/constant-rate-test/create/batch';
  public static readonly createTestConstantRateTestUrl: string = '/api/hydro-geology/v1/water-pump-test/constant-rate-test/create';
  public static readonly createObservationBoreholeUrl: string = '/api/hydro-geology/v1/water-pump-test/observation-borehole/create';

  public static readonly createWaterPumpingTestRecoveryUrl: string = '/api/hydro-geology/v1/water-pump-test/recovery/create';
  public static readonly getWaterPumpingTestByIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/one';
  public static readonly getAllWaterPumpingTestByBoreholeIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test';
  public static readonly getAllWaterPumpingTestByWaterPumpingTestTypeIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/water-pump-test-type';
  public static readonly getAllWaterPumpingTestByBoreholeIdAndWaterPumpingTestTypeIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/borehole/water-pump-test-type';
  public static readonly getAllWaterPumpingTestStepTestByWaterPumpingTestIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/step-test';
  public static readonly getAllWaterPumpingTestCalibrationByWaterPumpingTestIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/calibration';
  public static readonly getAllWaterPumpingTestCalibrationByBoreholeIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/calibration/water';
  public static readonly getWaterPumpingTestCalibrationByIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/calibration/detail';

  public static readonly getCoordinatesTypesUrl: string = '/api/ags-common/v1/coordinates-types/all';
  public static readonly getAllWaterPumpingTestConstantRateTestByWaterPumpingTestIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/constant-rate-test';
  public static readonly getWaterConstantRateTestByIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/constant-rate-test/individual';
  public static readonly getWaterObservationBoreholeByConstantRateTestUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/constant-rate-test/{constantRate}/observation-borehole';
  public static readonly getAllWaterBearingZonesByBoreholeIdUrl: string = '/api/hydro-geology/v1/water-bearing-zones/water-bearing-zone-log';

  public static readonly getAllWaterBearingZonesLogUrl: string = '/api/hydro-geology/v1/water-bearing-zones/water-bearing-zone-log';

  public static readonly createWaterBearingZonesByBoreholeIdUrl: string = '/api/hydro-geology/v1/water-bearing-zones/create';
  public static readonly getAllWaterPumpingTestRecoveryByWaterPumpingTestIdUrl: string = '/api/hydro-geology/v1/borehole/water-pump-test/recovery';

  public static readonly createFileUrl: string = '/api/upload-file/v1/file/create';
  public static readonly getAllFilesUrl: string = '/api/upload-file/v1/files/all';
  public static readonly getAllFilesByForeignKeyUrl: string = '/api/upload-file/v1/files';
  public static readonly deleteFileUrl: string = '/api/upload-file/v1/file/delete';
  public static readonly uploadFileToserverUrl: string = '/api/upload-file/v1/upload-file';
  public static readonly downloadFileUrl: string = '/api/upload-file/v1/download';

  public static readonly createProspectingLicenseUrl: string = '/api/ags-common/v1/prospecting-license/create';
  public static readonly getAllProspectingLicenseUrl: string = '/api/ags-common/v1/prospecting-license/all';
  public static readonly getAllProspectingLicenseUrlPaged: string = '/api/ags-common/v1/prospecting-license/paged';
  public static readonly pagedSearchUrl: string = '/api/ags-common/v1/prospecting-license/search/paged';
  public static readonly searchUrl: string = '/api/ags-common/v1/prospecting-license/search';
  public static readonly getAllProspectingLicenseLiteUrl: string = '/api/ags-common/v1/prospecting-license/all/lite';
  public static readonly getAllMyProspectingLicenseUrl: string = '/api/ags-common/v1/prospecting-license/individual';
  public static readonly getAllProspectingLicenseStatusesUrl: string = '/api/ags-common/v1/project/status/all';
  public static readonly getProspectingLicenseByIdUrl: string = '/api/ags-common/v1/prospecting-license';

  public static readonly getBGIProjectCountUrl: string = '/api/ags-common/v1/bgiProjects/count';
  public static readonly getNonBGIProjectCountUrl: string = '/api/ags-common/v1/nonBgiProjects/count';
  public static readonly getMyProjectCountUrl: string = '/api/ags-common/v1/userProjects/count';
  public static readonly getMyProjectApprovalCountsUrl: string = '/api/ags-common/v1/projects/individual/counts';
  public static readonly getMyProspectingLicenseApprovalCountsUrl: string = '/api/ags-common/v1/prospecting-license/individual/counts';


  public static readonly getBoreholeCountUrl: string = '/api/ags-common/v1/boreholes/count';
  public static readonly getProjectCountUrl: string = '/api/ags-common/v1/projects/count';
  public static readonly getCompanyCountUrl: string = '/api/ags-common/v1/companies/count';
  public static readonly getProspectingLicenseCountUrl: string = '/api/ags-common/v1/prospecting-licenses/count';
  public static readonly getNonPlBoreholesCountUrl: string = '/api/ags-common/v1/non_pl_boreholes/count';
  public static readonly getMyProspectingLicenseCountUrl: string = '/api/ags-common/v1/userProspectingLicense/count';
  public static readonly getMyProspectingLicenseBoreholesCountUrl: string = '/api/ags-common/v1/userProspectingLicenseBoreholes/count';
  public static readonly getFilesCountUrl: string = '/api/upload-file/v1/files/count';
  public static readonly getDrillersLogCountUrl: string = '/api/drillerlog/v1/driller-log-records/count';
  public static readonly getTimeAndMotionCountUrl: string = '/api/borehole-logs/v1/time-and-motions/count';
  public static readonly getBoreholeLithologyLogCountUrl: string = '/api/geotech/v1/borehole/borehole-lithology-logs/count';
  public static readonly getBoreholeRecoveryLogCountUrl: string = '/api/geotech/v1/borehole/borehole-core-recovery-logs/count';
  public static readonly getWaterBoreholeDrillersLogCountUrl: string = '/api/water-drillers-log/v1/water-drillers-logs/count';
  public static readonly getWaterBoreholeLithologyLogCountUrl: string = '/api/hydro-geology/v1/water-borehole-lithology-logs/count';

  public static readonly createProspectingLicenceMineralChemistryUrl: string = '/api/ags-common/v1/prospecting-license/mineral-chemistry/batch/create';
  public static readonly getProspectingLicenseeMineralChemistryUrl: string = '/api/ags-common/v1/prospecting-license/mineral-chemistry/all';

  public static readonly getAllSampleStatusesUrl: string = '/api/geotech/v1/sample-status/all';
  public static readonly createInfiltrationTestUrl: string = '/api/geotech/v1/infiltration-test/create';
  public static readonly deleteInfiltrationTestUrl: string = '/api/geotech/v1/infiltration-test/delete';
  public static readonly getInfiltrationTestByIdUrl: string = '/api/geotech/v1/infiltration-test/one';
  public static readonly getAllActiveInfiltrationTestsUrl: string = '/api/geotech/v1/infiltration-test/active/all';
  public static readonly createInfiltrationTestLogsUrl: string = '/api/geotech/v1/infiltration-test/logs/create';
  public static readonly createInfiltrationTestLogUrl: string = '/api/geotech/v1/infiltration-test/log/create';
  public static readonly deleteInfiltrationTestLogUrl: string = '/api/geotech/v1/infiltration-test/log/delete';
  public static readonly getAllActiveInfiltrationTestLogsByTestId: string = '/api/geotech/v1/infiltration-test/logs/active';

  public static readonly getAllActiveSamplesUrl: string = '/api/geotech/v1/sample/active/all';

  public static readonly getAllSamplesLiteUrl: string = '/api/geotech/v1/sample/lite';
  public static readonly getSamplesByIdUrl: string = '/api/geotech/v1/sample';
  public static readonly createSampleUrl: string = '/api/geotech/v1/sample/create';
  public static readonly deleteSampleUrl: string = '/api/geotech/v1/sample/delete';
  public static readonly createPetrographyUrl: string = '/api/geotech/v1/petrography-test/create';
  public static readonly getPetrographyUrl: string = '/api/geotech/v1/petrography-test/all';
  public static readonly deletePetrographyUrl: string = '/api/geotech/v1/petrography-test/delete';


  public static readonly createDiameterFormationUrl: string = '/api/diameter-formation/v1/log/create';
  public static readonly createAListOfDiameterFormationdUrl: string = '/api/diameter-formation/v1/logs/create';
  public static readonly deleteDiameterFormationdUrl: string = '/api/diameter-formation/v1/log/delete';
  public static readonly getAllDiameterFormationdByBoreholeIdUrl: string = '/api/diameter-formation/v1/logs';
  public static readonly getAllDiameterFormationsUrl: string = '/api/diameter-formation/v1/logs/all';
  public static readonly getTotalNumberFormationsUrl: string = '/api/diameter-formation/v1/all/count';

  public static readonly createPenetrationRateLogUrl: string = '/api/geotech/v1/penetration-rate-log/create';
  public static readonly createPenetrationRateLogListUrl: string = '/api/geotech/v1/penetration-rate-logs/create';
  public static readonly getAllPenetrationRateLogsUrl: string = '/api/geotech/v1/penetration-rate-log/all';
  public static readonly getAllPenetrationRateLogsByBoreholeIdUrl: string = '/api/geotech/v1/penetration-rate-log/all';
  public static readonly deletePenetrationRateLogUrl: string = '/api/geotech/v1/penetration-rate-log/delete';
  public static readonly getTotalNumberOfPenetrationRateLogUrl: string = '/api/geotech/v1/penetration-rate-log/all/count';

  public static readonly createBulletinUrl: string = '/api/seismology/v1/bulletin/create';
  public static readonly getAllBulletinsUrl: string = '/api/seismology/v1/bulletin/all';

  public static readonly getAllDepartmentsUrl: string = '/api/departments/v1/department/all';
  public static readonly getTotalNumberOfDepartmentsUrl: string = '/api/departments/v1/department/count';
  public static readonly getDepartmentByIdUrl: string = '/api/departments/v1/department';
  public static readonly createDepartmentUrl: string = '/api/departments/v1/department/create';
  public static readonly createAListOfDepartmentsUrl: string = '/api/departments/v1/department/create-list';
  public static readonly deleteDepartmentUrl: string = '/api/departments/v1/department/delete';

  public static readonly getAllBusinessUnitsUrl: string = '/api/business-units/v1/business-unit/all';
  public static readonly getBusinessUnitByIdUrl: string = '/api/business-units/v1/business-unit';
  public static readonly getAllBusinessUnitsByDepartmentUrl: string = '/api/business-units/v1/business-units';
  public static readonly createBusinessUnitUrl: string = '/api/business-units/v1/business-unit/create';
  public static readonly createAListOfBusinessUnitsUrl: string = '/api/business-units/v1/business-unit/create-list';
  public static readonly deleteBusinessUnitUrl: string = '/api/business-units/v1/business-unit/delete';

  public static readonly getQaActivitiesPendingApprovalUrl: string = '/api/activities/v1/qa/activities_pending_approval';
  public static readonly getNonBgIQaActivitiesPendingApprovalUrl: string = '/api/activities/v1/non_bgi_qa/activities_pending_approval';
  public static readonly getNumberOfQaActivitiesPendingApprovalUrl: string = '/api/activities/v1/qa/activities_pending_approval/count';
  public static readonly getAllQaActivitiesRejectedUrl: string = '/api/activities/v1/qa/activities_rejected';
  public static readonly getAllNonBGIQaActivitiesRejectedUrl: string = '/api/activities/v1/non_bgi_qa/activities_rejected';
  public static readonly getNumberAllQaActivitiesRejectedUrl: string = '/api/activities/v1/qa/activities_rejected/count';
  public static readonly getAllQaActivitiesApprovedUrl: string = '/api/activities/v1/qa/activities_approved';
  public static readonly getAllNonBGIQaActivitiesApprovedUrl: string = '/api/activities/v1/non_bgi_qa/activities_approved';
  public static readonly getNumberAllQaActivitiesApprovedUrl: string = '/api/activities/v1/qa/activities_approved/count';
  public static readonly getAllQaActivitiesVettedUrl: string = '/api/activities/v1/qa/activities_all_vetted';
  public static readonly getAllNonBGIQaActivitiesVettedUrl: string = '/api/activities/v1/non_bgi_qa/activities_all_vetted';

  public static readonly getDrillingRequestsByStageUrl: string = '/api/activities/v1/drilling_requests';

  public static readonly getAllManagerActivitiesPendingApprovalUrl: string = '/api/activities/v1/manager/activities_pending_approval';
  public static readonly getAllNonBGIManagerActivitiesPendingApprovalUrl: string = '/api/activities/v1/non_bgi_manager/activities_pending_approval';
  public static readonly getAllManagerActivitiesVettedUrl: string = '/api/activities/v1/manager/activities_all_vetted';
  public static readonly getAllNonBGIManagerActivitiesVettedUrl: string = '/api/activities/v1/non_bgi_manager/activities_all_vetted';
  public static readonly getAllManagerActivitiesApprovedUrl: string = '/api/activities/v1/manager/activities_approved';
  public static readonly getAllNonBGIManagerActivitiesApprovedUrl: string = '/api/activities/v1/non_bgi_manager/activities_approved';
  public static readonly getAllNonBGIManagerActivitiesReturnedUrl: string = '/api/activities/v1/non_bgi_manager/activities_returned';
  public static readonly getAllManagerActivitiesReturnedUrl: string = '/api/activities/v1/manager/activities_returned';


  public static readonly getDashboardCountsUrl: string = '/api/dashboard/counts/v1/';


  public static readonly landFolioIntergrationBaseUrl: string = 'https://nigis-qa.bitri-ist.co.bw/gateway/intergration';
  public static readonly getLandFolioDocuments: string = '/api/landfolio/v1/document_details/all';

}
