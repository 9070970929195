import Swal,{ SweetAlertOptions } from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {DrillingProtocol} from '../../../../shared-kernel/entity/driller/DrillingProtocol';
import {Borehole} from '../../../../shared-kernel/entity/common/borehole';
import {ActivatedRoute} from '@angular/router';
import {DrillerService} from '../../../../driller/driller.service';
import {UnitEnum} from '../../../../shared-kernel/enumerations/unit-enum';
import {Size} from '../../../../shared-kernel/entity/common/size';
import {DrillingMethod} from '../../../../shared-kernel/entity/driller/drilling-method';
import {CoreService} from '../../../geo-tech/core/core.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Unit} from '../../../../shared-kernel/entity/common/unit';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DrillingProtocolCreateComponent } from './drilling-protocol-create/drilling-protocol-create.component';

declare var $: any;

@Component({
  selector: 'app-borehole-construction-drilling-protocol',
  templateUrl: './borehole-construction-drilling-protocol.component.html',
  styleUrls: ['./borehole-construction-drilling-protocol.component.scss']
})
export class BoreholeConstructionDrillingProtocolComponent implements OnInit {

  @Input('currentState') currentState: string = '';
  @Input('projectId') projectId: string = '';
  dataSourceDrillingProtocols: MatTableDataSource<DrillingProtocol> = new MatTableDataSource();
  displayedDrillingProtocolsColumns = ['check', '#', 'Depth From', 'Depth To', 'Diameter', 'Drilling Method', 'Inclination', 'Azimuth', 'Edit', 'Delete'];
  csvDrillingProtocolsColumns = ['#', 'Depth From', 'Depth To', 'Diameter', 'Drilling Method', 'Inclination', 'Azimuth', 'Delete'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileImportInput') fileImportInput: any;

  selectedCvsRecord: DrillingProtocol = new DrillingProtocol();
  csvDdataSource: MatTableDataSource<DrillingProtocol> = new MatTableDataSource();
  csvRecords: DrillingProtocol[] = [];
  modalHeading: string;
  boreholeId: string;
  drillingProtocol: DrillingProtocol;
  drillingProtocolLogs: DrillingProtocol[];
  dataSource: MatTableDataSource<DrillingProtocol> = new MatTableDataSource();
  loading = true;
  enumUnits: typeof UnitEnum = UnitEnum;
  selectedBorehole: any;
  idObj: any;
  dialogConfig = new MatDialogConfig();
  borehole: Borehole = new Borehole();
  drillingMethod: DrillingMethod = new DrillingMethod();
  drillingMethods: DrillingMethod [];

  selection = new SelectionModel<DrillingProtocol>(true, []);

  constructor(
    private drillerService: DrillerService,
    private toast: ToastrService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog) {
    this.route.params.subscribe(params => {
      this.selectedBorehole = params.id;
      this.boreholeId = params.id;
      this.idObj = {id: this.boreholeId};
    });

  }

  ngOnInit() {
    this.getBorehole(this.boreholeId);
    this.initializerDrillingProtocol();
  }

  initializerDrillingProtocol() {
    this.getAllDrillingProtocolsByBoreholeId();
  }

  ngAfterViewInit() {
    this.resetPaginator();
  }


  applyFilter(filterValue: string) {
    this.dataSourceDrillingProtocols.filter = filterValue.trim().toLowerCase();
  }

  getBorehole(id: any) {
    this.coreService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
      }
    );
  }

  openDrillingProtocolForm(drillingProtocol: any) {

   let drillingProtocolsData: DrillingProtocol = new DrillingProtocol();
    if(drillingProtocol!== ''){
      drillingProtocolsData = drillingProtocol;
    }

    drillingProtocolsData.borehole= this.borehole;

    const createDialogRef = this.dialog.open(DrillingProtocolCreateComponent,<MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data : {drillingProtocolsData,drillingProtocols:this.drillingProtocolLogs}
    });

    createDialogRef.afterClosed().subscribe(result => {
      this.getAllDrillingProtocolsByBoreholeId();

    });
  }


  compareFn(c1: DrillingProtocol, c2: DrillingProtocol): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  getAllDrillingProtocolsByBoreholeId() {

    this.drillerService.getAllDrillingProtocolsByBoreholeId(this.boreholeId)
      .subscribe(data => {
          this.drillingProtocolLogs = data as DrillingProtocol[];
          this.dataSourceDrillingProtocols.data = this.drillingProtocolLogs;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }, () => {
          this.loading = false;
          this.resetPaginator();
        }
      );
  }

  errorsmsg() {
    this.toast.error('Borehole construction save Unsuccessful', 'Casing Create');
  }

  successmsg() {
    this.toast.success('Borehole construction saved successfully', 'Casing Create');
  }

  deleteCsvRecord(drl: DrillingProtocol) {
    this.selectedCvsRecord = drl;
    const itemIndex = this.csvRecords.findIndex(item => item.id === this.selectedCvsRecord.id);
    if (itemIndex !== -1) {
      this.csvDdataSource.data.splice(itemIndex, 1);
    }
    this.csvDdataSource = new MatTableDataSource(this.csvRecords);
    this.toast.success('Record deleted', 'Record Deletion');
  }

  deleteRecord(drillingProtocol: DrillingProtocol) {
    this.drillingProtocol = drillingProtocol;
    this.drillingProtocol.deleted = true;
    $('#deleteModal').modal({backdrop: 'static'});
    $('#deleteModal').modal('show');
  }

  getAllDrillingMethods() {
    this.drillerService.getAllDrillingMethods()
      .subscribe(data => {
        this.drillingMethods = data as DrillingMethod[];
      });
  }

  createNewRecord(modalId) {

    if (Number(this.drillingProtocol.depthFrom.measure) <= Number(this.drillingProtocol.depthTo.measure)) {

      this.spinner.show();
      this.drillingProtocol.depthFrom.unit.id = this.enumUnits.meter;
      this.drillingProtocol.depthTo.unit.id = this.enumUnits.meter;
      this.drillingProtocol.diameter.unit.id = this.enumUnits.meter;
      this.drillingProtocol.inclination.unit.id = this.enumUnits.meter;
      this.drillingProtocol.azimuth.unit.id = this.enumUnits.meter;
      this.drillingProtocol.borehole = new Borehole();
      this.drillingProtocol.borehole.id = this.boreholeId;

      this.drillerService.createDrillingProtocol(this.drillingProtocol)
        .subscribe(data => {
            this.spinner.hide();
          }, error => {
            this.toast.error('Sorry, try again, record creation failed', 'Failure');
            this.spinner.hide();
          },
          () => {
            $('#' + modalId).modal('hide');
            if (modalId === 'deleteModal') {
              this.toast.success('Operation successful', 'Delete Record');
            } else {
              this.toast.success('Record created successfully', 'Add Record');
            }

            this.initializerDrillingProtocol();
            this.spinner.hide();
          });
    } else {
      this.toast.error('Depth To value should be greater than the Depth From value');
    }
  }

  importCSVFile() {
    $('#uploadCsv').click();
  }

  showCsvModal() {
    $('#csvModal').modal({backdrop: 'static'});
    $('#csvModal').modal('show');
  }

  isCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  saveBatch() {
    this.spinner.show();
    this.drillerService.batchCreateDrillingProtocol(this.csvDdataSource.data).subscribe(data => {
        this.spinner.hide();
      }, error => {
        this.toast.error('Sorry, try again, record creation failed', 'Failure');
        this.spinner.hide();
      }, () => {
        $('#csvModal').modal('hide');
        this.toast.success('Records saved successfully', 'Add Batch');
        this.initializerDrillingProtocol();
        this.fileReset();
      }
    );
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const dataArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const data = (<string>csvRecordsArray[i]).split(',');
      if (data.length === headerLength) {

        const drillingProtocolLogCsv = new DrillingProtocol();
        drillingProtocolLogCsv.depthFrom = new Size();
        drillingProtocolLogCsv.depthFrom.unit = new Unit();
        drillingProtocolLogCsv.depthFrom.unit.id = this.enumUnits.meter;
        drillingProtocolLogCsv.depthTo = new Size();
        drillingProtocolLogCsv.depthTo.unit = new Unit();
        drillingProtocolLogCsv.depthTo.unit.id = this.enumUnits.meter;

        drillingProtocolLogCsv.diameter = new Size();
        drillingProtocolLogCsv.diameter.unit = new Unit();
        drillingProtocolLogCsv.diameter.unit.id = this.enumUnits.meter;
        drillingProtocolLogCsv.inclination = new Size();
        drillingProtocolLogCsv.inclination.unit = new Unit();
        drillingProtocolLogCsv.inclination.unit.id = this.enumUnits.meter;
        drillingProtocolLogCsv.azimuth = new Size();
        drillingProtocolLogCsv.azimuth.unit = new Unit();
        drillingProtocolLogCsv.azimuth.unit.id = this.enumUnits.meter;
        drillingProtocolLogCsv.drillingMethod = new DrillingMethod();

        drillingProtocolLogCsv.depthFrom.measure = '' + parseFloat(data[0]);
        drillingProtocolLogCsv.depthTo.measure = '' + parseFloat(data[1]);
        drillingProtocolLogCsv.diameter.measure = '' + parseFloat(data[2]);
        this.drillingMethods.forEach(value => {
          if (value.name.trim().toLowerCase() === data[3].trim().toLowerCase()) {
            drillingProtocolLogCsv.drillingMethod = value;
          }
        });

        drillingProtocolLogCsv.inclination.measure = '' + parseFloat(data[4]);
        drillingProtocolLogCsv.azimuth.measure = '' + parseFloat(data[5]);
        drillingProtocolLogCsv.deleted = false;

        drillingProtocolLogCsv.borehole = new Borehole();
        drillingProtocolLogCsv.borehole.id = this.boreholeId;

        dataArr.push(drillingProtocolLogCsv);
      }
    }
    this.csvDdataSource.data = dataArr;
    return dataArr;
  }

  fileChangeListener($event: any): void {
    const text = [];
    const files = $event.srcElement.files;

    if (this.isCSVFile(files[0])) {

      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = function () {
      };
      this.showCsvModal();

    } else {
      this.fileReset();
    }
  }

  fileReset() {
    this.fileImportInput.nativeElement.value = '';
    this.csvRecords = [];
    this.csvDdataSource = new MatTableDataSource<DrillingProtocol>();
    this.resetPaginator();
  }

  resetPaginator() {
    this.dataSourceDrillingProtocols.paginator = this.paginator;
    this.dataSourceDrillingProtocols.sort = this.sort;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceDrillingProtocols.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceDrillingProtocols.data ? this.dataSourceDrillingProtocols.data.length : 0;
    return numSelected === numRows;
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }

  onDeleteAll(){
    Swal.fire(<SweetAlertOptions>{
      title: 'Delete',
      type: 'warning',
      html: 'Are you sure you want to <b>delete</b>?',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then(result => {
        if (result.value) {
            this.drillerService.batchDeleteDrillingProtocol(this.selection.selected).subscribe(() => {
              this.getAllDrillingProtocolsByBoreholeId();
              this.toast.success('Delete Drilling Protocol', 'You have Successfully deleted Drilling Protocol');
            }, err => {
                this.toast.error(err.message, "Failed Delete");
            });
        }
    });
  }

}
