import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core-lithology',
  template: `<router-outlet></router-outlet>`
})
export class CoreLithologyComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
