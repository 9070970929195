import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";

import { Observable } from 'rxjs';

import { SampleGeoChemistry } from './csv-record.model';
import { environment } from "./../../../../../../environments/environment";

@Injectable()
export class SampleGeoChemistryService {
    constructor(
        private _http: HttpClient
    ) {}

    addGeoChemistry(geChem: Array<SampleGeoChemistry>, sampleNumber: string): Observable<Array<SampleGeoChemistry>> {
        return this._http.post<Array<SampleGeoChemistry>>(environment.appliedGeoscienceBaseUrl + `/api/sample/v1/geo_chemistry/${sampleNumber}`, geChem);
    }

    addGeoChemistryInBorehole(geChem: Array<SampleGeoChemistry>, boreholeId: string): Observable<Array<SampleGeoChemistry>> {
        return this._http.post<Array<SampleGeoChemistry>>(environment.appliedGeoscienceBaseUrl + `/api/sample/v1/geo_chemistry/borehole/${boreholeId}`, geChem);
    }
    addMineralChemistryInProspectingLicense(geChem: Array<SampleGeoChemistry>, boreholeId: string): Observable<Array<SampleGeoChemistry>> {
        return this._http.post<Array<SampleGeoChemistry>>(environment.appliedGeoscienceBaseUrl + `/api/sample/v1/geo_chemistry/prospecting_license/${boreholeId}`, geChem);
    }

    getGeoChem(sampleNumber: string): Observable<Array<SampleGeoChemistry>> {
        return this._http.get<Array<SampleGeoChemistry>>(environment.appliedGeoscienceBaseUrl + `/api/sample/v1/geo_chemistry/${sampleNumber}`);
    }

    getGeoChemByBoreholeId(boreholeId: string): Observable<Array<SampleGeoChemistry>> {
      return this._http.get<Array<SampleGeoChemistry>>(environment.appliedGeoscienceBaseUrl + `/api/sample/v1/geo_chemistry/borehole/${boreholeId}`);
    }
}
