import { ToastrService } from 'ngx-toastr';
import { CollectionsApprovalService } from 'src/app/collections/services/collections.approval.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TrayApproveAction } from 'src/app/collections/model/tray-approval-state';
import {ScheduleService} from '../../../services/schedule.service';
import {CustomerRequest} from '../../../entity/CustomerRequest';
import {PeopleService} from '../../../../_core/mock/people.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Person} from '../../../../_core/data/_models/people.model';
import {EventScheduleFormComponent} from '../event-schedule-form/event-schedule-form.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EventSchedule} from '../../../entity/EventSchedule';
import {CustomerRequestFormComponent} from '../customer-request-form/customer-request-form.component';
import {DocumentsService} from 'src/app/shared/documents/dococument.service';
import {CloseRequestFormComponent} from '../close-request-form/close-request-form.component';
import {CompanyService} from '../../../../applied-geoscience/company/company.service';
import * as requestServicesForApproval from '../../../shared/request-services-for-approval.json';
import { CoreshedService } from '../../../services/coreshed.service';
import { CoreshedLocation } from '../../../entity/CoreshedLocation';
import { AppUsersService } from '../../../../_settings/services/users.service';

@Component({
  selector: 'app-customer-request-details',
  templateUrl: './customer-request-details.component.html',
  styleUrls: ['./customer-request-details.component.scss']
})
export class CustomerRequestDetailsComponent implements OnInit {

  customerRequest: CustomerRequest;
  eventSchedule: EventSchedule;
  attendants: Person[] = [];
  actualAttendants: Person[] = [];
  currentComment: string;

  // -- For captured when scheduling an event --
  servicedBy: Person[] = [];

  // -- Captured when closing customer request (part of the request) --
  requestServicedBy: Person[] = [];

  eventServiceBy: Person[]  = [];

  dialogConfig = new MatDialogConfig();

  selectedSection: string;
  documentCount = 0;

  company = null;
  customerRequestId:string;
  needManagerApproval = false;

  approvedServiceList: any[] = (requestServicesForApproval as any).default;

  constructor(
    private dialog: MatDialog,
    private scheduleService: ScheduleService,
    private router: ActivatedRoute,
    private companyService: CompanyService,
    private appUsersService: AppUsersService,
    private peopleService: PeopleService,
    private _docServ: DocumentsService,
    private spinner: NgxSpinnerService,
    private collectionsApprovalService: CollectionsApprovalService,
    private toastr: ToastrService,private coreshedService:CoreshedService) {
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '800px';

    this.router.params.subscribe(
      params => {
        this.customerRequestId = params.id;
        this.getCustomerRequestById(params.id);
      }
    );
  }

  exportPDF() {
  }

  onCloseRequest() {
    this.customerRequest.customerID = this.customerRequest.customerID.id;
    this.dialog.open(CloseRequestFormComponent,
      {disableClose: true, autoFocus: true, width: '650px', data: {request: this.customerRequest}})
      .afterClosed().subscribe(result => {
      this.getCustomerRequestById(this.customerRequest.id);
    });
  }

  onEdit(): void {
    this.dialogConfig.data = {
      request: this.customerRequest,
      requestType: (this.customerRequest.company) ? 'company' : 'individual'
    };

    this.dialog.open(CustomerRequestFormComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getCustomerRequestById(this.customerRequest.id);
    });
  }

  onScheduleEvent(): void {
    this.dialogConfig.data = {
      customerRequest: this.customerRequest,
      eventSchedule: this.eventSchedule,
      edit: true,
    };

    this.dialog.open(EventScheduleFormComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      this.getEventScheduleByRequestId(this.customerRequest.id);
    });
  }

  getEventScheduleByRequestId(id: string) {
    this.scheduleService.getAllEventScheduleByRequestId(id).subscribe(
      events => {
        if (events.length > 0) {
          this.eventSchedule = events[0];


          this.eventServiceBy = [];
          this.eventSchedule.servicedBy.forEach(pid => {
            this.peopleService.getPersonById(pid).subscribe(
              person => {
                this.eventServiceBy.push(person);
              }
            );
          });
          let centers = this.eventSchedule.serviceCenters;
          this.eventSchedule.serviceCenters = [];
          centers.forEach(value => {
            this.getCoreshedName(value);
          })

        }
      }
    );
  }

  getCoreshedName(id){
    this.coreshedService.getCoreshedLocationsById(id).subscribe(value => {
      let resultSet = value as CoreshedLocation;
      this.eventSchedule.serviceCenters.push(resultSet.name);
    })
  }

  getCustomerRequestById(id: string) {
    this.scheduleService.getCustomerRequestById(id)
      .subscribe(
        request => {
          this.customerRequest = request;

          this.customerRequest.commodity = this.customerRequest.commodities.map(x=>x.name).join(",")

          this.getEventScheduleByRequestId(this.customerRequest.id);
          this.countDocuments();
          this.appUsersService.getPersonById(this.customerRequest.customerID).subscribe(
            person => {
              this.customerRequest.customerID = person;
            }
          );

          if (this.customerRequest.company) {
            this.companyService.getCompanyById(this.customerRequest.company).subscribe(
              comp => {
                this.company = comp;
              }
            );
          }

          this.attendants = [];
          this.customerRequest.expectedAttendants.forEach(attendant => {
            this.peopleService.getPersonById(attendant).subscribe(
              person => {
                this.attendants.push(person);
              }
            );
          });

          this.actualAttendants = [];
          this.customerRequest.actualAttendants.forEach(attendant => {
            this.peopleService.getPersonById(attendant).subscribe(
              person => {
                this.actualAttendants.push(person);
              }
            );
          });

          this.requestServicedBy = [];
          this.customerRequest.servicedBy.forEach(attendant => {
            this.peopleService.getPersonById(attendant).subscribe(
              person => {
                this.requestServicedBy.push(person);
              }
            );
          });
          this.checkIfServicesNeedManagerApproval();
        }
      );
  }

  checkIfServicesNeedManagerApproval(){
    let count = 0;
    this.customerRequest.requestedServices.forEach(service =>{
      this.approvedServiceList.forEach(approvedService =>{
        if (service === approvedService){
          count = count + 1;
        }
      })
    })

    if (count > 0) {
      this.needManagerApproval = true
    } else (
      this.needManagerApproval = false
    )
  }

  redirectToDocuments() {
    this.selectedSection = 'customerRequestDocuments';
  }

  private countDocuments() {
    this._docServ.getDocumentCount(this.customerRequest.id).subscribe(res => {
      this.documentCount = res;
    });
  }

  changeState(action: TrayApproveAction) {
    let successMessage: string = 'Customer submitted successfully';
    let title: string = 'Customer Request Submit';

    switch (+action) {
      case TrayApproveAction.SUBMIT_TO_MANAGER:
        successMessage = 'Customer Request submitted successfully';
        title = 'Customer Submit';
        break;
      case TrayApproveAction.MANAGER_APPROVE:
        successMessage = 'Customer Request approved successfully';
        title = 'Customer Request Approve';
        break;
      case TrayApproveAction.MANAGER_REJECT:
        successMessage = 'Customer Request rejected successfully';
        title = 'Customer Request Reject';
        break;
      case TrayApproveAction.SUBMIT_SPECIMEN_TO_MANAGER:
        successMessage = 'Customer Request submitted successfully';
        title = 'Customer Submit';
        break;
      case TrayApproveAction.MANAGER_APPROVE_SPECIMEN:
        successMessage = 'Customer Request approved successfully';
        title = 'Customer Request Approve';
        break;
      case TrayApproveAction.MANAGER_REJECT_SPECIMEN:
        successMessage = 'Customer Request rejected successfully';
        title = 'Customer Request Reject';
        break;
      default:
        throw new Error('Unknown change state');
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this.spinner.show();
        this.collectionsApprovalService.changeStateCustomerRequest(this.customerRequest.id, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toastr.success(successMessage, title);
          this.getCustomerRequestById(this.customerRequestId);
        }, err => {
          this.spinner.hide();
          this.toastr.warning(err.message, 'Failed');
        }, ()=> {
          this.spinner.hide();
        });
      }
    });
  }
}
