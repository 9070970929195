import { UnapprovedActivitiesListComponent } from "./applied-geoscience/project/unapproved-activities-list/unapproved-activities-list.component";
import { WaterBearingZonesDetailsComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/water-bearing-zones/water-bearing-zones-details/water-bearing-zones-details.component";
import { MineralResourceDashboardComponent } from "./dashboards/mineral-resource-dashboard/mineral-resource-dashboard.component";
import { BrowserModule } from "@angular/platform-browser";
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { NgModule, forwardRef } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { AppNavComponent } from "./app-nav/app-nav.component";
import { LayoutModule } from "@angular/cdk/layout";
import {
  MatCheckboxModule,
  MatDialogModule,
  MatStepperModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatDialogRef,
  MatSlideToggleModule,
} from "@angular/material";
import * as bootstrap from "bootstrap";
import * as $ from "jquery";
import { ChartsModule } from "ng2-charts";

import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatIconModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatOptionModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatTabsModule,
  MatExpansionModule,
} from "@angular/material";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from "./auth/login/login.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { LockscreenComponent } from "./auth/lockscreen/lockscreen.component";
import { TABLEComponent } from "./table/table.component";
import { RegisterComponent } from "./auth/user/register/register.component";
import { UserComponent } from "./auth/user/user.component";
import { UserListComponent } from "./auth/user/list/list.component";
import { FirstSetupComponent } from "./auth/user/first-setup/first-setup.component";
import { CreateRolesComponent } from "./auth/roles/create/create.component";
import { ObserversModule } from "@angular/cdk/observers";
import { AdminComponent } from "./admin/admin.component";
import { RolesComponent } from "./auth/roles/roles.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { Session } from "./shared-kernel/services/usermanager/session";
import { AppliedGeoscienceComponent } from "./applied-geoscience/applied-geoscience.component";
import { DrillerComponent } from "./driller/driller.component";
import { ProjectComponent } from "./applied-geoscience/project/project.component";
import { CompanyComponent } from "./applied-geoscience/company/company.component";
import { GeoTechComponent } from "./applied-geoscience/geo-tech/geo-tech.component";
import { BoreholeGeoTechComponent } from "./applied-geoscience/geo-tech/borehole/borehole.component";
import { CoreComponent } from "./applied-geoscience/geo-tech/core/core.component";
import { CoreLithologyComponent } from "./applied-geoscience/geo-tech/core/core-lithology/core-lithology.component";
import { DrillingRequestComponent } from "./driller/drilling-request/drilling-request.component";
import { HydrogeologyComponent } from "./applied-geoscience/hydrogeology/hydrogeology.component";
import { DynamicConePenotrometerComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dynamic-cone-penotrometer.component";
import { DrillerLogComponent } from "./driller/drillerlog/drillerlog.component";
import { BoreholeLogsComponent } from "./applied-geoscience/geo-tech/borehole/logs/borehole-logs.component";
import { CoreLithologyListComponent } from "./applied-geoscience/geo-tech/core/core-lithology/core-lithology-list/core-lithology-list.component";
import { CompanyListComponent } from "./applied-geoscience/company/company-list/company-list.component";
import { ProjectCreateComponent } from "./applied-geoscience/project/project-create/project-create.component";
import { ProjectListComponent } from "./applied-geoscience/project/project-list/project-list.component";
import { DrillingRequestListComponent } from "./driller/drilling-request/drilling-request-list/drilling-request-list.component";
import { RoleListComponent } from "./auth/roles/role-list/role-list.component";
import { HighlightDirective } from "./highlight.directive";
import { MatchValueDirective } from "./directives/match-value.directive.directive";
import { MustMatchDirective } from "./_helpers/must-match.directive";
import { CoreRecoveryComponent } from "./applied-geoscience/geo-tech/core/core-recovery/core-recovery.component";
import { CoreRecoveryListComponent } from "./applied-geoscience/geo-tech/core/core-recovery/core-recovery-list/core-recovery-list.component";
import { DcpFoundationComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-foundation.component";
import { DcpTestFoundationCreateComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-create/dcp-test-foundation-create.component";
import { DcpTestFoundationListComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-list/dcp-test-foundation-list.component";
import { TwoDigitDecimaNumberDirective } from "./shared-kernel/entity/common/util/two-digit-decima-number.directive";
import { DiscontinuityLogComponent } from "./applied-geoscience/geo-tech/core/core-recovery/discontinuity-log/discontinuity-log.component";
import { DiscontinuityLogListComponent } from "./applied-geoscience/geo-tech/core/core-recovery/discontinuity-log/discontinuity-log-list/discontinuity-log-list.component";
import { DiscontinuityLogCreateComponent } from "./applied-geoscience/geo-tech/core/core-recovery/discontinuity-log/discontinuity-log-create/discontinuity-log-create.component";
import { TimeandmotionComponent } from "./driller/timeandmotion/timeandmotion.component";
import { WaterBoreholeComponent } from "./applied-geoscience/geo-tech/borehole/water-borehole/water-borehole.component";
import { WaterBoreholeLithologyLogListComponent } from "./applied-geoscience/hydrogeology/water-borehole-lithology-log/water-borehole-lithology-log-list/water-borehole-lithology-log-list.component";
import { WaterboreholeLogsComponent } from "./applied-geoscience/geo-tech/borehole/water-borehole/waterborehole-logs/waterborehole-logs.component";
import { WaterDrillersLogComponent } from "./applied-geoscience/geo-tech/borehole/water-drillers-log/water-drillers-log.component";
import { DcpSubgradeComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-subgrade.component";
import { DcpRoadworksCreateComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-create/dcp-roadworks-create.component";
import { DcpSubgradeLogsComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-subgrade-logs/dcp-subgrade-logs.component";
import { DrillerChecklistComponent } from "./driller/driller-checklist/driller-checklist.component";
import { DrillerChecklistListComponent } from "./driller/driller-checklist/driller-checklist-list/driller-checklist-list.component";
import { WaterBoreholeLithologyLogComponent } from "./applied-geoscience/hydrogeology/water-borehole-lithology-log/water-borehole-lithology-log.component";
import { DrillingProtocolComponent } from "./driller/drilling-protocol/drilling-protocol.component";
import { DrillingProtocolListComponent } from "./driller/drilling-protocol/drilling-protocol-list/drilling-protocol-list.component";
import { WaterPumpTestComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test.component";
import { WaterPumpTestStepTestComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test.component";
import { WaterPumpTestStepTestListComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test-list/water-pump-test-step-test-list.component";

import { NgxSpinnerModule } from "ngx-spinner";
import { CompanyDetailComponent } from "./applied-geoscience/company/company-detail/company-detail.component";
import { ProjectDetailComponent as ProDetailComp } from "./applied-geoscience/project/project-detail/project-detail.component";
import { ProjectDetailComponent } from "./applied-geoscience/project/project-detail/project-detail.component";
import { WaterPumpTestCalibrationComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration.component";
import { WaterPumpTestCalibrationListComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration-list/water-pump-test-calibration-list.component";
import { WaterBoreholeViewComponent } from "./applied-geoscience/geo-tech/borehole/water-borehole/water-borehole-view/water-borehole-view.component";
import { WaterPumpTestLogsComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-logs/water-pump-test-logs.component";
import { WaterPumpTestCalibrationCreateComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration-create/water-pump-test-calibration-create.component";
import { WaterPumpTestStepTestCreateComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test-create/water-pump-test-step-test-create.component";
import { WaterPumpTestCalibrationViewComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-calibration/water-pump-test-calibration-view/water-pump-test-calibration-view.component";
import { WaterPumpTestStepTestViewComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-step-test/water-pump-test-step-test-view/water-pump-test-step-test-view.component";
import { TrialPitListComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/trial-pit-list/trial-pit-list.component";
import { TrialPitComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/trial-pit.component";
import { WaterPumpTestConstantRateTestComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test.component";
import { WaterPumpTestConstantRateTestViewComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test-view/water-pump-test-constant-rate-test-view.component";
import { WaterPumpTestConstantRateTestListComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test-list/water-pump-test-constant-rate-test-list.component";
import { WaterPumpTestConstantRateTestCreateComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/water-pump-test-constant-rate-test-create/water-pump-test-constant-rate-test-create.component";
import { JwtModule, JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { HydrologeologyBoreholeConstructionComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/hydrologeology-borehole-construction.component";
import { BoreholeConstructionCasingComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-casing/borehole-construction-casing.component";
import { BoreholeConstructionScreeningComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-screening/borehole-construction-screening.component";
import { WaterBearingZonesComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/water-bearing-zones/water-bearing-zones.component";
import { BoreholeConstructionDrillingProtocolComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-drilling-protocol/borehole-construction-drilling-protocol.component";
import { UploadedFilesComponent } from "./common/uploaded-files/uploaded-files.component";
import { WaterPumpTestRecoveryComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery.component";
import { WaterPumpTestRecoveryCreateComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery-create/water-pump-test-recovery-create.component";
import { WaterPumpTestRecoveryListComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery-list/water-pump-test-recovery-list.component";
import { WaterPumpTestRecoveryViewComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-recovery/water-pump-test-recovery-view/water-pump-test-recovery-view.component";
import {
  PLComponent,
  PLDetailsComponent,
  PLListComponent,
  PLService,
  PLValidation,
} from "./applied-geoscience/prospecting-licenses/index";
import { CompanyCreateComponent } from "./applied-geoscience/company/company-create/company-create.component";
import { PlotToMapComponent } from "./common/plot-to-map/plot-to-map.component";
import { ProspectingLicenceMineralChemistryComponent } from "./applied-geoscience/prospecting-licenses/prospecting-licence-mineral-chemistry/prospecting-licence-mineral-chemistry.component";
import { DiameterFormationComponent } from "./common/diameter-formation/diameter-formation.component";
import { PlBoreholeLogsComponent } from "./applied-geoscience/geo-tech/borehole/logs/pl-borehole/pl-borehole-logs.component";
import { EfficiencyFormsComponent } from "./applied-geoscience/project/efficiency-forms/efficiency-forms.component";
import { TimeandmotionProjectComponent } from "./applied-geoscience/project/timeandmotion-project/timeandmotion-project.component";
import { DcpTestFoundationDetailComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/dcp-test-foundation-detail/dcp-test-foundation-detail.component";
import { PenetrationRateComponent } from "./common/penetration-rate/penetration-rate.component";
import { SoilLogComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log.component";
import { SoilLogListComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-list/soil-log-list.component";
import { AuthorityComponent } from "./auth/authority/authority.component";
import { AuthorityGroupComponent } from "./auth/authority-group/authority-group.component";

// Sytems Security
import { SettingsComponent } from "./_settings/settings.component";
import { WaterAnalyticalReportsComponent } from "./applied-geoscience/geo-tech/borehole/water-borehole/water-analytical-reports/water-analytical-reports.component";
import { InfiltrationTestCreateComponent } from "./applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-create/infiltration-test-create.component";
import { InfiltrationTestListComponent } from "./applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-list/infiltration-test-list.component";
import { InfiltrationTestDetailComponent } from "./applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-detail/infiltration-test-detail.component";
import { InfiltrationTestLogComponent } from "./applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-log/infiltration-test-log.component";
import { InfiltrationTestLogFormComponent } from "./applied-geoscience/geo-tech/soil/infiltration-test/infiltration-test-log-form/infiltration-test-log-form.component";
import { DcpRoadworksDetailComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-detail/dcp-roadworks-detail.component";
import { DcpRoadworksListComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-list/dcp-roadworks-list.component";
import { CoreModule } from "./_core/core.module";
import { WaterBoreholeLithologyFormComponent } from "./applied-geoscience/geo-tech/borehole/water-borehole/waterborehole-logs/water-borehole-lithology-form/water-borehole-lithology-form.component";
import { DrillingRequestDetailComponent } from "./driller/drilling-request/drilling-request-detail/drilling-request-detail.component";

import { TestPointListComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/test-points/test-point-list/test-point-list.component";
import { TestPointContainerComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/test-points/test-points-container";
import { CreateTestPointComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-foundation/test-points/create-test-point/create-test-point.component";

import { DcpRoadworksLogsComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-detail/dcp-roadworks-logs/dcp-roadworks-logs.component";
import { DcpRoadworksLogsCreateComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-detail/dcp-roadworks-logs/dcp-roadworks-logs-create/dcp-roadworks-logs-create.component";
import { DcpRoadworksLogsDeleteComponent } from "./applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcp-roadworks-detail/dcp-roadworks-logs/dcp-roadworks-logs-delete/dcp-roadworks-logs-delete.component";
import { BulletinListComponent } from "./applied-geoscience/seismology/bulletin-list/bulletin-list.component";
import { EditUserProfileComponent } from "./user-profile/edit-user-profile/edit-user-profile.component";
import { IntegerNumberDirective } from "./shared-kernel/entity/common/util/integer-number.directive";

import { ChangeStateComponent } from "./applied-geoscience/geo-tech/borehole/water-borehole/change-state/change-state.component";
import { AuthInterceptor } from "./_auth/_providers/auth.interceptor";
import { TokenProvider } from "./_auth/_providers/token.provider";
import { AuthGuard } from "./_auth/_guards/auth.guard";
import { RoleGuard } from "./_auth/_guards/role.guard";
import { LazyLoadGuard } from "./_auth/_guards/lazy-load.guard";
import { DashboardComponent } from "./dashboards/dashboard/dashboard.component";
import { UserDashboardComponent } from "./dashboards/user-dashboard/user-dashboard.component";
import { ManagerDashboardComponent } from "./dashboards/manager-dashboard/manager-dashboard.component";
import { QaDashboardComponent } from "./dashboards/qa-dashboard/qa-dashboard.component";

import { SharedModule } from "./shared/shared.module";
import { TrialpitTestsComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/trialpit-tests/trialpit-tests.component";
import { SoilChemistryImportComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/trialpit-tests/soil-chemistry-import/soil-chemistry-import.component";
import { SamplesComponent } from "./applied-geoscience/geo-tech/samples/samples.component";
import { SampleCreateComponent } from "./applied-geoscience/geo-tech/samples/sample-create/sample-create.component";
import { DrillingRequestCreateComponent } from "./driller/drilling-request/drilling-request-create/drilling-request-create.component";
import { SamplesDetailsComponent } from "./applied-geoscience/geo-tech/samples/samples-details/samples-details.component";
import { ProspectingLicenceMembersFormComponent } from "./applied-geoscience/prospecting-licenses/prospecting-licence-members-form/prospecting-licence-members-form.component";
import { ProjectDetailMembersComponent } from "./applied-geoscience/project/project-detail/project-detail-members/project-detail-members.component";
import { ProjectDetailMemberDeleteComponent } from "./applied-geoscience/project/project-detail/project-detail-members/project-detail-member-delete/project-detail-member-delete.component";
import { TrialPitCreateComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/trial-pit-create/trial-pit-create.component";
import { AddContactComponent } from "./applied-geoscience/company/add-contact/add-contact.component";
import { SixDigitDecimaNumberDirective } from "./_helpers/six-digit-decima-number.directive";
import { UploadFileComponent } from "./common/uploaded-files/upload-file/upload-file.component";
import { WaterDrillersLogCreateComponent } from "./applied-geoscience/geo-tech/borehole/water-drillers-log/water-drillers-log-create/water-drillers-log-create.component";
import { WaterBearingZonesCreateComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/water-bearing-zones/water-bearing-zones-create/water-bearing-zones-create.component";
import { PenetrationRateCreateComponent } from "./common/penetration-rate/penetration-rate-create/penetration-rate-create.component";
import { BoreholeConstructionScreeningCreateComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-screening/borehole-construction-screening-create/borehole-construction-screening-create.component";
import { DrillingProtocolCreateComponent } from "./applied-geoscience/hydrogeology/hydrologeology-borehole-construction/borehole-construction-drilling-protocol/drilling-protocol-create/drilling-protocol-create.component";
import { ImageComponent } from "./common/image/image.component";
import { SampleTestsComponent } from "./applied-geoscience/geo-tech/samples/samples-details/sample-tests/sample-tests.component";
import { SampleGeoChemistryComponent } from "./applied-geoscience/geo-tech/samples/samples-details/geo-chemistry/geo-chemistry.component";
import { PetrographyTestComponent } from "./applied-geoscience/geo-tech/samples/samples-details/sample-tests/petrography-test/petrography-test.component";
import { PetrographyTestCreateComponent } from "./applied-geoscience/geo-tech/samples/samples-details/sample-tests/petrography-test/petrography-test-create/petrography-test-create.component";
import { SoilLogCreateComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-create/soil-log-create.component";
import { DrillingRequestBoreholesComponent } from "./driller/drilling-request/drilling-request-boreholes/drilling-request-boreholes.component";
import { AddDRBoreholeComponent } from "./driller/drilling-request/drilling-request-boreholes/add-drborehole/add-drborehole.component";
import { DrillingRequestBoreholeDetailsComponent } from "./driller/drilling-request/drilling-request-boreholes/drilling-request-borehole-details/drilling-request-borehole-details.component";
import { LightboxModule } from "ngx-lightbox";
import { PersonnelSortPipe } from "./shared-kernel/pipes/personnel.sort.pipe";
import { SuperintendentDashboardComponent } from "./dashboards/superintendent-dashboard/superintendent-dashboard.component";
import { DrillingChecklistDetailComponent } from "./driller/driller-checklist/drilling-checklist-detail/drilling-checklist-detail.component";
import { DrillingLogDetailsComponent } from "./driller/drillerlog/drilling-log-details/drilling-log-details.component";
import { CoreLithologyCreateComponent } from "./applied-geoscience/geo-tech/core/core-lithology/core-lithology-create/core-lithology-create.component";
import { CoreLithologyDetailsComponent } from "./applied-geoscience/geo-tech/core/core-lithology/core-lithology-details/core-lithology-details.component";
import { TimeandmotionCreateComponent } from "./driller/timeandmotion/timeandmotion-create/timeandmotion-create.component";
import { DrillersLogImportComponent } from "./driller/drillerlog/drillers-log-import/drillers-log-import.component";
import { PlBoreholeDrillersLogCreateComponent } from "./applied-geoscience/geo-tech/borehole/logs/pl-borehole/drillers-log-create/pl-borehole-drillers-log-create.component";
import { TimeandmotionDetailsComponent } from "./driller/timeandmotion/timeandmotion-details/timeandmotion-details.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PlDrillersLogImportComponent } from "./applied-geoscience/geo-tech/borehole/logs/pl-borehole/log-import/pl-drillers-log-import.component";
import { ProjectMembersComponent } from "./applied-geoscience/project/project-detail/project-members/project-members.component";
import { AddMemberComponent } from "./applied-geoscience/project/project-detail/project-members/add-member/add-member.component";
import { MemberListComponent } from "./applied-geoscience/project/project-detail/project-members/list-members/member-list.component";
import { UpdateMemberRoleComponent } from "./applied-geoscience/project/project-detail/project-members/update-role/update-role.component";
import { SoilLogDetailsComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-details/soil-log-details.component";
import { SoilLogUploadComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-upload/soil-log-upload.component";
import { SoilLogTableComponent } from "./applied-geoscience/geo-tech/soil/trial-pit/soil-log/soil-log-table/soil-log-table.component";
import { ProspectingLicenseCreateComponent } from "./applied-geoscience/prospecting-licenses/prospecting-license-create/prospecting-license-create.component";
import { SurveysDashboardComponent } from "./dashboards/surveys-dashboard/surveys-dashboard.component";
import { CollectionsDashboardComponent } from "./dashboards/collections-dashboard/collections-dashboard.component";
import { MineralsDashboardComponent } from "./dashboards/mineral-resource-dashboard/minerals-dashboard/minerals-dashboard.component";
import { SamplesModule } from "./mineral-resources/samples/samples.module";
import { BoreholeTestsComponent } from "./mineral-resources/borehole-tests/borehole-tests.component";
import { CloseRequestFormComponent } from "./collections/views/schedule/close-request-form/close-request-form.component";
import { SetMyPasswordComponent } from "./_auth/reset-password/set-my-password/set-my-password.component";
import { UserDashboardUIComponent } from "./dashboards/user-dashboard-ui/user-dashboard-ui.component";
import { NgInit } from "./directives/after-init-directive";
import { AdminDashboardComponent } from "./dashboards/admin-dashboard/admin-dashboard.component";
import { SuperintendentDashboardUiComponent } from "./dashboards/superintendent-dashboard-ui/superintendent-dashboard-ui.component";
import { QaDashboardUiComponent } from "./dashboards/qa-dashboard/qa-dashboard-ui/qa-dashboard-ui.component";
import { QaDashboardTableComponent } from "./dashboards/qa-dashboard/qa-dashboard-table/qa-dashboard-table.component";
import { ProjectDashboardComponent } from "./dashboards/user-dashboard/project-dashboard/project-dashboard.component";
import { ProjectDashboardUiComponent } from "./dashboards/user-dashboard/project-dashboard-ui/project-dashboard-ui.component";
import { PlDashboardComponent } from "./dashboards/user-dashboard/pl-dashboard/pl-dashboard.component";
import { PlDashboardUiComponent } from "./dashboards/user-dashboard/pl-dashboard-ui/pl-dashboard-ui.component";
import { ManagerProjectDashboardComponent } from "./dashboards/manager-dashboard/manager-project-dashboard/manager-project-dashboard.component";
import { ManagerProjectDashboardUiComponent } from "./dashboards/manager-dashboard/manager-project-dashboard-ui/manager-project-dashboard-ui.component";
import { ManagerProjectDashboardTableComponent } from "./dashboards/manager-dashboard/manager-project-dashboard-table/manager-project-dashboard-table.component";
import { ManagerActivitiesDashboardComponent } from "./dashboards/manager-dashboard/manager-activities-dashboard/manager-activities-dashboard.component";
import { ManagerActivitiesDashboardUiComponent } from "./dashboards/manager-dashboard/manager-activities-dashboard-ui/manager-activities-dashboard-ui.component";
import { ManagerActivitiesDashboardTableComponent } from "./dashboards/manager-dashboard/manager-activities-dashboard-table/manager-activities-dashboard-table.component";
import { ManagerDrillerDashboardComponent } from "./dashboards/manager-dashboard/manager-driller-dashboard/manager-driller-dashboard.component";
import { ManagerDrillerDashboardUiComponent } from "./dashboards/manager-dashboard/manager-driller-dashboard-ui/manager-driller-dashboard-ui.component";
import { ManagerDrillerDashboardTableComponent } from "./dashboards/manager-dashboard/manager-driller-dashboard-table/manager-driller-dashboard-table.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { CollectionsUserComponent } from "./dashboards/collections-dashboard/collections-user/collections-user.component";
import { MineralsTechDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-tech-dashboard/minerals-tech-dashboard.component";
import { MineralsTechDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-tech-dashboard-ui/minerals-tech-dashboard-ui.component";
import { MineralsTechDashboardTableComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-tech-dashboard-table/minerals-tech-dashboard-table.component";
import { MineralsGeologistSampleDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-sample-dashboard/minerals-geologist-sample-dashboard.component";
import { MineralsGeologistSampleVettingDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-sample-vetting-dashboard/minerals-geologist-sample-vetting-dashboard.component";
import { MineralsGeologistFilesDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-files-dashboard/minerals-geologist-files-dashboard.component";
import { MineralsGeologistSampleDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-sample-dashboard-ui/minerals-geologist-sample-dashboard-ui.component";
import { MineralsGeologistSampleVettingDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-sample-vetting-dashboard-ui/minerals-geologist-sample-vetting-dashboard-ui.component";
import { MineralsGeologistFilesDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-files-dashboard-ui/minerals-geologist-files-dashboard-ui.component";
import { MineralsGeologistFilesDashboardTableComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-files-dashboard-table/minerals-geologist-files-dashboard-table.component";
import { MineralsGeologistFilesVettingDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-files-vetting-dashboard/minerals-geologist-files-vetting-dashboard.component";
import { MineralsGeologistFilesVettingDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-geologist-files-vetting-dashboard-ui/minerals-geologist-files-vetting-dashboard-ui.component";
import { CollectionsDashbordTableComponent } from "./dashboards/collections-dashboard/collections-dashbord-table/collections-dashbord-table.component";
import { MineralsManagerSampleVettingDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-manager-sample-vetting-dashboard/minerals-manager-sample-vetting-dashboard.component";
import { MineralsManagerSampleVettingDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-manager-sample-vetting-dashboard-ui/minerals-manager-sample-vetting-dashboard-ui.component";
import { MineralsManagerFilesVettingDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-manager-files-vetting-dashboard/minerals-manager-files-vetting-dashboard.component";
import { MineralsManagerFilesVettingDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-manager-files-vetting-dashboard-ui/minerals-manager-files-vetting-dashboard-ui.component";
import { CollectionSeniorArchivistComponent } from "./dashboards/collections-dashboard/collection-senior-archivist/collection-senior-archivist.component";
import { CollectionsManagerComponent } from "./dashboards/collections-dashboard/collections-manager/collections-manager.component";
import { CollectionsTechnicianComponent } from "./dashboards/collections-dashboard/collections-technician/collections-technician.component";
import { CollectionsTrayHistoryComponent } from "./dashboards/collections-dashboard/collections-tray-history/collections-tray-history.component";
import { CollectionsCuratorDashboardUiComponent } from "./dashboards/collections-dashboard/collections-curator-dashboard-ui/collections-curator-dashboard-ui.component";
import { MineralResourceProjectDashboardComponent } from "./dashboards/user-dashboard/project-dashboard/mineral-resource-project-dashboard/mineral-resource-project-dashboard.component";
import { ProjectActivitiesMineralsComponent } from "./dashboards/user-dashboard/project-dashboard/mineral-resource-project-dashboard/project-activities-minerals/project-activities-minerals.component";
import { MineralsQaVettingDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-qa-vetting-dashboard/minerals-qa-vetting-dashboard.component";
import { MineralsQaVettingDashboardUiComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-qa-vetting-dashboard-ui/minerals-qa-vetting-dashboard-ui.component";
import { MineralResourceBoreholeDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/mineral-resource-borehole-dashboard/mineral-resource-borehole-dashboard.component";
import { MineralsTechBoreholeDashboardComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/minerals-tech-borehole-dashboard/minerals-tech-borehole-dashboard.component";
import { MineralResourceBoreholeTableComponent } from "./dashboards/minerals-resource-hydrocarbons-dash/mineral-resource-borehole-dashboard/mineral-resource-borehole-table/mineral-resource-borehole-table.component";
import { UpdateRoleComponent } from "./applied-geoscience/prospecting-licenses/prospecting-licence-members-form/update-role/update-role.component";
import { CollectionGeologicalSpecimenVettingComponent } from "./dashboards/collections-dashboard/collection-geological-specimen-vetting/collection-geological-specimen-vetting.component";
import { ManagerGeologicalSpecimenUiComponent } from "./dashboards/manager-dashboard/manager-geological-specimen-ui/manager-geological-specimen-ui.component";
import { CompanyAlphabeticalOrderPipe } from "./shared-kernel/pipes/company-alpha-order.pipe";
import { RelinquishedDocumentsComponent } from "./landfolio/relinquished-documents/relinquished-documents.component";
import { DrillerChecklistCreateComponent } from "./driller/driller-checklist/driller-checklist-create/driller-checklist-create.component";
import { DepthAnalyticalReportsComponent } from './applied-geoscience/geo-tech/analysis/depth-analytical-reports.component';
import { ConstantRateTestUploadComponent } from "./applied-geoscience/hydrogeology/water-pump-test/water-pump-test-constant-rate-test/constant-rate-test-upload/constant-rate-test-upload.component";
import { BoreholeEditorComponent } from "./applied-geoscience/geo-tech/borehole/borehole-editor/borehole-editor.component";
import { BoreholeCreateComponent } from "./applied-geoscience/geo-tech/borehole/borehole-create/borehole-create.component";
import { BoreholeListComponent } from "./applied-geoscience/geo-tech/borehole/borehole-list/borehole-list.component";
import { BoreholeDetailComponent } from "./applied-geoscience/geo-tech/borehole/borehole-details/borehole-detail.component";
import { ProspectingLicenseSelectComponent } from "./applied-geoscience/prospecting-licenses/prospecting-licence-select/prospecting-license-select.component";

export function jwtOptionsFactory(tokenProvider: TokenProvider) {
  return {
    tokenGetter: () => {
      return tokenProvider.tokenGetter();
    },
  }; 
}

@NgModule({
  declarations: [
    AdminComponent,
    AppComponent,
    AppNavComponent,
    LoginComponent,
    PageNotFoundComponent,
    AdminComponent,
    LockscreenComponent,
    TABLEComponent,
    RolesComponent,
    DashboardComponent,
    RegisterComponent,
    UserComponent,
    UserListComponent,
    ManagerDashboardComponent,
    QaDashboardComponent,
    FirstSetupComponent,
    CreateRolesComponent,
    AppliedGeoscienceComponent,
    DrillerComponent,
    ProjectComponent,
    CompanyComponent,
    CompanyListComponent,
    GeoTechComponent,
    HydrogeologyComponent,
    BoreholeGeoTechComponent,
    CoreComponent,
    CoreRecoveryComponent,
    CoreRecoveryListComponent,
    CoreLithologyComponent,
    DrillingRequestComponent,
    DynamicConePenotrometerComponent,
    DrillerLogComponent,
    BoreholeLogsComponent,
    CoreLithologyListComponent,
    CoreLithologyDetailsComponent,
    CompanyCreateComponent,
    ProjectCreateComponent,
    ProjectListComponent,
    DrillingRequestListComponent,
    DrillingRequestCreateComponent,
    RoleListComponent,
    CreateRolesComponent,
    UserProfileComponent,
    DcpFoundationComponent,
    DcpTestFoundationCreateComponent,
    DcpTestFoundationListComponent,
    SoilLogComponent,
    MustMatchDirective,
    MatchValueDirective,
    NgInit,
    HighlightDirective,
    SoilLogListComponent,
    UserProfileComponent,
    DcpFoundationComponent,
    DcpTestFoundationCreateComponent,
    DcpTestFoundationListComponent,
    DcpSubgradeLogsComponent,
    TwoDigitDecimaNumberDirective,
    IntegerNumberDirective,
    DiscontinuityLogComponent,
    DiscontinuityLogListComponent,
    DiscontinuityLogCreateComponent,
    TimeandmotionComponent,
    DcpSubgradeComponent,
    DcpRoadworksCreateComponent,
    DcpRoadworksListComponent,
    BoreholeEditorComponent,
    BoreholeDetailComponent,
    WaterBoreholeComponent,
    WaterboreholeLogsComponent,
    WaterDrillersLogComponent,
    BoreholeCreateComponent,
    ProspectingLicenseSelectComponent,
    CompanyDetailComponent,
    ProDetailComp,
    ProjectDetailComponent,
    WaterDrillersLogComponent,
    DrillerChecklistComponent,
    DrillerChecklistCreateComponent,
    DrillerChecklistListComponent,
    WaterDrillersLogComponent,
    WaterBoreholeLithologyLogListComponent,
    WaterBoreholeLithologyLogComponent,
    WaterBoreholeLithologyLogComponent,
    DrillingProtocolComponent,
    DrillingProtocolListComponent,
    DrillingProtocolCreateComponent,
    WaterPumpTestComponent,
    WaterPumpTestStepTestComponent,
    WaterPumpTestStepTestListComponent,
    HydrologeologyBoreholeConstructionComponent,
    BoreholeConstructionCasingComponent,
    BoreholeConstructionScreeningComponent,
    WaterPumpTestCalibrationComponent,
    WaterPumpTestCalibrationListComponent,
    WaterBoreholeViewComponent,
    WaterPumpTestLogsComponent,
    WaterPumpTestCalibrationCreateComponent,
    WaterPumpTestStepTestCreateComponent,
    WaterPumpTestCalibrationViewComponent,
    WaterPumpTestStepTestViewComponent,
    WaterPumpTestStepTestListComponent,
    TrialPitListComponent,
    TrialPitCreateComponent,
    TrialPitComponent,
    WaterPumpTestStepTestViewComponent,
    WaterPumpTestConstantRateTestComponent,
    WaterPumpTestConstantRateTestViewComponent,
    WaterPumpTestConstantRateTestListComponent,
    WaterPumpTestConstantRateTestCreateComponent,
    BoreholeConstructionDrillingProtocolComponent,
    WaterBearingZonesComponent,
    ProjectComponent,
    UploadedFilesComponent,
    WaterPumpTestRecoveryComponent,
    WaterPumpTestRecoveryCreateComponent,
    WaterPumpTestRecoveryListComponent,
    WaterPumpTestRecoveryViewComponent,
    PlotToMapComponent,
    ProspectingLicenceMineralChemistryComponent,
    PlBoreholeLogsComponent,
    EfficiencyFormsComponent,
    TimeandmotionProjectComponent,
    DiameterFormationComponent,
    PenetrationRateComponent,
    CoreLithologyListComponent,
    CoreRecoveryComponent,
    DcpTestFoundationDetailComponent,
    PLComponent,
    PLListComponent,
    AuthorityComponent,
    AuthorityGroupComponent,
    DcpRoadworksLogsComponent,
    DcpRoadworksLogsCreateComponent,
    DcpRoadworksLogsDeleteComponent,
    SamplesComponent,
    SampleCreateComponent,
    SamplesDetailsComponent,
    CoreLithologyCreateComponent,
    // Security Components
    SettingsComponent,
    PersonnelSortPipe,
    CompanyAlphabeticalOrderPipe,
    UploadFileComponent,
    InfiltrationTestCreateComponent,
    InfiltrationTestListComponent,
    InfiltrationTestDetailComponent,
    InfiltrationTestLogComponent,
    InfiltrationTestLogFormComponent,
    DcpRoadworksDetailComponent,
    PLDetailsComponent,
    InfiltrationTestLogFormComponent,
    WaterAnalyticalReportsComponent,
    DepthAnalyticalReportsComponent,
    WaterBoreholeLithologyFormComponent,
    TrialpitTestsComponent,
    SoilChemistryImportComponent,
    TestPointListComponent,
    TestPointContainerComponent,
    CreateTestPointComponent,
    DrillingRequestDetailComponent,
    BulletinListComponent,
    EditUserProfileComponent,
    BulletinListComponent,
    UserDashboardComponent,

    BulletinListComponent,
    ChangeStateComponent,
    ProspectingLicenceMembersFormComponent,
    ChangeStateComponent,
    DrillingRequestCreateComponent,
    ProjectDetailMembersComponent,
    SixDigitDecimaNumberDirective,
    ProjectDetailMemberDeleteComponent,
    UploadedFilesComponent,
    AddContactComponent,
    UploadFileComponent,
    WaterDrillersLogCreateComponent,
    WaterBearingZonesCreateComponent,
    WaterBearingZonesDetailsComponent,
    PenetrationRateCreateComponent,
    InfiltrationTestLogFormComponent,
    DrillingRequestBoreholesComponent,
    AddDRBoreholeComponent,
    BoreholeConstructionScreeningCreateComponent,
    ImageComponent,
    SampleTestsComponent,
    SampleGeoChemistryComponent,
    PetrographyTestComponent,
    PetrographyTestCreateComponent,
    SoilLogCreateComponent,
    DrillingRequestBoreholeDetailsComponent,
    SuperintendentDashboardComponent,
    DrillingChecklistDetailComponent,
    DrillingLogDetailsComponent,
    TimeandmotionCreateComponent,
    DrillersLogImportComponent,
    PlBoreholeDrillersLogCreateComponent,
    TimeandmotionDetailsComponent,
    DrillersLogImportComponent,
    PlDrillersLogImportComponent,

    ProjectMembersComponent,
    AddMemberComponent,
    MemberListComponent,
    UpdateMemberRoleComponent,
    SoilLogDetailsComponent,
    SoilLogUploadComponent,
    SoilLogTableComponent,
    SurveysDashboardComponent,
    ProspectingLicenseCreateComponent,
    CollectionsDashboardComponent,
    MineralResourceDashboardComponent,
    MineralsDashboardComponent,
    BoreholeTestsComponent,
    CloseRequestFormComponent,
    SetMyPasswordComponent,
    UserDashboardUIComponent,
    AdminDashboardComponent,
    SuperintendentDashboardUiComponent,
    UnapprovedActivitiesListComponent,
    QaDashboardUiComponent,
    QaDashboardTableComponent,
    ProjectDashboardComponent,
    ProjectDashboardUiComponent,
    PlDashboardComponent,
    PlDashboardUiComponent,
    ManagerProjectDashboardComponent,
    ManagerProjectDashboardUiComponent,
    ManagerProjectDashboardTableComponent,
    ManagerActivitiesDashboardComponent,
    ManagerActivitiesDashboardUiComponent,
    ManagerActivitiesDashboardTableComponent,
    ManagerDrillerDashboardComponent,
    ManagerDrillerDashboardUiComponent,
    ManagerDrillerDashboardTableComponent,
    SpinnerComponent,
    BoreholeListComponent,
    CollectionsUserComponent,
    MineralsTechDashboardComponent,
    MineralsTechDashboardUiComponent,
    MineralsTechDashboardTableComponent,
    MineralsGeologistSampleDashboardComponent,
    MineralsGeologistSampleVettingDashboardComponent,
    MineralsGeologistFilesDashboardComponent,
    MineralsGeologistSampleDashboardUiComponent,
    MineralsGeologistSampleVettingDashboardUiComponent,
    MineralsGeologistFilesDashboardUiComponent,
    MineralsGeologistFilesDashboardTableComponent,
    MineralsGeologistFilesVettingDashboardComponent,
    MineralsGeologistFilesVettingDashboardUiComponent,
    CollectionsDashbordTableComponent,
    MineralsManagerSampleVettingDashboardComponent,
    MineralsManagerSampleVettingDashboardUiComponent,
    MineralsManagerFilesVettingDashboardComponent,
    MineralsManagerFilesVettingDashboardUiComponent,
    CollectionSeniorArchivistComponent,
    CollectionsManagerComponent,
    CollectionsTechnicianComponent,
    CollectionsTrayHistoryComponent,
    CollectionsCuratorDashboardUiComponent,
    MineralResourceProjectDashboardComponent,
    ProjectActivitiesMineralsComponent,
    MineralsQaVettingDashboardComponent,
    MineralsQaVettingDashboardUiComponent,
    MineralResourceBoreholeDashboardComponent,
    MineralsTechBoreholeDashboardComponent,
    MineralResourceBoreholeTableComponent,
    UpdateRoleComponent,
    CollectionGeologicalSpecimenVettingComponent,
    ManagerGeologicalSpecimenUiComponent,
    RelinquishedDocumentsComponent,
    ConstantRateTestUploadComponent,
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAwlYDTDNsqeM4jsl75A-9IjcIbV6T9ljs",
    }),
    AgmJsMarkerClustererModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    LayoutModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatTabsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ closeButton: true }),
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    ObserversModule,
    MatSortModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatExpansionModule,
    MatTabsModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatStepperModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    LightboxModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenProvider],
      },
    }),
    NgxMatSelectSearchModule,
    NgxSpinnerModule,
    CoreModule.forRoot(),
    SharedModule,
    SamplesModule,
    FlexLayoutModule,
    ChartsModule,
  ],
  providers: [
    JwtHelperService,
    Session,
    forwardRef(() => AuthGuard),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    PersonnelSortPipe,
    CompanyAlphabeticalOrderPipe,
  ],
  exports: [
    DrillingRequestListComponent,
    DrillerChecklistListComponent,
    TimeandmotionProjectComponent,
    ChangeStateComponent,
    PersonnelSortPipe,
    CompanyAlphabeticalOrderPipe,
    MustMatchDirective,
    SampleGeoChemistryComponent,
    BoreholeTestsComponent,
    NgInit,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    WaterBoreholeLithologyFormComponent,
    CreateTestPointComponent,
    EditUserProfileComponent,
    ChangeStateComponent,
    ProjectCreateComponent,
    SoilChemistryImportComponent,
    SampleCreateComponent,
    ProspectingLicenceMembersFormComponent,
    DrillingRequestCreateComponent,
    ProjectCreateComponent,
    SoilChemistryImportComponent,
    TimeandmotionCreateComponent,
    ProjectDetailMembersComponent,
    ProjectDetailMemberDeleteComponent,
    CompanyCreateComponent,
    DrillersLogImportComponent,
    PlDrillersLogImportComponent,
    AddContactComponent,
    TrialPitCreateComponent,
    UploadedFilesComponent,
    UploadFileComponent,
    DcpTestFoundationCreateComponent,
    DcpRoadworksLogsCreateComponent,
    InfiltrationTestCreateComponent,
    DrillerChecklistCreateComponent,
    WaterDrillersLogCreateComponent,
    PenetrationRateCreateComponent,
    DrillingProtocolCreateComponent,
    InfiltrationTestLogFormComponent,
    AddDRBoreholeComponent,
    SoilLogCreateComponent,
    WaterBearingZonesCreateComponent,
    BoreholeConstructionScreeningCreateComponent,
    ImageComponent,
    PetrographyTestCreateComponent,
    CoreLithologyCreateComponent,
    PlBoreholeDrillersLogCreateComponent,
    ProjectMembersComponent,
    BoreholeCreateComponent,
    UpdateMemberRoleComponent,
    CoreLithologyCreateComponent,
    PlBoreholeDrillersLogCreateComponent,
    SoilLogUploadComponent,
    ProspectingLicenseCreateComponent,
    WaterPumpTestCalibrationCreateComponent,
    WaterPumpTestStepTestCreateComponent,
    CloseRequestFormComponent,
    WaterPumpTestConstantRateTestCreateComponent,
    WaterPumpTestRecoveryCreateComponent,
    SetMyPasswordComponent,
    UpdateRoleComponent,
    ConstantRateTestUploadComponent,
    ProspectingLicenseSelectComponent
  ],
})
export class AppModule {}
