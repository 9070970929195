import { ApprovalService } from './../../../applied-geoscience/geo-tech/borehole/services/approval.service';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { ApproveAction } from './../../../applied-geoscience/models/approval-state';
import { DocumentsService } from './../../../shared/documents/dococument.service';
import { PeopleService } from './../../../_core/mock/people.service';
import { SampleLabSubmission } from './../../entity/SampleLabSubmission';
import { SampleSubmissionCreateComponent } from './../sample-submission-create/sample-submission-create.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { SamplesCreateComponent } from './../samples-create/samples-create.component';
import { FileToUpload } from './../../../common/uploaded-files/entity/file';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoTechService } from './../../../applied-geoscience/geo-tech/geo-tech.service';
import { Sample } from './../../../shared-kernel/entity/applied-geoscience/geoTech/Sample';
import { Component, OnInit } from '@angular/core';
import {Lightbox} from 'ngx-lightbox';

@Component({
  selector: 'app-samples-detail',
  templateUrl: './samples-detail.component.html',
  styleUrls: ['./samples-detail.component.scss']
})
export class SamplesDetailComponent implements OnInit {

  sampleId: string;
  sample: Sample;
  album: any = [];
  expanded: boolean = true;
  expandedSampleSubmission: boolean = true;
  loading:boolean = true;
  selectedSection: string;
  totalNumberOfFiles: number;
  uploadedFileList: FileToUpload[];
  sampleCount:number = 0;
  collectorName: string = '--';
  currentComment: string;

  sampleLabSubmission: SampleLabSubmission;
  requestedByName: any;

  sampleLocationImages: any[];

  constructor(
    private geoTechService: GeoTechService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private lightbox: Lightbox,
    private peopleService: PeopleService,
    private _docServ: DocumentsService,
    private toast: ToastrService,
    private _approvalServ: ApprovalService,
  ) {
    this.route.params.subscribe(params => {
      this.sampleId = params.id;
    });
  }

  ngOnInit() {
    this.getSampleBySampleId();
    this.countDocuments();
    this.getSampleLabSubmissionBySampleId();
  }

  getSampleBySampleId() {
    this.geoTechService.getSampleById(this.sampleId).subscribe(data => {
      this.getSampleLocationImagery();
      this.sample = data as Sample;
      this.peopleService.getPersonById(this.sample.collectedBy).subscribe(
        person => {
          if(person){
            this.collectorName = person.name +' '+ person.surname;
          }
        }
      );
    }, error => {
    }, () => {
    });
  }

  getSampleLabSubmissionBySampleId() {
    this.geoTechService.getSampleLabSubmissionBySampleId(this.sampleId).subscribe(data => {
      this.sampleLabSubmission = data as SampleLabSubmission;
      this.peopleService.getPersonById(this.sampleLabSubmission.requestedBy).subscribe(
        person => {
          this.requestedByName = person;
        }
      );
    }, error => {
    }, () => {
    });
  }

  private countDocuments() {
    this._docServ.getDocumentCount(this.sampleId).subscribe(res => {
        this.totalNumberOfFiles = res;
    });
  }

  redirect(){
    this.router.navigate(['/portal/mineral-resources/samples/list']);
  }

  redicrectToDocuments() {
    this.selectedSection = 'sampleDocuments';
  }

  onEdit(sample) {
    const obj = {sample: sample, sampleCount: this.sampleCount};
    const editSampleDialogRef = this.dialog.open(SamplesCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    editSampleDialogRef.afterClosed().subscribe((result) => {
      this.getSampleBySampleId();
    });
  }

  submitToLab() {
    const obj = {sample: this.sample, sampleLabSubmission: null};
    const newSampleSubmissionDialogRef = this.dialog.open(SampleSubmissionCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newSampleSubmissionDialogRef.afterClosed().subscribe((result) => {
      this.getSampleLabSubmissionBySampleId();
    });
  }

  onEditSampleSubmission() {
    const obj = {sample: this.sample, sampleLabSubmission: this.sampleLabSubmission};
    const newSampleSubmissionDialogRef = this.dialog.open(SampleSubmissionCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: obj
    });
    newSampleSubmissionDialogRef.afterClosed().subscribe((result) => {
      this.getSampleLabSubmissionBySampleId();
    });
  }

  changeState(action: ApproveAction) {
    let successMessage: string = 'Sample submitted successfully';
    let title: string = 'Sample Submit';

    switch (action) {
      case ApproveAction.SUBMIT_TO_QA:
        successMessage = 'Sample submitted successfully';
        title = 'Sample Submit';
        break;
      case ApproveAction.QA_APPROVE:
        successMessage = 'Sample approved successfully';
        title = 'Sample Approve';
        break;
      case ApproveAction.QA_REJECT:
        successMessage = 'Sample rejected successfully';
        title = 'Sample Reject';
        break;
      case ApproveAction.MANAGER_APPROVE:
        successMessage = 'Sample approved successfully';
        title = 'Sample Approved';
        break;
      case ApproveAction.MANAGER_REJECT:
        successMessage = 'Sample rejected successfully';
        title = 'Sample Rejected';
        break;
      case ApproveAction.UN_PUBLISH:
        successMessage = 'Sample published successfully';
        title = 'Sample Published';
        break;
      default:
        throw new Error('Unknown change state');
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(result => {
      if (result.value) {
        this._approvalServ.changeSampleState(this.sampleId, {
          action: action, comment: this.currentComment
        }).subscribe(res => {
          this.toast.success(successMessage, title);
          this.getSampleBySampleId();
        }, err => {
          this.toast.warning(err.message, 'Failed');
        });
      }
    });

  }
  getSampleLocationImagery() {

    this.geoTechService.getSampleLocationImageryByID(this.sampleId).subscribe(value => {

      this.sampleLocationImages =  value as any [];

      for (let image of this.sampleLocationImages) {

        let albumImage: any = {};

        albumImage.src = 'data:image/jpg;base64,'+ image.image;

        albumImage.caption = image.name;

        albumImage.thumb = 'data:image/jpg;base64,'+ image.image;

        this.album.push(albumImage);

      }

    } )

  }

  open(index: number): void {

    this.lightbox.open(this.album, index);

  }

}
