import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { Activity, ActivityType } from '../models/activity.model';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { DrillingRequestModel } from '../models/drilling-request.model';
import {DrillingRequestApproveAction} from '../../shared-kernel/entity/driller/drilling-request';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(
    private _http: HttpClient
  ) {
  }

  getMyActivities(filter: string): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/mine/${filter}`);
  }

  /**
   * Get activities of certain state - use state to filter QA and Manager boreholes
   * @param state approval state of borehole
   */
  getBusinessUnitActivities(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v1/qa');
  }

  getQaActivities(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v1/qa');
  }

  getNonBGIQaActivities(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v1/non_bgi_qa');
  }


  getManagerActivitiesPendingApproval(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllManagerActivitiesPendingApprovalUrl);
  }

  getNonBGIManagerActivitiesPendingApproval(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIManagerActivitiesPendingApprovalUrl);
  }

  getDrillingRequestsByStage(state?: number): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getDrillingRequestsByStageUrl + '/' + state  );
  }
  getRecentDrillingRequest(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<any>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v1/drilling_requests/recent');
  }

  getDrillingRequestsByStageV2(state?: number): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/' + state  );
  }

  getManagerDrillRequestActivities(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/manager_drilling_requests');
  }

  getManagerSubmittedToSupActivities(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/manager_submitted_superintendent');
  }

  getManagerRejectedActivities(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/manager_returned');
  }

  getManagerApprovedActivities(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/manager_approved');
  }

  getManagerReturnedToSuperActivities(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/manager_superintendent_returned');
  }

  getManagerAllVettedActivities(): Observable<Array<DrillingRequestModel>> {
    return this._http.get<Array<DrillingRequestModel>>(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/activities/v2/drilling_requests/manager_all_vetted');
  }

  getManagerActivitiesAllVetted(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllManagerActivitiesVettedUrl);
  }

  getNonBGIManagerActivitiesAllVetted(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIManagerActivitiesVettedUrl);
  }

  getManagerActivitiesApproved(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllManagerActivitiesApprovedUrl);
  }

  getNonBGIManagerActivitiesApproved(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIManagerActivitiesApprovedUrl);
  }

  getNonBGIManagerActivitiesReturned(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIManagerActivitiesReturnedUrl);
  }

  getManagerActivitiesReturned(state?: number): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllManagerActivitiesReturnedUrl);
  }

  getQaActivitiesPendingApproval(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getQaActivitiesPendingApprovalUrl);
  }

  getNonBGIQaActivitiesPendingApproval(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNonBgIQaActivitiesPendingApprovalUrl);
  }


  getAllQaActivitiesRejected(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllQaActivitiesRejectedUrl);
  }

  getAllNonBGIQaActivitiesRejected(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIQaActivitiesRejectedUrl);
  }

  getMyProspectingLicenceBorehole(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllQaActivitiesRejectedUrl);
  }

  getAllQaActivitiesApproved(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllQaActivitiesApprovedUrl);
  }

  getAllNonBGIQaActivitiesApproved(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIQaActivitiesApprovedUrl);
  }


  getAllQAVettedACtivities(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllQaActivitiesVettedUrl);
  }

  getAllNonBGIQAVettedACtivities(): Observable<Array<Activity>> {
    return this._http.get<Array<Activity>>(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllNonBGIQaActivitiesVettedUrl);
  }

  getManagerProjectActivitesByStage(state?: string): Observable<Array<any>> {
    return this._http.get<Array<any>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/manager/project/activities` + '/' + state  );
  }

  getUserProjectActivitesByStage(state?: string): Observable<Array<any>> {
    return this._http.get<Array<any>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/user/project/activities` + '/' + state);
  }

  getUnapprovedProjectActivityByProjectId(projectId?: string): Observable<Array<any>> {
    return this._http.get<Array<any>>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/activities/v1/project/activities/unapproved` + '/' + projectId);
  }

  getMyGeologicalActivitiesByStatus(state){
    return this._http.get<Array<any>>(environment.collectionsBaseUrl + '/api/activities/v1/activity/geological_specimen/'+state);
  }

  getGeologicalSpecimenApprovalActivitiesByStatus(state){
    return this._http.get<Array<any>>(environment.collectionsBaseUrl + '/api/activities/v1/activity/geological_specimen/approval/'+state);
  }
}
