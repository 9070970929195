import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {UserService} from '../user.service';
import Swal from 'sweetalert2';
import {LoginService} from '../../login/login.service';
import {Router} from '@angular/router';
import {Person} from '../../common/entity/person';

declare var $: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class UserListComponent implements OnInit {

  title = 'User Management';
  displayedColumns: string[] = ['No', 'username', 'firstname', 'lastname', 'idNumber', 'ctrls'];
  dataSource = new MatTableDataSource();
  public persons: string[];
  public person = new Person();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService, private authService: LoginService, private router: Router) {
  }

  ngOnInit() {
    this.loadPersons();
  }

  openRegisterUserForm() {
    $('#registerUser').modal({backdrop: 'static'});
    $('#registerUser').modal('show');
  }

  loadPersons() {
    return this.userService.getPersons().subscribe(data => {
      this.persons = data as string[];
      this.dataSource = new MatTableDataSource(this.persons);
    });
  }

  editEmployee(personData) {
    const idObject = {id: personData};
    this.router.navigate(['admin/user/register', idObject]);
  }

  getPersonById(personId) {
    this.userService.getPersonById(personId);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onResetUserPass(username: string) {
    Swal.fire({
      title: 'Password Reset',
      text: 'Are you sure you want to reset password for : ' + username,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then(result => {
        if (result.value) {
          this.authService.resetPassword(username);
        } else if (result.dismiss) {

        }
      }, function () {
      }
    );
  }


}
