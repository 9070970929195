import {Injectable, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { Borehole } from 'src/app/shared-kernel/entity/common/borehole';
import { BoreholeLithologyLog } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/BoreholeLithologyLog';

@Injectable({
    providedIn: 'root'
})
export class CoreValidation implements OnInit {
    borehole: Borehole;
    boreholeLithologyLog: BoreholeLithologyLog;
    boreholeLithologyLogs: BoreholeLithologyLog[];

    validation_messages = {
        'depthFrom': [
          { type: 'required', message: 'Depth From is required' },
        ],
        'depthTo': [
          { type: 'required', message: 'Depth To is required' },
        ],
        'date': [
          { type: 'required', message: 'Date is required' },
        ],
        'rockName': [
            { type: 'required', message: 'Please select Rock Name' },
        ],
        'weathering': [
            { type: 'required', message: 'Please select Weathering' },
        ],
        'hardness': [
          { type: 'required', message: 'Please select Hardness' },
        ],
        'colour': [
          { type: 'required', message: 'Colour is required' },
        ],
        'fabric': [
          { type: 'required', message: 'Fabric is required' },
        ],
        'discontinuity': [
            { type: 'required', message: 'Discontinuity is required' },
        ],
        'rockType': [
            { type: 'required', message: 'Please select Rock Type' },
        ],
        'grainSize': [
          { type: 'required', message: 'Please select Gain Size' },
        ]
        ,
        'freshness': [
          { type: 'required', message: 'Freshness is required' },
        ],
        'minerology': [
          { type: 'required', message: 'Minerology is required' },
        ]
      };

  constructor(private toastr: ToastrService) {
  }

  ngOnInit() {}

  errorsmsg(message) {
    this.toastr.error(message);
  }

  successmsg(message) {
    this.toastr.success(message);
  }

  validateCoreLithologyFormForm(borehole: Borehole): boolean {
    if (!borehole) {
        this.errorsmsg('Please make borehole details are provided');
        return false;
    } else if (!borehole.id) {
        this.errorsmsg('Please make borehole details are provided');
        return false;
    } else if ((borehole.boreholeLithologyLogs.length === 0)) {
        this.errorsmsg('Lithology Log Details NOT provided');
        return false;
    } else {
        return true;
    }
  }

  validateLithologyRecordAsItsAdded(boreholeLithologyLog: BoreholeLithologyLog): boolean {
      this.boreholeLithologyLog = boreholeLithologyLog;

    if (this.boreholeLithologyLog) {
        if (this.validateWhenDepthFromIsZero(this.boreholeLithologyLog)) {
        if (!this.boreholeLithologyLog.depthTo.measure) {
            this.errorsmsg('Borehole depth To NOT provided');
            return false;
        } else if (this.validateInteger(this.boreholeLithologyLog.depthFrom.measure)) {
            this.errorsmsg('"Depth From" must be a number');
            return false;
        } else if (this.validateInteger(this.boreholeLithologyLog.depthTo.measure)) {
            this.errorsmsg('"Depth To" must be a number');
            return false;
        } else if (Number(this.boreholeLithologyLog.depthFrom.measure) >= Number(this.boreholeLithologyLog.depthTo.measure)) {
            this.errorsmsg('"Depth From" should be less that "Depth To"');
            return false;
        } else {
            return true;
        }

        /*  else if (!this.boreholeLithologyLog.date) {
            this.errorsmsg('Date not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription) {
            this.errorsmsg('Rock description details not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription.rockName) {
            this.errorsmsg('Rock name not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription.weathering.weathering) {
            this.errorsmsg('Rock weathering not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription.fabric) {
            this.errorsmsg('Fabric not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription.colour) {
            this.errorsmsg('Colour not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription.hardness) {
            this.errorsmsg('Hardness not provided');
            return false;
        } else if (!this.boreholeLithologyLog.rockDescription.discontinuity) {
            this.errorsmsg('Discontinuity not provided');
            return false;
        }  */

    }
    } else {
        this.errorsmsg('Lithology Log Details NOT provided');
        return false;
    }
  }

  validateWhenDepthFromIsZero (boreholeLithologyLog: BoreholeLithologyLog) {
    const leastPossibleDepthFromValue = 0;
    this.boreholeLithologyLog = boreholeLithologyLog;
    if ((Number(this.boreholeLithologyLog.depthFrom.measure)) < leastPossibleDepthFromValue) {
        this.errorsmsg('Borehole depth From NOT provided');
        return false;
    } else {
        return true;
    }

  }

  validateInteger(digit): boolean {
      if (isNaN(Number(digit))) {
          return true;
      } else {
        return false;
      }
  }
}
