import {Component, OnInit} from '@angular/core';
import {DcpSubgradeTest} from '../../../dcpSubgradeTest';
import {ActivatedRoute} from '@angular/router';
import {DCPTestService} from '../../../../dynamic-cone-penetrometer.service';
import {ToastrService} from 'ngx-toastr';
import {DcpRoadworksLog} from '../../../../../../../shared-kernel/entity/applied-geoscience/geoTech/DcpRoadworksLog';

declare var $: any;

@Component({
  selector: 'app-dcp-roadworks-logs-delete',
  templateUrl: './dcp-roadworks-logs-delete.component.html',
  styleUrls: ['./dcp-roadworks-logs-delete.component.scss']
})
export class DcpRoadworksLogsDeleteComponent implements OnInit {
  dcpRoadworksLog: DcpRoadworksLog = new DcpRoadworksLog();
  dcpRoadworksTest: DcpSubgradeTest = new DcpSubgradeTest();
  dcpRoadworksTestId: string;


  constructor(private route: ActivatedRoute, private dcpTestService: DCPTestService, private toast: ToastrService) {
  }

  ngOnInit() {
    this.dcpRoadworksLog = new DcpRoadworksLog();
    this.dcpRoadworksLog.comments.description = '';
    this.route.params.subscribe(params => {
      this.dcpRoadworksTestId = params.id;
    });
    this.getDcpRoadworksTestById();
  }

  getDcpRoadworksTestById() {
    this.dcpTestService.getAllDCPTestSubgradeByDynamicConePenetrometerTestId(this.dcpRoadworksTestId).subscribe(data => {
        this.dcpRoadworksTest = data as DcpSubgradeTest;
      }
    )
  }

  deleteRecord() {
    this.dcpRoadworksLog.dynamicConePenetrometerTestRoadworks = this.dcpRoadworksTest;
    this.dcpRoadworksLog.deleted =true;
    this.dcpTestService.createDynamicConePenetroMeterRoadworkLogRecord(this.dcpRoadworksLog).subscribe(
      data => {
      }
      , () => {
      }
      , () => {
        $('#deleteModal').modal('hide');
        this.toast.success('You have Successfully removed the Roadworks Logs record', 'RoadworksLog Delete');
        $('#refreshTable').click();
      }
    )
  }

  deleteModal(dcpRoadworksLog: DcpRoadworksLog) {
    this.dcpRoadworksLog = dcpRoadworksLog;
  }

}
