import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-water-borehole',
  template: `<router-outlet></router-outlet>`
})
export class WaterBoreholeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
