import { SampleLabSubmission } from './../../mineral-resources/entity/SampleLabSubmission';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceEndPoints } from '../../shared-kernel/apiCalls/ServiceEndPoints';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { RockName } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/RockName';
import {Observable} from 'rxjs';
import { SoilName } from '../../shared-kernel/entity/applied-geoscience/geoTech/SoilName';
import { LocationTemp } from 'src/app/shared-kernel/entity/common/locationTemp';
import { BoreholeSearchCriteria } from 'src/app/shared-kernel/entity/common/BoreholeSearchCriteria';

@Injectable({
  providedIn: 'root'
})
export class GeoTechService implements OnInit {

  constructor(private http: HttpClient, private router: Router, private toast: ToastrService
    , private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  getAllRigTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllRigTypesUrl);
  }

  getAllCoordinatesTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCoordinatesTypesUrl);
  }

  getAllBoreholeTypes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholeTypesUrl);
  }

  getAllBoreholes() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholesUrl);
  }

  getAllBoreholesLite() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllBoreholesLiteUrl);
  }

  getAllSamplesLite() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllSamplesLiteUrl);
  }

  getBoreholeById(borehole: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeByIdUrl + '/' + borehole);
  }

  getCoreRecoveryById(coreRecoveryId: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getCoreRecoveryByIdUrl + '/' + coreRecoveryId);
  }

  getBoreholesByBoreholeTypeId(borehole: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholesByBoreholeTypeIdUrl + '/' + borehole);
  }

  getBoreholeNumberInDistrict(district) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeCountInDistrictUrl + '/' + district);
  }

  createBorehole(borehole) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createBoreholeUrl, borehole);
  }

  updateBorehole(borehole) {
    return this.http.patch(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.updateBoreholeUrl, borehole);
  }


  getBGIBoreholesByBoreholeTypeId(boreholeTypeId, companyId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBGIBoreholesByBoreholeTypeIdUrl
      + '/' + boreholeTypeId + '/' + companyId);
  }

  getProspectingLicenseBoreholesByBoreholeTypeId(boreholeTypeId, activeFlag) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getProspectingLicenseBoreholesByBoreholeTypeIdUrl
      + '/' + boreholeTypeId + '/' + activeFlag);
  }

  checkBoreholeOwnership(boreholeId, username) {//
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.checkBoreholeOwnershipUrl
      + '/' + boreholeId + '/' + username);
  }

  getMyActivities(username) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getMyActivitiesUrl
      + '/' + username);
  }

  getBoreholesByBoreholeTypeAndOwner(boreholeTypeId, username) {//
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getBoreholeByOwnershipUrl
      + '/' + boreholeTypeId + '/' + username);
  }

  getMyNumberOfBoreholesByBoreholeTypeAndOwner(boreholeTypeId, username) {//
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getOwnedBoreholeNumberUrl
      + '/' + boreholeTypeId + '/' + username);
  }

  getNumberOfBoreholesByBoreholeType(boreholeTypeId) {//
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getNumberOfBoreholesByBoreholeTypeUrl
      + '/' + boreholeTypeId);
  }

  createInfiltrationTest(infiltration) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createInfiltrationTestUrl, infiltration);
  }

  deleteInfiltrationTest(infiltration) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteInfiltrationTestUrl, infiltration);
  }

  getInfiltrationTestById(infiltrationId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getInfiltrationTestByIdUrl + '/' + infiltrationId);
  }

  getAllActiveInfiltrationTests() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllActiveInfiltrationTestsUrl);
  }

  getAllActiveInfiltrationTestLogsByTestId(infiltrationTestId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllActiveInfiltrationTestLogsByTestId + '/' + infiltrationTestId);
  }

  createInfiltrationTestLog(infiltrationTestLogs) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createInfiltrationTestLogsUrl, infiltrationTestLogs);
  }

  createInfiltrationTestIndividualLog(infiltrationTestLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createInfiltrationTestLogUrl, infiltrationTestLog);
  }

  deleteInfiltrationTestLog(infiltrationLog) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteInfiltrationTestLogUrl, infiltrationLog);
  }

  getSoilTestSoilChemistry(trialPitId) {
    let tempApiUrl: string = ServiceEndPoints.getAllSoilTestSoilChemistry;
    let apiUrl = tempApiUrl.replace('{id}', trialPitId);
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + apiUrl);
  }

  createSoilTestSoilChemistry(soilTest) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createSoilTestSoilChemistry, soilTest);
  }

  getAllActiveSamples() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllActiveSamplesUrl);
  }

  getSamplesByUnit(businessUnit) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/sample/unit/active/all?unitName=${businessUnit}`);
  }

  getSamplesByBoreholeId(boreholeId) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/sample/borehole/${boreholeId}`);
  }

  getSamplesSubmittedToLab() {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/sample/lab/submitted`);
  }

  getSamplesNotSubmittedToLab() {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/sample/lab/not-submitted`);
  }

  getSamplesByUnitAndRole(businessUnit, role) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/sample/unit/role/active/all?unitName=${businessUnit}&role=${role}`);
  }

  getSampleById(sampleId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getSamplesByIdUrl + '/' + sampleId);
  }

  createSamples(sample) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createSampleUrl, sample);
  }
  deleteSample(sample) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deleteSampleUrl, sample);
  }

  createSampleLabSubmission(sampleLabSubmission: SampleLabSubmission) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/sample/lab-submission/create', sampleLabSubmission);
  }

  getSampleLabSubmissionBySampleId(sampleId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/sample/lab-submission/' + sampleId);
  }

  infiltrationInProject(testPointId: string, projectId: string) {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/infiltration-test/check/${testPointId}/project/${projectId}`);
  }

  createPetrography(petrography) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.createPetrographyUrl, petrography);
  }

  deletePetrography(petrography) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.deletePetrographyUrl, petrography);
  }

  getPetrographyBySampleId(sampleId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getPetrographyUrl + '/' + sampleId);
  }

  sampleNumberAlreadyInProject(sampleNumber, projectId) {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/sample/check/${sampleNumber}/project/${projectId}`);
  }

  boreholeNumberAlreadyInProject(boreholeNumber, projectId) {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/borehole/check/${boreholeNumber}/project/${projectId}`);
  }

  getBoreholesByBoreholeTypeIdOptimized(borehole: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/ags-common/v1/boreholes/optimized' + '/' + borehole);
  }

  getBoreholesByBoreholeTypeAndOwnerOptimized(boreholeTypeId, username) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/ags-common/v1/borehole/optimized/creator' + '/' + boreholeTypeId + '/' + username);
  }

  getBGIBoreholesByBoreholeTypeIdOptimized(boreholeTypeId, companyId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/ags-common/v1/bgi-boreholes/optimized' + '/' + boreholeTypeId + '/' + companyId);
  }

  createRockName(rockName) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-name/create', rockName);
  }

  createSoilName(soilName) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/soil-name/create', soilName);
  }

  getAllRockGroups() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-groups/all');
  }

  getAllSoils() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/soil/all');
  }

  getBasicTypeRockNames(rockGroupId) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-names/rock-group' + '/' + rockGroupId);
  }

  getRockNamesByGroupAndBySubGroup(rockGroupId, subgroup) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-names/rock-group' + '/' + rockGroupId + '/' + subgroup);
  }

  getRockNamesByParentName(parentRock) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-names/parent-rock' + '/' + parentRock);
  }


  getRockNamesBySubdivision(subdivision) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-names/subdivision' + '/' + subdivision);
  }

  getRockNamesByParentNameAndSubgroup(parentRock, subgroup) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/rock-names/parent-rock/${parentRock}/sub-group/${subgroup}`);
  }

  rockNameAlreadyExists(rock, subgroup) {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/rock-name/check/${rock}/${subgroup}`);
  }

  soilNameAlreadyExists(soil) {
    return this.http.get<{ exist: boolean, message: string }>(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/soil-name/check/${soil}`);
  }

  createBatchRocks(rockNames: RockName[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/rock-name/batch/create', rockNames);
  }

  createBatchSoils(soilNames: SoilName[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/soil-name/batch/create', soilNames);
  }

  createCharacter(character: any) {
    return this.http.post(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/character/create`, character);
  }
  createBatchCharacter(characterList: any[]) {
    return this.http.post(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/character/batch/create`, characterList);
  }
  getAllCharacters(): Observable<any[]> {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/character/all`);
  }

  getCharacterById(id: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/character/${id}`);
  }

  deleteCharacter(id: string): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/character/${id}/delete`);
  }

  createMixedType(mixedType: any) {
    return this.http.post(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/mixed-type/create`, mixedType);
  }

  createBatchMixedType(mixedTypes: any[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + '/api/geotech/v1/mixed-type/batch/create', mixedTypes);
  }

  getAllMixedTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/mixed-type/all`);
  }

  getAllBasicTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/basic-type/all`);
  }

  getMixedTypeById(id: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/mixed-type/${id}`);
  }

  getMixedTypeByBasicId(id: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/mixed-type/basic-type/${id}`);
  }


  deleteMixedType(id: string): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/mixed-type/${id}/delete`);
  }

  // -- group symbols --
  createGroupSymbol(group_symbol: any) {
    return this.http.post(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol/create`, group_symbol);
  }

  createBatchGroupSymbol(group_symbols: any[]) {
    return this.http.post(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol/batch/create`, group_symbols);
  }


  getAllGroupSymbols(): Observable<any[]> {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol/all`);
  }

  getGroupSymbolById(id: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol/${id}`);
  }

  deleteGroupSymbol(id: string): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol/${id}/delete`);
  }

  // -- group symbol subdivision --
  createGroupSymbolSubdivision(group_symbol_subdivision: any) {
    return this.http.post(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol-subdivision/create`, group_symbol_subdivision);
  }

  getAllGroupSymbolSubdivision(): Observable<any[]> {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol-subdivision/all`);
  }

  getGroupSymbolSubdivisionById(id: string) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol-subdivision/${id}`);
  }

  deleteGroupSymbolSubdivision(id: string): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/group-symbol-subdivision/${id}/delete`);
  }

  getRockGroupByRockTypeId(id) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getRockGroupByRockTypeIdUrl + '/' + id);
  }

  getBoreholeLocationDetailsById(id) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/location/${id}`);
  }

  getBoreholeCurrentStateById(id) {
    return this.http.get(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/current-state/${id}`);
  }

  getWaterBoreholes() {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/ten`);
  }

  getWaterBoreholesPaged(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/paged?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  searchBoreholesPagedMinimal(criteria: BoreholeSearchCriteria, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.post<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/search/paged/lite?pageNumber=${pageNumber}&pageSize=${pageSize}`, criteria);
  }

  searchBoreholesPaged(criteria: BoreholeSearchCriteria, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.post<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/search/paged?pageNumber=${pageNumber}&pageSize=${pageSize}`, criteria);
  }

  searchBoreholes(criteria: BoreholeSearchCriteria): Observable<any> {
    return this.http.post<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/search`, criteria);
  }

  searchBoreholesMinimal(criteria: BoreholeSearchCriteria): Observable<any> {
    return this.http.post<any>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/search/lite`, criteria);
  }

  geBoreholeNumber() {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/borehole_numbers`);
  }

  getWaterBoreholesByBoreholeNumber(boreholeNumber) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/borehole-number?boreholeNumber=${boreholeNumber}`);
  }

  getWaterBoreholesByProjectName(projectName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/project?projectName=${projectName}`);
  }

  getWaterBoreholesByCompanyName(companyName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/company?companyName=${companyName}`);
  }

  getWaterBoreholesByVillage(villageName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/water/location?villageName=${villageName}`);
  }

  getBGIBoreholesTopTen() {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/bgi/ten`);
  }

  getBGIBoreholesByBoreholeNumber(boreholeNumber) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/bgi/borehole-number?boreholeNumber=${boreholeNumber}`);
  }

  getBGIBoreholesByProjectName(projectName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/bgi/project?projectName=${projectName}`);
  }

  getBGIBoreholesByVillage(villageName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/bgi/location?villageName=${villageName}`);
  }

  getPLBoreholes() {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/pl/ten`);
  }

  getPLBoreholesByBoreholeNumber(boreholeNumber) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/pl/borehole-number?boreholeNumber=${boreholeNumber}`);
  }

  getPLBoreholesByCompanyName(companyName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/pl/company?companyName=${companyName}`);
  }

  getPLBoreholesByVillage(villageName) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/pl/location?villageName=${villageName}`);
  }

  getPLBoreholesByProspectingLicense(prospectingLicense) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/boreholes/v1/pl/prospecting-license?prospectingLicense=${prospectingLicense}`);
  }


  getBGIBoreholesByID(boreholeId: string) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/ags-common/v1/borehole/details/${boreholeId}`);
  }

  getBGIBoreholeLocationImageryByID(boreholeId: string) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/ags-common/v1/borehole/details/${boreholeId}/location-imagery`);
  }
  getSampleLocationImageryByID(sampleId: string) {
    return this.http.get<any[]>(`${ServiceEndPoints.appliedGeoscienceBaseUrl}/api/geotech/v1/sample/details/${sampleId}/location-imagery`);
  }

  getBoreholeCountsById(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/ags-common/v1/borehole/details/${boreholeId}/counts` );
  }

  getSampleCountsById(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/geotech/v1/sample/borehole/${boreholeId}/count` );
  }


  getBoreholeStatusHistoryById(boreholeId: string) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/ags-common/v1/borehole/details/${boreholeId}/status-history` );
  }
}
