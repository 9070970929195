import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../../core/core.service';
import { Borehole } from '../../../../shared-kernel/entity/common/borehole';

declare var $: any;

@Component({
  selector: 'app-borehole-logs',
  templateUrl: './borehole-logs.component.html',
  styleUrls: ['./borehole-logs.component.scss']
})
export class BoreholeLogsComponent implements OnInit {
  @Input('boreholeCreator') boreholeCreator;
  @Input('currentState') currentState: string = '';
  @Input('boreholeId') boreholeId: string = '';
  @Input('projectId') projectId: string = '';
  @Input('companyId') companyId: string = '';
  @Input('boreholeOwner') boreholeOwner: string = '';
  boreholeNumber: string;
  borehole: Borehole;
  selectedTab: number;

  constructor(private router: ActivatedRoute, private coreService: CoreService) {

    router.params.subscribe(
      data => {
        if (data.tab) {
          this.selectedTab = data.tab;
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  getBoheholeNumber(id) {
    this.coreService.getBoreholeById(id).subscribe(
      data => {
        this.borehole = data as Borehole;
        this.boreholeNumber = this.borehole.boreholeNumber;
      });
  }

  ngOnInit() {
    this.boreholeNumber = '';
    this.getBoheholeNumber(this.boreholeId);
    $('.menu .item').tab();
  }
}
