import { TechnicianSampleDetailComponent } from './technician/technician-sample-detail/technician-sample-detail.component';
import { TechnicianSampleListComponent } from './technician/technician-sample-list/technician-sample-list.component';
import { SamplesDetailComponent } from './samples-detail/samples-detail.component';
import { SamplesListComponent } from './samples-list/samples-list.component';
import { SamplesComponent } from './samples.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const route: Routes = [
  {
    path: '', component: SamplesComponent,
    children: [
      {
        path: 'list',
        component: SamplesListComponent
      },
      {
        path: 'details',
        component: SamplesDetailComponent
      },
      {
        path: 'technician/list',
        component: TechnicianSampleListComponent
      },
      {
        path: 'technician/details',
        component: TechnicianSampleDetailComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(route)],
  exports: [RouterModule]
})
export class samplesRouting {
}
