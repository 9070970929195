import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { Label } from 'ng2-charts';
import { Activity } from '../../../activities/models';
import { ToastrService } from 'ngx-toastr';
import { Borehole } from '../../../shared-kernel/entity/common/borehole';
import { GeoTechService } from '../../../applied-geoscience/geo-tech/geo-tech.service';
import { CollectionsState } from '../../../activities/models/CollectionsState';

@Component({
  selector: 'app-collections-user',
  templateUrl: './collections-user.component.html',
  styleUrls: ['./collections-user.component.scss']
})
export class CollectionsUserComponent implements OnInit {

  cards :any;
  bgiProjectCounts = 0;
  stillFetchingBGIActiviesCount = false;
  stillFetchingMyActiviesCount = true;
  numberOfCoreshedActivities = 0;
  draftActivities: Array<any>;
  retunedActivities: Array<any>;
  approvedActivities: Array<any>;
  myActivitiesCount = 0;
  draftsDataSource : MatTableDataSource<Activity>  = new MatTableDataSource<Activity>();
  approvedDataSource : MatTableDataSource<Activity>  = new MatTableDataSource<Activity>();
  returnedDataSource : MatTableDataSource<Activity>  = new MatTableDataSource<Activity>();
  breakpoint: number;
  public myactivitiesData:number[]= [91, 107, 73, 107];
  public myactivitiesLabels:Label[]= [ 'Drafts', 'Submitted-Senior Archivist', 'Returned-Senior Archivist','Approved'];
  public pieChartType = 'pie';
  colors: any  = [{backgroundColor: [ '#00A2E5','#C8A207',
      '#CC0000', '#009900']}];
  public pieChartLegend : boolean= true;


  constructor(private _router: Router,private userDashBoardService: UserDashboardService,private _toast :ToastrService,
              private breakpointObserver: BreakpointObserver,private geoTechService: GeoTechService) {

    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        if (matches) {

          return [
            { title: 'My Activities', cols: 1, rows: 1 },
            { title: 'BGI Activities per District', cols: 1, rows: 1 },
            { title: 'My Projects', cols: 1, rows: 1 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        }

        return [
          { title: '', cols: 2, rows: 1 , id: 'cards'},
          { title: 'My Activities(Coreshed)', cols: 1, rows: 3 , id: 'myactivities'},
          { title: 'Drafts', cols: 1, rows: 3 ,id: 'districts'},
          { title: 'Returned',cols: 1, rows: 3 ,id: 'myprojects'},
          { title: 'Approved', cols: 1, rows: 3 ,id: 'recents'}
        ];
      })
    );
  }


  getMyTrayActivities( state: string) {
      this.userDashBoardService.getUserTrayActivities(state, 'tray').subscribe(res => {
        this.draftActivities = res;
          if (this.draftActivities.length > 0) {
            // tslint:disable-next-line: no-unused-expression
            new Promise((resolve) => {
              this.draftActivities.forEach((activity, index, array) => {
                // tslint:disable-next-line: no-shadowed-variable
                this.geoTechService.getBoreholeById(activity.boreholeNumber).subscribe(res => {
                  const borehole = res as Borehole;
                  activity.boreholeNumber = borehole.boreholeNumber;
                  if (index === array.length - 1) {
                    resolve(activity);
                    this. draftsDataSource = new MatTableDataSource<any>(this.draftActivities);
                  }
                }, err => {
                  this._toast.error(err.message, 'Failed to retrieve details');
                });
              });
            });
          } else {
            this. draftsDataSource = new MatTableDataSource<any>(this.draftActivities);
          }
      }, err => {
        this._toast.warning(err.message, 'Failed to get activities');
      }, () => {
      });
  }


  getMyTrayActivitiesReturned( state: string) {
      this.userDashBoardService.getUserTrayActivities(state, 'tray').subscribe(res => {
        this.retunedActivities = res;
        if (this.retunedActivities.length > 0) {
          // tslint:disable-next-line: no-unused-expression
          new Promise((resolve) => {
            this.retunedActivities.forEach((activity, index, array) => {
              // tslint:disable-next-line: no-shadowed-variable
              this.geoTechService.getBoreholeById(activity.boreholeNumber).subscribe(res => {
                const borehole = res as Borehole;
                activity.boreholeNumber = borehole.boreholeNumber;
                if (index === array.length - 1) {
                  resolve(activity);
                  this. returnedDataSource = new MatTableDataSource<any>(this.retunedActivities);
                }
              }, err => {
                this._toast.error(err.message, 'Failed to retrieve details');
              });
            });
          });
        } else {
          this. returnedDataSource = new MatTableDataSource<any>(this.retunedActivities);
        }
      }, err => {
        this._toast.warning(err.message, 'Failed to get activities');
      }, () => {
      });
  }

  getMyTrayActivitiesApproved( state: string) {
        this.userDashBoardService.getUserTrayActivities(state, 'tray').subscribe(res => {
          this.approvedActivities = res;
          if (this.approvedActivities.length > 0) {
            // tslint:disable-next-line: no-unused-expression
            new Promise((resolve) => {
              this.approvedActivities.forEach((activity, index, array) => {
                // tslint:disable-next-line: no-shadowed-variable
                this.geoTechService.getBoreholeById(activity.boreholeNumber).subscribe(res => {
                  const borehole = res as Borehole;
                  activity.boreholeNumber = borehole.boreholeNumber;
                  if (index === array.length - 1) {
                    resolve(activity);
                    this. approvedDataSource = new MatTableDataSource<any>(this.approvedActivities);
                  }
                }, err => {
                  this._toast.error(err.message, 'Failed to retrieve details');
                });
              });
            });
          } else {
            this. approvedDataSource = new MatTableDataSource<any>(this.approvedActivities);
          }
        }, err => {
          this._toast.warning(err.message, 'Failed to get activities');
        }, () => {
        });
  }

  getUserTrayActivitiesCount(){
    this.userDashBoardService.getUserTrayActivitiesCount('all', 'tray').subscribe(number => {
      this.numberOfCoreshedActivities = number;
      this.stillFetchingMyActiviesCount = false;

    });
  }
  numberOfCoreshedActivitiesSubmittedToSeniorArchivist: number = 0;
  numberOfCoreshedActivitiesRejectedBySeniorArchivist: number = 0;
  numberOfCoreshedActivitiesDrafts: number = 0;
  numberOfCoreshedActivitiesApproved: number = 0;
  stillFetchingCoreshedActivitiesSubmittedToSeniorArchivistCount = true;
  state = new CollectionsState();


  ngOnInit() {
     let collectionsState = new CollectionsState();
     this.getMyTrayActivities(collectionsState.PENDING_SENIOR_ARCHIVIST_SUBMISSION.status);
     this.getMyTrayActivitiesApproved(collectionsState.APPROVED.status);
     this.getMyTrayActivitiesReturned(collectionsState.REJECTED_BY_SENIOR_ARCHIVIST.status);
      this.getUserTrayActivitiesDraftsCount();
     this.getBGIProjectsCount();
     this.getUserTrayActivitiesCount();


  }

  getUserTrayActivitiesSubmittedToSeniorArchivistCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.SUBMITTED_TO_SENIOR_ARCHIVIST.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesSubmittedToSeniorArchivist = number;
      this.myactivitiesData = [this.numberOfCoreshedActivitiesDrafts, this.numberOfCoreshedActivitiesSubmittedToSeniorArchivist,
        this.numberOfCoreshedActivitiesRejectedBySeniorArchivist,
        this.numberOfCoreshedActivitiesApproved];

    });
  }

  getUserTrayActivitiesRejectedBySeniorArchivistCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.REJECTED_BY_SENIOR_ARCHIVIST.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesRejectedBySeniorArchivist = number;
      this.getUserTrayActivitiesApprovedCount();
    });
  }

  getUserTrayActivitiesApprovedCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.APPROVED.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesApproved= number;
      this.getUserTrayActivitiesSubmittedToSeniorArchivistCount();
    });
  }

  getUserTrayActivitiesDraftsCount(){
    this.userDashBoardService.getUserTrayActivitiesCount(this.state.PENDING_SENIOR_ARCHIVIST_SUBMISSION.status, 'tray').subscribe(number => {
      this.numberOfCoreshedActivitiesDrafts= number;
      this.getUserTrayActivitiesRejectedBySeniorArchivistCount();
    });
  }


  /*
    TODO  my project count, if archivists are allowed to create project

      getUserProjectActivities(){
      this.userDashBoardService.getMyProjectActivitiesCount('all').subscribe(value => {
        this.myProjectCounts = value;
        this.getBGIProjectsCount();
      });
    }*/

  getUserActivities(){
    this.userDashBoardService.getMyActivitiesCount('all').subscribe(number => {
      this.myActivitiesCount = number;
    });
  }

  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.bgiProjectCounts = number;
      this.stillFetchingBGIActiviesCount =false;
      this.getUserActivities();
    });
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.myactivitiesLabels[0]:
        this._router.navigate(['portal/activities/archivist/tray/PENDING_SENIOR_ARCHIVIST_SUBMISSION']);
        break
      case this.myactivitiesLabels[1]:
        this._router.navigate(['portal/activities/archivist/tray/SUBMITTED_TO_SENIOR_ARCHIVIST']);
        break
      case this.myactivitiesLabels[2]:
        this._router.navigate(['portal/activities/archivist/tray/REJECTED_BY_SENIOR_ARCHIVIST']);
        break
      case this.myactivitiesLabels[3]:
        this._router.navigate(['portal/activities/archivist/tray/APPROVED']);
        break
      default:
    }
  }

}
