import { NgxSpinnerService } from 'ngx-spinner';
import {Component, Inject, OnInit} from '@angular/core';
import {CompanyService} from '../company.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatTableDataSource} from '@angular/material';
import {PostalAddress} from 'src/app/common/entity/security/util/common/postal-address';
import {Countries} from 'src/app/common/entity/security/profile/countries';
import {PhysicalAddress} from 'src/app/common/entity/security/util/common/physical-address';
import {ContactDetail} from 'src/app/common/entity/security/util/common/contact-detail';
import {CompanyType} from 'src/app/shared-kernel/entity/applied-geoscience/company/company-type';
import {CompanyCategory} from 'src/app/shared-kernel/entity/applied-geoscience/company/company-category';
import {ContactDetailType} from 'src/app/common/entity/security/util/common/contact-detail-type';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Country} from '../../../_core/data/_models';
import {AddContactComponent} from '../add-contact/add-contact.component';

// -- Lodash makes working with arrays, numbers, objects, strings easier.
import * as _ from 'lodash';
import {Company} from '../../../shared-kernel/entity/applied-geoscience/company/company';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent implements OnInit {
  isChecked: boolean;

  companyCategories: CompanyCategory[];
  countries: Country[];
  companyTypes: CompanyType[];
  contactDetails: ContactDetail[] = [];
  contactTypes: ContactDetailType[];

  postalAddress: PostalAddress;
  physicalAddress: PhysicalAddress;
  company: Company = null;

  profilePicture = null;

  dialogConfig = new MatDialogConfig();

  contactForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl(null, Validators.required),
    companyCategory: new FormControl(null, Validators.required),
    companyType: new FormControl(null, Validators.required),
    boxNumber: new FormControl(null),
    postalLocation: new FormControl(null),
    postalCountry: new FormControl(null),
    plotNumber: new FormControl(null),
    street: new FormControl(null),
    physicalLocation: new FormControl(null),
    physicalCountry: new FormControl(null),
  });

  displayedColumns: string[] = ['type', 'address', 'id'];
  dataSource = new MatTableDataSource<ContactDetail>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CompanyCreateComponent>,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) {
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }


  /**
   * Close mat-dialog and send back some data to the calling parent.
   * @param actions - json object format
   * @return e.g {event:'reset': data:'data_object' }
   */
  onClose(actions: any) {
    this.dialogRef.close(actions);
  }

  ngOnInit() {
    this.getAllCompanyTypes();
    this.getAllCompanyCategories();
    this.getAllContactDetailTypes();
    this.getAllCountries();

    if (this.data.company) {
      this.getCompanyDetails(this.data.company.id);
    }

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.width = '420px';
  }

  getCompanyDetails(id: string) {
   // this.spinner.show();
      this.companyService.getCompanyById(id).subscribe(result => {
        let company = result as Company
        this.contactDetails = company.contactDetails;
        this.profilePicture = company.profilePicture;
        this.dataSource.data = this.contactDetails;

        this.contactForm.setValue({
          'id': company.id,
          'name': company.name,
          'companyCategory': company.companyCategory,
          'companyType': company.companyType,
          'boxNumber': (company.postalAddress) ? company.postalAddress.boxNumber : '',
          'postalLocation': (company.postalAddress) ? company.postalAddress.cityTown : '',
          'postalCountry': (company.postalAddress) ? company.postalAddress.country : '',
          'plotNumber': (company.physicalAddress) ? company.physicalAddress.plotNumber : '',
          'street': (company.physicalAddress) ? company.physicalAddress.streetName : '',
          'physicalLocation': (company.physicalAddress) ? company.physicalAddress.cityTown : '',
          'physicalCountry': (company.physicalAddress) ? company.physicalAddress.country : ''
        });
    //    this.spinner.hide();
      }, error => {
        this.toast.error('Failed to get company details', 'Company Details');
      });
  }


  onRemoveContact(address: string) {
    this.contactDetails.forEach((contact, index) => {
      if (contact.address === address) {
        this.contactDetails.splice(index, 1);
        this.dataSource.data = this.contactDetails;
      }
    });
  }

  onAddContact(c: ContactDetailType) {
    this.dialogConfig.data = {contactDetailType: c};

    this.dialog.open(AddContactComponent, this.dialogConfig)
      .afterClosed().subscribe(result => {
      if (result) {
        this.contactDetails.push(result as ContactDetail);
        this.dataSource.data = this.contactDetails;
      }
    });
  }

  getAllCompanyTypes() {
    this.companyService.getAllCompanyTypes()
      .subscribe(data => {
        this.companyTypes = data as CompanyType[];
      });
  }

  getAllCompanyCategories() {
    this.companyService.getAllCompanyCategories()
      .subscribe(data => {
        this.companyCategories = data as CompanyCategory[];
      });
  }

  getAllContactDetailTypes() {
    this.companyService.getAllContactDetailTypes()
      .subscribe(data => {
        this.contactTypes = data as ContactDetailType[];
      });
  }

  getAllCountries() {
    this.companyService.getAllCountries()
      .subscribe(data => {
        this.countries = data as Countries[];
      });
  }

  onSubmit() {
    const temp = this.contactForm.value;
    /**
     * Remove unwanted form fields       */
    this.company = _.omit(this.contactForm.value, ['boxNumber', 'postalLocation',
      'postalCountry', 'plotNumber', 'street', 'physicalLocation', 'physicalCountry']);

    this.company['contactDetails'] = this.contactDetails;
    this.company['profilePicture'] = this.profilePicture;

    /**
     * Create an instances of postal and physical address when adding/ updating company profile*/
    this.physicalAddress = {
      id: '', cityTown: temp.physicalLocation, country: temp.physicalCountry, district: null,
      plotNumber: temp.plotNumber, streetName: temp.street
    };

    this.postalAddress = {
      id: '', boxNumber: temp.boxNumber, cityTown: temp.postalLocation,
      country: temp.postalCountry, district: null
    };

    this.company['postalAddress'] = this.postalAddress;

    this.company['physicalAddress'] = this.physicalAddress;

    this.companyService.createCompany(this.company).subscribe(data => {
      if (this.data) {
        if (!this.contactForm.value.id) {
          this.toast.success('Company profile created successfully', 'Create Company');
        } else {
          this.toast.success('Company profile has been updated successfully', 'Update Company');
        }
        this.onClose(null);
      }
    }, error => {
      this.toast.error('An error occurred when trying to create company profile', 'Error Occurred');
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        const fileSplitdata = reader.result.toString().split(',');
        this.profilePicture = fileSplitdata[1];
      };
    }
  }

  checkValue() {
    if (this.isChecked) {
      if (this.contactForm.get('postalLocation').value) {
        this.contactForm.get('physicalLocation').setValue(this.contactForm.get('postalLocation').value);
      }

      if (this.contactForm.get('postalCountry').value) {
        this.contactForm.get('physicalCountry').setValue(this.contactForm.get('postalCountry').value);
      }
    }
  }

}
