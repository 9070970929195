import { Component, OnInit, Input, Inject } from '@angular/core';
import { InfiltrationTestLog } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/InfiltrationTestLog';
import { UnitEnum } from 'src/app/shared-kernel/enumerations/unit-enum';
import { GeoTechService } from '../../../geo-tech.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlTree } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-infiltration-test-log-form',
  templateUrl: './infiltration-test-log-form.component.html',
  styleUrls: ['./infiltration-test-log-form.component.scss']
})
export class InfiltrationTestLogFormComponent implements OnInit {

  unitEnum: typeof UnitEnum = UnitEnum;
  infiltrationTestLogForm: FormGroup;
  title: string = 'Create Infiltration Test Log';
  infiltrationTestLogTemplate : InfiltrationTestLog = new  InfiltrationTestLog();
  infiltrationTestId: string;
  urlInfiltrationLogObject: any;
  url: string;
  headDiff: number;
  infiltrationRate: number;


  constructor(
    private geoTechService: GeoTechService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, public dialogRef: MatDialogRef<InfiltrationTestLog>
  ) {
    this.url = router.url;
    const tree: UrlTree = router.parseUrl(this.url);
    const group = tree.root.children[PRIMARY_OUTLET];
    const segment: UrlSegment[] = group.segments;
    let urlData = {};
    urlData = segment[4].parameters;
    this.urlInfiltrationLogObject = urlData;
    this.infiltrationTestId = this.urlInfiltrationLogObject.id
  }

  ngOnInit() {
    this.initForms();
    if(this.data.infiltrationTestLog){
      this.title = 'Edit Infiltration Test Log';
    } else {
      this.title = 'Create Infiltration Test Log';
    }
  }

  initForms(){
    this.infiltrationTestLogForm = this.formBuilder.group({
      time: [this.data.infiltrationTestLog ? this.data.infiltrationTestLog.time : null, Validators.required],
      head: [this.data.infiltrationTestLog ? this.data.infiltrationTestLog.head.measure : null, Validators.required],
      headDiff: [{value: this.data.infiltrationTestLog ? this.data.infiltrationTestLog.headDiff : 0, disabled: true}],
      infiltrationRate: [{value: this.data.infiltrationTestLog ? this.data.infiltrationTestLog.infiltrationRate : 0, disabled: true}],
    })
  }

  onSubmit() {
    this.spinner.show();
    let infiltrationTestLogTemplateArray : InfiltrationTestLog [] = [];

    if (this.data.infiltrationTestLog) {
      this.infiltrationTestLogTemplate.id = this.data.infiltrationTestLog.id;
    }
    this.infiltrationTestLogTemplate.infiltrationTest.id = this.infiltrationTestId;
    this.infiltrationTestLogTemplate.time = this.infiltrationTestLogForm.controls.time.value;
    this.infiltrationTestLogTemplate.head.measure = this.infiltrationTestLogForm.controls.head.value;
    this.infiltrationTestLogTemplate.head.unit.id = this.unitEnum.millimeter;
    this.infiltrationTestLogTemplate.headDiff = this.infiltrationTestLogForm.controls.headDiff.value;
    this.infiltrationTestLogTemplate.infiltrationRate = this.infiltrationTestLogForm.controls.infiltrationRate.value;

    infiltrationTestLogTemplateArray.push(this.infiltrationTestLogTemplate);
      this.geoTechService.createInfiltrationTestIndividualLog(this.infiltrationTestLogTemplate).subscribe(response => {
        this.spinner.hide();
        if (this.title = 'Edit Infiltration Test Log') {
        this.toast.success('You have Successfully Updated Infiltration Test Log', this.title);
        } else {
          this.toast.success('You have Successfully Created Infiltration Test Log', this.title);
        }
        this.closeModal(this.infiltrationTestLogTemplate);
      },
      error => {
        this.spinner.hide();
        this.toast.error('Updating Infiltration Test Log failed, please try again.', this.title);
      },
      () => {
        this.spinner.hide();
        this.closeModal(this.infiltrationTestLogTemplate);
      });
  }


  closeModal(infiltrationTestLog){
      this.dialogRef.close(infiltrationTestLog);
  }

  onTimeInputChange(time: any) {
    let timeInterval = 0;
    if(this.data.infiltrationTest.infiltrationTestLogs.length <= 0) {
      this.headDiff = 0;
      this.infiltrationRate = 0;
    } else {

      if(time) {
        if(time > 0) {
          const index = this.data.infiltrationTest.infiltrationTestLogs.length - 1;
          const previousHead = this.data.infiltrationTest.infiltrationTestLogs[index].head.measure;
          if(this.infiltrationTestLogForm.controls.head.value) {
            this.infiltrationTestLogForm.controls.headDiff.setValue(Number(this.infiltrationTestLogForm.controls.head.value) - Number(previousHead));
            this.headDiff = Number(this.infiltrationTestLogForm.controls.head.value) - Number(previousHead);
          }
          if(this.infiltrationTestLogForm.controls.time.value) {
            if(this.infiltrationTestLogForm.controls.time.value > 0) {
              this.infiltrationTestLogForm.controls.infiltrationRate.setValue( this.infiltrationTestLogForm.controls.headDiff.value / Number(time));
              this.infiltrationRate = this.infiltrationTestLogForm.controls.headDiff.value / Number(time);
            }
          }
        }else{

        }
      }
    }
  }

  onHeadInputChange(head: any) {
    if(this.data.infiltrationTest.infiltrationTestLogs.length <= 0) {
      this.headDiff = 0;
      this.infiltrationRate = 0;
    } else {
      if(head) {
        if(head > 0) {
          const index = this.data.infiltrationTest.infiltrationTestLogs.length - 1;
          const previousHead = this.data.infiltrationTest.infiltrationTestLogs[index].head.measure;
          this.infiltrationTestLogForm.controls.headDiff.setValue(Number(head) - Number(previousHead));
          this.headDiff = Number(head) - Number(previousHead);
          if(this.infiltrationTestLogForm.controls.time.value) {
            if(this.infiltrationTestLogForm.controls.time.value > 0) {
              this.infiltrationTestLogForm.controls.infiltrationRate.setValue(this.infiltrationTestLogForm.controls.headDiff.value / Number(this.infiltrationTestLogForm.controls.time.value));
              this.infiltrationRate = this.infiltrationTestLogForm.controls.headDiff.value / Number(this.infiltrationTestLogForm.controls.time.value);
            }
          }
        }
      }
    }
  }
}
