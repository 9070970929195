import {Size} from '../../common/size';
import {Notes} from '../notes';
import {DcpSubgradeTest} from '../../../../applied-geoscience/geo-tech/dynamic-cone-penotrometer/dcp-roadworks/dcpSubgradeTest';
import { CoordinatesFormat } from '../../common/coordinatesValidator/coordinatesFormat';
import { LocationTemp } from '../../common/locationTemp';

export class DcpRoadworksLog {
  id: string;
  testNumber: number ;
  location: LocationTemp = new LocationTemp() ;
  numberOfBlows: number ;
  penetration: Size = new Size();
  startDepth: Size = new Size();
  endDepth: Size = new Size();
  deleted: boolean = false;
  comments: Notes = new Notes();
  dynamicConePenetrometerTestRoadworks: DcpSubgradeTest = new DcpSubgradeTest();
  coordinatesFormat: CoordinatesFormat = new CoordinatesFormat();
}
