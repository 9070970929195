import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceEndPoints } from 'src/app/shared-kernel/apiCalls/ServiceEndPoints';
import { WaterDrillersLogRecord } from './entity/waterdrillerslog';

@Injectable({
  providedIn: 'root'
})
export class WaterDrillersLogService {

  constructor(private http: HttpClient) { }

  getAllWaterDrillersLogRecords() {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllWaterDrillersLogRecordsUrl);
  }

  getBoreholeWaterDrillersLogRecords(borehole: any) {
    return this.http.get(ServiceEndPoints.appliedGeoscienceBaseUrl + ServiceEndPoints.getAllWaterDrillersLogRecordByBoreholeUrl + borehole);
  }

  deleteWaterDrillersLogRecord(waterDrillersLogRecord: WaterDrillersLogRecord) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.deleteWaterDrillersLogRecordUrl, waterDrillersLogRecord);
  }

  createWaterDrillersLogRecord(waterDrillersLogRecord: WaterDrillersLogRecord) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl +
      ServiceEndPoints.createWaterDrillersLogRecordUrl, waterDrillersLogRecord);
  }

  deleteWaterDrillersLogRecordBatch(waterDrillersLogRecords: WaterDrillersLogRecord[]) {
    return this.http.post(ServiceEndPoints.appliedGeoscienceBaseUrl + `/api/water-drillers-log/v1/water-drillers-log/delete/batch`, waterDrillersLogRecords);
  }
}
