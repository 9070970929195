import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Label } from 'ng2-charts';
import { UserDashboardService } from '../../user-dashboard/user-dashboard.service';
import { CollectionsState } from '../../../activities/models/CollectionsState';

@Component({
  selector: 'app-collection-senior-archivist',
  templateUrl: './collection-senior-archivist.component.html',
  styleUrls: ['./collection-senior-archivist.component.scss']
})
export class CollectionSeniorArchivistComponent implements OnInit {

  cards :any;
  state = new CollectionsState();
  stillFetchingMyActiviesCount = true;
  stillFetchingBGIProjectCount = true;
  numberOfCoreshedActivities = 0;
  myProjectCounts = 0;
  bgiProjectCounts = 0;
  public coreshedData:number[]= [];
  public customerData:number[]= [];
  public coreshedLabels:Label[]= [ 'Pending Approval', 'Approved', 'Returned','All Vetted'];
  public customerLabels:Label[]= [ 'Drafts','Submitted-Manager', 'Returned-Manager', 'Approved'];
  colors: any  = [{backgroundColor: [ '#00A2E5','#C8A207',
      '#CC0000', '#009900']}];

  stillFetchingnumberOfCoreshedActivitiesSeniorArchivistPendingCount = true;
  numberOfCoreshedActivitiesSeniorArchivistPending: number = 0;
  numberOfCoreshedActivitiesSeniorArchivistApproved: number = 0;
  numberOfCoreshedActivitiesSeniorArchivistReturned: number = 0;
  numberOfCoreshedActivitiesSeniorArchivistAllVetted: number = 0;
  numberOfRequestActivitiesSubmittedToManager: number = 0;
  stillFetchingRequestActivitiesSubmittedToManagerCount = true;
  numberOfRequestActivitiesRejectedByManager: number = 0;
  numberOfRequestActivitiesApproved: number = 0;
  numberOfRequestActivitiesDrafts: number = 0;

  public pieChartLegend : boolean= true;
  constructor(private _router:Router,private breakpointObserver: BreakpointObserver,private userDashBoardService: UserDashboardService,) {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {

        return [
          { title: 'My Activities', cols: 1, rows: 1 },
          { title: 'BGI Activities per District', cols: 1, rows: 1 },
          { title: 'My Projects', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: '', cols: 2, rows: 1 , id: 'cards'},
        { title: 'Coreshed Inventory', cols: 1, rows: 3 , id: 'myactivities'},
        { title: 'Customer Requests', cols: 1, rows: 3 ,id: 'districts'},

      ];
    })
  );
  }

  ngOnInit() {
    this.getBGIProjectsCount();
    this.getMyProjectsCount();
    this.getUserTrayActivitiesSeniorArchivistPendingCount();
    this.getUserRequestActivitiesRejectedByManagerCount();
  }

  chartClickedActivities(event){
    switch (event.active[0]._view.label) {
      case this.coreshedLabels[0]:
        this._router.navigate(['portal/activities/archivist/Senior-Archivist/Pending']);
        break
      case this.coreshedLabels[1]:
        this._router.navigate(['portal/activities/archivist/Senior-Archivist/Approved']);
        break
      case this.coreshedLabels[2]:
        this._router.navigate(['portal/activities/archivist/Senior-Archivist/Returned']);
        break
      case this.coreshedLabels[3]:
        this._router.navigate(['portal/activities/archivist/Senior-Archivist/All Vetted']);
        break
      default:
    }
  }

  chartClickedCustomer(event){
    switch (event.active[0]._view.label) {
      case this.customerLabels[0]:
        this._router.navigate(['portal/activities/archivist/request/PENDING_MANAGER_SUBMISSION']);
        break
      case this.customerLabels[1]:
        this._router.navigate(['portal/activities/archivist/request/SUBMITTED_TO_MANAGER']);
        break
      case this.customerLabels[2]:
        this._router.navigate(['portal/activities/archivist/request/REJECTED_BY_MANAGER']);
        break
      case this.customerLabels[3]:
        this._router.navigate(['portal/activities/archivist/request/APPROVED_MANAGER']);
        break
      default:
    }
  }

  getUserRequestActivitiesCount(){
    this.userDashBoardService.getUserRequestActivitiesCount('all', 'request').subscribe(number => {
      this.numberOfCoreshedActivities = number;
      this.stillFetchingMyActiviesCount = false;
    });
  }

  getMyProjectsCount() {
    this.userDashBoardService.getMyProjectCount().subscribe(number => {
      this.myProjectCounts = number;
      this.getUserRequestActivitiesCount();
    });
  }


  getBGIProjectsCount() {
    this.userDashBoardService.getBGIProjectCount().subscribe(number => {
      this.bgiProjectCounts = number;
      this.stillFetchingBGIProjectCount = false;
    });
  }


  getUserTrayActivitiesSeniorArchivistPendingCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('Pending').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistPending = number;
      this.getUserTrayActivitiesSeniorArchivistApprovedCount();
    });
  }

  getUserTrayActivitiesSeniorArchivistApprovedCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('Approved').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistApproved = number;
      this.getUserTrayActivitiesSeniorArchivistReturnedCount();
    });
  }

  getUserTrayActivitiesSeniorArchivistReturnedCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('Returned').subscribe(number => {
      this.numberOfCoreshedActivitiesSeniorArchivistReturned = number;
      this.getUserTrayActivitiesSeniorArchivistAllVettedCount()
    });
  }

  getUserTrayActivitiesSeniorArchivistAllVettedCount(){
    this.userDashBoardService.getSeniorArchivistTrayActivitiesCount('All Vetted').subscribe(number => {

      this.numberOfCoreshedActivitiesSeniorArchivistAllVetted = number;
      this.stillFetchingnumberOfCoreshedActivitiesSeniorArchivistPendingCount = false;
      this.coreshedData = [this.numberOfCoreshedActivitiesSeniorArchivistPending,this.numberOfCoreshedActivitiesSeniorArchivistApproved
        ,this.numberOfCoreshedActivitiesSeniorArchivistReturned,this.numberOfCoreshedActivitiesSeniorArchivistAllVetted];
    });
  }

  gotoProject(uri: string) {
    switch (uri) {
      case 'my_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'my_project' }]);
        break
      case 'bgi_project':
        this._router.navigate([`portal/applied-geoscience/project/list`,{ id: 'bgi_project' }]);
        break
      default:
    }
  }



  getUserRequestActivitiesSubmittedToManagerCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.SUBMITTED_TO_MANAGER.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesSubmittedToManager = number;
      this.stillFetchingRequestActivitiesSubmittedToManagerCount = false;
      this.customerData = [this.numberOfRequestActivitiesDrafts,this.numberOfRequestActivitiesSubmittedToManager,
        this.numberOfRequestActivitiesRejectedByManager,this.numberOfRequestActivitiesApproved];
    });
  }

  getUserRequestActivitiesDraftsCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.PENDING_MANAGER_SUBMISSION.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesDrafts = number;
      this.getUserRequestActivitiesSubmittedToManagerCount();
    });
  }

  getUserRequestActivitiesApprovedCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.APPROVED_MANAGER.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesApproved= number;
      this.getUserRequestActivitiesDraftsCount();
    });
  }

  getUserRequestActivitiesRejectedByManagerCount(){
    this.userDashBoardService.getUserRequestActivitiesCount(this.state.REJECTED_BY_MANAGER.status, 'request').subscribe(number => {
      this.numberOfRequestActivitiesRejectedByManager = number;
      this.getUserRequestActivitiesApprovedCount();
    });
  }

}
