import { UserService } from './../../../auth/user/user.service';
import { UserDashboardService } from './../user-dashboard.service';
import { State } from './../../../activities/models/state';
import { ApprovalState } from './../../../applied-geoscience/models/approval-state';
import { Person } from './../../../_core/data/_models/people.model';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'app-pl-dashboard',
  templateUrl: './pl-dashboard.component.html',
  styleUrls: ['./pl-dashboard.component.scss']
})
export class PlDashboardComponent implements OnInit {

  title = 'User Dashboard';
  plActivitiesPerDistrict :[];
  numberOfBGIProjects: number;
  numberOfMyProjects: number;
  numberOfProjectsActivitiesSubmittedToQA: number;
  numberOfProjectsActivitiesSubmittedToManager: number;
  numberOfMyPLs: number;
  numberOfPLs: number;
  private userName: string;
  private userReference: string;
  private authorities;
  private roles;
  numberOfActivities: any;
  numberOfDrafts: any;
  person: Person;


  myplactivities = {
  };
  issetDataMyPl = false;
  issetPl = false;
  mypl = {
    numberOfPlDrafts:0,
    numberOfPlSubmittedToQA:0,
    numberOfPlSubmittedToManager:0,
    numberOfPlApproved:0,
    numberOfPlReturned:0
  };

  state = new State();

  constructor(
    private _router: Router,
    private userDashBoardService: UserDashboardService,
    private userService: UserService) {

    let jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(localStorage.getItem('token'));
    this.userName = data.sub;
    this.userReference = data.jti;
    this.authorities = data.userAuthorises;
    this.roles = data.roles;
    this.userService.getPersonByUserId(this.userReference)
      .subscribe(data => {
        this.person = data as Person;
      });
  }

  ngOnInit() {
    this.getMyProspectingLicenseCounts();
    this.getPLsCount();
    this.getMyPLsCount();
    this.getPlcountsPerDistrict();
  }


  getPlcountsPerDistrict(){
    this.userDashBoardService.getMyProspectingLicensesActivitiesCountPerDistrict("all").subscribe(value => {
      this.plActivitiesPerDistrict = value;
    });
  }


  getPLsCount(){
    this.userDashBoardService.getPLsCount().subscribe(number => {
      this.numberOfPLs = number;
      this.myplactivities['numberOfAllPl'] = this.numberOfPLs;
    });
  }

  getMyPLsCount(){
    this.userDashBoardService.getMyPLsCount(this.userName).subscribe(number => {
      this.numberOfMyPLs = number;
      this.myplactivities['numberOfMyPl'] = this.numberOfMyPLs;
      this.mypl['numberOfPlDrafts'] = number;// TODO prospecting licence approval counts
      this.issetPl = true;
    });
  }

  goTo(type: string, filter: string) {
    this._router.navigate([`portal/activities/geologist/${type}/${filter}`]);
  }

  getMyProspectingLicenseCounts() {
    this.userDashBoardService.getMyProspectingLicencesActivitiesApprovalCount().subscribe(result => {
      let projectApprovals = result as [];
      let returned = 0;

      this.myplactivities['numberOfActivities'] = 0 ;
      projectApprovals.forEach(value => {
        this.myplactivities['numberOfActivities'] =  this.myplactivities['numberOfActivities'] + Number(value['counts']);
        switch(value['state']) {
          case ApprovalState.PENDING_QA_SUBMISSION:
            this.myplactivities['numberOfDrafts'] = value['counts'];
            break;
          case ApprovalState.SUBMITTED_TO_QA:
            this.myplactivities['numberOfPlActivitiesSubmittedToQA'] = value['counts'];
            break;
          case ApprovalState.REJECTED_BY_QA:
            returned = returned + value['counts'];
            break;
          case ApprovalState.SUBMITTED_TO_MANAGER:
            this.myplactivities['numberOfPlActivitiesSubmittedToManager'] = value['counts'];
            break;
          case ApprovalState.REJECTED_BY_MANAGER:
            returned = returned + value['counts'];
            break;
          case ApprovalState.PUBLISHED:
            this.myplactivities['approved'] = value['counts'];
            break;
          default:
            break;
        };

      });
      this.myplactivities['returned'] = returned;
      this.issetDataMyPl = true;
    });

  }
}
