import {Size} from '../../common/size';
import {CasingMaterial} from '../geoTech/CasingMaterial';
import {Borehole} from '../../common/borehole';
import {ScreeningCasing} from './ScreeningCasing';

export class CasingLog {
  id: string;
  casingId: string;
  parentId:string;
  constructionType: string;
  diameter: Size;
  depthFrom: Size;
  depthTo: Size;
  borehole: Borehole;
  slotNumber: string;
  material: CasingMaterial;
  deleted: boolean;
}
