import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { InfiltrationTest } from 'src/app/shared-kernel/entity/applied-geoscience/geoTech/InfiltrationTest';
import { MatTableDataSource, MatPaginator, MatSort, MatAutocomplete, MatChipInputEvent, MatDialogConfig, MatDialog } from '@angular/material';
import { GeoTechService } from '../../../geo-tech.service';
import { District } from 'src/app/common/entity/security/util/common/district';
import { Person } from 'src/app/common/entity/security/profile/person';
import { Project } from 'src/app/shared-kernel/entity/applied-geoscience/project/project';
import { NigisImages } from 'src/app/shared-kernel/entity/common/nigisImages';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { InfiltrationTestCreateComponent } from '../infiltration-test-create/infiltration-test-create.component';
import { CoordinatesType } from 'src/app/shared-kernel/entity/common/coordinatesType';
import { Coordinate } from 'src/app/shared-kernel/entity/common/coordinatesValidator/coordinate';
import { CoordinatesTypesEnum } from 'src/app/shared-kernel/enumerations/coordinates-type-enum';
import { CoordinatesFormatsEnum } from 'src/app/shared-kernel/enumerations/coordinates-formats-enumt';
import { BoreholeType } from 'src/app/shared-kernel/entity/common/boreholeType';
import { User } from '../../../../../_core/data/_models/people.model';
declare var $: any;

@Component({
  selector: 'app-infiltration-test-list',
  templateUrl: './infiltration-test-list.component.html',
  styleUrls: ['./infiltration-test-list.component.scss']
})
export class InfiltrationTestListComponent implements OnInit {

  dataSourceInfiltrationTests = new MatTableDataSource();
  displayedInfiltrationTestColumns = ['No.', 'Test Number', 'Project Name', 'Location', 'Date',
  'View','Edit', 'Delete'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('locationImageInput') locationImageInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  infiltrationTest: InfiltrationTest;
  infiltrationTests: InfiltrationTest[];
  projectGeologists: Person[];
  projectManagers: Person[];
  projectCoordinators: Person[];
  districts: District[];
  projects: Project[];
  locationImage: NigisImages;
  submitButtonLabel: String;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  locationImageCtrl = new FormControl();

  coordinatesTypes: CoordinatesType[];
  selectedCoordinatesType: any;
  selectedCoordinatesFormat: any;
  latLonArray: Coordinate = new Coordinate();
  botswanaPolygon: any[];

  dmsSx: number; // decimal minutes seconds, to hold x/longitude seconds value
  dmsMx: number; // decimal minutes seconds, to hold x/longitude minutes value
  dmsDx: number; // decimal minutes seconds, to hold x/longitude decimal value

  dmsSy: number; // decimal minutes seconds, to hold y/latitude seconds value
  dmsMy: number; // decimal minutes seconds, to hold y/latitude minutes value
  dmsDy: number; // decimal minutes seconds, to hold y/latitude decimal value

  zone34SNorthing: number;
  zone34SEasting: number;


  zone35SNorthing: number;
  zone35SEasting: number;

  coordinatesTypesEnum = CoordinatesTypesEnum;
  coordinatesFormatsEnum = CoordinatesFormatsEnum;
  boreholeTypes: BoreholeType[] = [];
  selectedBoreholeType: string;
  public currentUser: User;
  public currentPerson: Person;
  loading = true;

  constructor(
    private geoTechService: GeoTechService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getInfiltrationTests();
    this.dataSourceInfiltrationTests = new MatTableDataSource(this.infiltrationTests);
  }
  ngAfterViewInit() {
    this.dataSourceInfiltrationTests.sort = this.sort;
    this.dataSourceInfiltrationTests.paginator = this.paginator;
  }

  resetPaginator() {
    this.dataSourceInfiltrationTests.sort = this.sort;
    this.dataSourceInfiltrationTests.paginator = this.paginator;
    this.dataSourceInfiltrationTests.data = this.infiltrationTests;
  }

  applyFilter(filterValue: string) {
    this.dataSourceInfiltrationTests.filter = filterValue.trim().toLowerCase();
  }

  getInfiltrationTests() {
    this.geoTechService.getAllActiveInfiltrationTests()
      .subscribe(data => {
        this.infiltrationTests = data as InfiltrationTest[];
        this.infiltrationTests.sort((a, b) => (a.project.name || '').toString().localeCompare((b.project.name || '').toString())
          || <any>a.testPointId.localeCompare(<any>b.testPointId, undefined, {numeric: true, sensitivity: 'base'}));
        this.dataSourceInfiltrationTests.data = this.infiltrationTests;
        this.loading = false;
      }, error => {
      }, () => {
        this.resetPaginator();
      });
  }

  onDelete() {
    this.spinner.show();
    this.geoTechService.deleteInfiltrationTest(this.infiltrationTest).subscribe(response => {
      const index: number = this.infiltrationTests.indexOf(this.infiltrationTest);
        if (index !== -1) {
          this.infiltrationTests.splice(index, 1);
        }
        this.toast.success('You have Successfully deleted Infiltration Test', 'Infiltration Test Update');
        this.spinner.hide();
        this.resetPaginator();
        this.dataSourceInfiltrationTests = new MatTableDataSource(this.infiltrationTests);
        $('#resetForm').click();
        this.closeModal();
      },
      error => {
        this.toast.error('Deleting an Infiltration Test failed, please try again.', 'Infiltration Test Update');
      },
      () => {
        this.spinner.hide();
        this.resetPaginator();
        this.closeModal();
      });
  }

  openInfiltrationTestForm() {
    const newDCPDialogRef = this.dialog.open(InfiltrationTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
    });
    newDCPDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getInfiltrationTests();
      }
    });
  }

  openInfiltrationTestForEdit(infiltrationTest: InfiltrationTest) {
    const newDCPDialogRef = this.dialog.open(InfiltrationTestCreateComponent, <MatDialogConfig>{
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      data: infiltrationTest,
    });
    newDCPDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getInfiltrationTests();
      }
    });
  }

  openInfiltrationTestDetails(infiltrationTest) {
    const urlParams = { id: infiltrationTest.id };
    this.router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/details', urlParams]);
  }

  closeModal() {
    $('#deleteModal').modal('hide');
  }

  deleteRecordRequest(infiltrationTest: InfiltrationTest) {
    this.infiltrationTest = infiltrationTest;
    $('#deleteModal').modal('show');
  }

  navigateToMap() {
    if (this.infiltrationTests.length > 0) {
      localStorage.setItem('list', JSON.stringify(this.infiltrationTests));
      const from = { list: 'infiltration-tests' };
      this.router.navigate(['portal/applied-geoscience/geo-tech/infiltration-test/locations', from]);
    } else {
      this.toast.warning('Please make sure that Infiltration-test list is not Empty', 'Plot To Map');
    }
  }

  exportDocument(fileType){
    this.toast.warning('Feature still under construction', 'Export File');
  }
}
